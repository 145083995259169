import { apigw } from "../awsApi";
import { Dispatch } from "@reduxjs/toolkit";

const state = 0;//テストデータ
const error_code = "04070"
/**
 * 配信ユーザ詳細取得取得
 * @param {Dispatch} dispatch reducerディスパッチ
 * @param {string} userName ユーザ名
 * @param {string} handleName　ハンドルネーム
 * @param {string} mailAddress メールアドレス
 * @param {string} updateDate 更新日
 * @return コンポーネントHTMLコード内容
 */
export const US0305 = async (
  dispatch: Dispatch,
  userName: string,
  handleName: string,
  mailAddress: string,
  updateDate: string, 

) => {
  if (process.env.REACT_APP_API_USE_FLG === "true") {
    return apigw(
      "/US0305",
      {
        user_name: userName,
        handle_name: handleName,
        mail_address: mailAddress,
        update_date: updateDate,
      },
      dispatch
    );
  } else {
    //サンプルデータ用
    return { state,error_code };
  }
};
