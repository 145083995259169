import React, { useEffect } from "react";
// import { useDispatch } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { FormatMessage } from "../../components/atoms/FormatMessage";
import { unescapeStr } from "../../components/function/Function";

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  list: {
    marginTop: "20px",
    border: "1px solid #ccc",
  },
}));

export const TableDate = (props: any) => {
  // 【変数・定数宣言】
  const classes = useStyles();
  // const dispatch = useDispatch();

  //初期処理
  useEffect(() => {}, []);

  return (
    <React.Fragment>
      <Table size="small" className={classes.list}>
        <TableHead>
          <TableRow>
            <TableCell
              rowSpan={2}
              style={{ width: "13%", backgroundColor: "#a2cbe9" }}
            >
              {FormatMessage({ id: "Cls018.Label.text9" })}
            </TableCell>
            <TableCell
              rowSpan={2}
              style={{ width: "13%", backgroundColor: "#a2cbe9" }}
            >
              {FormatMessage({ id: "Cls018.Label.text10" })}
            </TableCell>
            <TableCell
              colSpan={3}
              style={{ width: "37%", backgroundColor: "#a2cbe9" }}
            >
              {FormatMessage({ id: "Cls018.Label.text11" })}
            </TableCell>
            <TableCell
              colSpan={3}
              style={{ width: "37%", backgroundColor: "#a2cbe9" }}
            >
              {FormatMessage({ id: "Cls018.Label.text12" })}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ width: "13%", backgroundColor: "#a2cbe9" }}>
              {FormatMessage({ id: "Cls018.Label.text13" })}
            </TableCell>
            <TableCell style={{ width: "12%", backgroundColor: "#a2cbe9" }}>
              {FormatMessage({ id: "Cls018.Label.text14" })}
            </TableCell>
            <TableCell style={{ width: "12%", backgroundColor: "#a2cbe9" }}>
              {FormatMessage({ id: "Cls018.Label.text15" })}
            </TableCell>
            <TableCell style={{ width: "13%", backgroundColor: "#a2cbe9" }}>
              {FormatMessage({ id: "Cls018.Label.text16" })}
            </TableCell>
            <TableCell style={{ width: "12%", backgroundColor: "#a2cbe9" }}>
              {FormatMessage({ id: "Cls018.Label.text17" })}
            </TableCell>
            <TableCell style={{ width: "12%", backgroundColor: "#a2cbe9" }}>
              {FormatMessage({ id: "Cls018.Label.text18" })}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.lesson_rows.map((item: any, index: any) => (
            <TableRow key={index}>
              <TableCell>{unescapeStr(item.class_name)}</TableCell>
              <TableCell style={{ textAlign: "right" }}>
                {item.class_time === 0 ? "" : item.class_time}
              </TableCell>
              <TableCell style={{ textAlign: "right" }}>
                {item.class_time === 0 ||
                item.existing_use_class === "2" ||
                item.stream_class === "2"
                  ? ""
                  : item.viewing_time}
              </TableCell>
              {/* 出席状況は視聴時間が1分以上で出席 */}
              <TableCell>
                {item.class_time === 0 ||
                item.existing_use_class === "2" ||
                item.stream_class === "2"
                  ? "-"
                  : item.viewing_time >= 1
                  ? "出席"
                  : "欠席"}
              </TableCell>
              <TableCell>{item.viewing_datetime}</TableCell>
              <TableCell style={{ textAlign: "right" }}>
                {item.class_time === 0 || item.open_class === "2"
                  ? ""
                  : item.viewing_time_ondemand}
              </TableCell>
              {/* 視聴状況は視聴時間が1分以上で一部視聴。視聴時間と授業時間がイコールで視聴済み */}
              <TableCell>
                {item.class_time === 0 || item.open_class === "2"
                  ? "-"
                  : item.viewing_time_ondemand / item.class_time === 1
                  ? "視聴済み"
                  : item.viewing_time_ondemand >= 1
                  ? "一部視聴済み"
                  : "未視聴"}
              </TableCell>
              <TableCell>{item.viewing_datetime_ondemand}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
};
