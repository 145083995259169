import { apigw } from "../awsApi";
import { Dispatch } from "@reduxjs/toolkit";

const state = 0;//テストデータ

/**
 * 配信ユーザ詳細取得取得
 * @param {Dispatch} dispatch reducerディスパッチ
 * @param {string} mailAddress メールアドレス
 * @param {string} updateDate 更新日時
 * @return コンポーネントHTMLコード内容
 */
export const US0303 = async (
  dispatch: Dispatch,
  mailAddress: string,
  updateDate: string,
) => {
  if (process.env.REACT_APP_API_USE_FLG === "true") {
    return apigw(
      "/US0303",
      {
        mail_address: mailAddress,
        update_date: updateDate
      },
      dispatch
    );
  } else {
    //サンプルデータ用
    return { state };
  }
};
