/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Tab from "@material-ui/core/Tab";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { MainLayout } from "../../layouts/MainLayout";
import { FormatMessage } from "../../components/atoms/FormatMessage";
import Tabs from "@material-ui/core/Tabs";
import { Tab1 } from "./001_1";
import { Tab2 } from "./001_2";
import { Cls019 } from "./019";
import { Cls020 } from "./020";
import { Cls021 } from "./021";
import { Cls022 } from "./022";
import { Cls002 } from "./002";
import { Cls003 } from "./003";
import { LC0302 } from "../../utils/api/LC0302";
import { CF0305 } from "../../utils/api/CF0305";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import CastForEducationIcon from "@material-ui/icons/CastForEducation";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { store } from "../../reducks/store";
import {
  createS3,
  uploadS3,
  getListS3,
  getFileS3,
  getUrlS3,
  deleteS3,
} from "../../components/function/AwsS3Fun";
import { LoadingBox } from "../../components/atoms/LoadingBox";
import sanitize from "sanitize-html";
import { unescapeStr } from "../../components/function/Function";
import { DialogTitle } from "@material-ui/core";

let addFlg2: any;

const AntTabs = withStyles({
  root: {
    borderBottom: "1px solid #e8e8e8",
  },
  indicator: {
    backgroundColor: "#1890ff",
  },
})(Tabs);

const AntTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: "none",
      minWidth: 72,
      fontWeight: theme.typography.fontWeightRegular,
      marginRight: theme.spacing(4),
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:hover": {
        color: "#40a9ff",
        opacity: 1,
      },
      "&$selected": {
        color: "#1890ff",
        fontWeight: theme.typography.fontWeightMedium,
      },
      "&:focus": {
        color: "#40a9ff",
      },
    },
    selected: {},
  })
)((props: StyledTabProps) => (
  <Tab disableRipple disabled={addFlg2 === "check" ? true : false} {...props} />
));

interface StyledTabProps {
  label: string;
}

let existingUseClass: String;
let statusClass: String;
let timeOut: number;
let start: any;

/**
 * 授業設定
 * @param {object} props 引き継いだオブジェクト
 * @return コンポーネントHTMLコード内容
 */
export const Cls001 = (props: any) => {
  const dispatch = useDispatch();
  let sessionDateValue = localStorage.getItem("Cls001_session") as string;
  const getDateValue =
    typeof props.location.state === "undefined"
      ? JSON.parse(sessionDateValue)
      : props.location.state;
  if (typeof props.location.state !== "undefined") {
    localStorage.setItem(
      "Cls001_session",
      JSON.stringify(props.location.state)
    );
  }
  const classId = getDateValue?.class_id ? getDateValue?.class_id : "";
  const [className, setClassName] = React.useState("");
  const errMsg1 = FormatMessage({ id: "Cls001.Err.Msg1" });
  const errMsg2 = FormatMessage({ id: "Cls001.Err.Msg2" });
  const errMsg10 = FormatMessage({ id: "Cls001.Err.Msg10" });
  const errMsg6 = FormatMessage({ id: "Cls001.Err.Msg6" });
  const [errDifStreamerOpen, setErrDifStreamerOpen] = React.useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);
  const [changeStreamer, setChangeStreamer] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [useMovClass, setUseMovClass] = React.useState(false);
  const [updateFlg, setUpdateFlg] = React.useState(false);
  const [showTelop, setShowTelop] = React.useState(false);
  const SafariCheckMessage = FormatMessage({ id: "Error.Message.SafariCheck" });
  const classStartCilck = async () => {
    start = async () => {
      if (statusClass !== "5") {
        if (serviceRows && serviceRows.length > 0) {
          if (
            serviceRows[0].status_stream_time <
              serviceRows[0].status_stream_time_limit ||
            serviceRows[0].stream_time_excess === "1"
          ) {
            if (
              serviceRows[0].month_end_save_class_time <
              serviceRows[0].save_class_time_limit
            ) {
              let noC: String = String(localStorage.getItem("NotClassID"));
              if (noC.indexOf(classId) >= 0) {
                alert(errMsg6);
              } else {
                let user = store.getState().user;
                const S3: any = await createS3();
                const list: any = await getListS3(
                  S3,
                  "haishin/" + user.service_id + "/" + classId + "/checkClass/"
                );
                if (list && list.length > 0) {
                  let nowTime = Number(new Date().getTime());
                  for (let index = 0; index < list.length; index++) {
                    if (
                      list[index].Key.substr(-4) === "json" &&
                      nowTime - Number(new Date(list[index].LastModified)) <
                        2 * 60 * 60 * 1000 &&
                      list[index].Key.indexOf(
                        user.user_id + "_" + user.user_class
                      ) < 0
                    ) {
                      let file: any = await getFileS3(S3, list[index].Key);
                      if (file) {
                        let reader = new FileReader();
                        reader.readAsText(file);
                        reader.onload = function () {
                          if (reader.result) {
                            let data = JSON.parse(String(reader.result));
                            setChangeStreamer(data.name);
                            setErrDifStreamerOpen(true);
                          }
                        };
                      }
                      return;
                    }
                  }
                }
                dispatch(push("/cls/008", { classId: sanitize(classId) }));
              }
            } else {
              alert(errMsg10);
            }
          } else {
            alert(errMsg1);
          }
        }
      } else {
        alert(errMsg2);
      }
    };
    if (savedImgList !== newImgList || savedPdfList !== newPdfList) {
      setOpenConfirmDialog(true);
    } else {
      var userAgent = window.navigator.userAgent.toLowerCase();
      if (
        userAgent.indexOf("safari") > -1 &&
        userAgent.indexOf("chrome") === -1
      ) {
        if (
          /android|ipod|ipad|iphone|macintosh/.test(userAgent) &&
          "ontouchend" in document
        ) {
        } else {
          alert(SafariCheckMessage);
        }
      }
      start();
    }
  };

  // 画像資料並び順
  const [savedImgList, setSavedImgList] = React.useState<any[]>();
  const [newImgList, setNewdImgList] = React.useState<any[]>();
  // 配布資料並び順
  const [savedPdfList, setSavedPdfList] = React.useState<any[]>();
  const [newPdfList, setNewPdfList] = React.useState<any[]>();

  // サービス利用情報データ
  const [serviceRows, setServiceRows] = React.useState<any[]>();

  // カスタムアンケートデータ
  type customQuesRow = {
    update_date: string;
    question_no: string;
    question: string;
    is_required: string;
    character_class: string;
    options: any[];
  };

  const [customQuesRows, setCustomQuesRows] = React.useState<customQuesRow[]>(
    []
  );
  const [initialCustomQuesRows, setInitialCustomQuesRows] = React.useState<
    customQuesRow[]
  >([]);
  const [noQuestionFlg, setNoQuestionFlg] = React.useState(false);

  const [addFlg, setAddFlg] = React.useState("input");

  type inputDate = {
    afterPageSetting: number;
    afterUrl: string;
    templateName: string;
    templateOption: string;
    beforeAnswerMsg: string;
    afterAnswerMsg: string;
    enquete: customQuesRow[];
    iniEnquete: customQuesRow[];
    tempFlg: number;
    enqRegFlg: number;
    classId: string;
    lecUpdate: string;
    customUpdate: string;
    noQuestionFlg: boolean;
    isVisibleDefFlg: string;
    customExistence: boolean;
    className: string;
  };

  const [addValue, setAddValue] = React.useState({
    afterPageSetting: 0,
    afterUrl: "",
    templateName: "",
    templateOption: "",
    beforeAnswerMsg: "",
    afterAnswerMsg: "",
    tempFlg: 0,
    enqRegFlg: 0,
    classId: "",
    lecUpdate: "",
    customUpdate: "",
    isVisibleDefFlg: "",
    customExistence: false,
    className: "",
  });

  const addCallback = (addDate: inputDate) => {
    setUpdateFlg(false);
    setAddFlg("check");
    addFlg2 = "check";
    setAddValue({
      afterPageSetting: addDate.afterPageSetting ? addDate.afterPageSetting : 0,
      afterUrl: addDate.afterUrl ? addDate.afterUrl : "",
      templateName: addDate.templateName ? addDate.templateName : "",
      templateOption: addDate.templateOption ? addDate.templateOption : "",
      beforeAnswerMsg: addDate.beforeAnswerMsg ? addDate.beforeAnswerMsg : "",
      afterAnswerMsg: addDate.afterAnswerMsg ? addDate.afterAnswerMsg : "",
      tempFlg: addDate.tempFlg ? addDate.tempFlg : 0,
      enqRegFlg: addDate.enqRegFlg ? addDate.enqRegFlg : 0,
      classId: addDate.classId ? addDate.classId : "",
      lecUpdate: addDate.lecUpdate ? addDate.lecUpdate : "",
      customUpdate: addDate.customUpdate ? addDate.customUpdate : "",
      isVisibleDefFlg: addDate.isVisibleDefFlg ? addDate.isVisibleDefFlg : "",
      customExistence: addDate.customExistence
        ? addDate.customExistence
        : false,
      className: addDate.className ? addDate.className : "",
    });
    setNoQuestionFlg(addDate.noQuestionFlg);
    setCustomQuesRows(addDate.enquete);
    setInitialCustomQuesRows(addDate.iniEnquete);
  };

  const checkCallback = (addDate: inputDate) => {
    setUpdateFlg(false);
    setNoQuestionFlg(false);
    setAddFlg("end");
    addFlg2 = "end";
  };

  const backCallback = (addDate: inputDate) => {
    setUpdateFlg(false);
    setAddFlg("input");
    addFlg2 = "input";
  };

  const endCallback = (addDate: inputDate) => {
    setUpdateFlg(true);
    setAddFlg("input");
    addFlg2 = "input";
  };

  const [telopValue, setTelopValue] = React.useState({});
  const [telopDo, setTelopDo] = React.useState(0);

  const telopEdit = (data: any) => {
    if (data) setTelopValue(data);
    if (data && data.state === "add_edit") {
      setTelopDo(1);
    } else if (data && data.state === "conf") {
      setTelopDo(2);
    } else if (data && data.state === "end") {
      setTelopDo(3);
    } else {
      setTelopDo(0);
    }
  };

  var flg = 0;
  var url = window.location.href.split("?");
  if (url.length > 1 && url[1] === "list2") {
    flg = 1;
  }
  const [value, setValue] = React.useState(flg);

  const handleChange = (
    event: React.ChangeEvent<{}>,
    newValue: React.SetStateAction<number>
  ) => {
    start = () => {
      setValue(newValue);
    };
    if (newValue === 1) {
      if (savedImgList !== newImgList || savedPdfList !== newPdfList) {
        setOpenConfirmDialog(true);
      } else {
        setValue(newValue);
      }
    } else {
      setValue(newValue);
    }
  };

  const [movieTime, setMovieTime] = React.useState("00:00:00");

  const getTimeFormat = (sec: number) => {
    sec = Math.floor(sec);
    var min = Math.floor(Number(sec) / 60);
    var second = Number(sec) - min * 60;
    var hour = Math.floor(min / 60);
    min = min - hour * 60;
    return (
      ("00" + hour).slice(-2) +
      ":" +
      ("00" + min).slice(-2) +
      ":" +
      ("00" + second).slice(-2)
    );
  };

  // 授業詳細情報取得処理
  const getLecData = async (callback: any) => {
    let getData: any = await LC0302(dispatch, classId, false, true);
    if (
      getData &&
      getData.state === "0" &&
      Object.keys(getData).indexOf("body") >= 0
    ) {
      setClassName(getData.body.class_name);
      statusClass = getData.body.status_class;
      existingUseClass = getData.body.existing_use_class;
      if (existingUseClass === "2") {
        setUseMovClass(true);
      }
      const s3 = await createS3();
      let user = store.getState().user;
      const filePath = "haishin/" + user.service_id + "/" + classId + "/";
      let getVideoInfo = (url: string) => {
        let video = document.getElementById("video") as HTMLMediaElement;
        video.src = url;
        video.load();
        const getTime = () => {
          let get_video = document.getElementById("video") as HTMLMediaElement;
          if (get_video && get_video.duration && !isNaN(get_video.duration)) {
            const TimeText = getTimeFormat(get_video.duration);
            setMovieTime(TimeText);
          } else {
            setTimeout(getTime, 1000);
          }
        };
        setTimeout(getTime, 1000);
      };
      if (s3) {
        const original: any = await getListS3(s3, filePath + "movie/original/");
        if (original && original.length > 0) {
          for (let index = 0; index < original.length; index++) {
            if (original[index].Key.substr(-3) === "mp4") {
              const vidoe_data: any = await getUrlS3(s3, original[index].Key);
              if (vidoe_data) {
                getVideoInfo(vidoe_data);
              }
              setShowTelop(true);
              break;
            }
          }
        } else {
          if (statusClass === "5") {
            const board: any = await getListS3(
              s3,
              filePath + "movie/board_convert/"
            );
            if (board && board.length > 0) {
              for (let index = 0; index < board.length; index++) {
                if (board[index].Key.substr(-3) === "mp4") {
                  const vidoe_data: any = await getUrlS3(s3, board[index].Key);
                  if (vidoe_data) {
                    getVideoInfo(vidoe_data);
                  }
                  setShowTelop(true);
                  break;
                }
              }
            }
          }
        }
      }
    }
    if (callback) callback();
  };

  // サービス利用情報取得処理
  const getServiceData = async (callback: any) => {
    let getServiceData: any = await CF0305(dispatch);
    if (getServiceData && Object.keys(getServiceData).indexOf("body") >= 0) {
      setServiceRows(getServiceData.body);
    }
    if (callback) callback();
  };

  const checkInClassState = async () => {
    let service_id = store.getState().user.service_id;
    if (!timeOut || timeOut === 0) timeOut = 30;
    const S3: any = await createS3();
    const list: any = await getListS3(
      S3,
      "haishin/" + service_id + "/" + classId + "/checkClass/"
    );
    if (timeOut === 1) {
      timeOut--;
      for (let index = 0; index < list.length; index++) {
        if (list[index].Key.substr(-4) === "json")
          await deleteS3(S3, list[index].Key);
      }
      setLoading(false);
      dispatch(push("/cls/008", { classId: sanitize(classId) }));
    } else {
      if (list && list.length > 0) {
        timeOut--;
        setTimeout(() => checkInClassState(), 1000);
      } else {
        setLoading(false);
        dispatch(push("/cls/008", { classId: sanitize(classId) }));
      }
    }
  };

  const handleChangeStreamer = async () => {
    setErrDifStreamerOpen(false);
    setLoading(true);
    let user = store.getState().user;
    let jsonData = JSON.stringify({ name: user.user_name });
    var blob = new Blob([jsonData], { type: "text/json" });
    var jsonFile = new File(
      [blob],
      user.user_id + "_" + user.user_class + ".json"
    );
    const S3: any = await createS3();
    if (S3) {
      await uploadS3(
        S3,
        "haishin/" +
          user.service_id +
          "/" +
          classId +
          "/checkClass/" +
          user.user_id +
          "_" +
          user.user_class +
          ".json",
        jsonFile
      );
      checkInClassState();
    }
  };

  const clickContinue = () => {
    if (start) start();
    setOpenConfirmDialog(false);
  };

  const handleLoading = (value: any) => {
    setLoading(value);
  };

  // 初期処理
  useEffect(() => {
    existingUseClass = "";
    statusClass = "";
    addFlg2 = addFlg;
    getLecData(null);
    getServiceData(null);
    if (props.location.state?.newValue) {
      setValue(props.location.state?.newValue);
    }
  }, []);

  return (
    <MainLayout>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className="App-paper">
            <Typography variant="h5">
              {FormatMessage({ id: "Cls001.Title" })}
            </Typography>
            <Grid item xs={12} className="App-alignRight">
              <Typography
                variant="h6"
                className="App-W250 App-alignLeft App-FloatLeft"
              >
                {FormatMessage({ id: "Cls001.Label.text3" })}&nbsp;
                {unescapeStr(classId)}
              </Typography>
              <Typography
                variant="h6"
                className="App-FloatLeft"
                align="left"
                style={{ wordBreak: "break-all" }}
              >
                {FormatMessage({ id: "Cls001.Label.text4" })}&nbsp;
                {className}
              </Typography>
              <Button
                variant="contained"
                color={
                  useMovClass
                    ? "default"
                    : addFlg === "check"
                    ? "default"
                    : "primary"
                }
                disabled={
                  useMovClass ? true : addFlg === "check" ? true : false
                }
                startIcon={<CastForEducationIcon />}
                onClick={classStartCilck}
              >
                {FormatMessage({ id: "Cls001.Button.text1" })}
              </Button>
            </Grid>
          </Paper>
          <Paper className="App-paper" style={{ marginTop: "10px" }}>
            <AntTabs value={value} onChange={handleChange}>
              <AntTab label={FormatMessage({ id: "Cls001.Label.text1" })} />
              <AntTab label={FormatMessage({ id: "Cls001.Label.text2" })} />
              {showTelop ? (
                <AntTab label={FormatMessage({ id: "Cls001.Label.text10" })} />
              ) : null}
            </AntTabs>
            <Grid style={{ marginTop: "10px" }}>
              {value === 1 ? (
                <div>
                  {addFlg === "input" ? (
                    <Tab2
                      Callback={addCallback}
                      class_id={classId}
                      Values={addValue}
                      QuesValues={customQuesRows}
                      IniQuesValues={initialCustomQuesRows}
                      UpdateFlg={updateFlg}
                      NoQuestionFlg={noQuestionFlg}
                    />
                  ) : (
                    ""
                  )}
                  {addFlg === "check" ? (
                    <Cls002
                      Values={addValue}
                      QuesValues={customQuesRows}
                      IniQuesValues={initialCustomQuesRows}
                      NoQuestionFlg={noQuestionFlg}
                      Callback={backCallback}
                      Endback={checkCallback}
                      setLoading={handleLoading}
                    />
                  ) : (
                    ""
                  )}
                  {addFlg === "end" ? <Cls003 Callback={endCallback} /> : ""}
                </div>
              ) : value === 0 ? (
                <div className="img_upload_download">
                  <Tab1
                    class_id={classId}
                    ClassName={className}
                    ExistingUseClass={existingUseClass}
                    SetSavedImgList={setSavedImgList}
                    SetNewdImgList={setNewdImgList}
                    SetSavedPdfList={setSavedPdfList}
                    SetNewPdfList={setNewPdfList}
                  />
                </div>
              ) : (
                <div>
                  {telopDo === 0 ? (
                    <Cls019
                      class_id={classId}
                      movie_time={movieTime}
                      Callback={telopEdit}
                    />
                  ) : telopDo === 1 ? (
                    <Cls020
                      class_id={classId}
                      saveData={telopValue}
                      Callback={telopEdit}
                    />
                  ) : telopDo === 2 ? (
                    <Cls021
                      class_id={classId}
                      confData={telopValue}
                      Callback={telopEdit}
                    />
                  ) : (
                    <Cls022
                      class_id={classId}
                      endData={telopValue}
                      Callback={telopEdit}
                    />
                  )}
                </div>
              )}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      {/* 二重ユーザ時 */}
      <Dialog
        open={errDifStreamerOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className="alignCenter">
          {FormatMessage({
            id: "HS060",
            option: { name: changeStreamer },
          })}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleChangeStreamer} color="primary" autoFocus>
            {FormatMessage({
              id: "Cls001.Dialog.textYes",
            })}
          </Button>
          <Button
            onClick={() => setErrDifStreamerOpen(false)}
            color="primary"
            autoFocus
          >
            {FormatMessage({
              id: "Cls001.Dialog.textNo",
            })}
          </Button>
        </DialogActions>
      </Dialog>
      {/* 編集保存確認ダイアログ */}
      <Dialog
        open={openConfirmDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {/* {FormatMessage({ id: "Cls001.Dialog.title" })} */}
        </DialogTitle>
        <DialogContent>
          {FormatMessage({ id: "Cls001.Dialog.text5" })}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirmDialog(false)} color="primary">
            {FormatMessage({ id: "Cls001.Button.cancel" })}
          </Button>
          <Button onClick={clickContinue} color="primary" autoFocus>
            {FormatMessage({ id: "Cls001.Button.OK" })}
          </Button>
        </DialogActions>
      </Dialog>
      {loading ? <LoadingBox /> : null}
      <video id="video" style={{ display: "none" }}></video>
    </MainLayout>
  );
};
