import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { TextFieldBox } from "../../components/atoms/TextFieldBox";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CheckIcon from "@material-ui/icons/Check";

import { MainLayout } from "../../layouts/MainLayout";
import { FormatMessage } from "../../components/atoms/FormatMessage";
import { isEmail } from "../../components/function/Function";
import { US0306 } from "../../utils/api/US0306";
import { LoadingBox } from "../../components/atoms/LoadingBox";
import sanitize from "sanitize-html";
import { unescapeStr } from "../../components/function/Function";

let getDateValue: any;
/**
 * アカウント編集（視聴ユーザ）
 * @param {object} props 引き継いだオブジェクト
 * @return コンポーネントHTMLコード内容
 */
export const Set006 = (props: any) => {
  let sessionDataValue = localStorage.getItem("Set006_session") as string;
  getDateValue =
    props && props.location && typeof props.location.state === "undefined"
      ? JSON.parse(sessionDataValue)
      : props.location.state;
  if (typeof props.location.state !== "undefined") {
    localStorage.setItem(
      "Set006_session",
      JSON.stringify(props.location.state)
    );
  }

  const dispatch = useDispatch();
  const [userId, setUserId] = React.useState("");
  const [userName, setUserName] = React.useState("");
  const [handleName, setHandleName] = React.useState("");
  const [mailAddress, setMailAddress] = React.useState("");
  const [updateDate, setUpdateDate] = React.useState("");

  const [userNameErr, setUserNameErr] = React.useState(false);
  const [handleNameErr, setHandleNameErr] = React.useState(false);
  const [textTypeErr] = React.useState(false);
  const [formatErr, setFormatErr] = React.useState(false);
  const [duplicationErr, setDuplicationErr] = React.useState(false);

  // loadingフラグ
  const [loading, setLoading] = React.useState(false);

  const [isPhone, setIsPhone] = React.useState(false);

  const backCilck = () => {
    dispatch(push("/set/005"));
  };
  const checkCilck = () => {
    setLoading(true);
    if (
      userName !== "" &&
      handleName !== "" &&
      (mailAddress === "" || isEmail(mailAddress))
    ) {
      checkMailAddress(mailAddress).then((res) => {
        if (res) {
          setLoading(false);
          dispatch(
            push("/set/007", {
              userId: sanitize(userId),
              userName: sanitize(userName),
              handleName: sanitize(handleName),
              mailAddress: sanitize(mailAddress),
              updateDate: sanitize(updateDate),
            })
          );
        } else {
          setDuplicationErr(true);
          setLoading(false);
        }
      });
    } else {
      if (userName === "") {
        setUserNameErr(true);
      }
      if (handleName === "") {
        setHandleNameErr(true);
      }
      if (mailAddress !== "") {
        if (!isEmail(mailAddress)) {
          setFormatErr(true);
        }
      }
      setLoading(false);
    }
  };

  //メールアドレス重複チェック
  const checkMailAddress = async (mailAddress: any) => {
    if (mailAddress !== "") {
      let checkMailAddress = await US0306(dispatch, mailAddress, updateDate);
      if (
        checkMailAddress &&
        Object.keys(checkMailAddress).indexOf("state") >= 0
      ) {
        if (checkMailAddress.state === "0") {
          return true;
        } else {
          return false;
        }
      }
    } else {
      return true;
    }
  };

  //必須項目の空欄解消時
  const CheckUserName = () => {
    setUserNameErr(false);
  };
  const CheckHandleName = () => {
    setHandleNameErr(false);
  };

  useEffect(() => {
    if (getDateValue) {
      setUserId(getDateValue.userId);
      setUserName(getDateValue.userName);
      setHandleName(getDateValue.handleName);
      setMailAddress(getDateValue.mailAddress);
      setUpdateDate(getDateValue.updateDate);
    }
    if (
      navigator.userAgent.indexOf("Android") > 0 ||
      navigator.userAgent.indexOf("iPhone") > 0
    ) {
      setIsPhone(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainLayout>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className="App-paper">
            <Typography
              component="h2"
              variant="h6"
              color="inherit"
              gutterBottom
            >
              {FormatMessage({ id: "Set006.Title" })}
            </Typography>
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
            >
              <Grid className="App-pLR5">
                <Button
                  variant="contained"
                  color="primary"
                  className="App-button_bottom"
                  startIcon={<ArrowBackIcon />}
                  onClick={backCilck}
                >
                  {FormatMessage({ id: "Set006.Button.back" })}
                </Button>
              </Grid>
              <Grid className="App-pLR5">
                <Button
                  variant="contained"
                  color="primary"
                  className="App-button_bottom"
                  startIcon={<CheckIcon />}
                  onClick={checkCilck}
                >
                  {FormatMessage({ id: "Button.Confirmation" })}
                </Button>
              </Grid>
            </Grid>
            <Grid className="App-box">
              <Grid container spacing={1} className="App-List">
                <Grid item xs={12} sm={4} className="item-width-edit">
                  {FormatMessage({ id: "Set.Label.ViewerId" })}
                </Grid>
                <Grid item xs={12} sm={8} className="item-padding">
                  {unescapeStr(userId)}
                </Grid>
              </Grid>
              {isPhone ? (
                <Grid
                  container
                  spacing={1}
                  className="App-List"
                  alignItems="center"
                >
                  <Grid item xs={12} sm={2} className="item-width-edit_Phone">
                    {FormatMessage({ id: "Set.Label.ViewerName" })}
                  </Grid>
                  <Grid item xs={12} sm={2} className="required_Phone">
                    {FormatMessage({ id: "Set006.Text.Required" })}
                  </Grid>
                  <Grid item xs={12} sm={8} className="item-padding">
                    <TextFieldBox
                      className="App-input-text"
                      id="outlined-basic"
                      value={unescapeStr(userName)}
                      variant="outlined"
                      error={userNameErr ? true : false}
                      helperText={
                        userNameErr
                          ? FormatMessage({
                              id: "Error.Message.RequiredError",
                              option: {
                                name: FormatMessage({
                                  id: "Set.Label.ViewerName",
                                }),
                              },
                            })
                          : ""
                      }
                      inputProps={{ maxLength: 256 }}
                      onChange={(e) => {
                        setUserName(e.target.value);
                      }}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              ) : (
                <Grid
                  container
                  spacing={1}
                  className="App-List"
                  alignItems="center"
                >
                  <Grid item xs={12} sm={4} className="item-width-edit">
                    {FormatMessage({ id: "Set.Label.ViewerName" })}
                    <span className="required_box">
                      {FormatMessage({ id: "Set006.Text.Required" })}
                    </span>
                  </Grid>
                  <Grid item xs={12} sm={8} className="item-padding">
                    <TextFieldBox
                      className="App-input-text"
                      id="outlined-basic"
                      value={unescapeStr(userName)}
                      variant="outlined"
                      error={userNameErr ? true : false}
                      helperText={
                        userNameErr
                          ? FormatMessage({
                              id: "Error.Message.RequiredError",
                              option: {
                                name: FormatMessage({
                                  id: "Set.Label.ViewerName",
                                }),
                              },
                            })
                          : ""
                      }
                      inputProps={{ maxLength: 256 }}
                      onChange={(e) => {
                        setUserName(e.target.value);
                      }}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              )}
              {isPhone ? (
                <Grid
                  container
                  spacing={1}
                  className="App-List"
                  alignItems="center"
                >
                  <Grid item xs={12} sm={2} className="item-width-edit_Phone">
                    {FormatMessage({ id: "Set.Label.HandleName" })}
                  </Grid>
                  <Grid item xs={12} sm={2} className="required_Phone">
                    {FormatMessage({ id: "Set006.Text.Required" })}
                  </Grid>
                  <Grid item xs={12} sm={8} className="item-padding">
                    <TextFieldBox
                      className="App-input-text"
                      id="outlined-basic"
                      value={unescapeStr(handleName)}
                      variant="outlined"
                      error={handleNameErr ? true : false}
                      helperText={
                        handleNameErr
                          ? FormatMessage({
                              id: "Error.Message.RequiredError",
                              option: {
                                name: FormatMessage({
                                  id: "Set.Label.HandleName",
                                }),
                              },
                            })
                          : ""
                      }
                      inputProps={{ maxLength: 32 }}
                      onChange={(e) => {
                        setHandleName(e.target.value);
                      }}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              ) : (
                <Grid
                  container
                  spacing={1}
                  className="App-List"
                  alignItems="center"
                >
                  <Grid item xs={12} sm={4} className="item-width-edit">
                    {FormatMessage({ id: "Set.Label.HandleName" })}
                    <span className="required_box">
                      {FormatMessage({ id: "Set006.Text.Required" })}
                    </span>
                  </Grid>
                  <Grid item xs={12} sm={8} className="item-padding">
                    <TextFieldBox
                      className="App-input-text"
                      id="outlined-basic"
                      value={unescapeStr(handleName)}
                      variant="outlined"
                      error={handleNameErr ? true : false}
                      helperText={
                        handleNameErr
                          ? FormatMessage({
                              id: "Error.Message.RequiredError",
                              option: {
                                name: FormatMessage({
                                  id: "Set.Label.HandleName",
                                }),
                              },
                            })
                          : ""
                      }
                      inputProps={{ maxLength: 32 }}
                      onChange={(e) => {
                        setHandleName(e.target.value);
                      }}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              )}
              <Grid
                container
                spacing={1}
                className="App-List"
                alignItems="center"
              >
                <Grid item xs={12} sm={4} className="item-width-edit">
                  {FormatMessage({ id: "Set.Label.MailAddress" })}
                </Grid>
                <Grid item xs={12} sm={8} className="item-padding">
                  <TextFieldBox
                    className="App-input-text"
                    id="outlined-basic"
                    value={unescapeStr(mailAddress)}
                    variant="outlined"
                    error={
                      textTypeErr || formatErr || duplicationErr ? true : false
                    }
                    helperText={
                      textTypeErr
                        ? FormatMessage({
                            id: "Error.Message.TypeError",
                            option: {
                              name: FormatMessage({
                                id: "Set.Label.MailAddress",
                              }),
                              type: "半角",
                            },
                          })
                        : formatErr
                        ? FormatMessage({
                            id: "Error.Message.FormatError",
                            option: {
                              name: FormatMessage({
                                id: "Set.Label.MailAddress",
                              }),
                            },
                          })
                        : duplicationErr
                        ? FormatMessage({ id: "Set.Text.DuplicationErr" })
                        : ""
                    }
                    inputProps={{ maxLength: 254 }}
                    onChange={(e) => {
                      setMailAddress(e.target.value);
                    }}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      {loading ? <LoadingBox /> : null}
    </MainLayout>
  );
};
