import { apigw } from "../awsApi";
import { Dispatch } from "@reduxjs/toolkit";

/**
 * 回答送信
 * @param {Dispatch} dispatch           reducerディスパッチ
 * @param {any} appData              登録データ配列
 * @return コンポーネントHTMLコード内容
 */

export const CQ0304 = async (dispatch: Dispatch, appData: any) => {
  if (process.env.REACT_APP_API_USE_FLG === "true") {
    return apigw(
      "/CQ0304",
      {
        enquete_answer: appData,
      },
      dispatch
    );
  } else {
    //サンプルデータ用
    return {
      state: "0",
      error_code: "",
      body: {},
    };
  }
};
