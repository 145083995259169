/* eslint-disable no-loop-func */
import React, { useMemo, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useDropzone } from "react-dropzone";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import GridList from "@material-ui/core/GridList";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { store } from "../../reducks/store";
import { LoadingBox } from "../../components/atoms/LoadingBox";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { FormatMessage } from "../../components/atoms/FormatMessage";
import { CT0307 } from "../../utils/api/CT0307";
// import { CT0308 } from "../../utils/api/CT0308";
import { CT0309 } from "../../utils/api/CT0309";
import { CT0303 } from "../../utils/api/CT0303";
import { CT0304 } from "../../utils/api/CT0304";
import { Cls010 } from "./010";
import "../../css/cls/cls001.css";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import ImageIcon from "@material-ui/icons/Image";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import SaveIcon from "@material-ui/icons/Save";
import imageCompression from "../../../node_modules/browser-image-compression";

//写真ドロップ用
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { ImgDropBox } from "./dropBoxImg";
// import { VdoDropBox } from "./dropBoxVdo";
import { PdfDropBox } from "./dropBoxPdf";

import {
  createS3,
  uploadS3,
  deleteS3,
  getFileS3,
  getUrlS3,
  getFileBase64S3,
  createJob,
} from "../../components/function/AwsS3Fun";

import { pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
      width: "100%",
      justifyContent: "space-around",
      overflow: "hidden",
      backgroundColor: theme.palette.background.paper,
    },
    gridList: {
      flexWrap: "nowrap",
      transform: "translateZ(0)",
    },
    title: {
      color: theme.palette.primary.light,
      padding: "10px",
      textAlign: "right",
    },
    titleBar: {
      background:
        "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
    },
    alignCenter: {
      textAlign: "center",
    },
    alignRight: {
      textAlign: "end",
    },
    floatleft: {
      float: "left",
    },
    mT20: {
      marginTop: "20px",
    },
    mB0Important: {
      marginBottom: "0 !important",
    },
    upload: {
      width: "65%",
      height: 170,
      margin: "20px auto",
      color: "#000",
      textAlign: "center",
      background: "#fff",
      border: "4px dashed #ccc;",
      padding: "10px",
      borderRadius: "4px",
      transition: "all .2s ease",
      outline: "none",
      textTransform: "uppercase",
      fontWeight: 700,
    },
    selected: {
      border: "5px solid rgb(137, 165, 229)",
    },
  })
);

/**
 * 資料アップロード
 * @param {object} props 引き継いだオブジェクト
 * @return コンポーネントHTMLコード内容
 */

// ベーススタイル
const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

//ドロップ可能な場合
const acceptStyle = {
  borderColor: "#afeeee",
  backgroundColor: "#e0ffff",
};

//ドロップ拒否の場合
const rejectStyle = {
  borderColor: "#ff7f50",
  backgroundColor: "#ffe4e1",
};
let dongList: number = 0;
// let checkVideoUrl: any;
let delFun: any;
let imgList: any = [],
  // videoList: any = [],
  pdfList: any = [];
let orderFileName = "order.json";
let orderList: any = {};

let fileNameCnt: number;
let returnFileName: any;

let imgNum: number = 0;
let pdfNum: number = 0;

export const Tab1 = (props: any) => {
  const { SetSavedImgList, SetNewdImgList, SetSavedPdfList, SetNewPdfList } =
    props;
  const FILE_FORMAT_ERROR = FormatMessage({ id: "Cls001.Err.Msg5" });
  const FILE_NUMBER_ERROR = FormatMessage({ id: "Cls001.Err.Msg7" });
  const FILE_NUMBER_ERROR50 = FormatMessage({ id: "Cls001.Err.Msg8" });
  const dispatch = useDispatch();
  const classId: string = props.class_id;
  const userIndo: any = store.getState().user;
  const filePath: string =
    "haishin/" + userIndo.service_id + "/" + classId + "/doc";
  const classes = useStyles();
  const [selectImg, setSelectImg] = React.useState(0);
  // const [selectVideo, setSelectVideo] = React.useState(0);
  const [selectPdf, setSelectPdf] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [errorMsgImgVideo, setErrorMsgImgVideo] = React.useState("");
  const [errorMsgPdf, setErrorMsgPdf] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [dialogText, setDialogText] = React.useState("");
  const [dialogTitle, setDialogTitle] = React.useState("");
  const [dialogCompletedText, setDialogCompletedText] = React.useState("");
  const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);
  const [openCompDialog, setOpenCompDialog] = React.useState(false);

  // 公開日設定画面表示
  const releaseDaySetCilck = () => {
    setOpen(true);
  };
  const releaseDayClose = () => {
    setOpen(false);
  };

  type imgItem = {
    board_document_id: string;
    board_document_name: string;
    s3_id: string;
    file_url: string;
  };

  // 画像ファイルデータ
  const [imageRows, setImgRows] = React.useState<imgItem[]>([]);

  // type videoItem = {
  //   video_id: string;
  //   video_name: string;
  //   s3_id: string;
  //   file_url: string;
  // };

  // 動画ファイルデータ
  // const [videoRows, setVdoRows] = React.useState<videoItem[]>([]);

  // type pdfItem = {
  //   download_document_id: string;
  //   download_document_name: string;
  //   s3_id: string;
  //   document_type: string;
  //   file_url: string;
  // };

  // // PDFファイルデータ
  // const [pdfRows, setPdfRows] = React.useState<pdfItem[]>([]);

  //ボード資料取得処理
  const getImagesData = async (callback: any) => {
    let getData = await CT0307(dispatch, classId);
    imgNum = 0;
    if (
      getData &&
      getData.state === "0" &&
      Object.keys(getData).indexOf("body") >= 0
    ) {
      let imgL = [];
      const S3 = await createS3();
      let list: any = getData.body;
      imgNum = list.length;
      if (orderList.imgOrder && orderList.imgOrder.length > 0) {
        for (let oi = 0; oi < orderList.imgOrder.length; oi++) {
          const odi = orderList.imgOrder[oi];
          for (let index = 0; index < list.length; index++) {
            const element = list[index];
            element.s3_id = element.s3_id.replace("/pictuire/", "/thumbnail/");
            if (odi === Number(element.board_document_id)) {
              const file_url =
                element.s3_id && element.s3_id !== ""
                  ? await getUrlS3(S3, element.s3_id)
                  : null;
              element.file_url = file_url;
              list.splice(index, 1);
              imgL.push(element);
              break;
            }
          }
        }
        if (list.length > 0) {
          for (let index = 0; index < list.length; index++) {
            const element = list[index];
            element.s3_id = element.s3_id.replace("/pictuire/", "/thumbnail/");
            const file_url =
              element.s3_id && element.s3_id !== ""
                ? await getUrlS3(S3, element.s3_id)
                : null;
            element.file_url = file_url;
            imgL.push(element);
          }
        }
      } else {
        for (let index = 0; index < list.length; index++) {
          const element = list[index];
          element.s3_id = element.s3_id.replace("/pictuire/", "/thumbnail/");
          const file_url =
            element.s3_id && element.s3_id !== ""
              ? await getUrlS3(S3, element.s3_id)
              : null;
          element.file_url = file_url;
          imgL.push(element);
        }
      }

      setImgRows(imgL);
      imgList = imgL;
      SetSavedImgList(JSON.stringify(setImgOrderList(imgL)));
      SetNewdImgList(JSON.stringify(setImgOrderList(imgL)));
    }
    if (callback) callback();
  };

  // 配布資料取得処理
  const getPdfsData = async (callback: any) => {
    let getData = await CT0309(dispatch, classId);
    if (
      getData &&
      getData.state === "0" &&
      Object.keys(getData).indexOf("body") >= 0
    ) {
      const S3 = await createS3();
      if (S3) {
        let pdfL = [];
        let list = getData.body;
        pdfNum = list.length;
        if (orderList.pdfOrder && orderList.pdfOrder.length > 0) {
          for (let oi = 0; oi < orderList.pdfOrder.length; oi++) {
            const odi = orderList.pdfOrder[oi];
            for (let index = 0; index < list.length; index++) {
              const element = list[index];
              if (odi === Number(element.download_document_id)) {
                const file_url =
                  element.s3_id && element.s3_id !== ""
                    ? await getFileBase64S3(S3, element.s3_id)
                    : null;
                element.document_type === "3"
                  ? (element.file_url = file_url
                      ? String(file_url).replace(
                          "data:application/octet-stream;",
                          "data:application/pdf;"
                        )
                      : null)
                  : (element.file_url = file_url);
                list.splice(index, 1);
                pdfL.push(element);
                break;
              }
            }
          }
          if (list.length > 0) {
            for (let index = 0; index < list.length; index++) {
              const element = list[index];
              const file_url =
                element.s3_id && element.s3_id !== ""
                  ? await getFileBase64S3(S3, element.s3_id)
                  : null;
              element.document_type === "3"
                ? (element.file_url = file_url
                    ? String(file_url).replace(
                        "data:application/octet-stream;",
                        "data:application/pdf;"
                      )
                    : null)
                : (element.file_url = file_url);
              pdfL.push(element);
            }
          }
        } else {
          for (let index = 0; index < getData.body.length; index++) {
            const element = getData.body[index];
            const file_url =
              element.s3_id && element.s3_id !== ""
                ? await getFileBase64S3(S3, element.s3_id)
                : null;
            element.document_type === "3"
              ? (element.file_url = file_url
                  ? String(file_url).replace(
                      "data:application/octet-stream;",
                      "data:application/pdf;"
                    )
                  : null)
              : (element.file_url = file_url);
            pdfL.push(element);
          }
        }
        pdfList = pdfL;
        SetSavedPdfList(JSON.stringify(setPdfOrderList(pdfL)));
        SetNewPdfList(JSON.stringify(setPdfOrderList(pdfL)));
      }
    }
    if (callback) callback();
  };

  const closeLoading = () => {
    dongList++;
    if (dongList === 2) setLoading(false);
  };

  const getOrderList = async (callback: any) => {
    const S3 = await createS3();
    if (S3) {
      let file: any = await getFileS3(S3, filePath + "/" + orderFileName);
      if (file && file.size > 0) {
        let reader = new FileReader();
        reader.readAsText(file);
        reader.onload = function () {
          if (reader.result) {
            orderList = JSON.parse(String(reader.result));
            if (callback) callback();
          } else {
            if (callback) callback();
          }
        };
      } else {
        if (callback) callback();
      }
    } else {
      if (callback) callback();
    }
  };

  //初期処理
  useEffect(() => {
    imgNum = 0;
    pdfNum = 0;
    imgList = [];
    // videoList = [];
    pdfList = [];
    dongList = 0;
    orderList = {};
    fileNameCnt = 0;
    setLoading(true);
    getOrderList(() => {
      //引数から授業ID設定
      getImagesData(() => closeLoading());
      getPdfsData(() => closeLoading());
    });
    setTimeout(() => setLoading(false), 5000);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  const createJpegFile = (base64: any, fileName: any) => {
    var bin = atob(base64.replace(/^.*,/, ""));
    var buffer = new Uint8Array(bin.length);
    for (var i = 0; i < bin.length; i++) {
      buffer[i] = bin.charCodeAt(i);
    }
    return new File([buffer.buffer], fileName, { type: "image/avif" });
  };

  const readPdfFile = async (file: any) => {
    const loadingTask = pdfjs.getDocument({
      url: URL.createObjectURL(file),
      cMapUrl: "https://cdn.jsdelivr.net/npm/pdfjs-dist@2.0.385/cmaps/",
      cMapPacked: true,
    });
    let loadedPdf: any = await loadingTask.promise;
    const pdfNum: number = Number(loadedPdf._pdfInfo.numPages);
    let checkedFileName: any;
    if (pdfNum + imgNum < 501) {
      for (let index = 1; index <= pdfNum; index++) {
        let page = await loadedPdf.getPage(index);
        let pageWidth = page._pageInfo.view[2] - page._pageInfo.view[0];
        let pageHeight = page._pageInfo.view[3] - page._pageInfo.view[1];
        let pegeFlg = pageWidth > 1500 || pageHeight > 1500 ? true : false;
        for (let i = 0; i < 2; i++) {
          var viewport = page.getViewport({
            scale: i === 0 ? (pegeFlg ? 1 : 2) : 1,
          });
          var canvas = document.createElement("canvas");
          var ctx = canvas.getContext("2d");
          var renderContext: any = {
            canvasContext: ctx,
            viewport: viewport,
          };
          canvas.height = viewport.height;
          canvas.width = viewport.width;
          let show_pdf = document.getElementById("show_pdf");
          if (show_pdf) {
            show_pdf.appendChild(canvas);
            await page.render(renderContext).promise;
            const base64 = canvas.toDataURL("image/avif");
            if (i === 0) {
              let fileNane = file.name.slice(0, -4) + ".avif";
              if (pdfNum > 1) {
                if (index === 1) {
                  checkPdfToImgFileName(fileNane, pdfNum);
                  fileNane = fileNameCnt > 0 ? returnFileName : fileNane;
                  checkedFileName =
                    fileNane.slice(0, -5) + "_(" + index + ").avif";
                } else {
                  checkedFileName =
                    checkedFileName.slice(
                      0,
                      -(Number(String(index - 1).length) + 6)
                    ) +
                    index +
                    ").avif";
                }
                fileNameCnt = 0;
              } else {
                checkImgFileName(fileNane);
                checkedFileName = fileNameCnt > 0 ? returnFileName : fileNane;
                fileNameCnt = 0;
              }
            }
            let uploadFile = createJpegFile(base64, checkedFileName);
            if (show_pdf) show_pdf.innerHTML = "";
            if (i === 0) {
              if (uploadFile.size > 3800000) {
                const resizeFie = await resizeImg(uploadFile, 3.8, 3000);
                await uploadImgData(resizeFie);
              } else {
                await uploadImgData(uploadFile);
              }
            } else {
              const thumbnail = await resizeImg(uploadFile, 1, 200);
              await uploadThumbnailData(thumbnail);
            }
          }
        }
      }
      return true;
    } else {
      setErrorMsgImgVideo(FILE_NUMBER_ERROR);
      return false;
    }
  };

  const uploadImgData = async (files: any, checkedFileName?: any) => {
    let S3: any = await createS3();
    if (S3) {
      let paraFilename = checkedFileName ? checkedFileName : files.name;
      let filename = macNFC(paraFilename);
      let upload = await uploadS3(
        S3,
        filePath + "/pictuire/" + filename,
        files
      );
      if (upload) {
        let result = await CT0303(
          dispatch,
          classId, //授業ID
          filename, //ボード資料名
          "1", //ボード資料種類(1:画像、2:動画)
          "", //動画ファイル名
          "", //ダウンロード資料名
          "1", //ダウンロード資料種類(1:画像、2:動画、3:PDF、4:音声)
          "1", //資料区分(1：ボード 2：動画 3：ダウンロード 4：録画)
          "", //動画区分(録画動画用)
          filePath + "/pictuire/" + filename //S3id
        );
        if (result && result.state === "0") {
          let file_url: any = await getUrlS3(
            S3,

            filePath + "/pictuire/" + filename
          );
          imgList.push({
            board_document_id: "",
            board_document_name: filename,
            s3_id: filePath + "/pictuire/" + filename,
            file_url: String(file_url),
          });
          imgNum++;
        } else {
          await deleteS3(S3, filePath + "/pictuire/" + filename);
        }
      }
    }
  };
  const uploadThumbnailData = async (files: any, checkedFileName?: any) => {
    let S3: any = await createS3();
    if (S3) {
      let paraFilename = checkedFileName ? checkedFileName : files.name;
      let filename = macNFC(paraFilename);
      await uploadS3(S3, filePath + "/thumbnail/" + filename, files);
    }
  };

  const resizeImg = async (
    file: any,
    maxSize: number,
    maxWidthHight: number
  ) => {
    const options = {
      maxSizeMB: maxSize, // 最大ファイルサイズ
      maxWidthOrHeight: maxWidthHight, // 最大画像幅もしくは高さ
    };

    // リサイズ画像の生成
    const resizeFile = await imageCompression(file, options);
    return resizeFile;
  };

  const sortNumBlock = (a: any, b: any) => {
    const sa = String(a.name).replace(/(\d+)/g, (m) => m.padStart(30, "0"));
    const sb = String(b.name).replace(/(\d+)/g, (m) => m.padStart(30, "0"));
    return sa < sb ? -1 : sa > sb ? 1 : 0;
  };

  // ドロップ時処理
  const onDrop1 = useCallback(async (acceptedFiles: File[]) => {
    // スライド資料ドロップエリア
    setErrorMsgImgVideo("");
    setErrorMsgPdf("");
    setLoading(true);
    let imgChangFlg: any = false;
    // let videoChangFlg = false;

    if (imgNum < 501 && acceptedFiles.length + imgNum < 501) {
      // 資料情報登録処理
      const sortList = acceptedFiles.sort(sortNumBlock);
      for (let index = 0; index < sortList.length; index++) {
        const files: any = sortList[index];

        switch (files.type) {
          case "image/png":
          case "image/jpg":
          case "image/jpeg":
          case "image/bmp":
          case "image/avif":
            checkImgFileName(files.name);
            const checkedFileName =
              fileNameCnt > 0 ? returnFileName : files.name;
            fileNameCnt = 0;
            if (files.size > 3800000) {
              const resizeFie = await resizeImg(files, 3.8, 3000);
              await uploadImgData(resizeFie);
            } else {
              await uploadImgData(files, checkedFileName);
            }
            const thumbnail = await resizeImg(files, 1, 200);
            await uploadThumbnailData(thumbnail, checkedFileName);
            //await uploadThumbnailData(files, checkedFileName);
            imgChangFlg = true;
            break;
          case "application/pdf":
            imgChangFlg = await readPdfFile(files);
            break;
          default:
            setErrorMsgImgVideo(FILE_FORMAT_ERROR);
            setLoading(false);
            break;
        }
      }
    } else {
      setErrorMsgImgVideo(FILE_NUMBER_ERROR);
    }
    if (imgChangFlg) {
      setImgRows([]);
      getImagesData(() => {
        saveOrderData();
        setLoading(false);
      });
    } else {
      setLoading(false);
    }

    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  const checkImgFileName = (fileName: any) => {
    returnFileName = fileName;
    if (imgList.length > 0) {
      for (let e = 0; e < imgList.length; e++) {
        if (imgList[e].board_document_name === fileName) {
          if (fileNameCnt > 0) {
            let reCutLength: number;
            let reExtension = ".";
            reCutLength = Number(String(fileNameCnt).length);
            fileNameCnt++;
            switch (fileName.slice(-2)) {
              case "ng":
                reCutLength += 4;
                reExtension += "png";
                break;
              case "pg":
                reCutLength += 4;
                reExtension += "jpg";
                break;
              case "mp":
                reCutLength += 4;
                reExtension += "bmp";
                break;
              case "eg":
                reCutLength += 5;
                reExtension += "jpeg";
                break;
              case "if":
                reCutLength += 5;
                reExtension += "avif";
                break;
            }
            let reNewFileName = fileName.slice(0, -reCutLength);
            reNewFileName = reNewFileName + fileNameCnt + reExtension;
            returnFileName = reNewFileName;
            checkImgFileName(reNewFileName);
          } else {
            let cutLength = 0;
            let extension = ".";
            fileNameCnt++;
            switch (fileName.slice(-2)) {
              case "ng":
                cutLength = 4;
                extension += "png";
                break;
              case "pg":
                cutLength = 4;
                extension += "jpg";
                break;
              case "mp":
                cutLength = 4;
                extension += "bmp";
                break;
              case "eg":
                cutLength += 5;
                extension += "jpeg";
                break;
              case "if":
                cutLength += 5;
                extension += "avif";
                break;
            }
            let newFileName = fileName.slice(0, -cutLength);
            newFileName = newFileName + "_" + fileNameCnt + extension;
            returnFileName = newFileName;
            checkImgFileName(newFileName);
          }
        }
      }
    }
  };

  const checkPdfToImgFileName = (fileName: any, totalPage: any) => {
    returnFileName = fileName;
    let existFlg = false;
    if (imgList.length > 0) {
      for (let e = 0; e < imgList.length; e++) {
        if (imgList[e].board_document_name === fileName) {
          existFlg = true;
          if (fileNameCnt > 0) {
            let reCutLength: number;
            reCutLength = Number(String(fileNameCnt).length) + 4;
            fileNameCnt++;
            let reNewFileName = fileName.slice(0, -reCutLength);
            reNewFileName = reNewFileName + fileNameCnt + ".avif";
            returnFileName = reNewFileName;
            checkPdfToImgFileName(reNewFileName, totalPage);
          } else {
            fileNameCnt++;
            let newFileName = fileName.slice(0, -5);
            newFileName = newFileName + "_" + fileNameCnt + ".avif";
            returnFileName = newFileName;
            checkPdfToImgFileName(newFileName, totalPage);
          }
        }
      }
      if (!existFlg) {
        let pdfsFileName = returnFileName;
        let chkFlg = false;
        for (let n = 0; n < imgList.length; n++) {
          for (let p = 1; p <= totalPage; p++) {
            if (
              imgList[n].board_document_name ===
              pdfsFileName.slice(0, -5) + "_(" + p + ").avif"
            ) {
              chkFlg = true;
            }
          }
        }
        if (chkFlg) {
          if (fileNameCnt > 0) {
            fileNameCnt++;
            checkPdfToImgFileName(
              pdfsFileName.slice(
                0,
                -(Number(String(fileNameCnt - 1).length) + 4)
              ) +
                fileNameCnt +
                ".avif",
              totalPage
            );
          } else {
            fileNameCnt++;
            checkPdfToImgFileName(
              pdfsFileName.slice(0, -5) + "_" + fileNameCnt + ".avif",
              totalPage
            );
          }
        }
      }
    }
  };

  const onDrop2 = useCallback(async (acceptedFiles: File[]) => {
    // 配布資料ドロップエリア用
    setErrorMsgImgVideo("");
    setErrorMsgPdf("");
    setLoading(true);
    // 資料情報登録処理
    if (pdfNum < 51 && acceptedFiles.length + pdfNum < 51) {
      const sortList = acceptedFiles.sort(sortNumBlock);
      for (let index = 0; index < sortList.length; index++) {
        const files: any = sortList[index];
        switch (files.type) {
          case "image/png":
          case "image/jpg":
          case "image/jpeg":
          case "image/bmp":
          case "image/avif":
            await uploadDlImg(files, files.name);
            break;
          case "video/mp4":
          case "video/x-ms-wmv":
          case "video/quicktime":
            await uploadDlVdo(files);
            break;
          case "application/pdf":
            await uploadDlPdf(files);
            break;
          case "audio/mpeg":
          case "audio/vnd.dlna.adts":
            await uploadDlAudio(files, files.name);
            break;
          default:
            setErrorMsgPdf(FILE_FORMAT_ERROR);
            break;
        }
      }
      pdfList = [];
      getPdfsData(() => setLoading(false));
      saveOrderData();
    } else {
      setErrorMsgPdf(FILE_NUMBER_ERROR50);
      setLoading(false);
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  const checkDlImgFileName = (fileName: any) => {
    returnFileName = fileName;
    if (pdfList.length > 0) {
      for (let e = 0; e < pdfList.length; e++) {
        if (pdfList[e].download_document_name === fileName) {
          if (fileNameCnt > 0) {
            let reCutLength: number;
            let reExtension = ".";
            reCutLength = Number(String(fileNameCnt).length);
            fileNameCnt++;
            switch (fileName.slice(-2)) {
              case "ng":
                reCutLength += 4;
                reExtension += "png";
                break;
              case "pg":
                reCutLength += 4;
                reExtension += "jpg";
                break;
              case "mp":
                reCutLength += 4;
                reExtension += "bmp";
                break;
              case "eg":
                reCutLength += 5;
                reExtension += "jpeg";
                break;
              case "if":
                reCutLength += 5;
                reExtension += "avif";
                break;
            }
            let reNewFileName = fileName.slice(0, -reCutLength);
            reNewFileName = reNewFileName + fileNameCnt + reExtension;
            returnFileName = reNewFileName;
            checkDlImgFileName(reNewFileName);
          } else {
            let cutLength = 0;
            let extension = ".";
            fileNameCnt++;
            switch (fileName.slice(-2)) {
              case "ng":
                cutLength = 4;
                extension += "png";
                break;
              case "pg":
                cutLength = 4;
                extension += "jpg";
                break;
              case "mp":
                cutLength = 4;
                extension += "bmp";
                break;
              case "eg":
                cutLength += 5;
                extension += "jpeg";
                break;
              case "if":
                cutLength += 5;
                extension += "avif";
                break;
            }
            let newFileName = fileName.slice(0, -cutLength);
            newFileName = newFileName + "_" + fileNameCnt + extension;
            returnFileName = newFileName;
            checkDlImgFileName(newFileName);
          }
        }
      }
    }
  };

  const checkDlFileName = (fileName: any) => {
    returnFileName = fileName;
    if (pdfList.length > 0) {
      for (let e = 0; e < pdfList.length; e++) {
        if (pdfList[e].download_document_name === fileName) {
          if (fileNameCnt > 0) {
            let reExtension = ".";
            fileNameCnt++;
            switch (fileName.slice(-3)) {
              case "mp4":
                reExtension += "mp4";
                break;
              case "pdf":
                reExtension += "pdf";
                break;
            }
            let reNewFileName = fileName.slice(0, -4);
            reNewFileName = reNewFileName + fileNameCnt + reExtension;
            returnFileName = reNewFileName;
            checkDlFileName(reNewFileName);
          } else {
            let cutLength = 0;
            let extension = ".";
            fileNameCnt++;
            switch (fileName.slice(-3)) {
              case "mp4":
                cutLength = 4;
                extension += "mp4";
                break;
              case "pdf":
                cutLength = 4;
                extension += "pdf";
                break;
            }
            let newFileName = fileName.slice(0, -cutLength);
            newFileName = newFileName + "_" + fileNameCnt + extension;
            returnFileName = newFileName;
            checkDlFileName(newFileName);
          }
        }
      }
    }
  };

  const uploadDlImg = async (files: any, checkedFileName?: any) => {
    let S3: any = await createS3();
    if (S3) {
      let filename = macNFC(checkedFileName);
      let upload = await uploadS3(
        S3,
        filePath + "/download/" + filename,
        files
      );
      if (upload) {
        let result = await CT0303(
          dispatch,
          classId, //授業ID
          "", //ボード資料名
          "", //ボード資料種類(1:画像、2:動画)
          "", //動画ファイル名
          filename, //ダウンロード資料名
          "1", //ダウンロード資料種類(1:画像、2:動画、3:PDF、4:音声)
          "3", //資料区分(1：ボード 2：動画 3：ダウンロード 4：録画)
          "", //動画区分(録画動画用)
          filePath + "/download/" + filename //S3id
        );
        if (result && result.state === "0") {
          pdfNum++;
        } else {
          await deleteS3(S3, filePath + "/download/" + filename);
        }
      }
    }
  };

  const uploadDlVdo = async (files: any) => {
    let S3: any = await createS3();
    if (S3) {
      let filename = macNFC(files.name);
      let newFileName =
        files.type !== "video/mp4"
          ? filename.substr(0, filename.length - 4) + ".mp4"
          : filename;
      checkDlFileName(newFileName);
      const checkedFileName = fileNameCnt > 0 ? returnFileName : filename;
      fileNameCnt = 0;
      let upload = await uploadS3(
        S3,
        filePath + "/download/" + checkedFileName,
        files
      );
      if (upload) {
        let result = await CT0303(
          dispatch,
          classId, //授業ID
          "", //ボード資料名
          "", //ボード資料種類(1:画像、2:動画)
          "", //動画ファイル名
          checkedFileName, //ダウンロード資料名
          "2", //ダウンロード資料種類(1:画像、2:動画、3:PDF、4:音声)
          "3", //資料区分(1：ボード 2：動画 3：ダウンロード 4：録画)
          "", //動画区分(録画動画用)
          filePath + "/download/" + checkedFileName //S3id
        );
        if (result && result.state === "0") {
          if (files.type !== "video/mp4") {
            await createJob(filePath + "/download/", checkedFileName);
          }
          pdfNum++;
        }
      }
    }
  };

  const uploadDlAudio = async (files: any, checkedFileName?: any) => {
    let S3: any = await createS3();
    if (S3) {
      let filename = macNFC(checkedFileName);
      let upload = await uploadS3(
        S3,
        filePath + "/download/" + filename,
        files
      );
      if (upload) {
        let result = await CT0303(
          dispatch,
          classId, //授業ID
          "", //ボード資料名
          "", //ボード資料種類(1:画像、2:動画)
          "", //動画ファイル名
          filename, //ダウンロード資料名
          "4", //ダウンロード資料種類(1:画像、2:動画、3:PDF、4:音声)
          "3", //資料区分(1：ボード 2：動画 3：ダウンロード 4：録画)
          "", //動画区分(録画動画用)
          filePath + "/download/" + filename //S3id
        );
        if (result && result.state === "0") {
          pdfNum++;
        } else {
          await deleteS3(S3, filePath + "/download/" + filename);
        }
      }
    }
  };

  const macNFC = (name: any) => {
    var myReg =
      /[^\u{0340}\u{0341}\u{0343}\u{0344}\u{0374}\u{037E}\u{0387}\u{0958}-\u{095F}\u{09DC}\u{09DD}\u{09DF}\u{0A33}\u{0A36}\u{0A59}-\u{0A5B}\u{0A5E}\u{0B5C}\u{0B5D}\u{0F43}\u{0F4D}\u{0F52}\u{0F57}\u{0F5C}\u{0F69}\u{0F73}\u{0F75}\u{0F76}\u{0F78}\u{0F81}\u{0F93}\u{0F9D}\u{0FA2}\u{0FA7}\u{0FAC}\u{0FB9}\u{1F71}\u{1F73}\u{1F75}\u{1F77}\u{1F79}\u{1F7B}\u{1F7D}\u{1FBB}\u{1FBE}\u{1FC9}\u{1FCB}\u{1FD3}\u{1FDB}\u{1FE3}\u{1FEB}\u{1FEE}\u{1FEF}\u{1FF9}\u{1FFB}\u{1FFD}\u{2000}\u{2001}\u{2126}\u{212A}\u{212B}\u{2329}\u{232A}\u{2ADC}\u{F900}-\u{FAFF}\u{FB1D}\u{FB1F}\u{FB2A}-\u{FB36}\u{FB38}-\u{FB3C}\u{FB3E}\u{FB40}\u{FB41}\u{FB43}\u{FB44}\u{FB46}-\u{FB4E}\u{1D15E}-\u{1D164}\u{1D1BB}-\u{1D1C0}\u{2F800}-\u{2FA1F}]+/gu;
    if (name.normalize)
      name = name.replace(myReg, function (r: any) {
        return r.normalize("NFC");
      });
    return name; //.normalize("NFC");;
  };

  const uploadDlPdf = async (files: any) => {
    let S3: any = await createS3();
    if (S3) {
      let filename = macNFC(files.name);
      checkDlFileName(filename);
      const checkedFileName = fileNameCnt > 0 ? returnFileName : filename;
      fileNameCnt = 0;

      let upload = await uploadS3(
        S3,
        filePath + "/download/" + checkedFileName,
        files
      );

      if (upload) {
        let result = await CT0303(
          dispatch,
          classId, //授業ID
          "", //ボード資料名
          "", //ボード資料種類(1:画像、2:動画)
          "", //動画ファイル名
          checkedFileName, //ダウンロード資料名
          "3", //ダウンロード資料種類(1:画像、2:動画、3:PDF、4:音声)
          "3", //資料区分(1：ボード 2：動画 3：ダウンロード 4：録画)
          "", //動画区分(録画動画用)
          filePath + "/download/" + checkedFileName //S3id
        );
        if (result && result.state === "0") {
          pdfNum++;
        }
      }
    }
  };

  // DropZone
  const {
    // acceptedFiles: acceptedFiles1,
    getRootProps: getRootProps1,
    getInputProps: getInputProps1,
    isDragAccept: isDragAccept1,
    isDragReject: isDragReject1,
    open: open1,
  } = useDropzone({
    onDrop: onDrop1,
    // accept: "image/png,image/jpg,image/jpeg,image/bmb,video/mp4",
    noClick: true,
  });

  // DropZone
  const {
    // acceptedFiles: acceptedFiles2,
    getRootProps: getRootProps2,
    getInputProps: getInputProps2,
    isDragAccept: isDragAccept2,
    isDragReject: isDragReject2,
    open: open2,
  } = useDropzone({
    onDrop: onDrop2,
    // accept: ".pdf",
    noClick: true,
  });

  const filedropstyle1 = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragAccept1 ? acceptStyle : {}),
      ...(isDragReject1 ? rejectStyle : {}),
    }),
    [isDragReject1, isDragAccept1]
  );

  const filedropstyle2 = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragAccept2 ? acceptStyle : {}),
      ...(isDragReject2 ? rejectStyle : {}),
    }),
    [isDragReject1, isDragAccept1]
  );

  const imgDelMsg = FormatMessage({
    id: "Cls001.Dialog.text1",
    option: { name: FormatMessage({ id: "Cls001.Title.text1" }) },
  });
  // 画像削除
  const deleteImgFile = () => {
    setDialogText(imgDelMsg);
    setOpenDialog(true);
    delFun = async () => {
      setLoading(true);
      let delImg: any = imgList[selectImg];
      let result = await CT0304(
        dispatch,
        "1",
        null,
        delImg.board_document_id,
        null,
        null
      );
      if (result && result.state === "0") {
        let S3: any = await createS3();
        const pictureS3Id = filePath + "/pictuire/" + delImg.board_document_name;
        await deleteS3(S3, pictureS3Id);
        const thumbnail = filePath + "/thumbnail/" + delImg.board_document_name;
        await deleteS3(S3, thumbnail);
        imgList.splice(selectImg, 1);
        setTimeout(() => setImgRows(imgList), 200);
        setImgRows([]);
        saveOrderData();
        imgNum--;
      }
      setLoading(false);
    };
  };
  const imgAllDelMsg = FormatMessage({
    id: "Cls001.Dialog.text2",
    option: { name: FormatMessage({ id: "Cls001.Title.text1" }) },
  });
  // 画像全件削除
  const deleteAllImgFile = () => {
    setDialogText(imgAllDelMsg);
    setOpenDialog(true);
    delFun = async () => {
      setLoading(true);
      let result = await CT0304(dispatch, "4", classId, null, null, null);
      if (result && result.state === "0") {
        if (imgList && imgList.length > 0) {
          let S3: any = await createS3();
          for (let index = 0; index < imgList.length; index++) {
            const pictureS3Id =
              filePath + "/pictuire/" + imgList[index].board_document_name;
            await deleteS3(S3, pictureS3Id);
            const thumbnail =
              filePath + "/thumbnail/" + imgList[index].board_document_name;
            await deleteS3(S3, thumbnail);
          }
          imgList = [];
          setImgRows([]);
          saveOrderData();
          imgNum = 0;
        }
      }
      setLoading(false);
    };
  };
  const pdfDelMsg = FormatMessage({
    id: "Cls001.Dialog.text1",
    option: { name: FormatMessage({ id: "Cls001.Title.text3" }) },
  });
  // PDF削除
  const deletePdfFile = async () => {
    setDialogText(pdfDelMsg);
    setOpenDialog(true);
    delFun = async () => {
      setLoading(true);
      let delPdf: any = pdfList[selectPdf];
      let result = await CT0304(
        dispatch,
        "3",
        null,
        null,
        null,
        delPdf.download_document_id
      );
      if (result && result.state === "0") {
        let S3: any = await createS3();
        await deleteS3(S3, delPdf.s3_id);
        let List = pdfList;
        List.splice(selectPdf, 1);
        setTimeout(() => pdfList = List, 200);
        pdfList = [];
        saveOrderData();
        pdfNum--;
      }
      setLoading(false);
    };
  };
  const pdfAllDelMsg = FormatMessage({
    id: "Cls001.Dialog.text2",
    option: { name: FormatMessage({ id: "Cls001.Title.text3" }) },
  });
  // PDF全件削除
  const deleteAllPdfFile = async () => {
    setDialogText(pdfAllDelMsg);
    setOpenDialog(true);
    delFun = async () => {
      setLoading(true);
      let result = await CT0304(dispatch, "6", classId, null, null, null);
      if (result && result.state === "0") {
        let S3: any = await createS3();
        for (let index = 0; index < pdfList.length; index++) {
          await deleteS3(S3, pdfList[index].s3_id);
        }
        pdfList = [];
        saveOrderData();
        pdfNum = 0;
      }
      setLoading(false);
    };
  };

  const handleClose = () => {
    delFun = null;
    setOpenDialog(false);
  };

  const CompDelTitle = FormatMessage({ id: "Cls001.Dialog.title2" });
  const CompEditTitle = FormatMessage({ id: "Cls001.Dialog.title4" });
  const CompDelMsg = FormatMessage({ id: "Cls001.Dialog.text4" });
  const CompEditMsg = FormatMessage({ id: "Cls001.Dialog.text6" });

  const clickDelete = async () => {
    setOpenDialog(false);
    if (delFun) await delFun();
    setDialogTitle(CompDelTitle);
    setDialogCompletedText(CompDelMsg);
    setOpenCompDialog(true);
  };

  //編集保存確認ダイアログ
  const handleCloseConfirm = () => {
    setOpenConfirmDialog(false);
  };

  const clickSave = async () => {
    await saveOrderData();
    setDialogTitle(CompEditTitle);
    setDialogCompletedText(CompEditMsg);
    setOpenConfirmDialog(false);
    setOpenCompDialog(true);
  };

  //編集保存ボタン押下時
  const ClickSaveOrderData = () => {
    setOpenConfirmDialog(true);
  };

  //完了ダイアログ
  const handleCloseComp = () => {
    setOpenCompDialog(false);
  };

  //編集保存処理
  const saveOrderData = async () => {
    let data: any = {};
    let imgOrder = [];
    for (let index = 0; index < imgList.length; index++) {
      imgOrder.push(imgList[index].board_document_id);
      if (index === 0) data.fristImgName = imgList[index].board_document_name;
    }
    // let videoOrder = [];
    // for (let index = 0; index < videoList.length; index++) {
    //   videoOrder.push(videoList[index].video_id);
    // }
    let pdfOrder = [];
    for (let index = 0; index < pdfList.length; index++) {
      pdfOrder.push(pdfList[index].download_document_id);
    }
    data.imgOrder = imgOrder;
    // data.videoOrder = videoOrder;
    data.pdfOrder = pdfOrder;
    let jsonData = JSON.stringify(data);
    var blob = new Blob([jsonData], { type: "text/json" });
    var jsonFile = new File([blob], orderFileName);
    const S3: any = await createS3();
    if (S3) {
      await uploadS3(S3, filePath + "/" + orderFileName, jsonFile);
    }
    SetSavedImgList(JSON.stringify(imgOrder));
    SetNewdImgList(JSON.stringify(imgOrder));
    SetSavedPdfList(JSON.stringify(pdfOrder));
    SetNewPdfList(JSON.stringify(pdfOrder));
  };

  //並び順比較用
  const setImgOrderList = (list: any[]) => {
    let OrderList = [];
    for (let index = 0; index < list.length; index++) {
      OrderList.push(list[index].board_document_id);
    }
    return OrderList;
  };
  //並び順比較用
  const setPdfOrderList = (list: any[]) => {
    let OrderList = [];
    for (let index = 0; index < list.length; index++) {
      OrderList.push(list[index].download_document_id);
    }
    return OrderList;
  };

  return (
    <div className={classes.root}>
      {/* 画像資料一覧 */}
      <Grid container spacing={3}>
        <Grid item xs>
          <Typography variant="body1" className={classes.title}>
            {FormatMessage({ id: "Cls001.Title.text1" })}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <GridList className={classes.gridList} cols={2.5}>
            <DndProvider backend={HTML5Backend}>
              {imageRows.length > 0 ? (
                <ImgDropBox
                  imgList={imageRows}
                  callback={(flg: string, data: any) => {
                    if (flg === "1") {
                      setSelectImg(data);
                    }
                    if (flg === "2") {
                      imgList = data;
                      SetNewdImgList(JSON.stringify(setImgOrderList(data)));
                    }
                  }}
                />
              ) : null}
            </DndProvider>
          </GridList>
        </Grid>

        {/* 画像削除ボタン */}
        <Grid item xs>
          <Grid item xs className="App-pTB5">
            <Button
              variant="contained"
              color="default"
              startIcon={<DeleteForeverIcon />}
              onClick={() => {
                deleteImgFile();
              }}
            >
              {FormatMessage({ id: "Cls001.Button.text2" })}
            </Button>
          </Grid>
          <Grid item xs className="App-pTB5">
            <Button
              variant="contained"
              color="default"
              startIcon={<DeleteForeverIcon />}
              onClick={() => {
                deleteAllImgFile();
              }}
            >
              {FormatMessage({ id: "Cls001.Button.text3" })}
            </Button>
          </Grid>
          <Grid item xs className="App-pTB5">
            <Button
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              onClick={() => {
                ClickSaveOrderData();
              }}
            >
              {FormatMessage({ id: "Cls001.Button.text8" })}
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={0}>
        <Grid item xs={12} className="">
          {/* スライド資料ドロップエリア */}
          <div
            {...getRootProps1({ className: "dropzone", filedropstyle1 })}
            className={
              classes.mB0Important + " App-attachment-box App-attachment"
            }
          >
            <section className="container">
              <input {...getInputProps1()} multiple={true} />
              <div>
                {/* <input {...getInputProps()} multiple={true} /> */}
                <p>{FormatMessage({ id: "Cls001.Area.text1" })}</p>
                <p>{FormatMessage({ id: "Cls001.Area.text2" })}</p>
              </div>
              <div className="App-attachment" onClick={open1}>
                <label>
                  {/* <input {...getInputProps()} multiple={true} /> */}
                  {
                    <ImageIcon
                      style={{ verticalAlign: "bottom", fontSize: 19 + "px" }}
                    />
                  }
                  {FormatMessage({ id: "Cls001.Button.text4" })}
                </label>
              </div>
            </section>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        <Grid item xs></Grid>
        <Grid item xs={8} className="App-mL20 App-mB20">
          <div>
            <span className="App-Color-Red">{errorMsgImgVideo}</span>
          </div>
        </Grid>
        <Grid item xs></Grid>
      </Grid>

      {/* 配布資料一覧 */}
      <Grid container spacing={3}>
        <Grid item xs>
          <Typography variant="body1" className={classes.title}>
            {FormatMessage({ id: "Cls001.Title.text3" })}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <GridList className={classes.gridList} cols={2.5}>
            <DndProvider backend={HTML5Backend}>
              {pdfList.length > 0 ? (
                <PdfDropBox
                  pdfList={pdfList}
                  callback={(flg: string, data: any) => {
                    if (flg === "1") {
                      setSelectPdf(data);
                    }
                    if (flg === "2") {
                      pdfList = data;
                      SetNewPdfList(JSON.stringify(setPdfOrderList(data)));
                    }
                  }}
                />
              ) : null}
            </DndProvider>
          </GridList>
        </Grid>

        {/* PDF削除ボタン */}
        <Grid item xs>
          <Grid item xs className="App-pTB5">
            <Button
              variant="contained"
              color="default"
              startIcon={<DeleteForeverIcon />}
              onClick={() => {
                deletePdfFile();
              }}
            >
              {FormatMessage({ id: "Cls001.Button.text2" })}
            </Button>
          </Grid>
          <Grid item xs className="App-pTB5">
            <Button
              variant="contained"
              color="default"
              startIcon={<DeleteForeverIcon />}
              onClick={() => {
                deleteAllPdfFile();
              }}
            >
              {FormatMessage({ id: "Cls001.Button.text3" })}
            </Button>
          </Grid>
          <Grid item xs className="App-pTB5">
            <Button
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              onClick={() => {
                ClickSaveOrderData();
              }}
            >
              {FormatMessage({ id: "Cls001.Button.text8" })}
            </Button>
          </Grid>
          <Grid item xs className="App-pTB5">
            <Button
              variant="contained"
              color="primary"
              startIcon={<CalendarTodayIcon />}
              onClick={releaseDaySetCilck}
            >
              {FormatMessage({ id: "Cls001.Button.text5" })}
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={0}>
        <Grid item xs={12} className="">
          {/* PDF資料ドロップエリア */}
          <div
            {...getRootProps2({ className: "dropzone", filedropstyle2 })}
            className={
              classes.mB0Important + " App-attachment-box App-attachment"
            }
          >
            <input {...getInputProps2()} multiple={true} />
            <section className="container">
              <div>
                <p>{FormatMessage({ id: "Cls001.Area.text1" })}</p>
                <p>{FormatMessage({ id: "Cls001.Area.text2" })}</p>
              </div>
              <div className="App-attachment" onClick={open2}>
                <label>
                  {
                    <ImageIcon
                      style={{ verticalAlign: "bottom", fontSize: 19 + "px" }}
                    />
                  }
                  {FormatMessage({ id: "Cls001.Button.text4" })}
                </label>
              </div>
            </section>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        <Grid item xs></Grid>
        <Grid item xs={8} className="App-mL20 App-mB20">
          <div>
            <span className="App-Color-Red">{errorMsgPdf}</span>
          </div>
        </Grid>
        <Grid item xs></Grid>
      </Grid>
      <Cls010
        ClassId={classId}
        ClassName={props.ClassName}
        HandleClickClose={releaseDayClose}
        Open={open}
      />
      {/*削除確認ダイアログ*/}
      <Dialog
        open={openDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {FormatMessage({ id: "Cls001.Dialog.title1" })}
        </DialogTitle>
        <DialogContent>{dialogText}</DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {FormatMessage({ id: "Button.Close" })}
          </Button>
          <Button onClick={clickDelete} color="primary" autoFocus>
            {FormatMessage({ id: "Button.Delete" })}
          </Button>
        </DialogActions>
      </Dialog>
      {/*編集内容保存確認ダイアログ*/}
      <Dialog
        open={openConfirmDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {FormatMessage({ id: "Cls001.Dialog.title3" })}
        </DialogTitle>
        <DialogContent>
          {FormatMessage({ id: "Cls001.Dialog.text3" })}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirm} color="primary">
            {FormatMessage({ id: "Cls001.Button.cancel" })}
          </Button>
          <Button onClick={clickSave} color="primary" autoFocus>
            {FormatMessage({ id: "Cls001.Button.OK" })}
          </Button>
        </DialogActions>
      </Dialog>
      {/*完了ダイアログ*/}
      <Dialog
        open={openCompDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
        <DialogContent>{dialogCompletedText}</DialogContent>
        <DialogActions>
          <Button onClick={handleCloseComp} color="primary">
            {FormatMessage({ id: "Button.Close" })}
          </Button>
        </DialogActions>
      </Dialog>
      {loading ? <LoadingBox /> : null}
      <div id="show_pdf" style={{ display: "none" }}></div>
    </div>
  );
};
