/* eslint-disable jsx-a11y/alt-text */
import { FC, memo } from "react";
import { useDrag, useDrop } from "react-dnd";


/**
 * 資料アップロード
 * Image DnD 並び替えコンポーネント
 */

export const ItemTypes = {
  CARD: "card",
};

export interface CardProps {
  id: string;
  url: string;
  className: string;
  onClick: any;
  moveCard: (id: string, to: number) => void;
  findCard: (id: string) => { index: number };
}

interface Item {
  id: string;
  originalIndex: number;
}

export const Card: FC<CardProps> = memo(function Card({
  id,
  url,
  className,
  onClick,
  moveCard,
  findCard,
}) {
  const originalIndex = findCard(id).index;
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: ItemTypes.CARD,
      item: { id, originalIndex },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        const { id: droppedId, originalIndex } = item;
        const didDrop = monitor.didDrop();
        if (!didDrop) {
          moveCard(droppedId, originalIndex);
        }
      },
    }),
    [id, originalIndex, moveCard]
  );
  const [, drop] = useDrop(
    () => ({
      accept: ItemTypes.CARD,
      canDrop: () => false,
      hover({ id: draggedId }: Item) {
        if (draggedId !== id) {
          const { index: overIndex } = findCard(id);
          moveCard(draggedId, overIndex);
        }
      },
    }),
    [findCard, moveCard]
  );
  const onClickHandle = (setid: string) => {
    if (onClick) onClick(setid);
  };

  const opacity = isDragging ? 0 : 1;
  return (
    <div
      ref={(node) => drag(drop(node))}
      style={{ opacity, height: "115px", overflow:"hidden", padding: "0.5rem 1rem" }}
      className={className}
      onClick={() => onClickHandle(id)}
    >
      <img src={url} ></img>
    </div>
  );
});
