import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";

import { userReducer } from "./userSlice";
import { notificationReducer } from "./notificationSlice";
import {
  openSwitchKeywordSearchDialogReducer,
  stateKeywordSearchProcReducer,
  keywordListsSliceReducer,
  errorMessageOnKeywordSearchReducer,
  getKeywordListsFlgReducer,
} from "./storedKeywordSeachConditionSlice";

// Connected React Routerで共通のインスタンスを使用する
// 必要があるためエクスポートしておく
export const history = createBrowserHistory();

const reducer = combineReducers({
  router: connectRouter(history),
  user: userReducer,
  notification: notificationReducer,

  /** キーワード検索ダイアログ用store定義  */
  openSwitchKeywordSearchDialog: openSwitchKeywordSearchDialogReducer,
  stateKeywordSearchProc: stateKeywordSearchProcReducer,
  keywordLists: keywordListsSliceReducer,
  errorMessageOnKeywordSearch: errorMessageOnKeywordSearchReducer,
  getKeywordListsFlg:getKeywordListsFlgReducer,
});

// useSelectorでの型推論に必要なため、reducerの戻り値型をエクスポート
export type RootState = ReturnType<typeof reducer>;

export const store = configureStore({
  reducer,
  middleware(getDefaultMiddleware) {
    return getDefaultMiddleware().concat(routerMiddleware(history));
  },
});
