import { apigw } from "../awsApi";
import { Dispatch } from "@reduxjs/toolkit";

const rows = {
    status:0,
    error_code : null,
    body:{
        answer_1_Aggregate_results:10,
        answer_2_Aggregate_results:20,
        answer_3_Aggregate_results:5,
        answer_4_Aggregate_results:0,
        answer_5_Aggregate_results:1,
        answer_6_Aggregate_results:1,
        answer_7_Aggregate_results:1,
        answer_8_Aggregate_results:0,
        answer_9_Aggregate_results:1,
        answer_10_Aggregate_results:3,
    }
}

/**
 * 択一問題サマリー取得
 * @param {Dispatch} dispatch reducerディスパッチ
 * @param {string} classId 授業ID
 * @param {string} choiceid 択一問題ID
 * @return コンポーネントHTMLコード内容
 */
export const QZ0303 = async (
  dispatch: Dispatch,
  classId:string,
  choiceid:number
) => {
  if (process.env.REACT_APP_API_USE_FLG === "true") {
    return apigw(
      "/QZ0303",
      {
        class_id:classId,
        choice_id:choiceid
      },
      dispatch
    );
  } else {
    //サンプルデータ用
    return { body: rows };
  }
};