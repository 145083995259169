import { apigw } from "../awsApi";
import { Dispatch } from "@reduxjs/toolkit";


const rows = [
  {
    class_id: "english02",
    class_name: "英語02",
    explain_text: "英語02説明文",
    is_font_red: "0",
    is_font_bold: "1",
    sort: 2,
    stream_class: "1",
    open_class: "1",
    existing_use_class: "1",
    is_filmed: "1",
    plan_date: "2021-09-25T12:00:00.000Z",
    channel_id: "channel_id002",
    status_class: "2",
    page_after_url: "",
    tag_id: "summer2021",
    status_class_value: "配信準備中",
  },
  {
    class_id: "kokugo02",
    class_name: "国語02",
    explain_text: "国語02説明文",
    is_font_red: "0",
    is_font_bold: "1",
    sort: 12,
    stream_class: "1",
    open_class: "2",
    existing_use_class: "2",
    is_filmed: "1",
    plan_date: "2021-07-01T10:00:00.000Z",
    channel_id: "channel_id012",
    status_class: "5",
    page_after_url: "",
    tag_id: "summer2021",
    status_class_value: "授業終了",
  },
  {
    class_id: "math02",
    class_name: "数学02",
    explain_text: "数学02説明文",
    is_font_red: "1",
    is_font_bold: "1",
    sort: 7,
    stream_class: "1",
    open_class: "2",
    existing_use_class: "2",
    is_filmed: "1",
    plan_date: "2021-07-01T10:00:00.000Z",
    channel_id: "channel_id007",
    status_class: "2",
    page_after_url: "",
    tag_id: "summer2021",
    status_class_value: "配信準備中",
  },
];

/**
 * 授業一覧取得
 * @param {Dispatch} dispatch reducerディスパッチ
 * @param {any} tagId サービスID
 * @param {any} customEnqueteFlg カスタムアンケートフラグ
 * @return コンポーネントHTMLコード内容
 */
export const LC0301 = async (
  dispatch: Dispatch,
  tagId: any,
  customEnqueteFlg: any
) => {
  if (process.env.REACT_APP_API_USE_FLG === "true") {
    return apigw(
      "/LC0301",
      {
        tag_id: tagId,
        custom_enquete_flg: customEnqueteFlg,
      },
      dispatch
    );
  } else {
    //サンプルデータ用
    return {
      state: "0",
      error_code: "",
      body: rows,
    };
  }
};
