import { createSlice } from "@reduxjs/toolkit";

/**
 *
 * キーワード検索処理について他コンポーネントへstate伝達するためのstore定義
 *
 * @author z02.koh.sakamoto@lincrea.co.jp
 */
type StateKeywordSearchProcSlice = {
  // トップページでこのstateを監視対象とすることで変更時にuseEffectを再始動して授業一覧を再取得させるために使用する
  refreshKey: number,
  // 授業一覧を再取得時に前回取得した授業一覧をクリアする際にtrueを入れる（ダイアログの検索ボタンが押された場合はtrue、それ以外はfalse）。
  stateFlagResetCurrentRows: boolean,
}
const initialStateKeywordSearchProc: StateKeywordSearchProcSlice = {
  refreshKey: new Date().getTime(), // 便宜上timestampを使用
  stateFlagResetCurrentRows: false,
};
const stateKeywordSearchProcSlice = createSlice({
  name: "stateKeywordSearchProc",
  initialState: initialStateKeywordSearchProc,
  reducers: {
    setStateKeywordSearchProc(state, action):StateKeywordSearchProcSlice {
      if (action.payload.hasOwnProperty("refreshKey") === true) {
        state.refreshKey = new Date().getTime(); // 便宜上timestampを使用
      }
      if (action.payload.hasOwnProperty("stateFlagResetCurrentRows") === true) {
        state.stateFlagResetCurrentRows = action.payload["stateFlagResetCurrentRows"];
      }
      return state;
    },
  }
});
export const stateKeywordSearchProcReducer = stateKeywordSearchProcSlice.reducer;
export const { setStateKeywordSearchProc } = stateKeywordSearchProcSlice.actions;

/**
 *
 * キーワード検索ダイアログを開閉するサブスイッチ
 *
 * @author z02.koh.sakamoto@lincrea.co.jp
 */
const openSwitchKeywordSearchDialogSlice = createSlice({
  name: "openSwitchKeywordSearchDialog",
  initialState: false,
  reducers: {
    setOpenSwitchKeywordSearchDialog(state, action):boolean {
      return true;
    },
    setCloseSwitchKeywordSearchDialog(state, action):boolean {
      return false;
    },
  }
});
export const openSwitchKeywordSearchDialogReducer = openSwitchKeywordSearchDialogSlice.reducer;
export const { setOpenSwitchKeywordSearchDialog, setCloseSwitchKeywordSearchDialog } = openSwitchKeywordSearchDialogSlice.actions;


/**
 *
 * キーワード一覧を保持するstore定義
 *
 * @author z02.koh.sakamoto@lincrea.co.jp
 */
const initialKeywordLists: any = [];
const KeywordListsSlice = createSlice({
  name: "keywordsList",
  initialState: initialKeywordLists,
  reducers: {
    setKeywordListsOnStore(state, action):any {
      return action.payload;
    },
  }
});
export const keywordListsSliceReducer = KeywordListsSlice.reducer;
export const { setKeywordListsOnStore } = KeywordListsSlice.actions;

/**
 *
 * キーワード検索ダイアログで表示するエラーメッセージのstore定義
 *
 * @author z02.koh.sakamoto@lincrea.co.jp
 */
const errorMessageOnKeywordSearchSlice = createSlice({
  name: "errorMessageOnKeywordSearch",
  initialState: "",
  reducers: {
    setErrorMessageOnKeywordSearch(state, action):string {
      return action.payload;
    },
  }
});
export const errorMessageOnKeywordSearchReducer = errorMessageOnKeywordSearchSlice.reducer;
export const { setErrorMessageOnKeywordSearch } = errorMessageOnKeywordSearchSlice.actions;


/**
 *
 * キーワードデータ再度取得フラグ設定のstore定義
 *
 */
 const getKeywordListsFlgSlice = createSlice({
  name: "getKeywordListsFlg",
  initialState: false,
  reducers: {
    setTrueGetKeywordListsFlg(state, action):boolean {
      return true;
    },
    setFalseGetKeywordListsFlg(state, action):boolean {
      return false;
    },
  }
});
export const getKeywordListsFlgReducer = getKeywordListsFlgSlice.reducer;
export const { setTrueGetKeywordListsFlg,setFalseGetKeywordListsFlg } = getKeywordListsFlgSlice.actions;