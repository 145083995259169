import { apigw } from "../awsApi";
import { Dispatch } from "@reduxjs/toolkit";

//サンプルデータ
const rows = 
  {
    concurrent_connection_result:true
  };

/**
 * 同時接続数判定
 * @param {Dispatch} dispatch           reducerディスパッチ
 * @return コンポーネントHTMLコード内容
 */

export const CF0306 = async (dispatch: Dispatch) => {
  if (process.env.REACT_APP_API_USE_FLG === "true") {
    return apigw(
      "/CF0306",
      {},
      dispatch
    );
  } else {
    //サンプルデータ用
    return { body: rows };
  }
};
