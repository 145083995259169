/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import ButtonBase from "@material-ui/core/ButtonBase";
import { ST0326 } from "../../utils/api/ST0326";
// import { LoadingBox } from "../../components/atoms/LoadingBox";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: 230,
    height: 280,
  },
  icon: {
    color: "rgba(255, 255, 255, 0.54)",
  },
  colorWhite: {
    color: "white",
  },
  alignCenter: {
    textAlign: "center",
  },
  image: {
    width: 90,
    height: 60,
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  title: {
    height: 10,
    textAlign: "center",
  },
}));

export default function TitlebarGridList(props: any) {
  const classes = useStyles();
  const [boardList, setBoardList] = React.useState(props.whiteboardDataList);
  const [selectBoardId, setSelectBoardId] = React.useState();
  // const [loading, setLoading] = React.useState(false);
  const [buttonDis, setButtonDis] = React.useState(false);
  const clickBoard = (listData: any) => {
    // setLoading(true);
    setButtonDis(true);
    props.boardChange(listData);
    setSelectBoardId(listData.boardNo);
  };

  const chengeScale = (event: any) => {
    const image: any = document.getElementById(event.target.id);
    if (image !== null) {
      image.style.transform = "scale(1.2,1.2)";
    }
  };
  const scaleReset = (event: any) => {
    const image: any = document.getElementById(event.target.id);
    if (image !== null) {
      image.style.transform = "scale(1.0,1.0)";
    }
  };

  useEffect(() => {
    ST0326().then((urlInfo: any) => {
      let whiteboardDataList = props.whiteboardDataList;
      if (urlInfo && urlInfo.state === "0") {
        var info: any = urlInfo.body;
        let url =
          "?Policy=" +
          info["Policy"] +
          "&Signature=" +
          info["Signature"] +
          "&Key-Pair-Id=" +
          info["Key-Pair-Id"];
        console.log("url", url);
        whiteboardDataList = [];
        for (let index = 0; index < props.whiteboardDataList.length; index++) {
          let img = props.whiteboardDataList[index];
          let d = String(img.url).split("?");
          img.url = d[0] + url;
          whiteboardDataList.push(img);
        }
      }
    setBoardList(whiteboardDataList);
    setSelectBoardId(props.selectBoardId);
  });

  }, []);

  return (
    <div>
      {/* {loading ? <LoadingBox /> : null} */}
      <div className={classes.root}>
        <GridList cellHeight={90} className={classes.gridList}>
          {boardList.map((listData: any, index: number) => (
            <GridListTile
              key={"white_board_list_" + index}
              className={classes.image}
            >
              <ButtonBase
                className={classes.image}
                onClick={() => {
                  clickBoard(listData);
                }}
                disabled={buttonDis}
              >
                <div
                  onMouseOver={chengeScale}
                  onMouseOut={scaleReset}
                  style={{
                    backgroundColor:
                      selectBoardId === listData.boardNo ? "red" : "white",
                    padding: 1,
                  }}
                >
                  <img
                    src={listData.url}
                    alt={"img" + listData.boardNo}
                    className={classes.img}
                    id={"img" + listData.boardNo}
                  />
                </div>
                <GridListTileBar
                  className={classes.title}
                  subtitle={"Board" + listData.boardNo}
                />
              </ButtonBase>
            </GridListTile>
          ))}
        </GridList>
      </div>
    </div>
  );
}
