import { apigw } from "../awsApi";
import { Dispatch } from "@reduxjs/toolkit";

const rows = {
    status:0,
    error_code : null,
    body:null
}

/**
 * 配信時間更新
 * @param {Dispatch} dispatch reducerディスパッチ
 * @param {string} classId 授業ID
 * @param {string} elapsedTime 経過時間
 * @param {string} startFlg 授業開始フラグ
 * @return コンポーネントHTMLコード内容
 */
export const ST0307 = async (
  dispatch: Dispatch,
  classId:string,
  elapsedTime:number,
  startFlg:string
) => {
  if (process.env.REACT_APP_API_USE_FLG === "true") {
    return apigw(
      "/ST0307",
      {
        class_id:classId,
        elapsed_time:elapsedTime,
        start_flg: startFlg
      },
      dispatch
    );
  } else {
    //サンプルデータ用
    return { body: rows };
  }
};