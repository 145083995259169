import { createSlice, Dispatch } from "@reduxjs/toolkit";
import { push } from "connected-react-router";
import { addNotification } from "./notificationSlice";
// import { apigw } from "../utils/awsApi";
import { AU0901 } from "../utils/api/AU0901";
import sanitize from "sanitize-html";

const streamRedirectUrl = "/top/001";
const viewerRedirectUrl = "/top/002";

export type User = {
  user_id: string; //配信ユーザID
  user_name: string;
  language: "ja" | "en";
  mailaddress: string;
  type: "viewer" | "stream";
  password: string; //パスワード
  password_expire_date: Date; //パスワード有効期限
  session_id: string; //セッションID
  is_first_login: string; //初回フラグ
  user_class: string; //ユーザ区分
  system_class: string; //システム区分
  system_version: string; //システム区分
  service_id: string; //サービスID
  download_existence: boolean; //ダウンロード有無
};

const userSlice = createSlice({
  name: "user",
  initialState: {
    service_id: "",
    user_id: "",
    user_name: "",
    password: "",
    password_expire_date: "",
    session_id: "",
    is_first_login: "",
    user_class: "",
    language: "ja",
    system_class: "",
    system_version: "",
    mailaddress: "",
    type: "stream",
    download_existence: true,
  },
  reducers: {
    signInAction(state, action) {
      return {
        ...state,
        service_id: action.payload.service_id,
        user_id: action.payload.user_id,
        user_name: action.payload.user_name,
        password: action.payload.password,
        password_expire_date: action.payload.password_expire_date,
        session_id: action.payload.session_id,
        is_first_login: action.payload.is_first_login,
        user_class: action.payload.user_class,
        language: action.payload.language,
        system_class: action.payload.system_class,
        system_version: action.payload.system_version,
        mailaddress: action.payload.mailaddress,
        type: action.payload.type,
        download_existence: action.payload.download_existence,
      };
    },
    signOutAction(state, action) {
      return {
        ...state,
        service_id: "",
        user_id: "",
        user_name: "",
        password: "",
        password_expire_date: "",
        session_id: "",
        is_first_login: "",
        user_class: "",
        language: "ja",
        system_class: "",
        system_version: "",
        mailaddress: "",
        type: "stream",
        download_existence: true,
      };
    },
    changeLanguage(state, action) {
      return {
        ...state,
        language: action.payload.language,
      };
    },
    changeuserName(state, action) {
      return {
        ...state,
        user_name: action.payload.user_name,
      };
    },
  },
});

// Reducerをエクスポートする
export const userReducer = userSlice.reducer;

// Action Creatorsをエクスポートする
export const { signInAction, signOutAction, changeLanguage, changeuserName } =
  userSlice.actions;

// 外部からはこの関数を呼んでもらう
export const signIn = (data: any) => async (dispatch: Dispatch) => {
  var userTypeFlg = localStorage.getItem("userTypeFlg")
    ? localStorage.getItem("userTypeFlg")
    : "2";
  try {
    let loginData = {
      service_id: data.service_id,
      user_id: data.user_id,
      user_name: data.user_name,
      password: data.password,
      password_expire_date: data.password_expire_date,
      session_id: data.session_id,
      is_first_login: data.is_first_login,
      user_class: data.user_class,
      language: data.language,
      system_class: data.system_class,
      system_version: data.system_class,
      mailaddress: data.mailaddress,
      type: userTypeFlg === "1" ? "stream" : "viewer",
      download_existence: data.download_existence,
    };
    dispatch(signInAction(loginData));
    localStorage.setItem("session_id", data.session_id);
    localStorage.setItem("session_expiration", "");
    if (
      data.is_first_login === "1" ||
      (Boolean(data.password_expire_date) &&
        lowerThanDateOnly(new Date(), new Date(data.password_expire_date)))
    ) {
      dispatch(push("/pas/001"));
    } else {
      if (userTypeFlg === "1") {
        dispatch(push(sanitize(streamRedirectUrl)));
      } else {
        dispatch(push(sanitize(viewerRedirectUrl)));
      }
    }
  } catch (error: any) {
    dispatch(
      addNotification({
        message: error?.response?.data.message || error.message,
        variant: "error",
      })
    );
  }
};

//日付の比較
const lowerThanDateOnly = (date1: any, date2: any) => {
  var year1 = date1.getFullYear();
  var month1 = date1.getMonth() + 1;
  var day1 = date1.getDate();

  var year2 = date2.getFullYear();
  var month2 = date2.getMonth() + 1;
  var day2 = date2.getDate();

  if (year1 === year2) {
    if (month1 === month2) {
      return day1 > day2;
    } else {
      return month1 > month2;
    }
  } else {
    return year1 > year2;
  }
};

export const signOut = () => async (dispatch: Dispatch) => {
  try {
    const logout = await AU0901(dispatch);
    if (logout && logout.state === "0") {
      dispatch(signOutAction({}));
      var userTypeFlg = localStorage.getItem("userTypeFlg")
        ? localStorage.getItem("userTypeFlg")
        : "2";
      var classTimeCount = localStorage.getItem("classTimeCount");
      var Cls008_classId = localStorage.getItem("Cls008_classId");
      localStorage.clear();
      sessionStorage.clear();
      if (classTimeCount)
        localStorage.setItem("classTimeCount", classTimeCount);
      if (Cls008_classId)
        localStorage.setItem("classTimeCount", Cls008_classId);
      dispatch(push(userTypeFlg === "1" ? "/streamer/" : "/"));
    }
  } catch (error: any) {
    dispatch(addNotification({ message: error.message, variant: "error" }));
  }
};

export const setUser = (user: User) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(
        signInAction({
          service_id: user.service_id,
          user_id: user.user_id,
          user_name: user.user_name,
          password: user.password,
          password_expire_date: user.password_expire_date,
          session_id: user.session_id,
          is_first_login: user.is_first_login,
          user_class: user.user_class,
          language: user.language,
          system_class: user.system_class,
          system_version: user.system_version,
          mailaddress: user.mailaddress,
          type: user.type,
          download_existence: user.download_existence,
        })
      );
      let url = String(localStorage.getItem("redirecturl"));
      if (url === "" || url === "/" || url === "/null") {
        url = viewerRedirectUrl;
      } else {
        switch (url) {
          case "/pas/001":
            break;
          case "/pas/002":
            break;
          case "/top/001":
            url = user.type === "stream" ? url : "/";
            break;
          case "/cls/001":
            url = user.type === "stream" ? url : "/";
            break;
          case "/cls/002":
            url = user.type === "stream" ? url : "/";
            break;
          case "/cls/003":
            url = user.type === "stream" ? url : "/";
            break;
          case "/cls/004":
            url = user.type === "stream" ? url : "/";
            break;
          case "/cls/005":
            url = user.type === "stream" ? url : "/";
            break;
          case "/cls/006":
            url = user.type === "stream" ? url : "/";
            break;
          case "/cls/007":
            url = user.type === "stream" ? url : "/";
            break;
          case "/cls/008":
            url = user.type === "stream" ? url : "/";
            break;
          case "/cls/009":
            url = user.type === "stream" ? url : "/";
            break;
          case "/cls/010":
            url = user.type === "stream" ? url : "/";
            break;
          case "/sts/001":
            url = user.type === "stream" ? url : "/";
            break;
          case "/sts/002":
            url = user.type === "stream" ? url : "/";
            break;
          case "/sts/003":
            url = user.type === "stream" ? url : "/";
            break;
          case "/sts/004":
            url = user.type === "stream" ? url : "/";
            break;
          case "/set/001":
            url = user.type === "stream" ? url : "/";
            break;
          case "/set/002":
            url = user.type === "stream" ? url : "/";
            break;
          case "/set/003":
            url = user.type === "stream" ? url : "/";
            break;
          case "/set/004":
            url = user.type === "stream" ? url : "/";
            break;
          default:
            if (user.type === "viewer" || user.type === "stream") {
              url = user.type === "viewer" ? url : "/streamer/";
            } else {
              url = "/";
            }

            break;
        }
      }
      dispatch(push(sanitize(url)));
    } catch (error) {
      console.log("error signing in", error);
    }
  };
};
