import { apigw } from "../awsApi";
import { Dispatch } from "@reduxjs/toolkit";

const rows = {
    status:0,
    error_code : null,
    body:null
}

/**
 * 授業ボード資料アップロード
 * @param {Dispatch} dispatch reducerディスパッチ
 * @param {string} classId 授業ID
 * @param {string} boardDocumentName ボード資料名
 * @param {string} documentType ボード資料種類
 * @param {string} s3Id ボード資料S3ID
 * @return コンポーネントHTMLコード内容
 */
export const ST0310 = async (
  dispatch: Dispatch,
  classId:string,
  boardDocumentName:string,
  documentType:string,
  s3Id:string
) => {
  if (process.env.REACT_APP_API_USE_FLG === "true") {
    return apigw(
      "/ST0310",
      {
        class_id:classId,
        board_document_name:boardDocumentName,
        document_type:documentType,
        s3_id:s3Id
      },
      dispatch
    );
  } else {
    //サンプルデータ用
    return { body: rows };
  }
};