import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { FormatMessage } from "../../components/atoms/FormatMessage";
import { ST0325 } from "../../utils/api/ST0325";
import { makeStyles } from "@material-ui/core";
import { LoadingBox } from "../../components/atoms/LoadingBox";
import "../../css/pas/pas004.css";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AgoraRTM from "agora-rtm-sdk";
import { RiCheckboxBlankCircleLine } from "react-icons/ri";
import { BsX } from "react-icons/bs";

let clientRTM: any;
let ChannelId: any;
let agoraOption: any;
let userId: any;
let channelRTM: any;

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  arrow: {
    color: "#3993b2",
    fontSize: "20px",
    width: "20px",
    "&::before": {
      backgroundColor: "#3993b2",
      boxSizing: "border-box",
    },
  },
  tooltip: {
    maxWidth: 400,
    top: "10px",
    color: "#fff",
    backgroundColor: "#3993b2",
    display: "brock",
  },
}));
/**
 * RTM+RTC接続チェック
 * @return メッセージ内容
 */

export const Rtm001 = (props: any) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [chatCheckFlg, setChatCheckFlg] = useState(false);
  const [checkResult, setCheckResult] = useState(false);

  const backClick = () => {
    const userType = localStorage.getItem("userTypeFlg");
    if (userType === "1") {
      dispatch(push("/streamer")); //配信ログイン
    } else {
      dispatch(push("/")); //視聴ログイン
    }
  };

  //RTM接続情報取得
  const RTMConectInfo = async () => {
    const result = await ST0325(dispatch, userId, ChannelId);
    if (result && Object.keys(result).indexOf("body") >= 0) {
      agoraOption = {
        appID: result.body.app_id,
        token_rtm: result.body.token_rtm,
      };
      setLoading(false);
    }
  };

  //RTM接続
  const rtmClick = async () => {
    if (ChannelId && agoraOption.appID && agoraOption.token_rtm) {
      clientRTM = AgoraRTM.createInstance(agoraOption.appID, {
        enableLogUpload: true,
      });
      clientRTM
        .login({
          uid: userId,
          token: agoraOption.token_rtm,
        })
        .then((data: any) => {
          channelRTM = clientRTM.createChannel(ChannelId);
          channelRTM?.join().then(() => {
            RTMLoginCheck();
          });
        })
        .catch((e: any) => {
          console.log("RTM login err", e);
          setChatCheckFlg(true);
          setCheckResult(false);
          setLoading(false);
        });
    } else {
      setTimeout(() => rtmClick(), 100);
    }
  };

  const RTMLoginCheck = async () => {
    channelRTM
      .getMembers()
      .then((result: any) => {
        if (result.find((element: any) => element === userId)) {
          channelRTM
            ?.sendMessage({
              text: "chatCheck",
            })
            .then(() => {
              console.log("RTMLoginNow");
              setChatCheckFlg(true);
              setCheckResult(true);
              setLoading(false);
            })
            .catch((err: any) => {
              console.log(err);
              setChatCheckFlg(true);
              setCheckResult(false);
              setLoading(false);
            });
        } else {
          setChatCheckFlg(true);
          setCheckResult(false);
          setLoading(false);
        }
      })
      .catch((err: any) => {
        console.log(err);
        setChatCheckFlg(true);
        setCheckResult(false);
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    userId = "RTMChecker";
    ChannelId = userId + new Date().getTime();
    RTMConectInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ height: "100%" }}>
      <Grid item xs={12} className="App-login-header">
        {/*<img src="/logo.svg" height="24" />*/}
      </Grid>
      <Grid
        container
        spacing={3}
        style={{
          minHeight: "calc(100% - 50px)",
          width: "100%",
          margin: "0 auto",
          padding: "50px 0 0 0",
        }}
      >
        <Grid item xs={12}>
          <Paper className="App-paper">
            <Typography
              component="h2"
              variant="h6"
              color="inherit"
              gutterBottom
            >
              {FormatMessage({ id: "Rtm001.Title" })}
            </Typography>
            <Grid
              item
              xs={12}
              className="App-alignLeft"
              style={{ whiteSpace: "pre-wrap" }}
            ></Grid>
            <Grid item xs={12} className="App-alignRight">
              <Button
                variant="contained"
                color="primary"
                className="App-button_bottom"
                startIcon={<ArrowBackIcon />}
                onClick={backClick}
              >
                {FormatMessage({ id: "Rtm001.Button.Back" })}
              </Button>
            </Grid>
            <div style={{ marginTop: 20 }}>
              <Grid item xs={12}>
                {FormatMessage({ id: "Rtm001.Text.Explanation1" })}
              </Grid>
              <Grid item xs={12}>
                {FormatMessage({ id: "Rtm001.Text.Explanation2" })}
              </Grid>
              <Grid item xs={12}>
                {FormatMessage({ id: "Rtm001.Text.Explanation3" })}
              </Grid>
            </div>
            <div style={{ marginTop: 30 }}>
              <Grid item xs={12}>
                {FormatMessage({ id: "Rtm001.ChatCheck.Title" })}
              </Grid>
              <div style={{ marginLeft: 10 }}>
                <Grid item xs={12}>
                  {FormatMessage({ id: "Rtm001.ChatCheck.Explanation1" })}
                </Grid>
                <Grid item xs={12}>
                  {FormatMessage({ id: "Rtm001.ChatCheck.Explanation2" })}
                </Grid>
              </div>
            </div>
            <Grid item xs={12} className="App-alignCenter">
              <Button
                variant="contained"
                color="primary"
                className="checkButton"
                style={{
                  width: 220,
                  margin: 30,
                }}
                //startIcon={<RefreshIcon />}
                onClick={() => {
                  setLoading(true);
                  setChatCheckFlg(false);
                  rtmClick();
                }}
              >
                {FormatMessage({ id: "Rtm001.Button.check1" })}
              </Button>
            </Grid>
            {chatCheckFlg ? (
              checkResult ? (
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <RiCheckboxBlankCircleLine
                      style={{ fontSize: 40, float: "right" }}
                      color="green"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    {FormatMessage({ id: "Rtm001.ChatCheck.ResultOK1" })}
                    <br />
                    {FormatMessage({ id: "Rtm001.ChatCheck.ResultOK2" })}
                  </Grid>
                </Grid>
              ) : (
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <BsX style={{ fontSize: 40, float: "right" }} color="red" />
                  </Grid>
                  <Grid item xs={6}>
                    {FormatMessage({ id: "Rtm001.ChatCheck.ResultNG1" })}
                    <br />
                    {FormatMessage({ id: "Rtm001.ChatCheck.ResultNG2" })}
                  </Grid>
                </Grid>
              )
            ) : null}
            <div style={{ marginTop: 30 }}>
              <Grid item xs={12}>
                {FormatMessage({ id: "Rtm001.StreamCheck.Title" })}
              </Grid>
              <div style={{ marginLeft: 10 }}>
                <Grid item xs={12}>
                  {FormatMessage({ id: "Rtm001.StreamCheck.Explanation1" })}
                </Grid>
                <div style={{ marginTop: 10 }}>
                  <Grid item xs={12}>
                    {FormatMessage({ id: "Rtm001.StreamCheck.Explanation2" })}
                  </Grid>
                  <Grid item xs={12}>
                    {FormatMessage({ id: "Rtm001.StreamCheck.Explanation3" })}
                  </Grid>
                </div>
              </div>
            </div>
            <Grid item xs={12} className="App-alignCenter">
              <Button
                variant="contained"
                color="primary"
                className="checkButton"
                style={{
                  width: 240,
                  margin: 30,
                }}
                //startIcon={<RefreshIcon />}
                onClick={() => {
                  window.open(
                    process.env.REACT_APP_AGORA_RTC_CHECK_URL,
                    "test",
                    "noreferrer"
                  );
                }}
              >
                {FormatMessage({ id: "Rtm001.Button.check1" })}
              </Button>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      {loading ? <LoadingBox /> : null}
    </div>
  );
};
