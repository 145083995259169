import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { FormatMessage } from "../../components/atoms/FormatMessage";
import { MainLayout } from "../../layouts/MainLayout";
// import { TextFieldBox } from "../../components/atoms/TextFieldBox";
import Checkbox from "@material-ui/core/Checkbox";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import RefreshIcon from "@material-ui/icons/Refresh";
import AddIcon from "@material-ui/icons/Add";

import { OQ0302 } from "../../utils/api/OQ0302";
import { OQ0303 } from "../../utils/api/OQ0303";
import { OQ0304 } from "../../utils/api/OQ0304";
import { LoadingBox } from "../../components/atoms/LoadingBox";
import sanitize from "sanitize-html";
// import { CollectionsOutlined } from "@material-ui/icons";

/**
 * カスタムアンケート登録編集確認
 * @param {object} props 引き継いだオブジェクト
 * @return コンポーネントHTMLコード内容
 */
export const Cls006 = (props: any) => {
  const dispatch = useDispatch();
  const [classId, setClassId] = React.useState("");

  const IS_REQUIRED = "1";
  const IS_NOT_REQUIRED = "0";
  // const MAX_QUESTION = 50;
  const ERROR_CODE_EXCLUSIVE = "04070";
  const ERROR_CODE_ADD_FAILED = "04013";
  const ERROR_CODE_UPDATE_FAILED = "04014";
  const ERROR_CODE_DELETE_FAILED = "04015";
  const ERROR_CODE_WAF_BLOCKED = "09013";

  //input データ
  const [action, setAction] = React.useState("");
  const [questionNo, setQuestionNo] = React.useState("");
  // 質問
  const [question, setQuestion] = React.useState("");
  // 回答方式
  const [characterClass, setCharacterClass] = React.useState("1");
  const [questionClassValue, setQuestionClassValue] = React.useState("");
  // 必須
  const [isRequired, setIsRequired] = React.useState(IS_NOT_REQUIRED);
  // 回答方式_内容
  // 質問データ
  const [option, setOption] = React.useState<any>([]);
  const [inputOption, setInputOption] = React.useState<any>({});
  // 更新日時
  const [updateDate, setUpdateDate] = React.useState(new Date());

  // loadingフラグ
  const [loading, setLoading] = React.useState(false);

  // 入力エラーフラグ
  type inputError = {
    error: boolean;
    error_message: any;
  };
  const [topError, setTopError] = React.useState<inputError>({
    error: false,
    error_message: "",
  });

  // // カスタムアンケートデータ
  // type customQuestionRowType = {
  //   option_1: string;
  //   option_2: string;
  //   option_3: string;
  //   option_4: string;
  //   option_5: string;
  //   option_6: string;
  //   option_7: string;
  //   option_8: string;
  //   option_9: string;
  //   option_10: string;
  //   option_11: string;
  //   option_12: string;
  //   option_13: string;
  //   option_14: string;
  //   option_15: string;
  //   option_16: string;
  //   option_17: string;
  //   option_18: string;
  //   option_19: string;
  //   option_20: string;
  //   option_21: string;
  //   option_22: string;
  //   option_23: string;
  //   option_24: string;
  //   option_25: string;
  //   option_26: string;
  //   option_27: string;
  //   option_28: string;
  //   option_29: string;
  //   option_30: string;
  //   option_31: string;
  //   option_32: string;
  //   option_33: string;
  //   option_34: string;
  //   option_35: string;
  //   option_36: string;
  //   option_37: string;
  //   option_38: string;
  //   option_39: string;
  //   option_40: string;
  //   option_41: string;
  //   option_42: string;
  //   option_43: string;
  //   option_44: string;
  //   option_45: string;
  //   option_46: string;
  //   option_47: string;
  //   option_48: string;
  //   option_49: string;
  //   option_50: string;
  // };

  // const [customQuestionRow, setCustomQuestionRow] = React.useState<
  //   customQuestionRowType[]
  // >([]);
  // const customQuestion: customQuestionRowType[] = [];

  // メッセージの定義
  const messages = {
    // ラベル
    delButton: FormatMessage({ id: "Button.Del" }),
    addButton: FormatMessage({ id: "Button.Add" }),
    exclusiveError: FormatMessage({ id: "Error.ExlusiveControl" }),
    // 新規登録失敗
    addFailed: FormatMessage({
      id: "Cls006.Message.InsertError",
    }),
    // 更新失敗
    updateFailed: FormatMessage({
      id: "Cls006.Message.UpdeteError",
    }),
    // 削除失敗
    deleteFailed: FormatMessage({
      id: "Cls006.Message.DeleteError",
    }),
    // WAFブロック
    wafBlock: FormatMessage({
      id: "API_ERROR_CODE_9013",
    }),
  };

  const backCilck = () => {
    if (action === "delete") {
      dispatch(push("/cls/004"));
    } else {
      dispatch(
        push("/cls/005", {
          class_id: classId,
          action: action,
          question_no: questionNo,
          question: question,
          character_class: characterClass,
          is_required: isRequired,
          option: option,
          question_class_value: questionClassValue,
          update_date: updateDate,
          backFlg: true,
        })
      );
    }
  };

  const checkCilck = async () => {
    setLoading(true);
    // 04013	登録失敗		登録に失敗しました。再度「登録する」ボタンを押してください。
    // 04014	更新失敗		更新に失敗しました。再度「更新する」ボタンを押してください。
    // 04015	削除失敗		削除に失敗しました。再度「削除する」ボタンを押してください。

    if (action === "add") {
      // 新規登録
      await OQ0302(
        dispatch,
        classId,
        questionNo,
        question,
        isRequired,
        characterClass,
        inputOption.option_1 ? inputOption.option_1 : "",
        inputOption.option_2 ? inputOption.option_2 : "",
        inputOption.option_3 ? inputOption.option_3 : "",
        inputOption.option_4 ? inputOption.option_4 : "",
        inputOption.option_5 ? inputOption.option_5 : "",
        inputOption.option_6 ? inputOption.option_6 : "",
        inputOption.option_7 ? inputOption.option_7 : "",
        inputOption.option_8 ? inputOption.option_8 : "",
        inputOption.option_9 ? inputOption.option_9 : "",
        inputOption.option_10 ? inputOption.option_10 : "",
        inputOption.option_11 ? inputOption.option_11 : "",
        inputOption.option_12 ? inputOption.option_12 : "",
        inputOption.option_13 ? inputOption.option_13 : "",
        inputOption.option_14 ? inputOption.option_14 : "",
        inputOption.option_15 ? inputOption.option_15 : "",
        inputOption.option_16 ? inputOption.option_16 : "",
        inputOption.option_17 ? inputOption.option_17 : "",
        inputOption.option_18 ? inputOption.option_18 : "",
        inputOption.option_19 ? inputOption.option_19 : "",
        inputOption.option_20 ? inputOption.option_20 : "",
        inputOption.option_21 ? inputOption.option_21 : "",
        inputOption.option_22 ? inputOption.option_22 : "",
        inputOption.option_23 ? inputOption.option_23 : "",
        inputOption.option_24 ? inputOption.option_24 : "",
        inputOption.option_25 ? inputOption.option_25 : "",
        inputOption.option_26 ? inputOption.option_26 : "",
        inputOption.option_27 ? inputOption.option_27 : "",
        inputOption.option_28 ? inputOption.option_28 : "",
        inputOption.option_29 ? inputOption.option_29 : "",
        inputOption.option_30 ? inputOption.option_30 : "",
        inputOption.option_31 ? inputOption.option_31 : "",
        inputOption.option_32 ? inputOption.option_32 : "",
        inputOption.option_33 ? inputOption.option_33 : "",
        inputOption.option_34 ? inputOption.option_34 : "",
        inputOption.option_35 ? inputOption.option_35 : "",
        inputOption.option_36 ? inputOption.option_36 : "",
        inputOption.option_37 ? inputOption.option_37 : "",
        inputOption.option_38 ? inputOption.option_38 : "",
        inputOption.option_39 ? inputOption.option_39 : "",
        inputOption.option_40 ? inputOption.option_40 : "",
        inputOption.option_41 ? inputOption.option_41 : "",
        inputOption.option_42 ? inputOption.option_42 : "",
        inputOption.option_43 ? inputOption.option_43 : "",
        inputOption.option_44 ? inputOption.option_44 : "",
        inputOption.option_45 ? inputOption.option_45 : "",
        inputOption.option_46 ? inputOption.option_46 : "",
        inputOption.option_47 ? inputOption.option_47 : "",
        inputOption.option_48 ? inputOption.option_48 : "",
        inputOption.option_49 ? inputOption.option_49 : "",
        inputOption.option_50 ? inputOption.option_50 : ""
      ).then((getData: any) => {
        if (
          getData &&
          Object.keys(getData).indexOf("state") >= 0 &&
          getData.state === "0"
        ) {
          setLoading(false);
          dispatch(push("/cls/007"));
        } else {
          if (getData && Object.keys(getData).indexOf("error_code") >= 0) {
            setTopError({
              error: true,
              error_message:
                getData.error_code === ERROR_CODE_WAF_BLOCKED
                  ? messages.wafBlock
                  : getData.error_code === ERROR_CODE_ADD_FAILED
                  ? messages.addFailed
                  : getData.error_code === ERROR_CODE_EXCLUSIVE
                  ? messages.exclusiveError
                  : "",
            });
          }
        }
      });
      setLoading(false);
    } else if (action === "edit") {
      // 更新
      await OQ0303(
        dispatch,
        classId,
        questionNo,
        question,
        characterClass,
        isRequired,
        inputOption,
        updateDate
      ).then((getData: any) => {
        if (
          getData &&
          Object.keys(getData).indexOf("state") >= 0 &&
          getData.state === "0"
        ) {
          setLoading(false);
          dispatch(push("/cls/007", { classId: sanitize(classId) }));
        } else {
          if (getData && Object.keys(getData).indexOf("error_code") >= 0) {
            setTopError({
              error: true,
              error_message:
                getData.error_code === ERROR_CODE_UPDATE_FAILED
                  ? messages.updateFailed
                  : getData.error_code === ERROR_CODE_EXCLUSIVE
                  ? messages.exclusiveError
                  : "",
            });
          }
        }
      });
      setLoading(false);
    } else {
      // 削除
      await OQ0304(dispatch, classId, questionNo, updateDate).then(
        (getData: any) => {
          if (
            getData &&
            Object.keys(getData).indexOf("state") >= 0 &&
            Object.keys(getData).indexOf("error_code") >= 0
          ) {
            if (getData.state !== "0") {
              setTopError({
                error: true,
                error_message:
                  getData.error_code === ERROR_CODE_DELETE_FAILED
                    ? messages.deleteFailed
                    : getData.error_code === ERROR_CODE_EXCLUSIVE
                    ? messages.exclusiveError
                    : "",
              });
            } else {
              setLoading(false);
              dispatch(push("/cls/007"));
            }
          }
        }
      );
      setLoading(false);
    }
  };

  const getListData = async (callback: any) => {
    let sessionDataValue = localStorage.getItem("Cls006_session");
    const sessionData =
      typeof props.location.state === "undefined"
        ? sessionDataValue
          ? JSON.parse(sessionDataValue)
          : sessionDataValue
        : props.location.state;
    if (typeof props.location.state !== "undefined") {
      localStorage.setItem(
        "Cls006_session",
        JSON.stringify(props.location.state)
      );
    }
    console.log("sessionData:" + JSON.stringify(sessionData));

    if (sessionData && Object.keys(sessionData).indexOf("class_id") >= 0) {
      setClassId(sessionData.class_id);
    }
    if (sessionData && Object.keys(sessionData).indexOf("action") >= 0) {
      setAction(sessionData.action);
    }
    if (
      sessionData &&
      Object.keys(sessionData).indexOf("character_class") >= 0
    ) {
      setCharacterClass(sessionData.character_class);
      let classValue = "";
      switch (sessionData.character_class) {
        case "1":
          classValue = "記述式";
          break;
        case "2":
          classValue = "ラジオボタン";
          break;
        case "3":
          classValue = "チェックボックス";
          break;
        case "4":
          classValue = "プルダウン";
          break;
        default:
          classValue = "";
          break;
      }
      setQuestionClassValue(classValue);
    }
    //if (
    // sessionData &&
    //Object.keys(sessionData).indexOf("question_class_value") >= 0
    //) {
    // setQuestionClassValue(sessionData.question_class_value);
    //}
    if (sessionData && Object.keys(sessionData).indexOf("is_required") >= 0) {
      setIsRequired(sessionData.is_required);
    }

    if (sessionData && Object.keys(sessionData).indexOf("question_no") >= 0) {
      setQuestionNo(sessionData.question_no);
    }
    if (sessionData && Object.keys(sessionData).indexOf("question") >= 0) {
      setQuestion(sessionData.question);
    }
    if (sessionData && Object.keys(sessionData).indexOf("option") >= 0) {
      setOption(sessionData.option);
    }
    if (
      sessionData &&
      Object.keys(sessionData).indexOf("option") >= 0 &&
      Object.keys(sessionData).indexOf("originOption") >= 0
    ) {
      const len =
        sessionData.originOption === undefined ||
        sessionData.option.length > sessionData.originOption
          ? sessionData.option.length
          : sessionData.originOption;
      setOption(sessionData.option);
      let data: any = {};
      for (let i = 0; i < len; i++) {
        data["option_" + String(i + 1)] =
          sessionData.option[i] !== undefined
            ? String(sessionData.option[i])
            : "";
      }

      setInputOption(data);
    }
    if (sessionData && Object.keys(sessionData).indexOf("update_date") >= 0) {
      setUpdateDate(sessionData.update_date);
    }
  };

  useEffect(() => {
    getListData(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const OptionBox: React.FC = () => {
    return (
      <Grid className="App-box">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={1} className="App-alignLeft">
            {FormatMessage({ id: "Cls006.Label.question" })}
          </Grid>
          <Grid
            item
            xs={12}
            sm={11}
            style={{ whiteSpace: "pre-line" }}
            className="App-text-no-width"
          >
            {question}
          </Grid>
        </Grid>
        <Grid container spacing={3} className="App-mT10">
          <Grid
            item
            xs={2}
            className="App-alignLeft"
            style={{ width: "300px", minWidth: "300px", maxWidth: "300px" }}
          >
            {FormatMessage({ id: "Cls006.Label.questionClass" })}
          </Grid>
          <Grid item xs={2} style={{ paddingLeft: "0px" }}>
            {questionClassValue}
          </Grid>
          <Grid item xs={2} className="App-alignLeft">
            {FormatMessage({ id: "Cls006.Label.Required" })}
            <Checkbox
              name="isrequired"
              size="small"
              color="default"
              checked={isRequired === IS_REQUIRED}
            />
          </Grid>
        </Grid>
        {option.length > 0 ? (
          <Grid
            item
            xs={12}
            className="App-mT10 App-text-no-width item-padding10"
            style={{ width: "100%" }}
          >
            {option.map((val: string, index: number) => (
              <div key={val} className="item-padding10">
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={1}>
                    {FormatMessage({ id: "Cls006.Label.option" })}
                    {index + 1}:
                  </Grid>
                  <Grid item xs={12} sm={11}>
                    {val}
                  </Grid>
                </Grid>
              </div>
            ))}
          </Grid>
        ) : null}
      </Grid>
    );
  };

  return (
    <MainLayout>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className="App-paper">
            <Typography
              component="h2"
              variant="h6"
              color="inherit"
              gutterBottom
            >
              {FormatMessage({ id: "Cls006.Title" })}
            </Typography>

            <Grid item xs={12} className="App-alignRight">
              <span
                hidden={!topError.error}
                style={{ float: "left", color: "red" }}
              >
                {topError.error_message}{" "}
              </span>
              <Button
                variant="contained"
                color="primary"
                className="App-button_bottom"
                startIcon={<ArrowBackIcon />}
                onClick={backCilck}
              >
                {FormatMessage({ id: "Button.EditBack" })}
              </Button>
            </Grid>
            {/*characterClass === "1" ? (
              <Grid className="App-box">
                <Grid container spacing={3} className="App-mT10">
                  <Grid
                    item
                    xs={2}
                    className="App-alignLeft"
                    style={{
                      width: "300px",
                      minWidth: "300px",
                      maxWidth: "300px",
                    }}
                  >
                    {FormatMessage({ id: "Cls006.Label.question" })}
                  </Grid>
                  <Grid item xs style={{ paddingLeft: "0px" }}>
                    {question}
                  </Grid>
                </Grid>
                <Grid container spacing={3} className="App-mT10">
                  <Grid
                    item
                    xs={2}
                    className="App-alignLeft"
                    style={{
                      width: "300px",
                      minWidth: "300px",
                      maxWidth: "300px",
                    }}
                  >
                    {FormatMessage({ id: "Cls006.Label.questionClass" })}
                  </Grid>
                  <Grid item xs={2} style={{ paddingLeft: "0px" }}>
                    {questionClassValue}
                  </Grid>
                </Grid>
                <Grid container spacing={3} className="App-mT10">
                  <Grid
                    item
                    xs={2}
                    className="App-alignLeft"
                    style={{
                      width: "300px",
                      minWidth: "300px",
                      maxWidth: "300px",
                    }}
                  ></Grid>

                  <Grid
                    item
                    xs={2}
                    className="App-alignLeft"
                    style={{ paddingLeft: "0px" }}
                  ></Grid>
                </Grid>
              </Grid>
            ) : (
              ""
            )*/}

            {characterClass === "1" ? (
              <OptionBox />
            ) : characterClass === "2" ? (
              <OptionBox />
            ) : characterClass === "3" ? (
              <OptionBox />
            ) : characterClass === "4" ? (
              <OptionBox />
            ) : (
              ""
            )}

            <Grid
              container
              spacing={3}
              className="App-mT10"
              style={{ margin: "0px" }}
            >
              <Grid item xs={12} className="App-alignCenter">
                <Button
                  variant="contained"
                  color="primary"
                  className="App-button_bottom"
                  startIcon={
                    action === "edit" ? (
                      <RefreshIcon />
                    ) : action === "add" ? (
                      <AddIcon />
                    ) : (
                      <DeleteForeverIcon />
                    )
                  }
                  onClick={checkCilck}
                >
                  {action === "edit"
                    ? FormatMessage({ id: "Button.Update" })
                    : action === "add"
                    ? FormatMessage({ id: "Button.Register" })
                    : FormatMessage({ id: "Button.Delete" })}
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      {loading ? <LoadingBox /> : null}
    </MainLayout>
  );
};
