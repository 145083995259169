import { apigw } from "../awsApi";
import { Dispatch } from "@reduxjs/toolkit";

const rows = {
    status:0,
    error_code : null,
    body:null
}

/**
 * Agora接続情報取得
 * @param {Dispatch} dispatch reducerディスパッチ
 * @param {string} classId 授業ID
 * @param {string} status 授業ステータス
 * @return コンポーネントHTMLコード内容
 */
export const ST0301 = async (
  dispatch: Dispatch,
  classId:string,
  status:string,
  startDate?:string
) => {
  if (process.env.REACT_APP_API_USE_FLG === "true") {
    // var date = new Date();
    // var str = date.getFullYear()
    //     + '/' + ('0' + (date.getMonth() + 1)).slice(-2)
    //     + '/' + ('0' + date.getDate()).slice(-2)
    //     + ' ' + ('0' + date.getHours()).slice(-2)
    //     + ':' + ('0' + date.getMinutes()).slice(-2)
    //     + ':' + ('0' + date.getSeconds()).slice(-2);

    // if(startDate){
    //   str = startDate;
    // }

    return apigw(
      "/ST0301",
      {
        class_id:classId,
        status_class:status,
        update_date:startDate
      },
      dispatch
    );
  } else {
    //サンプルデータ用
    return { body: rows };
  }
};