/**
 * 全角文字チェック
 * @param {string} str　チェックになる文字列
 * @returns true:正/false:否
 */
export const doubleByteCheck = (str: string) => {
  var zenkaku =
    /(?:[　！”＃＄％＆’（）＊＋，－．／：；＜＝＞？＠［￥］＾＿‘｛｜｝￣])|(?:[、。・゛゜´｀¨ヽヾゝゞ〃仝々〆〇ー―‐＼～〜∥…‥“〔〕〈〉《》「」『』【】±×÷≠≦≧∞∴♂♀°′″℃￠￡§☆★○●◎◇◇◆□■△▲▽▼※〒→←↑↓〓])|(?:[０-９])|(?:[Ａ-Ｚ])|(?:[ａ-ｚ])|(?:[ぁ-ん])|(?:[ァ-ヶ])|(?:[Α-Ωα-ω])|(?:[А-Яа-я])|(?:[\u2570-\u25ff])|(?:[\u3400-\u4dbf\u4e00-\u9fff\uf900-\ufaff])/;
  var check = str.match(zenkaku);
  if (check && check.length > 0) {
    return true;
  }
  return false;
};

/**
 * 全角文字から半角文字へ変換
 * @param {string} str　変換対象になる文字列
 * @returns 半角文字
 */
export const doubleByteChange = (str: string) => {
  return str
    .replace(/[あ]/g, () => {
      return "a";
    })
    .replace(/[い]/g, () => {
      return "i";
    })
    .replace(/[う]/g, () => {
      return "u";
    })
    .replace(/[え]/g, () => {
      return "e";
    })
    .replace(/[お]/g, () => {
      return "o";
    })
    .replace(/[Ａ-Ｚａ-ｚ０-９]/g, function (txt: string) {
      return String.fromCharCode(txt.charCodeAt(0) - 0xfee0);
    });
};

/**
 * 数字チェック
 * @param {string|number} value チェックになる値
 * @param {any} option オプション{​​​​​​​​​​​​​max:最大数値,min:最小数値}
 * @returns true:正/false:否
 */
export const isNumber = (value: string | number, option: any) => {
  if (!value || value === "") return false;
  var state = true;
  var checkValue = Number(value);
  if (checkValue.toString() === "NaN") state = false;
  if (state && option) {
    var max = 0,
      min = 0;
    if (Object.keys(option).indexOf("max") >= 0) {
      max = Number(option.max);
      if (checkValue <= max) {
        state = true;
      } else {
        state = false;
      }
    }
    if (state && Object.keys(option).indexOf("min") >= 0) {
      min = Number(option.min);
      if (checkValue >= min) {
        state = true;
      } else {
        state = false;
      }
    }
    if (state && min > 0 && max > 0) {
      if (checkValue <= max && checkValue >= min) {
        state = true;
      } else {
        state = false;
      }
    }
  }
  return state;
};

/**
 * メールアドレスチェック
 * @param {string} value チェックになる値
 * @returns true:正/false:否
 */
export const isEmail = (value: string) => {
  if (!value || value === "") return false;
  var state = false;
  var reg =
    /^[A-Za-z0-9.]{1}[A-Za-z0-9_.-]{1,}@{1}[A-Za-z0-9]{1}[A-Za-z0-9_-]*[.]{1}[A-Za-z0-9_.-]*[A-Za-z0-9]{1}$/;
  if (value.match(reg) && value.indexOf("..") < 0) {
    state = true;
  }
  return state;
};

/**
 * 桁数チェック
 * @param {string} value チェックになる値
 * @param {any} option オプション{​​​​​​​​​​​​​max:最大文字数,min:最小文字数}
 * @returns true:正/false:否
 */
export const checkCount = (value: string, option: any) => {
  if (!value || value === "") return false;
  var state = true;
  var re = /([\uD800-\uDBFF][\uDC00-\uDFFF])|[^]/g;
  var rn = value.match(re);
  var checkValue = 0;
  if (rn) checkValue = rn.length;
  if (option) {
    var max = 0,
      min = 0;
    if (Object.keys(option).indexOf("min") >= 0) {
      min = Number(option.min);
      if (checkValue >= min) {
        state = true;
      } else {
        state = false;
      }
    }
    if (state && Object.keys(option).indexOf("max") >= 0) {
      max = Number(option.max);
      if (checkValue <= max) {
        state = true;
      } else {
        state = false;
      }
    }
    if (state && min > 0 && max > 0) {
      if (checkValue <= max && checkValue >= min) {
        state = true;
      } else {
        state = false;
      }
    }
  }
  return state;
};

/**
 * バイトチェック
 * @param {string} value チェックになる値
 * @param {any} option オプション{max:最大byte数,min:最小byte数}
 * @returns true:正/false:否
 */
export const checkMultibyte = (value: string, option: any) => {
  if (!value || value === "") return false;
  var state = false;
  var checkValue = Number(
    encodeURIComponent(value).replace(/%../g, "x").length
  );
  if (option) {
    var max = 0,
      min = 0;
    if (Object.keys(option).indexOf("min") >= 0) {
      min = Number(option.min);
      if (checkValue >= min) {
        state = true;
      } else {
        state = false;
      }
    }
    if (state && Object.keys(option).indexOf("max") >= 0) {
      max = Number(option.max);
      if (checkValue <= max) {
        state = true;
      } else {
        state = false;
      }
    }
    if (state && min > 0 && max > 0) {
      if (checkValue <= max && checkValue >= min) {
        state = true;
      } else {
        state = false;
      }
    }
  }
  return state;
};

/**
 * CSVダウンロード
 * @param {any} data csv作成用データ（[{key:name,..},{}]）
 * @param {string} fileName ファイル名（拡張子不要）
 * @param {any} headerList Csvヘッダー作成用（[{key:name,..},{}]）
 * @param {any} noValueHeader Csvヘッダー作成用（[key,key...]）
 * @param {any} noValueBody Csvヘッダー作成用（[key,key...]）
 * @returns CSVダウンロードファイル
 */
export const downloadCsv = (
  data: any,
  fileName: string,
  headerList: any,
  noValueHeader: any,
  noValueBody: any
) => {
  if (
    data &&
    typeof data === "object" &&
    data.length > 0 &&
    fileName &&
    fileName.length > 0
  ) {
    var header = "";
    if (headerList && headerList.length > 0) {
      header = headerList
        .map(function (d: any) {
          return Object.keys(d)
            .map(function (key: any) {
              return noValueHeader.indexOf(key) >= 0 ? "" : d[key];
            })
            .join(",");
        })
        .join("\n");
    }
    var body = data
      .map(function (d: any) {
        return Object.keys(d)
          .map(function (key: any) {
            return noValueBody.indexOf(key) >= 0 ? "" : d[key];
          })
          .join(",");
      })
      .join("\n");
    //BOMを付与する（Excelでの文字化け対策）
    const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
    const blob = new Blob([bom, header + "\n" + body], { type: "text/csv" });

    //chrome以外用(download属性が機能しないためmsSaveBlobを使用）
    // if (window && window.navigator && window.navigator.msSaveBlob) {
    //   window.navigator.msSaveBlob(blob, fileName + ".csv");
    // } else {
    //BlobからオブジェクトURLを作成する
    const url = (window.URL || window.webkitURL).createObjectURL(blob);
    //ダウンロード用にリンクを作成する
    const download = document.createElement("a");
    //リンク先に上記で生成したURLを指定する
    download.href = url;
    //download属性にファイル名を指定する
    download.download = fileName + ".csv";
    //作成したリンクをクリックしてダウンロードを実行する
    download.click();
    //createObjectURLで作成したオブジェクトURLを開放する
    (window.URL || window.webkitURL).revokeObjectURL(url);
    // }
  }
};

/**
 * CSV読み込み
 * @param {string} dataPath ファイルパス
 * @param {function} callback CSV読み込み後の処理（CSVデータ）
 */
export const loadCsvFile = (dataPath: string, callback: any) => {
  var req = new XMLHttpRequest();
  req.open("get", dataPath, true);
  req.send(null);
  req.onload = function () {
    var result:any = [];
    var tmp = req.responseText.split("\n");
    for (var i = 0; i < tmp.length; ++i) {
      result[i] = tmp[i].split(",");
    }
    if (callback) callback(result);
  };
};

/**
 * サロゲートペア文字チェック
 * @param {string} value チェックになる値
 * @returns {string} val 利用できる文字列
 */
 export const InputBanChange = (value: string) => {
  var re = /([\uD800-\uDBFF][\uDC00-\uDFFF])|[^]/g;
  var textList = value.match(re);
  var newValue = "";
  if (textList) {
    for (let index = 0; index < textList.length; index++) {
      const element = textList[index];
      if (element.length === 1) {
          newValue = newValue + element;
      }
    }
  }
  return newValue;
};

/**
 * 下記のエスケープ文字を復元
 * 小なり、大なり、アンパサンド、ノーブレークスペース、フォントサイズの半分のスペース、フォントサイズのスペース、フォントサイズ半分のダッシュ、フォントサイズのダッシュ、水平線
 * @param data
 * @returns
 */
 export const unescapeStr = (data: any) => {
  let result = "";
  const regex1 = /&lt;/gi;
  const regex2 = /&gt;/gi;
  const regex3 = /&amp;/gi;
  const regex4 = /&nbsp;/gi;
  const regex5 = /&ensp;/gi;
  const regex6 = /&emsp;/gi;
  const regex7 = /&ndash;/gi;
  const regex8 = /&mdash;/gi;
  const regex9 = /<hr \/>/gi;
  if (data !== null) {
    if (data.length > 0) {
      if (typeof data === "string" || data instanceof String) {
        result = data
          .replace(regex1, "<")
          .replace(regex2, ">")
          .replace(regex3, "&")
          .replace(regex4, " ")
          .replace(regex5, " ")
          .replace(regex6, " ")
          .replace(regex7, "–")
          .replace(regex8, "—")
          .replace(regex9, "<hr>");
      }
    }
    return result;
  }
  return data;
};

