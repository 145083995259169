import { useEffect, useCallback, useState } from "react";
import { useDrop } from "react-dnd";
import { Card } from "./cardImg";
import { CardVdo } from "./cardVdo";
import { CardPdf } from "./cardPdf";
import { CardAud } from "./cardAud";
import CircularProgress from "@material-ui/core/CircularProgress";
import update from "immutability-helper";
import {
  createS3,
  getListS3,
  // getUrlS3,
} from "../../components/function/AwsS3Fun";

/**
 * 資料アップロード
 * PDF DnD 並び替えコンポーネント
 */

export const ItemTypes = {
  CARD: "card",
};

export interface ContainerState {
  cards: any[];
}

type pdfData = {
  download_document_id: string;
  download_document_name: string;
  s3_id: string;
  document_type: string;
  file_url: string;
  isUpLoading?: boolean;
};

type PdfDrop = {
  pdfList?: pdfData[];
  callback?: any;
};
let vList: any[];

export const PdfDropBox: React.FC<PdfDrop> = ({
  pdfList = [],
  callback = () => {},
}: PdfDrop) => {
  const [cards, setCards] = useState(pdfList);
  const [selected, setSelected] = useState<String>();
  const findCard = useCallback(
    (id: string) => {
      const card = cards.filter((c) => `${c.download_document_id}` === id)[0];
      return {
        card,
        index: cards.indexOf(card),
      };
    },
    [cards]
  );

  const moveCard = useCallback(
    (id: string, atIndex: number) => {
      const { card, index } = findCard(id);
      setCards(
        update(cards, {
          $splice: [
            [index, 1],
            [atIndex, 0, card],
          ],
        })
      );
      if (callback)
        callback(
          "2",
          update(cards, {
            $splice: [
              [index, 1],
              [atIndex, 0, card],
            ],
          })
        );
    },
    [findCard, cards, setCards, callback]
  );

  const [, drop] = useDrop(() => ({ accept: ItemTypes.CARD }));
  const ClickCallback = (id: string) => {
    setSelected(id);
    const { index } = findCard(id);
    if (callback) callback("1", index);
  };

  const checkUrl = async (data: any, index: number) => {
    try {
      const s3 = await createS3();
      if (s3) {
        const list: any = await getListS3(s3, data.s3_id);
        if (list && list.length > 0) {
          vList[index].isUpLoading = true;
          setCards([]);
          setTimeout(() => setCards(vList), 100);
          return;
        }
      }
      setTimeout(() => checkUrl(data, index), 5000);
    } catch (error: any) {
      setTimeout(() => checkUrl(data, index), 5000);
    }
  };

  const reloadCheck = () => {
    if (vList.length > 0) {
      for (let index = 0; index < vList.length; index++) {
        if (!vList[index].isUpLoading) {
          checkUrl(vList[index], index);
        }
      }
    }
  };

  const checkDataList = () => {
    if (pdfList.length > 0) {
      setCards(pdfList);
      vList = pdfList;
      reloadCheck();
    }
  };

  useEffect(() => {
    vList = [];
    checkDataList();
  }, []);

  return (
    <div ref={drop} className="App-Image-DropArea">
      {cards.map((card, index) => (
        <div
          key={"pdf_show_" + index}
          className={
            selected === `${card.download_document_id}` ||
            (!selected && index === 0)
              ? "App-Image-DropItem-Selected "
              : "App-Image-DropItem"
          }
        >
          {card.document_type === "1" ? (
            <Card
              key={card.download_document_id}
              id={`${card.download_document_id}`}
              url={card.file_url}
              moveCard={moveCard}
              findCard={findCard}
              className={""}
              onClick={ClickCallback}
            />
          ) : card.document_type === "2" ? (
            !card.isUpLoading ? (
              <CircularProgress />
            ) : (
              <CardVdo
                key={card.download_document_id}
                id={`${card.download_document_id}`}
                url={card.file_url}
                moveCard={moveCard}
                findCard={findCard}
                className=""
                onClick={ClickCallback}
              />
            )
          ) : card.document_type === "3" ? (
            <CardPdf
              key={card.download_document_id}
              id={`${card.download_document_id}`}
              url={card.file_url}
              moveCard={moveCard}
              findCard={findCard}
              onClick={ClickCallback}
              className=""
            />
          ) : card.document_type === "4" ? (
            <CardAud
              key={card.download_document_id}
              id={`${card.download_document_id}`}
              fileName={card.download_document_name}
              moveCard={moveCard}
              findCard={findCard}
              className={""}
              onClick={ClickCallback}
            />
          ) : null}
        </div>
      ))}
    </div>
  );
};
