import { store } from "../../reducks/store";
import { addNotification } from "../../reducks/notificationSlice";
import { getMessagesId } from "../getMessagesId";

export const checkData = (responseData: any, api: any) => {
  if (responseData && Object.keys(responseData).indexOf("state") >= 0) {
    if (Number(responseData.state) === 1) return false; //1:正常終了(返却値なし)
  }
  if (responseData && Object.keys(responseData).indexOf("error_code") >= 0) {
    if (responseData.error_code !== "") {
      var textID = "";
      var systemErr = true;
      switch (responseData.error_code) {
        case "01001":
          textID = "API_ERROR_CODE_1001";
          break;
        case "01002":
          textID = "API_ERROR_CODE_1002";
          break;
        case "01003":
          textID = "API_ERROR_CODE_1003";
          break;
        case "01004":
          systemErr = false;
          textID = "API_ERROR_CODE_1004";
          break;
        case "02001":
          textID = "API_ERROR_CODE_2001";
          break;
        case "02002":
          textID = "API_ERROR_CODE_2002";
          break;
        case "02003":
          textID = "API_ERROR_CODE_2003";
          break;
        case "02004":
          textID = "API_ERROR_CODE_2004";
          break;
        case "02005":
          textID = "API_ERROR_CODE_2005";
          break;
        case "02006":
          textID = "API_ERROR_CODE_2006";
          break;
        case "03001":
          textID = "API_ERROR_CODE_3001";
          break;
        case "03002":
          textID = "API_ERROR_CODE_3002";
          break;
        case "03003":
          textID = "API_ERROR_CODE_3003";
          break;
        case "09001":
          textID = "API_ERROR_CODE_9001";
          break;
        case "09002":
          textID = "API_ERROR_CODE_9002";
          break;
        case "09003":
          textID = "API_ERROR_CODE_9003";
          break;
        case "09004":
          textID = "API_ERROR_CODE_9004";
          break;
        case "09005":
          textID = "API_ERROR_CODE_9005";
          break;
        case "09006":
          textID = "API_ERROR_CODE_9006";
          break;
        case "09007":
          textID = "API_ERROR_CODE_9007";
          break;
        case "09008":
          textID = "API_ERROR_CODE_9008";
          break;
        case "09009":
          textID = "API_ERROR_CODE_9009";
          break;
        case "09010":
          textID = "API_ERROR_CODE_9010";
          break;
        case "09011":
          textID = "API_ERROR_CODE_9011";
          break;
        case "09013":
          textID = "API_ERROR_CODE_9013";
          break;

        default:
          systemErr = false;
          break;
      }
      if (systemErr) {
        const session = store.getState().user;
        let path = store.getState().router.location.pathname;
        if (path !== "/cls/008" && path !== "/cls/012" && path !== "/cls/013") {
          if (session) {
            if (
              session.service_id !== "" ||
              api === "AU0301" ||
              api === "AU0305" ||
              api === "CF0902"
            ) {
              const timeOutFlg = sessionStorage.getItem("time_out");
              if (!timeOutFlg) {
                if (responseData.error_code === "01001") {
                  sessionStorage.setItem("time_out", "true");
                }
                store.dispatch(
                  addNotification({
                    message:
                      "[" +
                      api +
                      "]" +
                      "[" +
                      responseData.error_code +
                      "]" +
                      getMessagesId(textID, store.getState().user.language),
                    variant: "error",
                  })
                );
              }
            }
          }
          return responseData.error_code;
        }
      }
    }
  }

  return true;
};
