import React from "react";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { TextFieldBox } from "../../components/atoms/TextFieldBox";
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
//import { TextFieldBox } from "../../components/atoms/TextFieldBox";

import { FormatMessage } from "../../components/atoms/FormatMessage";
import { FormControl, MenuItem, Select, Typography } from "@material-ui/core";
import { CT0305 } from "../../utils/api/CT0305";
import { CT0306 } from "../../utils/api/CT0306";
import TextField from "@material-ui/core/TextField";
import "../../css/cls/cls010.css";
import { LoadingBox } from "../../components/atoms/LoadingBox";

export const Cls010 = (props: any) => {
  const dispatch = useDispatch();
  const { ClassId, ClassName, Open, HandleClickClose } = props;
  const [rows, setRows] = useState<any[]>([]);
  const [newRows, setNewRows] = useState<any[]>([]);

  const [exlusiveControlErr, setExlusiveControlErr] = useState(false);
  //loadingフラグ
  const [loading, setLoading] = useState(false);

  const HandleClickUpdate = () => {
    upDateData();
  };

  const handleClose = () => {
    rowsReset();
    props.HandleClickClose();
  };

  //日付の変更
  const handleDateChange = (value: any, row: any, releaseDate: any) => {
    if (
      rows.find(
        (item) => item.download_document_id === row.download_document_id
      )
    ) {
      const result = rows.find(
        (item) => item.download_document_id === row.download_document_id
      );
      let newRow: any;
      if (
        Object.keys(result).indexOf("release_date") >= 0 &&
        result.release_date !== undefined
      ) {
        const date = new Date(result.release_date);
        newRow = rows
          .filter(
            (item) => item.download_document_id !== row.download_document_id
          )
          .concat({
            download_document_id: row.download_document_id,
            download_document_name: row.download_document_name,
            release_date: new Date(
              value.substr(0, 4),
              Number(value.substr(5, 2)) - 1,
              Number(value.substr(8, 2)),
              date.getHours(),
              date.getMinutes()
            ),
            update_date: result.update_date,
          });
      } else {
        newRow = rows
          .filter(
            (item) => item.download_document_id !== row.download_document_id
          )
          .concat({
            download_document_id: row.download_document_id,
            download_document_name: row.download_document_name,
            release_date: new Date(
              value.substr(0, 4),
              Number(value.substr(5, 2)) - 1,
              Number(value.substr(8, 2)),
              0,
              0
            ),
            update_date: result.update_date,
          });
      }
      setRows(
        newRow.sort(function (a: any, b: any) {
          if (a.download_document_id < b.download_document_id) return -1;
          if (a.download_document_id > b.download_document_id) return 1;
          return 0;
        })
      );
    }
    if (
      newRows.find(
        (item) => item.download_document_id === row.download_document_id
      )
    ) {
      const result = newRows.find(
        (item) => item.download_document_id === row.download_document_id
      );
      if (Object.keys(result).indexOf("release_date") >= 0) {
        const date = new Date(result.release_date);
        const newRow = newRows
          .filter(
            (item) => item.download_document_id !== row.download_document_id
          )
          .concat({
            download_document_id: row.download_document_id,
            download_document_name: row.download_document_name,
            release_date: new Date(
              value.substr(0, 4),
              Number(value.substr(5, 2)) - 1,
              Number(value.substr(8, 2)),
              date.getHours(),
              date.getMinutes()
            ),
            update_date: result.update_date,
          });
        setNewRows(newRow);
      }
    } else if (
      Object.keys(row).indexOf("release_date") >= 0 &&
      row.release_date !== undefined
    ) {
      const newRow = newRows
        .filter(
          (item) => item.download_document_id !== row.download_document_id
        )
        .concat({
          download_document_id: row.download_document_id,
          download_document_name: row.download_document_name,
          release_date: new Date(
            value.substr(0, 4),
            Number(value.substr(5, 2)) - 1,
            Number(value.substr(8, 2)),
            releaseDate.getHours(),
            releaseDate.getMinutes()
          ),
          update_date: row.update_date,
        });
      setNewRows(newRow);
    } else {
      const newRow = newRows
        .filter(
          (item) => item.download_document_id !== row.download_document_id
        )
        .concat({
          download_document_id: row.download_document_id,
          download_document_name: row.download_document_name,
          release_date: new Date(
            value.substr(0, 4),
            Number(value.substr(5, 2)) - 1,
            Number(value.substr(8, 2)),
            0,
            0
          ),
          update_date: row.update_date,
        });
      setNewRows(newRow);
    }
  };

  //時間（時）の変更
  const handleHourChange = (value: any, row: any, releaseDate: any) => {
    if (
      rows.find(
        (item) => item.download_document_id === row.download_document_id
      )
    ) {
      const result = rows.find(
        (item) => item.download_document_id === row.download_document_id
      );
      let newRow: any;
      if (
        Object.keys(result).indexOf("release_date") >= 0 &&
        result.release_date !== undefined
      ) {
        const date = new Date(result.release_date);
        newRow = rows
          .filter(
            (item) => item.download_document_id !== row.download_document_id
          )
          .concat({
            download_document_id: row.download_document_id,
            download_document_name: row.download_document_name,
            release_date: new Date(
              date.getFullYear(),
              date.getMonth(),
              date.getDate(),
              value,
              date.getMinutes()
            ),
            update_date: result.update_date,
          });
      } else {
        newRow = rows
          .filter(
            (item) => item.download_document_id !== row.download_document_id
          )
          .concat({
            download_document_id: row.download_document_id,
            download_document_name: row.download_document_name,
            release_date: new Date(0, 0, 0, value, 0),
            update_date: result.update_date,
          });
      }
      setRows(
        newRow.sort(function (a: any, b: any) {
          if (a.download_document_id < b.download_document_id) return -1;
          if (a.download_document_id > b.download_document_id) return 1;
          return 0;
        })
      );
    }
    if (
      newRows.find(
        (item) => item.download_document_id === row.download_document_id
      )
    ) {
      const result = newRows.find(
        (item) => item.download_document_id === row.download_document_id
      );
      if (Object.keys(result).indexOf("release_date") >= 0) {
        const newRow = newRows
          .filter(
            (item) => item.download_document_id !== row.download_document_id
          )
          .concat({
            download_document_id: row.download_document_id,
            download_document_name: row.download_document_name,
            release_date: new Date(
              result.release_date.getFullYear(),
              result.release_date.getMonth(),
              result.release_date.getDate(),
              value,
              result.release_date.getMinutes()
            ),
            update_date: result.update_date,
          });
        setNewRows(newRow);
      }
    } else if (
      Object.keys(row).indexOf("release_date") >= 0 &&
      row.release_date !== undefined
    ) {
      const date = new Date(releaseDate);
      const newRow = newRows
        .filter(
          (item) => item.download_document_id !== row.download_document_id
        )
        .concat({
          download_document_id: row.download_document_id,
          download_document_name: row.download_document_name,
          release_date: new Date(
            new Date(releaseDate).getFullYear(),
            date.getMonth(),
            date.getDate(),
            value,
            date.getMinutes()
          ),
          update_date: row.update_date,
        });
      setNewRows(newRow);
    } else {
      const newRow = newRows
        .filter(
          (item) => item.download_document_id !== row.download_document_id
        )
        .concat({
          download_document_id: row.download_document_id,
          download_document_name: row.download_document_name,
          release_date: new Date(0, 0, 0, value),
          update_date: row.update_date,
        });
      setNewRows(newRow);
    }
  };

  //時間（分）の変更
  const handleMinuteChange = (value: any, row: any, releaseDate: any) => {
    if (
      rows.find(
        (item) => item.download_document_id === row.download_document_id
      )
    ) {
      const result = rows.find(
        (item) => item.download_document_id === row.download_document_id
      );
      let newRow: any;
      if (
        Object.keys(result).indexOf("release_date") >= 0 &&
        result.release_date !== undefined
      ) {
        const date = new Date(result.release_date);
        newRow = rows
          .filter(
            (item) => item.download_document_id !== row.download_document_id
          )
          .concat({
            download_document_id: row.download_document_id,
            download_document_name: row.download_document_name,
            release_date: new Date(
              date.getFullYear(),
              date.getMonth(),
              date.getDate(),
              date.getHours(),
              value
            ),
            update_date: result.update_date,
          });
      } else {
        newRow = rows
          .filter(
            (item) => item.download_document_id !== row.download_document_id
          )
          .concat({
            download_document_id: row.download_document_id,
            download_document_name: row.download_document_name,
            release_date: new Date(0, 0, 0, 0, value),
            update_date: result.update_date,
          });
      }
      setRows(
        newRow.sort(function (a: any, b: any) {
          if (a.download_document_id < b.download_document_id) return -1;
          if (a.download_document_id > b.download_document_id) return 1;
          return 0;
        })
      );
    }
    if (
      newRows.find(
        (item) => item.download_document_id === row.download_document_id
      )
    ) {
      const result = newRows.find(
        (item) => item.download_document_id === row.download_document_id
      );
      if (Object.keys(result).indexOf("release_date") >= 0) {
        const newRow = newRows
          .filter(
            (item) => item.download_document_id !== row.download_document_id
          )
          .concat({
            download_document_id: row.download_document_id,
            download_document_name: row.download_document_name,
            release_date: new Date(
              result.release_date.getFullYear(),
              result.release_date.getMonth(),
              result.release_date.getDate(),
              result.release_date.getHours(),
              value
            ),
            update_date: result.update_date,
          });
        setNewRows(newRow);
      }
    } else if (
      Object.keys(row).indexOf("release_date") >= 0 &&
      row.release_date !== undefined
    ) {
      const newRow = newRows
        .filter(
          (item) => item.download_document_id !== row.download_document_id
        )
        .concat({
          download_document_id: row.download_document_id,
          download_document_name: row.download_document_name,
          release_date: new Date(
            releaseDate.getFullYear(),
            releaseDate.getMonth(),
            releaseDate.getDate(),
            releaseDate.getHours(),
            value
          ),
          update_date: row.update_date,
        });
      console.log(releaseDate.getHours());
      setNewRows(newRow);
    } else {
      const newRow = newRows
        .filter(
          (item) => item.download_document_id !== row.download_document_id
        )
        .concat({
          download_document_id: row.download_document_id,
          download_document_name: row.download_document_name,
          release_date: new Date(0, 0, 0, 0, value),
          update_date: row.update_date,
        });
      setNewRows(newRow);
    }
  };

  //入力値のリセット
  const rowsReset = () => {
    setNewRows([]);
    setExlusiveControlErr(false);
  };

  useEffect(() => {
    //配布資料情報取得
    const getDocumentData = async (ClassId: any) => {
      if (Open) {
        setLoading(true);
        let getData = await CT0305(dispatch, ClassId);
        if (getData && Object.keys(getData).indexOf("body") >= 0) {
          let firstRows = [];
          for (const elem of getData.body) {
            if (elem.release_date) {
              let date = new Date(elem.release_date);
              firstRows.push({
                download_document_id: elem.download_document_id,
                download_document_name: elem.download_document_name,
                release_date: new Date(
                  new Date(elem.release_date).getFullYear(),
                  date.getMonth(),
                  date.getDate(),
                  date.getHours() - 9,
                  date.getMinutes()
                ),
                update_date: elem.update_date,
              });
            } else {
              firstRows.push({
                download_document_id: elem.download_document_id,
                download_document_name: elem.download_document_name,
                update_date: elem.update_date,
              });
            }
          }
          setRows(firstRows);
          setLoading(false);
        }
      }
    };
    getDocumentData(ClassId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Open]);

  //更新
  const upDateData = async () => {
    setLoading(true);
    let err = false;
    for (const elem of newRows) {
      if (Object.keys(elem).indexOf("release_date") >= 0) {
        if (elem.release_date.getFullYear() < 1900) {
          let download_document_id = elem.download_document_id;
          let update_date = elem.update_date;
          let updateData = await CT0306(
            dispatch,
            download_document_id,
            "",
            update_date
          );
          if (updateData && Object.keys(updateData).indexOf("state") >= 0) {
            if (updateData.state !== "0") {
              err = true;
              if (
                updateData &&
                Object.keys(updateData).indexOf("error_code") >= 0
              ) {
                if (updateData.error_code === "04070") {
                  setExlusiveControlErr(true);
                }
              }
              setLoading(false);
            }
          }
          setLoading(false);
        } else {
          let download_document_id = elem.download_document_id;
          let release_date = formatChangeDateTime(
            elem.release_date.getFullYear(),
            elem.release_date.getMonth() + 1,
            elem.release_date.getDate(),
            elem.release_date.getHours(),
            elem.release_date.getMinutes()
          );
          let update_date = elem.update_date;
          let updateData = await CT0306(
            dispatch,
            download_document_id,
            release_date,
            update_date
          );
          if (updateData && Object.keys(updateData).indexOf("state") >= 0) {
            if (updateData.state !== "0") {
              err = true;
              if (
                updateData &&
                Object.keys(updateData).indexOf("error_code") >= 0
              ) {
                if (updateData.error_code === "04070") {
                  setExlusiveControlErr(true);
                }
              }
              setLoading(false);
            }
          }
        }
      }
    }
    if (err === false) {
      setLoading(false);
      rowsReset();
      HandleClickClose();
    }
  };

  //Date型を文字列に変換(日付のみ)
  const formatChangeDate = (year: any, month: any, date: any) => {
    let format_str = "YYYY-MM-DD";
    format_str = format_str.replace(/YYYY/g, year);
    format_str = format_str.replace(/MM/g, ("0" + month).slice(-2));
    format_str = format_str.replace(/DD/g, ("0" + date).slice(-2));
    return format_str;
  };

  //Date型を文字列に変換（時間あり）
  const formatChangeDateTime = (
    year: any,
    month: any,
    date: any,
    hour: any,
    minute: any
  ) => {
    let format_str = "YYYY-MM-DD hh:mm:00";
    format_str = format_str.replace(/YYYY/g, year);
    format_str = format_str.replace(/MM/g, ("0" + month).slice(-2));
    format_str = format_str.replace(/DD/g, ("0" + date).slice(-2));
    format_str = format_str.replace(/hh/g, ("0" + hour).slice(-2));
    format_str = format_str.replace(/mm/g, ("0" + minute).slice(-2));
    return format_str;
  };

  return (
    <Dialog open={Open} onClose={HandleClickClose} fullWidth>
      <DialogTitle id="alert-dialog-title">
        {FormatMessage({ id: "Cls010.Title" })}
        <Typography component="h2" variant="subtitle1" color="error">
          {exlusiveControlErr ? (
            <div> {FormatMessage({ id: "Error.ExlusiveControl" })}</div>
          ) : null}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {FormatMessage({ id: "Cls010.Label" })}
          {ClassName}
        </DialogContentText>
        <Table size="small" className="table">
          <TableHead>
            <TableRow>
              <TableCell>ファイル名</TableCell>
              <TableCell>日付</TableCell>
              <TableCell className="table-time">時間</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              ? rows.map((row: any) => (
                <TableRow key={row.download_document_id}>
                  <TableCell>{row.download_document_name}</TableCell>
                  <TableCell>
                    <TextField
                      type="date"
                      value={
                        row.release_date
                          ? row.release_date.getFullYear() > 1900
                            ? formatChangeDate(
                              new Date(row.release_date).getFullYear(),
                              new Date(row.release_date).getMonth() + 1,
                              new Date(row.release_date).getDate()
                            )
                            : ""
                          : ""
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => {
                        handleDateChange(
                          e.target.value,
                          row,
                          row.release_date
                        );
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <FormControl>
                      <Select
                        className="demo-width-class"
                        value={
                          row.release_date
                            ? new Date(row.release_date).getHours()
                            : ""
                        }
                        inputProps={{
                          shrink: true,
                        }}
                        onChange={(e) => {
                          handleHourChange(
                            e.target.value,
                            row,
                            row.release_date
                          );
                        }}
                      >
                        <MenuItem value={0}>00</MenuItem>
                        <MenuItem value={1}>01</MenuItem>
                        <MenuItem value={2}>02</MenuItem>
                        <MenuItem value={3}>03</MenuItem>
                        <MenuItem value={4}>04</MenuItem>
                        <MenuItem value={5}>05</MenuItem>
                        <MenuItem value={6}>06</MenuItem>
                        <MenuItem value={7}>07</MenuItem>
                        <MenuItem value={8}>08</MenuItem>
                        <MenuItem value={9}>09</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={11}>11</MenuItem>
                        <MenuItem value={12}>12</MenuItem>
                        <MenuItem value={13}>13</MenuItem>
                        <MenuItem value={14}>14</MenuItem>
                        <MenuItem value={15}>15</MenuItem>
                        <MenuItem value={16}>16</MenuItem>
                        <MenuItem value={17}>17</MenuItem>
                        <MenuItem value={18}>18</MenuItem>
                        <MenuItem value={19}>19</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={21}>21</MenuItem>
                        <MenuItem value={22}>22</MenuItem>
                        <MenuItem value={23}>23</MenuItem>
                      </Select>
                    </FormControl>
                    :
                    <FormControl>
                      <Select
                        className="demo-width-class"
                        value={
                          row.release_date
                            ? new Date(row.release_date).getMinutes()
                            : ""
                        }
                        inputProps={{
                          shrink: true,
                        }}
                        onChange={(e) => {
                          handleMinuteChange(
                            e.target.value,
                            row,
                            row.release_date
                          );
                        }}
                      >
                        <MenuItem value={0}>00</MenuItem>
                        <MenuItem value={1}>01</MenuItem>
                        <MenuItem value={2}>02</MenuItem>
                        <MenuItem value={3}>03</MenuItem>
                        <MenuItem value={4}>04</MenuItem>
                        <MenuItem value={5}>05</MenuItem>
                        <MenuItem value={6}>06</MenuItem>
                        <MenuItem value={7}>07</MenuItem>
                        <MenuItem value={8}>08</MenuItem>
                        <MenuItem value={9}>09</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={11}>11</MenuItem>
                        <MenuItem value={12}>12</MenuItem>
                        <MenuItem value={13}>13</MenuItem>
                        <MenuItem value={14}>14</MenuItem>
                        <MenuItem value={15}>15</MenuItem>
                        <MenuItem value={16}>16</MenuItem>
                        <MenuItem value={17}>17</MenuItem>
                        <MenuItem value={18}>18</MenuItem>
                        <MenuItem value={19}>19</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={21}>21</MenuItem>
                        <MenuItem value={22}>22</MenuItem>
                        <MenuItem value={23}>23</MenuItem>
                        <MenuItem value={24}>24</MenuItem>
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={26}>26</MenuItem>
                        <MenuItem value={27}>27</MenuItem>
                        <MenuItem value={28}>28</MenuItem>
                        <MenuItem value={29}>29</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                        <MenuItem value={31}>31</MenuItem>
                        <MenuItem value={32}>32</MenuItem>
                        <MenuItem value={33}>33</MenuItem>
                        <MenuItem value={34}>34</MenuItem>
                        <MenuItem value={35}>35</MenuItem>
                        <MenuItem value={36}>36</MenuItem>
                        <MenuItem value={37}>37</MenuItem>
                        <MenuItem value={38}>38</MenuItem>
                        <MenuItem value={39}>39</MenuItem>
                        <MenuItem value={40}>40</MenuItem>
                        <MenuItem value={41}>41</MenuItem>
                        <MenuItem value={42}>42</MenuItem>
                        <MenuItem value={43}>43</MenuItem>
                        <MenuItem value={44}>44</MenuItem>
                        <MenuItem value={45}>45</MenuItem>
                        <MenuItem value={46}>46</MenuItem>
                        <MenuItem value={47}>47</MenuItem>
                        <MenuItem value={48}>48</MenuItem>
                        <MenuItem value={49}>49</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={51}>51</MenuItem>
                        <MenuItem value={52}>52</MenuItem>
                        <MenuItem value={53}>53</MenuItem>
                        <MenuItem value={54}>54</MenuItem>
                        <MenuItem value={55}>55</MenuItem>
                        <MenuItem value={56}>56</MenuItem>
                        <MenuItem value={57}>57</MenuItem>
                        <MenuItem value={58}>58</MenuItem>
                        <MenuItem value={59}>59</MenuItem>
                      </Select>
                    </FormControl>
                  </TableCell>
                </TableRow>
              ))
              : ""}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions className="App-button">
        <Button
          variant="contained"
          color="primary"
          size="small"
          className="App-button_bottom"
          startIcon={<CloseIcon />}
          onClick={handleClose}
        >
          {FormatMessage({ id: "Button.Close" })}
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          className="App-button_bottom"
          startIcon={<AddIcon />}
          style={{ marginLeft: "10px" }}
          onClick={HandleClickUpdate}
        >
          {FormatMessage({ id: "Button.Register" })}
        </Button>
      </DialogActions>
      {loading ? <LoadingBox /> : null}
    </Dialog>
  );
};
