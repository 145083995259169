import { apigw } from "../awsApi";
import { Dispatch } from "@reduxjs/toolkit";

const rows = {
    system_version: "1.0.0",
		service_id: "",
		terms_of_use_url: "https://www.google.com/search?q=%E5%88%A9%E7%94%A8%E8%A6%8F%E7%B4%84&hl=ja&source=hp&ei=4Fc8YY2GN8z_-gTzjoIQ&iflsig=ALs-wAMAAAAAYTxl8QDnyzMGNwMbKM2Lg4JUdebrYW1u&oq=%E5%88%A9%E7%94%A8%E8%A6%8F%E7%B4%84&gs_lcp=Cgdnd3Mtd2l6EAMyBQgAEIAEMgUIABCABDIFCAAQgAQyBQgAEIAEMgUIABCABDIFCAAQgAQyBQgAEIAEMgUIABCABFDmYVjmYWCCZGgBcAB4AIABdogBdpIBAzAuMZgBAKABAqABAbABAA&sclient=gws-wiz&ved=0ahUKEwiN9q2fsPbyAhXMv54KHXOHAAIQ4dUDCAk&uact=5",
		privacy_policy_url: "https://www.google.com/search?q=%E3%83%97%E3%83%A9%E3%82%A4%E3%83%90%E3%82%B7%E3%83%BC%E3%83%9D%E3%83%AA%E3%82%B7%E3%83%BC&hl=ja&ei=7lc8YY7oB4iK0gTn7rSICw&oq=%E3%83%97%E3%83%A9%E3%82%A4%E3%83%90%E3%82%B7%E3%83%BC%E3%83%9D%E3%83%AA%E3%82%B7%E3%83%BC&gs_lcp=Cgdnd3Mtd2l6EAMyBQgAEIAEMgsIABCABBCxAxCxAzIFCAAQgAQyBAgAEEMyBQgAEIAEMgUIABCABDIFCAAQgAQyBAgAEEM6BwgAEEcQsAM6DQgAEIAEELEDEIMBEAQ6CwgAEIAEELEDEIMBOgcIABCABBAEOggIABCABBCxAzoKCAAQgAQQsQMQBDoKCAAQsQMQsQMQQzoHCAAQsQMQQ0oECEEYAFCxrQFYy80BYITQAWgCcAJ4AIABtgGIAckUkgEEMS4xOJgBAKABAbABAMgBCMABAQ&sclient=gws-wiz&ved=0ahUKEwiOl9WlsPbyAhUIhZQKHWc3DbEQ4dUDCA4&uact=5",
		help_support_url: "https://www.google.com/search?q=%E3%83%98%E3%83%AB%E3%83%97%E3%82%B5%E3%83%9D%E3%83%BC%E3%83%88&hl=ja&ei=CVg8YbquHpiFr7wP4tK5sAw&oq=%E3%83%98%E3%83%AB%E3%83%97%E3%82%B5%E3%83%9D%E3%83%BC%E3%83%88&gs_lcp=Cgdnd3Mtd2l6EAMyBQgAEIAEMgUIABCABDIFCAAQgAQyBQgAEIAEOgcIABBHELADOgoIABCABBCxAxAEOgcIABCABBAEOg0IABCABBCxAxCDARAEOgkIABCABBAEEAo6BAgAEA06CAgAEIAEELEDOgYIABAEEAM6BAgAEEM6CwgAEIAEELEDELEDOhEIABCABBCxAxCDARCxAxCDAToGCAAQBBAeSgQIQRgAUKlYWMOeAWCSqQFoDnACeACAAagBiAHvGJIBBDAuMjOYAQCgAQGwAQDIAQjAAQE&sclient=gws-wiz&ved=0ahUKEwj61tuysPbyAhWYwosBHWJpDsYQ4dUDCA4&uact=5",
		management_company_url: "https://www.google.com/search?q=%E9%81%8B%E5%96%B6%E4%BC%9A%E7%A4%BE&hl=ja&ei=H1g8YZ_kONmHr7wPqMmHoAs&oq=%E9%81%8B%E5%96%B6%E4%BC%9A%E7%A4%BE&gs_lcp=Cgdnd3Mtd2l6EAMyBQgAEIAEMgUIABCABDIFCAAQgAQyBQgAEIAEMgUIABCABDIFCAAQgAQyBQgAEIAEMgUIABCABDoICAAQgAQQsAM6CQgAELADEAcQHjoKCAAQgAQQsQMQBDoNCAAQgAQQsQMQgwEQBDoLCAAQgAQQsQMQsQM6BwgAEIAEEAQ6DAgAEIAEEAQQRhD_AToECAAQQzoGCAAQBBAeOgQIABAeOggIABAFEAQQHkoECEEYAVDAVFi1aWCAa2gCcAB4AIABtAGIAdwMkgEEMS4xMZgBAKABAbABAMgBCMABAQ&sclient=gws-wiz&ved=0ahUKEwjf77S9sPbyAhXZw4sBHajkAbQQ4dUDCA4&uact=5"
};

/**
 * ログイン画面のフッター情報
 * @param {Dispatch} dispatch reducerディスパッチ

 * @return コンポーネントHTMLコード内容
 */
export const CF0902 = async (
    dispatch: Dispatch,
    subdomain:string | undefined,  
    user_class: string,
  ) => {
  if (process.env.REACT_APP_API_USE_FLG === "true") {
    return apigw("/CF0902", { 
      subdomain,
      user_class
 }, dispatch);
  } else {
    //サンプルデータ用
    return { body: rows };
  }
};
