import React, { Fragment, useEffect } from "react";
import "./App.css";
import { Router } from "./Router";
import { createIntl, createIntlCache, RawIntlProvider } from "react-intl";
import { enLocaleData } from "./static/locales/en";
import { jaLocaleData } from "./static/locales/ja";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { RootState } from "./reducks/store";

function selectMessages(locale: string) {
  switch (locale) {
    case "en":
      return enLocaleData;
    case "ja":
      return jaLocaleData;
    default:
      return jaLocaleData;
  }
}

const cache = createIntlCache();

export const App = () => {
  const language = useSelector((state: RootState) => state.user.language);
  const notification: { id: string; message: string; variant: string } =
    useSelector((state: RootState) => state.notification);
  const { enqueueSnackbar } = useSnackbar();

  const handleClick = (message: string) => {
    enqueueSnackbar(message, { variant: "error" });
  };

  useEffect(() => {
    if (!!notification.id) {
      handleClick(notification.message);
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [notification]);

  const intl = createIntl(
    {
      locale: language,
      defaultLocale: "ja",
      messages: selectMessages(language),
    },
    cache
  );

  return (
    <Fragment>
      <RawIntlProvider value={intl}>
        <Router />
      </RawIntlProvider>
    </Fragment>
  );
};
