import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { push } from "connected-react-router";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { TextareaAutoSize } from "../../components/atoms/TextareaAutoSize";
import Select from "@material-ui/core/Select";
import AddIcon from "@material-ui/icons/Add";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";

import {
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  makeStyles,
} from "@material-ui/core";

import { FormatMessage } from "../../components/atoms/FormatMessage";
import { MainLayout } from "../../layouts/MainLayout";

import { OQ0301 } from "../../utils/api/_OQ0301";
import { LoadingBox } from "../../components/atoms/LoadingBox";
let getDateValue: any;

const useStyles = makeStyles(() => ({
  formControlLabel: { overflowWrap: "anywhere" },
}));
/**
 * カスタムアンケート参照
 * @param {object} props 引き継いだオブジェクト
 * @return コンポーネントHTMLコード内容
 */
export const Cls004 = (props: any) => {
  const classes = useStyles();

  let sessionDateValue = localStorage.getItem("Cls007_session") as string;
  getDateValue =
    props && props.location && typeof props.location.state === "undefined"
      ? JSON.parse(sessionDateValue)
      : props.location.state;
  if (typeof props.location.state !== "undefined") {
    localStorage.setItem(
      "Cls007_session",
      JSON.stringify(props.location.state)
    );
  }

  let classId = getDateValue?.classId ? getDateValue?.classId : "";
  const dispatch = useDispatch();

  const [maxQuestionNo, setMaxQuestionNo] = React.useState(0);

  // loadingフラグ
  const [loading, setLoading] = React.useState(false);

  // 質問データ
  type Rows = {
    questionNo: string; // 質問No
    question: string; // 質問（内容）
    characterClass: string; // 回答方式 1:記述式、2:ラジオボタン、3:チェックボックス、4:プルダウン
    isRequired: string; // 必須フラグ
    // option_1: string;
    updateDate: Date;
  }[];
  const [rows, setRows] = React.useState<Rows>([]);

  // 入力エラーフラグ
  type inputError = {
    error: boolean;
    error_message: any;
  };
  const [topError, setTopError] = React.useState<inputError>({
    error: false,
    error_message: "",
  });

  const errorMessages = {
    // 項目数エラー
    deleteFailed: FormatMessage({ id: "Cls004.Message.Err" }),
  };

  // const [openAlert, setOpenAlert] = React.useState(false);

  // 【 処理 】
  // 検索処理
  const getListData = async (callback: any) => {
    setLoading(true);
    let maxNo = 1;
    const getData = await OQ0301(dispatch, classId, null);
    if (getData && Object.keys(getData.body).indexOf("question") >= 0) {
      if (getData.body.question.length > 0) {
        const sortList = getData.body.question.sort(function (a: any, b: any) {
          return Number(a.question_no) - Number(b.question_no);
        });
        setRows(
          sortList.map((getDataItem: any, index: number) => {
            maxNo =
              maxNo < Number(getDataItem.question_no)
                ? Number(getDataItem.question_no)
                : maxNo;
            const options = getDataItem.option[0];
            let optionsIndex = 1;
            let optionsArray = [];
            while (
              Object.keys(options).indexOf("option_" + optionsIndex) >= 0
            ) {
              if (options["option_" + optionsIndex]) {
                optionsArray.push(options["option_" + optionsIndex]);
              }
              optionsIndex++;
            }
            return {
              questionNo: getDataItem.question_no, // 質問No
              question: getDataItem.question, // 質問（内容）
              characterClass: getDataItem.character_class, // 回答方式 1:記述式、2:ラジオボタン、3:チェックボックス、4:プルダウン
              isRequired: getDataItem.is_required, // 必須フラグ
              options: optionsArray,
              updateDate: getDataItem.update_date,
            };
          })
        );
      }
      setMaxQuestionNo(maxNo);
    }
    setLoading(false);
  };

  // 初期処理
  useEffect(() => {
    //データ取得
    getListData(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const backCilck = () => {
    dispatch(push("/cls/001", { class_id: classId, newValue: 1 }));
  };

  const addCilck = (questionNo: string) => {
    if (rows.length >= 50) {
      setTopError({
        error: true,
        error_message: errorMessages.deleteFailed,
      });
    } else {
      dispatch(
        push("/cls/005", {
          class_id: classId,
          question_no: String(maxQuestionNo + 1),
          action: "add",
        })
      );
    }
  };

  const editCilck = (questionNo: string, type: string, row: any) => {
    dispatch(
      push("/cls/005", {
        class_id: classId,
        question_no: questionNo,
        character_class: row.characterClass,
        action: "edit",
      })
    );
  };
  const deleteCilck = (questionNo: string, type: string, row: any) => {
    dispatch(
      push("/cls/006", {
        class_id: classId,
        question_no: row.questionNo,
        question: row.question,
        character_class: row.characterClass,
        is_required: row.isRequired,
        option: row.options,
        question_class_value: row.questionClassValue,
        update_date: row.updateDate,
        action: "delete",
      })
    );
  };

  type inputProps = {
    row: any;
  };

  const TextAreaBox: React.FC<inputProps> = ({ row = {} }) => {
    return (
      <Grid className="App-box">
        <Grid item xs={12}>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={10}
              className="App-text-no-width"
              style={{ whiteSpace: "pre-line" }}
            >
              <Typography gutterBottom variant="subtitle1">
                {row.question}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={2} style={{ paddingRight: "30px" }}>
              <Typography
                gutterBottom
                variant="subtitle1"
                className="App-Color-Red"
              >
                {row.isRequired === "1" ? (
                  <span className="required_box">
                    {FormatMessage({ id: "Cls004.Label.Required" })}
                  </span>
                ) : null}
              </Typography>
            </Grid>
          </Grid>
          <TextareaAutoSize
            value={""}
            rows={3}
            style={{
              boxSizing: "border-box",
              maxWidth: "700px",
              width: "650px",
            }}
          />
        </Grid>
        <EditButton
          type={row.characterClass}
          question_no={row.questionNo}
          row={row}
        />
      </Grid>
    );
  };

  const RadioBox: React.FC<inputProps> = ({ row = {} }) => {
    return (
      <Grid className="App-box">
        <Grid item xs={12}>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={10}
              style={{ whiteSpace: "pre-line" }}
              className="App-text-no-width"
            >
              <Typography gutterBottom variant="subtitle1">
                {row.question}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={2} style={{ paddingRight: "30px" }}>
              <Typography
                gutterBottom
                variant="subtitle1"
                className="App-Color-Red"
              >
                {row.isRequired === "1" ? (
                  <span className="required_box">
                    {FormatMessage({ id: "Cls004.Label.Required" })}
                  </span>
                ) : null}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <RadioGroup>
              {Object.keys(row.options).map((val: string) => (
                <div key={val} style={{ marginBottom: 12 }}>
                  {row.options[val] ? (
                    <FormControlLabel
                      key={row.options[val]}
                      value={row.options[val]}
                      control={<Radio color="default" />}
                      label={
                        <Typography className={classes.formControlLabel}>
                          {row.options[val]}
                        </Typography>
                      }
                    />
                  ) : null}
                </div>
              ))}
            </RadioGroup>
          </Grid>
        </Grid>
        <EditButton
          type={row.characterClass}
          question_no={row.questionNo}
          row={row}
        />
      </Grid>
    );
  };

  const CheckBox: React.FC<inputProps> = ({ row = {} }) => {
    return (
      <Grid className="App-box">
        <Grid item xs={12}>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={10}
              style={{ whiteSpace: "pre-line" }}
              className="App-text-no-width"
            >
              <Typography gutterBottom variant="subtitle1">
                {row.question}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={2} style={{ paddingRight: "30px" }}>
              <Typography
                gutterBottom
                variant="subtitle1"
                className="App-Color-Red"
              >
                {row.isRequired === "1" ? (
                  <span className="required_box">
                    {FormatMessage({ id: "Cls004.Label.Required" })}
                  </span>
                ) : null}
              </Typography>
            </Grid>
          </Grid>
          {Object.keys(row.options).map((val, index) => (
            <div key={index} style={{ marginBottom: 12 }}>
              {row.options[val] ? (
                <FormControlLabel
                  key={row.options[val]}
                  value={row.options[val]}
                  control={<Checkbox color="default" />}
                  label={
                    <Typography className={classes.formControlLabel}>
                      {row.options[val]}
                    </Typography>
                  }
                />
              ) : null}
            </div>
          ))}
        </Grid>
        <EditButton
          type={row.characterClass}
          question_no={row.questionNo}
          row={row}
        />
      </Grid>
    );
  };

  const SelectBox: React.FC<inputProps> = ({ row = {} }) => {
    return (
      <Grid className="App-box">
        <Grid item xs={12}>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={10}
              style={{ whiteSpace: "pre-line" }}
              className="App-text-no-width"
            >
              <Typography gutterBottom variant="subtitle1">
                {row.question}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={2} style={{ paddingRight: "30px" }}>
              <Typography
                gutterBottom
                variant="subtitle1"
                className="App-Color-Red"
              >
                {row.isRequired === "1" ? (
                  <span className="required_box">
                    {FormatMessage({ id: "Cls004.Label.Required" })}
                  </span>
                ) : null}
              </Typography>
            </Grid>
          </Grid>
          <Select native className={classes.formControlLabel}>
            {SelectItems(row.options)}
          </Select>
        </Grid>
        <EditButton
          type={row.characterClass}
          question_no={row.questionNo}
          row={row}
        />
      </Grid>
    );
  };

  function SelectItems(items: any) {
    return [
      Object.keys(items).map((val: string) =>
        items[val] ? (
          <option key={val} id={items[val]}>
            {items[val]}
          </option>
        ) : null
      ),
    ];
  }

  type inputButtonProps = {
    type: string;
    question_no: string;
    row: any;
  };

  const EditButton: React.FC<inputButtonProps> = ({
    type = "",
    question_no = "",
    row = {},
  }) => {
    return (
      <Grid item xs={12} className="App-alignRight">
        <span className="App-mR10">
          <Button
            variant="contained"
            color="primary"
            startIcon={<EditIcon />}
            onClick={() => {
              editCilck(question_no, type, row);
            }}
          >
            {FormatMessage({ id: "Button.Edit" })}
          </Button>
        </span>
        <span>
          <Button
            variant="contained"
            color="default"
            startIcon={<DeleteForeverIcon />}
            onClick={() => {
              deleteCilck(question_no, type, row);
            }}
          >
            {FormatMessage({ id: "Button.Del" })}
          </Button>
        </span>
      </Grid>
    );
  };

  return (
    <MainLayout>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className="App-paper">
            <Typography
              component="h2"
              variant="h6"
              color="inherit"
              gutterBottom
            >
              {FormatMessage({ id: "Cls004.Title" })}
            </Typography>
            {topError.error ? (
              <Grid
                xs={12}
                className="App-alignLeft App-mT20"
                style={{ color: "red" }}
              >
                {topError.error_message}
              </Grid>
            ) : null}
            <Grid item xs={12} className="App-alignRight">
              <Button
                variant="contained"
                color="primary"
                className="App-button_bottom"
                startIcon={<ArrowBackIcon />}
                onClick={backCilck}
              >
                {FormatMessage({ id: "Cls004.Button.back" })}
              </Button>
            </Grid>
            {rows.length > 0
              ? rows.map((row) => (
                  <div key={row.questionNo}>
                    {row.characterClass === "1" ? (
                      <TextAreaBox row={row} />
                    ) : null}
                    {row.characterClass === "2" ? <RadioBox row={row} /> : null}
                    {row.characterClass === "3" ? <CheckBox row={row} /> : null}
                    {row.characterClass === "4" ? (
                      <SelectBox row={row} />
                    ) : null}
                  </div>
                ))
              : null}
            <Grid item xs className="App-mT10 App-alignCenter">
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={() => {
                  addCilck(String(Number(maxQuestionNo) + 1));
                }}
              >
                {FormatMessage({ id: "Cls004.Button.add" })}
              </Button>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      {loading ? <LoadingBox /> : null}
    </MainLayout>
  );
};
