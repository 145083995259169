/* eslint-disable jsx-a11y/alt-text */
import { useState, useEffect } from "react";
import { FC, memo } from "react";
import { useDrag, useDrop } from "react-dnd";
// import { Page, Document, pdfjs } from "react-pdf";
import { pdfjs } from "react-pdf";
/**
 * 資料アップロード
 * PDF DnD 並び替えコンポーネント
 */

export const ItemTypes = {
  CARD: "card",
};

export interface CardProps {
  id: string;
  url: string;
  className: string;
  onClick: any;
  moveCard: (id: string, to: number) => void;
  findCard: (id: string) => { index: number };
}

interface Item {
  id: string;
  originalIndex: number;
}
let idCheck: any = [];
let imageData: any = {};
export const CardPdf: FC<CardProps> = memo(function Card({
  id,
  url,
  className,
  onClick,
  moveCard,
  findCard,
}) {
  const originalIndex = findCard(id).index;
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: ItemTypes.CARD,
      item: { id, originalIndex },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        const { id: droppedId, originalIndex } = item;
        const didDrop = monitor.didDrop();
        if (!didDrop) {
          moveCard(droppedId, originalIndex);
        }
      },
    }),
    [id, originalIndex, moveCard]
  );
  const [, drop] = useDrop(
    () => ({
      accept: ItemTypes.CARD,
      canDrop: () => false,
      hover({ id: draggedId }: Item) {
        if (draggedId !== id) {
          const { index: overIndex } = findCard(id);
          moveCard(draggedId, overIndex);
        }
      },
    }),
    [findCard, moveCard]
  );

  const [imageSrc, setImageSrc] = useState("");

  const onClickHandle = (setid: string) => {
    if (onClick) onClick(setid);
  };

  const opacity = isDragging ? 0 : 1;

  const getImgData = async () => {
    const loadingTask = pdfjs.getDocument({
      url: url,
      cMapUrl: "https://cdn.jsdelivr.net/npm/pdfjs-dist@2.0.385/cmaps/",
      cMapPacked: true,
    });
    let loadedPdf: any = await loadingTask.promise;
    let page = await loadedPdf.getPage(1);
    var viewport = page.getViewport({ scale: 1 });
    var canvas = document.createElement("canvas");
    var ctx = canvas.getContext("2d");
    var renderContext: any = {
      canvasContext: ctx,
      viewport: viewport,
    };
    canvas.height = viewport.height;
    canvas.width = viewport.width;
    await page.render(renderContext).promise;
    const data = canvas.toDataURL("image/jpg");
    setImageSrc(data);
    imageData[id] = data;
    let imgD: any = document.getElementById("pdf_show_view_" + id);
    imgD.src = data;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    console.log("idCheck", idCheck);
    if (idCheck.indexOf(id) < 0) {
      idCheck.push(id);
      getImgData();
    } else {
      setImageSrc(imageData[id]);
    }
  });

  // pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  return (
    <div
      ref={(node) => drag(drop(node))}
      style={{
        opacity,
        height: "190px",
        overflow: "hidden",
        padding: "0.5rem",
      }}
      className={className}
      onClick={() => onClickHandle(id)}
    >
      <img src={imageSrc} id={"pdf_show_view_" + id}></img>
      {/* <Document file={url}
      options={{
        cMapUrl: 'https://cdn.jsdelivr.net/npm/pdfjs-dist@2.0.385/cmaps/',
        cMapPacked: true,
      }}
      >
        <div className="pdf-page-container" key={`pdfpage`}>
          <Page pageIndex={0} key={`page1`} width={190} height={190}/>
        </div>
      </Document> */}
    </div>
  );
});
