import { apigw } from "../awsApi";
import { Dispatch } from "@reduxjs/toolkit";

//データあり
const rows1 = [
    {
      enquete_class: "1",     //アンケートタイプ（1:デフォルト、2:カスタム）
      input_date:"2021/12/12 12:00",     //登録日時
      user_id:"ABC123",      //視聴ユーザID
      user_name:"視聴ユーザ1",      //視聴ユーザ氏名
      handle_name:"ネーム1",      //ハンドルネーム
      answer:"選択肢内容１",     //回答
    },
  ];
  const rows2 = [
    {
      enquete_class: "1",     //アンケートタイプ
      input_date:"2021/12/12 12:00",     //登録日時
      user_id:"CBA321",      //視聴ユーザID
      user_name:"視聴ユーザ2",      //視聴ユーザ氏名
      handle_name:"ネーム2",      //ハンドルネーム
      answer:"選択肢内容１",     //回答
    },
    {
      enquete_class: "1",     //アンケートタイプ
      input_date:"2021/12/13 13:00",     //登録日時
      user_id:"CBA321",      //視聴ユーザID
      user_name:"視聴ユーザ2",      //視聴ユーザ氏名
      handle_name:"ネーム2",      //ハンドルネーム
      answer:"選択肢内容２",     //回答
    },
  ];
  const rows3 = [
    {
      enquete_class: "2",     //アンケートタイプ
      input_date:"2021/12/12 12:00",     //登録日時
      user_id:"ABC123",      //視聴ユーザID
      user_name:"視聴ユーザ1",      //視聴ユーザ氏名
      handle_name:"ネーム1",      //ハンドルネーム
      answer:"選択肢内容１",     //回答
    },
    {
      enquete_class: "2",     //アンケートタイプ
      input_date:"2021/12/13 13:00",     //登録日時
      user_id:"CBA321",      //視聴ユーザID
      user_name:"視聴ユーザ2",      //視聴ユーザ氏名
      handle_name:"ネーム2",      //ハンドルネーム
      answer:"選択肢内容２",     //回答
    },
    {
      enquete_class: "2",     //アンケートタイプ
      input_date:"2021/12/13 14:00",     //登録日時
      user_id:"DDDDDD",      //視聴ユーザID
      user_name:"視聴ユーザ3",      //視聴ユーザ氏名
      handle_name:"ネーム3",      //ハンドルネーム
      answer:"選択肢内容１",     //回答
    },
    {
      enquete_class: "2",     //アンケートタイプ
      input_date:"2021/12/13 15:00",     //登録日時
      user_id:"EEEEEE",      //視聴ユーザID
      user_name:"視聴ユーザ4",      //視聴ユーザ氏名
      handle_name:"ネーム4",      //ハンドルネーム
      answer:"選択肢内容３",     //回答
    },    
  ];
/**
 * アンケート結果取得
 * @param {Dispatch} dispatch reducerディスパッチ
 * @param {string} classId 授業ID
 * @param {string} questionNo 質問No
 * @param {string} targetQuestionNo 質問No
 * @param {string} enqueteClass アンケートタイプ(1:デフォルトアンケート 2:カスタムアンケート)
 * @param {number} PageNo ページ番号
 * @return コンポーネントHTMLコード内容
 */
export const CQ0302 = async (
  dispatch: Dispatch,
  classId: string,
  questionNo: string,
  targetQuestionNo:string,
  enqueteClass: string,
  PageNo: number | null
) => {
  if (process.env.REACT_APP_API_USE_FLG === "true") {
    return apigw(
      "/CQ0302",
      {
        class_id: classId,
        question_no: questionNo,
        target_question_no: targetQuestionNo,
        enquete_class: enqueteClass,
        page_no: PageNo
      },
      dispatch
    );
  } else {
    //サンプルデータ用
    if (targetQuestionNo === "1" || (targetQuestionNo === "0" && questionNo === "1"))
    {
      return { body: rows1 }
    };
    if (targetQuestionNo === "2" || (targetQuestionNo === "0" && questionNo === "4"))
    {
      return { body: rows3 }
    };
    if (targetQuestionNo === "0")
    {
      return { body: rows2 }
    };
  }
};
