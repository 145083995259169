export const operations: any = {
  AU0101: "ログイン実行",
  AU0104: "パスワード変更",
  AU0106: "パスワードポリシーチェック",
  AU0201: "ログイン実行",
  AU0203: "パスワード変更",
  AU0205: "パスワードポリシーチェック",
  AU0301: "ログイン認証実行",
  AU0304: "パスワード変更",
  AU0309: "パスワードポリシーチェック",
  AU0311: "パスワード初期化（再発行）",
  AU0305: "ログイン認証実行",
  AU0308: "パスワード変更",
  AU0310: "パスワードポリシーチェック",
  AU0312: "パスワード初期化（再発行）",
  CF0101: "一覧取得",
  CF0102: "詳細取得",
  CF0103: "変更",
  CF0108: "配信視聴時間追加",
  CF0109: "一覧取得",
  CF0107: "警告アラート閾値取得",
  CF0203: "詳細取得",
  CF0303: "区分マスタ取得",
  CF0304: "詳細取得",
  CF0901: "各種システム利用制限チェック",
  US0101: "一覧取得",
  US0102: "詳細取得",
  US0103: "新規作成",
  US0104: "変更",
  US0105: "削除",
  US0106: "登録内容事前チェック",
  US0112: "パスワード初期化（再発行）",
  US0107: "詳細取得",
  US0108: "新規作成",
  US0109: "変更",
  US0110: "削除",
  US0111: "登録内容事前チェック",
  US0113: "パスワード初期化（再発行）",
  US0201: "一覧取得",
  US0202: "詳細取得",
  US0203: "新規作成",
  US0204: "変更",
  US0205: "削除",
  US0206: "登録内容事前チェック",
  US0234: "パスワード初期化（再発行）",
  US0212: "一覧取得",
  US0213: "詳細取得",
  US0214: "新規作成",
  US0215: "変更",
  US0216: "削除",
  US0217: "登録内容事前チェック",
  US0218: "一括新規作成",
  US0219: "一括変更",
  US0220: "一括削除",
  US0221: "一括登録内容事前チェック",
  US0235: "パスワード初期化（再発行）",
  US0237: "一括タグ紐づけ",
  US0238: "一括タグ紐づけ登録内容事前チェック",
  US0223: "一覧取得",
  US0224: "詳細取得",
  US0225: "新規作成",
  US0226: "変更",
  US0227: "削除",
  US0228: "登録内容事前チェック",
  US0229: "一括新規作成",
  US0230: "一括変更",
  US0231: "一括削除",
  US0232: "一括登録内容事前チェック",
  US0236: "パスワード初期化（再発行）",
  US0239: "一括タグ紐づけ",
  US0240: "一括タグ紐づけ登録内容事前チェック",
  US0301: "詳細取得",
  US0302: "変更",
  US0303: "登録内容事前チェック",
  US0304: "詳細取得",
  US0305: "変更",
  US0306: "登録内容事前チェック",
  LC0201: "一覧取得",
  LC0202: "詳細取得",
  LC0203: "新規作成",
  LC0204: "変更",
  LC0205: "削除",
  LC0206: "登録内容事前チェック",
  LC0207: "一括新規作成",
  LC0208: "一括変更",
  LC0209: "一括削除",
  LC0210: "一括登録内容事前チェック",
  LC0211: "視聴記録取得",
  LC0213: "授業一覧表示メニュー一括設定",
  LC0214: "一括タグ紐づけ",
  LC0215: "一括タグ紐づけ登録内容事前チェック",
  LC0301: "授業一覧取得",
  LC0302: "授業詳細取得",
  LC0303: "授業視聴状況一覧取得",
  LC0304: "授業視聴状況詳細取得",
  LC0305: "授業詳細取得",
  LC0306: "授業視聴状況詳細取得",
  CH0301: "一覧取得",
  CH0305: "一覧取得",
  CQ0201: "詳細取得",
  CQ0202: "新規作成",
  CQ0203: "変更",
  CQ0204: "削除",
  CQ0301: "詳細取得",
  CQ0302: "回答内容詳細取得",
  CQ0304: "回答送信",
  OQ0301: "詳細取得",
  OQ0302: "新規作成",
  OQ0303: "変更",
  OQ0304: "削除",
  OQ0306: "回答内容詳細取得",
  OQ0308: "回答送信",
  QZ0301: "取得",
  QZ0302: "新規作成",
  QZ0303: "回答結果サマリー取得",
  QZ0304: "取得",
  QZ0305: "回答結果送信",
  CT0301: "一覧取得",
  CT0302: "詳細取得",
  CT0303: "新規作成（アップロード登録）",
  CT0304: "削除（削除登録）",
  CT0305: "公開日時一覧取得",
  CT0306: "公開日時編集",
  TG0201: "一覧取得",
  TG0202: "詳細取得",
  TG0211: "新規作成",
  TG0203: "変更",
  TG0204: "削除",
  TG0205: "登録内容事前チェック",
  TG0206: "一括新規作成",
  TG0207: "一括変更",
  TG0208: "一括削除",
  TG0209: "一括登録内容事前チェック",
  TG0301: "一覧取得",
  ST0301: "授業ステータス更新",
  ST0313: "S3アクセストークン取得",
};
