import React from "react";
import { useDispatch } from "react-redux";
import Grid from "@material-ui/core/Grid";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { FormatMessage } from "../../components/atoms/FormatMessage";
import { OQ0302 } from "../../utils/api/OQ0302";
import { OQ0304 } from "../../utils/api/OQ0304";
import { OQ0309 } from "../../utils/api/OQ0309";
import { OQ0310 } from "../../utils/api/OQ0310";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AddIcon from "@material-ui/icons/Add";

/**
 * 授業後ページ設定確認
 * @param {object} props 引き継いだオブジェクト
 * @return コンポーネントHTMLコード内容
 */
export const Cls002 = (props: any) => {
  const { Values, QuesValues, IniQuesValues, Callback, Endback, NoQuestionFlg } = props;
  const dispatch = useDispatch();

  const EXCLUSIVE_ERROR = "04070";
  const DELETE_FAILED = "04015";

  // 入力エラーフラグ
  type inputError = {
    error: boolean;
    error_message: string;
  };
  const [topError, setTopError] = React.useState<inputError>({
    error: false,
    error_message: "",
  });

  const errorMessages = {
    // 削除失敗
    deleteFailed: FormatMessage({ id: "Cls006.Message.InsertError" }),
    // 排他エラー
    exclusiveError: FormatMessage({ id: "Error.ExlusiveControl" }),
  };

  // カスタムアンケートデータ
  type customQuesRow = {
    update_date: Date;
    question_no: string;
    question: string;
    is_required: string;
    character_class: string;
    options: any[];
  };

  const backCilck = () => {
    Callback();
  };

  const addCilck = async () => {
    props.setLoading(true);
    /* 
    Values.afterPageSetting : 授業後ページ設定
    Values.afterUrl         : 外部URL
    Values.templateName     : テンプレート
    Values.beforeAnswerMsg  : 回答前Msg
    Values.afterAnswerMsg   : 回答後Msg
    Values.tempFlg          : テンプレート使用フラグ
    Values.enqRegFlg        : カスタムアンケート登録・更新フラグ
    Values.classId          : 授業ID
    Values.lecUpdate        : 授業マスタ更新日時
    Values.customUpdate     : カスタムアンケート更新日時
    QuesValues              : カスタムアンケート問題
    IniQuesValues   : カスタムアンケート問題DELETE用
    */
    let delFaildFlg = false;
    let quizDelResult: any;
    let mstUpdateResult: any;
    let msgResult: any;
    // サービス設定にしたがう・デフォルトアンケート・表示しない
    if (
      Values.afterPageSetting === 0 ||
      Values.afterPageSetting === 1 ||
      Values.afterPageSetting === 4
    ) {
      mstUpdateResult = await OQ0309(
        dispatch,
        Values.classId,
        String(Values.afterPageSetting),
        "",
        Values.lecUpdate,
        "",
        "",
        ""
      );
    }
    // 外部URL
    if (Values.afterPageSetting === 3) {
      mstUpdateResult = await OQ0309(
        dispatch,
        Values.classId,
        String(Values.afterPageSetting),
        Values.afterUrl,
        Values.lecUpdate,
        "",
        "",
        ""
      );
    }
    // カスタムアンケート
    if (Values.afterPageSetting === 2) {
      // テンプレート使用有無
      if (Values.tempFlg === 1) {
        // テンプレート使用

        // カスタムアンケート問題有無
        if (IniQuesValues.length > 0) {
          // カスタムアンケート問題登録有
          // カスタムアンケート登録有無
          if (Values.enqRegFlg === 1) {
            // カスタムアンケート登録有
            // 授業マスタ・カスタムアンケート更新
            mstUpdateResult = await OQ0309(
              dispatch,
              Values.classId,
              String(Values.afterPageSetting),
              "",
              Values.lecUpdate,
              Values.beforeAnswerMsg,
              Values.afterAnswerMsg,
              Values.customUpdate
            );
          } else {
            // カスタムアンケート登録無
            // 授業マスタ更新
            mstUpdateResult = await OQ0309(
              dispatch,
              Values.classId,
              String(Values.afterPageSetting),
              "",
              Values.lecUpdate,
              "",
              "",
              ""
            );
            // カスタムアンケート新規登録
            msgResult = await OQ0310(
              dispatch,
              Values.classId,
              Values.beforeAnswerMsg,
              Values.afterAnswerMsg
            );
          }
          // カスタムアンケート問題DELETE・INSERT
          if (QuesValues.length > 0) {
            // DELETE
            if (NoQuestionFlg === false) {
              for (let row of IniQuesValues) {
                quizDelResult = await OQ0304(
                  dispatch,
                  Values.classId,
                  row.question_no,
                  row.update_date
                );
                if (
                  !quizDelResult ||
                  Object.keys(quizDelResult).indexOf("state") < 0 ||
                  quizDelResult.state !== "0"
                ) {
                  delFaildFlg = true;
                  break;
                }
              }
            }
            // INSERT
            if (!delFaildFlg) {
              QuesValues.map(
                async (row: customQuesRow) =>
                  await OQ0302(
                    dispatch,
                    Values.classId,
                    row.question_no,
                    row.question,
                    row.is_required,
                    row.character_class,
                    row.options[0],
                    row.options[1],
                    row.options[2],
                    row.options[3],
                    row.options[4],
                    row.options[5],
                    row.options[6],
                    row.options[7],
                    row.options[8],
                    row.options[9],
                    row.options[10],
                    row.options[11],
                    row.options[12],
                    row.options[13],
                    row.options[14],
                    row.options[15],
                    row.options[16],
                    row.options[17],
                    row.options[18],
                    row.options[19],
                    row.options[20],
                    row.options[21],
                    row.options[22],
                    row.options[23],
                    row.options[24],
                    row.options[25],
                    row.options[26],
                    row.options[27],
                    row.options[28],
                    row.options[29],
                    row.options[30],
                    row.options[31],
                    row.options[32],
                    row.options[33],
                    row.options[34],
                    row.options[35],
                    row.options[36],
                    row.options[37],
                    row.options[38],
                    row.options[39],
                    row.options[40],
                    row.options[41],
                    row.options[42],
                    row.options[43],
                    row.options[44],
                    row.options[45],
                    row.options[46],
                    row.options[47],
                    row.options[48],
                    row.options[49]
                  )
              );
            }
          }
        } else {
          // カスタムアンケート問題登録無

          // カスタムアンケート登録有無
          if (Values.enqRegFlg === 1) {
            // カスタムアンケート登録有
            // 授業マスタ・カスタムアンケート更新
            mstUpdateResult = await OQ0309(
              dispatch,
              Values.classId,
              String(Values.afterPageSetting),
              "",
              Values.lecUpdate,
              Values.beforeAnswerMsg,
              Values.afterAnswerMsg,
              Values.customUpdate
            );
          } else {
            // カスタムアンケート登録無
            // 授業マスタ更新
            mstUpdateResult = await OQ0309(
              dispatch,
              Values.classId,
              String(Values.afterPageSetting),
              "",
              Values.lecUpdate,
              "",
              "",
              ""
            );
            // カスタムアンケート新規登録
            msgResult = await OQ0310(
              dispatch,
              Values.classId,
              Values.beforeAnswerMsg,
              Values.afterAnswerMsg
            );
          }
          // カスタムアンケート問題INSERT
          if (QuesValues.length > 0) {
            // INSERT
            QuesValues.map(
              async (row: customQuesRow) =>
                await OQ0302(
                  dispatch,
                  Values.classId,
                  row.question_no,
                  row.question,
                  row.is_required,
                  row.character_class,
                  row.options[0],
                  row.options[1],
                  row.options[2],
                  row.options[3],
                  row.options[4],
                  row.options[5],
                  row.options[6],
                  row.options[7],
                  row.options[8],
                  row.options[9],
                  row.options[10],
                  row.options[11],
                  row.options[12],
                  row.options[13],
                  row.options[14],
                  row.options[15],
                  row.options[16],
                  row.options[17],
                  row.options[18],
                  row.options[19],
                  row.options[20],
                  row.options[21],
                  row.options[22],
                  row.options[23],
                  row.options[24],
                  row.options[25],
                  row.options[26],
                  row.options[27],
                  row.options[28],
                  row.options[29],
                  row.options[30],
                  row.options[31],
                  row.options[32],
                  row.options[33],
                  row.options[34],
                  row.options[35],
                  row.options[36],
                  row.options[37],
                  row.options[38],
                  row.options[39],
                  row.options[40],
                  row.options[41],
                  row.options[42],
                  row.options[43],
                  row.options[44],
                  row.options[45],
                  row.options[46],
                  row.options[47],
                  row.options[48],
                  row.options[49]
                )
            );
          }
        }
      } else {
        // テンプレート不使用

        // カスタムアンケート登録有無
        if (Values.enqRegFlg === 1) {
          // カスタムアンケート登録有
          // 授業マスタ・カスタムアンケート更新
          mstUpdateResult = await OQ0309(
            dispatch,
            Values.classId,
            String(Values.afterPageSetting),
            "",
            Values.lecUpdate,
            Values.beforeAnswerMsg,
            Values.afterAnswerMsg,
            Values.customUpdate
          );
        } else {
          // カスタムアンケート登録無
          // 授業マスタ更新
          mstUpdateResult = await OQ0309(
            dispatch,
            Values.classId,
            String(Values.afterPageSetting),
            "",
            Values.lecUpdate,
            "",
            "",
            ""
          );
          // カスタムアンケート新規登録
          msgResult = await OQ0310(
            dispatch,
            Values.classId,
            Values.beforeAnswerMsg,
            Values.afterAnswerMsg
          );
        }
      }
    }
    if (delFaildFlg) {
      // 更新失敗
      switch (quizDelResult.error_code) {
        case DELETE_FAILED:
          setTopError({
            error: true,
            error_message: errorMessages.deleteFailed,
          });
          break;
        case EXCLUSIVE_ERROR:
          setTopError({
            error: true,
            error_message: errorMessages.exclusiveError,
          });
          break;
      }
      props.setLoading(false);
    } else {
      if (
        mstUpdateResult &&
        Object.keys(mstUpdateResult).indexOf("state") >= 0 &&
        mstUpdateResult.state === "0"
      ) {
        if (Values.afterPageSetting === 2) {
          if (msgResult) {
            if (
              Object.keys(msgResult).indexOf("state") >= 0 &&
              msgResult.state === "0"
            ) {
              props.setLoading(false);
              Endback();
            } else {
              setTopError({
                error: true,
                error_message: errorMessages.deleteFailed,
              });
              props.setLoading(false);
            }
          } else {
            props.setLoading(false);
            Endback();
          }
        } else {
          props.setLoading(false);
          Endback();
        }
      } else {
        // 更新失敗
        setTopError({
          error: true,
          error_message: errorMessages.exclusiveError,
        });
        props.setLoading(false);
      }
    }
  };

  return (
    <div className="App-paper-root">
      <Grid container spacing={1} className="App-mTB10">
        <Grid item xs={12} sm={8}>
          <Typography variant="h6">
            {FormatMessage({ id: "Cls002.Title" })}
          </Typography>
          {topError.error ? (
            <Grid xs={12} className="App-alignLeft App-mT20" style={{ color: "red" }}>
              {topError.error_message}
            </Grid>
          ) : null}
        </Grid>
        <Grid item xs={12} sm={4} className="App-alignRight">
          <Button
            variant="contained"
            color="primary"
            startIcon={<ArrowBackIcon />}
            onClick={backCilck}
          >
            {FormatMessage({ id: "Cls002.Button.back" })}
          </Button>
        </Grid>
      </Grid>

      <Grid className="App-box">
        <Grid container spacing={1} className="App-List">
          <Grid item xs={12} sm={6}>
            {FormatMessage({ id: "Cls001.Label.text5" })}
          </Grid>
          <Grid item xs={12} sm={6}>
            {Values.afterPageSetting === 0 ? (
              <div>{FormatMessage({ id: "Cls001.List.text1" })}</div>
            ) : null}
            {Values.afterPageSetting === 1 ? (
              <div>{FormatMessage({ id: "Cls001.List.text2" })}</div>
            ) : null}
            {Values.afterPageSetting === 2 ? (
              <div>{FormatMessage({ id: "Cls001.List.text3" })}</div>
            ) : null}
            {Values.afterPageSetting === 3 ? (
              <div>{FormatMessage({ id: "Cls001.List.text4" })}</div>
            ) : null}
            {Values.afterPageSetting === 4 ? (
              <div>{FormatMessage({ id: "Cls001.List.text5" })}</div>
            ) : null}
          </Grid>
        </Grid>
        <Grid
          container
          spacing={1}
          className="App-List"
          style={{
            visibility: Values.afterPageSetting === 3 ? "visible" : "hidden",
          }}
        >
          <Grid item xs={12} sm={6}>
            {FormatMessage({ id: "Cls001.Label.text6" })}
          </Grid>
          <Grid item xs={12} sm={6} className="App-text-no-width">
            {Values.afterUrl}
          </Grid>
        </Grid>
        <Grid
          container
          spacing={1}
          className="App-List"
          style={{
            visibility: Values.afterPageSetting === 2 ? "visible" : "hidden",
          }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            style={{ paddingTop: 5 + "px", paddingBottom: 5 + "px" }}
          >
            {FormatMessage({ id: "Cls001.Label.text7" })}
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            style={{ paddingTop: 5 + "px", paddingBottom: 5 + "px" }}
            className="App-text-no-width"
          >
            {Values.templateName? Values.templateName: Values.customExistence ? Values.className :FormatMessage({ id: "Cls001.List.text6" })}
          </Grid>
        </Grid>
        <Grid
          container
          spacing={1}
          className="App-List"
          style={{
            visibility: Values.afterPageSetting === 2 ? "visible" : "hidden",
          }}
        >
          <Grid item xs={12} sm={6}>
            {FormatMessage({ id: "Cls001.Label.text8" })}
          </Grid>
          <Grid item xs={12} sm={6} className="App-text-no-width">
            {Values.beforeAnswerMsg}
          </Grid>
        </Grid>
        <Grid
          container
          spacing={1}
          className="App-List"
          style={{
            visibility: Values.afterPageSetting === 2 ? "visible" : "hidden",
          }}
        >
          <Grid item xs={12} sm={6}>
            {FormatMessage({ id: "Cls001.Label.text9" })}
          </Grid>
          <Grid item xs={12} sm={6} className="App-text-no-width">
            {Values.afterAnswerMsg}
          </Grid>
        </Grid>
        <Grid xs={12} spacing={1} className="App-List">
          <Grid xs={12} className="App-alignCenter">
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={addCilck}
            >
              {FormatMessage({ id: "Button.Register" })}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
