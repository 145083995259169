import { apigw } from "../awsApi";
import { Dispatch } from "@reduxjs/toolkit";

//サンプルデータ
const row = [
  {
    download_document_id:"aaaa",
    download_document_name:"aaaaaaaaaaaaaaaaaaaaaaaaa",
    release_date:new Date(2021, 7, 28, 12, 21),
    update_date:new Date(2021, 7, 28, 12, 21),
  }, 
  {
    download_document_id:"bbbb",
    download_document_name:"bbbb",
    release_date:new Date(2021, 7, 29),
    update_date:new Date(2021, 7, 28),
  },
  {
    download_document_id:"cccc",
    download_document_name:"cccc",
    update_date:new Date(2021, 7, 28, 12, 21),
  },
  {
    download_document_id:"dddd",
    download_document_name:"dddd",
    release_date:new Date(2021, 10, 1, 7, 5),
    update_date:new Date(2021, 7, 28, 12, 21),
  }
  ];
  

/**
 * 公開日時一覧取得
 * @param {Dispatch} dispatch reducerディスパッチ
 * @param {string} class_id 授業ID
 * @return コンポーネントHTMLコード内容
 */
export const CT0305 = async (
  dispatch: Dispatch,
  classId: string,

) => {
  if (process.env.REACT_APP_API_USE_FLG === "true") {
    return apigw(
      "/CT0305",
      {
        class_id: classId
      },
      dispatch
    );
  } else {
    //サンプルデータ用
    return {body: row}
  }
};
