import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import SettingsIcon from "@material-ui/icons/Settings";
import WebAssetIcon from "@material-ui/icons/WebAsset";
import { store } from "../../reducks/store";
import { signOut } from "../../reducks/userSlice";
import { MainLayout } from "../../layouts/MainLayout";
import { FormatMessage } from "../../components/atoms/FormatMessage";
import { LoadingBox } from "../../components/atoms/LoadingBox";
import { ShowClassImage } from "./ShowClassImage";
import Tooltip from "@material-ui/core/Tooltip";
import { LC0301 } from "../../utils/api/LC0301";
import { TG0301 } from "../../utils/api/TG0301";
import { LC0308 } from "../../utils/api/LC0308";
import { createS3 } from "../../components/function/AwsS3Fun";
import sanitize from "sanitize-html";
import { unescapeStr } from "../../components/function/Function";
let S3Api: any;
let getS3Api: any;
let allRows: any;
let nowRows: any;
/**
 * 配信ユーザトップ
 * @param {object} props 引き継いだオブジェクト
 * @return コンポーネントHTMLコード内容
 */
export const Top001 = (props: any) => {
  const dispatch = useDispatch();
  const serviceId = store.getState().user.service_id;

  type itemType = {
    class_id: string; //授業ID
    class_name: string; //授業名
    s3_id: string; //授業写真
    plan_date: string; //授業予定日時
    explain_text: string; //説明文
    is_font_red: string; //フォント設定(赤字)
    is_font_bold: string; //フォント設定(太字)
    sort: number; //並び順
    status_class: string;
    channel_id: string; //チャンネルID
    stream_class: string; //授業区分
    open_nuclass: string; //授業区分(公開設定)
    existing_use_class: string; //授業区分(既存動画)
    is_filmed: string; //録画済みフラグ
  };

  //loadingフラグ
  const [loading, setLoading] = React.useState(true);

  //配信予定データ
  const [rows, setRows] = React.useState<itemType[]>([]);
  const nowR: any = React.useRef(null);
  nowR.current = rows;
  //授業一覧データ
  const [crows, setCrows] = React.useState<itemType[]>([]);
  const elm: any = React.useRef(null);
  elm.current = crows;

  const getDateFormat = (dt: Date) => {
    let y = dt.getFullYear();
    let m = ("00" + (dt.getMonth() + 1)).slice(-2);
    let d = ("00" + dt.getDate()).slice(-2);
    let h = ("00" + dt.getHours()).slice(-2);
    let i = ("00" + dt.getMinutes()).slice(-2);
    return y + "/" + m + "/" + d + " " + h + ":" + i;
  };

  const getDateTime = (date: any) => {
    let ct = new Date(date).getTime();
    let nt = new Date(ct - 9 * 60 * 60 * 1000);
    return (
      nt.getFullYear() +
      "/" +
      ("00" + (nt.getMonth() + 1)).slice(-2) +
      "/" +
      ("00" + nt.getDate()).slice(-2) +
      " " +
      ("00" + nt.getHours()).slice(-2) +
      ":" +
      ("00" + nt.getMinutes()).slice(-2)
    );
  };

  //データ処理
  const getListData = async (callback: any) => {
    // const userData = await getSessionUser();
    const userData = store.getState().user;
    let getData = await LC0308(dispatch, userData.user_class, false);
    //6時間前から翌日の24時までの授業をチェック
    let nt = new Date().getTime();
    let d1 = getDateFormat(new Date(nt - 6 * 60 * 60 * 1000)); //6時間前
    var tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    let ty = tomorrow.getFullYear();
    let tm = ("00" + (tomorrow.getMonth() + 1)).slice(-2);
    let td = ("00" + tomorrow.getDate()).slice(-2);
    let tt = ("00" + tomorrow.getHours()).slice(-2);
    let tM = ("00" + tomorrow.getMinutes()).slice(-2);
    let d2 = ty + "/" + tm + "/" + td + " " + tt + ":" + tM; //翌日の24時
    if (getData && getData.state === "0" && getData.body.length > 0) {
      let listDate: any[] = getData.body;
      // 新しい授業から

      listDate.sort(function (a, b) {
        if (a.sort > b.sort) return 1;
        if (a.sort < b.sort) return -1;
        return 0;
      });

      // 授業一覧全体から授業の重複をフィルターしてセット
      let newTimeData: any[] = [];
      if (listDate.length > 0) {
        let duplicatedListDateIndex: any = [];
        listDate.reduce((prev: any, current: any, index) => {
          if (prev[current["class_id"]]) {
            duplicatedListDateIndex = [...duplicatedListDateIndex, index];
          } else {
            prev[current["class_id"]] = current;
          }
          return prev;
        }, {});
        duplicatedListDateIndex.forEach(
          (deleteIndex: number, deletedCount: number) => {
            listDate.splice(deleteIndex - deletedCount, 1);
          }
        );
        //日付変換
        listDate.forEach((data: any) => {
          data.plan_date = getDateTime(data.plan_date);
          newTimeData.push(data);
        });
      }

      // 配信予定の授業をセット
      let nowDate: any[] = [];
      newTimeData.forEach((data: any) => {
        if (data.plan_date >= d1 && data.plan_date <= d2) {
          nowDate.push(data);
        }
      });
      if (nowDate.length > 0) {
        if (nowR.current.length > 0) {
          if (nowRows && nowRows.length === 0) {
            nowRows = [];
            setRows(nowDate);
          } else {
            nowRows = nowDate.slice(nowR.current.length);
            setRows(nowDate.slice(0, nowR.current.length));
          }
        } else {
          nowRows = nowDate.slice(20);
          setRows(nowDate.slice(0, 20));
        }
      }
      if ((nowRows && nowRows.length === 0) || nowDate.length === 0) {
        if (elm.current.length > 0) {
          allRows = newTimeData.slice(elm.current.length);
          setCrows(newTimeData.slice(0, elm.current.length));
        } else {
          allRows = newTimeData.slice(20);
          setCrows(newTimeData.slice(0, 20));
        }
      } else {
        allRows = newTimeData;
      }
    } else if (getData === "01001") {
      dispatch(signOut());
    }

    if (callback) callback();
  };

  const classSetCilck = (id: string) => {
    dispatch(push("/cls/001", { class_id: sanitize(id) }));
  };

  const viewStatusCilck = (id: string) => {
    dispatch(push("/sts/001", { class_id: sanitize(id) }));
  };

  const getS3Token = async () => {
    createS3().then((s3: any) => {
      S3Api = s3;
      getS3Api = setTimeout(getS3Token, 600000);
    });
  };

  //初期処理
  useEffect(() => {
    setLoading(true);
    createS3().then((s3: any) => {
      S3Api = s3;
      getListData(() => {
        setLoading(false);
        getS3Token();
      });
    });

    //配信予定授業一覧と授業一覧のリロードをする
    const intervalId = setInterval(function () {
      sessionStorage.removeItem("fristImgList");
      getListData(null);
    }, 60000);

    return function () {
      clearInterval(intervalId);
      if (getS3Api) {
        clearTimeout(getS3Api);
        getS3Api = undefined;
      }
    };
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  // ページのページがなくなった場合の関数

  const title = FormatMessage({ id: "Top001.Label.text1" });
  const Tooltip1 = FormatMessage({ id: "Top001.Tooltip.text1" });
  const Tooltip2 = FormatMessage({ id: "Top001.Tooltip.text2" });

  useEffect(() => {
    var box: any = document.getElementsByTagName("main");
    if (box && box.length > 0) {
      box[0].onscroll = function () {
        var scroll = box[0].scrollTop;
        var range = box[0].scrollHeight - box[0].offsetHeight;
        if (
          Math.floor(range) - Math.ceil(scroll) < 10 &&
          (allRows.length > 0 || (nowRows && nowRows.length > 0))
        ) {
          if ((nowRows && nowRows.length === 0) || !nowRows) {
            let list = allRows.slice(0, 20);
            setCrows([...elm.current, ...list]);
            allRows = allRows.slice(20);
          } else {
            let list = nowRows.slice(0, 20);
            setRows([...nowR.current, ...list]);
            nowRows = nowRows.slice(20);
          }
        }
      };
    }
  }, []);

  return (
    <MainLayout>
      <Grid item xs={12}>
        <Paper className={rows.length > 0 ? "App-paper" : "App-NoDisplay"}>
          <Typography variant="h6" style={{ borderBottom: "1px solid #ccc" }}>
            {title}
          </Typography>
          {rows.map((item, index) => (
            <Grid
              container
              spacing={2}
              className="App-List"
              key={"item_" + index}
              style={
                index + 1 !== rows.length
                  ? { borderBottom: "1px solid #ccc" }
                  : {}
              }
            >
              <Grid container style={{ padding: "10px" }}>
                <Grid
                  item
                  xs
                  style={{
                    minWidth: "145px",
                    maxWidth: "145px",
                  }}
                >
                  <ShowClassImage
                    S3={S3Api}
                    serviceId={serviceId}
                    classId={item.class_id}
                  />
                </Grid>
                <Grid item xs={12} sm={9}>
                  <Typography
                    gutterBottom
                    variant="h6"
                    style={{ wordBreak: "break-all" }}
                  >
                    {unescapeStr(item.class_name)}
                  </Typography>
                  <Typography
                    variant="body2"
                    gutterBottom
                    style={
                      item.is_font_red === "1" && item.is_font_bold === "1"
                        ? {
                            color: "red",
                            fontWeight: "bold",
                          }
                        : item.is_font_red === "1"
                        ? {
                            color: "red",
                          }
                        : item.is_font_bold === "1"
                        ? {
                            fontWeight: "bold",
                          }
                        : {}
                    }
                  >
                    <div
                      className="App-text-no-width"
                      style={{ whiteSpace: "pre-line" }}
                    >
                      {unescapeStr(item.explain_text)}
                    </div>
                  </Typography>
                </Grid>
                <Grid
                  xs
                  item
                  style={{
                    minWidth: "168px",
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "flex-end",
                  }}
                >
                  <div>
                    <Grid item xs={12} className="App-alignCenter">
                      <Typography variant="body1" color="textSecondary">
                        {item.plan_date}
                      </Typography>
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                    >
                      <Grid
                        item
                        xs
                        className="App-alignCenter App-pLR10"
                        style={{ paddingRight: 5 + "px" }}
                      >
                        <Tooltip title={Tooltip1} aria-label="classSet">
                          <Button
                            variant="contained"
                            color="primary"
                            className="App-button_bottom"
                            onClick={() => classSetCilck(item.class_id)}
                          >
                            <SettingsIcon />
                          </Button>
                        </Tooltip>
                      </Grid>
                      <Grid
                        item
                        xs
                        className="App-alignCenter App-pLR10"
                        style={{ paddingLeft: 5 + "px" }}
                      >
                        <Tooltip title={Tooltip2} aria-label="viewStatus">
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => viewStatusCilck(item.class_id)}
                          >
                            <WebAssetIcon />
                          </Button>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Paper>
        <Paper
          className={
            crows.length > 0
              ? rows.length > 0
                ? "App-mT20 App-paper"
                : "App-paper"
              : "App-NoDisplay"
          }
        >
          {crows.map((item, index) => (
            <Grid
              container
              spacing={2}
              className="App-List"
              key={"citem_" + index}
              style={
                index + 1 !== crows.length
                  ? { borderBottom: "1px solid #ccc" }
                  : {}
              }
            >
              <Grid container style={{ padding: "10px" }}>
                <Grid
                  item
                  xs
                  style={{
                    minWidth: "145px",
                    maxWidth: "145px",
                  }}
                >
                  <ShowClassImage
                    S3={S3Api}
                    serviceId={serviceId}
                    classId={item.class_id}
                  />
                </Grid>
                <Grid item xs={12} sm={9}>
                  <Typography
                    gutterBottom
                    variant="h6"
                    style={{ wordBreak: "break-all" }}
                  >
                    {unescapeStr(item.class_name)}
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    gutterBottom
                    style={
                      item.is_font_red === "1" && item.is_font_bold === "1"
                        ? {
                            color: "red",
                            fontWeight: "bold",
                          }
                        : item.is_font_red === "1"
                        ? { color: "red" }
                        : item.is_font_bold === "1"
                        ? { fontWeight: "bold" }
                        : {}
                    }
                  >
                    <div
                      className="App-text-no-width"
                      style={{ whiteSpace: "pre-line" }}
                    >
                      {unescapeStr(item.explain_text)}
                    </div>
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs
                  style={{
                    minWidth: "168px",
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "flex-end",
                  }}
                >
                  <div>
                    <Grid item xs={12} className="App-alignCenter">
                      <Typography variant="body1" color="textSecondary">
                        {item.plan_date}
                      </Typography>
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                    >
                      <Grid item xs className="App-alignCenter App-pLR5">
                        <Tooltip title={Tooltip1} aria-label="classSet">
                          <Button
                            variant="contained"
                            color="primary"
                            className="App-button_bottom"
                            onClick={() => classSetCilck(item.class_id)}
                          >
                            <SettingsIcon />
                          </Button>
                        </Tooltip>
                      </Grid>
                      <Grid item xs className="App-alignCenter App-pLR5">
                        <Tooltip title={Tooltip2} aria-label="viewStatus">
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => viewStatusCilck(item.class_id)}
                          >
                            <WebAssetIcon />
                          </Button>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Paper>
      </Grid>
      {loading ? <LoadingBox /> : null}
    </MainLayout>
  );
};
