import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { store } from "../../reducks/store";
import { MainLayout } from "../../layouts/MainLayout";
import { FormatMessage } from "../../components/atoms/FormatMessage";
import EditIcon from "@material-ui/icons/Edit";
import VpnKeyIcon from '@material-ui/icons/VpnKey';

import { US0304 } from "../../utils/api/US0304";
import { LoadingBox } from "../../components/atoms/LoadingBox";
import sanitize from "sanitize-html";
/**
 * アカウント情報（配信ユーザ）
 * @param {object} props 引き継いだオブジェクト
 * @return コンポーネントHTMLコード内容
 */
export const Set005 = () => {
  const dispatch = useDispatch();

   //input データ
  const [serviceId, setServiceId] = React.useState("");
  const [userId, setUserId] = React.useState("");
  const [userName, setUserName] = React.useState("");
  const [handleName, setHandleName] = React.useState("");
  const [mailAddress, setMailAddress] = React.useState("");
  const [updateDate, setUpdateDate] = React.useState("");

  // loadingフラグ
  const [loading, setLoading] = React.useState(false);

  const pcCilck = () => {
    dispatch(push("/pas/001", { root: "acount" }));
  };
  const editCilck = () => {
    dispatch(
      push("/set/006", {
        serviceId:sanitize(serviceId),
        userId:sanitize(userId),
        userName:sanitize(userName),
        handleName:sanitize(handleName),
        mailAddress:sanitize(mailAddress),
        updateDate:sanitize(updateDate),
      })
    );
  };

  const getUserData = async () => {
    setLoading(true);
    let getData = await US0304(dispatch);
    if (getData && Object.keys(getData).indexOf("body") >= 0) {
      if (Object.keys(getData.body).indexOf("user_name") >= 0) {
        setUserName(getData.body.user_name);
      }
      if (getData && Object.keys(getData.body).indexOf("handle_name") >= 0) {
        setHandleName(getData.body.handle_name);
      }
      if (Object.keys(getData.body).indexOf("mail_address") >= 0) {
        setMailAddress(getData.body.mail_address);
      }
      if (getData && Object.keys(getData.body).indexOf("update_date") >= 0) {
        setUpdateDate(getData.body.update_date);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    const user = store.getState().user;
    setServiceId(user.service_id);
    setUserId(user.user_id);
    getUserData();
  }, []);

  return (
    <MainLayout>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className="App-paper">
            <Typography
              component="h2"
              variant="h6"
              color="inherit"
              gutterBottom
            >
              {FormatMessage({ id: "Set005.Title" })}
            </Typography>
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
            >
              <Grid className="App-pLR5">
                <Button
                  variant="contained"
                  color="primary"
                  className="App-button_bottom"
                  startIcon={<EditIcon />}
                  onClick={editCilck}
                >
                  {FormatMessage({ id: "Button.Edit" })}
                </Button>
              </Grid>
              <Grid className="App-pLR5">
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<VpnKeyIcon />}
                  className="App-button_bottom"
                  onClick={pcCilck}
                >
                  {FormatMessage({ id: "Set005.Button.changepPass" })}
                </Button>
              </Grid>
            </Grid>
            <Grid className="App-box">
              <Grid container spacing={3} className="App-mT10">
                <Grid item xs={12} sm={3} className="App-alignLeft" style={{ width: "190px", minWidth: "190px", maxWidth: "190px" }}>
                  {FormatMessage({ id: "Set.Label.ViewerId" })}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="App-text"> {userId}</div>
                </Grid>
              </Grid>
              <Grid container spacing={3} className="App-mT10">
                <Grid item xs={12} sm={3} className="App-alignLeft" style={{ width: "190px", minWidth: "190px", maxWidth: "190px" }}>
                  {FormatMessage({ id: "Set.Label.ViewerName" })}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="App-text">{userName}</div>
                </Grid>
              </Grid>
              <Grid container spacing={3} className="App-mT10">
                <Grid item xs={12} sm={3} className="App-alignLeft" style={{ width: "190px", minWidth: "190px", maxWidth: "190px" }}>
                  {FormatMessage({ id: "Set.Label.HandleName" })}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="App-text"> {handleName}</div>
                </Grid>
              </Grid>
              <Grid container spacing={3} className="App-mT10">
                <Grid item xs={12} sm={3}className="App-alignLeft" style={{ width: "190px", minWidth: "190px", maxWidth: "190px" }}>
                  {FormatMessage({ id: "Set.Label.MailAddress" })}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="App-text">{mailAddress}</div>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      {loading ? <LoadingBox /> : null}
    </MainLayout>
  );
};
