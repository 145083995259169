import { apigw } from "../awsApi";
import { Dispatch } from "@reduxjs/toolkit";

//サンプルデータ
const rows = [
  {
    code:"1",
    value:"記述式",
  },
  {
    code:"2",
    value:"ラジオボタン",
  },  {
    code:"3",
    value:"チェックボックス",
  },  {
    code:"4",
    value:"プルダウン",
  },
]

/**
 * パスワードポリシー取得
 * @param {Dispatch} dispatch reducerディスパッチ
 * @param {string} user_class ユーザクラス

 * @return コンポーネントHTMLコード内容
 */
export const CF0303 = async (
  dispatch: Dispatch,
  classId: string,
) => {
  if (process.env.REACT_APP_API_USE_FLG === "true") {
    return apigw(
      "/CF0303",
      {
        class_id: classId,
      },
      dispatch
    );
  } else {
    //サンプルデータ用
    return {body: rows };
  }
};
