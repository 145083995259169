import React, { useEffect } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
// import ListItemAvatar from "@material-ui/core/ListItemAvatar";
// import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import VideocamIcon from "@material-ui/icons/Videocam";
import PersonIcon from "@material-ui/icons/Person";
// import SettingsVoiceIcon from "@material-ui/icons/SettingsVoice";
// import BorderColorIcon from "@material-ui/icons/BorderColor";
// import ScreenShareIcon from "@material-ui/icons/ScreenShare";
import ActionComand from "./comand";
import MicIcon from "@material-ui/icons/Mic";
import EditIcon from "@material-ui/icons/Edit";
import ImageIcon from "@material-ui/icons/Image";
import { withStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { FormatMessage } from "../../components/atoms/FormatMessage";

const USER_STATUS = {
  JOIN: "JOIN",
  EXIT: "EXIT",
  CHAT: "CHAT",
  REACTION_OK: "REACTION_OK",
  REACTION_NG: "REACTION_NG",
  REACTION_HAND: "REACTION_HAND",
  CHOICE_1: "CHOICE1",
  CHOICE_2: "CHOICE2",
  CHOICE_3: "CHOICE3",
  CHOICE_4: "CHOICE4",
  CHOICE_5: "CHOICE5",
  CHOICE_6: "CHOICE6",
  CHOICE_7: "CHOICE7",
  CHOICE_8: "CHOICE8",
  CHOICE_9: "CHOICE9",
  CHOICE_10: "CHOICE10",
  NOACTION: "NOACTION",
};

const useStyles = makeStyles((theme) => ({
  root: {
    //width: "100%",
    minWidth: 200,
    maxWidth: 600,
    height: 300,
    backgroundColor: theme.palette.background.paper,
    overflow: "scroll",
  },
  inline: {
    display: "inline",
  },
  psition: {
    float: "right",
  },
  icon: {
    width: 40,
    height: 40,
    verticalalign: "middle",
  },
  userInfo: {
    float: "left",
  },
}));

const REQUEST_ID = {
  video: 1,
  voice: 2,
  pen: 3,
  file: 4,
};

const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: "#7cc3dc",
    border: "1px solid #ccc",
    color: "rgba(255, 255, 255, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

export default function AlignItemsList(props: any) {
  const classes = useStyles();
  const [UserList, setUserList] = React.useState<any[]>([]);
  type request = {
    userId: string;
    requestId: number;
  };

  const requestClick = (comand: string, userData: any, requestId: number) => {
    let status;
    switch (requestId) {
      case REQUEST_ID.video:
        status = userData.voiceFlg ? 2 : 1;
        break;
      case REQUEST_ID.voice:
        status = userData.movieFlg ? 2 : 1;
        break;
      case REQUEST_ID.pen:
        status = userData.picFlg ? 2 : 1;
        break;
      case REQUEST_ID.file:
        status = userData.penFlg ? 2 : 1;
        break;
    }

    props.sendAction(comand, userData.user_id, {
      request_type: requestId,
      status: status,
    });
  };

  const makeList = (UserList: any, requestUser: any) => {
    let UserLists: any[] = [];
    UserList.map((listData: any) => {
      const mFlg =
        (listData.user_id === requestUser[0]?.userId &&
          requestUser[0]?.requestId === 2) ||
        (listData.user_id === requestUser[1]?.userId &&
          requestUser[1]?.requestId === 2) ||
        (listData.user_id === requestUser[2]?.userId &&
          requestUser[2]?.requestId === 2) ||
        (listData.user_id === requestUser[3]?.userId &&
          requestUser[3]?.requestId === 2) ||
        (requestUser.length >= 2 &&
          listData.user_id !== requestUser[0]?.userId &&
          listData.user_id !== requestUser[1]?.userId &&
          requestUser[0]?.userId !== requestUser[1]?.userId) ||
        (requestUser.length >= 3 &&
          listData.user_id !== requestUser[0]?.userId &&
          listData.user_id !== requestUser[1]?.userId &&
          listData.user_id !== requestUser[2]?.userId &&
          requestUser[0]?.userId !== requestUser[2]?.userId) ||
        (requestUser.length === 4 &&
          listData.user_id !== requestUser[0]?.userId &&
          listData.user_id !== requestUser[1]?.userId &&
          listData.user_id !== requestUser[2]?.userId &&
          listData.user_id !== requestUser[3]?.userId)
          ? true
          : false;

      const vFlg =
        (listData.user_id === requestUser[0]?.userId &&
          requestUser[0]?.requestId === 1) ||
        (listData.user_id === requestUser[1]?.userId &&
          requestUser[1]?.requestId === 1) ||
        (listData.user_id === requestUser[2]?.userId &&
          requestUser[2]?.requestId === 1) ||
        (listData.user_id === requestUser[3]?.userId &&
          requestUser[3]?.requestId === 1) ||
        (requestUser.length >= 2 &&
          listData.user_id !== requestUser[0]?.userId &&
          listData.user_id !== requestUser[1]?.userId &&
          requestUser[0]?.userId !== requestUser[1]?.userId) ||
        (requestUser.length >= 3 &&
          listData.user_id !== requestUser[0]?.userId &&
          listData.user_id !== requestUser[1]?.userId &&
          listData.user_id !== requestUser[2]?.userId &&
          requestUser[0]?.userId !== requestUser[2]?.userId) ||
        (requestUser.length === 4 &&
          listData.user_id !== requestUser[0]?.userId &&
          listData.user_id !== requestUser[1]?.userId &&
          listData.user_id !== requestUser[2]?.userId &&
          listData.user_id !== requestUser[3]?.userId)
          ? true
          : false;

      const peFlg =
        (listData.user_id === requestUser[0]?.userId &&
          requestUser[0]?.requestId === 4) ||
        (listData.user_id === requestUser[1]?.userId &&
          requestUser[1]?.requestId === 4) ||
        (listData.user_id === requestUser[2]?.userId &&
          requestUser[2]?.requestId === 4) ||
        (listData.user_id === requestUser[3]?.userId &&
          requestUser[3]?.requestId === 4) ||
        (requestUser.length >= 2 &&
          listData.user_id !== requestUser[0]?.userId &&
          listData.user_id !== requestUser[1]?.userId &&
          requestUser[0]?.userId !== requestUser[1]?.userId) ||
        (requestUser.length >= 3 &&
          listData.user_id !== requestUser[0]?.userId &&
          listData.user_id !== requestUser[1]?.userId &&
          listData.user_id !== requestUser[2]?.userId &&
          requestUser[0]?.userId !== requestUser[2]?.userId) ||
        (requestUser.length === 4 &&
          listData.user_id !== requestUser[0]?.userId &&
          listData.user_id !== requestUser[1]?.userId &&
          listData.user_id !== requestUser[2]?.userId &&
          listData.user_id !== requestUser[3]?.userId)
          ? true
          : false;

      const piFlg =
        (listData.user_id === requestUser[0]?.userId &&
          requestUser[0]?.requestId === 3) ||
        (listData.user_id === requestUser[1]?.userId &&
          requestUser[1]?.requestId === 3) ||
        (listData.user_id === requestUser[2]?.userId &&
          requestUser[2]?.requestId === 3) ||
        (listData.user_id === requestUser[3]?.userId &&
          requestUser[3]?.requestId === 3) ||
        (requestUser.length >= 2 &&
          listData.user_id !== requestUser[0]?.userId &&
          listData.user_id !== requestUser[1]?.userId &&
          requestUser[0]?.userId !== requestUser[1]?.userId) ||
        (requestUser.length >= 3 &&
          listData.user_id !== requestUser[0]?.userId &&
          listData.user_id !== requestUser[1]?.userId &&
          listData.user_id !== requestUser[2]?.userId &&
          requestUser[0]?.userId !== requestUser[2]?.userId) ||
        (requestUser.length === 4 &&
          listData.user_id !== requestUser[0]?.userId &&
          listData.user_id !== requestUser[1]?.userId &&
          listData.user_id !== requestUser[2]?.userId &&
          listData.user_id !== requestUser[3]?.userId)
          ? true
          : false;

      const userInfo = {
        user_id: listData.user_id,
        user_name: listData.user_name,
        handle_name: listData.handle_name,
        status: listData.status,
        movieFlg: mFlg,
        voiceFlg: vFlg,
        penFlg: peFlg,
        picFlg: piFlg,
      };
      UserLists.push(userInfo);
    });
    setUserList(UserLists);
  };

  const titleImage = FormatMessage({ id: "Clslist.Button.Image" });
  const titlePen = FormatMessage({ id: "Clslist.Button.Pen" });
  const titleVideo = FormatMessage({ id: "Clslist.Button.Video" });
  const titleMic = FormatMessage({ id: "Clslist.Button.Mic" });

  useEffect(() => {
    makeList(props.ListViewList, props.requestUser);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.requestUser]);

  return (
    <List className={classes.root}>
      {UserList.map((listData: any, index: number) => (
        <ListItem alignItems="flex-start" key={"user_list_" + index}>
          <PersonIcon
            style={{
              color:
                listData.status === USER_STATUS.EXIT
                  ? "#c8c8cb"
                  : listData.status === USER_STATUS.JOIN
                  ? "#fff100"
                  : listData.status === USER_STATUS.CHAT
                  ? "#d8f255"
                  : listData.status === USER_STATUS.REACTION_OK
                  ? "#005aff"
                  : listData.status === USER_STATUS.REACTION_NG
                  ? "#ff4b00"
                  : listData.status === USER_STATUS.REACTION_HAND
                  ? "#03af7a"
                  : listData.status === USER_STATUS.CHOICE_1
                  ? "#78e00b"
                  : listData.status === USER_STATUS.CHOICE_2
                  ? "#26d4e0"
                  : listData.status === USER_STATUS.CHOICE_3
                  ? "#e0b501"
                  : listData.status === USER_STATUS.CHOICE_4
                  ? "#c504e0"
                  : listData.status === USER_STATUS.CHOICE_5
                  ? "#e0610b"
                  : listData.status === USER_STATUS.CHOICE_6
                  ? "#ace072"
                  : listData.status === USER_STATUS.CHOICE_7
                  ? "#8ddbe0"
                  : listData.status === USER_STATUS.CHOICE_8
                  ? "#e0c868"
                  : listData.status === USER_STATUS.CHOICE_9
                  ? "#c45dd4"
                  : listData.status === USER_STATUS.CHOICE_10
                  ? "#e09f72"
                  : "#84919e",
              fontSize: "40px",
            }}
          />
          <ListItemText
            className={classes.userInfo}
            primary={
              <div>
                <div>{listData.user_id}</div>
                <div>{listData.user_name}</div>
                <div>{listData.handle_name}</div>
              </div>
            }
          />
          <React.Fragment>
            <LightTooltip title={titleVideo} placement="top">
              <IconButton
                aria-label="delete"
                disabled={listData.movieFlg}
                onClick={() => {
                  requestClick(
                    ActionComand.MSG_JOIN_REQUEST,
                    listData,
                    REQUEST_ID.video
                  );
                }}
              >
                <VideocamIcon />
              </IconButton>
            </LightTooltip>
            <LightTooltip title={titleMic} placement="top">
              <IconButton
                aria-label="delete"
                disabled={listData.voiceFlg}
                onClick={() => {
                  requestClick(
                    ActionComand.MSG_JOIN_REQUEST,
                    listData,
                    REQUEST_ID.voice
                  );
                }}
              >
                <MicIcon />
              </IconButton>
            </LightTooltip>
            <LightTooltip title={titlePen} placement="top">
              <IconButton
                aria-label="delete"
                disabled={listData.penFlg}
                onClick={() => {
                  requestClick(
                    ActionComand.MSG_JOIN_REQUEST,
                    listData,
                    REQUEST_ID.pen
                  );
                }}
              >
                <EditIcon />
              </IconButton>
            </LightTooltip>
            <LightTooltip title={titleImage} placement="top">
              <IconButton
                aria-label="delete"
                disabled={listData.picFlg}
                onClick={() => {
                  requestClick(
                    ActionComand.MSG_JOIN_REQUEST,
                    listData,
                    REQUEST_ID.file
                  );
                }}
              >
                <ImageIcon />
              </IconButton>
            </LightTooltip>
          </React.Fragment>
        </ListItem>
      ))}
    </List>
  );
}
