export const jaLocaleData = {
  // ログイン画面
  "SignIn.StreamTitle": "配信ユーザログイン",
  "SignIn.ViewerTitle": "視聴ユーザログイン",
  "SignIn.UserId": "ユーザID",
  "SignIn.UserId.Required": "ユーザIDを入力してください",
  "SignIn.Password": "パスワード",
  "SignIn.Password.Required": "パスワードを入力してください",
  "SignIn.Text.Lock":
    "6回以上連続でパスワードを間違えると、アカウントがロックされ、一定時間ログインできなくなります。\nロックがかかった場合は、一定時間経過するまで解除することはできません。",
  "SignIn.Button": "ログイン",
  "SignIn.Language": "言語",
  "SignIn.ss": "こんにちは! { name }!",
  "SignIn.Help.Text1": "連続でログイン失敗すると、アカウントがロック",
  "SignIn.Help.Text2": "され一定時間ログインできなくなります。",
  "SignIn.Help.Text3": "パスワードがわからなくなったときは、ロックがかかる前に",
  "SignIn.Help.Text4": "パスワードの再設定",
  "SignIn.Help.Text5": "をされることをおすすめいたします。",
  "SignIn.Help.Text6":
    "6回以上連続で、ログイン失敗すると一定時間ログインできなくなります。ロックがかかった場合は、一定時間経過するまで解除することはできません。",
  "SignIn.Help.Text7":
    "6回目以降、ロックされる時間は段階的に長くなっていきます。（10回目で16秒、16回目以降は15分）",
  "SignIn.Dialog.Title": "ログインエラー",
  "SignIn.Dialog.Content":
    "すでにログインしています。他機器での接続を切断してログインを継続しますか？",
  "SignIn.Dialog.LeftButton": "はい",
  "SignIn.Dialog.RightButton": "いいえ",
  "SignIn.Error.Login1":
    "ログインに失敗しました。",
  "SignIn.Error.Login2":
    "ID・パスワードが間違っているか、連続してログイン失敗したためにIDがロックされている可能性があります。",
    "SignIn.Check.Text1": "初めてライブ授業に参加する前には、",
    "SignIn.Check.Text2": "ネットワーク環境チェック",
    "SignIn.Check.Text3": "の実施をお願いいたします。",


  //共有エラーメッセージ
  "Error.Message.PostErr":
    "通信エラー：時間を置いてから再度実行してください。実行できない場合は，システム管理者に連絡してください。",
  "Error.Message.MakeCsvErr":
    "只今、CSVを作成しています。しばらくお待ちください。",
  "Error.Message.FormatError":
    "【{ name }】は、{ name }の書式で入力してください",
  "Error.Message.TypeError": "【{ name }】は、{ type }で入力してください",
  "Error.Message.RequiredError": "【{name}】を入力してください",
  "Error.Message.duplicateError": "指定した{name}は、既にテロップが登録されているか、再生時間外のため、登録できません。",
  "Error.Message.MismatchError":
    "新しいパスワードとして入力された値が確認用パスワードと一致しません",
  "Error.Message.PolicyError":
    "新しいパスワードとして入力された値がパスワードポリシーを満たしていません。",
  "Error.Message.UpdateError":
    "更新に失敗しました。再度「更新する」ボタンを押してください。",
  "Error.Message.NotLoggedIn":
    "ログイン状態が確認できません。\r\nお手数ですが、再度ログインしなおしてください。",
  "Error.Message.SystemErr":
    "システムエラーが発生しました。\r\nシステム管理者にお問い合わせください。",
  "Error.Message.Timeout":
    "タイムアウトが発生しました。\r\nシステム管理者にお問い合わせください。",
  "Error.ExlusiveControl":
    "他のユーザによって更新または削除されています。対象のデータを表示しなおしてください。",
    "Error.Message.ClassTime":
    "ご契約いただいているプランの利用上限を超えたため、配信を停止しました。",
    "Error.Message.SafariCheck":
    "大変恐れ入りますが、当システムでは Mac Safari\r\nでの配信をサポートしておりません。\r\n\r\nMac をご使用の場合は、Google Chrome での配信を推奨いたします。",

  // Menu
  "Menu.Top": "トップ",
  "Menu.CountInfo": "アカウント情報",
  "Menu.CourseHistory": "受講記録",
  "Menu.Logout": "ログアウト",

  //共有ボタン
  "Button.NewRegister": "新規登録",
  "Button.Edit": "編集",
  "Button.Del": "削除",
  "Button.Download": "ダウンロード",
  "Button.Register": "登録する",
  "Button.Update": "更新する",
  "Button.Delete": "削除する",
  "Button.Search": "検索",
  "Button.Close": "閉じる",
  "Button.Confirmation": "確認画面へ",
  "Button.AddBack": "登録画面に戻る",
  "Button.EditBack": "編集画面に戻る",

  // フッター（Copyright）
  "Copyright.TermsOfUse": "利用規約",
  "Copyright.PrivacyPolicy": "プライバシーポリシー",
  "Copyright.HelpSupport": "ヘルプ・サポート",
  "Copyright.OperatingCompany": "運営会社",
  // "Copyright.SystemVersion": "システムバージョン",
  "Copyright.SystemVersion": "powered by educast (ver.{version})",

  //Agora
  "Agora.Error.System":
    "システムエラーが発生しました。ご不便をおかけして申し訳ございません。\r\nお手数ですが、サービス運営者までお問い合わせください。",
  "Agora.Error.Browser":
    "お使いのブラウザではご利用いただけません。\r\nお手数ですが、ブラウザを変えてご利用ください。",
  "Agora.Error.Timeout":
    "タイムアウトが発生しました。\r\nお手数ですが、通信状態をご確認の上、しばらくたってから接続しなおしてください。",
  "Agora.Error.Policy":
    "お使いのブラウザのセキュリティポリシーにより、ご利用が制限されています。ブラウザの設定をご確認ください。\r\n別のブラウザでおためしいただいてもエラーが発生する場合は、サービス運営者までお問い合わせください。",
  "Agora.Error.NoResponce":
    "サーバからの応答がありません。ご不便をおかけして申し訳ございません。\r\nお手数ですが、しばらくたってから、やりなおしてください。\r\nエラーが続く場合は、サービス運営者までお問い合わせください。",
  "Agora.Error.SameUser":
    "すでに同一のユーザが接続されているため、接続することができません。\r\nユーザIDをお確かめの上、接続しなおしてください。",
    "Agora.Error.NoDevice":
    "カメラまたはマイクが取得できません。\r\n別のブラウザまたはアプリケーションでカメラとマイクが使用されていないかお確かめの上、デバイスを指定し、再接続ボタンを押下、または、退出ボタン押下後、再度授業に入室してください",

  //Network
  "Network.Message1": "通信が不安定になっています。",


  //top/001
  "Top001.Title": "配信ユーザトップ ",
  "Top001.Label.text1": "配信予定 ",
  "Top001.Button.text1": "授業設定情報",
  "Top001.Button.text2": "授業視聴状況",
  "Top001.Tooltip.text1": "授業設定",
  "Top001.Tooltip.text2": "授業視聴状況一覧",

  //top/002
  "Top002.Title": "視聴ユーザトップ",
  "Top002.Label.text1": "配信予定",
  "Top002.Button.iconText": "末",
  "Top002.Tooltip.text1": "ライブ配信",
  "Top002.Tooltip.text2": "資料ダウンロード",
  "Top002.Tooltip.text3": "オンデマンド",

  "Top002.Status.text1": "配信前",
  "Top002.Status.text2": "配信準備中",
  "Top002.Status.text3": "授業前",
  "Top002.Status.text4": "授業中",
  "Top002.Status.text5": "授業終了",
  "Top002.Status.text6": "配信中断",

  "Top002.Dialog.title": "資料ダウンロード",
  "Top002.Dialog.lable": "授業名",
  "Top002.Dialog.table": "ファイル名",

  "Top002.Check.Error":
    "現在、授業の視聴に制限がかけられているため、ご利用いただけません。",
  "Top002.Check.ErrorText":
    "現在ご利用中のユーザー数が定員を超えているためアクセスできません",
  "Top002.Check.ErrorText2":
    "既に授業を開いています。授業画面を閉じてください。",
  "Top002.KeywordSearch.NoDataError": "検索条件にあてはまる授業が見つかりませんでした。",
  "Top002.KeywordSearch.ValidationError.TooManyCharacters": "入力文字数がオーバーしています。検索文字は125文字以下で入力してください。",
  "Top002.KeywordSearch.ValidationError.InvalidDate": "日付が不正です",
  "Top002.KeywordSearch.": "日付が不正です",
  "Top002.KeywordSearch.SearchLabel": "授業名/説明文からさがす",
  "Top002.KeywordSearch.SearchText": "未視聴のオンデマンドのみ:",
  "Top002.KeywordSearch.SearchLabel2": "配信日：",
  "Top002.KeywordSearch.ConditionClearButton": "条件クリア",
  "Top002.KeywordSearch.SearchButton": "検索",

  //cls/001
  "Cls001.Title": "授業設定 ",

  "Cls001.Button.text1": "授業を始める ",
  "Cls001.Button.text2": "削除 ",
  "Cls001.Button.text3": "全件削除 ",
  "Cls001.Button.text4": "ファイルを選択",
  "Cls001.Button.text5": "公開日設定",
  "Cls001.Button.text6": "確認画面へ",
  "Cls001.Button.text7": "カスタムアンケート参照",
  "Cls001.Button.text8": "編集内容の保存",
  "Cls001.Button.cancel": "キャンセル",
  "Cls001.Button.OK": "OK",

  "Cls001.Dialog.title1": "削除確認",
  "Cls001.Dialog.title2": "削除完了",
  "Cls001.Dialog.title3": "編集内容保存確認",
  "Cls001.Dialog.title4": "編集内容保存完了",
  "Cls001.Dialog.text1": "この{name}を削除しますか？",
  "Cls001.Dialog.text2": "{name}を全て削除しますか？",
  "Cls001.Dialog.text3": "編集内容を保存します。よろしいですか？",
  "Cls001.Dialog.text4": "削除しました。",
  "Cls001.Dialog.text5": "編集内容が保存されていません。移動しますか？",
  "Cls001.Dialog.text6": "保存しました。",

  "Cls001.Label.text1": "資料アップロード",
  "Cls001.Label.text2": "授業後ページ設定",
  "Cls001.Label.text3": "授業ID",
  "Cls001.Label.text4": "授業名",
  "Cls001.Label.text5": "授業後ページ",
  "Cls001.Label.text6": "外部URL",
  "Cls001.Label.text7": "テンプレート",
  "Cls001.Label.text8": "回答前コメント",
  "Cls001.Label.text9": "回答後コメント",
  "Cls001.Label.text10": "テロップ編集",

  "Cls001.Title.text1": "画像資料 ",
  "Cls001.Title.text2": "動画資料 ",
  "Cls001.Title.text3": "配布資料 ",
  "Cls001.Title.text4": "授業後ページ設定",
  "Cls001.Title.text5": "アンケート内容",

  "Cls001.List.text1": "サービス設定にしたがう",
  "Cls001.List.text2": "デフォルトアンケート",
  "Cls001.List.text3": "カスタムアンケート",
  "Cls001.List.text4": "外部URL",
  "Cls001.List.text5": "表示しない",
  "Cls001.List.text6": "新規で作成する",

  "Cls001.Area.text1": "ここにファイルをドロップ",
  "Cls001.Area.text2": "または",

  "Cls001.Dialog.Title": "公開日時設定",
  "Cls001.Dialog.textYes": "はい",
  "Cls001.Dialog.textNo": "いいえ",

  "Cls001.Err.Msg1":
    "ご契約いただいているプランの利用上限を超えたため、現在、配信が制限されています。",
  "Cls001.Err.Msg2": "配信が終了しております。再度ご確認お願い致します。",
  "Cls001.Err.Msg3": "外部サイトのURLが入力されていません",
  "Cls001.Err.Msg4": "入力に誤りがあります",
  "Cls001.Err.Msg5":
    "アップロードされたファイル内に誤りがあります。\r\nエラー内容をご確認のうえファイルを修正し、再度ファイルをアップロードしてください。",
  "Cls001.Err.Msg6":"再度入室することができません",
  "Cls001.Err.Msg7":
  "アップロード可能なファイル件数の上限を超えているためアップロードできません。アップロード可能なファイルは500件までです。",
  "Cls001.Err.Msg8":
  "アップロード可能なファイル件数の上限を超えているためアップロードできません。アップロード可能なファイルは50件までです。",
  "Cls001.Err.Msg9":"外部サイトのURLが入力されていません。",
  "Cls001.Err.Msg10":
    "ご契約いただいているプランの授業保存時間を超えたため、現在、ご利用が制限されています。",
  "Cls001.Err.Msg11":
    "をアップロードできません、再度ファイルをアップロードしてください。",

  //cls/002
  "Cls002.Title": "授業後ページ設定確認 ",
  "Cls002.Button.back": "授業後ページ設定に戻る ",

  //cls/003
  "Cls003.Title": "授業後ページ設定完了 ",
  "Cls003.Content.text": "授業後設定が完了しました。",

  //cls/004
  "Cls004.Title": "カスタムアンケート参照",
  "Cls004.Button.back": "授業設定に戻る ",
  "Cls004.Alert.title": "削除 ",
  "Cls004.Alert.text": "このカスタムアンケートを削除しますか？ ",
  "Cls004.Alert.leftButton": "キャンセル ",
  "Cls004.Alert.rightButton": "削除する",
  "Cls004.Button.add": "追加",
  "Cls004.Label.Required": "必須",
  "Cls004.Message.Err": "項目数が上限に達しているため追加できません。",

  //cls/005
  "Cls005.Title": "カスタムアンケート登録編集",
  "Cls005.Label.question": "質問",
  "Cls005.Label.questionClass": "回答方式",
  "Cls005.Label.option": "回答選択肢",
  "Cls005.Label.Required": "必須",
  "Cls005.Button.back": "カスタムアンケート参照に戻る",
  "Cls005.Button.check": "確認画面へ",
  "Cls005.Button.add": "選択肢追加",
  "Cls005.Message.question": "【 質問 】を入力してください。",
  "Cls005.Message.option": "【 選択肢回答 】を入力してください。",
  "Cls005.Message.questionMax": "【 質問 】は{ item }字以内で入力してください。",
  "Cls005.Message.optionMax":
    "【 選択肢回答 】は{ item }字以内で入力してください。",
  "Cls005.Message.itemMax": "項目数が上限に達しているため追加できません",

  //cls/006
  "Cls006.Title": "カスタムアンケート登録編集確認",
  "Cls006.Label.question": "質問:",
  "Cls006.Label.questionClass": "回答方式:",
  "Cls006.Label.Required": "必須:",
  "Cls006.Label.option": "選択肢",
  "Cls006.Message.InsertError": "カスタムアンケートの登録に失敗しました",
  "Cls006.Message.UpdeteError": "カスタムアンケートの更新に失敗しました",
  "Cls006.Message.DeleteError": "カスタムアンケートの削除に失敗しました",

  //cls/007
  "Cls007.Title": "カスタムアンケート登録編集完了",
  "Cls007.Button.back": "カスタムアンケート参照に戻る",
  "Cls007.Content.text": "カスタムアンケート登録編集完了しました。",

  //cls/008
  "Cls008.Button.Exit": "退出",
  "Cls008.Button.Time": "経過時間",
  "Cls008.Button.Record": "録画中",
  "Cls008.Button.SendUser": "送信先",
  "Cls008.Button.AllUser": "全員",
  "Cls008.Button.ChatControl": "制御",
  "Cls008.Text.UserCount": "名接続中",
  "Cls008.Button.UserSelect": "指名",
  "Cls008.Button.ClassStart": "授業開始",
  "Cls008.Button.NoRecordClassStart": "録画せずに授業開始",
  "Cls008.Button.ChatSendSetting": "送信制御",
  "Cls008.Button.ChatSendSetting_All&Teacher": "全員あて・配信ユーザのみ可",
  "Cls008.Button.ChatSendSetting_Teacher": "配信ユーザのみ可",
  "Cls008.Button.ChatSendSetting_No": "全てのチャットを禁止",
  "Cls008.Button.ChatSendSetting_All": "全てのチャットを許可",
  "Cls008.Button.ChatDisplaySetting": "表示制御",
  "Cls008.Button.ChatDisplaySetting_True": "チャットを表示",
  "Cls008.Button.ChatDisplaySetting_False": "チャットを非表示",
  "Cls008.Request.Option1": "動画参加",
  "Cls008.Request.Option2": "音声参加",
  "Cls008.Request.Option3": "手書き参加",
  "Cls008.Request.Option4": "画像追加",
  "Cls008.Dialog.Text1":
    "{ name }から、{type}に「参加しない」と回答がありました。",
  "Cls008.Dialog.Text2":
    "{ name }から、{type}に「参加できない」と回答がありました。",
  "Cls008.Dialog.Text3": "視聴ユーザに画像提出依頼の承諾が取り消されました。",
  "Cls008.Dialog.Button": "OK",
  "Cls008.Dialog.ImgUploadExe.Text1": "ファイル名：",
  "Cls008.Dialog.ImgUploadExe.Button1": "やめる",
  "Cls008.Dialog.ImgUploadExe.Button2": "画像撮影",
  "Cls008.Dialog.ImgUpload.Button1": "アップロード",
  "Cls008.Dialog.Camera.Button1": "再撮影",
  "Cls008.Dialog.Camera.Button2": "撮影",
  "Cls008.Dialog.Camera.Button3": "使用",
  "Cls008.Dialog.Camera.Button4": "キャンセル",
  "Cls008.Request.Text1": "{ name }動画参加中",
  "Cls008.Request.Text2": "{ name }音声参加中",
  "Cls008.Request.Text3": "{ name }手書き参加中",
  "Cls008.Request.Text4": "{ name }画像追加中",
  "Cls008.ToolTip.Mic": "マイクON/OFF切替",
  "Cls008.ToolTip.Camera": "カメラON/OFF切替",
  "Cls008.ToolTip.Device": "デバイス設定",
  "Cls008.ToolTip.Finger": "指差し",
  "Cls008.ToolTip.Color": "ペンの色と太さ",
  "Cls008.ToolTip.Elaser": "消しゴム",
  "Cls008.ToolTip.Clear": "ボード上にある描画を全て消去",
  "Cls008.ToolTip.Shape": "図形の挿入",
  "Cls008.ToolTip.Video": "動画の挿入",
  "Cls008.ToolTip.Before": "前の画像へ",
  "Cls008.ToolTip.Image": "画像の挿入",
  "Cls008.ToolTip.Next": "次の画像へ",
  "Cls008.ToolTip.Share": "PC画面共有",
  "Cls008.ToolTip.ShareStop": "PC画面共有の停止",
  "Cls008.ToolTip.Quiz": "択一問題",
  "Cls008.ToolTip.Expansion": "ボード拡大",
  "Cls008.ToolTip.Hand": "手のひらツール",
  "Cls008.ToolTip.Board": "ボードの切替",
  "Cls008.ToolTip.RequestLyout":
    "視聴画面の表示レイアウト変更(ボード/映像の最大化)",
  "Cls008.ToolTip.LyoutExpansion": "配信画面レイアウト変更(ボード拡大表示)",
  "Cls008.ToolTip.LyoutDefolt": "配信画面レイアウト変更(標準レイアウトに戻る)",
  "Cls008.ToolTip.ImageClear": "画像資料の削除",
  "Cls008.ToolTip.ImageAdd": "画像資料の追加",
  "Cls008.ToolTip.RequestVideo": "動画参加依頼",
  "Cls008.ToolTip.RequestVoice": "音声参加依頼",
  "Cls008.ToolTip.RequestPen": "手書き参加依頼",
  "Cls008.ToolTip.RequestImage": "画像資料追加依頼",
  "Cls008.ToolTip.Chat": "チャット送信",
  "Cls008.Dialog.ImgUploadExe.File": "ファイル選択",
  "Cls008.Dialog.Lyout.VideoMax": "配信ユーザ映像最大化",
  "Cls008.Dialog.Lyout.VideoMin": "配信ユーザ映像最小化",
  "Cls008.Dialog.Lyout.BoardMax": "ボード最大化",
  "Cls008.Dialog.Lyout.BoardMin": "ボード最小化",
  "Cls008.ChatIcon1": "基本モード（全員あて/配信ユーザのみ指定可）",
  "Cls008.ChatIcon2": "配信ユーザのみに送信を許可",
  "Cls008.ChatIcon3": "全てのチャットを禁止",
  "Cls008.ChatIcon4": "全てのチャットを許可",
  "Cls008.GuideMsg": "配信を開始してください",
  "Cls008.ClassStartGuideMsg": "授業を開始してください",
  "Cls008.Dialog.ImagesTitle": "画像追加",
  "Cls008.Dialog.GetImages": "画像撮影",
  "Cls008.Dialog.Reconect": "再接続",
  "Cls008.Dialog.UploadError": "ボードの切り替えに失敗しました。",
  "Cls008.Dialog.ConfirmText": "授業を終了しますか？",
  "Cls008.Dialog.MicSelectText": "マイクを選択し直してください",
  "Cls008.Dialog.OfflineText": "インターネット接続が切断されました。ネットワーク接続を確認してください。",
  "Cls008.Dialog.p2pErrText": "サーバとの通信が切断されました。ネットワーク接続を確認してください。",
  "Cls008.Dialog.WocketErrText": "サーバとの接続ができません。ブラウザリフレッシュ後に、再度配信開始を押してください。\r\n 繰り返しサーバとの接続が失敗する場合は、ブラウザキャッシュをクリアしたうえで、再度配信開始を実行ください。\r\nまたは配信開始⁽Proxy⁾ボタンを押してください。",
  "Cls008.Dialog.SaveQuestionErrText": "択一問題の送信に失敗しました。再度やり直してください",
  "Cls008.RTMErr.Text1":"チャットとの接続が切れました。",
  "Cls008.RTMErr.Text2":"システムが自動で再接続を試行しますが、接続が",
  "Cls008.RTMErr.Text3":"復旧するまでの間は、チャットをご使用いただけません。",
  "Cls008.Restart.Text":"配信再開の処理をしています。このままの状態でお待ちください。\n「授業開始」が操作できるようになるには、30秒程度かかります。",

  //cls/009
  "Cls009.Dialog.Text": "すべての選択肢の内容を消去しますが、よろしいですか？",
  "Cls009.ErrDialog.Text":
    "問題が選択されていません。1問以上選択してください。",
  "Cls009.Dialog.Button1": "キャンセル",
  "Cls009.Dialog.Button2": "OK",
  "Cls009.Button.Clear": "クリア",
  "Cls009.Button.Push": "出題",

  //cls010
  "Cls010.Title": "公開日時設定",
  "Cls010.Label": "授業名：",

  //cls012
  "Cls012.Chat.Option1": "全員",
  "Cls012.Button1": "退出",
  "Cls012.Button2": "送信",
  "Cls012.Button3": "授業設定",
  "Cls012.Button4": "再読み込み",
  "Cls012.Label.Text1": "チャット",
  "Cls012.Label.Text2": "送信先",
  "Cls012.Confirm.Text1": "授業を退出しますか",
  "Cls012.Confirm.Button1": "はい",
  "Cls012.Dialog.JoinLec.Text1":
    "配信ユーザから映像音声参加の依頼がありました。参加しますか？",
  "Cls012.Dialog.JoinLec.Text2":
    "配信ユーザから音声参加の依頼がありました。参加しますか？",
  "Cls012.Dialog.JoinLec.Text3":
    "配信ユーザから授業参加の依頼がありました。参加しますか？",
  "Cls012.Dialog.JoinLec.Button1": "参加する",
  "Cls012.Dialog.JoinLec.Button2": "音声のみで参加する",
  "Cls012.Dialog.JoinLec.Button3": "参加しない",
  "Cls012.Dialog.JoinLec.Button4": "参加できない",
  "Cls012.Dialog.ImgUpload.Text1": "画像提出依頼がありました。提出しますか？",
  "Cls012.Dialog.ImgUpload.Button1": "提出する",
  "Cls012.Dialog.ImgUpload.Button2": "提出しない",
  "Cls012.Dialog.ImgUploadExe.Text1": "名前：",
  "Cls012.Dialog.ImgUploadExe.Button1": "やめる",
  "Cls012.Dialog.ImgUploadExe.Button2": "画像撮影",
  "Cls012.Dialog.ImgUploadExe.File": "ファイル選択",
  "Cls012.Dialog.ImgUploadExe.Error":
    "アップロードするファイルが選択されていません。",
  "Cls012.Dialog.Button": "OK",
  "Cls012.Err.Text1":
    "選択された授業は配信されていないか配信が終了されています。",
  "Cls012.Dialog.Ipad":
    "この授業では音声が再生されます。",
  "Cls012.camera.err":
    "カメラ起動ができませんでした。カメラへのアクセスを許可してください。",
  "Cls012.mic.err":
    "マイク起動ができませんでした。マイクへのアクセスを許可してください。",
  "Cls012.safari.tap":
    "授業の音声を再生するには、動画の部分をタップしてください。",
  "Cls012.Err.Text2":
    "授業に接続できません。しばらく経ってからやり直してください。",
  "Cls012.RTMErr.Text1":"チャットとの接続が切れました。",
  "Cls012.RTMErr.Text2":"しばらく待ってもエラーが消えない場合は、",
  "Cls012.RTMErr.Text3":"ブラウザの再読み込みをおためしください。",

  //cls/013
  "Cls013.Button.Text1": "退出する",
  "Cls013.Button.Text2": "授業再開",
  "Cls013.Button.Text3": "戻る",
  "Cls013.QuizOption.Text": "択一問題",

  //cls/014
  "Cls014.Title": "授業後アンケート",
  "Cls014.Label.text1": "  授業後アンケート",
  "Cls014.Button.confirm": "入力内容を確認する",

  //cls/015
  "Cls015.Title": "授業後アンケート確認",
  "Cls015.Button.Back": "授業後アンケート画面に戻る",
  "Cls015.Button.Register": "送信する",
  "Cls015.Label.text1": "問",
  "Cls015.Label.text2": "回答",
  "Cls015.Message.InsertError": "授業後アンケートの登録に失敗しました。",

  //cls/016
  "Cls016.Title": "送信完了",
  "Cls016.Button.back": "トップに戻る",

  //cls/017
  "Cls017.Title": "授業後外部URL表示",
  "Cls017.Button.back": "トップに戻る",
  "Cls017.Label.text1": "外部サイトに移動します。",
  "Cls017.Label.text2": "これより先は外部サイトに移動します",
  "Cls017.Label.text3": "当システムが運営するものではありません。",
  "Cls017.Label.text4": "【免責事項】",
  "Cls017.Label.text5": "※必ずお読みください。",
  "Cls017.Label.text6":
    "すべてのウェブサイトがスマートフォン等に対応しているとは限りません。\nご利用方法により通信料が高額になる場合がありますのでご注意ください。",
  "Cls017.Label.text7":
    "専用ホームページに掲載する情報には充分に注意を払っておりますが、リンク先のウェブサイトの内容、活動、掲載情報、\n 安全性、その他品質等を保証するものではありません。\nまた、個人情報の取扱い、通信料等、外部サイトの使用ならびに閲覧によるいかなる損害にも管理者は一切の責任を負いかねます。",

  //cls/018
  "Cls018.Title": "受講記録一覧",
  "Cls018.Label.text1": "授業名",
  "Cls018.Label.text2": "ライブ配信授業",
  "Cls018.Label.text3": "出席",
  "Cls018.Label.text4": "欠席",
  "Cls018.Label.text5": "オンデマンド授業",
  "Cls018.Label.text6": "視聴済",
  "Cls018.Label.text7": "一部視聴済",
  "Cls018.Label.text8": "未視聴",
  "Cls018.Label.text9": "授業名",
  "Cls018.Label.text10": "授業時間",
  "Cls018.Label.text11": "ライブ配信授業",
  "Cls018.Label.text12": "オンデマンド授業",
  "Cls018.Label.text13": "視聴時間",
  "Cls018.Label.text14": "出席状況",
  "Cls018.Label.text15": "日時",
  "Cls018.Label.text16": "視聴時間",
  "Cls018.Label.text17": "視聴状況",
  "Cls018.Label.text18": "日時",
  "Cls018.Search.noFind": "検索結果がありませんでした。",
  "Cls018.Label.SearchResult": "検索結果：{ count }件",

  //cls/019
  "Cls019.Label.text1": "テロップは、指定された表示開始時点から指定された表示時間の間、オンデマンド授業のボード上に表示されます。",
  "Cls019.Label.Display_Start": "表示開始",
  "Cls019.Label.Display_Time": "表示時間(秒)",
  "Cls019.Label.Trop": "テロップ",
  "Cls019.Button.Edit": "編集",
  "Cls020.Label.Class_Time": "再生時間",

  //cls/020
  "Cls020.Label.text1": "テロップ編集画面に戻る",
  "Cls020.Label.text2": "登録確認へ",
  "Cls020.Text.StartTime":"表示開始：",
  "Cls020.Text.DisplayTime":"表示時間：",
  "Cls020.Label.Display_Start": "表示時間",
  "Cls020.Label.Display_Time": "表示期間",
  "Cls020.Text.Second":"秒",
  "Cls020.Text.telop":"テロップ：",
  "Cls020.Error.telop":"空白行は登録できません",

  //cls/021

  //cls/list
  "Clslist.Button.Video": "動画参加依頼",
  "Clslist.Button.Mic": "音声参加依頼",
  "Clslist.Button.Pen": "手書き参加依頼",
  "Clslist.Button.Image": "画像資料追加依頼",

  //cls/list3
  "Clslist3.Dialog.Text": "ボード上の手書きを全て削除しますか？",
  "Clslist3.Dialog.Button1": "キャンセル",
  "Clslist3.Dialog.Button2": "OK",

  //cls/list6
  "Clslist6.Button.Left": "前の画像へ",
  "Clslist6.Button.Delete": "画像資料の削除",
  "Clslist6.Button.Add": "画像資料の追加",
  "Clslist6.Button.Right": "次の画像へ",

  //set/001
  "Set001.Title": "アカウント情報",
  "Set001.Button.changepPass": "パスワード変更",
  "Set001.Text.UserId": "配信ユーザID",
  "Set001.Text.UserName": "配信ユーザ名",
  "Set001.Text.MailAddress": "メールアドレス",
  //set/002
  "Set002.Title": "アカウント編集",
  "Set002.Button.back": "アカウント情報に戻る",
  "Set002.Text.UserId": "配信ユーザID",
  "Set002.Text.UserName": "配信ユーザ名",
  "Set002.Text.MailAddress": "メールアドレス",
  "Set002.Text.Required": "必須",
  //set/003
  "Set003.Title": "アカウント編集確認",
  "Set003.ErrMsg1": "配信ユーザの更新に失敗しました",
  "Set003.Text.UserId": "配信ユーザID",
  "Set003.Text.UserName": "配信ユーザ名",
  "Set003.Text.MailAddress": "メールアドレス",
  "Set003.ErrMsg2":
    "入力されたメールアドレスはすでに登録があります。\r\n登録済みのメールアドレスと同じものは新規登録できませんので、違う値に変更してください。",
  //set/004
  "Set004.Title": "アカウント編集完了",
  "Set004.Button.back": "アカウント情報に戻る",
  "Set004.Content.text": "アカウントの編集が完了しました。",

  //set/005,006,007共通
  "Set.Label.ViewerId": "視聴ユーザID",
  "Set.Label.ViewerName": "視聴ユーザ名",
  "Set.Label.HandleName": "ハンドルネーム",
  "Set.Label.MailAddress": "メールアドレス",
  "Set.Text.DuplicationErr":
    "入力されたメールアドレスはすでに登録があります。\r\n登録済みのメールアドレスと同じものは新規登録できませんので、違う値に変更してください。",

  //set/005
  "Set005.Title": "アカウント情報",
  "Set005.Button.changepPass": "パスワード変更",

  //set/006
  "Set006.Title": "アカウント編集",
  "Set006.Button.back": "アカウント情報に戻る",
  "Set006.Text.Required": "必須",

  //set/007
  "Set007.Title": "アカウント編集確認",
  "Set007.Text.UpdateErr": "視聴ユーザの更新に失敗しました",

  //set/008
  "Set008.Title": "アカウント編集完了",
  "Set008.Button.back": "アカウント情報に戻る",
  "Set008.Content.text": "アカウントの編集が完了しました。",

  //sts/001
  "Sts001.Title": "授業視聴状況一覧",
  "Sts001.Label.text1": "視聴状況一覧",
  "Sts001.Label.text2": "アンケート結果",
  "Sts001.Label.text3": "チャット履歴",
  "Sts001.Label.Class_Id": "授業ID",
  "Sts001.Label.Class_Name": "授業名",
  "Sts001.Label.Class_Time": "授業時間 :{val}分",
  "Sts001.Button.Back": "視聴状況一覧へ",
  "Sts001.Button.Download": "表示内容のダウンロード",

  //sts/002
  "Sts002.Title": "授業視聴ログ詳細",
  "Sts001.Search.Label1": "ライブ配信授業",
  "Sts001.Search.Label2": "オンデマンド授業",
  "Sts001.Search.attendance": "出席",
  "Sts001.Search.partialAttendance": "一部出席",
  "Sts001.Search.absence": "欠席",
  "Sts001.Search.watched": "視聴済",
  "Sts001.Search.partiallyWatched": "一部視聴済",
  "Sts001.Search.notWatched": "未視聴",

  "Sts001.Search.userId": "視聴ユーザID",
  "Sts001.Search.userName": "視聴ユーザ名",
  "Sts001.Search.period": "期間",
  "Sts001.Search.placeholder": "半角英数字",
  "Sts001.Search.attendanceTimeThreshold": "視聴時間閾値",
  "Sts001.Search.noFind": "検索結果がありませんでした。",
  "Sts001.Search.Label": "検索条件:",
  "Sts001.Label.SearchResult": "検索結果：{ count }件",

  "Sts001.Help.text1":
    "「視聴時間閾値」とは、授業をどの程度を視聴すれば、出席扱い（オンデマンド授業の場合は視聴済）として表示するのかを指定するための基準値です。",
  "Sts001.Help.text2":
    "（例）100分の授業の場合：視聴時間閾値 80 と設定した場合、表示されるデータは以下のようになります。",
  "Sts001.HelpTable:text1": "ライブ配信授業",
  "Sts001.HelpTable:text2": "出席",
  "Sts001.HelpTable:text3":
    "80分以上、ライプ配信授業の接続記録のある視聴ユーザのデータ",
  "Sts001.HelpTable:text4": "一部出席",
  "Sts001.HelpTable:text5":
    "1～79分間、ライプ配信授業の接続記録のある視聴ユーザのデータ",
  "Sts001.HelpTable:text6": "欠席",
  "Sts001.HelpTable:text7": "表示対象が（データなし）",
  "Sts001.HelpTable:text8": "オンデマンド授業",
  "Sts001.HelpTable:text9": "視聴済",
  "Sts001.HelpTable:text10":
    "80分以上、オンデマンド授業の視聴記録のある視聴ユーザのデータ",
  "Sts001.HelpTable:text11": "一部視聴済",
  "Sts001.HelpTable:text12":
    "1～79分間、オンデマンド授業の視聴記録のある視聴ユーザのデータ",
  "Sts001.HelpTable:text13": "未視聴",
  "Sts001.HelpTable:text14": ">表示対象が（データなし）",

  //sts/002
  "Sts002.Search.text1": "ライブ配信参加者数：{val} / {total} 人　",
  "Sts002.Search.text2": "オンデマンド視聴ユーザ数：{val} / {total} 人　",
  "Sts002.Button.LogDetails": "視聴ログ詳細へ",

  "Sts002.Table.userId": "視聴ユーザID",
  "Sts002.Table.userName": "視聴ユーザ名",
  "Sts002.Table.handleName": "ハンドルネーム",
  "Sts002.Table.viewingTime": "視聴時間（分）",
  "Sts002.Table.viewingTimeState": "出席状況",
  "Sts002.Table.viewingTimeoState": "視聴状況",

  "Sts002.Table.viewingDate": "視聴日時",
  "Sts002.Table.streamingClass": "ライブ配信/オンデマンド",
  "Sts002.Table.elapsedTimeFraction": "視聴位置",
  "Sts002.Table.LiveClass": "ライブ配信",
  "Sts002.Table.VideoClass": "オンデマンド",

  //sts/003
  "Sts003.Title": "チャット履歴",
  "Sts003.Label.text1": "位置",
  "Sts003.Label.text2": "送信者",
  "Sts003.Label.text3": "受信者",
  "Sts003.Label.text4": "タイプ",
  "Sts003.Label.text5": "内容",
  "Sts003.Label.text6": "区分",
  "Sts003.Label.text7": "ID",
  "Sts003.Label.text8": "ユーザ名",
  "Sts003.Label.text9": "ハンドルネーム",
  "Sts003.Label.text10": "区分",
  "Sts003.Label.text11": "ID",
  "Sts003.Label.text12": "ユーザ名",

  //sts/004
  "Sts004.Title": "アンケート結果",
  "Sts004.Button.text1": "最初の質問",
  "Sts004.Button.text2": "前の質問",
  "Sts004.Button.text3": "次の質問",
  "Sts004.Button.text4": "最後の質問",
  "Sts004.Label.text1": "回答日時",
  "Sts004.Label.text2": "ユーザID",
  "Sts004.Label.text3": "視聴ユーザ名",
  "Sts004.Label.text4": "ハンドルネーム",
  "Sts004.Label.text5": "選択肢",
  "Sts004.Label.text6": "回答",
  "Sts004.Label.text7": "選択肢",
  "Sts004.Label.text8": "件数",
  "Sts004.Label.text9": "パーセント",
  "Sts004.Label.text10": "質問：",
  "Sts004.Label.text11": "授業ID",
  "Sts004.Label.text12": "視聴ユーザID",
  "Sts004.Label.text13": "質問No.",
  "Sts004.Label.text14": "質問",
  "Sts004.Label.text15": "回答番号",
  "Sts004.FileName1": "アンケート結果",

  //pas/001
  "Pas001.Title": "パスワード変更",
  "Pas001.label1": "現在のパスワード",
  "Pas001.label2": "新しいパスワード",
  "Pas001.label3": "新しいパスワード（確認用）",
  "Pas001.Button.Back": "アカウント情報に戻る",
  "Pas001.Text.Required": "必須",
  "Pas001.Text.Error1": "現在のパスワードとして入力された値が正しくありません",
  "Pas001.Text.Error2":
    "現在のパスワードと同じ値は、新しいパスワードにすることができません。違うパスワードを設定してください",
  "Pas001.Message.Top1": "パスワードを変更してください。",
  "Pas001.Message.Top2": "パスワードを初期パスワードから変更してください。",
  "Pas001.Message.Top3":
    "パスワードの有効期限が過ぎています。パスワードを変更してください。",

  //pas/002
  "Pas002.Title": "パスワード変更完了",
  "Pas002.Button.Back": "{name}に戻る",
  "Pas002.Content": "パスワードの変更が完了しました。",

  //pas/003
  "Pas003.Title": "パスワード初期化",
  "Pas003.Button.Back": "ログイン画面に戻る",
  "Button.Send": "送信する",
  "Pas003.Button.sent": "送信する",
  "Pas003.label": "メールアドレス",
  "Pas003.Content":
    "IDにご登録されているメールアドレスをご入力の上、「送信する」ボタンを押してください。\nご登録の メールアドレスあてに、パスワードリセット用のコードが送信されます。",
  "Pas003.Help":
    " ※ IDにメールアドレスを登録されていない場合は、パスワードリセット用のコードを受信できません。\nその場合は、このサイトの運営者までお問い合わせください。",

  //pas/004
  "Pas004.Title": "パスワード再設定",
  "Pas004.label1": "コード",
  "Pas004.label2": "新しいパスワード",
  "Pas004.label3": "新しいパスワード（確認用）",
  "Pas004.Button.Back": "パスワード初期化に戻る",
  "Pas004.Content":
    "ご登録のメールアドレスあてに届いたコードをご入力の上、新しいパスワードを再設定してください。\nコードが届かない場合",
  "Pas004.Text.Help1":
    "・メールが迷惑メールフォルダに分類されていないかご確認ください。",
  "Pas004.Text.Help2":
    "・メールアドレスのご入力が正しくない可能性があります。パスワード初期化画面へ戻り、再度おためしください。",
  "Pas004.Text.Help3":
    "・ご登録のメールアドレスがご不明な場合は、サイトの運営者までお問い合わせください。",
  "Pas004.Text.Required": "必須",
  "Pas004.Text.CodeErr":
    "パスワードの変更に失敗しました。\r\nリセットコードが一致しないか、リセットコードの有効期限が切れています。リセットコードが正しいかご確認ください。有効期限が切れている場合は、\r\nログイン画面からパスワードの再設定を行ってください。",

  //rtm/001
  "Rtm001.Title": "educast ネットワーク環境チェック",
  "Rtm001.Button.Back": "ログインに戻る",
  "Rtm001.Button.check1": "チェックを開始する",
  "Rtm001.Button.check2": "",
  "Rtm001.Text.RTCurl": "RTCチェックは下記URLからご実施ください。",
  "Rtm001.Text.Explanation1": "※このチェックは、ライブ配信授業をご利用になる場合に事前に行ってください。",
  "Rtm001.Text.Explanation2": "（オンデマンド授業のみご利用の場合は、このチェックは必要ございません）",
  "Rtm001.Text.Explanation3": "このページではお使いのネットワーク環境が、educastのライブ配信授業で必要とする通信が許可されているのかの確認を行います。手順にしたがってお進みください。",
  "Rtm001.ChatCheck.Title": "1. チャットのチェック",
  "Rtm001.ChatCheck.Explanation1": "チャットに必要な通信が許可されているかの確認を行います。",
  "Rtm001.ChatCheck.Explanation2": "以下の「チェックを開始する」ボタンをタップ（クリック）してください。",
  "Rtm001.StreamCheck.Title": "2. 動画・音声のチェック",
  "Rtm001.StreamCheck.Explanation1": "動画・音声に必要な通信が許可されているかの確認を行います。",
  "Rtm001.StreamCheck.Explanation2": "1. 以下の「チェックを開始する」ボタンをタップすると、チェック用のページが開きます。",
  "Rtm001.StreamCheck.Explanation3": "2. 画面の説明にしたがって、チェックをしてください。",
  "Rtm001.StreamCheck.Explanation4": "※Apple製品（iPad,iPhone,macOS）をご利用の方へ",
  "Rtm001.StreamCheck.Explanation5": "iPadOS/iOS 17、Safari バージョン17をお使いの場合、「ネットワーク接続」のチェック結果が正しく出ない事象が確認されております。",
  "Rtm001.StreamCheck.Explanation6": "それ以外の項目で問題がないようでしたら、educast のライブ通信はできておりますので、そのままご利用ください。",
  "Rtm001.StreamCheck.Explanation7": "ご不便をおかけして申し訳ございませんが、よろしくお願いいたします。",
  "Rtm001.ChatCheck.ResultOK1": "お使いのネットワーク環境で、チャット機能をご利用頂けます。",
  "Rtm001.ChatCheck.ResultOK2": "引き続き、動画・音声のチェックを行ってください。",
  "Rtm001.ChatCheck.ResultNG1": "大変恐れ入りますが、お使いのネットワーク環境ではチャット機能がご利用いただけません。",
  "Rtm001.ChatCheck.ResultNG2": "別のネットワークに接続を変えて、再度お試しください。",

  //パスワードポリシー

  "Policy.Text1": "【stringLength】文字以上で入力してください。",
  "Policy.Text2":
    "【value】を含めた【stringLength】文字以上で入力してください。",

  //Message List
  "TYPE-1": "半角",
  "TYPE-2": "全角",
  "TYPE-3": "数値",
  HS009: "【{name}】は、（{type}）で入力してください。",
  HS011: "【{option}】を選択してください。",
  HS012: "【{name}】を入力してください。",
  HS016:
    "このIDはロックされているため現在ログインすることができません。\r\n時間をおいてから再度おためしください。",
  HS025: "パスワードを初期パスワードから変更してください。",
  HS049:
    "ファイルが見つかりませんでした。すでに削除されているか、ファイルが破損している可能性があります。",
  HS059:
    "配信ユーザが切断しました。このままお待ちいただくか、しばらく経ってから入室してください。",
  HS060:
    "ユーザ{name}が配信中です。授業を起動しますか？",
  HS061:
    "ユーザ{name}が授業開始するため、退室します。",
  HS067:
    "配信者映像の録画に失敗しました。\r\n再度実行してください。\r\n解決しない場合は、少し時間をおいてから再度実行してください。",

  //API ERROE CODE
  API_ERROR_CODE_1001:
    "ログイン状態が確認できません。お手数ですが、再度ログインしなおしてください。",
  API_ERROR_CODE_1002:
    "システムエラーが発生しました。ご不便をおかけして申し訳ございません。\r\nお手数ですが、サービス運営者までお問い合わせください。",
  API_ERROR_CODE_1003:
    "このIDはロックされているため現在ログインすることができません。\r\n時間をおいてから再度おためしください。",
  API_ERROR_CODE_1004:
    "ログインに失敗しました。\r\nID・パスワードが間違っているか、連続してログイン失敗したためにIDがロックされている可能性があります。",
  API_ERROR_CODE_2001:
    "システムエラーが発生しました。ご不便をおかけして申し訳ございません。\r\nお手数ですが、サービス運営者までお問い合わせください。",
  API_ERROR_CODE_2002:
    "システムエラーが発生しました。ご不便をおかけして申し訳ございません。\r\nお手数ですが、サービス運営者までお問い合わせください。",
  API_ERROR_CODE_2003:
    "システムエラーが発生しました。ご不便をおかけして申し訳ございません。\r\nお手数ですが、サービス運営者までお問い合わせください。",
  API_ERROR_CODE_2004:
    "システムエラーが発生しました。ご不便をおかけして申し訳ございません。\r\nお手数ですが、サービス運営者までお問い合わせください。",
  API_ERROR_CODE_2005:
    "システムエラーが発生しました。ご不便をおかけして申し訳ございません。\r\nお手数ですが、サービス運営者までお問い合わせください。",
  API_ERROR_CODE_2006:
    "システムエラーが発生しました。ご不便をおかけして申し訳ございません。\r\nお手数ですが、サービス運営者までお問い合わせください。",
  API_ERROR_CODE_3001:
    "システムエラーが発生しました。ご不便をおかけして申し訳ございません。\r\nお手数ですが、サービス運営者までお問い合わせください。",
  API_ERROR_CODE_3002:
    "システムエラーが発生しました。ご不便をおかけして申し訳ございません。\r\nお手数ですが、サービス運営者までお問い合わせください。",
  API_ERROR_CODE_3003:
    "システムエラーが発生しました。ご不便をおかけして申し訳ございません。\r\nお手数ですが、サービス運営者までお問い合わせください。",
  API_ERROR_CODE_9001:
    "システムエラーが発生しました。ご不便をおかけして申し訳ございません。\r\nお手数ですが、サービス運営者までお問い合わせください。",
  API_ERROR_CODE_9002:
    "システムエラーが発生しました。ご不便をおかけして申し訳ございません。\r\nお手数ですが、しばらくたってから、やりなおしてください。\r\nエラーが続く場合は、サービス運営者までお問い合わせください。",
  API_ERROR_CODE_9003:
    "システムエラーが発生しました。ご不便をおかけして申し訳ございません。\r\nお手数ですが、しばらくたってから、やりなおしてください。\r\nエラーが続く場合は、サービス運営者までお問い合わせください。",
  API_ERROR_CODE_9004:
    "システムエラーが発生しました。ご不便をおかけして申し訳ございません。\r\nお手数ですが、しばらくたってから、やりなおしてください。\r\nエラーが続く場合は、サービス運営者までお問い合わせください。",
  API_ERROR_CODE_9005:
    "システムエラーが発生しました。ご不便をおかけして申し訳ございません。\r\nお手数ですが、しばらくたってから、やりなおしてください。\r\nエラーが続く場合は、サービス運営者までお問い合わせください。",
  API_ERROR_CODE_9006:
    "システムエラーが発生しました。ご不便をおかけして申し訳ございません。\r\nお手数ですが、しばらくたってから、やりなおしてください。\r\nエラーが続く場合は、サービス運営者までお問い合わせください。",
  API_ERROR_CODE_9007:
    "タイムアウトが発生しました。ご不便をおかけして申し訳ございません。\r\nお手数ですが、しばらくたってから、やりなおしてください。\r\nエラーが続く場合は、サービス運営者までお問い合わせください。",
  API_ERROR_CODE_9008:
    "システムエラーが発生しました。ご不便をおかけして申し訳ございません。\r\nお手数ですが、サービス運営者までお問い合わせください。",
  API_ERROR_CODE_9009:
    "システムエラーが発生しました。ご不便をおかけして申し訳ございません。\r\nお手数ですが、サービス運営者までお問い合わせください。",
  API_ERROR_CODE_9010:
    "システムエラーが発生しました。ご不便をおかけして申し訳ございません。\r\nお手数ですが、サービス運営者までお問い合わせください。",
  API_ERROR_CODE_9011:
    "システムエラーが発生しました。ご不便をおかけして申し訳ございません。\r\nお手数ですが、サービス運営者までお問い合わせください。",
  API_ERROR_CODE_9013:
    "登録しようとした内容は不正なアクセスと判断されたため、処理を停止しました。\r\nお手数ですが、登録内容を見直してから、再度やり直してください。",
};
