import { apigw } from "../awsApi";
import { Dispatch } from "@reduxjs/toolkit";

const rows = {
  class_name: "授業ABC",                             // 授業名
  page_after_class: "2",                            // 授業後ページ区分
  page_after_url: "http",                           // 授業後ページURL
  default_before_answer_msg: "回答してください",     // デフォルトアンケート.回答前メッセージ
  default_after_answer_msg: "ありがとうございます",  // デフォルトアンケート.回答完了メッセージ
  custom_before_answer_msg: "回答してください",     // カスタムアンケート.回答前メッセージ
  custom_after_answer_msg: "ありがとうございます"   // カスタムアンケート.回答完了メッセージ
};

/**
 * 授業詳細取得
 * @param {Dispatch} dispatch reducerディスパッチ
 * @param {string} classId 授業ID
 * @return コンポーネントHTMLコード内容
 */
export const LC0305 = async (
  dispatch: Dispatch,
  classId: string, //授業ID
) => {
  if (process.env.REACT_APP_API_USE_FLG === "true") {
    return apigw(
      "/LC0305",
      {
        class_id: classId,
      },
      dispatch
    );
  } else {
    //サンプルデータ用
    return {
      body: rows
    };
  }
};
