import { apigw } from "../awsApi";
import { Dispatch } from "@reduxjs/toolkit";

/**
 * ログアウト
 * @param {Dispatch} dispatch reducerディスパッチ
 * @return レスポンスデータ
 */
export const AU0901 = async (
  dispatch: Dispatch,
) => {
  if (process.env.REACT_APP_API_USE_FLG === "true") {
    return apigw("/AU0901", {}, dispatch);
  } else {
    //サンプルデータ用
    return {
      state: "0",
      error_code: "",
      body: {},
    };
  }
};
