import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { LoadingBox } from "../../components/atoms/LoadingBox";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import { MainLayout } from "../../layouts/MainLayout";
import { FormatMessage } from "../../components/atoms/FormatMessage";

import { CQ0304 } from "../../utils/api/CQ0304";
import sanitize from "sanitize-html";

/**
 * 授業後アンケート確認
 * @param {object} props 引き継いだオブジェクト
 * @return コンポーネントHTMLコード内容
 */
export const Cls015 = (props: any) => {
  const dispatch = useDispatch();
  const [enqueteType, setEnqueteType] = React.useState("");

  const ERROR_CODE_ADD_FAILED = "09002";

  const [userId, setUserId] = React.useState("");
  const [classId, setClassId] = React.useState("");
  const [isPhone, setIsPhone] = React.useState(false);

  // 回答データ（授業後アンケートからの値受け取り用）
  type inputType = {
    questionNo: string; // 質問No
    question: string; // 質問
    optionNo: string; // 選択肢No
    answer: string; // 回答
    checked: any;
    characterClass: string; // 回答方式 1:記述式、2:ラジオボタン、3:チェックボックス、4:プルダウン
    isRequired: string; // 必須フラグ
    error: any;
  }[];
  const [answerList, setAnswerList] = React.useState<inputType>([]);

  // 表示用データ
  type displayDataType = {
    questionNo: string; // 質問No
    question: string; // 質問
    // optionNo: string; // 選択肢No
    answer: string; // 回答
    characterClass: string;
  }[];
  const [displayData, setDisplayData] = React.useState<displayDataType>([]);

  // 新規登録用データ
  type addDataType = {
    class_id: string;
    answer_user_id: string;
    enquete_class: string;
    question_no: string; // 質問No
    question: string; // 質問
    option_no: string; // 選択肢No
    answer: string; // 回答
  }[];
  const [addData, setAddData] = React.useState<addDataType>([]);

  // 入力エラーフラグ
  type inputError = {
    error: boolean;
    error_message: any;
  };
  const [topError, setTopError] = React.useState<inputError>({
    error: false,
    error_message: "",
  });

  // loadingフラグ
  const [loading, setLoading] = React.useState(false);

  // メッセージの定義
  const messages = {
    // ラベル
    delButton: FormatMessage({ id: "Button.Del" }),
    addButton: FormatMessage({ id: "Cls015.Button.Register" }),
    // exclusiveError: FormatMessage({ id: "Message.UN121" }),
    // 新規登録失敗
    // addFailed: FormatMessage({
    //   id: "Message.UN067",
    //   option: {
    //     target: FormatMessage({ id: "Def003.TitleRaw" }),
    //   },
    // }),
    strQuestion: FormatMessage({ id: "Cls015.Label.text1" }),
    strAnswer: FormatMessage({ id: "Cls015.Label.text2" }),
    //エラーメッセージ
    addFailed: FormatMessage({ id: "Cls015.Message.InsertError" }),
  };

  //ページリロード対応
  let sessionDataValue = localStorage.getItem("Cls015_session");
  const getDataValue =
    typeof props.location.state === "undefined"
      ? sessionDataValue
        ? JSON.parse(sessionDataValue)
        : sessionDataValue
      : props.location.state;
  if (typeof props.location.state !== "undefined") {
    localStorage.setItem(
      "Cls015_session",
      JSON.stringify(props.location.state)
    );
  }

  const getListData = async (callback: any) => {
    //データ取得
    if (getDataValue && Object.keys(getDataValue).indexOf("input") >= 0) {
      setAnswerList(getDataValue.input);
      let newRows = getDataValue.input.map((data: any) => {
        if (data.characterClass === "3" || data.characterClass === "2") {
          return {
            questionNo: data.questionNo, // 質問No
            question: data.question, // 質問
            answer: Object.values(data.checked)
              .filter((val) => val)
              .join(","), // 回答
            characterClass: data.characterClass,
          };
        } else {
          return {
            questionNo: data.questionNo, // 質問No
            question: data.question, // 質問
            answer: data.answer, // 回答
            characterClass: data.characterClass,
          };
        }
      });

      newRows.sort(function (a: any, b: any) {
        if (a.questionNo !== b.questionNo) {
          if (Number(a.questionNo) === null) return 1;
          if (Number(a.questionNo) > Number(b.questionNo)) return 1;
          if (Number(a.questionNo) < Number(b.questionNo)) return -1;
        }
        return 0;
      });
      setDisplayData(newRows);
      const addData: any = [];
      getDataValue.input.reduce((prev: any, data: any) => {
        if (data.characterClass === "3") {
          return Object.keys(data.checked).reduce(
            (prev: any, optionNo: string) => {
              if (Boolean(data.checked[optionNo])) {
                addData.push({
                  class_id: getDataValue.class_id,
                  answer_user_id: getDataValue.user_id,
                  enquete_class: getDataValue.enquete_type,
                  question_no: data.questionNo, // 質問No
                  question: data.question, // 質問
                  option_no: optionNo, // 選択肢No
                  answer: data.checked[optionNo], // 回答
                });
                return prev;
              }
              return prev;
            },
            []
          );
        } else if (data.characterClass === "2") {
          addData.push({
            class_id: getDataValue.class_id,
            answer_user_id: getDataValue.user_id,
            enquete_class: getDataValue.enquete_type,
            question_no: data.questionNo, // 質問No
            question: data.question, // 質問
            option_no: data.optionNo, // 選択肢No
            answer: Object.values(data.checked)
              .filter((val) => val)
              .join(","), // 回答
          });
        } else {
          addData.push({
            class_id: getDataValue.class_id,
            answer_user_id: getDataValue.user_id,
            enquete_class: getDataValue.enquete_type,
            question_no: data.questionNo, // 質問No
            question: data.question, // 質問
            option_no: data.optionNo, // 選択肢No
            answer: data.answer, // 回答
          });
        }
        return prev;
      }, []);
      setAddData(addData);
    }
    if (getDataValue && Object.keys(getDataValue).indexOf("user_id") >= 0) {
      setUserId(getDataValue.user_id);
    }
    if (getDataValue && Object.keys(getDataValue).indexOf("class_id") >= 0) {
      setClassId(getDataValue.class_id);
    }
    if (
      getDataValue &&
      Object.keys(getDataValue).indexOf("enquete_type") >= 0
    ) {
      setEnqueteType(getDataValue.enquete_type);
    }
  };

  //初期処理
  useEffect(() => {
    getListData(null);

    var ua = navigator.userAgent;
    if (ua.indexOf("iPhone") > 0 || ua.indexOf("Android") > 0) {
      setIsPhone(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const confirmClick = async () => {
    setLoading(true);
    setTimeout(() => setLoading(false), 60000);
    // 新規登録
    CQ0304(dispatch, addData).then((getData: any) => {
      if (
        getData &&
        Object.keys(getData).indexOf("state") >= 0 &&
        Object.keys(getData).indexOf("error_code") >= 0
      ) {
        if (getData.state !== "0") {
          setTopError({
            error: true,
            error_message:
              getData.error_code === ERROR_CODE_ADD_FAILED
                ? messages.addFailed
                : "",
          });
        } else {
          setLoading(false);
          dispatch(push("/cls/016"));
        }
      }
    });
  };

  const backClick = () => {
    dispatch(push("/cls/014", { class_id: sanitize(classId), back: true }));
  };

  return (
    <MainLayout>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className="App-paper">
            <Typography
              component="h2"
              variant="h6"
              color="inherit"
              gutterBottom
            >
              {FormatMessage({ id: "Cls015.Title" })}
            </Typography>
            <Grid container className="" alignItems="center">
              <Grid item xs={12} sm={6} className="font-red">
                {topError.error_message}
              </Grid>
            </Grid>
            <Grid item xs={12} className="App-alignRight">
              <Button
                variant="contained"
                color="primary"
                className="App-button_bottom"
                startIcon={<ArrowBackIcon />}
                onClick={backClick}
              >
                {FormatMessage({ id: "Cls015.Button.Back" })}
              </Button>
            </Grid>
            {answerList.length > 0 ? (
              <div>
                {displayData.map((val, index: number) => (
                  <div key={val.questionNo}>
                    <div>
                      <Grid container spacing={3} className="App-mT20">
                        {!isPhone ? <Grid item xs={4}></Grid> : null}
                        <Grid
                          item
                          xs={isPhone ? 12 : 6}
                          container
                          className={
                            isPhone ? "App-mT20 App-mL20 App-mR20" : "App-mT20"
                          }
                          // justify="center"
                          style={{
                            wordBreak: "break-all",
                            overflowWrap: "break-word",
                            whiteSpace: "pre-line",
                          }}
                        >
                          <Grid
                            xs={12}
                            sm={1}
                            alignItems="flex-start"
                            justify="flex-end"
                          >
                            {messages.strQuestion}
                          </Grid>
                          <Grid xs={12} sm={11} alignItems="center">
                            {val.question}
                          </Grid>
                        </Grid>
                        {!isPhone ? <Grid item xs={2}></Grid> : null}
                        {!isPhone ? <Grid item xs={4}></Grid> : null}
                        <Grid
                          item
                          xs={isPhone ? 12 : 6}
                          container
                          className={
                            isPhone ? "App-mT20 App-mL20 App-mR20" : "App-mT20"
                          }
                          style={{
                            overflowWrap: "break-word",
                            wordBreak: "break-all",
                            whiteSpace: "pre-line",
                          }}
                          // justify="center"
                        >
                          <Grid
                            xs={12}
                            sm={1}
                            alignItems="flex-start"
                            justify="flex-end"
                          >
                            {messages.strAnswer}
                          </Grid>
                          <Grid xs={12} sm={11} alignItems="center">
                            {val.answer}
                          </Grid>
                        </Grid>
                        {!isPhone ? <Grid item xs={2}></Grid> : null}
                      </Grid>
                    </div>
                  </div>
                ))}
              </div>
            ) : null}
            <Grid container spacing={3} style={{ marginTop: "20px" }}>
              <Grid item xs={12} className="App-alignCenter">
                <Button
                  variant="contained"
                  color="primary"
                  className="App-button_bottom"
                  onClick={confirmClick}
                >
                  {FormatMessage({ id: "Cls015.Button.Register" })}
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {loading ? <LoadingBox /> : null}
      </Grid>
    </MainLayout>
  );
};
