import { enLocaleData } from "../static/locales/en";
import { jaLocaleData } from "../static/locales/ja";

function getMessages(locale: string) {
  switch (locale) {
    case "en":
      return enLocaleData;
    case "ja":
      return jaLocaleData;
    default:
      return jaLocaleData;
  }
}
export const getMessagesId = (id: string, locale: string, option: any = {}) => {
  let LocaleData = getMessages(locale) as any;
  let returnData = LocaleData[id] ? LocaleData[id] : id;
  //returnData = returnData.replace(/\r?\n/g, "<br>");
  if (typeof option === "object" && Object.keys(option).length > 0) {
    let keys = Object.keys(option);
    for (let index = 0; index < keys.length; index++) {
      let regexp = new RegExp(
        "{\\s*" + keys[index] + "\\s*}|{" + keys[index] + "}",
        "g"
      );
      returnData = returnData.replace(regexp, option[keys[index]]);
    }
  }
  return returnData;
};
