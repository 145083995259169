import { apigw } from "../awsApi";
import { Dispatch } from "@reduxjs/toolkit";

const rows = {
    status:0,
    error_code : null,
    body:null
}

/**
 * 択一問題取得
 * @param {Dispatch} dispatch reducerディスパッチ
 * @param {string} classId 授業ID
 * @return コンポーネントHTMLコード内容
 */
export const QZ0302 = async (
  dispatch: Dispatch,
  classId:string,
  dateNow:any,
  answer:any
) => {
  if (process.env.REACT_APP_API_USE_FLG === "true") {
    return apigw(
      "/QZ0302",
      {
        class_id:classId,
        input_date:dateNow,
        answer_1:answer[1]?.answer,
        answer_2:answer[2]?.answer,
        answer_3:answer[3]?.answer,
        answer_4:answer[4]?.answer,
        answer_5:answer[5]?.answer,
        answer_6:answer[6]?.answer,
        answer_7:answer[7]?.answer,
        answer_8:answer[8]?.answer,
        answer_9:answer[9]?.answer,
        answer_10:answer[10]?.answer,
      },
      dispatch
    );
  } else {
    //サンプルデータ用
    return { body: rows };
  }
};