import { apigw } from "../awsApi";
import { Dispatch } from "@reduxjs/toolkit";

/**
 * 授業視聴時間取得
 * @param {Dispatch} dispatch reducerディスパッチ
 * @param {string} classId 授業ID
 * @return コンポーネントHTMLコード内容
 */
export const LC0307 = async (
  dispatch: Dispatch,
  classId: any[] //授業ID
) => {
  if (process.env.REACT_APP_API_USE_FLG === "true") {
    return apigw(
      "/LC0307",
      {
        class_id: classId,
      },
      dispatch
    );
  } else {
    //サンプルデータ用
    return {
      state: "0",
      err_code: "",
      body: [{}],
    };
  }
};
