import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { push } from "connected-react-router";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { TextareaAutoSize } from "../../components/atoms/TextareaAutoSize";
import FormHelperText from "@material-ui/core/FormHelperText";
import Select from "@material-ui/core/Select";
import Box from "@material-ui/core/Box";
import { LoadingBox } from "../../components/atoms/LoadingBox";

import {
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
} from "@material-ui/core";

import { MainLayout } from "../../layouts/MainLayout";
import { FormatMessage } from "../../components/atoms/FormatMessage";
import { store } from "../../reducks/store";

import { LC0305 } from "../../utils/api/LC0305";
import { CQ0301 } from "../../utils/api/CQ0301";
import { OQ0301 } from "../../utils/api/OQ0301";

/**
 * 授業後アンケート
 * @param {object} props 引き継いだオブジェクト
 * @return コンポーネントHTMLコード内容
 */
export const Cls014 = (props: any) => {
  // [ 変数宣言 ]
  const dispatch = useDispatch();
  const PAGE_AFTER_CLASS_AUTO = "0";
  const PAGE_AFTER_CLASS_DEFAULT = "1";
  const PAGE_AFTER_CLASS_CUSTOM = "2";
  const PAGE_AFTER_CLASS_URL = "3";

  // レンダリング発火用
  const [, rerender] = React.useState();
  const [updata, setUpdata] = React.useState<boolean>(false);
  // 画面用変数
  const [userId, setUserId] = React.useState("");
  const [classId, setClassId] = React.useState("");
  const [className, setClassName] = React.useState("");
  const [enqueteType, setEnqueteType] = React.useState("");
  const [beforeAnswer, setBeforeAnswer] = React.useState("");
  // const [question, setQuestion] = React.useState("");
  // const [questionList, setQuestionList] = React.useState("");
  // const [optionList, setOptionList] = React.useState("");

  // メッセージの定義
  const error_messages = {
    optionRequire: FormatMessage({
      id: "HS011",
      option: {
        option: "name",
      },
    }),
    textRequire: FormatMessage({
      id: "HS012",
      option: {
        name: "name",
      },
    }),
  };

  // 質問データ
  type Rows = {
    questionNo: string; // 質問No
    question: string; // 質問（内容）
    characterClass: string; // 回答方式 1:記述式、2:ラジオボタン、3:チェックボックス、4:プルダウン
    isRequired: string; // 必須フラグ
    updateDate: Date;
  }[];
  const [rows, setRows] = React.useState<Rows>([]);

  // 回答データ
  type inputType = {
    questionNo: string; // 質問No
    question: string; // 質問
    optionNo: string; // 選択肢No
    answer: string; // 回答
    checked: any;
    characterClass: string; // 回答方式 1:記述式、2:ラジオボタン、3:チェックボックス、4:プルダウン
    isRequired: string; // 必須フラグ
    error: any;
  }[];
  // const inputary: any = [];
  const [input, setInput] = React.useState<inputType>([]);

  // loadingフラグ
  const [loading, setLoading] = React.useState(false);

  // [ 処理 ]
  // ページリロード対応
  let sessionDateValue = localStorage.getItem("Cls014_session");
  const getDateValue =
    typeof props.location.state === "undefined"
      ? sessionDateValue
        ? JSON.parse(sessionDateValue)
        : null
      : props.location.state;
  if (typeof props.location.state !== "undefined")
    localStorage.setItem(
      "Cls014_session",
      JSON.stringify(props.location.state)
    );

  // データ処理
  const getListData = async (callback: any) => {
    const userData = store.getState().user;
    setUserId(
      userData && Object.keys(userData).indexOf("user_id") >= 0
        ? userData.user_id
        : ""
    );
    if (userData && Object.keys(getDateValue).indexOf("class_id") >= 0) {
      setClassId(getDateValue?.class_id);
      // 授業一覧データ取得
      let getData = await LC0305(dispatch, getDateValue?.class_id);
      if (getData && Object.keys(getData).indexOf("body") >= 0) {
        // 授業名設定
        setClassName(getData.body.class_name);
        // アンケートタイプ設定
        setEnqueteType(getData.body.page_after_class);
        // 回答前質問、回答後メッセージ設定
        if (
          getData.body.page_after_class === "1" ||
          getData.body.page_after_class === "0"
        ) {
          setBeforeAnswer(getData.body.default_before_answer_msg);
          localStorage.setItem(
            "Cls014_message",
            getData.body.default_after_answer_msg
          );
        } else {
          setBeforeAnswer(getData.body.custom_before_answer_msg);
          localStorage.setItem(
            "Cls014_message",
            getData.body.custom_after_answer_msg
          );
        }
        // アンケートタイプ判定
        if (
          getData.body.page_after_class === PAGE_AFTER_CLASS_DEFAULT ||
          getData.body.page_after_class === PAGE_AFTER_CLASS_AUTO
        ) {
          // 0:サービス設定にしたがう1:デフォルトアンケート、2:カスタムアンケート、3:外部URL、4:表示しない
          setEnqueteType("1");
          await CQ0301(dispatch, null).then((getDefData) => {
            if (
              getDefData &&
              Object.keys(getDefData).indexOf("body") >= 0 &&
              getDefData.body &&
              Object.keys(getDefData.body).indexOf("question") >= 0
            ) {
              if (getDefData.body.question.length > 0) {
                const sortList = getDefData.body.question.sort(function (
                  a: any,
                  b: any
                ) {
                  return Number(a.question_no) - Number(b.question_no);
                });
                let newRows: any[] = sortList.map(
                  (getDataItem: any, index: number) => {
                    const options = getDataItem.option[0];
                    let optionsIndex = 1;
                    let optionsArray = [];
                    while (
                      Object.keys(options).indexOf("option_" + optionsIndex) >=
                      0
                    ) {
                      if (options["option_" + optionsIndex]) {
                        optionsArray.push(options["option_" + optionsIndex]);
                      }
                      optionsIndex++;
                    }
                    return {
                      questionNo: getDataItem.question_no, // 質問No
                      question: getDataItem.question, // 質問（内容）
                      characterClass: getDataItem.question_class, // 回答方式 1:記述式、2:ラジオボタン、3:チェックボックス、4:プルダウン
                      isRequired: getDataItem.is_required, // 必須フラグ
                      options: optionsArray,
                      updateDate: getDataItem.update_date,
                    };
                  }
                );
                setRows(newRows);
                setInput(
                  newRows.map((getDataItem: any, index: number) => {
                    return {
                      questionNo: getDataItem.questionNo, // 質問No
                      question: getDataItem.question, // 質問（内容）
                      optionNo: "",
                      answer: "",
                      checked: {},
                      characterClass: getDataItem.characterClass, // 回答方式 1:記述式、2:ラジオボタン、3:チェックボックス、4:プルダウン
                      isRequired: getDataItem.isRequired, // 必須フラグ
                      error: { is: false, message: "" },
                    };
                  })
                );

                if (
                  props.location.state &&
                  Object.keys(props.location.state).indexOf("back") >= 0 &&
                  props.location.state.back === true
                ) {
                  let sessionData = localStorage.getItem("Cls014_answer");
                  const getAnswer = sessionData
                    ? JSON.parse(sessionData)
                    : null;
                  setInput(getAnswer);
                }
              }
            }
          });
        } else if (
          getData.body.page_after_class === PAGE_AFTER_CLASS_URL &&
          Boolean(getData.body.page_after_url)
        ) {
          window.location.href = getData.body.page_after_url;
        } else if (getData.body.page_after_class === PAGE_AFTER_CLASS_CUSTOM) {
          // 0:サービス設定にしたがう1:デフォルトアンケート、2:カスタムアンケート、3:外部URL、4:表示しない
          setEnqueteType("2");
          // classid受け取り元が分からないので仮設定
          await OQ0301(dispatch, getDateValue?.class_id, null).then(
            (getCusData) => {
              if (
                getCusData &&
                Object.keys(getCusData).indexOf("body") >= 0 &&
                getCusData.body &&
                Object.keys(getCusData.body).indexOf("question") >= 0
              ) {
                if (getCusData.body.question.length > 0) {
                  const sortList = getCusData.body.question.sort(function (
                    a: any,
                    b: any
                  ) {
                    return Number(a.question_no) - Number(b.question_no);
                  });
                  setRows(
                    sortList.map((getDataItem: any, index: number) => {
                      const options = getDataItem.option[0];
                      let optionsIndex = 1;
                      let optionsArray = [];
                      while (
                        Object.keys(options).indexOf(
                          "option_" + optionsIndex
                        ) >= 0
                      ) {
                        if (options["option_" + optionsIndex]) {
                          optionsArray.push(options["option_" + optionsIndex]);
                        }
                        optionsIndex++;
                      }
                      return {
                        questionNo: getDataItem.question_no, // 質問No
                        question: getDataItem.question, // 質問（内容）
                        characterClass: getDataItem.character_class, // 回答方式 1:記述式、2:ラジオボタン、3:チェックボックス、4:プルダウン
                        isRequired: getDataItem.is_required, // 必須フラグ
                        options: optionsArray,
                        updateDate: getDataItem.update_date,
                      };
                    })
                  );
                  setInput(
                    getCusData.body.question.map(
                      (getDataItem: any, index: number) => {
                        return {
                          questionNo: getDataItem.question_no, // 質問No
                          question: getDataItem.question, // 質問（内容）
                          optionNo: "",
                          answer: "",
                          checked: {},
                          characterClass: getDataItem.character_class, // 回答方式 1:記述式、2:ラジオボタン、3:チェックボックス、4:プルダウン
                          isRequired: getDataItem.is_required, // 必須フラグ
                          error: { is: false, message: "" },
                        };
                      }
                    )
                  );
                  if (
                    props.location.state &&
                    Object.keys(props.location.state).indexOf("back") >= 0 &&
                    props.location.state.back === true
                  ) {
                    let sessionData = localStorage.getItem("Cls014_answer");
                    const getAnswer = sessionData
                      ? JSON.parse(sessionData)
                      : null;
                    setInput(getAnswer);
                  }
                }
              }
            }
          );
        }
      }
      // 質問データ取得
    }
  };

  useEffect(() => {
    setLoading(true);
    //データ取得
    getListData(null).finally(() => {
      rerender(undefined);
      setTimeout(setLoading, 1000, false);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeAnswer = (
    answer: string,
    optionNo: string,
    row: any,
    checked: boolean = false,
    checkedLabel: string = ""
  ) => {
    // eslint-disable-next-line array-callback-return
    input.map((item: any) => {
      if (item.questionNo === row.questionNo) {
        if (row.characterClass === "3") {
          // チェックボックスの時
          let checkedObject: any = {};
          if (checked) {
            checkedObject[optionNo] = checkedLabel;
          } else {
            checkedObject[optionNo] = undefined;
          }
          Object.assign(item.checked, checkedObject);
        } else if (row.characterClass === "2") {
          // ラジオボックスの時
          let checkedObject: any = {};
          checkedObject[optionNo] = row.options[Number(answer)];
          item.optionNo = optionNo;
          item.answer = answer;
          item.checked = checkedObject;
        } else {
          item.optionNo = optionNo;
          item.answer = answer;
        }
      }
    });
    setUpdata(!updata);
  };

  const checkInput = async () => {
    let validate = true;
    if (input.length > 0) {
      // eslint-disable-next-line array-callback-return
      input.map((item: any) => {
        item.error.is = false;
        item.error.message = "";
        if (item.isRequired !== "0" && item.characterClass === "3") {
          let empFlg = true;
          if (Object.keys(item.checked).length !== 0) {
            for (let row of Object.values(item.checked)) {
              if (row !== undefined) empFlg = false;
            }
          }
          if (empFlg === true) {
            validate = false;
            item.error.is = true;
            item.error.message = error_messages.optionRequire.replace(
              "name",
              item.question
            );
          }
        } else {
          if (item.isRequired !== "0" && item.answer === "") {
            validate = false;
            item.error.is = true;
            item.error.message =
              item.characterClass === "1"
                ? error_messages.textRequire.replace("name", item.question)
                : error_messages.optionRequire.replace("name", item.question);
          }
        }
      });
    }
    return validate;
  };

  const confirmClick = async () => {
    setLoading(true);
    const result = await checkInput();
    if (result) {
      rerender(undefined);
      localStorage.setItem("Cls014_answer", JSON.stringify(input));
      dispatch(
        push("/cls/015", {
          user_id: userId,
          class_id: classId,
          input: input,
          enquete_type: enqueteType,
        })
      );
    }
    setLoading(false);
  };

  type inputProps = {
    row: any;
    index: number;
  };

  // コンポーネントに分けると入力ごとにフォーカスが外れてしまう。解決方法がわかり次第こちらを使用する。（記載者:下家 記載日:20210915）
  // const TextAreaBox: React.FC<inputProps> = ({ row = {}, index = 0 }) => {
  //   return (
  //     <Grid className="App-mT10 App-mL10">
  //       <Box borderColor="grey.500" borderBottom={1}>
  //         <Grid className="App-mA20 ">
  //           <Grid item xs={12} className="App-pB10">
  //             <Typography component="p" variant="subtitle1">
  //               {row.question}
  //             </Typography>
  //           </Grid>

  //           <Grid item xs={12} className="App-pA20">
  //             <TextareaAutoSize
  //               value={Boolean(input[index]) ? input[index].answer : ""}
  //               onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
  //                 setInput({
  //                   ...input,
  //                   [index]: {
  //                     questionNo: row.questionNo, // 質問No
  //                     question: row.question, // 質問（内容）
  //                     optionNo: "",
  //                     answer: event.target.value,
  //                   },
  //                 });
  //                 // onChangeAnswer(event.target.value, "", row, index);
  //               }}
  //               rows={3}
  //             />
  //           </Grid>
  //         </Grid>
  //       </Box>
  //     </Grid>
  //   );
  // };

  const RadioBox: React.FC<inputProps> = ({ row = {}, index = 0 }) => {
    return (
      <Grid className="App-mT10 App-mL10">
        <Box borderColor="grey.500" borderBottom={1}>
          <Grid className="App-mA20 ">
            <Grid item xs={12} className="App-pB10">
              <Typography
                component="p"
                variant="subtitle1"
                className="App-text-no-width"
                style={{ whiteSpace: "pre-line" }}
              >
                {row.question}
                {row.isRequired === "1" ? (
                  <span className="required_box1">
                    {FormatMessage({ id: "Cls004.Label.Required" })}
                  </span>
                ) : null}
              </Typography>
            </Grid>
            <Grid item xs={12} className="App-pL20">
              <RadioGroup
                value={
                  updata
                    ? input?.find(
                        (element) => element.questionNo === row.questionNo
                      )?.answer
                    : input?.find(
                        (element) => element.questionNo === row.questionNo
                      )?.answer
                }
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  onChangeAnswer(
                    event.target.value,
                    String(Number(event.target.value) + 1),
                    row
                  )
                }
              >
                {Object.keys(row.options).map((val: string, index: number) => (
                  <div key={val} style={{ marginBottom: 12 }}>
                    {row.options[val] ? (
                      <FormControlLabel
                        key={val}
                        value={String(index)}
                        control={<Radio color="default" />}
                        label={
                          <Typography
                            className="App-text-no-width"
                            style={{ wordBreak: "break-all" }}
                          >
                            {row.options[val]}
                          </Typography>
                        }
                      />
                    ) : null}
                  </div>
                ))}
              </RadioGroup>
              <FormHelperText error>
                {input[index] && input[index].error && input[index].error.is
                  ? input[index].error.message
                  : ""}
              </FormHelperText>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    );
  };

  const CheckBox: React.FC<inputProps> = ({ row = {}, index = 0 }) => {
    return (
      <Grid className="App-mT10 App-mL10">
        <Box borderColor="grey.500" borderBottom={1}>
          <Grid className="App-mA20 ">
            <Grid item xs={12} className="App-pB10">
              <Typography
                component="p"
                variant="subtitle1"
                className="App-text-no-width"
                style={{ whiteSpace: "pre-line" }}
              >
                {row.question}
                {row.isRequired === "1" ? (
                  <span className="required_box1">
                    {FormatMessage({ id: "Cls004.Label.Required" })}
                  </span>
                ) : null}
              </Typography>
            </Grid>
            <Grid item xs={12} className="App-pL20">
              {Object.keys(row.options).map((val: string, index: number) => (
                <div key={val} style={{ marginBottom: 12 }}>
                  {row.options[val] ? (
                    <FormControlLabel
                      key={String(val)}
                      value={String(val)}
                      control={
                        <Checkbox
                          color="default"
                          checked={
                            input?.find(
                              (element) => element.questionNo === row.questionNo
                            )?.checked[String(index + 1)] !== undefined
                          }
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            onChangeAnswer(
                              "",
                              String(Number(event.target.value) + 1),
                              row,
                              event.target.checked,
                              row.options[val]
                            );
                          }}
                        />
                      }
                      label={
                        <Typography
                          className="App-text-no-width"
                          style={{ wordBreak: "break-all" }}
                        >
                          {row.options[val]}
                        </Typography>
                      }
                    />
                  ) : null}
                </div>
              ))}
              <FormHelperText error>
                {input[index] && input[index].error && input[index].error.is
                  ? input[index].error.message
                  : ""}
              </FormHelperText>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    );
  };

  const SelectBox: React.FC<inputProps> = ({ row = {}, index = 0 }) => {
    return (
      <Grid className="App-mT10 App-mL10">
        <Box borderColor="grey.500" borderBottom={1}>
          <Grid className="App-mA20 ">
            <Grid item xs={12} className="App-pB10">
              <Typography
                component="p"
                variant="subtitle1"
                className="App-text-no-width"
                style={{ whiteSpace: "pre-line" }}
              >
                {row.question}
                {row.isRequired === "1" ? (
                  <span className="required_box1">
                    {FormatMessage({ id: "Cls004.Label.Required" })}
                  </span>
                ) : null}
              </Typography>
            </Grid>
            <Grid item xs={12} className="App-pL20">
              <Select
                native
                value={
                  updata
                    ? String(
                        Number(
                          input?.find(
                            (element) => element.questionNo === row.questionNo
                          )?.optionNo
                        ) - 1
                      )
                    : String(
                        Number(
                          input?.find(
                            (element) => element.questionNo === row.questionNo
                          )?.optionNo
                        ) - 1
                      )
                }
                // defaultValue={row.}
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                  onChangeAnswer(
                    row.options[Number(event.target.value)],
                    String(Number(event.target.value) + 1),
                    row
                  );
                }}
              >
                <option hidden>選択してください</option>
                {SelectItems(row.options)}
              </Select>
              <FormHelperText error>
                {input[index] && input[index].error && input[index].error.is
                  ? input[index].error.message
                  : ""}
              </FormHelperText>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    );
  };

  function SelectItems(items: any) {
    return [
      Object.keys(items).map((val: string) =>
        items[val] ? (
          <option
            key={String(val)}
            value={String(val)}
            style={{ wordBreak: "break-all" }}
            // id={items[val]}
          >
            {items[val]}
          </option>
        ) : null
      ),
    ];
  }

  return (
    <MainLayout>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className="App-paper">
            <Typography
              component="h2"
              variant="h6"
              color="inherit"
              gutterBottom
            >
              {className} {FormatMessage({ id: "Cls014.Label.text1" })}
            </Typography>
            <Grid className="App-mL20 App-text-no-width">{beforeAnswer}</Grid>
            {rows.length > 0
              ? rows.map((row, index: number) => (
                  <div key={row.questionNo}>
                    {row.characterClass === "1" ? (
                      <Grid className="App-mT10 App-mL10">
                        <Box borderColor="grey.500" borderBottom={1}>
                          <Grid className="App-mA20 ">
                            <Grid item xs={12} className="App-pB10">
                              <Typography
                                component="p"
                                variant="subtitle1"
                                className="App-text-no-width"
                                style={{ whiteSpace: "pre-line" }}
                              >
                                {row.question}
                                {row.isRequired === "1" ? (
                                  <span className="required_box1">
                                    {FormatMessage({
                                      id: "Cls004.Label.Required",
                                    })}
                                  </span>
                                ) : null}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} className="App-pA20">
                              <TextareaAutoSize
                                defaultValue={
                                  Boolean(input[index])
                                    ? input[index].answer
                                    : ""
                                }
                                style={{ width: "-webkit-fill-available" }}
                                // value={Boolean(input[index]) ? input[index].answer : ""}
                                onBlur={(
                                  event: React.ChangeEvent<HTMLTextAreaElement>
                                ) => {
                                  onChangeAnswer(event.target.value, "0", row);
                                }}
                                rows={3}
                                maxLength="4000"
                              />
                              <FormHelperText error>
                                {input[index] &&
                                input[index].error &&
                                input[index].error.is
                                  ? input[index].error.message
                                  : ""}
                              </FormHelperText>
                              {/* {(input[index] && input[index].requiredError) ?? (
                                <FormHelperText error>
                                  {error_messages.textRequire.replace(
                                    "{name}",
                                    row.question
                                  )}
                                </FormHelperText>
                              )} */}
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    ) : null}
                    {row.characterClass === "2" ? (
                      <RadioBox row={row} index={index} />
                    ) : null}
                    {row.characterClass === "3" ? (
                      <CheckBox row={row} index={index} />
                    ) : null}
                    {row.characterClass === "4" ? (
                      <SelectBox row={row} index={index} />
                    ) : null}
                  </div>
                ))
              : null}
            <Grid container spacing={1} className="App-List">
              <Grid item xs={12} className="App-alignCenter">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={confirmClick}
                >
                  {FormatMessage({ id: "Cls014.Button.confirm" })}
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {loading ? <LoadingBox /> : null}
      </Grid>
    </MainLayout>
  );
};
