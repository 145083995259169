import { apigw } from "../awsApi";
import { Dispatch } from "@reduxjs/toolkit";

//サンプルデータ
const rows = [
    {
      board_document_id :1,
      board_document_name :"image1",
      board_s3_id : "https://1.bp.blogspot.com/-eAS3kj-J4tI/X9dK0afb-iI/AAAAAAABc0s/XwtKOj7Wn90iyRtsA1qKHCH5Nowjp75owCNcBGAsYHQ/s180-c/thumbnail_office_chair_business.jpg.",
      board_document_type : "1"
    },
    {
      board_document_id :2,
      board_document_name :"image2",
      board_s3_id : "https://1.bp.blogspot.com/-ALx_6IgToeY/X9GYSwKwpQI/AAAAAAABcvg/ae0wuJNrMyQUBR0WnlxQiUTBVGZENqD9gCNcBGAsYHQ/s400/office_chair_woman.png",
      board_document_type : "1"
    },
    {
      board_document_id :3,
      board_document_name :"image3",
      board_s3_id : "https://1.bp.blogspot.com/-SBsC6grX7g4/X5OcNqn3cWI/AAAAAAABb60/d4TNwPb1AQAmep79hNzG4Tsde8zQizZ9gCNcBGAsYHQ/s400/computer_sagyouin_man.png",
      board_document_type : "1"
    },
    {
      board_document_id :4,
      board_document_name :"image4",
      board_s3_id : "https://1.bp.blogspot.com/-GwwfOlEDB2g/X5OcNzc4tKI/AAAAAAABb64/HEKmvNOpKAoFpujvKKt1EOkiyd6ZteI9wCNcBGAsYHQ/s400/computer_sagyouin_woman.png",
      board_document_type : "1"
    },
    {
      board_document_id :5,
      board_document_name :"image5",
      board_s3_id : "https://1.bp.blogspot.com/-mMypAkOae-c/X7zMgo_E6MI/AAAAAAABccg/D76spoUGL2QD9w100gdIF9xuKkVufZuogCNcBGAsYHQ/s400/sagyouin_smartphone_talk_man.png",
      board_document_type : "1"
    },
    {
      board_document_id :6,
      board_document_name :"image6",
      board_s3_id : "https://1.bp.blogspot.com/-qe75GaBimGY/X7zMhOLdvRI/AAAAAAABcco/zivc3i5yFwoEdIIlvzUo8kTHAFuwVcrfQCNcBGAsYHQ/s400/sagyouin_smartphone_talk_woman.png",
      board_document_type : "1"
    },
  ];
  
/**
 * コンテンツ一覧取得
 * @param {Dispatch} dispatch reducerディスパッチ
 * @param {string} class_id 授業ID
 * @return コンポーネントHTMLコード内容
 */
export const CT0307 = async (
  dispatch: Dispatch,
  class_id: string
) => {
  if (process.env.REACT_APP_API_USE_FLG === "true") {
    return apigw(
      "/CT0307",
      {
      class_id:class_id

      },
      dispatch
    );
  } else {
    //サンプルデータ用
    return { body: rows };
  }
};