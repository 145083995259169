import { apigw } from "../awsApi";
import { Dispatch } from "@reduxjs/toolkit";

/**
 * 択一問題取得
 * @param {Dispatch} dispatch reducerディスパッチ
 * @param {string} classId 授業ID
 * @param {string} choiceId 択一問題ID
 * @return コンポーネントHTMLコード内容
 */
export const QZ0304 = async (
  dispatch: Dispatch,
  classId: string,
  choiceId: string
) => {
  if (process.env.REACT_APP_API_USE_FLG === "true") {
    return apigw(
      "/QZ0304",
      {
        class_id: classId,
        choice_id: Number(choiceId),
      },
      dispatch
    );
  } else {
    //サンプルデータ用

    return {
      state: "0",
      err_code: "",
      body: {
        answer_1: "aaa",
        answer_2: "bbb",
        answer_3: "ccc",
        answer_4: "ddd",
        answer_5: "eee",
        answer_6: "fff",
        answer_7: "ggg",
        answer_8: "hhh",
        answer_9: "iii",
        answer_10: "jjj",
      },
    };
  }
};
