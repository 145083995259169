/* eslint-disable jsx-a11y/alt-text */
import React, { useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { push } from "connected-react-router";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import PlayArrow from "@material-ui/icons/PlayArrow";
import Description from "@material-ui/icons/Description";
import DesktopWindowsIcon from "@material-ui/icons/DesktopWindows";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";
import { saveAs } from "file-saver";
import { RootState, store } from "../../reducks/store";
import { addNotification } from "../../reducks/notificationSlice";
import { MainLayout } from "../../layouts/MainLayout";
import { FormatMessage } from "../../components/atoms/FormatMessage";
import { LoadingBox } from "../../components/atoms/LoadingBox";

import { ShowClassImage } from "./ShowClassImage";

import {
  createS3,
  getFileS3,
  getFileBase64S3,
} from "../../components/function/AwsS3Fun";
import { unescapeStr } from "../../components/function/Function";

// import { getSessionUser } from "../../utils/authFlow";
import { LC0301 } from "../../utils/api/LC0301";
import { TG0301 } from "../../utils/api/TG0301";
import { ST0326 } from "../../utils/api/ST0326";
import { LC0308 } from "../../utils/api/LC0308";
import { CT0302 } from "../../utils/api/CT0302";
import { LC0307 } from "../../utils/api/LC0307";
//import { CF0901 } from "../../utils/api/CF0901";
import { Theme, withStyles, createStyles } from "@material-ui/core/styles";
import Badge from "@material-ui/core/Badge";
import { CF0306 } from "../../utils/api/CF0306";
import { US0304 } from "../../utils/api/US0304";
import { CF0305 } from "../../utils/api/CF0305";
import sanitize from "sanitize-html";
import { LC0305 } from "../../utils/api/LC0305";
import { signOut } from "../../reducks/userSlice";

import { KY0301 } from "../../utils/api/KY0301";
import { sessionHandlerForKeywordSearch as sessionHandler } from "../../utils/sessionHandlerForKeywordSearch";
import {
  setKeywordListsOnStore,
  setOpenSwitchKeywordSearchDialog,
  setErrorMessageOnKeywordSearch,
  setStateKeywordSearchProc,
  setTrueGetKeywordListsFlg,
  setFalseGetKeywordListsFlg,
} from "../../reducks/storedKeywordSeachConditionSlice";

const StyledBadge = withStyles((theme: Theme) =>
  createStyles({
    badge: {
      backgroundColor: "#f44336",
      color: "#f44336",
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "$ripple 1.2s infinite ease-in-out",
        border: "1px solid currentColor",
        content: '""',
      },
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  })
)(Badge);

let intervalId: any;
let downloadItem: any = {};
let S3Api: any;
let getS3Api: any;
let clickFlg: any;
let allRows: any;
let nowRows: any;
let nowR: any;
let elm: any;
//let subwin: any;
/**
 * 視聴ユーザトップ
 * @param {object} props 引き継いだオブジェクト
 * @return コンポーネントHTMLコード内容
 */
export const Top002 = (props: any) => {
  const dispatch = useDispatch();
  const serviceId = store.getState().user.service_id;

  //loadingフラグ
  const [loading, setLoading] = React.useState(true);
  const [downloadList, setDownloadList] = React.useState<any>({});
  type itemType = {
    tag_id: string; //タグID,
    class_id: string; //授業ID
    class_name: string; //授業名
    s3_id: string; //授業写真
    plan_date: string; //授業予定日時
    explain_text: string; //説明文
    is_font_red: string; //フォント設定(赤字)
    is_font_bold: string; //フォント設定(太字)
    sort: number; //並び順
    status_class: string; //ステータス
    channel_id: string; //チャンネルID agora接続時の入室キー
    stream_class: string; //授業区分 1:ライブ配信　2:収録
    open_class: string; //授業区分(公開設定) 1:公開　2:非公開
    existing_use_class: string; //授業区分(既存動画) 1:既存動画を利用しない　2:既存動画を利用する
    is_filmed: string; //録画済みフラグ 0:未録画　1:録画済
    is_watched: boolean; //未視聴
  };

  //配信予定データ
  const [rows, setRows] = React.useState<itemType[]>([]);
  // const nowR: any = useRef(null);
  // nowR.current = rows;

  //授業一覧データ
  const [crows, setCrows] = React.useState<itemType[]>([]);
  // const elm: any = useRef(null);
  // elm.current = crows;

  // ダイアログの制御
  const [openDialog, setOpenDialog] = React.useState(false);

  // 非公開授業の視聴可否
  const [closedVideoIsValid, setClosedVideoIsValid] = React.useState("");

  const getDateFormat = (dt: Date) => {
    let y = dt.getFullYear();
    let m = ("00" + (dt.getMonth() + 1)).slice(-2);
    let d = ("00" + dt.getDate()).slice(-2);
    let h = ("00" + dt.getHours()).slice(-2);
    let i = ("00" + dt.getMinutes()).slice(-2);
    return y + "/" + m + "/" + d + " " + h + ":" + i;
  };

  const getDateTime = (date: any) => {
    let ct = new Date(date).getTime();
    let nt = new Date(ct - 9 * 60 * 60 * 1000);
    return (
      nt.getFullYear() +
      "/" +
      ("00" + (nt.getMonth() + 1)).slice(-2) +
      "/" +
      ("00" + nt.getDate()).slice(-2) +
      " " +
      ("00" + nt.getHours()).slice(-2) +
      ":" +
      ("00" + nt.getMinutes()).slice(-2)
    );
  };

  const getDownloadData = async (data: any) => {
    if (data && data.length > 0) {
      let classIds: any[] = [];
      for (let index = 0; index < data.length; index++) {
        if (!downloadItem[data[index].class_id]) {
          classIds.push(data[index].class_id);
        }
      }
      let result = await CT0302(dispatch, classIds);
      if (
        result &&
        result.state === "0" &&
        result.body &&
        result.body.length > 0
      ) {
        for (let index = 0; index < result.body.length; index++) {
          const element = result.body[index];
          if (!downloadItem[element.class_id])
            downloadItem[element.class_id] = [];
          downloadItem[element.class_id].push(element);
        }
      }

      setDownloadList(downloadItem);
    }
  };

  // キーワード検索絞り込み再実行用監視キーを読み込み（useEffectのトリガーとして使用）
  const refreshKeyByKeywordSearchProc: number = useSelector(
    (state: RootState) => state.stateKeywordSearchProc.refreshKey
  );
  // キーワード検索絞り込み再実行時に既存授業一覧をクリアする必要があるか否か（ダイアログのボタン「検索」が押された際にtrueとなる）。
  const stateFlagResetCurrentRows: boolean = useSelector(
    (state: RootState) => state.stateKeywordSearchProc.stateFlagResetCurrentRows
  );
  // 定義済みキーワード一覧、および選択済みキーワード一覧を取得する。
  // const keywordListsOnStore: any = useSelector(
  //   (state: RootState) => state.keywordLists
  // );

  // const getKeywordListsFlg: boolean = useSelector(
  //   (state: RootState) => state.getKeywordListsFlg
  // );
  // キーワード一覧を取得する。
  // const getKeywordLists = async () => {
  //   // すでに取得済みの場合はスキップする
  //   if (keywordListsOnStore.length === 0 || getKeywordListsFlg) {
  //     // 定義済みキーワード一覧をAPIを使って取得する
  //     const responceKY0301 = await KY0301(dispatch);
  //     dispatch(setKeywordListsOnStore(responceKY0301.body));
  //     setFalseGetKeywordListsFlg(false);
  //   }
  // };
  // 検索結果0件時のエラーメッセージを定義
  const errorMessageNoResult = FormatMessage({
    id: "Top002.KeywordSearch.NoDataError",
  });

  //データ処理
  const getListData = async (callback: any) => {
    // const userData = await getSessionUser();
    const userData = store.getState().user;

    //非公開授業視聴可否
    const userAuthority = await US0304(dispatch);
    if (
      userAuthority &&
      userAuthority.body &&
      userAuthority.body.closed_video_is_valid
    ) {
      setClosedVideoIsValid(userAuthority.body.closed_video_is_valid);
    } else if (userAuthority === "01001") {
      dispatch(signOut());
    }
    // キーワード検索ダイアログのボタン「検索」が押された場合を検知
    if (stateFlagResetCurrentRows === true) {
      // 既存授業一覧を保持しているstateをクリアする
      if (callback != null) {
        setRows([]);
        setCrows([]);
        nowR = [];
        elm = [];
        nowRows = [];
      }
      // stateFlagResetCurrentRowsをfalseに戻す
      await dispatch(
        setStateKeywordSearchProc({ stateFlagResetCurrentRows: false })
      );
    }
    // 事前にキーワード一覧を取得する（すでに取得済みの場合はスキップする）
    // await getKeywordLists();
    let getData = await LC0308(
      dispatch,
      userData.user_class,
      false,
      sessionHandler.getSearchText(), // 「授業名/説明文から探す」　undefined可
      sessionHandler.getKeywords(), // 選択キーワード一覧配列　undefined可
      sessionHandler.getUnwatchedOndemandFlag(), // 「未視聴オンデマンドのみ」undefined可
      sessionHandler.getPlanDateFrom(), // 「配信日(From)」　undefined可
      sessionHandler.getPlanDateTo() // 「配信日(From)」　undefined可
    );
    //6時間前から翌日の24時までの授業をチェック
    let nt = new Date().getTime();
    let d1 = getDateFormat(new Date(nt - 6 * 60 * 60 * 1000)); //6時間前
    var tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    let ty = tomorrow.getFullYear();
    let tm = ("00" + (tomorrow.getMonth() + 1)).slice(-2);
    let td = ("00" + tomorrow.getDate()).slice(-2);
    let tt = ("00" + tomorrow.getHours()).slice(-2);
    let tM = ("00" + tomorrow.getMinutes()).slice(-2);
    let d2 = ty + "/" + tm + "/" + td + " " + tt + ":" + tM; //翌日の24時
    if (getData && getData.state === "0" && getData.body.length > 0) {
      getDownloadData(getData.body);
      let listDate: any[] = getData.body;
      listDate.sort(function (a, b) {
        if (a.sort > b.sort) return 1;
        if (a.sort < b.sort) return -1;
        return 0;
      });

      let class_ids = [];
      for (let index = 0; index < listDate.length; index++) {
        class_ids.push(listDate[index].class_id);
      }

      let checkLookData: any = {};
      let getDataLC = await LC0307(dispatch, class_ids);
      if (getDataLC && getDataLC.state === "0") {
        if (getDataLC.body.length > 0) {
          for (let index = 0; index < getDataLC.body.length; index++) {
            const element = getDataLC.body[index];
            checkLookData[element.class_id] = element.viewing_time_ondemand;
          }
        }
      }
      let newData: any[] = [];
      for (let index = 0; index < listDate.length; index++) {
        const element = listDate[index];
        element.is_watched = false;
        if (
          checkLookData[element.class_id] &&
          Number(checkLookData[element.class_id]) > 0
        ) {
          element.is_watched = true;
        }
        newData.push(element);
      }

      // 授業一覧全体から授業の重複をフィルターしてセット
      let newTimeData: any[] = [];
      if (newData.length > 0) {
        let duplicatedNewDataIndex: any = [];
        newData.reduce((prev: any, current: any, index) => {
          if (prev[current["class_id"]]) {
            duplicatedNewDataIndex = [...duplicatedNewDataIndex, index];
          } else {
            prev[current["class_id"]] = current;
          }
          return prev;
        }, {});
        duplicatedNewDataIndex.forEach(
          (deleteIndex: number, deletedCount: number) => {
            newData.splice(deleteIndex - deletedCount, 1);
          }
        );
        //日付変換
        newData.forEach((data: any) => {
          data.plan_date = getDateTime(data.plan_date);
          newTimeData.push(data);
        });
      }

      let nowDate: any[] = [];
      newTimeData.forEach((data: any) => {
        if (data.plan_date >= d1 && data.plan_date <= d2) {
          nowDate.push(data);
        }
      });
      if (nowDate.length > 0) {
        // if (nowR.length > 0) {
        //   if (nowRows && nowRows.length === 0) {
        //     setRows(nowDate);
        //     nowR = nowDate;
        //   } else {
        //     nowRows = nowDate.slice(nowR.length);
        //     let row = nowDate.slice(0, nowR.length);
        //     setRows(row);
        //     nowR = row;
        //   }
        // } else {
        //   nowRows = nowDate.slice(20);
        //   let row = nowDate.slice(0, 20);
        //   setRows(row);
        //   nowR = row;
        // }
        nowRows = nowDate.slice(20);
        let row = nowDate.slice(0, 20);
        setRows(row);
        nowR = row;
      } else {
        setRows([]);
        nowR = [];
      }
      if ((nowRows && nowRows.length === 0) || nowDate.length === 0) {
        // if (elm.length > 0) {
        //   allRows = newTimeData.slice(elm.length);
        //   let row = newTimeData.slice(0, elm.length);
        //   setCrows(row);
        //   elm = row;
        // } else {
        //   let limit = 20 - nowR.length;
        //   if (limit > 0) {
        //     allRows = newTimeData.slice(limit);
        //     let row = newTimeData.slice(0, limit);
        //     setCrows(row);
        //     elm = row;
        //   }
        // }
        let limit = 20 - nowR.length;
        if (limit > 0) {
          allRows = newTimeData.slice(limit);
          let row = newTimeData.slice(0, limit);
          setCrows(row);
          elm = row;
        }
      } else {
        allRows = newTimeData;
        setCrows([]);
      }
    }

    // 授業表示件数をチェック（※表示件数が0件だった場合ダイアログを表示するため）
    if (
      getData &&
      typeof getData === "object" &&
      getData.hasOwnProperty("body") === true
    ) {
      if (
        // LC0308の検索結果が0件だった場合
        // ※LC0308は検索結果0件だった場合 body: {} と返却される
        Array.isArray(getData.body) === false &&
        Object.keys(getData.body).length === 0 //||
        // 表示件数が0件だった場合
        // (Array.isArray(elm.current) === true && elm.current.length === 0)
      ) {
        // 検索結果件数が0件だった場合
        setRows([]); // 授業一覧の表示をリセットする。
        setCrows([]); // 授業一覧の表示をリセットする。
        getData.body = []; // LC0308のresponceをリセットする。
        // キーワード検索ダイアログにエラーメッセージ「検索条件にあてはまる授業が見つかりませんでした。」を表示する。
        dispatch(setErrorMessageOnKeywordSearch(errorMessageNoResult));
        // キーワード検索ダイアログを再表示する（エラーメッセージ表示のため）。
        dispatch(setOpenSwitchKeywordSearchDialog(true));
      } else {
        // キーワード検索ダイアログにエラーメッセージを消去する。
        dispatch(setErrorMessageOnKeywordSearch(""));
      }
    }

    if (callback) callback();
  };

  /*const subWindowclose = () => {
    localStorage.setItem("ClassClose", "1");
    localStorage.removeItem("playclass");
  };*/

  //const PostErr = FormatMessage({ id: "Error.Message.PostErr" });
  const NumberErr = FormatMessage({ id: "Top002.Check.ErrorText" });
  const TimeErr = FormatMessage({ id: "Top002.Check.Error" });
  const StatusErr = FormatMessage({ id: "Cls012.Err.Text1" });
  //const StatusErr2 = FormatMessage({ id: "Top002.Check.ErrorText2" });
  const liveCilck = async (prop: any) => {
    //授業ステータスチェック
    if (clickFlg) return;
    clickFlg = true;
    const checkClassStatus = await LC0305(dispatch, prop);
    if (
      checkClassStatus &&
      checkClassStatus.state === "0" &&
      checkClassStatus.body
    ) {
      if (checkClassStatus.body.status_class) {
        if (
          checkClassStatus.body.status_class === "3" ||
          checkClassStatus.body.status_class === "4"
        ) {
          //配信視聴時間チェック
          const checkLiveStatus = await CF0305(dispatch);
          if (
            checkLiveStatus &&
            Object.keys(checkLiveStatus).indexOf("body") >= 0
          ) {
            if (checkLiveStatus.body && checkLiveStatus.body.length > 0) {
              if (
                checkLiveStatus.body[0].status_stream_time <
                  checkLiveStatus.body[0].status_stream_time_limit ||
                checkLiveStatus.body[0].stream_time_excess === "1"
              ) {
                //同時接続数チェック
                const checkLiveNumber = await CF0306(dispatch);
                if (
                  checkLiveNumber &&
                  Object.keys(checkLiveNumber).indexOf("body") >= 0
                ) {
                  if (checkLiveNumber.body) {
                    if (checkLiveNumber.body.concurrent_connection_result) {
                      clearInterval(intervalId);
                      console.log(
                        "同時接続数チェックOK",
                        "checkLiveNumber:",
                        checkLiveNumber
                      );
                      dispatch(push("/cls/012", { class_id: sanitize(prop) }));
                    } else {
                      alert(NumberErr);
                    }
                  } else {
                    console.log(
                      "[checkLiveNumber.body_NOTHING_ERR]",
                      "checkLiveNumber:",
                      checkLiveNumber
                    );
                  }
                } else {
                  console.log(
                    "[checkLiveNumber_NOTHING_ERR]",
                    "checkLiveNumber:",
                    checkLiveNumber
                  );
                }
              } else {
                //subWindowclose();
                alert(TimeErr);
                //配信視聴時間エラーメッセージ表示
                /*dispatch(
                  addNotification({
                    message: TimeErr,
                    variant: "error",
                  })
                );*/
              }
            }
          }
        } else {
          //subWindowclose();
          alert(StatusErr);
          //配信視聴時間エラーメッセージ表示
          /*dispatch(
            addNotification({
              message: StatusErr,
              variant: "error",
            })
          );*/
        }
      }
      setTimeout(() => {
        clickFlg = false;
      }, 2000);
    } else if (checkClassStatus === "01001") {
      clickFlg = false;
      dispatch(signOut());
    }
  };

  /*const liveOpen = (prop: any) => {
    var playclass = localStorage.getItem("playclass");

    if (!playclass || playclass === "") {
      liveCilck(prop);
      localStorage.setItem("playclass", prop);
      subwin = window.open("/cls/012", prop, "noreferrer");
      //subwin.on("close", subWindowclose);
    } else {
      alert(StatusErr2);
    }
  };*/

  /*const videoOpen = (classId: string, existingUseClass: string) => {
    var playclass = JSON.parse(String(localStorage.getItem("playclass")));

    if (!playclass) {
      videoCilck(classId, existingUseClass);
      const classData = JSON.stringify({
        classId: classId,
        existingUseClass: existingUseClass,
      });
      localStorage.setItem("playclass", classData);
      subwin = window.open("/cls/013", classId, "noreferrer");
      //subwin.on("close", subWindowclose);
    } else {
      alert(StatusErr2);
    }
  };*/

  const videoCilck = async (classId: string, existingUseClass: string) => {
    //配信視聴時間チェック (CF0305権限付与後)
    if (clickFlg) return;
    clickFlg = true;
    const checkLiveStatus = await CF0305(dispatch);
    if (checkLiveStatus && Object.keys(checkLiveStatus).indexOf("body") >= 0) {
      if (checkLiveStatus.body && checkLiveStatus.body.length > 0) {
        if (
          checkLiveStatus.body[0].status_stream_time <
            checkLiveStatus.body[0].status_stream_time_limit ||
          checkLiveStatus.body[0].stream_time_excess === "1"
        ) {
          /* //同時接続数チェック
          const checkLiveNumber = await CF0306(dispatch);
          if (
            checkLiveNumber &&
            checkLiveNumber.body &&
            checkLiveNumber.body.concurrent_connection_result &&
            checkLiveNumber.body.concurrent_connection_result === true
          ) {*/
          clearInterval(intervalId);
          dispatch(
            push("/cls/013", {
              classId: classId,
              existingUseClass: existingUseClass,
            })
          );
          /* //(CF0305権限付与後)
          } else {
            //エラーメッセージ表示
            subWindowclose();
            alert(NumberErr);
            /*dispatch(
              addNotification({
                message: NumberErr,
                variant: "error",
              })
            );
          }*/
        } else {
          //配信視聴時間エラーメッセージ表示
          //subWindowclose();
          alert(TimeErr);
          /*dispatch(
            addNotification({
              message: TimeErr,
              variant: "error",
            })
          );*/
        }
      }
      setTimeout(() => {
        clickFlg = false;
      }, 2000);
    } else if (checkLiveStatus === "01001") {
      clickFlg = false;
      dispatch(signOut());
    }
  };

  type DownloadFile = {
    download_document_id: string;
    download_document_name: string;
    s3_id: string;
  };

  //資料ダウンロードデータ
  const [dialogTitle, setDialogTitle] = React.useState("");
  const [downloadFile, setDownloadFile] = React.useState<DownloadFile[]>();
  const [open, setOpen] = React.useState(false);
  const [checkBoolean, setCheckBoolean] = React.useState<boolean[]>([]);
  const [isChecked, setIsChecked] = React.useState(false);
  const [isPhone, setIsPhone] = React.useState(false);

  const handleClickOpen = async (classData: any) => {
    setLoading(true);
    setDialogTitle(classData.class_name);
    const S3 = await createS3();
    const userData = store.getState().user;
    const path =
      "haishin/" + userData.service_id + "/" + classData.class_id + "/doc";
    let orderList: any = {};
    if (S3) {
      let file: any = await getFileS3(S3, path + "/order.json");
      if (file && file.size > 0) {
        let reader = new FileReader();
        reader.readAsText(file);
        reader.onload = function () {
          if (reader.result) {
            orderList = JSON.parse(String(reader.result));
          }
        };
      }
    }
    let result = await CT0302(dispatch, [classData.class_id]);
    if (
      result &&
      result.state === "0" &&
      result.body &&
      result.body.length > 0
    ) {
      let fileList = result.body;
      var newFlg = [];
      for (let index = 0; index < fileList.length; index++) {
        newFlg[index] = false;
      }
      setCheckBoolean(newFlg);
      if (orderList && orderList.pdfOrder) {
        let List = fileList;
        let newList = [];
        for (let a = 0; a < orderList.pdfOrder.length; a++) {
          const id = orderList.pdfOrder[a];
          for (let i = 0; i < fileList.length; i++) {
            const item = fileList[i];
            if (id === item.download_document_id) {
              List.splice(i, 1);
              newList.push(item);
              break;
            }
          }
        }
        if (List.length > 0) {
          for (let index = 0; index < List.length; index++) {
            newList.push(List[index]);
          }
        }
        setDownloadFile(newList);
      } else {
        setDownloadFile(fileList);
      }
    } else if (result === "01001") {
      dispatch(signOut());
    }
    setLoading(false);
    setOpen(true);
  };

  const handleClose = () => {
    changeAllcheck(false);
    setOpen(false);
    setIsChecked(false);
  };

  const clickDownload = () => {
    if (downloadFile && downloadFile.length > 0) {
      for (let index = 0; index < downloadFile.length; index++) {
        const element = downloadFile[index];
        if (checkBoolean[index]) {
          handleDownload(element.download_document_name, element.s3_id);
        }
      }
      setIsChecked(false);
      changeAllcheck(false);
      setOpen(false);
    }
  };

  const checkChangeHandle = (event: any, index: number) => {
    if (downloadFile && downloadFile.length > 0) {
      checkBoolean[index] = event.target.checked;
      let checkCnt = 0;
      var newFlg = [];
      for (let ai = 0; ai < downloadFile.length; ai++) {
        if (ai === index) {
          newFlg[ai] = event.target.checked;
        } else {
          newFlg[ai] = checkBoolean[ai];
        }
        if (newFlg[ai]) {
          checkCnt++;
        }
      }
      setCheckBoolean(newFlg);
      if (isChecked) {
        if (downloadFile.length !== checkCnt) {
          setIsChecked(false);
        }
      } else {
        if (downloadFile.length === checkCnt) {
          setIsChecked(true);
        }
      }
    }
  };

  const linkClick = (index: number) => {
    for (let i = 0; i < checkBoolean.length; i++) {
      if (i === index) {
        checkBoolean[i] = true;
      } else {
        checkBoolean[i] = false;
      }
    }
    clickDownload();
  };

  const handleDownload = async (name: string, url: string) => {
    const S3 = await createS3();
    if (S3) {
      let file: any = await getFileS3(S3, url);
      if (file) {
        saveAs(file, name);
      } else {
        setOpenDialog(true);
      }
    }
  };

  const allSeleted = (event: any) => {
    changeAllcheck(event.target.checked);
    if (isChecked) {
      setIsChecked(false);
    } else {
      setIsChecked(true);
    }
  };

  const changeAllcheck = (flg: boolean) => {
    if (downloadFile && downloadFile.length > 0) {
      var newFlg = [];
      for (let index = 0; index < downloadFile.length; index++) {
        newFlg[index] = flg;
      }
      setCheckBoolean(newFlg);
    }
  };
  const getS3Token = async () => {
    createS3().then((s3: any) => {
      S3Api = s3;
      getS3Api = setTimeout(getS3Token, 600000);
    });
  };

  //初期処理
  useEffect(() => {
    downloadItem = {};
    clickFlg = false;
    nowR = [];
    elm = [];
    /*subwin = localStorage.getItem("subWindow")
      ? localStorage.getItem("subWindow")
      : undefined;*/
    setLoading(true);
    ST0326().then((data: any) => {
      createS3().then((s3: any) => {
        S3Api = s3;
        getListData(() => {
          setLoading(false);
          getS3Token();
        });
      });
    });

    var ua = navigator.userAgent;
    if (ua.indexOf("iPhone") > 0 || ua.indexOf("iPad") > 0) {
      setIsPhone(true);
    } else if (
      navigator.userAgent.includes("Macintosh") &&
      "ontouchend" in document
    ) {
      setIsPhone(true);
    }

    //配信予定授業一覧と授業一覧のリロードをする
    intervalId = setInterval(function () {
      if (store.getState().router.location.pathname === "/top/002") {
        sessionStorage.removeItem("fristImgList");
        getListData(null);
      } else {
        clearInterval(intervalId);
      }
    }, 60000);

    return function () {
      clearInterval(intervalId);
      if (getS3Api) {
        clearTimeout(getS3Api);
        getS3Api = undefined;
      }
    };
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [refreshKeyByKeywordSearchProc]); // キーワード検索条件が入力された際に変更されるstore定義をトリガーとする

  const dialogOk = () => {
    setOpenDialog(false);
  };

  const title = FormatMessage({ id: "Top002.Label.text1" });
  const Tooltip1 = FormatMessage({ id: "Top002.Tooltip.text1" });
  const Tooltip2 = FormatMessage({ id: "Top002.Tooltip.text2" });
  const Tooltip3 = FormatMessage({ id: "Top002.Tooltip.text3" });

  useEffect(() => {
    var box: any = document.getElementsByTagName("main");
    if (box && box.length > 0) {
      box[0].onscroll = function () {
        var scroll = box[0].scrollTop;
        var range = box[0].scrollHeight - box[0].offsetHeight;
        if (
          Math.floor(range) - Math.ceil(scroll) < 10 &&
          (allRows.length > 0 || (nowRows && nowRows.length > 0))
        ) {
          if ((nowRows && nowRows.length === 0) || !nowRows) {
            let list = allRows.slice(0, 20);
            setCrows([...elm, ...list]);
            allRows = allRows.slice(20);
            elm = elm.concat(list);
          } else {
            let list = nowRows.slice(0, 20);
            setRows([...nowR, ...list]);
            nowRows = nowRows.slice(20);
            nowR = nowR.concat(list);
          }
        }
      };
    }
  }, []);

  // useEffect(() => {
  //   if (getKeywordListsFlg) getKeywordLists();
  // }, [getKeywordListsFlg]);

  return (
    <MainLayout>
      <Grid item xs={12}>
        <Paper className={rows.length > 0 ? "App-paper" : "App-NoDisplay"}>
          <Typography variant="h6" style={{ borderBottom: "1px solid #ccc" }}>
            {title}
          </Typography>
          {rows.map(
            (item, index) => (
              //item.stream_class === "2" && item.is_filmed === "0" ? null : (
              <Grid
                container
                spacing={2}
                className="App-List"
                key={"firitem_" + index}
                style={
                  index + 1 !== rows.length
                    ? { borderBottom: "1px solid #ccc" }
                    : {}
                }
              >
                <Grid container style={{ padding: "10px" }}>
                  <Grid
                    item
                    xs
                    style={{
                      minWidth: "145px",
                      maxWidth: "145px",
                    }}
                  >
                    <ShowClassImage
                      S3={S3Api}
                      serviceId={serviceId}
                      classId={item.class_id}
                    />
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    <Typography
                      gutterBottom
                      variant="h6"
                      style={{ wordBreak: "break-all" }}
                    >
                      {unescapeStr(item.class_name)}
                    </Typography>
                    <Typography
                      variant="body2"
                      gutterBottom
                      style={
                        item.is_font_red === "1" && item.is_font_bold === "1"
                          ? {
                              color: "red",
                              fontWeight: "bold",
                            }
                          : item.is_font_red === "1"
                          ? {
                              color: "red",
                            }
                          : item.is_font_bold === "1"
                          ? {
                              fontWeight: "bold",
                            }
                          : {}
                      }
                    >
                      <div
                        className="App-text-no-width"
                        style={{ whiteSpace: "pre-line" }}
                      >
                        {unescapeStr(item.explain_text)}
                      </div>
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs
                    style={{
                      minWidth: "168px",
                      display: "flex",
                      alignItems: "flex-end",
                      justifyContent: "flex-end",
                    }}
                  >
                    <div>
                      {(item.status_class === "3" ||
                        item.status_class === "4") &&
                      item.stream_class === "1" ? (
                        <Grid
                          item
                          xs={12}
                          className="App-alignRight  App-pLR10"
                        >
                          <img src="/img/3ue--Live.png" height="24" />
                        </Grid>
                      ) : null}

                      <Grid item xs={12} className="App-alignRight App-pLR10">
                        <Typography variant="body1" color="textSecondary">
                          {item.plan_date}
                        </Typography>
                      </Grid>
                      <Grid
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="center"
                      >
                        <Grid
                          item
                          xs
                          className="App-alignCenter App-pLR10"
                          style={{ width: "84px" }}
                        >
                          {item.stream_class === "1" &&
                          item.status_class !== "5" ? (
                            <Tooltip title={Tooltip1} aria-label="live">
                              <Button
                                variant="contained"
                                color="primary"
                                className="App-button_bottom"
                                onClick={() => {
                                  liveCilck(item.class_id);
                                }}
                              >
                                <PlayArrow />
                              </Button>
                            </Tooltip>
                          ) : item.is_filmed === "1" &&
                            (item.open_class === "1" ||
                              closedVideoIsValid === "1") ? (
                            item.is_watched === false ? (
                              <StyledBadge
                                color="secondary"
                                overlap="circle"
                                badgeContent=" "
                                variant="dot"
                              >
                                <Tooltip title={Tooltip3} aria-label="video">
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    className="App-button_bottom"
                                    onClick={() =>
                                      videoCilck(
                                        item.class_id,
                                        item.existing_use_class === "2"
                                          ? "2"
                                          : "1"
                                      )
                                    }
                                  >
                                    <DesktopWindowsIcon />
                                  </Button>
                                </Tooltip>
                              </StyledBadge>
                            ) : (
                              <Tooltip title={Tooltip3} aria-label="video">
                                <Button
                                  variant="contained"
                                  color="primary"
                                  className="App-button_bottom"
                                  onClick={() =>
                                    videoCilck(
                                      item.class_id,
                                      item.existing_use_class === "2"
                                        ? "2"
                                        : "1"
                                    )
                                  }
                                >
                                  <DesktopWindowsIcon />
                                </Button>
                              </Tooltip>
                            )
                          ) : null}
                        </Grid>
                        <Grid
                          item
                          xs
                          className="App-alignCenter App-pLR10"
                          style={{ width: "84px" }}
                        >
                          {downloadList[item.class_id] &&
                          downloadList[item.class_id].length > 0 ? (
                            <Tooltip title={Tooltip2} aria-label="dwonload">
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleClickOpen(item)}
                              >
                                <Description />
                              </Button>
                            </Tooltip>
                          ) : null}
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            )
            //)
          )}
        </Paper>
        <Paper
          className={
            crows.length > 0
              ? rows.length > 0
                ? "App-mT20 App-paper"
                : "App-paper"
              : "App-NoDisplay"
          }
        >
          {crows.map(
            (item, index) => (
              //item.stream_class === "2" && item.is_filmed === "0" ? null : (
              <Grid
                container
                spacing={2}
                className="App-List"
                key={"item_" + index}
                style={
                  index + 1 !== crows.length
                    ? { borderBottom: "1px solid #ccc" }
                    : {}
                }
              >
                <Grid container style={{ padding: "10px" }}>
                  <Grid
                    item
                    xs
                    style={{
                      minWidth: "145px",
                      maxWidth: "145px",
                    }}
                  >
                    <ShowClassImage
                      S3={S3Api}
                      serviceId={serviceId}
                      classId={item.class_id}
                    />
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    <Typography
                      gutterBottom
                      variant="h6"
                      style={{ wordBreak: "break-all" }}
                    >
                      {unescapeStr(item.class_name)}
                    </Typography>
                    <Typography
                      variant="body2"
                      gutterBottom
                      style={
                        item.is_font_red === "1" && item.is_font_bold === "1"
                          ? {
                              color: "red",
                              fontWeight: "bold",
                              whiteSpace: "pre-line",
                            }
                          : item.is_font_red === "1"
                          ? {
                              color: "red",
                              whiteSpace: "pre-line",
                            }
                          : item.is_font_bold === "1"
                          ? {
                              fontWeight: "bold",
                              whiteSpace: "pre-line",
                            }
                          : { whiteSpace: "pre-line" }
                      }
                    >
                      <div
                        className="App-text-no-width"
                        style={{ whiteSpace: "pre-line" }}
                      >
                        {unescapeStr(item.explain_text)}
                      </div>
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs
                    style={{
                      minWidth: "168px",
                      display: "flex",
                      alignItems: "flex-end",
                      justifyContent: "flex-end",
                    }}
                  >
                    <div>
                      {(item.status_class === "3" ||
                        item.status_class === "4") &&
                      item.stream_class === "1" ? (
                        <Grid
                          item
                          xs={12}
                          className="App-alignRight  App-pLR10"
                        >
                          <img src="/img/3ue--Live.png" height="24" />
                        </Grid>
                      ) : null}
                      <Grid item xs={12} className="App-alignRight App-pLR10">
                        <Typography variant="body1" color="textSecondary">
                          {item.plan_date}
                        </Typography>
                      </Grid>
                      <Grid
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="flex-end"
                      >
                        <Grid
                          item
                          xs
                          className="App-alignCenter App-pLR10"
                          style={{ width: "84px" }}
                        >
                          {item.stream_class === "1" &&
                          item.status_class !== "5" ? (
                            <Tooltip title={Tooltip1} aria-label="live">
                              <Button
                                variant="contained"
                                color="primary"
                                className="App-button_bottom"
                                onClick={() => {
                                  liveCilck(item.class_id);
                                }}
                              >
                                <PlayArrow />
                              </Button>
                            </Tooltip>
                          ) : item.is_filmed === "1" &&
                            (item.open_class === "1" ||
                              closedVideoIsValid === "1") ? (
                            item.is_watched === false ? (
                              <StyledBadge
                                color="secondary"
                                overlap="circle"
                                badgeContent=" "
                                variant="dot"
                              >
                                <Tooltip title={Tooltip3} aria-label="video">
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    className="App-button_bottom"
                                    onClick={() =>
                                      videoCilck(
                                        item.class_id,
                                        item.existing_use_class === "2"
                                          ? "2"
                                          : "1"
                                      )
                                    }
                                  >
                                    <DesktopWindowsIcon />
                                  </Button>
                                </Tooltip>
                              </StyledBadge>
                            ) : (
                              <Tooltip title={Tooltip3} aria-label="video">
                                <Button
                                  variant="contained"
                                  color="primary"
                                  className="App-button_bottom"
                                  onClick={() =>
                                    videoCilck(
                                      item.class_id,
                                      item.existing_use_class === "2"
                                        ? "2"
                                        : "1"
                                    )
                                  }
                                >
                                  <DesktopWindowsIcon />
                                </Button>
                              </Tooltip>
                            )
                          ) : null}
                        </Grid>
                        <Grid
                          item
                          xs
                          className="App-alignCenter App-pLR10"
                          style={{ width: "84px" }}
                        >
                          {downloadList[item.class_id] &&
                          downloadList[item.class_id].length > 0 ? (
                            <Tooltip title={Tooltip2} aria-label="dwonload">
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleClickOpen(item)}
                              >
                                <Description />
                              </Button>
                            </Tooltip>
                          ) : null}
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            )
            //)
          )}
        </Paper>
      </Grid>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {FormatMessage({ id: "Top002.Dialog.title" })}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {FormatMessage({ id: "Top002.Dialog.lable" })}：{dialogTitle}
          </DialogContentText>
          <Table size="small">
            <TableHead>
              <TableRow>
                {!isPhone ? (
                  <TableCell>
                    <Checkbox
                      size="small"
                      onChange={allSeleted}
                      checked={isChecked}
                      inputProps={{ "aria-label": "uncontrolled-checkbox" }}
                      style={{ padding: "0px" }}
                    />
                  </TableCell>
                ) : null}
                <TableCell>
                  {FormatMessage({ id: "Top002.Dialog.table" })}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {downloadFile && downloadFile.length > 0
                ? downloadFile.map((row, index) => (
                    <TableRow key={"select_file_" + row.download_document_id}>
                      {!isPhone ? (
                        <TableCell>
                          <Checkbox
                            size="small"
                            key={"check_" + index}
                            checked={checkBoolean[index]}
                            onChange={(e: any) => checkChangeHandle(e, index)}
                            style={{ paddingLeft: "11px" }}
                          />
                        </TableCell>
                      ) : null}
                      <TableCell>
                        <span
                          className="App-Link"
                          onClick={(e: any) => linkClick(index)}
                        >
                          {row.download_document_name}
                        </span>
                      </TableCell>
                    </TableRow>
                  ))
                : null}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {FormatMessage({ id: "Button.Close" })}
          </Button>
          {!isPhone ? (
            <Button onClick={clickDownload} color="primary" autoFocus>
              {FormatMessage({ id: "Button.Download" })}
            </Button>
          ) : null}
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className="alignCenter">
          {FormatMessage({
            id: "HS049",
          })}
        </DialogContent>
        <DialogActions>
          <Button onClick={dialogOk} color="primary" autoFocus>
            {FormatMessage({
              id: "Cls012.Confirm.Button1",
            })}
          </Button>
        </DialogActions>
      </Dialog>

      {loading ? <LoadingBox /> : null}
    </MainLayout>
  );
};
