import React, { useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { FormatMessage } from "../../components/atoms/FormatMessage";

/**
 * タグ一覧
 * @param {object} props 引き継いだオブジェクト
 * @return コンポーネントHTMLコード内容
 */

export const Cls022 = (props: any) => {
  const { endData, Callback } = props;
  const [movieTime, setmovieTime] = React.useState<any>("");
  const canselClick = () => {
    Callback(null);
  };
  // 初回読み込み
  useEffect(() => {
    setmovieTime(endData.movieTime);
  }, []);

  return (
    <div className="App-paper-root">
      <Grid item xs={12} className="App-pTB10 App-pLR10">
        <Typography variant="h6">{FormatMessage({ id: "Cls001.Label.text10" })}</Typography>
        <Grid container spacing={2}>
          <Grid item xs={6} style={{ display: "flex" }}>
            <div style={{ margin: "10px" }}>{FormatMessage({ id: "Cls020.Label.Class_Time" })}</div>
            <div style={{ margin: "10px" }}>{movieTime}</div>
          </Grid>
          <Grid item xs={6}></Grid>
        </Grid>
      </Grid>
      <Grid className="App-box">
        <Grid item xs={12}>
          テロップを{endData.delflg ? "削除" : "登録"}しました。
        </Grid>
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={canselClick}
            startIcon={<ArrowBackIcon />}
          >
            {FormatMessage({ id: "Cls020.Label.text1" })}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
