import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { FormatMessage } from "../../components/atoms/FormatMessage";
import { MainLayout } from "../../layouts/MainLayout";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

/**
 * パスワード変更完了
 * @return メッセージ内容
 */
export const Pas002 = (props: any) => {
  const dispatch = useDispatch();
  const [userClass, setUserClass] = useState("");
  const [root, setRoot] = useState("");

  useEffect(() => {
    setUserClass(props.location.state.userClass);
    setRoot(props.location.state.root);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const backClick = () => {
    if (root === "acount") {
      if (userClass === "3") {
        dispatch(push("/set/001")); //配信ユーザアカウント情報
      } else if (userClass === "4") {
        dispatch(push("/set/005")); //視聴ユーザアカウント情報
      }
    } else {
      if (userClass === "3") {
        dispatch(push("/top/001")); //配信ユーザトップ
      } else if (userClass === "4") {
        dispatch(push("/top/002")); //視聴ユーザトップ
      }
    }
  };

  return (
    <MainLayout>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className="App-paper">
            <Typography
              component="h2"
              variant="h6"
              color="inherit"
              gutterBottom
            >
              {FormatMessage({ id: "Pas002.Title" })}
            </Typography>
            <Grid item xs={12} className="App-alignRight">
              <Button
                variant="contained"
                color="primary"
                startIcon={<ArrowBackIcon />}
                className="App-button_bottom"
                onClick={backClick}
              >
                {root === "acount"
                  ? FormatMessage({
                      id: "Pas002.Button.Back",
                      option: { name: "アカウント情報" },
                    })
                  : FormatMessage({
                      id: "Pas002.Button.Back",
                      option: { name: "トップ" },
                    })}
              </Button>
            </Grid>
            <Grid item xs={12} className="App-alignCenter">
              <Typography
                component="h2"
                variant="body1"
                color="inherit"
                gutterBottom
              >
                {FormatMessage({ id: "Pas002.Content" })}
              </Typography>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </MainLayout>
  );
};
