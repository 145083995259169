import { apigw } from "../awsApi";
import { Dispatch } from "@reduxjs/toolkit";

function createData(
  viewing_date: string, //視聴日時
  user_id: string, //視聴ユーザID
  user_name: string, //視聴ユーザ氏名
  handle_name: string, //ハンドルネーム
  streaming_class: string, //配信区分(0:0　1:授業版配信)
  elapsed_time_fraction: number //経過時間分数
) {
  return {
    viewing_date,
    user_id,
    user_name,
    handle_name,
    streaming_class,
    elapsed_time_fraction,
  };
}

/**
 * 授業視聴状況詳細取得
 * @param {Dispatch} dispatch reducerディスパッチ
 * @param {string} classId 授業ID
 * @param {string} userId 視聴ユーザID
 * @param {string} userName 視聴ユーザ名
 * @param {string} periodFrom 期間From
 * @param {string} periodTo 期間To
 * @param {string} attendanceTimeThreshold 視聴時間閾値
 * @param {string} attendance 0授業 出席
 * @param {string} partialAttendance 0授業 一部出席
 * @param {string} absence 0授業 欠席
 * @param {string} watched 1授業 視聴済
 * @param {string} partiallyWatched 1授業 一部視聴済
 * @param {string} notWatched 1授業 未視聴
 * @param {string} pageNo ページ番号
 * @return コンポーネントHTMLコード内容
 */
export const LC0304 = async (
  dispatch: Dispatch,
  classId: string, //授業ID
  userId: string, //視聴ユーザID
  userName: string, //視聴ユーザ名
  periodFrom: string, //期間From
  periodTo: string, //期間To
  attendanceTimeThreshold: number, //視聴時間閾値
  attendance: boolean, //0授業 出席
  partialAttendance: boolean, //0授業 一部出席
  watched: boolean, //1授業 視聴済
  partiallyWatched: boolean, //1授業 一部視聴済
  pageNo: number //ページ番号
) => {
  if (process.env.REACT_APP_API_USE_FLG === "true") {
    return apigw(
      "/LC0304",
      {
        class_id: classId,
        user_id: userId,
        user_name: userName,
        period_from: periodFrom,
        period_to: periodTo,
        attendance_time_threshold: attendanceTimeThreshold,
        attendance: attendance,
        partial_attendance: partialAttendance,
        watched: watched,
        partially_watched: partiallyWatched,
        page_no: pageNo,
      },
      dispatch
    );
  } else {
    //サンプルデータ用
    return {
      body: [
        createData("2021/4/18 13:05", "1234", "A", "エー", "0", 1),
        createData("2021/4/18 13:06", "1234", "A", "エー", "0", 2),
        createData("2021/4/18 13:07", "1234", "A", "エー", "0", 3),
        createData("2021/4/18 13:08", "1234", "A", "エー", "0", 4),
        createData("2021/4/18 13:09", "1234", "A", "エー", "0", 5),
        createData("2021/5/18 20:00", "1234", "A", "エー", "1", 1),
        createData("2021/5/18 20:01", "1234", "A", "エー", "1", 2),
        createData("2021/5/18 20:02", "1235", "B", "ビー", "1", 3),
        createData("2021/5/18 13:05", "1235", "B", "ビー", "1", 4),
        createData("2021/5/19 16:05", "1234", "A", "エー", "1", 15),
      ],
    };
  }
};
