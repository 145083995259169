/* eslint-disable @typescript-eslint/no-redeclare */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-loop-func */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/alt-text */
import React, { useRef, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import Button from "@material-ui/core/Button";
import {
  withStyles,
  makeStyles,
  Theme,
  createStyles,
} from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import IconButton from "@material-ui/core/IconButton";
import "../../css/cls/pen.css";
import "../../css/cls/cls008.css";
import ActionComand from "./comand";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { FormatMessage } from "../../components/atoms/FormatMessage";
import { signOut } from "../../reducks/userSlice";
import ListName from "./list";
import VideoSetting from "./list2";
import { PenSetting } from "./PenSetting";
import { ShapeSetting } from "./ShapeSetting";
// import VideoList from "./list5";
import ImgList from "./list6";
import Expansion from "./list7";
import WhiteBoardList from "./list8";
import QuestionResult from "./list9";
import Question from "./009";
import Button1 from "./button1";
import ChatSetting from "./list10";
import { TextFieldBox } from "../../components/atoms/TextFieldBox";
import Camera from "./camera";
import LyoutSetting from "./list11";
import { store } from "../../reducks/store";
import CastForEducationIcon from "@material-ui/icons/CastForEducation";
import SchoolIcon from "@material-ui/icons/School";
import SpeakerNotesOffIcon from "@material-ui/icons/SpeakerNotesOff";
import ForumIcon from "@material-ui/icons/Forum";
import MicIcon from "@material-ui/icons/Mic";
import VideocamIcon from "@material-ui/icons/Videocam";
import TuneIcon from "@material-ui/icons/Tune";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { faMagic } from "@fortawesome/free-solid-svg-icons";
// import { faFilm } from "@fortawesome/free-solid-svg-icons";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { faListOl } from "@fortawesome/free-solid-svg-icons";
import { faClone } from "@fortawesome/free-regular-svg-icons";
import SettingsOverscanIcon from "@material-ui/icons/SettingsOverscan";
import { faExpandAlt } from "@fortawesome/free-solid-svg-icons";
import { faCompressAlt } from "@fortawesome/free-solid-svg-icons";
import ImageIcon from "@material-ui/icons/Image";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import CloseIcon from "@material-ui/icons/Close";
import PublishIcon from "@material-ui/icons/Publish";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import imageCompression from "../../../node_modules/browser-image-compression";

import {
  FaShapes,
  FaRegHandPaper,
  FaEraser,
  FaRegHandPointer,
} from "react-icons/fa";
// import { ImZoomIn, ImPlay3, ImStop2, ImPause2 } from "react-icons/im";
import { ImZoomIn } from "react-icons/im";
import { AiFillSetting } from "react-icons/ai";
// import { BsChatDotsFill, BsChatSquareDots } from "react-icons/bs";
import { BsChatDotsFill } from "react-icons/bs";
import PersonIcon from "@material-ui/icons/Person";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import Typography from "@material-ui/core/Typography";
import { LC0302 } from "../../utils/api/LC0302";
import { CT0303 } from "../../utils/api/CT0303";
import { CT0307 } from "../../utils/api/CT0307";
import { CF0305 } from "../../utils/api/CF0305";
import { QZ0301 } from "../../utils/api/QZ0301";
import { QZ0302 } from "../../utils/api/QZ0302";
import { QZ0303 } from "../../utils/api/QZ0303";
import { ST0301 } from "../../utils/api/ST0301";
import { ST0302 } from "../../utils/api/ST0302";
import { ST0304 } from "../../utils/api/ST0304";
import { ST0305 } from "../../utils/api/ST0305";
import { ST0307 } from "../../utils/api/ST0307";
import { ST0311 } from "../../utils/api/ST0311";
// import { CT0308 } from "../../utils/api/CT0308";
// import { ST0313 } from "../../utils/api/ST0313";
import { ST0318 } from "../../utils/api/ST0318";
import { ST0323 } from "../../utils/api/ST0323";
import { ST0320 } from "../../utils/api/ST0320";
import { ST0326 } from "../../utils/api/ST0326";
import { getSessionUser } from "../../utils/authFlow";
import {
  agoraRecQuire,
  agoraRecQuire2,
  agoraRecStart,
  agoraRecStart2,
  agoraRecChangeLayout,
  agoraRecChangeLayout2,
  agoraRecStop,
  agoraRecStop2,
  agoraRecCheck,
} from "../../utils/api/AgoraCRec";

import AgoraRTC from "agora-rtc-sdk-ng"; // CameraVideoTrackInitConfig, // MicrophoneAudioTrackInitConfig, // IAgoraRTCRemoteUser,
import AgoraRTM from "agora-rtm-sdk";
import { fabric } from "fabric";

import actionComand from "./comand";

import {
  createS3,
  uploadS3,
  getListS3,
  // getFileBase64S3,
  getUrlS3,
  getFileS3,
  deleteS3,
} from "../../components/function/AwsS3Fun";

import useAgora from "../../utils/useAgora";
import MediaPlayer from "../../utils/MediaPlayer";
import { FaVideoSlash, FaMicrophoneAltSlash } from "react-icons/fa";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import "../../css/cls/cls008.css";

//画面共有
import ScreenShareRoundedIcon from "@material-ui/icons/ScreenShareRounded";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
// import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Tooltip from "@material-ui/core/Tooltip";
import Badge from "@material-ui/core/Badge";
import educastLogo from "../../css/educast.jpg";
import SendIcon from "@material-ui/icons/Send";
import MicOff from "@material-ui/icons/MicOff";
// import Popper from "@material-ui/core/Popper";

import { LoadingBox } from "../../components/atoms/LoadingBox";
// import { AnyARecord } from "dns";
// 絵文字データ
import { setData } from "./setData";
// import { VerticalAlignTop } from "@material-ui/icons";
import { apiAgoraUserCheck } from "../../utils/AgoraRTCUserApi";
import { pdfjs } from "react-pdf";
//@ts-ignore
// import * as FFMPEG from "react-ffmpeg";
// const FFMPEG2 = require("react-ffmpeg");
if (
  process.env.REACT_APP_AGORA_RTC_SHOW_LOG === "false" &&
  process.env.REACT_APP_AGORA_P2P_CHECK === "false"
)
  AgoraRTC.setLogLevel(4);
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
// const fabricImp = require("fabric");
// const fabric = fabricImp.fabric;
let socket: any;
let canvas: any;
let lastReactionTime: any;
// let canvasImg: any;
// let canvasShare: any;
// let canvasRecord: any;
// let canvasConcat: any;
// let canvasFabric: any;
// let canvasGraph: any;
// let canvasCopy: any;
// let drowCanvasDiv: any;
// let imgCanvasDiv: any;
let ctx: any;
// let ctxI: any;
// let ctxS: any;
// let ctxC: any;
// let ctxR: any;

let FabCanvas: any;
let buckBoradImg: any;
let joinList: any;
let getViewUserTimeout: any;
let getRTCUserTimeout: any;
// let GraphFabCanvas: any;
// let CopyFabCanvas: any;
let mousePoint: any = { x: 0, y: 0, px: 0, py: 0 };
let drowing = false;
let imageData: any;
let ViewLogInterval: any;
let operation = "pen";
let shapeType = "";
let agoraUserId: string;
let penColor: string = "#000",
  penWidth: number = 3,
  defaultWidth: number = 1280;
let speedcount = 0;
let summegaBps = 0;
// let video: any;
let addShapeLine: any,
  addShapeCircle: any,
  addShapeRect: any,
  addShapeTriangle: any,
  origX: any,
  origY: any,
  lineList: any,
  lineData: any,
  lineOrder: any,
  shapeList: any,
  graphShapeList: any,
  currentTime: any,
  isBrush: boolean,
  delIdList: any;
let msgIndex: number = 0;
let classStartTime: any;
let S3: any;
let chatBox: any;
let scrollMoveFlg = true;
let myuserData: any;
let videoCount: any;
let loginUserId: any;
let agoraErrCode = "";
let userCount = 0;
let agoraInfo: any;
let videoDevices: any;
let audioDevices: any;
let getDateValue: any;
let browserType: any;
let deviceType: any;
let clickEnter: boolean = false;
let mediaType: string;
let minute = 0;
let retryCount = 0;
let lastViewLog = -1;
let lastLog = false;
let selectBoardId: number;
let nowBoardId: number;
let msgLength = 300;
let retryFlg = false;
// type actUser = { userId: string; minute: number };
// let actionUserList: actUser[] = [];
let startFlg: any;
let endFlg: any;
let choiceId: number;
let zoom: any, cscale: any, movePoint: any;
let orderList: any;
let recorder: any, recorder2: any;
let userName: string;
let brush: any,
  lineId: string,
  lineNo: number,
  lineListData: any,
  shapeId: string;
let SessionGetTime = new Date().getTime();
let startDate: any;
let startDateTime: any;
let nowDate: any;
let recordTime: any;
let chatSendState: number;
let chatdisplayState: number;
let deviceConfig: any;
let classData: any;
let LastChatReceiveTime = 0;
let saveLogFlg:any;
let questionLists = [
  { check: false, answer: "", answer_no: 1, color: "#78e00b" },
  { check: false, answer: "", answer_no: 2, color: "#26d4e0" },
  { check: false, answer: "", answer_no: 3, color: "#e0b501" },
  { check: false, answer: "", answer_no: 4, color: "#c504e0" },
  { check: false, answer: "", answer_no: 5, color: "#e0610b" },
  { check: false, answer: "", answer_no: 6, color: "#ace072" },
  { check: false, answer: "", answer_no: 7, color: "#8ddbe0" },
  { check: false, answer: "", answer_no: 8, color: "#e0c868" },
  { check: false, answer: "", answer_no: 9, color: "#c45dd4" },
  { check: false, answer: "", answer_no: 10, color: "#e09f72" },
];
let questionListsColor: any = {
  shape_id: "",
  answer_1: "#78e00b",
  answer_2: "#26d4e0",
  answer_3: "#e0b501",
  answer_4: "#c504e0",
  answer_5: "#e0610b",
  answer_6: "#ace072",
  answer_7: "#8ddbe0",
  answer_8: "#e0c868",
  answer_9: "#c45dd4",
  answer_10: "#e09f72",
};
// 択一問題グラフ間の隙間の割合
const GAP_PERCENTAGE_SELECT_QUESTIONS = 2;
let percentFlg: boolean = false;
let userReactions: any = [];
let drawReactionCountInfo: any = {
  shape_id: "",
  COLOR_REACTION_OK: "#005aff",
  COLOR_REACTION_NG: "#ff4b00",
  COLOR_REACTION_HAND: "#03af7a",
};
type wbitem = {
  boardNo: number;
  selectImgId: number;
  url: string;
};
let handId: string, handNo: number, handImg: any;
let srceenShareInterval: any;
let whiteboardDataList: wbitem[] = [];
let pMouseMoveFlg: boolean = false;
let popupX: any;
let popupY: any;
let selectImgNo: any;
let selectImgUrl: any;
let chatsedFlg = false;
let agoraResorceId: any;
let agoraSId: any;
let agoraResorceId2: any;
let agoraSId2: any;
let channelRTM: any;
let refflg = false;
let screenIsSmallFlg: boolean = false;
const USER_STATUS = {
  JOIN: "JOIN",
  EXIT: "EXIT",
  CHAT: "CHAT",
  REACTION_OK: "REACTION_OK",
  REACTION_NG: "REACTION_NG",
  REACTION_HAND: "REACTION_HAND",
  CHOICE_1: "CHOICE1",
  CHOICE_2: "CHOICE2",
  CHOICE_3: "CHOICE3",
  CHOICE_4: "CHOICE4",
  CHOICE_5: "CHOICE5",
  CHOICE_6: "CHOICE6",
  CHOICE_7: "CHOICE7",
  CHOICE_8: "CHOICE8",
  CHOICE_9: "CHOICE9",
  CHOICE_10: "CHOICE10",
  NOACTION: "NOACTION",
  AGORAEXIT: "AGORAEXIT",
};
let answerCatch = 0;
let sentAnswerCatch = 0;
let checkSaveTmpMessage: any;
let Users = [
  {
    user_id: "",
    user_name: "",
    handle_name: "",
    status: USER_STATUS.EXIT,
    lastActionTime: 0,
    user_agoraid: -1,
  },
];
let joinUser: any = [];
let maxDivScrollTop: any;
let befClassTime: any;
let maxDivScrollLeft: any;
AgoraRTC.enableLogUpload();
const client = AgoraRTC.createClient({ codec: "vp8", mode: "live" });
const share_client = AgoraRTC.createClient({ codec: "vp8", mode: "live" });
let clientRTM: any;
let localVideo: any; //画面共有
// let loadFabCanvasJson: any; //画面共有時保存手書くデータ
// let videoRows: any = {}; //動画データ
// let videoInterval: any; //動画loop
// let selectVideoId: any;
let classState: any;
let nowSeconds: any;
let nowImageData: any;
let maxHeightMessageDiv = 10200;
//agora画面共有
let shareLocalTracks: any = {
  videoTrack: null,
  audioTrack: null,
};
let clickImageFlg: any;
let changeBoardFlg: any;
// let micconfig: MicrophoneAudioTrackInitConfig;
// let camconfig: CameraVideoTrackInitConfig;

let recorderStartTime: any, recorderStartTime2: any;
let reactionList: any, reactionFlg: any, reactionData: any;
let messageList: any, messageFlg: any, messageData: any, messageListData: any;
let fileNameCnt: number;
let returnFileName: any;
let ListViewList: any;
let concatFlg: any;
let largeFlg: any;
let disNameFlg: any;
let canvasData: any;
let saveJsonFlg: any;
let chatnotTestFlg: boolean = true;
let jsonSaveNo: any;
let socketSendNo: any;
let imgBackground: any;
let shareVideo: any;
let boardTime: any;
let FPSIVL: any, FPSIVLNo: any;
let screenSmall = false;
let clickBrushFlg: any;
let img_file_data: any, img_file_list: any;
let agora_view_div: any;
let classTimeAddFlg = true;
let sendImgNo: any;
let setActive: any;
let sharelength = 0;
let listlength = 0;
let alertFlg: any;
let alertCnt: any;
let offlineCnt: any;
let befClassInterval: any;
let befClassIntervalFlg: any;
let RTMTimerID: any;
type answer = {
  answer: string;
  answer_no: number;
  count: number;
  percent: number;
};
let localVideoTrackFlg: any;
let showAnswer: answer[] = [];
let requestList: any[] = [];
let BackupNo: any;
let incomingCommandList: any[] = [];
const useStyles = makeStyles(() => ({
  root: {
    display: "block",
  },
  red: {
    width: 15,
    height: 15,
    color: "red",
  },
  def: {
    width: 15,
    height: 15,
  },
  blue: {
    width: 15,
    height: 15,
    color: "blue",
  },
  green: {
    width: 15,
    height: 15,
    color: "green",
  },
  yellow: {
    width: 15,
    height: 15,
    color: "yellow",
  },
  skyblue: {
    width: 15, //theme.spacing(3),
    height: 15, //theme.spacing(3),
    color: "skyblue",
  },
  b50: {
    // float: "left",
    // margin: "10px 20px"
  },
  b60: {
    float: "left",
    margin: "10px 0 0 10px",
  },
  b70: {
    float: "right",
    margin: "10px 0 0 10px",
  },
  t50: {
    float: "left",
    margin: "10px 0 0 10px",
    color: "#fff",
  },
  headerIcon: {
    fontSize: "23px",
    color: "#3498db",
  },
  graph: {
    pointerEvents: "none",
  },
  popover: {
    pointerEvents: "none",
  },
  popoverContent: {
    pointerEvents: "auto",
  },
  arrow: {
    color: "#3993b2",
    fontSize: "20px",
    width: "20px",
    "&::before": {
      backgroundColor: "#3993b2",
      boxSizing: "border-box",
    },
  },
  tooltip: {
    top: "10px",
    color: "#fff",
    backgroundColor: "#3993b2",
    display: "brock",
  },
}));

const OPERATION_TYPE = {
  SELECT: "select",
  PEN: "pen",
  CLEAR: "clear",
  SHAPE: "shape",
  MOVE: "move",
  HAND: "hand",
  VIDEO: "video",
  SHARE: "share",
};

const SHAPE_TYPE = {
  LINE: "line",
  CIRCLE: "circle",
  TRIANGLE: "triangle",
  SQUARE: "square",
};

const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: "#7cc3dc",
    border: "1px solid #ccc",
    color: "rgba(255, 255, 255, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

const LightTooltip2 = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    border: "1px solid #ccc",
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

const StyledBadge = withStyles((theme: Theme) =>
  createStyles({
    badge: {
      position: "absolute",
      top: 10,
      left: -10,
      backgroundColor: "#f21e3f",
      color: "#f21e3f",
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: -1,
        left: -1,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "$ripple 1.2s infinite ease-in-out",
        border: "1px solid currentColor",
        content: '""',
      },
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  })
)(Badge);

declare global {
  interface Document {
    mozCancelFullScreen?: () => Promise<void>;
    msExitFullscreen?: () => Promise<void>;
    webkitExitFullscreen?: () => Promise<void>;
    mozFullScreenElement?: Element;
    msFullscreenElement?: Element;
    webkitFullscreenElement?: Element;
    webkitIsFullScreen?: any;
  }

  interface HTMLElement {
    msRequestFullscreen?: () => Promise<void>;
    mozRequestFullscreen?: () => Promise<void>;
    webkitRequestFullscreen?: () => Promise<void>;
  }
}
let classId: any;
let msgList: any = [];
let questionResFlg: any;
let videoNo: any;
let videoRecFlg: any;
let checkVideoFlg: any;
let joinStartTime: any;

export const Cls008 = (props: any) => {
  let sessionDateValue = localStorage.getItem("Cls008_session") as string;
  getDateValue =
    props &&
    props.location &&
    props.location.state &&
    typeof props.location.state !== "undefined"
      ? props.location.state
      : JSON.parse(sessionDateValue);

  const dispatch = useDispatch();
  classId = getDateValue?.classId ? getDateValue?.classId : "";

  const consoleLogOut = async (level: any, msg: any, flg?: any) => {
    if (!classId || classId === "") return;
    let saveData = [];
    if (flg) {
      saveData = msgList;
      msgList = [];
    } else {
      msg = "[" + level + "]:" + JSON.stringify(msg);
      msgList.push(msg);

      if (msgList.length > 1000) {
        saveData = msgList;
        msgList = [];
      }
    }
    if (saveData.length > 0) {
      var jsontext = JSON.stringify(saveData);
      var blob = new Blob([jsontext], { type: "text/json" });
      var jsonFile = new File([blob], "message.json");
      const date = new Date().getTime();
      var S3: any = await createS3();
      await uploadS3(
        S3,
        "haishin/" +
          store.getState().user.service_id +
          "/" +
          classId +
          "/Log/console/" +
          store.getState().user.user_id +
          "/" +
          date +
          ".json",
        jsonFile
      );
    }
  };
  if (
    process.env.REACT_APP_AWS_S3_UPLOG === "true" ||
    process.env.REACT_APP_AGORA_P2P_CHECK === "true"
  ) {
    let console: any = {};
    console.error = (...args: any) => {
      if (process.env.REACT_APP_AWS_S3_UPLOG === "true")
        consoleLogOut("ERROR", args);
      if (process.env.REACT_APP_AGORA_P2P_CHECK === "true") {
        if (JSON.stringify(args).indexOf("ice-state: pub p2p failed") >= 0)
          setP2pCheckEndOpen(true);
      }
    };
    console.warn = (...args: any) => {
      if (process.env.REACT_APP_AWS_S3_UPLOG === "true")
        consoleLogOut("WARN", args);
      if (process.env.REACT_APP_AGORA_P2P_CHECK === "true") {
        if (JSON.stringify(args).indexOf("ice-state: pub p2p failed") >= 0)
          setP2pCheckEndOpen(true);
      }
    };
    console.info = (...args: any) => {
      if (process.env.REACT_APP_AWS_S3_UPLOG === "true")
        consoleLogOut("INFO", args);
      if (process.env.REACT_APP_AGORA_P2P_CHECK === "true") {
        if (JSON.stringify(args).indexOf("ice-state: pub p2p failed") >= 0)
          setP2pCheckEndOpen(true);
      }
    };
    console.debug = (...args: any) => {
      if (process.env.REACT_APP_AWS_S3_UPLOG === "true")
        consoleLogOut("DEBUG", args);
      if (process.env.REACT_APP_AGORA_P2P_CHECK === "true") {
        if (JSON.stringify(args).indexOf("ice-state: pub p2p failed") >= 0)
          setP2pCheckEndOpen(true);
      }
    };
    console.log = (...args: any) => {
      if (process.env.REACT_APP_AWS_S3_UPLOG === "true")
        consoleLogOut("DEBUG", args);
      if (process.env.REACT_APP_AGORA_P2P_CHECK === "true") {
        if (JSON.stringify(args).indexOf("ice-state: pub p2p failed") >= 0)
          setP2pCheckEndOpen(true);
      }
    };
    (window as any).console = console;
  }

  const uploadLog = async (Log: any, mesod: string) => {
    if (process.env.REACT_APP_AWS_S3_UPLOG === "true") {
      var jsontext = JSON.stringify(Log);
      var blob = new Blob([jsontext], { type: "text/json" });
      var jsonFile = new File([blob], "message.json");
      const date = new Date().getTime();
      var S3: any = await createS3();
      await uploadS3(
        S3,
        "haishin/" +
          myuserData.service_id +
          "/" +
          classId +
          "/Log/" +
          myuserData.user_id +
          "/" +
          myuserData.user_id +
          mesod +
          date +
          ".json",
        jsonFile
      );
    }
  };

  const elm = useRef(null);
  const classes = useStyles();
  const maxclassUserNum = Number(process.env.REACT_APP_CHAT_GROUP_FLG);
  const getUserData = async () => {
    myuserData = await getSessionUser();
    if (myuserData !== false) {
      loginUserId = myuserData.user_id;
      S3 = await createS3();
      getOrderList(true);
      checkClassEndJson();
    } else {
      myuserData = await getSessionUser();
    }
  };

  const [screenIsSmall, setscreenIsSmall] = React.useState(false);
  const [allWidth, setAllWidth] = React.useState(false);
  let [UserData, setUserData] = React.useState([
    {
      user_id: "",
      user_name: "",
      handle_name: "",
      status: USER_STATUS.EXIT,
      user_agoraid: -1,
    },
  ]);
  const [classInfo] = React.useState({
    classId: getDateValue?.classId,
    className: "",
    channelId: "",
  });
  const [classTime, setClassTime] = React.useState("00:00:00");
  // const [operation, setOperetion] = React.useState(OPERATION_TYPE.PEN);
  // const [penColor, setPenColor] = React.useState({
  //   colorName: "black",
  //   color: "black",
  // });
  // const [penWidth, setPenWidth] = React.useState(3);
  const [canvasWide, setCanvasWide] = React.useState("");
  const [canvasHeight, setCanvasHeight] = React.useState("");

  // const [showVideoMenu, setShowVideoMenu] = React.useState(true);
  // const [nowVideoTime, setNowVideoTime] = React.useState("");
  // const [videoTime, setVideoTime] = React.useState("");
  const [boardImg, setBoardImg] = React.useState("");
  const [selectImgId, setSelectImgId] = React.useState(-1);
  const [imgItems, setImgItems] = React.useState<imgItem[]>([]);
  const [recordingState, setRecordingState] = React.useState(true);
  const [scale, setScale] = React.useState(1);
  const [handFlg, setHandFlg] = React.useState(false);

  const [largeNowFlg, setLargeNowFlg] = React.useState(false);
  const [displayNameFlg, setDisplayNameFlg] = React.useState(false);

  const [classStatus, setClassStatus] = React.useState("2");
  // const [agoraInfo, setAgoraInfo] = React.useState<any>({});
  // const [agoraRecInfo, setAgoraRecInfo] = React.useState({});
  const [joinLecDialogOpen, setJoinLecDialogOpen] = React.useState(false);
  // const [message, setMessage] = React.useState<Message[]>([]);
  const [chatSendUser, setChatSendUser] = React.useState("-1");
  const [chatMassage, setChatMassage] = React.useState("");
  const [requestUser, setRequestUser] = React.useState<request[]>([]);
  const [imgUploadDialogOpen, setImgUploadDialogOpen] = React.useState(false);
  const [cameraDialogOpen, setCameraDialogOpen] = React.useState(false);
  const [chooseFileName, setChooseFileName] = React.useState("");
  const [uploadFileUrl, setUploadFileUrl] = React.useState("");
  const [uploadFile, setUploadFile] = React.useState<any>();
  const [joinUserCount, setJoinUserCount] = React.useState(0);
  // const [classVideo, setClassVideo] = React.useState("");
  const [cameraStatus, setCameraStatus] = React.useState(true);
  const cas = useRef(cameraStatus);
  cas.current = cameraStatus;
  const [micStatus, setMicStatus] = React.useState(true);
  const [chatMT, setChatMT] = React.useState(0);
  const [textAreaMT, setTextAreaMT] = React.useState(32);
  const [gideMessageHidden, setGideMessageHidden] = React.useState(false);
  const [gideMessage, setGideMessage] = React.useState("");

  const [ingegate, setIngegate] = React.useState(0);
  const {
    localAudioTrack,
    localVideoTrack,
    leave,
    join,
    publish,
    unpublish,
    microphoneSetEnabled,
    cameraSetEnabled,
    remoteUsers,
    remoteLiveUsers,
    setDevice,
    microphoneSetDevice,
    cameraSetDevice,
    microphoneSetDisabled,
    microphoneSetChange,
    cameraTrack,
    getCameraMuted,
    /*cameraSetAutoDevice,
    microphoneSetAutoDevice,*/
  } = useAgora(
    client,
    async (camTrack: any, list: any, share: any) => {
      if (!camTrack) return;
      if (sharelength === share.length && listlength === list.length) return;
      if (share.length > 0) {
        switch (list.length) {
          case 0://画面共有+配信
            await camTrack.setEncoderConfiguration({
              width: 640,
              height: 360,
              frameRate: 15,
            });
            break;
          case 1://画面共有+配信+視聴1
            await camTrack.setEncoderConfiguration({
              width: 480,
              height: 360,
              frameRate: 15,
            });
            break;
          case 2://画面共有+配信+視聴1+視聴2
            await camTrack.setEncoderConfiguration({
              width: 480,
              height: 360,
              frameRate: 15,
            });
            break;
        }
      } else {
        switch (list.length) {
          case 0://配信のみ
            await camTrack.setEncoderConfiguration({
              width: 1280,
              height: 720,
              frameRate: 15,
            });
            break;
          case 1://+配信+視聴1
            await camTrack.setEncoderConfiguration({
              width: 848,
              height: 480,
              frameRate: 15,
            });
            break;
          case 2://配信+視聴1+視聴2
            await camTrack.setEncoderConfiguration({
              width: 848,
              height: 480,
              frameRate: 15,
            });
            break;
        }
      }
      sharelength = share.length;
      listlength = list.length;
    },
    async (list: any) => {
      if (
        classInfo.channelId &&
        agoraInfo.app_id &&
        agoraUserId &&
        agoraResorceId &&
        agoraSId
      ) {
        let joinUser: any = [agoraUserId];
        for (let index = 0; index < list.length; index++) {
          joinUser.push(list[index].uid);
        }
        await agoraRecChangeLayout(
          dispatch,
          classInfo.channelId,
          joinUser,
          joinUser.length,
          agoraInfo.app_id,
          agoraResorceId,
          agoraSId
        );
        if (agoraResorceId2 && agoraSId2)
          await agoraRecChangeLayout2(
            dispatch,
            classInfo.channelId,
            joinUser,
            joinUser.length,
            agoraInfo.app_id,
            agoraResorceId2,
            agoraSId2
          );
      }
    }
  );
  const refLocalVideoTrack = useRef(localVideoTrack);
  refLocalVideoTrack.current = localVideoTrack;

  const [shareState, setShareState] = React.useState(false);
  const [errDifStreamerOpen, setErrDifStreamerOpen] = React.useState(false);
  const [recordend, setRecordend] = React.useState(false);
  const [classFlg, setClassFlg] = React.useState(true);
  const [classNameWidth, setClassNameWidth] = React.useState(100);
  const refBoardImg = useRef(boardImg);
  // const refClassVideo = useRef(classVideo);
  const refRecordingState = useRef(recordingState);
  const refScale = useRef(scale);
  const refShareState = React.useRef(shareState);
  const refRecordend = useRef(recordend);
  const refClassFlg = useRef(classFlg);
  const [result, setResult] = useState<any[]>([]);
  const [networkAlertOpen, setNetworkAlertOpen] = React.useState(true);

  refBoardImg.current = boardImg;
  refRecordingState.current = recordingState;
  // refClassVideo.current = classVideo;
  refScale.current = scale;
  refShareState.current = shareState;
  refRecordend.current = recordend;
  refClassFlg.current = classFlg;

  const DialogText = {
    Button: FormatMessage({
      id: "Cls008.Dialog.Button",
    }),
    Button1: FormatMessage({
      id: "Clslist3.Dialog.Button1",
    }),
    Button2: FormatMessage({
      id: "Clslist3.Dialog.Button2",
    }),
    Text: FormatMessage({
      id: "Clslist3.Dialog.Text",
    }),
    ImageTitle: FormatMessage({
      id: "Cls008.Dialog.GetImages",
    }),
    ImageTitle2: FormatMessage({
      id: "Cls008.Dialog.ImagesTitle",
    }),
    ImgUploadExe: FormatMessage({
      id: "Cls008.Dialog.ImgUploadExe.Button1",
    }),
    ImgUploadB1: FormatMessage({
      id: "Cls008.Dialog.ImgUpload.Button1",
    }),
    ImgUploadExe2: FormatMessage({
      id: "Cls008.Dialog.ImgUploadExe.Button2",
    }),
    ImgUploadExeF: FormatMessage({
      id: "Cls008.Dialog.ImgUploadExe.File",
    }),
    ImgUploadExeT: FormatMessage({
      id: "Cls008.Dialog.ImgUploadExe.Text1",
    }),
    Reconect: FormatMessage({
      id: "Cls008.Dialog.Reconect",
    }),
    JsonUploadError: FormatMessage({
      id: "Cls008.Dialog.UploadError",
    }),
    ConfirmClassEnd: FormatMessage({
      id: "Cls008.Dialog.ConfirmText",
    }),
    Button3: FormatMessage({
      id: "Cls012.Confirm.Button1",
    }),
    Button4: FormatMessage({
      id: "Cls001.Dialog.textNo",
    }),
    closeButton: FormatMessage({
      id: "Button.Close",
    }),
    networkUnstable: FormatMessage({
      id: "Network.Message1",
    }),
    offlineText: FormatMessage({
      id: "Cls008.Dialog.OfflineText",
    }),
    p2pErrText: FormatMessage({
      id: "Cls008.Dialog.p2pErrText",
    }),
    p2pCheck: FormatMessage({
      id: "Cls008.Dialog.WocketErrText",
    }),
  };

  const agoraErrText = {
    NOT_SUPPORTED: FormatMessage({
      id: "Agora.Error.Browser",
    }),
    OPERATION_ABORTED: FormatMessage({
      id: "Agora.Error.Timeout",
    }),
    NETWORK_TIMEOUT: FormatMessage({
      id: "Agora.Error.Timeout",
    }),
    WEB_SECURITY_RESTRICT: FormatMessage({
      id: "Agora.Error.Policy",
    }),
    NETWORK_RESPONSE_ERROR: FormatMessage({
      id: "Agora.Error.NoResponce",
    }),
    UID_CONFLICT: FormatMessage({
      id: "Agora.Error.SameUser",
    }),
    default: FormatMessage({
      id: "Agora.Error.System",
    }),
    NO_USE_DEVICE: FormatMessage({
      id: "Agora.Error.NoDevice",
    }),
  };

  const RequestText = {
    Text1: FormatMessage({
      id: "Cls008.Dialog.Text1",
      option: {
        name: "(NAME)",
        type: "(TYPE)",
      },
    }),
    Text2: FormatMessage({
      id: "Cls008.Dialog.Text2",
      option: {
        name: "(NAME)",
        type: "(TYPE)",
      },
    }),
    Text3: FormatMessage({
      id: "Cls008.Dialog.Text3",
    }),
    Option1: FormatMessage({
      id: "Cls008.Request.Option1",
    }),
    Option2: FormatMessage({
      id: "Cls008.Request.Option2",
    }),
    Option3: FormatMessage({
      id: "Cls008.Request.Option3",
    }),
    Option4: FormatMessage({
      id: "Cls008.Request.Option4",
    }),
    joinType1: FormatMessage({
      id: "Cls008.Request.Text1",
      option: { name: "(NAME)" },
    }),
    joinType2: FormatMessage({
      id: "Cls008.Request.Text2",
      option: { name: "(NAME)" },
    }),
    joinType3: FormatMessage({
      id: "Cls008.Request.Text3",
      option: { name: "(NAME)" },
    }),
    joinType4: FormatMessage({
      id: "Cls008.Request.Text4",
      option: { name: "(NAME)" },
    }),
  };

  const showText = {
    UserSelect: FormatMessage({ id: "Cls008.Button.UserSelect" }),
    UserCount: FormatMessage({ id: "Cls008.Text.UserCount" }),
    ChatControl: FormatMessage({ id: "Cls008.Button.ChatControl" }),
    AllUser: FormatMessage({ id: "Cls008.Button.AllUser" }),
    SendUser: FormatMessage({ id: "Cls008.Button.SendUser" }),
    GuideMsg: FormatMessage({ id: "Cls008.GuideMsg" }),
    RestartMsg: FormatMessage({ id: "Cls008.Restart.Text" }),
    Record: FormatMessage({ id: "Cls008.Button.Record" }),
    Time: FormatMessage({ id: "Cls008.Button.Time" }),
    Exit: FormatMessage({ id: "Cls008.Button.Exit" }),
    ClassStartGuideMsg: FormatMessage({ id: "Cls008.ClassStartGuideMsg" }),
  };

  const ToolTipText = {
    Chat: FormatMessage({ id: "Cls008.ToolTip.Chat" }),
    ChatIcon1: FormatMessage({ id: "Cls008.ChatIcon1" }),
    ChatIcon2: FormatMessage({ id: "Cls008.ChatIcon2" }),
    ChatIcon3: FormatMessage({ id: "Cls008.ChatIcon3" }),
    ChatIcon4: FormatMessage({ id: "Cls008.ChatIcon4" }),
    RequestLyout: FormatMessage({ id: "Cls008.ToolTip.RequestLyout" }),
    LyoutDefolt: FormatMessage({ id: "Cls008.ToolTip.LyoutDefolt" }),
    LyoutExpansion: FormatMessage({ id: "Cls008.ToolTip.LyoutExpansion" }),
    Device: FormatMessage({ id: "Cls008.ToolTip.Device" }),
    Camera: FormatMessage({ id: "Cls008.ToolTip.Camera" }),
    Mic: FormatMessage({ id: "Cls008.ToolTip.Mic" }),
    Board: FormatMessage({ id: "Cls008.ToolTip.Board" }),
    Hand: FormatMessage({ id: "Cls008.ToolTip.Hand" }),
    Expansion: FormatMessage({ id: "Cls008.ToolTip.Expansion" }),
    Quiz: FormatMessage({ id: "Cls008.ToolTip.Quiz" }),
    ShareStop: FormatMessage({ id: "Cls008.ToolTip.ShareStop" }),
    Share: FormatMessage({ id: "Cls008.ToolTip.Share" }),
    Next: FormatMessage({ id: "Cls008.ToolTip.Next" }),
    Image: FormatMessage({ id: "Cls008.ToolTip.Image" }),
    Before: FormatMessage({ id: "Cls008.ToolTip.Before" }),
    Video: FormatMessage({ id: "Cls008.ToolTip.Video" }),
    Shape: FormatMessage({ id: "Cls008.ToolTip.Shape" }),
    Clear: FormatMessage({ id: "Cls008.ToolTip.Clear" }),
    Elaser: FormatMessage({ id: "Cls008.ToolTip.Elaser" }),
    Color: FormatMessage({ id: "Cls008.ToolTip.Color" }),
    Finger: FormatMessage({ id: "Cls008.ToolTip.Finger" }),
  };

  type imgItem = {
    board_document_id: number; //画像ID,
    board_document_name: string; //画像ファイル名
    s3_id: any; //画像URL
    file_url: any;
  };
  // type Message = {
  //   id: string;
  //   name: string;
  //   message: any;
  //   messageType: any;
  //   send_to_class: any;
  // };
  type request = {
    userId: string;
    requestId: number;
  };
  const finger = "/img/icon_058480_16.png";
  const newWhiteBoard: string = "/img/whitebord.png";
  const chimeMelody = "/westminster.mp3";
  const FILE_FORMAT_ERROR = FormatMessage({ id: "Cls001.Err.Msg5" });
  const [errorMsgImgUp, setErrorMsgImgUp] = React.useState("");
  const [agoraErrOpen, setAgoraErrOpen] = React.useState(false);
  const setAgoraErrClose = () => {
    if (localVideo && localVideo.srcObject !== null) stopStreamedVideo();
    updateStatus("6");
    leave();
    setAgoraErrOpen(false);
    sendAction(actionComand.MSG_CLASS_END, null, { status_flg: false });
    socket.close();
    channelRTM?.leave();
    clientRTM?.logout();
    localStorage.setItem("Cls008_session", "");
    localStorage.setItem("classTimeCount", "");
    localStorage.setItem("Cls008_classId", "");
    dispatch(push("/cls/001"));
  };
  const agoraError = (e: any) => {
    agoraErrCode = e;
    setAgoraErrOpen(true);
  };
  const errMsg1 = FormatMessage({ id: "Cls001.Err.Msg1" });
  const errMsg10 = FormatMessage({ id: "Cls001.Err.Msg10" });
  const errMsg2 = FormatMessage({ id: "Error.Message.PostErr" });
  const errMsg3 = FormatMessage({ id: "Error.Message.ClassTime" });
  const errMsg4 = FormatMessage({ id: "Cls008.Dialog.SaveQuestionErrText" });
  const RTMConectErr1 = FormatMessage({ id: "Cls008.RTMErr.Text1" });
  const RTMConectErr2 = FormatMessage({ id: "Cls008.RTMErr.Text2" });
  const RTMConectErr3 = FormatMessage({ id: "Cls008.RTMErr.Text3" });
  const [RTMErrFlg, setRTMErrFlg] = React.useState(false);
  const classTimeCheck = async () => {
    let path = store.getState().router.location.pathname;
    if (path === "/cls/008") {
      let getServiceData: any = await CF0305(dispatch);
      if (getServiceData && Object.keys(getServiceData).indexOf("body") >= 0) {
        var serviceRows = getServiceData.body;
        if (
          serviceRows[0].status_stream_time <
            serviceRows[0].status_stream_time_limit ||
          serviceRows[0].stream_time_excess === "1"
        ) {
        } else {
          setRecordingState(false);
          updateStatus("1");
          await leave();
          alert(errMsg3);
          sendAction(actionComand.MSG_ClASSTIME_LIMIT, null, {});
          setTimeout(getOut, 1000, false);
        }
      }
      setTimeout(classTimeCheck, 60000);
    }
  };

  const JoinStart = async (flg: any) => {
    joinStartTime = new Date().getTime();
    var timeout = 100;
    if (befClassTime > 0) {
      timeout = 28000;
      setLoading(true);
      setGideMessage(showText.RestartMsg);
    }
    setJoinButtonState(false);
    client.setClientRole("host");
    if(agoraUserId === ""){
      setTimeout(async () => {
        if (agoraInfo.app_id && classInfo.channelId && agoraInfo.token) {
          setJoinButtonState(false);
          let getServiceData: any = await CF0305(dispatch);
          if (
            getServiceData &&
            Object.keys(getServiceData).indexOf("body") >= 0
          ) {
            var serviceRows = getServiceData.body;
            if (
              serviceRows[0].status_stream_time <
                serviceRows[0].status_stream_time_limit ||
              serviceRows[0].stream_time_excess === "1"
            ) {
              if (
                serviceRows[0].month_end_save_class_time <
                serviceRows[0].save_class_time_limit
              ) {
                await updateStatus("3");
                reactionSaveStart();
                setGideMessage(showText.ClassStartGuideMsg);
                classTimeCheck();
                let checkJoinFlg = false;
                join(
                  agoraInfo.app_id,
                  classInfo.channelId,
                  agoraInfo.token,
                  20000, //loginUserId + myuserData.user_class
                  undefined,
                  true,
                  flg ? true : false
                ).then((result: any) => {
                  if (result.state !== "OK") {
                    agoraError(result);
                  } else {
                    setJoinButtonState(true);
                    agoraUserId = result.agoraUserId;
                    const callback = () => {
                      console.log("localAudioTrack track-ended", localAudioTrack);
                      unpublish(() => {
                        localVideoTrackFlg = true;
                        checkVideoFlg = false;
                        setRetryErrorOpen(true);
                      });
                    };
                    publish(null, null, callback).then((result: any) => {
                      checkJoinFlg = true;
                      checkVideoFlg = true;
                      localVideoTrackFlg = false;
                      if (result.state !== "OK") {
                        agoraError(result);
                        return false;
                      } else {
                        setJoinButtonState(true);
                        SearchNoActiveUser();
                        let whiteBoard = document.getElementsByClassName(
                          "whiteBoard"
                        ) as HTMLCollectionOf<HTMLElement>;
                        let timeCount = 0;
                        if (befClassTime !== recordTime) {
                          if (befClassTime > recordTime) {
                            timeCount = befClassTime;
                          } else {
                            timeCount = recordTime;
                          }
                        } else {
                          timeCount = recordTime;
                        }
                        // sendAction(actionComand.MSG_WB_RESULT, null, {
                        //   board_no: selectBoardId,
                        //   send_user_id: loginUserId,
                        //   send_user_name: userName,
                        //   scale: cscale,
                        //   move_point: movePoint,
                        //   width: whiteBoard[0].style.width,
                        //   height: whiteBoard[0].style.height,
                        //   vl_setting: VLSetting.current,
                        //   bl_setting: BLSetting.current,
                        //   img_no: selectImgNo,
                        //   now_lec_time: timeCount,
                        //   class_start_time: classStartTime,
                        //   class_user_num: Users.length,
                        // });
                        // if (localVideoTrack && localVideoTrack.getStats()) {
                        //   localVideoTrack.on("track-ended", () => {
                        //     console.log(
                        //       "localVideoTrack track-ended",
                        //       localVideoTrack
                        //     );
                        //     localVideoTrackFlg = true;
                        //     unpublish(() => {
                        //       checkVideoFlg = false;
                        //       setRetryErrorOpen(true);
                        //     });
                        //   });
                        // }
  
                        // const saveBuckupImageInterval = setInterval(() => {
                        //   saveBackupImage();
                        //   let path = store.getState().router.location.pathname;
                        //   if (refRecordend.current || path !== "/cls/008") {
                        //     clearInterval(saveBuckupImageInterval);
                        //   }
                        // }, 1000);
  
                        const makePenDoing = setInterval(() => {
                          let path = store.getState().router.location.pathname;
                          if (refRecordend.current || path !== "/cls/008") {
                            clearInterval(makePenDoing);
                          } else {
                            makeDrawPen();
                          }
                        }, 100);
                        if(!saveLogFlg) {
                          saveLogFlg = true;
                          ViewLogInterval = setInterval(() => {
                            let path = store.getState().router.location.pathname;
                            const aboutMinite = Math.ceil(nowSeconds / 60);
                            if (path !== "/cls/008") {
                              clearInterval(ViewLogInterval);
                            } else {
                              const flg = startDateTime ? "1" : "0";
                              registViewLog(aboutMinite, flg);
                            }
                          }, 60000);
                        }
                        getRTCUser();
                        setLoading(false);
                        return true;
                      }
                    });
                  }
                });
                setTimeout(() => {
                  setLoading(false);
                  if (!checkJoinFlg) setJoinCheckEndOpen(true);
                }, 15000);
              } else {
                setLoading(false);
                alert(errMsg10);
              }
            } else {
              setLoading(false);
              alert(errMsg1);
            }
          } else {
            setLoading(false);
            alert(errMsg2);
            setJoinButtonState(true);
            return false;
          }
        } else {
          setLoading(false);
          return false;
        }
      }, timeout);
    }
  };

  const agoraLeave = async (flg: boolean) => {
    setRecordingState(false);
    let leaveState: any = await leave();
    if (leaveState === "OK") {
      getOut(flg);
    } else {
      agoraError(leaveState);
    }
  };

  type deviceItem = {
    deviceId: string;
    groupId: string;
    kind: string;
    label: string;
  };
  const [micList, setMicList] = React.useState<deviceItem[]>();
  const [cameraList, setCameraList] = React.useState<deviceItem[]>();
  const [selectCamera, setSelectCamera] = React.useState<any>("");
  const [selectMic, setSelectMic] = React.useState<any>("");
  const getDeviceList = async () => {
    AgoraRTC.getDevices().then((devices) => {
      audioDevices = devices.filter(function (device) {
        return device.kind === "audioinput";
      });
      setMicList(audioDevices);
      videoDevices = devices.filter(function (device) {
        return device.kind === "videoinput";
      });
      setCameraList(videoDevices);
    });
  };

  const cameraClick = async () => {
    let muted = await getCameraMuted();
    if (!muted) {
      cameraSetEnabled("true").then((result: any) => {
        setCameraStatus(false);
      });
    } else {
      cameraSetEnabled("false").then((result: any) => {
        setCameraStatus(true);
      });
    }
  };

  const micClick = () => {
    if (micStatus) {
      microphoneSetEnabled().then((result: any) => {
        if (result) {
          setMicStatus(false);
          sendAction(actionComand.MSG_DISPLAY_CLICK, null, {});
        }
      });
    } else {
      microphoneSetDisabled().then((result: any) => {
        if (result) setMicStatus(true);
      });
    }
  };

  const cameraChange = (deviceName: any) => {
    const found = cameraList?.find((element) => element.label === deviceName);
    if (found) {
      setCameraStatus(true);
      cameraSetDevice(found?.deviceId, deviceName, cameraStatus);
      // microphoneSetDisabled();
    }
  };

  const micChange = (deviceName: any) => {
    const found = micList?.find((element) => element.label === deviceName);
    microphoneSetDevice(found?.deviceId, micStatus);
    microphoneSetChange(micStatus);
    if (!micStatus) {
      setTimeout(() => {
        microphoneSetEnabled().then((result: any) => {
          setMicStatus(false);
          sendAction(actionComand.MSG_DISPLAY_CLICK, null, {});
        });
      }, 1000);
      sendAction(actionComand.MSG_DISPLAY_CLICK, null, {});
    }
  };

  const sendChange = (e: any) => {
    setChatSendUser(e.target.value);
  };
  //メッセージ作成
  const sendMessage = () => {
    if (chatMassage === "") {
      return;
    }
    if (!chatsedFlg) {
      chatsedFlg = true;
      /*if (
      chatMassage.indexOf("*") === 0 &&
      chatMassage.lastIndexOf("*") === chatMassage.length - 1
    ) {
      const strNum = chatMassage.substring(1, chatMassage.length - 1);
      const num = Number(strNum);
      let msgInsert = document.getElementsByClassName("msg-insert");
      if (msgInsert[0].firstChild) {
        const child: any = msgInsert[0].firstChild;
        const firstId = child.id;
        const firstIndex = Number(firstId.substring(3));
        if (num) {
          msgLength = num;
          const nowmsgIndex = msgIndex;
          const delIndex = nowmsgIndex - msgLength;
          if (delIndex > 0) {
            for (let index = firstIndex; index < delIndex; index++) {
              const delId = "msg" + index;
              const delDiv = document.getElementById(delId);
              delDiv?.remove();
            }
          }
        }
      }
    }*/
      const viewsendUser: any = document.getElementById("status-select");
      if (viewsendUser.value === "none") {
        chatsedFlg = false;
        return;
      }
      let userId;
      let sendClass;
      if (viewsendUser.value === chatSendUser) {
        userId = chatSendUser !== "-1" ? chatSendUser : null;
        sendClass = chatSendUser !== "-1" ? 2 : null;
      } else {
        userId = viewsendUser.value !== "-1" ? chatSendUser : null;
        sendClass = viewsendUser.value !== "-1" ? 2 : null;
      }

      const option = {
        message: chatMassage,
        send_to_class: sendClass,
        send_user_id: loginUserId,
        send_user_name: userName,
        class_id: classId,
        session_id: myuserData.session_id,
      };
      sendAction(actionComand.MSG_CHAT_SEND_MESSAGE, userId, option);
      chatsedFlg = false;
      setChatMassage("");
    }
  };

  const messagesEndRef = useRef<null | HTMLDivElement>(null);

  const scrollToBottom = () => {
    if (messagesEndRef && messagesEndRef.current)
      messagesEndRef.current.scrollIntoView();
  };

  useEffect(scrollToBottom, []);

  const sendAction = async (actionId: string, sendUserId: any, option: any) => {
    const nowTime = new Date().getTime();
    let chatTime = nowTime;
    // if (retryFlg) {
    //   const reClassTime = new Date(classStartTime);
    //   chatTime = reClassTime.setSeconds(
    //     reClassTime.getSeconds() + befClassTime
    //   );
    //   if (startDate > 0) {
    //     const def = nowTime - startDate;
    //     chatTime = chatTime + def;
    //   }
    // }
    socketSendNo++;
    let message = JSON.stringify({
      send_no: socketSendNo,
      save_no: jsonSaveNo,
      actionId: actionId,
      channelId: classInfo.channelId,
      userId: sendUserId,
      currentTime: nowTime,
      option: option,
      classId: classId,
      sessionId: localStorage.getItem("session_id")
        ? localStorage.getItem("session_id")
        : null,
    });

    let list = JSON.stringify({
      action: "MESSAGE_SEND",
      message: message,
      roomId: classInfo.channelId,
    });

    if (
      actionId === actionComand.MSG_CHAT_SEND_MESSAGE ||
      actionId === actionComand.MSG_REACTION ||
      actionId === actionComand.MSG_QUIZ_QUESTIONS
    ) {
      channelRTM
        ?.sendMessage({
          text: list,
        })
        .then(() => {
          if (actionId === actionComand.MSG_CHAT_SEND_MESSAGE)
            ST0323(
              dispatch,
              classId,
              chatTime,
              sendUserId ? sendUserId : "",
              2,
              option.message,
              String(nowSeconds),
              sendUserId
            );
          if (actionId === actionComand.MSG_CHAT_SEND_MESSAGE) {
            // if (!messageData[minute]) messageData[minute] = [];
            // messageData[minute].push({
            //   nowSeconds: nowSeconds,
            //   currentTime: nowTime,
            //   user_id: sendUserId,
            //   send_user_id: option.send_user_id,
            //   send_user_name: option.send_user_name,
            //   message: option.message,
            // });
            let div = document.createElement("div");
            div.setAttribute("class", "msg-sends");
            let span = document.createElement("span");
            if (sendUserId) {
              span.style.color = "red";
            }
            span.append(userName + ":");
            // span.append(document.createElement("br"));
            let messages = option.message.split("\n");
            for (let index = 0; index < messages.length; index++) {
              if (index > 0) span.append(document.createElement("br"));
              span.append(messages[index]);
            }
            div.append(span);
            if (!messageData[minute]) messageData[minute] = [];
            messageData[minute].push({
              nowSeconds: nowSeconds,
              type: 2,
              currentTime: chatTime,
              user_id: sendUserId ? sendUserId : null,
              html: div.innerHTML,
            });
            let msgInsert = document.getElementsByClassName("msg-insert");
            let chatPopup = document.getElementsByClassName("chat-popup");
            for (let index = 0; index < msgInsert.length; index++) {
              msgInsert[index].append(div);
            }
            if (chatPopup[0]) chatPopup[0].children[0].children[0].append(div);
            if (
              msgInsert &&
              msgInsert[0] &&
              msgInsert[0].clientHeight &&
              msgInsert[0].clientHeight > maxHeightMessageDiv
            ) {
              for (let index = 0; index < 10; index++) {
                if (msgInsert[0].children[0]) msgInsert[0].children[0].remove();
                if (msgInsert[0].clientHeight < maxHeightMessageDiv) break;
              }
            }
            const clist = document.getElementsByClassName("chat-box");
            //スクロールバーの位置をリストの最下部に設定
            if (clist && clist.length > 0) {
              clist[0].scrollTop = clist[0].scrollHeight;
            }
            // const breakedText = option.message
            //   .split("\n")
            //   .map((line: any, key: any) => (
            //     <span key={key}>
            //       {line}
            //       <br />
            //     </span>
            //   ));
            // setMessage((prev) => [
            //   ...prev,
            //   {
            //     id: loginUserId,
            //     name: userName,
            //     message: breakedText,
            //     messageType: 0,
            //     send_to_class: option.send_to_class,
            //   },
            // ]);
          }
          setRTMErrFlg(false);
        })
        .catch((err: any) => {
          const Log: any[] = [];
          Log.push("chat sendErr");
          Log.push(JSON.stringify(err));
          uploadLog(Log, "_chatSendErr_");
          let path = store.getState().router.location.pathname;
          if (!endFlg && path === "/cls/008") setRTMErrFlg(true); //setRTMErrFlg(RTMConectErr);
        });
    } else {
      if (socket && socket.readyState !== WebSocket.OPEN) {
        socket.onopen = (event: any) => {
          // クライアント接続時
          socket.send(list);
          console.log("socket 送信しました", list);
        };
      } else {
        socket.send(list);
        console.log("socket 送信しました", list);
      }
    }

    if (socket && actionId === actionComand.MSG_QUIZ_QUESTIONS) {
      let mergeShowAnswer: any = [];
      if (option.answer_options && option.answer_options.length > 0) {
        // eslint-disable-next-line array-callback-return
        option.answer_options.map((item: any) => {
          mergeShowAnswer = [
            ...mergeShowAnswer,
            {
              answer: item.answer,
              answer_no: item.answer_no,
              count: item.count ? item.count : 0,
              percent: item.percent ? item.percent : 0,
            },
          ];
        });

        showAnswer = mergeShowAnswer;
        setResult(mergeShowAnswer);
        drawShowAnswer(mergeShowAnswer);
        questionResultOpen();
      }
    } else if (socket && actionId === actionComand.MSG_CHAT_SEND_SETTING) {
      chatSendState = option.chat_send_setting;
    } else if (socket && actionId === actionComand.MSG_CHAT_DISPLAY_SETTINGS) {
      chatdisplayState = option.chat_display_setting;
    } else if (socket && actionId === actionComand.MSG_JOIN_REQUEST) {
      if (option.status === 2) {
        requestList = [...requestUser];
        const newRequestList = requestList.filter(
          (item: any) =>
            !(
              item.userId === sendUserId &&
              item.requestId === option.request_type
            )
        );
        requestList = newRequestList;
        setRequestUser(newRequestList);
        joinUpdete(
          { send_user_id: sendUserId, reply_type: option.request_type },
          false
        );
      } else {
        requestList = [...requestUser];
        requestList.push({
          userId: sendUserId,
          requestId: option.request_type,
        });
        setRequestUser(requestList);
      }
    }
  };

  const RTMLoginCheck = async () => {
    let path = store.getState().router.location.pathname;
    if (path === "/cls/008") {
      const nowTime = new Date().getTime();
      if (nowTime > LastChatReceiveTime + 300000) {
        channelRTM
          .getMembers()
          .then((result: any) => {
            if (
              result.find((element: any) => element === myuserData.user_id + 3)
            ) {
              channelRTM
                ?.sendMessage({
                  text: "chatCheck",
                })
                .then(() => {
                  RTMTimerID = setTimeout(RTMLoginCheck, 300000);
                })
                .catch((err: any) => {
                  if (err.code === 5) {
                    channelRTM?.join();
                    RTMTimerID = setTimeout(RTMLoginCheck, 300000);
                  } else {
                    clientRTM
                      .login({
                        uid: myuserData.user_id + myuserData.user_class,
                        token: agoraInfo.token_rtm,
                      })
                      .then(() => {
                        channelRTM?.join();
                        RTMTimerID = setTimeout(RTMLoginCheck, 300000);
                      })
                      .catch((e: any) => {
                        const Log: any[] = [];
                        Log.push("chat sendErr");
                        Log.push(JSON.stringify(e));
                        uploadLog(Log, "_RTMReLoginErr_");
                        RTMTimerID = setTimeout(RTMLoginCheck, 300000);
                      });
                  }
                });
            }
          })
          .catch((err: any) => {
            if (err.code === 5) {
              channelRTM?.join();
              RTMTimerID = setTimeout(RTMLoginCheck, 300000);
            } else {
              clientRTM
                .login({
                  uid: myuserData.user_id + myuserData.user_class,
                  token: agoraInfo.token_rtm,
                })
                .then(() => {
                  channelRTM?.join();
                  RTMTimerID = setTimeout(RTMLoginCheck, 300000);
                })
                .catch((e: any) => {
                  const Log: any[] = [];
                  Log.push("chat sendErr");
                  Log.push(JSON.stringify(e));
                  uploadLog(Log, "_RTMReLoginErr_");
                  RTMTimerID = setTimeout(RTMLoginCheck, 300000);
                });
            }
          });
      } else {
        RTMTimerID = setTimeout(RTMLoginCheck, 300000);
      }
    }
  };

  type replyItem = {
    replyType: number;
    status: number;
    userName: any;
  };

  const [replyType, setReplyType] = React.useState<replyItem>();
  const [joinUser1, setJoinUser1] = React.useState<any>(null);
  const [joinUser2, setJoinUser2] = React.useState<any>(null);
  const [joinUser3, setJoinUser3] = React.useState<any>(null);
  const [joinUser4, setJoinUser4] = React.useState<any>(null);
  const setAnswerCatch = (v: number) => {
    answerCatch = v;
  };
  const setSentAnswerCatch = (v: number) => {
    sentAnswerCatch = v;
  };

  const joinUpdete = async (option: any, flg: boolean) => {
    const found = Users.find(
      (element) => element.user_id === option.send_user_id
    );

    const joinNow = option.reply_type
      ? option.reply_type === 1
        ? joinUser.find(
            (element: any) =>
              element.userId === found?.user_id &&
              (element.joinType === 1 || element.joinType === 2)
          )
        : joinUser.find(
            (element: any) =>
              element.userId === found?.user_id &&
              element.joinType === option.reply_type
          )
      : joinUser.find((element: any) => element.userId === found?.user_id);

    if (joinNow) {
      const delUser = option.reply_type
        ? joinUser.filter(
            (element: any) =>
              !(
                element.userId === joinNow.userId &&
                element.joinType === joinNow.joinType
              )
          )
        : joinUser.filter(
            (element: any) => !(element.userId === joinNow.userId)
          );
      joinUser = delUser;
    } else {
      if (option.reply_type && flg) {
        joinUser.push({
          userId: found?.user_id,
          userName: found?.handle_name,
          joinType: option.reply_type,
        });
      }
    }

    if (joinUser.length === 0) {
      setJoinUser1(null);
      setJoinUser2(null);
      setJoinUser3(null);
      setJoinUser4(null);
    } else {
      setJoinUser1(joinUser[0]);
      if (joinUser.length > 1) {
        setJoinUser2(joinUser[1]);
      } else {
        setJoinUser2(null);
      }
      if (joinUser.length > 2) {
        setJoinUser3(joinUser[2]);
      } else {
        setJoinUser3(null);
      }
      if (joinUser.length > 3) {
        setJoinUser4(joinUser[3]);
      } else {
        setJoinUser4(null);
      }
    }
  };

  const makeDrawPen = () => {
    if (!refflg && lineOrder.length > 0) {
      refflg = true;
      const doing = (lid: string) => {
        var ref = true;
        var line_d: any;
        if (lineData[lid]) {
          if (
            lineData[lid] &&
            lineData[lid][0].flg === "S" &&
            lineData[lid][1].flg === "E"
          ) {
            let path: any = [
              ["M", lineData[lid][0].point.x, lineData[lid][0].point.y],
              ["L", lineData[lid][0].point.x - 1, lineData[lid][0].point.y - 1],
            ];
            lineList[lid] = new fabric.Path(path, {
              name: lid,
              globalCompositeOperation: lid,
              objectCaching: false,
              selectable: false,
              evented: true,
              hoverCursor: "default",
              stroke: lineData[lid][0].color,
              strokeWidth: lineData[lid][0].width,
              fill: "",
              strokeLineCap: "round",
              strokeLineJoin: "round",
            });
            FabCanvas.add(lineList[lid]);
            FabCanvas.renderAll();
            ref = false;
            refflg = false;
            delete lineData[lid];
            changeJson();
          } else {
            if (!lineData[lid]["goC"]) {
              lineData[lid]["goC"] = 0;
              lineData[lid]["goE"] = 0;
              lineData[lid]["goR"] = 0;
              lineData[lid]["path"] = [];
            }
            var key = lineData[lid]["goC"];
            if (lineData[lid][key]) {
              if (key === 0 && lineData[lid][key]["flg"] !== "S") {
                ref = false;
                refflg = false;
                delete lineData[lid];
              } else {
                if (lineData[lid][key]["flg"] === "S") {
                  lineData[lid].path.push([
                    "M",
                    lineData[lid][key].point.x,
                    lineData[lid][key].point.y,
                  ]);
                  lineList[lid] = new fabric.Path(lineData[lid].path, {
                    name: lid,
                    globalCompositeOperation: lid,
                    objectCaching: false,
                    selectable: false,
                    evented: false,
                    hoverCursor: "default",
                    stroke: lineData[lid][key].color,
                    strokeWidth: lineData[lid][key].width,
                    strokeLineCap: "round",
                    strokeDashOffset: 0,
                    strokeLineJoin: "round",
                    strokeUniform: false,
                    strokeMiterLimit: 10,
                    fill: "",
                  });
                  FabCanvas.add(lineList[lid]);
                  FabCanvas.renderAll();
                }
                if (lineData[lid][key]["flg"] === "M") {
                  lineData[lid].path.push([
                    "Q",
                    lineData[lid][key - 1].point.x,
                    lineData[lid][key - 1].point.y,
                    lineData[lid][key].point.x,
                    lineData[lid][key].point.y,
                  ]);
                  if (lineList[lid] && lineList[lid].path) {
                    lineList[lid].path.push([
                      "Q",
                      lineData[lid][key - 1].point.x,
                      lineData[lid][key - 1].point.y,
                      lineData[lid][key].point.x,
                      lineData[lid][key].point.y,
                    ]);
                  }
                }
                if (lineData[lid][key]["flg"] === "E") {
                  FabCanvas.remove(lineList[lid]);
                  lineData[lid].path.push([
                    "L",
                    lineData[lid][key].point.x,
                    lineData[lid][key].point.y,
                  ]);
                  lineList[lid] = new fabric.Path(lineData[lid].path, {
                    name: lid,
                    globalCompositeOperation: lid,
                    objectCaching: false,
                    selectable: false,
                    evented: true,
                    hoverCursor: "default",
                    stroke: lineData[lid][key].color,
                    strokeWidth: lineData[lid][key].width,
                    strokeLineCap: "round",
                    strokeDashOffset: 0,
                    strokeLineJoin: "round",
                    strokeUniform: false,
                    strokeMiterLimit: 10,
                    fill: "",
                  });
                  FabCanvas.add(lineList[lid]);
                  ref = false;
                  refflg = false;
                  delete lineData[lid];
                  line_d = null;
                  changeJson();
                }
                FabCanvas.renderAll();
                if (lineData[lid])
                  lineData[lid]["goC"] = lineData[lid]["goC"] + 1;
              }
            } else {
              if (lineData[lid]["goR"] > 100) {
                ref = false;
                refflg = false;
                // FabCanvas.remove(lineList[lid]);
                // delete lineList[lid];
                delete lineData[lid];
              } else if (lineData[lid]["goE"] > 100) {
                ref = false;
                refflg = false;
                lineData[lid]["goE"] = 0;
                lineData[lid]["goR"]++;
                lineOrder.push(lid);
                // delete lineData[lid];
              } else {
                lineData[lid]["goE"]++;
              }
            }
          }
        }
        if (ref) {
          setTimeout(() => {
            doing(lid);
          }, 10);
        }
      };
      doing(lineOrder[0]);
      lineOrder.splice(0, 1);
    }
  };

  // const makeDrawPen = (id: string) => {
  //   var ref = true;
  //   refflg = true;
  //   // var doFlg = true;
  //   // if (operation === OPERATION_TYPE.PEN && drowing) doFlg = false;
  //   if (lineData[id]) {
  //     if (!lineData[id]["goC"]) lineData[id]["goC"] = 0;
  //     var key = lineData[id]["goC"];
  //     if (lineData[id][key]) {
  //       if (lineData[id][key]["flg"] === "S") {
  //         let path: any = [];
  //         path[0] = ["M", lineData[id][key].point.x, lineData[id][key].point.y];
  //         lineList[id] = new fabric.Path(path, {
  //           name: id,
  //           globalCompositeOperation: id,
  //           objectCaching: false,
  //           selectable: false,
  //           evented: false,
  //           hoverCursor: "default",
  //           stroke: lineData[id][key].color,
  //           strokeWidth: lineData[id][key].width,
  //           strokeLineCap: "round",
  //           strokeDashOffset: 0,
  //           strokeLineJoin: "round",
  //           strokeUniform: false,
  //           strokeMiterLimit: 10,
  //           fill: "",
  //         });
  //         FabCanvas.add(lineList[id]);
  //         FabCanvas.renderAll();
  //       }
  //       if (lineData[id][key]["flg"] === "M") {
  //         if (lineList[id] && lineList[id].path) {
  //           lineList[id].path.push([
  //             "Q",
  //             lineData[id][key - 1].point.x,
  //             lineData[id][key - 1].point.y,
  //             lineData[id][key].point.x,
  //             lineData[id][key].point.y,
  //           ]);
  //         }
  //         FabCanvas.renderAll();
  //       }
  //       if (lineData[id][key]["flg"] === "E") {
  //         if (lineList[id] && lineList[id].path)
  //           lineList[id].path.push([
  //             "L",
  //             lineData[id][key].point.x,
  //             lineData[id][key].point.y,
  //           ]);
  //         FabCanvas.remove(lineList[id]);
  //         lineList[id] = new fabric.Path(lineList[id].path, {
  //           name: id,
  //           globalCompositeOperation: id,
  //           objectCaching: false,
  //           selectable: false,
  //           evented: true,
  //           hoverCursor: "default",
  //           stroke: lineData[id][key].color,
  //           strokeWidth: lineData[id][key].width,
  //           strokeLineCap: "round",
  //           strokeDashOffset: 0,
  //           strokeLineJoin: "round",
  //           strokeUniform: false,
  //           strokeMiterLimit: 10,
  //           fill: "",
  //         });
  //         FabCanvas.add(lineList[id]);
  //         FabCanvas.renderAll();
  //         ref = false;
  //         refflg = false;
  //         delete lineData[id];
  //         changeJson();
  //       }
  //       if (lineData[id]) lineData[id]["goC"] = lineData[id]["goC"] + 1;
  //     }
  //   }
  //   if (ref) {
  //     setTimeout(() => {
  //       // eslint-disable-next-line @typescript-eslint/no-unused-vars
  //       makeDrawPen(id);
  //     }, 5);
  //   }
  // };

  const streamerDrawline = (drawPoint: any) => {
    if (FabCanvas) {
      // var flg = false;
      var id = drawPoint.user_id;
      var mousePoint = drawPoint.mouse_point;
      var color = drawPoint.pen_color;
      var width = drawPoint.pen_width;
      if (!lineData[id] && mousePoint[0].flg === "S") {
        // flg = true;
        lineData[id] = {};
        lineOrder.push(id);
      }
      for (let index = 0; index < mousePoint.length; index++) {
        const element = mousePoint[index];
        if (
          lineData[id] &&
          element &&
          Object.keys(element).indexOf("point") >= 0
        ) {
          if (!lineData[id][element.no]) lineData[id][element.no] = {};
          lineData[id][element.no]["point"] = element.point;
          lineData[id][element.no]["flg"] = element.flg;
          lineData[id][element.no]["color"] = color;
          lineData[id][element.no]["width"] = width;
        }
      }
      // if (flg) makeDrawPen(id);
    }
  };

  // 2秒タイマー
  // let timer2Second: any = null;

  const [confirmClassEndOpen, setconFirmClassEndOpen] = React.useState(false);
  //授業終了
  const confirmClassEnd = () => {
    setconFirmClassEndOpen(true);
  };
  const ClassEndCancel = () => {
    setconFirmClassEndOpen(false);
  };
  const ClassEnd = () => {
    updateStatus("5");
    setconFirmClassEndOpen(false);
  };

  const [offlineEndOpen, setOfflineEndOpen] = React.useState(false);
  const [p2pCheckEndOpen, setP2pCheckEndOpen] = React.useState(false);
  const [joinCheckEndOpen, setJoinCheckEndOpen] = React.useState(false);

  const messageDoing = () => {
    if (!messageFlg) {
      if (messageList.length > 0) {
        messageFlg = true;
        setTimeout(() => {
          try {
            var data = messageList;
            messageList = [];
            if (largeFlg) {
              data = data
                .concat(data)
                .concat(data)
                .concat(data)
                .concat(data)
                .concat(data)
                .concat(data)
                .concat(data);
            }
            var users: any = {};
            let msgInsert = document.getElementsByClassName("msg-insert");
            let chatPopup = document.getElementsByClassName("chat-popup");
            if (disNameFlg || Users.length > maxclassUserNum) {
              if (data && data.length > 0) {
                let div = document.createElement("div");
                div.setAttribute("class", "msg-receives");
                let checkLastSave = false;
                let checkLastTime = null;
                for (let index = 0; index < data.length; index++) {
                  const commandProp = data[index];
                  if (commandProp) {
                    if (!users[commandProp.option.send_user_id])
                      users[commandProp.option.send_user_id] = "user_id";
                    if (commandProp.userId) {
                      if (index > 0 && div.innerHTML.length > 0) {
                        if (!messageData[minute]) messageData[minute] = [];
                        messageData[minute].push({
                          nowSeconds: nowSeconds,
                          type: 2,
                          currentTime: data[index - 1].currentTime,
                          user_id: null,
                          html: div.innerHTML,
                        });
                        msgInsert[0].append(div);
                        if (chatPopup[0])
                          chatPopup[0].children[0].children[0].append(div);
                        div = document.createElement("div");
                        div.setAttribute("class", "msg-receives");
                      }
                      if (commandProp.userId === loginUserId) {
                        let span = document.createElement("span");
                        span.style.color = "red";
                        span.append(commandProp.option.send_handle_name + ":");
                        let messages = commandProp.option.message.split("\n");
                        for (let mi = 0; mi < messages.length; mi++) {
                          if (mi > 0) span.append(document.createElement("br"));
                          span.append(messages[mi]);
                        }
                        div.append(span);
                        msgInsert[0].append(div);
                        if (chatPopup[0])
                          chatPopup[0].children[0].children[0].append(div);
                      }
                      if (!messageData[minute]) messageData[minute] = [];
                      messageData[minute].push({
                        nowSeconds: nowSeconds,
                        type: 2,
                        currentTime: commandProp.currentTime,
                        user_id: commandProp.userId,
                        html: div.innerHTML,
                      });
                      checkLastSave = false;
                      div = document.createElement("div");
                      div.setAttribute("class", "msg-receives");
                    } else {
                      if (index > 0 && div.innerHTML.length > 0)
                        div.append(document.createElement("br"));
                      let span = document.createElement("span");
                      span.append(commandProp.option.send_handle_name + ":");
                      let messages = commandProp.option.message.split("\n");
                      for (let mi = 0; mi < messages.length; mi++) {
                        if (mi > 0) span.append(document.createElement("br"));
                        span.append(messages[mi]);
                      }
                      div.append(span);
                      checkLastTime = commandProp.currentTime;
                      checkLastSave = true;
                    }
                  }
                }
                if (checkLastSave) {
                  if (!messageData[minute]) messageData[minute] = [];
                  messageData[minute].push({
                    nowSeconds: nowSeconds,
                    type: 2,
                    currentTime: checkLastTime,
                    user_id: null,
                    html: div.innerHTML,
                  });
                  msgInsert[0].append(div);
                  if (chatPopup[0])
                    chatPopup[0].children[0].children[0].append(div);
                }
                data = null;
              }
            } else {
              //1件件表示
              if (data && data.length > 0) {
                let msgInsert: any =
                  document.getElementsByClassName("msg-insert");
                for (let index = 0; index < data.length; index++) {
                  const commandProp = data[index];
                  if (commandProp) {
                    if (!users[commandProp.option.send_user_id])
                      users[commandProp.option.send_user_id] = "user_id";
                    let div = document.createElement("div");
                    div.setAttribute("class", "msg-receives");
                    let span = document.createElement("span");
                    if (commandProp.userId === loginUserId) {
                      span.style.color = "red";
                    }
                    if (commandProp.option.send_handle_name) {
                      span.append(commandProp.option.send_handle_name + ":");
                    } else {
                      span.append(commandProp.option.send_user_name + ":");
                    }
                    let messages = commandProp.option.message.split("\n");
                    for (let mi = 0; mi < messages.length; mi++) {
                      if (mi > 0) span.append(document.createElement("br"));
                      span.append(messages[mi]);
                    }
                    if (
                      !commandProp.userId ||
                      commandProp.userId === loginUserId
                    ) {
                      div.append(span);
                      msgInsert[0].append(div);
                      if (chatPopup[0])
                        chatPopup[0].children[0].children[0].append(div);
                    }
                    if (!messageData[minute]) messageData[minute] = [];
                    messageData[minute].push({
                      nowSeconds: nowSeconds,
                      type: 2,
                      currentTime: commandProp.currentTime,
                      user_id: commandProp.userId ? commandProp.userId : null,
                      html: div.innerHTML,
                    });
                  }
                }
                data = null;
              }
            }
            const userKeys = Object.keys(users);
            for (let index = 0; index < userKeys.length; index++) {
              userStatusChange(userKeys[index], USER_STATUS.CHAT);
              setActionUser(userKeys[index]);
            }
            if (
              msgInsert &&
              msgInsert[0] &&
              msgInsert[0].clientHeight &&
              msgInsert[0].clientHeight > maxHeightMessageDiv
            ) {
              for (let index = 0; index < 10; index++) {
                if (msgInsert[0].children[0]) msgInsert[0].children[0].remove();
                if (msgInsert[0].clientHeight < maxHeightMessageDiv) break;
              }
            }
            if (
              chatPopup &&
              chatPopup[0] &&
              chatPopup[0].clientHeight &&
              chatPopup[0].clientHeight > maxHeightMessageDiv
            ) {
              for (let index = 0; index < 10; index++) {
                if (chatPopup[0].children[0]) chatPopup[0].children[0].remove();
                if (chatPopup[0].clientHeight < maxHeightMessageDiv) break;
              }
            }
            const clist = document.getElementsByClassName("chat-box");
            if (clist && clist.length > 0) {
              clist[0].scrollTop = clist[0].scrollHeight;
            }
            const clist2 = document.getElementsByClassName("chat-popup");
            if (clist2 && clist2.length > 0) {
              clist2[0].scrollTop = clist2[0].scrollHeight;
            }
            if (messageList.length > 0) {
              setTimeout(() => messageDoing(), 1000);
            } else {
              messageFlg = false;
            }
          } catch (err: any) {
            console.log("MessageInsertErr:", err);
            messageFlg = false;
          }
        }, 1000);
      }
    } else {
      setTimeout(() => messageDoing(), 1000);
    }
  };

  const reactionDoing = () => {
    if (!reactionFlg && !messageFlg) {
      if (reactionList.length > 0) {
        reactionFlg = true;
        setTimeout(() => {
          try {
            var data = reactionList;
            reactionList = [];
            if (largeFlg) {
              data = data
                .concat(data)
                .concat(data)
                .concat(data)
                .concat(data)
                .concat(data)
                .concat(data)
                .concat(data);
            }
            var users: any = {};
            var reaction1 = 0;
            var reaction2 = 0;
            var reaction3 = 0;
            let saveTime: any;
            let msgInsert = document.getElementsByClassName("msg-insert");
            let chatPopup = document.getElementsByClassName("chat-popup");
            // if (Users.length > 100) {
            if (Users.length > maxclassUserNum) {
              for (let index = 0; index < data.length; index++) {
                const commandProp = data[index];
                if (commandProp) {
                  users[commandProp.option.send_user_id] =
                    commandProp.option.reaction;
                  saveTime = commandProp.currentTime;
                  if (commandProp.option.reaction === 1) {
                    reaction1++;
                  } else if (commandProp.option.reaction === 2) {
                    reaction2++;
                  } else {
                    reaction3++;
                  }
                  userReactions = [
                    ...userReactions,
                    {
                      time: new Date().getTime(),
                      reaction: commandProp.option.reaction,
                    },
                  ];
                }
              }
              data = null;
              const userKeys = Object.keys(users);
              for (let index = 0; index < userKeys.length; index++) {
                const element = users[userKeys[index]];
                userStatusChange(
                  userKeys[index],
                  element === 1
                    ? USER_STATUS.REACTION_OK
                    : element === 2
                    ? USER_STATUS.REACTION_NG
                    : USER_STATUS.REACTION_HAND
                );
                setActionUser(userKeys[index]);
              }
              let div = document.createElement("div");
              div.setAttribute("class", "msg-receives");
              let span = document.createElement("span");
              if (reaction1 > 0) {
                span.append("🙂 X " + reaction1);
              }
              if (reaction2 > 0) {
                if (reaction1 > 0) span.append(document.createElement("br"));
                span.append("❌ X " + reaction2);
              }
              if (reaction3 > 0) {
                if (reaction1 > 0 || reaction2 > 0)
                  span.append(document.createElement("br"));
                span.append("✋ X " + reaction3);
              }
              div.append(span);
              if (!messageData[minute]) messageData[minute] = [];
              messageData[minute].push({
                nowSeconds: nowSeconds,
                type: 1,
                reaction1: reaction1,
                reaction2: reaction2,
                reaction3: reaction3,
                currentTime: saveTime ? saveTime : null,
                user_id: null,
                html: div.innerHTML,
              });

              msgInsert[0].append(div);
              if (chatPopup[0])
                chatPopup[0].children[0].children[0].append(div);
              if (
                msgInsert &&
                msgInsert[0] &&
                msgInsert[0].clientHeight &&
                msgInsert[0].clientHeight > maxHeightMessageDiv
              ) {
                for (let index = 0; index < 10; index++) {
                  if (msgInsert[0].children[0])
                    msgInsert[0].children[0].remove();
                  if (msgInsert[0].clientHeight < maxHeightMessageDiv) break;
                }
              }
              if (
                chatPopup &&
                chatPopup[0] &&
                chatPopup[0].clientHeight &&
                chatPopup[0].clientHeight > maxHeightMessageDiv
              ) {
                for (let index = 0; index < 10; index++) {
                  if (chatPopup[0].children[0])
                    chatPopup[0].children[0].remove();
                  if (chatPopup[0].clientHeight < maxHeightMessageDiv) break;
                }
              }
            } else {
              for (let index = 0; index < data.length; index++) {
                const commandProp = data[index];
                if (commandProp) {
                  if (!users[commandProp.option.send_user_id])
                    users[commandProp.option.send_user_id] = {
                      name: commandProp.option.send_handle_name,
                      reaction: commandProp.option.reaction,
                      reaction1: 0,
                      reaction2: 0,
                      reaction3: 0,
                    };
                  saveTime = commandProp.currentTime;
                  if (commandProp.option.reaction === 1) {
                    users[commandProp.option.send_user_id].reaction1++;
                    reaction1++;
                  } else if (commandProp.option.reaction === 2) {
                    users[commandProp.option.send_user_id].reaction2++;
                    reaction2++;
                  } else {
                    users[commandProp.option.send_user_id].reaction3++;
                    reaction3++;
                  }

                  userReactions = [
                    ...userReactions,
                    {
                      time: new Date().getTime(),
                      reaction: commandProp.option.reaction,
                    },
                  ];
                }
              }
              data = null;
              let div = document.createElement("div");
              div.setAttribute("class", "msg-receives");
              let span = document.createElement("span");
              // span.append(commandProp.option.send_handle_name + ":");
              const userKeys = Object.keys(users);
              for (let index = 0; index < userKeys.length; index++) {
                const element = users[userKeys[index]];
                userStatusChange(
                  userKeys[index],
                  element.reaction === 1
                    ? USER_STATUS.REACTION_OK
                    : element.reaction === 2
                    ? USER_STATUS.REACTION_NG
                    : USER_STATUS.REACTION_HAND
                );
                setActionUser(userKeys[index]);
                if (index > 0) span.append(document.createElement("br"));
                if (element.reaction1 > 0) {
                  span.append(element.name + ":🙂 X " + element.reaction1);
                }
                if (element.reaction2 > 0) {
                  if (element.reaction1 > 0)
                    span.append(document.createElement("br"));
                  span.append(element.name + ":❌ X " + element.reaction2);
                }
                if (element.reaction3 > 0) {
                  if (element.reaction1 > 0 || element.reaction2 > 0)
                    span.append(document.createElement("br"));
                  span.append(element.name + ":✋ X " + element.reaction3);
                }
              }
              div.append(span);
              if (!messageData[minute]) messageData[minute] = [];
              messageData[minute].push({
                nowSeconds: nowSeconds,
                type: 1,
                reaction1: reaction1,
                reaction2: reaction2,
                reaction3: reaction3,
                currentTime: saveTime ? saveTime : null,
                user_id: null,
                html: div.innerHTML,
              });
              msgInsert[0].append(div);
              if (chatPopup[0])
                chatPopup[0].children[0].children[0].append(div);

              if (
                msgInsert &&
                msgInsert[0] &&
                msgInsert[0].clientHeight &&
                msgInsert[0].clientHeight > maxHeightMessageDiv
              ) {
                for (let index = 0; index < 10; index++) {
                  if (msgInsert[0].children[0])
                    msgInsert[0].children[0].remove();
                  if (msgInsert[0].clientHeight < maxHeightMessageDiv) break;
                }
              }
              if (
                chatPopup &&
                chatPopup[0] &&
                chatPopup[0].clientHeight &&
                chatPopup[0].clientHeight > maxHeightMessageDiv
              ) {
                for (let index = 0; index < 10; index++) {
                  if (chatPopup[0].children[0])
                    chatPopup[0].children[0].remove();
                  if (chatPopup[0].clientHeight < maxHeightMessageDiv) break;
                }
              }
            }
            if (!messageListData[minute])
              messageListData[minute] = {
                reaction1: 0,
                reaction2: 0,
                reaction3: 0,
              };
            messageListData[minute].reaction1 += reaction1;
            messageListData[minute].reaction2 += reaction2;
            messageListData[minute].reaction3 += reaction3;
            let check = false;
            if (operation === OPERATION_TYPE.SHARE) check = true;
            if (!check && operation === OPERATION_TYPE.PEN && drowing)
              check = true;
            if (!check && operation === OPERATION_TYPE.CLEAR && drowing)
              check = true;
            if (!check) {
              const reactionCounts = drawUserReactionCount(userReactions);
              sendAction(actionComand.MSG_REACTION, null, {
                reaction: "",
                reaction_counts: reactionCounts,
              });
            }
            const clist = document.getElementsByClassName("chat-box");
            if (clist && clist.length > 0) {
              clist[0].scrollTop = clist[0].scrollHeight;
            }
            const clist2 = document.getElementsByClassName("chat-popup");
            if (clist2 && clist2.length > 0) {
              clist2[0].scrollTop = clist2[0].scrollHeight;
            }
            if (reactionList.length > 0) {
              setTimeout(() => reactionDoing(), 1000);
            } else {
              reactionFlg = false;
            }
          } catch (err: any) {
            console.log("MessageInsertErr");
            reactionFlg = false;
          }
        }, 1000);
      }
    } else {
      setTimeout(() => reactionDoing(), 1000);
    }
  };

  const reComingCommand = () => {
    if (incomingCommandList.length > 0) {
      let list = incomingCommandList;
      incomingCommandList = [];
      for (let index = 0; index < list.length; index++) {
        setTimeout(() => incomingCommand(list[index]), 100 * Number(index));
      }
    }
  };

  // 受信時処理
  const incomingCommand = (commandProp: any) => {
    if (changeBoardFlg) {
      incomingCommandList.push(commandProp);
      setTimeout(() => incomingCommand("null"), 1000);
    } else {
      if (!commandProp || commandProp === "null") {
        reComingCommand();
        return;
      }
      if (!currentTime) currentTime = commandProp.currentTime;
      switch (commandProp.actionId) {
        // 手書き
        case "MSG_WB_DRAW":
          if (commandProp.option.board_event_type === 1) {
            streamerDrawline(commandProp.option);
          }
          if (commandProp.currentTime >= currentTime) {
            if (
              commandProp.option.board_event_type === 3 ||
              commandProp.option.board_event_type === 4 ||
              commandProp.option.board_event_type === 5 ||
              commandProp.option.board_event_type === 6
            ) {
              let shape_data: any = commandProp.option.shape_data;
              let shape_id: any = commandProp.option.shape_id;
              if (FabCanvas) {
                shape_data.name = shape_id;
                shape_data.globalCompositeOperation = shape_id;
                shape_data.noScaleCache = false;
                shape_data.strokeUniform = true;
                shape_data.selectable = true;
                shape_data.evented = true;
                if (shape_data.type === "line") {
                  let mouse_point: any = commandProp.option.mouse_point;
                  shapeList[shape_id] = new fabric.Line(
                    [
                      mouse_point.px,
                      mouse_point.py,
                      mouse_point.x,
                      mouse_point.y,
                    ],
                    shape_data
                  );
                }
                if (shape_data.type === "rect") {
                  shapeList[shape_id] = new fabric.Rect(shape_data);
                }
                if (shape_data.type === "circle") {
                  shapeList[shape_id] = new fabric.Circle(shape_data);
                }
                if (shape_data.type === "triangle") {
                  shapeList[shape_id] = new fabric.Triangle(shape_data);
                }
                if (shapeList[shape_id]) {
                  FabCanvas.add(shapeList[shape_id]);
                  shapeList[shape_id].set({ name: shape_id });
                }
                changeJson();
              }
            }
            if (commandProp.option.board_event_type === 8) {
              let brush_id = commandProp.option.brush_id;
              if (brush_id && brush_id.length > 0) {
                for (let index = 0; index < brush_id.length; index++) {
                  const id = brush_id[index];
                  if (lineList[id]) {
                    FabCanvas.remove(lineList[id]);
                    delete lineList[id];
                  }
                  if (shapeList[id]) {
                    FabCanvas.remove(shapeList[id]);
                    delete shapeList[id];
                  }
                }
                changeJson();
              }
            }
            if (commandProp.option.board_event_type === 9) {
              let shape_id = commandProp.option.shape_id;
              let activeObjects = commandProp.option.shape_data;
              activeObjects.globalCompositeOperation = shape_id;
              activeObjects.selectable = true;
              activeObjects.evented = true;
              if (shapeList[shape_id]) {
                if (shapeList[shape_id].type === "line") {
                  FabCanvas.remove(shapeList[shape_id]);
                  shapeList[shape_id] = new fabric.Line(
                    [
                      activeObjects.x1,
                      activeObjects.y1,
                      activeObjects.x2,
                      activeObjects.y2,
                    ],
                    activeObjects
                  );
                  FabCanvas.add(shapeList[shape_id]);
                } else {
                  shapeList[shape_id].set(activeObjects);
                }
                FabCanvas.renderAll();
                changeJson();
              }
            }
            if (commandProp.option.board_event_type === 10) {
              let clear_uesr_id = commandProp.option.clear_uesr_id;
              if (clear_uesr_id) {
                Object.keys(lineList).forEach((key: string) => {
                  if (key.indexOf(clear_uesr_id) >= 0) {
                    FabCanvas.remove(lineList[key]);
                    delete lineList[key];
                  }
                });
                Object.keys(shapeList).forEach((key: string) => {
                  if (key.indexOf(clear_uesr_id) >= 0) {
                    FabCanvas.remove(shapeList[key]);
                    delete shapeList[key];
                  }
                });
                changeJson();
              }
            }

            currentTime = commandProp.currentTime;
          }
          break;

        // 授業参加依頼結果
        case "MSG_JOIN_RESULT":
          let newRequestList;
          switch (commandProp.option.status) {
            case 1:
              joinUpdete(commandProp.option, true);
              break;
            case 4:
              joinUpdete(commandProp.option, true);
              setReplyType({
                replyType: commandProp.option.reply_type,
                status: commandProp.option.status,
                userName: "",
              });
              setJoinLecDialogOpen(true);
              newRequestList = requestList.filter(
                (item: any) =>
                  !(
                    item.userId === commandProp.option.send_user_id &&
                    item.requestId === commandProp.option.reply_type
                  )
              );
              requestList = newRequestList;
              setRequestUser(newRequestList);
              break;
            case 5:
              joinUpdete(commandProp.option, true);
              setUploadFlg(true);
              newRequestList = requestList.filter(
                (item: any) =>
                  !(
                    item.userId === commandProp.option.send_user_id &&
                    item.requestId === commandProp.option.reply_type
                  )
              );
              requestList = newRequestList;
              setRequestUser(newRequestList);

              getOrderList(false);
              break;
            default:
              const found = Users.find(
                (element) => element.user_id === commandProp.option.send_user_id
              );
              setReplyType({
                replyType: commandProp.option.reply_type,
                status: commandProp.option.status,
                userName: found?.handle_name,
              });
              setJoinLecDialogOpen(true);

              newRequestList = requestList.filter(
                (item: any) =>
                  !(
                    item.userId === commandProp.option.send_user_id &&
                    item.requestId === commandProp.option.reply_type
                  )
              );
              requestList = newRequestList;
              setRequestUser(newRequestList);
          }
          setActionUser(commandProp.option.send_user_id);
          break;

        // チャット
        case "MSG_CHAT_SEND_MESSAGE":
          if (commandProp.option.send_to_class !== 2) {
            messageList.push(commandProp);
            messageDoing();
            LastChatReceiveTime = new Date().getTime();
          }
          break;

        // 択一問題
        case "MSG_QUIZ_ANSWER":
          if (commandProp.option.answer_id !== null) {
            commandProp.option.answer_no = commandProp.option.answer_id;
            setAnswerCatch(answerCatch + 1);
            for (let i = 0; i < showAnswer.length; i++) {
              if (showAnswer[i].answer_no === commandProp.option.answer_no) {
                showAnswer[i].count = showAnswer[i].count + 1;
              }
              showAnswer[i].percent =
                showAnswer[i].count !== null
                  ? Math.round((showAnswer[i].count / answerCatch) * 100 * 10) /
                    10
                  : 0;
            }
            setResult(showAnswer);
            const state =
              commandProp.option.answer_no === 1
                ? USER_STATUS.CHOICE_1
                : commandProp.option.answer_no === 2
                ? USER_STATUS.CHOICE_2
                : commandProp.option.answer_no === 3
                ? USER_STATUS.CHOICE_3
                : commandProp.option.answer_no === 4
                ? USER_STATUS.CHOICE_4
                : commandProp.option.answer_no === 5
                ? USER_STATUS.CHOICE_5
                : commandProp.option.answer_no === 6
                ? USER_STATUS.CHOICE_6
                : commandProp.option.answer_no === 7
                ? USER_STATUS.CHOICE_7
                : commandProp.option.answer_no === 8
                ? USER_STATUS.CHOICE_8
                : commandProp.option.answer_no === 9
                ? USER_STATUS.CHOICE_9
                : USER_STATUS.CHOICE_10;
            userStatusChange(commandProp.option.send_user_id, state);
            setActionUser(commandProp.option.send_user_id);
          }
          break;

        case "MSG_REACTION":
          reactionList.push(commandProp);
          reactionDoing();
          LastChatReceiveTime = new Date().getTime();
          break;

        case "MSG_WB_REQUEST":
          // uplodeJson(commandProp.option.send_user_id,selectBoardId );
          if (commandProp.option.send_user_id) {
            let whiteBoard = document.getElementsByClassName(
              "whiteBoard"
            ) as HTMLCollectionOf<HTMLElement>;
            const checkKeys = Object.keys(messageListData);
            let chat_no = minute;
            for (var a = Number(minute); a >= 0; a--) {
              if (checkKeys.indexOf(String(a)) >= 0) {
                chat_no = a;
                break;
              }
            }
            const lyout = !videoLyoutSetting ? 1 : !boardLyoutSetting ? 3 : 2;
            sendAction(
              actionComand.MSG_WB_RESULT,
              commandProp.option.send_user_id,
              {
                board_no: selectBoardId,
                send_user_id: loginUserId,
                send_user_name: userName,
                scale: cscale,
                move_point: movePoint,
                width: whiteBoard[0].style.width,
                height: whiteBoard[0].style.height,
                vl_setting: VLSetting.current,
                bl_setting: BLSetting.current,
                img_no: selectImgNo,
                class_user_num: Users.length,
                chat_no: chat_no,
                chat_data:
                  messageData[minute] && messageData[minute].length > 0
                    ? messageData[minute].slice(
                        messageListData.tmp &&
                          messageListData.tmp.chat_data &&
                          messageListData.tmp.chat_data.length > 0
                          ? messageListData.tmp.chat_data.length - 1
                          : 0
                      )
                    : [],
                layout_type: lyout,
                chat_send_setting: chatSendState,
                chat_display_setting: chatdisplayState,
                uid: agoraUserId,
                extraTime: befClassTime,
                startDate: startDate,
                join_start_time: joinStartTime,
              }
            );
          }
          userStatusChange(
            commandProp.option.send_user_id,
            USER_STATUS.JOIN,
            commandProp.option.send_user_agoraid
          );
          setActionUser(commandProp.option.send_user_id);
          break;
        // case "MSG_SETTNG_REQUEST":
        //   const lyout = !videoLyoutSetting ? 1 : !boardLyoutSetting ? 3 : 2;
        //   sendAction(
        //     actionComand.MSG_SETTING_RESULT,
        //     commandProp.option.send_user_id,
        //     {
        //       layout_type: lyout,
        //       chat_send_setting: chatSendState,
        //       chat_display_setting: chatdisplayState,
        //       send_user_id: loginUserId,
        //       send_user_name: userName,
        //       uid: agoraUserId,
        //     }
        //   );
        //   break;
        case "MSG_CLASS_EXIT":
          userStatusChange(commandProp.option.send_user_id, USER_STATUS.EXIT);
          joinUpdete(commandProp.option, true);
          const newRequestList2 = requestList.filter(
            (item: any) => item.userId !== commandProp.option.send_user_id
          );
          requestList = newRequestList2;
          setRequestUser(newRequestList2);

          break;
        // case "MSG_AGORAID":
        //   userStatusChange(
        //     commandProp.option.send_user_id,
        //     USER_STATUS.JOIN,
        //     commandProp.option.send_user_agoraid
        //   );
      }
    }
  };

  const sendQuestionResult = () => {
    if (questionResFlg) {
      if (answerCatch !== sentAnswerCatch) {
        setSentAnswerCatch(answerCatch);
        sendAction(actionComand.MSG_QUIZ_QUESTIONS, null, {
          answer_options: showAnswer,
          choice_id: props.choiceId,
        });
      }
      setTimeout(sendQuestionResult, 2000);
    }
  };

  const sessionCheck = async () => {
    const sessionInterval = setInterval(() => {
      getSessionUser().then((result: any) => {
        if (result !== false) {
          SessionGetTime = new Date().getTime();
        } else {
          agoraLeave(false);
        }
        let path = store.getState().router.location.pathname;
        if (path !== "/cls/008") {
          clearInterval(sessionInterval);
        }
      });
    }, 600000);
  };

  const winResize = () => {
    let window_H = document.getElementsByTagName("body")[0].offsetHeight;
    let window_W = document.getElementsByTagName("body")[0].offsetWidth;
    let whiteBoard = document.getElementsByClassName(
      "whiteBoard"
    ) as HTMLCollectionOf<HTMLElement>;
    // let tableRight = document.getElementsByClassName(
    //   "tableRight"
    // ) as HTMLCollectionOf<HTMLElement>;
    // let videoMenu = document.getElementsByClassName(
    //   "videoMenu"
    // ) as HTMLCollectionOf<HTMLElement>;
    let guideMessage = document.getElementsByClassName(
      "guideMsg"
    ) as HTMLCollectionOf<HTMLElement>;
    let rightT = document.getElementsByClassName(
      "tableRight"
    ) as HTMLCollectionOf<HTMLElement>;
    let nw = 0;
    if (screenIsSmall && screenSmall) {
      // if (window_H > window_W) {
      // whiteBoard[0].style.width = "100%";
      // whiteBoard[0].style.height = "100%";
      //   nw = window.innerWidth - 10;
      // } else {
      let videoW = window_W - 60;
      nw = Math.ceil((window_H - 105) * (16 / 9));
      if (nw > videoW) {
        nw = videoW;
      }
      // }
      whiteBoard[0].style.height = nw / (16 / 9) + "px";
      whiteBoard[0].style.width = nw + "px";
      // videoMenu[0].style.top = nw / (16 / 9) + 40 + "px";
      // videoMenu[0].style.width = whiteBoard[0].style.width;
      guideMessage[0].style.top = nw / 2 / (16 / 9) + 10 + "px";
      guideMessage[0].style.left =
        (window_W - Number(rightT[0].style.width.replace("px", ""))) / 2 -
        50 +
        "px";
    } else {
      // if (window.innerHeight > window.innerWidth) {
      //   setAllWidth(true);

      //   //whiteBoard[0].style.width = "100%";
      //   // whiteBoard[0].style.height = "100%";
      //   nw = window.innerWidth - 10;
      //   whiteBoard[0].style.width = nw + "px";
      //   whiteBoard[0].style.height = nw / (16 / 9) + 10 + "px";
      //   videoMenu[0].style.top = nw / (16 / 9) + 40 + "px";
      //   videoMenu[0].style.width = whiteBoard[0].style.width;
      //   guideMessage[0].style.top = nw / 2 / (16 / 9) + 10 + "px";
      //   guideMessage[0].style.left =
      //     (window_W - Number(rightT[0].style.width.replace("px", ""))) / 2 -
      //     50 +
      //     "px";
      //   let smallTableLeft = document.getElementsByClassName(
      //     "small-table-left"
      //   ) as HTMLCollectionOf<HTMLElement>;

      //   if (smallTableLeft.length > 0) {
      //     let nh = Math.ceil(window.innerHeight - tableRight[0].offsetHeight);
      //     smallTableLeft[0].style.height = nh + "px";
      //   }
      // } else {
      setAllWidth(false);

      let leftTable = document.getElementById("left_table");
      let videoW = window_W - (leftTable ? leftTable.offsetWidth : 0) - 20;
      let nw = Math.ceil((window_H - 105) * (16 / 9));

      if (nw > videoW) {
        nw = videoW;
      }
      whiteBoard[0].style.width = nw + "px";
      whiteBoard[0].style.height = nw / (16 / 9) + "px";
      // videoMenu[0].style.top = nw / (16 / 9) + 40 + "px";
      // videoMenu[0].style.width = whiteBoard[0].style.width;
      guideMessage[0].style.top = nw / 2 / (16 / 9) + 10 + "px";
      guideMessage[0].style.left =
        (window_W - Number(rightT[0].style.width.replace("px", ""))) / 2 -
        50 +
        "px";
      // }
    }
    let header1 = document.getElementById("header1");
    let header2 = document.getElementById("header2");
    let header3 = document.getElementById("header3");
    let header4 = document.getElementById("header4");
    let header5 = document.getElementById("header5");
    if (
      header1 !== null &&
      header2 !== null &&
      header3 !== null &&
      header4 !== null &&
      header5 !== null
    ) {
      const headerWidth =
        window_W -
        header1?.clientWidth -
        header2?.clientWidth -
        header3?.clientWidth -
        header4?.clientWidth -
        header5?.clientWidth -
        200;
      setClassNameWidth(headerWidth);
    }

    var width = whiteBoard[0].style.width.replace("px", "");
    var height = whiteBoard[0].style.height.replace("px", "");
    setCanvasWide(width);
    setCanvasHeight(height);
    canvasData = { w: width, h: height };
    let upperCanvas = document.getElementsByClassName(
      "upper-canvas"
    ) as HTMLCollectionOf<HTMLElement>;
    let canvasContainer = document.getElementsByClassName(
      "canvas-container"
    ) as HTMLCollectionOf<HTMLElement>;
    if (upperCanvas.length > 0) {
      upperCanvas[0].style.width = width + "px";
      upperCanvas[0].style.height = height + "px";
      canvasContainer[0].style.width = width + "px";
      canvasContainer[0].style.height = height + "px";
      ctx.canvas.width = width;
      ctx.canvas.height = height;
      FabCanvas.setWidth(Number(width));
      FabCanvas.setHeight(Number(height));
      // GraphFabCanvas.setWidth(Number(width));
      // GraphFabCanvas.setHeight(Number(height));
      // CopyFabCanvas.setWidth(
      //   Number(whiteBoard[0].style.width.replace("px", ""))
      // );
      // CopyFabCanvas.setHeight(
      //   Number(whiteBoard[0].style.height.replace("px", ""))
      // );

      let zoon = Number(width) / defaultWidth;
      zoom = Math.abs(zoon * 100) / 100;
      FabCanvas.setZoom(zoom < 0.1 ? 0.1 : zoom);
      // GraphFabCanvas.setZoom(zoom < 0.1 ? 0.1 : zoom);
      // CopyFabCanvas.setZoom(zoom < 0.1 ? 0.1 : zoom);
      // if (selectImgUrl) {
      //   drawImg(selectImgUrl);
      // } else {
      //   deleteImg();
      // }
      // if (Number(cscale) > 1) {
      //   changeScale(cscale, () => {
      //     if (movePoint && movePoint.scrollTop && movePoint.scrollLeft) {
      //       // drowCanvasDiv.scrollTop = movePoint.scrollTop * maxDivScrollTop;
      //       // drowCanvasDiv.scrollLeft = movePoint.scrollLeft * maxDivScrollLeft;
      //       // imgCanvasDiv.scrollTop = movePoint.scrollTop * maxDivScrollTop;
      //       // imgCanvasDiv.scrollLeft = movePoint.scrollLeft * maxDivScrollLeft;
      //     }
      //   });
      // }
      let chatBox = document.getElementsByClassName(
        "chat-box"
      ) as HTMLCollectionOf<HTMLElement>;
      let chatAvatar = document.getElementsByClassName(
        "chat-Avatar"
      ) as HTMLCollectionOf<HTMLElement>;
      chatAvatar[0].style.height = "100px";
      let live_header = document.getElementsByClassName(
        "live_header"
      ) as HTMLCollectionOf<HTMLElement>;
      let other_send_box: any = document.getElementById("other_send_box");
      let agora_view_div: any = document.getElementById("agora_view_div");

      let other_H =
        Number(live_header[0].offsetHeight) +
        Number(other_send_box.offsetHeight) +
        Number(agora_view_div.offsetHeight) + 2;

      if (window_H - other_H > 50) {
        chatBox[0].style.height = window_H - other_H + "px";
      } else {
        chatBox[0].style.height = "50px";
        var cah = 170 + (window_H - other_H);
        if (cah > 50) {
          chatAvatar[0].style.height = cah + "px";
        } else {
          chatAvatar[0].style.height = "50px";
        }
      }
    }
  };

  const getDateTime = () => {
    let nt = new Date();
    return (
      nt.getFullYear() +
      "/" +
      ("00" + (nt.getMonth() + 1)).slice(-2) +
      "/" +
      ("00" + nt.getDate()).slice(-2) +
      " " +
      ("00" + nt.getHours()).slice(-2) +
      ":" +
      ("00" + nt.getMinutes()).slice(-2) +
      ":" +
      ("00" + nt.getSeconds()).slice(-2)
    );
  };

  // 視聴ログ更新
  const registViewLog = async (minute: number, lecFlg: string) => {
    ST0311(
      dispatch,
      classId,
      minute + 1,
      getDateTime(),
      "0",
      lecFlg,
      "",
      process.env.REACT_APP_AGORA_REC_FLG === "1" ||
        process.env.REACT_APP_AGORA_REC_FLG === "2"
        ? "1"
        : "2"
    );
    lastViewLog = minute;
  };

  const getClassData = async (classId: any) => {
    myuserData = await getSessionUser();
    if (myuserData !== false) {
      loginUserId = myuserData.user_id;
      S3 = await createS3();
    } else {
      myuserData = await getSessionUser();
    }
    let getData = await LC0302(dispatch, classId, false, true);
    if (!getData) {
      getData = await LC0302(dispatch, classId, false, true);
    }
    if (getData && Object.keys(getData.body).indexOf("class_name") >= 0) {
      let classTimeCount;
      let checkClassId = localStorage.getItem("Cls008_classId");
      if (
        props &&
        props.location &&
        props.location.state &&
        typeof props.location.state !== "undefined"
      ) {
        classTimeCount = 0;
        classStartTime = getData.body.start_date;
      } else if (checkClassId && checkClassId === classId) {
        classTimeCount = localStorage.getItem("classTimeCount");
        classStartTime = localStorage.getItem("classStartTime");
        if (!classStartTime) {
          classStartTime = getData.body.start_date;
        }
      }
      if (classStartTime) classStartTime = classStartTime.slice(0, 19);
      retryFlg = getData.body.class_time > 0 ? true : false;

      if (classTimeCount) {
        befClassTime = Number(classTimeCount);
        // nowSeconds = befClassTime;
        minute = Math.floor(befClassTime / 60);
        befClassTime = minute * 60;
        nowSeconds = minute * 60;
        setClassTime(timeFoemat(nowSeconds));
      } else if (getData.body.class_time) {
        var classTime = Number(getData.body.class_time);
        if (classTime > 0) classTime--;
        befClassTime = classTime * 60;
        nowSeconds = befClassTime;
        minute = Math.floor(nowSeconds / 60);
        setClassTime(timeFoemat(nowSeconds));
      } else {
        befClassTime = 0;
        nowSeconds = 0;
        minute = 0;
      }

      classInfo.className = getData.body.class_name;
      classInfo.channelId = getData.body.channel_id;
      Promise.all([
        getAgoraJoinData(getData.body.channel_id),
        updateStatus(classStatus),
      ]);
    } else {
      setLoading(false);
    }
    S3 = await createS3();
    let file: any;
    // if (S3) {
    //   file = await getFileS3(
    //     S3,
    //     "haishin/" +
    //       myuserData.service_id +
    //       "/" +
    //       classId +
    //       "/userList/userList.json"
    //   );
    // }
    if (getData && Object.keys(getData.body).indexOf("user") >= 0) {
      let user: any = [];
      let UserJsonList: any = [];
      let agoraUid = 20000;
      for (let i = 0; i < getData.body.user.length; i++) {
        agoraUid++;
        user[i] = {
          user_id: getData.body.user[i].user_id,
          user_name: getData.body.user[i].user_name,
          handle_name: getData.body.user[i].handle_name,
          status: USER_STATUS.EXIT,
          lastActionTime: 0,
          user_agoraid: agoraUid,
        };
        UserJsonList.push({
          userId: getData.body.user[i].user_id,
          AgoraId: agoraUid,
        });
        /*actionUserList.push({
          userId: getData.body.user[i].user_id,
          minute: 0,
        });*/
      }
      const jsonData = JSON.stringify(UserJsonList);
      var blob = new Blob([jsonData], { type: "text/json" });
      var jsonFile = new File([blob], "userList.json");

      if (S3 && !file) {
        deleteS3(
          S3,
          "haishin/" +
            myuserData.service_id +
            "/" +
            classId +
            "/userList/userList.json"
        ).then(() => {
          uploadS3(
            S3,
            "haishin/" +
              myuserData.service_id +
              "/" +
              classId +
              "/userList/userList.json",
            jsonFile
          );
        });
      }
      setUserData(user);
      Users = user;
      setUserItem(Users);
      getViewUser();
    }
  };

  // const userJoin = (agorauser: any) => {
  //   // const joinUser = Users?.find(
  //   //   (element) => element.user_agoraid === agorauser.uid
  //   // );
  //   let uid = String(agorauser).slice(0,-1);
  //   const joinUser = Users?.find(
  //     (element) => element.user_id === uid
  //   );
  //   if (joinUser) {
  //     userStatusChange(joinUser?.user_id, USER_STATUS.JOIN);
  //   }
  // };

  // const userLeft = (agorauser: any) => {
  //   // let uid = agorauser.uid;
  //   setTimeout(() => {
  //     // let check = client.remoteUsers.find((a: any) => uid === a.uid);
  //     // const leftUser = Users?.find(
  //     //   (element) => element.user_agoraid === agorauser.uid
  //     // );
  //     // if (!check) {
  //     //   if (leftUser) {
  //     //     userStatusChange(leftUser?.user_id, USER_STATUS.AGORAEXIT);
  //     //   }
  //     // }
  //     let uid = String(agorauser).slice(0,-1);
  //     const leftUser = Users?.find(
  //       (element) => element.user_id === uid
  //     );
  //     if (leftUser) {
  //       userStatusChange(leftUser?.user_id, USER_STATUS.AGORAEXIT);
  //       joinUpdete({ send_user_id: leftUser?.user_id }, true);
  //       const newRequestList = requestList.filter(
  //         (item: any) => !(item.userId === leftUser?.user_id)
  //       );
  //       requestList = newRequestList;
  //       setRequestUser(newRequestList);
  //     }
  //   }, 30000);
  // };

  const getViewUser = async () => {
    let getData = await ST0318(
      dispatch,
      classId,
      process.env.REACT_APP_AGORA_REC_FLG === "1" ||
        process.env.REACT_APP_AGORA_REC_FLG === "2"
        ? "1"
        : "2"
    );
    if (getData && getData.body.length >= 0) {
      joinList = getData.body.filter(
        (element: any) => element.slice(-1) !== "3"
      );
      const stg = myuserData.service_id + "_" + classId;
      for (let i = 0; i < Users.length; i++) {
        const userId = Users[i].user_id;
        const find = joinList.find(
          (element: any) => element === stg + userId + 4
        );
        if (!find) {
          Users[i].status = USER_STATUS.EXIT;
        } else {
          if (Users[i].status === USER_STATUS.EXIT) {
            Users[i].status = USER_STATUS.JOIN;
          }
        }
      }
      setUserItem(Users);
    }
    getViewUserTimeout = setTimeout(getViewUser, 60000);
  };

  const getRTCUser = async () => {
    let user: any = await apiAgoraUserCheck(
      agoraInfo.app_id + "/" + classInfo.channelId
    );
    if (user && user.data && user.data.success) {
      if (user.data.data.audience && user.data.data.broadcasters) {
        if (Users.length > 0) {
          for (let i = 0; i < Users.length; i++) {
            if (
              user.data.data.audience.indexOf(Users[i].user_agoraid) >= 0 ||
              user.data.data.broadcasters.indexOf(Users[i].user_agoraid) >= 0
            ) {
              if (Users[i].status === USER_STATUS.EXIT)
                Users[i].status = USER_STATUS.JOIN;
            } else {
              Users[i].status = USER_STATUS.EXIT;
              // setUserItem(Users);
            }
          }
          setUserItem(Users);
        }
      }
    }
    getRTCUserTimeout = setTimeout(getRTCUser, 5000);
  };

  const userStatusChange = (
    userid: string,
    status: string,
    useragoraid?: any
  ) => {
    const Index = Users.findIndex((element) => element.user_id === userid);
    const stg = myuserData.service_id + "_" + classId;
    const user = Users[Index];
    const leftUser = joinList?.find(
      (element: any) => element === stg + user.user_id + 4
    );
    if (leftUser && status === USER_STATUS.AGORAEXIT) {
      return;
    } else if (!leftUser && status === USER_STATUS.AGORAEXIT) {
      Users[Index].status = USER_STATUS.EXIT;
    } else {
      Users[Index].status = status;
    }
    const date = new Date();
    Users[Index].lastActionTime = date.setTime(date.getTime() + 5 * 60 * 1000);

    UserData = Users;
    userCount = UserData.filter(
      (element) => element.status !== USER_STATUS.EXIT
    ).length;
    setJoinUserCount(userCount);
    setUserItem(UserData);
  };

  const setActionUser = async (userId: string) => {
    const find = Users.findIndex((element) => element.user_id === userId);
    if (find !== -1) {
      const date = new Date();
      Users[find].lastActionTime = date.setTime(date.getTime() + 5 * 60 * 1000);
    }
  };

  const [userItems, setUserItems] = React.useState<any[]>();

  const setUserItem = (UserData: any) => {
    const items: any = [];
    for (let i = 0; i < UserData.length; i++) {
      if (UserData[i].user_id) {
        const text =
          "ID:" +
          UserData[i].user_id +
          " \n名前:" +
          UserData[i].user_name +
          " \nハンドルネーム:" +
          UserData[i].handle_name;
        const breakedText = text.split("\n").map((line, key) => (
          <span key={"breaked_text_" + i + "_" + key}>
            {line}
            <br />
          </span>
        ));
        let color;
        switch (UserData[i].status) {
          case USER_STATUS.EXIT:
            color = "#c8c8cb";
            break;
          case USER_STATUS.JOIN:
            color = "#ffff80";
            break;
          case USER_STATUS.CHAT:
            color = "#d8f255";
            break;
          case USER_STATUS.REACTION_OK:
            color = "#005aff";
            break;
          case USER_STATUS.REACTION_NG:
            color = "#ff4b00";
            break;
          case USER_STATUS.REACTION_HAND:
            color = "#03af7a";
            break;
          case USER_STATUS.CHOICE_1:
            color = "#78e00b";
            break;
          case USER_STATUS.CHOICE_2:
            color = "#26d4e0";
            break;
          case USER_STATUS.CHOICE_3:
            color = "#e0b501";
            break;
          case USER_STATUS.CHOICE_4:
            color = "#c504e0";
            break;
          case USER_STATUS.CHOICE_5:
            color = "#e0610b";
            break;
          case USER_STATUS.CHOICE_6:
            color = "#ace072";
            break;
          case USER_STATUS.CHOICE_7:
            color = "#8ddbe0";
            break;
          case USER_STATUS.CHOICE_8:
            color = "#e0c868";
            break;
          case USER_STATUS.CHOICE_9:
            color = "#c45dd4";
            break;
          case USER_STATUS.CHOICE_10:
            color = "#e09f72";
            break;
          case USER_STATUS.NOACTION:
            color = "#84919e";
            break;
        }

        items.push(
          <div key={"user_list_" + i}>
            <LightTooltip title={breakedText} placement="top">
              <PersonIcon
                style={{
                  color: color,
                  fontSize: " 18px",
                }}
              />
            </LightTooltip>
          </div>
        );
      }
    }
    setUserItems(items);
  };

  //const getQuestionList = async () => {
  //  let getData = await QZ0301(dispatch, classId);
  //  if (getData && getData.state === "0") {
  //    const answers = getData.body;
  //    let i = 0;
  //    let keyArray = Object.keys(answers);
  //    keyArray.forEach(function (element) {
  //      questionLists[i].answer = answers[element];
  //      i++;
  //    });
  //  }
  //};
  // const saveQuestJson = async (choiceId: any, flg: any) => {
  //   let s3: any = await createS3();
  //   const jsonData = JSON.stringify({ choiceId: choiceId, display: flg });
  //   var blob = new Blob([jsonData], { type: "text/json" });
  //   var jsonFile = new File([blob], "quest.json");
  //   if (s3) {
  //     await uploadS3(
  //       s3,
  //       "haishin/" +
  //         myuserData.service_id +
  //         "/" +
  //         classId +
  //         "/QuestJson/quest.json",
  //       jsonFile
  //     );
  //   }
  // };

  const setQuestionList = async (answerList: any, option: any) => {
    const dateNow = new Date();
    let answer_options: any = {};
    console.log("option", option);
    for (let index = 0; index < option.answer_options.length; index++) {
      const element = option.answer_options[index];
      answer_options[element.answer_no] = { answer: element.answer };
    }
    console.log("answer_options", answer_options);
    let getData = await QZ0302(dispatch, classId, dateNow, answer_options);
    if (getData && getData.state === "0") {
      choiceId = Number(getData.body.choice_id);
      option.choice_id = choiceId;
      questionResFlg = true;
      // saveQuestJson(choiceId, true);
      setTimeout(sendQuestionResult, 2000);
      sendAction(ActionComand.MSG_QUIZ_QUESTIONS, null, option);
      setLog(choiceId, null, "4");
      questionLists = answerList;
      //getQuestionList();
      return;
    } else {
      //エラー処理
      alert(errMsg4);
    }
  };

  const getQuestionResultList = async () => {
    let getData = await QZ0303(dispatch, classId, choiceId);
    if (getData && getData.state === "0") {
      return getData.body;
    } else if (retryCount <= 2) {
      retryCount++;
      setTimeout(getQuestionResultList, 1000);
    } else {
      retryCount = 0;
      return false;
    }
  };

  const updateStatus = async (status: string) => {
    if (status === "5") {
      setJoinButtonState(status === "5" ? false : true);

      playChime(true);
      setTimeout(() => {
        clearInterval(ViewLogInterval);
        const lastminute = Math.ceil((nowSeconds + 5) / 60);
        classTimeAddFlg = false;
        if (lastViewLog !== lastminute) {
          registViewLog(lastminute, "1");
        }
      }, 10000);
      setTimeout(() => {
        setRecordend(true);
        agoraLeave(true);
      }, 15000);
    }
    if (status === "3") {
      if (!befClassIntervalFlg) {
        befClassIntervalFlg = true;
        updateElapsedTimeBefClass();
        befClassInterval = setInterval(() => {
          let path = store.getState().router.location.pathname;
          if (path !== "/cls/008") {
            clearInterval(befClassInterval);
          }
          updateElapsedTimeBefClass();
        }, 60000);
      }
    } else {
      clearInterval(befClassInterval);
    }

    const getStatus = async (status: any) => {
      let getResult;
      if (status === "4" && !classStartTime) {
        var str =
          startDateTime.getFullYear() +
          "/" +
          ("0" + (startDateTime.getMonth() + 1)).slice(-2) +
          "/" +
          ("0" + startDateTime.getDate()).slice(-2) +
          " " +
          ("0" + startDateTime.getHours()).slice(-2) +
          ":" +
          ("0" + startDateTime.getMinutes()).slice(-2) +
          ":" +
          ("0" + startDateTime.getSeconds()).slice(-2);
        localStorage.setItem("classStartTime", str);
        getResult = await ST0301(dispatch, classId, status, str);
      } else if (status === "4" && classStartTime) {
        getResult = await ST0301(dispatch, classId, status);
      } else {
        getResult = await ST0301(dispatch, classId, status);
      }
      if (getResult /* && getResult.state === "0"*/) {
        setClassStatus(status);
      } else {
        setTimeout(() => getStatus(status), 1000);
      }
    };
    getStatus(status);
  };

  const [joinButtonState, setJoinButtonState] = React.useState(false);
  const getAgoraJoinData = async (channelId: string) => {
    SessionGetTime = new Date().getTime();
    if (myuserData) {
      userName = myuserData.user_name;
      let getData = await ST0302(
        dispatch,
        channelId,
        myuserData.user_id + myuserData.user_class
      );
      if (getData /*.state === "0"*/) {
        agoraInfo = getData.body;
        // setAgoraInfo(getData.body);
        if (selectCamera === "" && selectMic === "") {
          setTimeout(getDevice, 1000);
        }
      }
    }
  };
  const [retryErrorOpen, setRetryErrorOpen] = React.useState(false);
  const getDevice = () => {
    setDevice(undefined, deviceConfig).then((device: any) => {
      if (device.camTrack && device.micTrack) {
        setSelectCamera(device.camTrack._deviceName);
        setSelectMic(device.micTrack._deviceName);
        setJoinButtonState(true);
        setTimeout(setLoading, 1000, false);
        checkVideoFlg = true;
        if (localVideoTrackFlg) {
          if (!micStatus) device.micTrack?.setMuted(false);
          publish(device.micTrack, device.camTrack).then(() => {
            localVideoTrackFlg = false;
            if (!micStatus)
              sendAction(actionComand.MSG_DISPLAY_CLICK, null, {});
            device.camTrack.on("track-ended", () => {
              console.log("getDevice camTrack track-ended");
              unpublish(() => {
                localVideoTrackFlg = true;
                checkVideoFlg = false;
                setRetryErrorOpen(true);
              });
            });
            device.micTrack.on("track-ended", () => {
              console.log("getDevice micTrack track-ended");
              unpublish(() => {
                localVideoTrackFlg = true;
                checkVideoFlg = false;
                setRetryErrorOpen(true);
              });
            });
          });
        }
      } else if (retryCount <= 5) {
        retryCount++;
        setTimeout(getDevice, 2000);
      } else {
        retryCount = 0;
        setRetryErrorOpen(true);
      }
    });
  };

  const closeRetryErrorDialg = () => {
    setRetryErrorOpen(false);
  };

  const slectAgoraJoin = () => {
    const camfound = cameraList?.find(
      (element) => element.label === selectCamera
    );
    const micfound = micList?.find((element) => element.label === selectMic);
    const micconfig = micfound?.deviceId;
    const camconfig = camfound?.deviceId;
    deviceConfig = { micconfig, camconfig };
    console.log("camfound", camfound);
    console.log("micfound", micfound);
    console.log("localVideoTrackFlg", localVideoTrackFlg);
    getDevice();
    closeRetryErrorDialg();
  };

  const setResorceId = async (resorceId: string) => {
    let getResult = await ST0304(dispatch, classId, resorceId);
    if (getResult && getResult.state === "0") {
      return;
    }
  };

  const chengVideoType = async (count: number) => {
    const flg = browserType === "Safari" ? true : false;

    let getResult = await ST0305(dispatch, classId, count, flg);
    if (getResult && getResult.state === "0") {
      return;
    }
  };

  const updateElapsedTime = async (minute: number) => {
    let getResult = await ST0307(dispatch, classId, minute, "1");
    if (getResult && getResult.state === "0") {
      return;
    } else {
      //エラー処理
    }
  };

  const updateElapsedTimeBefClass = async () => {
    let getResult = await ST0307(dispatch, classId, 0, "0");
    if (getResult && getResult.state === "0") {
      return;
    } else {
      //エラー処理
    }
  };

  const getOrderList = async (flg: boolean, callback?: any) => {
    if (!S3) S3 = await createS3();
    let file: any = await getFileS3(
      S3,
      "haishin/" +
        myuserData.service_id +
        "/" +
        classId +
        "/doc/" +
        "order.json"
    );
    if (file && file.size > 0) {
      let reader = new FileReader();
      reader.readAsText(file);
      reader.onload = function () {
        if (reader.result) {
          orderList = JSON.parse(String(reader.result));
          getImgData(flg);
          if (callback) callback();
        } else {
          if (callback) callback();
        }
      };
    } else {
      if (!orderList)
        orderList = { videoOrder: [], imgOrder: [], pdfOrder: [] };
      getImgData(flg);
      if (callback) callback();
    }
  };

  const checkClassEndJson = async () => {
    if (!S3) S3 = await createS3();
    let list = await getListS3(
      S3,
      "haishin/" + myuserData.service_id + "/" + classId + "/doc/"
    );
    if (list && list.length > 0) {
      for (let index = 0; index < list.length; index++) {
        const element: any = list[index];
        if (element && element.Key && element.Key.slice(-8) === "end.json") {
          await deleteS3(
            S3,
            "haishin/" + myuserData.service_id + "/" + classId + "/doc/end.json"
          );
        }
      }
    }
  };

  const getResorceId = async (callback?: any) => {
    let getResult = await agoraRecQuire(
      dispatch,
      classInfo.channelId,
      agoraUserId,
      agoraInfo.app_id
    );
    if (getResult) {
      var resourceId = getResult.data.resourceId;
      setResorceId(resourceId);
      await agoraRecordingStart(resourceId, callback);
    }
  };

  const getResorceId2 = async (callback?: any) => {
    let getResult = await agoraRecQuire2(
      dispatch,
      classInfo.channelId,
      agoraUserId,
      agoraInfo.app_id
    );
    if (getResult) {
      var resourceId = getResult.data.resourceId;
      await agoraRecordingStart2(resourceId);
      if (callback) callback();
    }
  };

  const checkAgora = async () => {
    let s3: any = await createS3();
    let list = await getListS3(
      s3,
      "haishin/" + myuserData.service_id + "/" + classId + "/agora_rec/"
    );
    let agora_Json1 = "";
    let agora_Json2 = "";
    if (list && list.length > 0) {
      for (let index = 0; index < list.length; index++) {
        const element: any = list[index];
        if ( element && element.Key && element.Key.slice(-9) === "info.json") {
          agora_Json1 = element.Key;
        }
        if (element && element.Key && element.Key.slice(-10) === "info2.json") {
          agora_Json2 = element.Key;
        }
      }
    }
    const doingFile = async(josn:any,josn2:any) => {
      let file: any = await getFileS3(S3, josn);
      let file2: any = await getFileS3(S3, josn2);
      if (file && file.size > 0 && file2 && file2.size > 0) {
        let rec_data: any = await new Promise((resolve, reject) => {
          let reader = new FileReader();
          reader.readAsText(file);
          reader.onload = function () {
            let list: any = null;
            if (reader.result) {
              list = JSON.parse(String(reader.result));
            }
            resolve(list);
          };
        });
        let rec_data2: any = await new Promise((resolve, reject) => {
          let reader = new FileReader();
          reader.readAsText(file2);
          reader.onload = function () {
            let list: any = null;
            if (reader.result) {
              list = JSON.parse(String(reader.result));
            }
            resolve(list);
          };
        });
        if (
          rec_data && rec_data2
        ) {
          let result = await agoraRecCheck(
            dispatch,
            rec_data.app_id,
            rec_data.resorce_id,
            rec_data.sid
          );
          if (result) {
            const t1 = await agoraRecStop(
              dispatch,
              rec_data.channel_id,
              "",
              rec_data.token,
              rec_data.app_id,
              rec_data.resorce_id,
              rec_data.sid
            );
            console.log("t1",t1);
            const t2 = await agoraRecStop2(
              dispatch,
              rec_data2.channel_id,
              "",
              rec_data2.token,
              rec_data2.app_id,
              rec_data2.resorce_id,
              rec_data2.sid
            );
            console.log("t1",t2);
          }
        }
      }
    }
    if (agora_Json1 !== "" && agora_Json2 !== "") {
      await doingFile(agora_Json1,agora_Json2);
    }
  };

  const agoraRecordingStart = async (resorceId: string, callback?: any) => {
    let path = ["haishin", myuserData.service_id, classId, "movie", "agora"];

    let joinUser: any = [agoraUserId];
    if (remoteUsers && remoteUsers.length > 0) {
      for (let index = 0; index < remoteUsers.length; index++) {
        joinUser.push(remoteUsers[index].uid);
      }
    }
    let getResult = await agoraRecStart(
      dispatch,
      classInfo.channelId,
      joinUser,
      agoraInfo.token,
      agoraInfo.app_id,
      resorceId,
      path
    );
    if (getResult && getResult.state === "0" && getResult.error_code === "") {
      if (callback) callback();
      agoraResorceId = getResult.body.resourceId;
      agoraSId = getResult.body.sid;
      const jsonData = JSON.stringify({
        channel_id: classInfo.channelId,
        token: agoraInfo.token,
        app_id: agoraInfo.app_id,
        resorce_id: agoraResorceId,
        sid: agoraSId,
      });
      var blob = new Blob([jsonData], { type: "text/json" });
      var jsonFile = new File([blob], "agora_rec_info.json");
      const uploadFile = async (file: any) => {
        let s3: any = await createS3();
        let result = await uploadS3(
          s3,
          "haishin/" +
            myuserData.service_id +
            "/" +
            classId +
            "/agora_rec/agora_rec_info.json",
          file
        );
        if (!result) setTimeout(() => uploadFile(file), 5000);
      };
      uploadFile(jsonFile);
    } else {
      alert(StreamRecordingErrMsg);
    }
  };

  const agoraRecordingStart2 = async (resorceId: string) => {
    let path = ["haishin", myuserData.service_id, classId, "movie", "agorabak"];

    let joinUser: any = [agoraUserId];
    if (remoteUsers && remoteUsers.length > 0) {
      for (let index = 0; index < remoteUsers.length; index++) {
        joinUser.push(remoteUsers[index].uid);
      }
    }
    let getResult = await agoraRecStart2(
      dispatch,
      classInfo.channelId,
      joinUser,
      agoraInfo.token,
      agoraInfo.app_id,
      resorceId,
      path
    );
    if (getResult && getResult.state === "0" && getResult.error_code === "") {
      agoraResorceId2 = getResult.body.resourceId;
      agoraSId2 = getResult.body.sid;
      const jsonData = JSON.stringify({
        channel_id: classInfo.channelId,
        token: agoraInfo.token,
        app_id: agoraInfo.app_id,
        resorce_id: agoraResorceId2,
        sid: agoraSId2,
      });
      var blob = new Blob([jsonData], { type: "text/json" });
      var jsonFile = new File([blob], "agora_rec_info2.json");
      const uploadFile = async (file: any) => {
        let s3: any = await createS3();
        let result = await uploadS3(
          s3,
          "haishin/" +
            myuserData.service_id +
            "/" +
            classId +
            "/agora_rec/agora_rec_info2.json",
          file
        );
        if (!result) setTimeout(() => uploadFile(file), 5000);
      };
      uploadFile(jsonFile);
    }
  };

  const SearchNoActiveUser = async () => {
    const dateNow = new Date();
    const noActiveUsers = Users.filter(
      (user) => user.lastActionTime < dateNow.getTime()
    );
    for (let i = 0; i < noActiveUsers.length; i++) {
      if (noActiveUsers[i].status !== USER_STATUS.EXIT) {
        userStatusChange(noActiveUsers[i].user_id, USER_STATUS.NOACTION);
      }
    }
    if (refClassFlg.current) {
      setTimeout(() => SearchNoActiveUser(), 60000);
    }
  };

  const setLog = async (
    choiceId: any,
    recordingVideoId: any,
    operationClass: string
  ) => {
    var date = new Date();
    var str =
      date.getFullYear() +
      "/" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "/" +
      ("0" + date.getDate()).slice(-2) +
      " " +
      ("0" + date.getHours()).slice(-2) +
      ":" +
      ("0" + date.getMinutes()).slice(-2) +
      ":" +
      ("0" + date.getSeconds()).slice(-2);
    let getData = await ST0320(
      dispatch,
      classId,
      choiceId,
      recordingVideoId,
      nowSeconds,
      str,
      operationClass
    );
    if (getData && getData.state === "0") {
      return;
    } else {
      //エラー処理
    }
  };

  const requestFullScreen = () => {
    setscreenIsSmall(true);
    screenSmall = true;
    if (document.body.webkitRequestFullscreen) {
      document.body.webkitRequestFullscreen(); //Chrome15+, Safari5.1+, Opera15+
    } else if (document.body.requestFullscreen) {
      document.body.requestFullscreen(); // HTML5 Fullscreen API仕様
    }
    setTimeout(() => winResize(), 100);
  };

  const exitFullScreen = () => {
    setscreenIsSmall(false);
    screenSmall = false;
    if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.exitFullscreen) {
      document.exitFullscreen(); // HTML5 Fullscreen API仕様
    }

    setTimeout(() => winResize(), 100);
    setTimeout(() => {
      const clist = document.getElementsByClassName("chat-box");
      if (clist && clist.length > 0) {
        clist[0].scrollTop = clist[0].scrollHeight;
      }
    }, 1000);
  };

  const checkBrowserType = () => {
    var userAgent = window.navigator.userAgent.toLowerCase();

    if (
      userAgent.indexOf("msie") !== -1 ||
      userAgent.indexOf("trident") !== -1
    ) {
      browserType = "Internet Explorer";
    } else if (userAgent.indexOf("edge") !== -1) {
      browserType = "Edge";
    } else if (userAgent.indexOf("chrome") !== -1) {
      browserType = "Google Chrome";
    } else if (userAgent.indexOf("crios") !== -1) {
      browserType = "Google Chrome";
    } else if (userAgent.indexOf("safari") !== -1) {
      browserType = "Safari";
    }
  };

  const checkDeviceType = () => {
    var ua = navigator.userAgent;
    if (
      !(
        ua.indexOf("iPhone") > 0 ||
        ua.indexOf("iPad") > 0 ||
        ua.indexOf("Android") > 0 ||
        ua.indexOf("Mobile") > 0
      )
    ) {
      deviceType = "PC";
    } else {
      deviceType = "NoPC";
    }
  };

  //レイアウト変更
  const [videoLyoutSetting, setVideoLyoutSetting] = React.useState(true);
  const [vLSetting, setVLSetting] = React.useState(false);
  const VLSetting = useRef(vLSetting);
  VLSetting.current = vLSetting;
  const [boardLyoutSetting, setBoardLyoutSetting] = React.useState(true);
  const [bLSetting, setBLSetting] = React.useState(false);
  const BLSetting = useRef(bLSetting);
  BLSetting.current = bLSetting;

  const [lyout, setLyout] = React.useState(null);
  const lyoutClick = (event: any) => {
    setLyout(event.currentTarget);
  };
  const layoutClose = () => {
    setLyout(null);
  };
  const lyoutOpen = Boolean(lyout);
  const idLyout = lyoutOpen ? "lyout-popover" : undefined;

  //チャット一覧表
  const [chat, setChat] = React.useState(null);
  const chatClick = (event: any) => {
    setChat(event.currentTarget);
    setTimeout(() => {
      let chatPop: any = document.getElementsByClassName("chat-popup");
      let chatBody = document.getElementsByClassName("msg-insert");
      if (chatBody.length > 0) {
        var clone: any = chatBody[0].cloneNode(true);
        if (chatPop) {
          chatPop[0].children[0].children[0].innerHTML = "";
          chatPop[0].children[0].children[0].appendChild(clone);
        }
        const clist2 = document.getElementsByClassName("chat-popup");
        if (clist2 && clist2.length > 0) {
          clist2[0].scrollTop = clist2[0].scrollHeight;
        }
      }
    }, 100);
  };
  const chatClose = () => {
    setChat(null);
  };
  const chatOpen = Boolean(chat);
  const idChat = lyoutOpen ? "chat-popover" : undefined;

  //チャット制御
  type chatSettingType = {
    sendSetting: string;
    displaySetting: string;
  };
  const [chatSettingItem, setChatSettingItem] = React.useState<chatSettingType>(
    {
      sendSetting: "1",
      displaySetting: "1",
    }
  );
  const [chatSetting, setChatSetting] = React.useState(null);
  const chatSettingClick = (event: any) => {
    setChatSetting(event.currentTarget);
  };
  const chatSettingClose = () => {
    setChatSetting(null);
  };
  const openChatSetting = Boolean(chatSetting);
  const idChatSetting = openChatSetting ? "chatSetting-popover" : undefined;

  //ユーザー一覧表
  const [user, setUser] = React.useState(null);

  const userClick = (event: any) => {
    setUser(event.currentTarget);
  };
  const userClose = () => {
    setUser(null);
  };
  const userOpen = Boolean(user);
  const idUser = userOpen ? "user-popover" : undefined;

  //指名一覧表
  const [name, setName] = React.useState(null);
  const nameClick = (event: any) => {
    setName(event.currentTarget);
  };
  const nameClose = () => {
    setName(null);
  };
  const nameOpen = Boolean(name);
  const idName = nameOpen ? "name-popover" : undefined;

  //video設定一覧表
  const [videoSet, setVideoSet] = React.useState(null);
  const videoSetClick = (event: any) => {
    setVideoSet(event.currentTarget);
  };
  const videoSetClose = () => {
    setVideoSet(null);
  };
  const videoSetOpen = Boolean(videoSet);
  const idVideoSet = videoSetOpen ? "videoSet-popover" : undefined;

  //pen設定一覧表
  const [penSet, setPenSet] = React.useState(null);
  const penSetClick = (event: any) => {
    setPenSet(event.currentTarget);
  };
  const penSetClose = () => {
    setPenSet(null);
  };
  const penSetOpen = Boolean(penSet);
  const idPenSet = penSetOpen ? "penSet-popover" : undefined;

  //図形設定一覧表
  const [shapeSet, setShapeSet] = React.useState(null);
  const shapeSetClick = (event: any) => {
    setShapeSet(event.currentTarget);
  };
  const shapeSetClose = () => {
    setShapeSet(null);
  };
  const shapeSetOpen = Boolean(shapeSet);
  const idShapeSet = shapeSetOpen ? "shapeSet-popover" : undefined;

  //video一覧表
  // const [videoList, setVideoList] = React.useState(null);
  // const videoListClick = (event: any) => {
  //   setVideoList(event.currentTarget);
  // };
  // const videoListClose = () => {
  //   setVideoList(null);
  // };
  // const videoListOpen = Boolean(videoList);
  // const idVideoList = videoListOpen ? "videoList-popover" : undefined;

  //Img一覧表
  const [imgList, setImgList] = React.useState(null);
  const imgListClick = (event: any) => {
    setImgList(event.currentTarget);
  };
  const imgListClose = () => {
    setImgList(null);
  };
  const imgListOpen = Boolean(imgList);
  const idImgList = imgListOpen ? "imgList-popover" : undefined;

  //択一問題
  const [question, setQuestion] = React.useState(null);
  const questionClick = (event: any) => {
    if (openQuestionResult) {
      //択一問題結果ダイアログを閉じる
      setAnswerCatch(0);
      setSentAnswerCatch(0);
      setResult([]);
      questionResultClose();
      closeAnswerToLog();
    } else {
      //択一問題出題ダイアログを表示
      setQuestion(event.currentTarget);
    }
  };
  const questionClose = () => {
    setQuestion(null);
  };
  const openQuestion = Boolean(question);
  const idQuestion = openQuestion ? "question-popover" : undefined;

  //択一問題結果
  const [questionResult, setQuestionResult] = React.useState<any>(null);
  const switchCountDisplay = (answerList: any, is: boolean) => {
    drawShowAnswer(answerList);
    percentFlg = is;
    const option = {
      percent_flg: percentFlg,
      choice_id: choiceId,
      answer_options: answerList,
    };
    sendAction(ActionComand.MSG_QUIZ_QUESTIONS, loginUserId, option);
  };
  const questionResultOpen = () => {
    setQuestionResult(true);
  };
  const questionResultClose = () => {
    if (graphShapeList[questionListsColor.shape_id]) {
      // GraphFabCanvas.remove(graphShapeList[questionListsColor.shape_id]);
      FabCanvas.remove(graphShapeList[questionListsColor.shape_id]);
      delete graphShapeList[questionListsColor.shape_id];
      // GraphFabCanvas.renderAll();
      FabCanvas.renderAll();
    }
    percentFlg = false;
    questionResFlg = false;
    // saveQuestJson(choiceId, false);
    sendAction(ActionComand.MSG_QUIZ_QUESTIONS, null, {});
    setQuestionResult(null);
  };
  const openQuestionResult = Boolean(questionResult);
  const idQuestionResult = openQuestionResult
    ? "questionResult-popover"
    : undefined;

  // 択一問題結果描写
  const drawShowAnswer = (mergeShowAnswer: any) => {
    if (graphShapeList[questionListsColor.shape_id]) {
      // GraphFabCanvas.remove(graphShapeList[questionListsColor.shape_id]);
      FabCanvas.remove(graphShapeList[questionListsColor.shape_id]);
      delete graphShapeList[questionListsColor.shape_id];
    }

    const gap_percentage = GAP_PERCENTAGE_SELECT_QUESTIONS;
    const rectHScale = (100 - gap_percentage * 11) / 10 / 100;
    const clientHeightformated = canvasData.h / zoom;
    const clientWidthformated = canvasData.w / zoom;
    const barHeight = clientHeightformated * rectHScale;
    questionListsColor.shape_id = "shape_" + loginUserId + "_drawShowAnswer";
    const textBoxWidth = barHeight * 1.4;
    const fontSize = barHeight * 0.6;

    let answers: any = [];
    if (mergeShowAnswer.length > 0) {
      // eslint-disable-next-line array-callback-return
      mergeShowAnswer.map((answer: any, index: number) => {
        const rect = new fabric.Rect({
          left: 0,
          top:
            barHeight * index +
            clientHeightformated * (gap_percentage / 100) * (index + 1),
          originX: "left",
          originY: "top",
          width: (clientWidthformated - textBoxWidth) * (answer.percent / 100),
          height: barHeight,
          angle: 0,
          fill: questionListsColor["answer_" + String(answer.answer_no)],
          strokeWidth: 2,
          stroke: "black",
          opacity: 0.75,
          scaleX: 1, //初期倍率
          scaleY: 1, //初期倍率
          selectable: false,
          transparentCorners: false,
          noScaleCache: false,
          strokeUniform: false,
        });
        const text = new fabric.Textbox(
          percentFlg ? String(answer.percent) + "%" : String(answer.count),
          {
            originX: "center",
            originY: "center",
            width: textBoxWidth,
            top:
              barHeight * index +
              clientHeightformated * (gap_percentage / 100) * (index + 1) +
              barHeight * 0.5,
            left:
              (clientWidthformated - textBoxWidth) * (answer.percent / 100) +
              textBoxWidth * 0.5,
            fontSize: fontSize,
            textAlign: "center",
          }
        );
        answers = [...answers, rect, text];
      });
    }
    const answerGroup = new fabric.Group(answers, {
      left: 0,
      top: clientHeightformated * (gap_percentage / 100),
    });
    graphShapeList[questionListsColor.shape_id] = answerGroup;
    // GraphFabCanvas.add(answerGroup);
    FabCanvas.add(answerGroup);
    // GraphFabCanvas.setActiveObject(answer1);
    // GraphFabCanvas.renderAll();
    FabCanvas.renderAll();
    return false;
  };

  // ユーザのリアクション数描写
  const drawUserReactionCount = (userReactions: any) => {
    FabCanvas.getObjects().forEach((element: any) => {
      if (
        element.type === "group" &&
        element.globalCompositeOperation === drawReactionCountInfo.shape_id
      )
        FabCanvas.remove(element);
    });
    if (graphShapeList[drawReactionCountInfo.shape_id]) {
      delete graphShapeList[drawReactionCountInfo.shape_id];
    }

    const clientHeightformated = ctx.canvas.clientHeight / zoom;
    const clientWidthformated = ctx.canvas.clientWidth / zoom;
    const barWidth = clientWidthformated / 16;
    drawReactionCountInfo.shape_id =
      "shape_" + loginUserId + "_drawUserReactionCount";
    const fontSize = barWidth * 0.6;
    const overHeight = clientHeightformated / 10;

    let reactionCounts: any = {
      REACTION_OK: 0,
      REACTION_NG: 0,
      REACTION_HAND: 0,
    };
    for (let i = userReactions.length - 1; i >= 0; i--) {
      const now = new Date();
      if (Number(now.getTime()) - Number(userReactions[i].time) <= 2000) {
        switch (userReactions[i].reaction) {
          case 1:
            reactionCounts.REACTION_OK++;
            break;
          case 2:
            reactionCounts.REACTION_NG++;
            break;
          case 3:
            reactionCounts.REACTION_HAND++;
            break;
        }
      } else {
        break;
      }
    }

    let count: any = [];
    if (
      reactionCounts.REACTION_OK > 0 ||
      reactionCounts.REACTION_NG > 0 ||
      reactionCounts.REACTION_HAND > 0
    ) {
      // eslint-disable-next-line array-callback-return
      const dummyCanvasRect = new fabric.Rect({
        left: 0,
        top: -overHeight,
        originX: "left",
        originY: "top",
        width: clientWidthformated,
        height: clientHeightformated + overHeight,
        angle: 0,
        fill: "white",
        strokeWidth: 0,
        stroke: "white",
        opacity: 0,
        scaleX: 1, //初期倍率
        scaleY: 1, //初期倍率
        selectable: false,
        transparentCorners: false,
        noScaleCache: false,
        strokeUniform: false,
      });
      count = [...count, dummyCanvasRect];
      Object.keys(reactionCounts).forEach((key: string, index: number) => {
        const rect = new fabric.Rect({
          left: clientWidthformated / 2 - barWidth * 2 + barWidth * 1.5 * index,
          top:
            clientHeightformated -
            (clientHeightformated / 100) * reactionCounts[key],
          originX: "left",
          originY: "top",
          width: barWidth,
          height: (clientHeightformated / 100) * reactionCounts[key],
          angle: 0,
          fill: drawReactionCountInfo["COLOR_" + key],
          strokeWidth: 2,
          stroke: "black",
          opacity: 0.75,
          scaleX: 1, //初期倍率
          scaleY: 1, //初期倍率
          selectable: false,
          transparentCorners: false,
          noScaleCache: false,
          strokeUniform: false,
        });
        const emoji = new fabric.Textbox(
          setData["simple_reaction_" + String(index + 1)],
          {
            left:
              clientWidthformated / 2 - barWidth * 2 + barWidth * 1.5 * index,
            top:
              clientHeightformated -
              (clientHeightformated / 100) * reactionCounts[key] +
              barWidth / 4,
            originX: "left",
            originY: "top",
            width: barWidth,
            fontSize: fontSize,
            opacity: 0.75,
            textAlign: "center",
          }
        );
        count = [...count, rect, emoji];
      });
    } else {
      userReactions = [];
    }
    const countGroup = new fabric.Group(count, {
      globalCompositeOperation: drawReactionCountInfo.shape_id,
      left: 0,
      top: -overHeight,
    });
    graphShapeList[drawReactionCountInfo.shape_id] = countGroup;
    // GraphFabCanvas.add(countGroup);
    // GraphFabCanvas.renderAll();

    FabCanvas.add(countGroup);
    FabCanvas.renderAll();
    lastReactionTime = new Date().getTime();
    setTimeout(() => {
      const nowTiem = new Date().getTime();
      if (nowTiem - lastReactionTime > 2000) {
        FabCanvas.getObjects().forEach((element: any) => {
          if (
            element.type === "group" &&
            element.globalCompositeOperation === drawReactionCountInfo.shape_id
          )
            FabCanvas.remove(element);
        });
        sendAction(actionComand.MSG_REACTION, null, {
          reaction: "",
          reaction_counts: {
            REACTION_OK: 0,
            REACTION_NG: 0,
            REACTION_HAND: 0,
          },
        });
      }
    }, 4000);
    return reactionCounts;
  };
  // 択一問題表示終了時操作ログに登録
  const closeAnswerToLog = () => {
    setLog(choiceId, null, "A");
  };

  //拡大
  const [expansion, setExpansion] = React.useState(null);
  const expansionClick = (event: any) => {
    setExpansion(event.currentTarget);
  };
  const expansionClose = () => {
    setExpansion(null);
  };
  const expansionOpen = Boolean(expansion);
  const idExpansion = expansionOpen ? "expansion-popover" : undefined;

  //ホワイトボードリスト
  const [wbList, setWbList] = React.useState(null);
  const wbListClick = (event: any) => {
    setWbList(event.currentTarget);
  };
  const wbListClose = () => {
    setWbList(null);
  };
  const wbListOpen = Boolean(wbList);
  const idWbList = wbListOpen ? "wbList-popover" : undefined;

  let count = 0;

  const setWhiteBoardList = async (callback?: any) => {
    //ホワイトボードリスト画像アップロード
    S3 = await createS3();
    if (S3) {
      const image = FabCanvas.toDataURL();
      var bin = atob(image.replace(/^.*,/, ""));
      var buffer = new Uint8Array(bin.length);
      for (var i = 0; i < bin.length; i++) {
        buffer[i] = bin.charCodeAt(i);
      }
      const blob: any = new Blob([buffer.buffer], { type: "image/png" });
      let fileName =
        "haishin/" +
        myuserData.service_id +
        "/" +
        classId +
        "/board_" +
        nowBoardId +
        "/" +
        "board_" +
        nowBoardId +
        ".png";
      let result = await uploadS3(S3, fileName, blob);
      if (result) {
        whiteboardDataList[nowBoardId - 1] = {
          boardNo: nowBoardId,
          selectImgId: selectImgNo,
          // url: image,
          url: await getUrlS3(S3, fileName),
        };
        if (callback) callback();
      }
    }
  };

  const makeJson = async (id: any) => {
    let Json = JSON.stringify({
      lineList: {},
      shapeList: {},
      scale: 1,
      img_no: -1,
      json: FabCanvas.toJSON(),
    });
    const canvasJsonFile: any = new Blob([Json], {
      type: "application/json",
    });
    S3 = await createS3();
    if (S3) {
      let fileName =
        "haishin/" +
        store.getState().user.service_id +
        "/" +
        classId +
        "/board_" +
        id +
        "/" +
        "canvas_" +
        id +
        ".json";
      let result = await uploadS3(S3, fileName, canvasJsonFile);
      if (!result) setTimeout(() => makeJson(id), 100);
    }
  };

  const setWhiteBord = async () => {
    for (let i = 1; i < 21; i++) {
      whiteboardDataList.push({
        boardNo: i,
        selectImgId: -1,
        url: newWhiteBoard,
      });
      await makeJson(i);
    }
  };

  // const setWbImg = async () => {
  // ctxS.clearRect(0, 0, ctxS.canvas.clientWidth, ctxS.canvas.clientHeight);
  // const imgStartX = drowCanvasDiv.scrollLeft / refScale.current;
  // const imgStartY = drowCanvasDiv.scrollTop / refScale.current;

  // let Img;
  // Img = await getImagefromCanvas("canvasImg");
  // ctxS.drawImage(
  //   Img,
  //   imgStartX,
  //   imgStartY,
  //   ctxI.canvas.width / refScale.current,
  //   ctxI.canvas.height / refScale.current,
  //   0,
  //   0,
  //   ctxS.canvas.width,
  //   ctxS.canvas.height
  // );
  // Img = await getImagefromCanvas("canvas");
  // ctxS.drawImage(
  //   Img,
  // imgStartX,
  // imgStartY,
  // ctx.canvas.width / refScale.current,
  // ctx.canvas.height / refScale.current,
  //   0,
  //   0,
  //   ctxS.canvas.width,
  //   ctxS.canvas.height
  // );
  // };

  //canvas合成
  // async function concatCanvas() {
  //   if (!concatFlg && operation !== OPERATION_TYPE.SHARE) {
  //     concatFlg = true;
  //     // const imgStartX = imgCanvasDiv.scrollLeft / refScale.current;
  //     // const imgStartY = imgCanvasDiv.scrollTop / refScale.current;

  //     // if (
  //     //   refShareState.current ||
  //     //   operation === OPERATION_TYPE.VIDEO ||
  //     //   (operation === OPERATION_TYPE.PEN && drowing) ||
  //     //   refflg
  //     // ) {
  //     /*// 画面共有の合成

  //     image1 = await getImagefromCanvas(canvasShare);
  //     ctxC.drawImage(
  //       image1,
  //       imgStartX,
  //       imgStartY,
  //       ctxS.canvas.width / refScale.current,
  //       ctxS.canvas.height / refScale.current,
  //       0,
  //       0,
  //       ctxC.canvas.width,
  //       ctxC.canvas.height
  //     );
  //     var image = ctxC.getImageData(
  //       0,
  //       0,
  //       ctxC.canvas.width,
  //       ctxC.canvas.height
  //     );
  //     //ctxC.clearRect(0, 0, ctxC.canvas.clientWidth, ctxC.canvas.clientHeight);
  //     ctxR.putImageData(image, 0, 0);*/
  //     // } else {
  //     Promise.all([
  //       // getImagefromCanvas("canvasImg"),
  //       getImagefromCanvas("canvas"),
  //       getImagefromCanvas("canvasGraph"),
  //     ]).then((result: any) => {
  //       if (result && result[0] && result[1]) {
  //         //} && result[2]) {
  //         ctxC.drawImage(
  //           result[0],
  //           0,
  //           0,
  //           ctxC.canvas.width,
  //           ctxC.canvas.height
  //         );
  //         // キャンバスの合成
  //         // var width = Number(canvas.style.width.slice(0, -2));
  //         // var height = Number(canvas.style.height.slice(0, -2));
  //         // ctxC.drawImage(
  //         //   result[1],
  //         //   Math.floor(drowCanvasDiv.scrollLeft / refScale.current),
  //         //   Math.floor(drowCanvasDiv.scrollTop / refScale.current),
  //         //   Math.floor(width / refScale.current),
  //         //   Math.floor(height / refScale.current),
  //         //   0,
  //         //   0,
  //         //   width,
  //         //   height
  //         // );
  //         // 択一問題グラフ、反応結果グラフの合成
  //         ctxC.drawImage(result[1], 0, 0, ctx.canvas.width, ctx.canvas.height);
  //         var image = ctxC.getImageData(
  //           0,
  //           0,
  //           ctxC.canvas.width,
  //           ctxC.canvas.height
  //         );
  //         //ctxC.clearRect(0, 0, ctxC.canvas.clientWidth, ctxC.canvas.clientHeight);
  //         ctxR.putImageData(image, 0, 0);
  //         concatFlg = false;
  //       }
  //     });
  //     // }

  //     /*image1 = await getImagefromCanvas(canvasConcat);
  //   await ctxR.clearRect(
  //     0,
  //     0,
  //     ctxR.canvas.clientWidth,
  //     ctxR.canvas.clientHeight
  //   );*/
  //     //ctxR.drawImage(image1, 0, 0, canvasRecord.width, canvasRecord.height);
  //     //ctxC.clearRect(0, 0, ctxC.canvas.clientWidth, ctxC.canvas.clientHeight);
  //     //ctxR.clearRect(0, 0, ctxR.canvas.clientWidth, ctxR.canvas.clientHeight);
  //   }
  // }

  //画像として取得
  // function getImagefromCanvas(canvasSample: any, flg?: boolean) {
  //   var canvasG: any = document.getElementById(canvasSample);
  //   if (canvasG) {
  //     if (canvasSample === "canvas") {
  //       return new Promise((resolve, reject) => {
  //         var ctx = canvasG.getContext("2d");
  //         var width = Number(ctx.canvas.width) / scale;
  //         var height = Number(ctx.canvas.height) / scale;
  //         canvasShare.width = width;
  //         canvasShare.height = height;
  //         ctxS.clearRect(0, 0, width, height);
  //         var imgData;
  //         if (cscale > 1) {
  //           imgData = ctx.getImageData(
  //             drowCanvasDiv.scrollLeft,
  //             drowCanvasDiv.scrollTop,
  //             drowCanvasDiv.scrollLeft + width,
  //             drowCanvasDiv.scrollTop + height
  //           );
  //         } else {
  //           imgData = ctx.getImageData(
  //             0,
  //             0,
  //             ctx.canvas.width,
  //             ctx.canvas.height
  //           );
  //         }
  //         ctxS.putImageData(imgData, 0, 0);
  //         const image = new Image();
  //         image.onload = () => resolve(image);
  //         image.onerror = (e) => reject(e);
  //         image.src = ctxS.canvas.toDataURL();
  //       });
  //     } else {
  //       return new Promise((resolve, reject) => {
  //         const ctx = canvasG.getContext("2d");
  //         const image = new Image();
  //         image.onload = () => resolve(image);
  //         image.onerror = (e) => reject(e);
  //         image.src = ctx.canvas.toDataURL();
  //       });
  //     }
  //   } else {
  //     return false;
  //   }
  // }

  const saveChapter = async (s3: any) => {
    const image = canvas.toDataURL();
    var bin = atob(image.replace(/^.*,/, ""));
    var buffer = new Uint8Array(bin.length);
    for (var i = 0; i < bin.length; i++) {
      buffer[i] = bin.charCodeAt(i);
    }
    const blob: any = new Blob([buffer.buffer], { type: "image/png" });
    const options = {
      maxSizeMB: 1, // 最大ファイルサイズ
      maxWidthOrHeight: 300, // 最大画像幅もしくは高さ
    };

    // リサイズ画像の生成
    const resizeFile = await imageCompression(blob, options);
    const uploadData = async (minute_no: any, resizeFileData: any) => {
      let upd = await uploadS3(
        s3,
        "haishin/" +
          myuserData.service_id +
          "/" +
          classId +
          "/chapter/" +
          classId +
          "_" +
          minute_no +
          ".jpg",
        resizeFileData
      );
      if (!upd) setTimeout(() => uploadData(minute_no, resizeFileData), 1000);
    };
    uploadData(minute, resizeFile);
  };

  const saveBackupImage = async () => {
    const uploadUrl =
      "haishin/" +
      myuserData.service_id +
      "/" +
      classId +
      "/backup/" +
      classId +
      "_" +
      ("00000" + BackupNo).slice(-5) +
      ".jpg";
    BackupNo++;
    const makeImg = (url: any) => {
      canvas.toBlob(
        async (image: any) => {
          const resizeFile = await imageCompression(image, {
            maxSizeMB: 0.3,
            maxWidthOrHeight: 2000,
          });
          const uploadData = async (uploadUrl: any, resizeFileData: any) => {
            const s3: any = await createS3();
            let upd = await uploadS3(s3, uploadUrl, resizeFileData);
            if (!upd)
              setTimeout(() => uploadData(uploadUrl, resizeFileData), 3000);
          };
          uploadData(url, resizeFile);
        },
        "image/jpeg",
        0.9
      );
    };
    makeImg(uploadUrl);
  };

  const reactionSaveStart = () => {
    var checkMinute = 0;
    const saveInterval = setInterval(() => {
      if (minute > checkMinute) {
        saveMessageData(checkMinute);
        checkMinute = minute;
      }
      saveMessageListData();
      let path = store.getState().router.location.pathname;
      if (path !== "/cls/008") {
        clearInterval(saveInterval);
      }
    }, 30000);
  };

  const saveMessageListData = async () => {
    messageListData.tmp = {
      no: minute,
      messageData:
        messageData[minute] && messageData[minute].length > 0
          ? messageData[minute]
          : [],
    };
    let jsonData = JSON.stringify(messageListData);
    if (jsonData.length !== checkSaveTmpMessage) {
      checkSaveTmpMessage = jsonData.length;
      var blob = new Blob([jsonData], { type: "text/json" });
      var jsonFile = new File([blob], "message.json");
      S3 = await createS3();
      const result = await uploadS3(
        S3,
        "haishin/" +
          myuserData.service_id +
          "/" +
          classId +
          "/chatMessage/all_list.json",
        jsonFile
      );
      if (!result) checkSaveTmpMessage = 0;
    }
  };

  const saveMessageData = async (no: any) => {
    var data = messageData[no];
    if (data && data.length > 0) {
      if (!messageListData[no])
        messageListData[no] = {
          reaction1: 0,
          reaction2: 0,
          reaction3: 0,
        };
      messageListData[no].chat_data = data.slice(0, 10);
      let jsonData = JSON.stringify(data);
      var blob = new Blob([jsonData], { type: "text/json" });
      var jsonFile = new File([blob], "message.json");
      S3 = await createS3();
      const result = await uploadS3(
        S3,
        "haishin/" +
          myuserData.service_id +
          "/" +
          classId +
          "/chatMessage/chat_" +
          no +
          ".json",
        jsonFile
      );
      if (result) {
        delete messageData[no];
      } else {
        setTimeout(() => saveMessageData(no), 1000);
      }
    }
  };

  const readFileAsync = (file: any) => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = reject;

      reader.readAsArrayBuffer(file);
    });
  };

  const delAgoraRecInfo = async () => {
    if (!S3) S3 = await createS3();
    let list = await getListS3(
      S3,
      "haishin/" + myuserData.service_id + "/" + classId + "/agora_rec/"
    );
    if (list && list.length > 0) {
      for (let index = 0; index < list.length; index++) {
        const element: any = list[index];
        if (element && element.Key && element.Key.slice(-4) === "json") {
          await deleteS3(S3, element.Key);
        }
      }
    }
  };

  const recordStart = async () => {
    var stream = canvas.captureStream(15);
    var ua = navigator.userAgent;
    if (
      browserType === "Safari" ||
      ua.indexOf("iPad") > 0 ||
      (navigator.userAgent.includes("Macintosh") && "ontouchend" in document)
    ) {
      recorder = new MediaRecorder(stream);
      mediaType = "mp4";
    } else {
      recorder = new MediaRecorder(stream, {
        mimeType: "video/webm;codecs=vp8",
      });
      mediaType = "webm";
    }
    recorder.ondataavailable = async (event: any) => {
      if (event.data) {
        var videoBlob: any = new Blob([event.data], {
          type: "video/" + mediaType,
        });
        const s3: any = await createS3();
        videoNo++;
        const uploadData = async (num: any, blob: any) => {
          let upV = await uploadS3(
            s3,
            "haishin/" +
              myuserData.service_id +
              "/" +
              classId +
              "/movie/board_data_" +
              videoCount +
              "/" +
              classId +
              "_" +
              num +
              "." +
              mediaType,
            blob
          );
          if (!upV) {
            setTimeout(() => uploadData(num, blob), 1000);
          } else if (upV && !refRecordingState.current && !classState) {
            chengVideoType(1);
            delAgoraRecInfo();
            recorder = null;
          }
        };
        uploadData(videoNo, videoBlob);
      }
    };
    recorder.start(60000);
  };

  const [recordingFlg, setRecordingFlg] = React.useState(false);
  //録画
  const startRecording = async (recordFlg: boolean) => {
    if (videoRecFlg) return;
    videoRecFlg = true;
    setJoinButtonState(false);
    setGideMessageHidden(true);
    if (recordFlg) {
      setTimeout(() => {
        createS3().then((s3: any) => {
          saveChapter(s3);

          const saveBuckupImageInterval = setInterval(() => {
            saveBackupImage();
            let path = store.getState().router.location.pathname;
            if (refRecordend.current || path !== "/cls/008") {
              clearInterval(saveBuckupImageInterval);
            }
          }, 1000);
        });
      }, 1000);
      await checkAgora();
      getResorceId2();
      console.log("BackupMovie")
      getResorceId(() => {
        playChime(false);
        recordStart();
        startDateTime = new Date();
        startDate = startDateTime.getTime();
        updateStatus("4");
        setClassStatus("4");
        setJoinButtonState(true);
        sendAction(ActionComand.MSG_CLASS_START, null, {
          extraTime: befClassTime,
          startDate: startDate,
        });
        FPSIVL = setInterval(() => {
          if (!refRecordingState.current) {
            clearInterval(FPSIVL);
            FPSIVL = null;
          } else {
            if (operation === OPERATION_TYPE.SHARE) return;
            if (boardTime) {
              if (!FPSIVLNo || FPSIVLNo === 9) FPSIVLNo = 0;
              FPSIVLNo++;
              boardTime.set({ text: String(FPSIVLNo) });
              FabCanvas.renderAll();
            }
          }
        }, 1000 / 15);
        setRecordingFlg(recordFlg);
      });
      console.log("Movie")
    } else {
      startDateTime = new Date();
      startDate = startDateTime.getTime();

      updateStatus("4");
      setClassStatus("4");
      setJoinButtonState(true);
      sendAction(ActionComand.MSG_CLASS_START, null, {
        extraTime: befClassTime,
        startDate: startDate,
      });
    }

    updateElapsedTime(minute + 1);
    const recordInterval = setInterval(() => {
      if (startDate > 0) {
        nowDate = new Date().getTime();
        count = Math.floor((nowDate - startDate) / 1000) + befClassTime;

        nowSeconds = count;
        setClassTime(timeFoemat(count));

        if (count !== 0 && recordTime !== count) {
          localStorage.setItem("classTimeCount", String(count));
          localStorage.setItem("Cls008_classId", classId);
          if (count % 60 === 0) {
            minute = count / 60;
            if (recordFlg) {
              createS3().then((s3) => {
                saveChapter(s3);
              });
            }
            if (classTimeAddFlg) {
              updateElapsedTime(minute + 1);
            }
          }

          if (count % 1500 === 0) {
            getSessionUser().then((result) => {
              if (!result) {
                if (recorder && recorder.state === "recording") recorder.stop();
                // if (recorder2 && recorder2.state === "recording")
                //   recorder2.stop();
                clearInterval(recordInterval);
                agoraLeave(false);
              }
            });
          }
        }
        recordTime = count;
      }
      let path = store.getState().router.location.pathname;
      if (refRecordend.current || path !== "/cls/008") {
        agoraRecStop(
          dispatch,
          classInfo.channelId,
          "",
          agoraInfo.token,
          agoraInfo.app_id,
          agoraResorceId,
          agoraSId
        ).then(() => {
          
          if (recorder && recorder.state === "recording") recorder.stop();
          // if (recorder2 && recorder2.state === "recording") recorder2.stop();
        });
        setTimeout(() => {
          if (recorder && recorder.state === "recording") recorder.stop();
        }, 2000);
        clearInterval(recordInterval);
        agoraRecStop2(
          dispatch,
          classInfo.channelId,
          "",
          agoraInfo.token,
          agoraInfo.app_id,
          agoraResorceId2,
          agoraSId2
        );
      }
    }, 500);

    // Promise.all([recordStart(true), getResorceId()]);
    // const copyInterval = setInterval(() => {
    //   if ((operation === OPERATION_TYPE.PEN && drowing) || refflg) {
    //   } else {
    //     const json = FabCanvas.toJSON();
    //     CopyFabCanvas.loadFromJSON(json).renderAll();
    //   }
    //   let path = store.getState().router.location.pathname;
    //   if (refRecordend.current || path !== "/cls/008") {
    //     clearInterval(copyInterval);
    //   }
    // }, 150);
    // const concatInterval = setInterval(() => {
    //   concatCanvas();
    //   let path = store.getState().router.location.pathname;
    //   if (refRecordend.current || path !== "/cls/008") {
    //     clearInterval(concatInterval);
    //   }
    // }, 1000 / 15);
  };

  const playChime = async (flg: boolean) => {
    sendAction(actionComand.MSG_PLAY_CHIME, null, {});

    var music = new Audio(chimeMelody);
    music.play();
    // music.addEventListener("ended", (event) => {
    //   if (flg) {
    //     setRecordend(true);
    //     agoraLeave(flg);
    //   }
    // });
  };

  // const [start, setStart] = React.useState({ x: 0, y: 0 });
  const allClear = (flg: boolean) => {
    FabCanvas.getObjects().forEach((element: any) => {
      if (
        element.globalCompositeOperation !== "back_ground_color" &&
        element.globalCompositeOperation !== "back_ground_text" &&
        element.globalCompositeOperation !== "back_ground_image"
      )
        FabCanvas.remove(element);
    });
    FabCanvas.renderAll();
    lineList = {};
    shapeList = {};
    sendAction(actionComand.MSG_WB_DRAW, null, {
      board_event_type: 10,
    });
    changeJson();
    handleClickClose();
  };

  //画像挿入
  const drawImg = async (value: any) => {
    //imgListClose();
    const s3 = await createS3();
    const fileUrl = await getUrlS3(s3, value);
    if (fileUrl) {
      return new Promise((resolve, reject) => {
        var image = new Image();
        image.crossOrigin = "Anonymous";
        image.src = fileUrl;
        var left;
        var top;
        var width;
        var height;
        var canvasW = defaultWidth;
        var canvasH = defaultWidth / (16 / 9);

        image.onload = () => {
          var imgAspect = image.naturalWidth / image.naturalHeight;
          if (image.naturalWidth > image.naturalHeight) {
            width = canvasW;
            height = canvasW / imgAspect;
            if (height > canvasH) {
              width = (canvasH - 2) * imgAspect;
              height = canvasH - 2;
              top = 0;
              left = Math.floor(((canvasW - width) / 2) * cscale);
            } else {
              width = canvasW - 2;
              height = (canvasW - 2) / imgAspect;
              left = 0;
              top = Math.floor(((canvasH - height) / 2) * cscale);
            }
          } else {
            width = canvasH * imgAspect;
            height = canvasH;
            if (width > canvasW) {
              width = canvasW - 2;
              height = (canvasW - 2) / imgAspect;
              left = 0;
              top = Math.floor(((canvasH - height) / 2) * cscale);
            } else {
              width = (canvasH - 2) * imgAspect;
              height = canvasH - 2;
              top = 0;
              left = Math.floor(((canvasW - width) / 2) * cscale);
            }
          }
          if (cscale > 1) {
            top = imgBackground.top + top;
            left = imgBackground.left + left;
          }
          if (nowImageData) {
            let objects = FabCanvas.getObjects();
            for (let index = 0; index < objects.length; index++) {
              const element = objects[index];
              if (element.type === "image") FabCanvas.remove(element);
            }
          }
          nowImageData = new fabric.Image(image, {
            name: "back_ground_image",
            globalCompositeOperation: "back_ground_image",
            width: Math.floor(image.naturalWidth),
            height: Math.floor(image.naturalHeight),
            scaleX:
              Math.round((width / image.naturalWidth) * cscale * 100) / 100,
            scaleY:
              Math.round((height / image.naturalHeight) * cscale * 100) / 100,
            selectable: false,
            evented: false,
            top: Math.floor(top),
            left: Math.floor(left),
          });
          FabCanvas.add(nowImageData);
          nowImageData.moveTo(1);
          selectImgUrl = value;
          // const saveImg = (url: any) => {
          //   setTimeout(async () => {
          //     if (url === selectImgUrl) {
          //       const s3: any = await createS3();
          //       const fileUrl = await getFileBase64S3(s3, url);
          //       nowImageData.setSrc(
          //         fileUrl,
          //         function () {
          //           changeJson();
          //           FabCanvas.requestRenderAll();
          //         },
          //         { crossOrigin: "annonymous" }
          //       );
          //     }
          //   }, 500);
          // };
          // saveImg(value);
          changeJson();
          clickImageFlg = false;
          resolve(value);
        };
      });
    }
  };

  //画像削除
  const deleteImg = () => {
    if (nowImageData) {
      FabCanvas.remove(nowImageData);
      nowImageData = null;
      selectImgUrl = null;
      selectImgNo = -1;
      changeJson();
    }
  };

  //画像変更
  const changeImg = async (changeId: number) => {
    if (!clickImageFlg) {
      clickImageFlg = true;
      const found = img_file_data[changeId];
      if (found) {
        const sendImg = (id: any) => {
          setTimeout(() => {
            if (id === selectImgNo) {
              sendAction(ActionComand.MSG_WB_SHOW_PICTURE, null, {
                board_document_id: selectImgNo,
              });
            }
          }, 800);
        };
        sendImg(changeId);
        setSelectImgId(changeId);
        selectImgNo = changeId;
        const URL = found ? found : "";
        setBoardImg(URL);
        if (await drawImg(URL)) return changeId;
      } else {
        clickImageFlg = false;
      }
    }
    return false;
  };

  const handleImgUploadExeClickClose = () => {
    if (!uploading) {
      setUploadFile(null);
      setUploadFileUrl("");
      setChooseFileName("");
      setErrorMsgImgUp("");
    }
    setImgUploadDialogOpen(false);
  };

  const handleCameraClickClose = () => {
    setCameraDialogOpen(false);
  };

  const handleCameraClickOpen = () => {
    setCameraDialogOpen(true);
  };

  const createJpegFile = (base64: any, fileName: any) => {
    var bin = atob(base64.replace(/^.*,/, ""));
    var buffer = new Uint8Array(bin.length);
    for (var i = 0; i < bin.length; i++) {
      buffer[i] = bin.charCodeAt(i);
    }
    return new File([buffer.buffer], fileName, { type: "image/jpeg" });
  };
  /*
  const readPdfFile = async (file: any) => {
    const loadingTask = pdfjs.getDocument({
      url: URL.createObjectURL(file),
      cMapUrl: "https://cdn.jsdelivr.net/npm/pdfjs-dist@2.0.385/cmaps/",
      cMapPacked: true,
    });
    let loadedPdf: any = await loadingTask.promise;
    const pdfNum: number = Number(loadedPdf._pdfInfo.numPages);
    let files: any[] = [];
    for (let index = 1; index <= pdfNum; index++) {
      let page = await loadedPdf.getPage(index);
      var viewport = page.getViewport({ scale: 3 });
      var canvas = document.createElement("canvas");
      var ctx = canvas.getContext("2d");
      var renderContext: any = {
        canvasContext: ctx,
        viewport: viewport,
      };
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      let show_pdf = document.getElementById("show_pdf");
      if (show_pdf) {
        show_pdf.appendChild(canvas);
        await page.render(renderContext).promise;
        const base64 = canvas.toDataURL("image/jpg");
        let fileNane = file.name.slice(0, -4) + ".jpg";
        if (pdfNum > 1)
          fileNane = file.name.slice(0, -4) + "_" + index + ".jpg";
        let uploadFile = createJpegFile(base64, fileNane);
        if (show_pdf) show_pdf.innerHTML = "";
        files.push(uploadFile);
      }
    }
    return files;
  };*/

  const changeUploadImage = async (event: any) => {
    setErrorMsgImgUp("");
    if (event.target.files[0].length !== 0) {
      const imageFile = event.target.files[0];
      if (
        imageFile.type === "image/png" ||
        imageFile.type === "image/jpg" ||
        imageFile.type === "image/jpeg" ||
        imageFile.type === "image/bmp"
      ) {
        setUploadFile(imageFile);
        setUploadFileUrl(URL.createObjectURL(imageFile));
        setChooseFileName(imageFile.name);

        setNoChooseFileFlg(false);
      } else {
        setErrorMsgImgUp(FILE_FORMAT_ERROR);
      }
    }
  };

  const backArrowClick = () => {
    if (selectImgId < 0) return;
    let index = img_file_list.indexOf(selectImgId);
    if (index === 0) return;
    if (img_file_list[index - 1]) changeImg(img_file_list[index - 1]);
  };

  const forwardArrowClick = () => {
    let index = img_file_list.indexOf(selectImgId);
    if (index === img_file_list.length) return;
    if (img_file_list[index + 1]) changeImg(img_file_list[index + 1]);
  };

  const changeCameraImage = (img: any) => {
    const imageFile = img;
    setUploadFile(imageFile);
    const imageUrl = URL.createObjectURL(imageFile);
    setUploadFileUrl(imageUrl);
    var date = new Date();
    var name =
      date.getFullYear() +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      ("0" + date.getDate()).slice(-2) +
      ("0" + date.getHours()).slice(-2) +
      ("0" + date.getMinutes()).slice(-2) +
      ".png";
    setChooseFileName(name);
    setNoChooseFileFlg(false);
  };

  const macNFC = (name: any) => {
    var myReg =
      /[^\u{0340}\u{0341}\u{0343}\u{0344}\u{0374}\u{037E}\u{0387}\u{0958}-\u{095F}\u{09DC}\u{09DD}\u{09DF}\u{0A33}\u{0A36}\u{0A59}-\u{0A5B}\u{0A5E}\u{0B5C}\u{0B5D}\u{0F43}\u{0F4D}\u{0F52}\u{0F57}\u{0F5C}\u{0F69}\u{0F73}\u{0F75}\u{0F76}\u{0F78}\u{0F81}\u{0F93}\u{0F9D}\u{0FA2}\u{0FA7}\u{0FAC}\u{0FB9}\u{1F71}\u{1F73}\u{1F75}\u{1F77}\u{1F79}\u{1F7B}\u{1F7D}\u{1FBB}\u{1FBE}\u{1FC9}\u{1FCB}\u{1FD3}\u{1FDB}\u{1FE3}\u{1FEB}\u{1FEE}\u{1FEF}\u{1FF9}\u{1FFB}\u{1FFD}\u{2000}\u{2001}\u{2126}\u{212A}\u{212B}\u{2329}\u{232A}\u{2ADC}\u{F900}-\u{FAFF}\u{FB1D}\u{FB1F}\u{FB2A}-\u{FB36}\u{FB38}-\u{FB3C}\u{FB3E}\u{FB40}\u{FB41}\u{FB43}\u{FB44}\u{FB46}-\u{FB4E}\u{1D15E}-\u{1D164}\u{1D1BB}-\u{1D1C0}\u{2F800}-\u{2FA1F}]+/gu;
    if (name.normalize)
      name = name.replace(myReg, function (r: any) {
        return r.normalize("NFC");
      });
    return name;
  };

  const checkImgFileName = (fileName: any) => {
    returnFileName = fileName;
    if (uploadFile.length > 1) {
      let cutLength = 0;
      let extension = ".";
      fileNameCnt++;
      switch (fileName.slice(-2)) {
        case "ng":
          cutLength = 4;
          extension += "png";
          break;
        case "pg":
          cutLength = 4;
          extension += "jpg";
          break;
        case "mp":
          cutLength = 4;
          extension += "bmp";
          break;
        case "eg":
          cutLength = 5;
          extension += "jpeg";
          break;
      }
      let newFileName = fileName.slice(0, -cutLength);
      newFileName = newFileName + "_" + fileNameCnt + extension;
      returnFileName = newFileName;
    }
  };

  const [noChooseFileFlg, setNoChooseFileFlg] = React.useState(true);
  const [uploadFlg, setUploadFlg] = React.useState(false);
  const [uploading, setUploading] = React.useState(false);
  const clickUploadExecute = async () => {
    if (uploadFile) {
      //画像アップロード
      if (S3) {
        fileNameCnt = 0;
        const filenamebef = chooseFileName ? chooseFileName : uploadFile.name;
        let filename = macNFC(filenamebef);
        checkImgFileName(filename);
        const checkedFileName =
          uploadFile.length > 1 ? returnFileName : filename;

        let result = await uploadS3(
          S3,
          "haishin/" +
            myuserData.service_id +
            "/" +
            classId +
            "/doc/pictuire/" +
            checkedFileName,
          uploadFile
        );
        await uploadS3(
          S3,
          "haishin/" +
            myuserData.service_id +
            "/" +
            classId +
            "/doc/thumbnail/" +
            checkedFileName,
          uploadFile
        );
        if (!result && retryCount < 2) {
          S3 = await createS3();
          clickUploadExecute();
        }
        await uploadClear(
          checkedFileName,
          "haishin/" +
            myuserData.service_id +
            "/" +
            classId +
            "/doc/pictuire/" +
            checkedFileName
        );

        saveOrderData();
        setUploadFlg(true);
        sendAction(ActionComand.MSG_IMGLIST_RESET, null, {});
      }

      setImgUploadDialogOpen(false);
      setUploading(false);
      setUploadFile(null);
      setNoChooseFileFlg(true);
      setUploadFileUrl("");
      setChooseFileName("");
      setErrorMsgImgUp("");
    }
  };

  const saveOrderData = async () => {
    let getData = await CT0307(dispatch, classId);
    if (getData && Object.keys(getData).indexOf("body") >= 0) {
      if (!orderList)
        orderList = { videoOrder: [], imgOrder: [], pdfOrder: [] };
      if (!orderList.imgOrder) orderList.imgOrder = [];
      for (let oi = 0; oi < getData.body.length; oi++) {
        if (
          orderList.imgOrder.indexOf(getData.body[oi].board_document_id) < 0
        ) {
          orderList.imgOrder.push(getData.body[oi].board_document_id);
        }
      }
      let jsonData = JSON.stringify(orderList);
      var blob = new Blob([jsonData], { type: "text/json" });
      var jsonFile = new File([blob], "order.json");
      S3 = await createS3();
      if (S3) {
        uploadS3(
          S3,
          "haishin/" +
            myuserData.service_id +
            "/" +
            classId +
            "/doc/order.json",
          jsonFile
        ).then(() => {
          getOrderList(false);
        });
      }
    }
  };

  const uploadClear = async (chooseFileName: any, s3id: any) => {
    await CT0303(
      dispatch,
      classId,
      chooseFileName,
      "1",
      null,
      null,
      null,
      "1",
      null,
      s3id
    );
  };

  const changeContentType = (fileName: any, file: any) => {
    switch (fileName) {
      case "jpg":
      case "jpeg":
        return file.replace(
          "data:application/octet-stream;base64,",
          "data:image/jpeg;base64,"
        );
      case "png":
        return file.replace(
          "data:application/octet-stream;base64,",
          "data:image/png;base64,"
        );
      case "gif":
        return file.replace(
          "data:application/octet-stream;base64,",
          "data:image/gif;base64,"
        );
      case "bmp":
        return file.replace(
          "data:application/octet-stream;base64,",
          "data:image/bmp;base64,"
        );
      case "json":
        return file.replace(
          "data:application/octet-stream;base64,",
          "data:application/json;base64,"
        );
      case "pdf":
        return file.replace(
          "data:application/octet-stream;base64,",
          "data:application/pdf;base64,"
        );

      default:
        return file;
    }
  };

  const getImgData = async (flg: boolean, callback?: any) => {
    let getData = await CT0307(dispatch, classId);
    if (getData && Object.keys(getData).indexOf("body") >= 0) {
      const S3 = await createS3();
      if (S3) {
        let imgL = [];
        let list: any = getData.body;
        if (flg) {
          img_file_data = {};
          img_file_list = [];
          if (orderList.imgOrder && orderList.imgOrder.length > 0) {
            for (let oi = 0; oi < orderList.imgOrder.length; oi++) {
              const odi = orderList.imgOrder[oi];
              const index = list.findIndex(
                (element: any) => Number(element.board_document_id) === odi
              );
              if (index !== -1) {
                const element = list[index];
                const thumbnailUrl = element.s3_id.replace(
                  "/pictuire/",
                  "/thumbnail/"
                );
                let file =
                  element.s3_id && element.s3_id !== ""
                    ? await getUrlS3(S3, thumbnailUrl)
                    : null;
                file = changeContentType(
                  element.s3_id.substr(-3).toUpperCase(),
                  file
                );
                element.file_url = file;
                img_file_data[element.board_document_id] = element.s3_id;
                img_file_list.push(element.board_document_id);
                list.splice(index, 1);
                imgL.push(element);
              }
            }
            if (list.length > 0) {
              for (let index = 0; index < list.length; index++) {
                const element = list[index];
                const thumbnailUrl = element.s3_id.replace(
                  "/pictuire/",
                  "/thumbnail/"
                );
                let file =
                  element.s3_id && element.s3_id !== ""
                    ? await getUrlS3(S3, thumbnailUrl)
                    : null;
                file = changeContentType(
                  element.s3_id.substr(-3).toUpperCase(),
                  file
                );
                element.file_url = file;
                img_file_data[element.board_document_id] = element.s3_id;
                img_file_list.push(element.board_document_id);
                imgL.push(element);
              }
            }
          } else {
            for (let index = 0; index < list.length; index++) {
              const element = list[index];
              const thumbnailUrl = element.s3_id.replace(
                "/pictuire/",
                "/thumbnail/"
              );
              let file =
                element.s3_id && element.s3_id !== ""
                  ? await getUrlS3(S3, thumbnailUrl)
                  : null;
              file = changeContentType(
                element.s3_id.substr(-3).toUpperCase(),
                file
              );
              element.file_url = file;
              img_file_data[element.board_document_id] = element.s3_id;
              img_file_list.push(element.board_document_id);
              imgL.push(element);
            }
          }
          setImgItems(imgL);
        } else {
          if (list.length > 0) {
            for (
              let index = Object.keys(img_file_data).length;
              index < list.length;
              index++
            ) {
              const element = list[index];
              let file =
                element.s3_id && element.s3_id !== ""
                  ? await getUrlS3(S3, element.s3_id)
                  : null;
              file = changeContentType(
                element.s3_id.substr(-3).toUpperCase(),
                file
              );
              element.file_url = file;
              img_file_data[element.board_document_id] = element.s3_id;
              img_file_list.push(element.board_document_id);
              imgL.push(element);
            }
          }
        }
      }
    }
    if (callback) callback();
  };

  // type videoItem = {
  //   video_id: string; //動画ID,
  //   video_name: string; //動画ファイル名
  //   s3_id: string; //動画URL
  //   file_url: any;
  // };
  // const [videoLists, setvideoLists] = React.useState<videoItem[]>([]);
  //動画取り込み
  // const getVideoData = async (callback: any) => {
  //   let getData = await CT0308(dispatch, classId);

  //   if (getData && Object.keys(getData).indexOf("body") >= 0) {
  //     let videoL = [];
  //     let list = getData.body;
  //     if (!S3) S3 = await createS3();
  //     if (
  //       orderList &&
  //       orderList.videoOrder &&
  //       orderList.videoOrder.length > 0
  //     ) {
  //       for (let oi = 0; oi < orderList.videoOrder.length; oi++) {
  //         const odi = orderList.videoOrder[oi];
  //         for (let index = 0; index < list.length; index++) {
  //           const element = list[index];
  //           if (odi === Number(element.video_id)) {
  //             const file_url =
  //               element.s3_id && element.s3_id !== ""
  //                 ? await getUrlS3(
  //                     S3,
  //                     "haishin/" +
  //                       myuserData.service_id +
  //                       "/" +
  //                       classId +
  //                       "/doc/movie/" +
  //                       element.video_name
  //                   )
  //                 : null;
  //             element.file_url = file_url;
  //             list.splice(index, 1);
  //             videoL.push(element);
  //             break;
  //           }
  //         }
  //       }
  //       if (list.length > 0) {
  //         for (let index = 0; index < list.length; index++) {
  //           const element = list[index];
  //           const file_url =
  //             element.s3_id && element.s3_id !== ""
  //               ? await getUrlS3(
  //                   S3,
  //                   "haishin/" +
  //                     myuserData.service_id +
  //                     "/" +
  //                     classId +
  //                     "/doc/movie/" +
  //                     element.video_name
  //                 )
  //               : null;
  //           element.file_url = file_url;
  //           videoL.push(element);
  //         }
  //       }
  //     } else {
  //       for (let index = 0; index < getData.body.length; index++) {
  //         const element = getData.body[index];
  //         const file_url =
  //           element.s3_id && element.s3_id !== ""
  //             ? await getUrlS3(
  //                 S3,
  //                 "haishin/" +
  //                   myuserData.service_id +
  //                   "/" +
  //                   classId +
  //                   "/doc/movie/" +
  //                   element.video_name
  //               )
  //             : null;
  //         element.file_url = file_url;
  //         videoL.push(element);
  //       }
  //     }
  //     setvideoLists(videoL);
  //   }
  //   if (callback) callback();
  // };

  // const changevideoScale = (flg: boolean) => {
  //   const value = flg ? scale : 1;
  //   canvasImg.style.transform = "scale(" + String(value) + ")";
  //   if (flg) {
  //     imgCanvasDiv.scrollTop = drowCanvasDiv.scrollTop;
  //     imgCanvasDiv.scrollLeft = drowCanvasDiv.scrollLeft;
  //   }
  // };

  // const [videoId, setVideoId] = React.useState();
  const [loading, setLoading] = React.useState(false);

  //動画挿入
  // const drawVideo = (value: any) => {
  //   var video = document.getElementById("showvideo") as HTMLVideoElement;
  //   if (videoInterval) {
  //     clearInterval(videoInterval);
  //     videoInterval = null;
  //   }
  //   setLoading(true);

  //   const find = videoLists.find((element) => element.video_id === value);
  //   if (find) {
  //     video.src = find.file_url;
  //     operation = OPERATION_TYPE.VIDEO;
  //     setDisableType(OPERATION_TYPE.VIDEO);
  //     video.load();
  //     video.play();
  //     videoInterval = setInterval(function () {
  //       let path = store.getState().router.location.pathname;
  //       if (path !== "/cls/008") {
  //         clearInterval(videoInterval);
  //         return;
  //       }
  //       ctxI.drawImage(
  //         video,
  //         0,
  //         0,
  //         ctx.canvas.clientWidth,
  //         ctx.canvas.clientHeight
  //       );
  //       ctxR.drawImage(
  //         video,
  //         0,
  //         0,
  //         ctx.canvas.clientWidth,
  //         ctx.canvas.clientHeight
  //       );

  //       setVideoTime(timeFoemat(video.duration));
  //       setNowVideoTime(timeFoemat(video.currentTime));
  //       const percent =
  //         Math.round((video.currentTime / video.duration) * 1000) / 10;
  //       seekbar.style.backgroundSize = percent + "%";
  //     }, 1000 / 15);
  //   } else {
  //     setTimeout(() => drawVideo(value), 500);
  //   }
  //   setLoading(false);
  // };

  // var seekbar = document.getElementById("seekbar") as HTMLElement;

  //シークバークリック
  // const seekClick = (e: any) => {
  //   const duration = Math.round(video.duration);
  //   if (!isNaN(duration)) {
  //     const mouse = e.pageX;
  //     const rect = seekbar.getBoundingClientRect();
  //     const position = rect.left + window.pageXOffset;
  //     const offset = mouse - position;
  //     const width = rect.right - rect.left;
  //     video.currentTime = Math.round(duration * (offset / width));
  //     // const videoTime = timeFoemat(video.currentTime);
  //     sendAction(ActionComand.MSG_WB_MOVIE_FORWARD_REWIND, null, {
  //       time: video.currentTime,
  //     });
  //     setNowVideoTime(videoTime);
  //   }
  // };

  //時間フォーマット変更
  const timeFoemat = (time: any) => {
    var sec = String(
      ((Math.floor(time) % (24 * 60 * 60)) % (60 * 60)) % 60
    ).padStart(2, "0");
    var min = String(
      Math.floor(((time % (24 * 60 * 60)) % (60 * 60)) / 60)
    ).padStart(2, "0");
    var hour = String(Math.floor((time % (24 * 60 * 60)) / (60 * 60))).padStart(
      2,
      "0"
    );

    return hour + ":" + min + ":" + sec;
  };
  //動画再生
  // const playVideo = () => {
  //   sendAction(ActionComand.MSG_WB_SHOW_MOVIE, null, {
  //     video_id: videoId,
  //     first_flg: false,
  //   });
  //   video.play();
  // };

  //動画一時停止
  // const pauseVideo = () => {
  //   sendAction(ActionComand.MSG_WB_MOVIE_PAUSE, null, {
  //     time: nowVideoTime,
  //   });
  //   video.pause();
  // };

  //動画停止
  // const stopVideo = () => {
  //   sendAction(ActionComand.MSG_WB_MOVIE_STOP, null, null);
  //   video.pause();
  //   setClassVideo("");
  //   video.load();
  //   setShowVideoMenu(true);
  //   operation = OPERATION_TYPE.PEN;
  //   setDisableType("");
  //   getS3Json(selectBoardId);
  //   ctxI.clearRect(0, 0, ctx.canvas.clientWidth, ctx.canvas.clientHeight);
  //   changevideoScale(true);
  //   if (imageData) ctxI.putImageData(imageData, 0, 0);
  //   if (videoInterval) {
  //     clearInterval(videoInterval);
  //     videoInterval = null;
  //   }
  // };
  const [disableType, setDisableType] = React.useState("");
  function gotLocalMediaStream() {
    if (localVideo) {
      // if (boardTime) {
      //   clearInterval(FPSIVL);
      //   FPSIVL = null;
      // }
      setShareState(true);
      operation = OPERATION_TYPE.SHARE;
      setDisableType(OPERATION_TYPE.SHARE);
      if (!micStatus) {
        sendAction(actionComand.MSG_DISPLAY_CLICK, null, {});
      }
      var left;
      var top;
      let width;
      let height;
      srceenShareInterval = setInterval(function () {
        try {
          // if (FPSIVL) {
          //   clearInterval(FPSIVL);
          //   FPSIVL = null;
          // }
          // var canvasW = ctx.canvas.width;
          // var canvasH = ctx.canvas.height;
          var canvasW = canvasData.w;
          var canvasH = canvasData.h;
          ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
          var imgAspect = localVideo.videoWidth / localVideo.videoHeight;
          if (localVideo.videoWidth > localVideo.videoHeight) {
            width = canvasW;
            height = canvasW / imgAspect;
            if (height > canvasH) {
              width = canvasH * imgAspect;
              height = canvasH;
              top = 0;
              left = Math.floor((canvasW - width) / 2);
            } else {
              left = 0;
              top = Math.floor((canvasH - height) / 2);
            }
          } else {
            width = canvasH * imgAspect;
            height = canvasH;
            if (width > canvasW) {
              width = canvasW;
              height = canvasW / imgAspect;
              left = 0;
              top = Math.floor((canvasH - height) / 2);
            } else {
              top = 0;
              left = Math.floor((canvasW - width) / 2);
            }
          }
          ctx.drawImage(localVideo, left, top, width, height);
        } catch (error: any) {
          clearInterval(srceenShareInterval);
        }
      }, 1000 / 15);
    }
  }

  async function stopStreamedVideo() {
    clearInterval(srceenShareInterval);
    // if (boardTime && !FPSIVL) {
    //   FPSIVL = setInterval(() => {
    //     if (!refRecordingState.current) {
    //       clearInterval(FPSIVL);
    //       FPSIVL = null;
    //     } else {
    //       if (boardTime) {
    //         // if (operation === OPERATION_TYPE.PEN && drowing) return;
    //         if (!FPSIVLNo || FPSIVLNo === 9) FPSIVLNo = 0;
    //         FPSIVLNo++;
    //         boardTime.set({ text: String(FPSIVLNo) });
    //         FabCanvas.renderAll();
    //       }
    //     }
    //   }, 1000 / 15);
    // }
    operation = OPERATION_TYPE.PEN;
    var drowCanvas = document.getElementById("drowCanvas");
    if (drowCanvas) drowCanvas.style.display = "block";
    setDisableType("");
    setLoading(true);
    var newObject = [];
    for (let a = 0; a < shareVideo.objects.length; a++) {
      if (shareVideo.objects[a].type !== "group") {
        shareVideo.objects[a].selectable = false;
        if (
          shareVideo.objects[a].type === "image" &&
          shareVideo.objects[a].globalCompositeOperation === "back_ground_image"
        ) {
          if (
            selectImgNo &&
            Number(selectImgNo) > 0 &&
            img_file_data[selectImgNo]
          ) {
            const s3 = await createS3();
            const fileUrl = await getUrlS3(s3, img_file_data[selectImgNo]);
            shareVideo.objects[a].src = fileUrl;
          }
        }
        newObject.push(shareVideo.objects[a]);
      }
    }
    shareVideo.objects = newObject;
    FabCanvas.loadFromJSON(shareVideo);
    FabCanvas.renderAll();
    setTimeout(() => {
      makeDataOfJson().then(() => {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      });
    }, 1000);
    for (const trackName in shareLocalTracks) {
      var track = shareLocalTracks[trackName];
      if (track) {
        track.stop();
        track.close();
        shareLocalTracks[trackName] = undefined;
      }
    }
    share_client.localTracks.forEach((v: any) => v.stop());
    share_client.localTracks.forEach((v: any) => v.close());
    share_client.unpublish().then(() => {
      share_client.leave().then(() => {
        share_client.stopProxyServer();
      });
    });
    let shareWinPlayer: any = document.getElementById("share-win-player");
    if (shareWinPlayer) shareWinPlayer.innerHTML = "";
    if (localVideo !== null) {
      localVideo = null;
      setShareState(false);
    }
  }

  const windowShare = async () => {
    if (operation === OPERATION_TYPE.SHARE) {
      stopStreamedVideo();
    } else {
      AgoraRTC.createScreenVideoTrack(
        {
          encoderConfig: {
            bitrateMax: 1000,
            bitrateMin: 500,
            frameRate: 15,
            height: 576,
            width: 1024,
          },
        },
        "auto"
      ).then((shareTrack: any) => {
        shareVideo = FabCanvas.toJSON();
        var drowCanvas = document.getElementById("drowCanvas");
        if (drowCanvas) drowCanvas.style.display = "none";
        FabCanvas.clear();
        share_client.setClientRole("host");
        if (shareTrack.length > 1) {
          shareLocalTracks.videoTrack = shareTrack[0];
          shareLocalTracks.audioTrack = shareTrack[1];
          share_client
            .join(agoraInfo.app_id, classInfo.channelId, agoraInfo.token, 19999)
            .then((uid: any) => {
              localVideoTrack
                .setEncoderConfiguration({
                  width: 640,
                  height: 360,
                  frameRate: 15,
                })
                .then(() => {
                  share_client
                    .publish([
                      shareLocalTracks.audioTrack,
                      shareLocalTracks.videoTrack,
                    ])
                    .then(() => {
                      /*var cl: any = share_client;
                      if (!cl._isDualStreamEnabled)
                        share_client.enableDualStream().then(() => {
                          client.setLowStreamParameter({
                            width: 160,
                            height: 120,
                          });
                        });*/
                      shareLocalTracks.videoTrack.play("share-win-player");
                      localVideo = document.getElementById(
                        "video_" + shareLocalTracks.videoTrack._ID
                      ) as HTMLMediaElement;
                      gotLocalMediaStream();
                      let checkVideoElementStatus = setInterval(() => {
                        if (localVideo && refRecordingState.current) {
                          if (
                            shareLocalTracks.videoTrack._player
                              ._videoElementStatus === "suspend"
                          )
                            stopStreamedVideo();
                        } else {
                          clearInterval(checkVideoElementStatus);
                        }
                      }, 1000);
                    });
                });
            });
        } else {
          shareLocalTracks.videoTrack = shareTrack;
          share_client
            .join(agoraInfo.app_id, classInfo.channelId, agoraInfo.token, 19999)
            .then((uid: any) => {
              localVideoTrack
                .setEncoderConfiguration({
                  width: 640,
                  height: 360,
                  frameRate: 15,
                })
                .then(() => {
                  share_client.publish(shareLocalTracks.videoTrack).then(() => {
                    /*var cl: any = share_client;
                    if (!cl._isDualStreamEnabled)
                      share_client.enableDualStream().then(() => {
                        client.setLowStreamParameter({
                          width: 160,
                          height: 120,
                        });
                      });*/
                    shareLocalTracks.videoTrack.play("share-win-player");
                    localVideo = document.getElementById(
                      "video_" + shareLocalTracks.videoTrack._ID
                    ) as HTMLMediaElement;
                    gotLocalMediaStream();
                    let checkVideoElementStatus = setInterval(() => {
                      if (localVideo && refRecordingState.current) {
                        if (
                          shareLocalTracks.videoTrack._player
                            ._videoElementStatus === "suspend"
                        )
                          stopStreamedVideo();
                      } else {
                        clearInterval(checkVideoElementStatus);
                      }
                    }, 1000);
                  });
                });
            });
        }
      });
    }
  };

  //拡大縮小
  const changeScale = (value: any, callback?: any) => {
    expansionClose();
    value === "1" ? setHandFlg(false) : setHandFlg(true);
    FabCanvas.discardActiveObject();
    var dataList = FabCanvas.getObjects();
    var selectData = [];
    for (let index = 0; index < dataList.length; index++) {
      if (
        dataList[index].globalCompositeOperation &&
        dataList[index].type !== "text" &&
        dataList[index].type !== "group"
      )
        selectData.push(dataList[index]);
    }
    var canvasW = defaultWidth;
    var canvasH = defaultWidth / (16 / 9);
    var checkTop = imgBackground.top;
    var checkLeft = imgBackground.left;
    if (String(value) === "1") {
      var sel = new fabric.ActiveSelection(selectData, {
        canvas: FabCanvas,
      });
      var newTop =
        (Math.abs(checkTop) - Math.abs(sel.top ? sel.top : 0)) / cscale;
      var newLeft =
        (Math.abs(checkLeft) - Math.abs(sel.left ? sel.left : 0)) / cscale;
      sel.set({
        left: newLeft,
        top: newTop,
        scaleX: 1 / cscale,
        scaleY: 1 / cscale,
        hasControls: false,
        hasBorders: false,
        hoverCursor: "pointer",
        moveCursor: "pointer",
        angle: 0,
      });
      setActive = true;
      FabCanvas.setActiveObject(sel);
      FabCanvas.requestRenderAll();
      FabCanvas.discardActiveObject();
      setActive = false;
      // drawImg(selectImgUrl);
      FabCanvas.renderAll();
    } else {
      var left = (Number(canvasW) * (Number(value) - 1)) / 2;
      var top = (Number(canvasH) * (Number(value) - 1)) / 2;
      var sel = new fabric.ActiveSelection(selectData, {
        canvas: FabCanvas,
      });
      var newTop =
        top +
        Math.abs(
          ((Math.abs(checkTop) - Math.abs(sel.top ? sel.top : 0)) / cscale) *
            Number(value)
        );
      var newLeft =
        left +
        Math.abs(
          ((Math.abs(checkLeft) - Math.abs(sel.left ? sel.left : 0)) / cscale) *
            Number(value)
        );
      sel.set({
        left: -newLeft,
        top: -newTop,
        scaleX: (1 / cscale) * Number(value),
        scaleY: (1 / cscale) * Number(value),
        hasControls: false,
        hasBorders: false,
        hoverCursor: "pointer",
        moveCursor: "pointer",
        angle: 0,
      });
      setActive = true;
      FabCanvas.setActiveObject(sel);
      FabCanvas.requestRenderAll();
      FabCanvas.discardActiveObject();
      setActive = false;
      // drawImg(selectImgUrl);
      FabCanvas.renderAll();
    }
    setScale(value);
    cscale = value;
    changeJson();
    sendAction(ActionComand.MSG_WB_EXPANTION, null, {
      scale: value,
    });
    // imgBackground.moveTo(0);
    if (callback) callback();
  };

  //ボード切替
  const boardChange = async (newBoard: any) => {
    if (Number(newBoard.boardNo) !== Number(selectBoardId)) {
      // await setWbImg();
      changeBoardFlg = true;
      setLoading(true);
      nowBoardId = selectBoardId;
      await setWhiteBoardList();
      uplodeJson(null, newBoard.boardNo);
    } else {
      wbListClose();
    }
  };

  if (screenIsSmallFlg !== screenIsSmall) {
    screenIsSmallFlg = screenIsSmall;
    winResize();
  }
  //マウスが押された際の関数
  const popupMousedown = (e: any) => {
    const Qzpopup: any = document.getElementsByClassName("drag-and-drop");
    //要素内の相対座標を取得
    if (Qzpopup) {
      pMouseMoveFlg = true;
      popupX = e.pageX - Qzpopup[0].offsetLeft;
      popupY = e.pageY - Qzpopup[0].offsetTop;
      //ムーブイベントにコールバック
      document.body.addEventListener("mousemove", popupMousemove, false);
    }
  };

  //マウスカーソルが動いたときに発火
  const popupMousemove = (e: any) => {
    if (pMouseMoveFlg) {
      const Qzpopup: any = document.getElementsByClassName("drag-and-drop");
      //フリックしたときに画面を動かさないようにデフォルト動作を抑制
      e.preventDefault();

      //マウスが動いた場所に要素を動かす
      if (Qzpopup && Object.keys(Qzpopup).length > 0) {
        Qzpopup[0].style.top = e.pageY - popupY + "px";
        Qzpopup[0].style.left = e.pageX - popupX + "px";
      }

      //マウスボタンが離されたとき発火
    }
    document.body.addEventListener("mouseup", popupMouseup, false);
  };

  //マウスボタンが上がったら発火
  const popupMouseup = () => {
    pMouseMoveFlg = false;
    //ムーブベントハンドラの消去
    document.body.removeEventListener("mousemove", popupMousemove, false);
  };

  const popState = (e: any) => {
    if (store.getState().router.location.pathname === "/cls/008") {
      window.history.go(1);
    }
  };

  //退出
  const getOut = async (flg: boolean) => {
    endFlg = true;
    window.removeEventListener("popstate", popState);
    setClassFlg(false);

    if (localVideo && localVideo.srcObject !== null) stopStreamedVideo();
    await saveMessageData(minute);
    await saveMessageListData();
    const list: any = await getListS3(
      S3,
      "haishin/" +
        store.getState().user.service_id +
        "/" +
        classId +
        "/checkClass/"
    );
    if (list && list.length > 0) {
      for (let index = 0; index < list.length; index++) {
        if (list[index].Key !== "/") {
          await deleteS3(S3, list[index].Key);
        }
      }
    }
    if (process.env.REACT_APP_AWS_S3_UPLOG === "true")
      consoleLogOut(null, null, true);
    if (classState) {
      sendAction(actionComand.MSG_STREAMER_CHANGE, null, {}).then(() => {
        socket.close();
        channelRTM?.leave();
        clientRTM?.logout();
        clearInterval(ViewLogInterval);
        localStorage.setItem("Cls008_session", "");
        localStorage.setItem("classTimeCount", "");
        localStorage.setItem("Cls008_classId", "");
        localStorage.setItem("classStartTime", "");
        dispatch(push("/cls/001"));
      });
    } else {
      classTimeAddFlg = false;
      sendAction(actionComand.MSG_CLASS_END, null, {});
      uploadClassEndJson(flg).then(() => {
        socket.close();
        channelRTM?.leave();
        clientRTM?.logout();
        clearInterval(ViewLogInterval);
        localStorage.setItem("Cls008_session", "");
        localStorage.setItem("classTimeCount", "");
        localStorage.setItem("Cls008_classId", "");
        localStorage.setItem("classStartTime", "");
        dispatch(push("/cls/001"));
      });
      //if (!flg)
      //  sendAction(actionComand.MSG_CLASS_END, null, { status_flg: flg });
      //socket.close();
      //localStorage.setItem("Cls008_session", "");
      //dispatch(push("/cls/001"));
    }
  };

  const uploadClassEndJson = async (flg: any) => {
    let classEndJson = JSON.stringify({
      status_flg: flg,
      class_time: minute + 1,
    });
    const classEndJsonFile: any = new Blob([classEndJson], {
      type: "application/json",
    });
    S3 = await createS3();
    let fileName =
      "haishin/" +
      store.getState().user.service_id +
      "/" +
      classId +
      "/doc/end.json";
    const uploadData = async (jsonFile: any) => {
      if (!(await uploadS3(S3, fileName, jsonFile)))
        setTimeout(() => uploadData(jsonFile), 1000);
    };
    uploadData(classEndJsonFile);
  };

  const [uploadErrorOpen, setUploadErrorOpen] = React.useState(false);

  const closeUploadErrorDialg = () => {
    setUploadErrorOpen(false);
    wbListClose();
  };

  const changeJson = async () => {
    if (!saveJsonFlg) saveJsonFlg = { doNo: 0, saveFlg: false };
    if (!saveJsonFlg.saveFlg) {
      saveJsonFlg.saveFlg = true;
      const saveJson = async () => {
        if (saveJsonFlg.doNo === 0 && !loading) {
          jsonSaveNo++;
          let canvasJson = JSON.stringify({
            save_no: jsonSaveNo,
            lineList: lineList,
            shapeList: shapeList,
            scale: cscale,
            img_no: selectImgNo,
            json: FabCanvas.toJSON(),
          });
          const canvasJsonFile: any = new Blob([canvasJson], {
            type: "application/json",
          });
          S3 = await createS3();
          let fileName =
            "haishin/" +
            store.getState().user.service_id +
            "/" +
            classId +
            "/board_" +
            selectBoardId +
            "/" +
            "canvas_" +
            selectBoardId +
            ".json";
          await deleteS3(S3, fileName);
          await uploadS3(S3, fileName, canvasJsonFile);
          if (saveJsonFlg.doNo > 0) {
            saveJsonFlg.doNo = 0;
            setTimeout(() => saveJson(), 1000);
          } else {
            saveJsonFlg = { doNo: 0, saveFlg: false };
          }
        } else {
          saveJsonFlg.doNo = 0;
          setTimeout(() => saveJson(), 1000);
        }
      };
      setTimeout(() => saveJson(), 1000);
    }
    saveJsonFlg.doNo++;
  };

  const uplodeJson = async (userId: any, newboardId: any, callback?: any) => {
    jsonSaveNo++;
    let canvasJson = JSON.stringify({
      next_board_no: newboardId,
      save_no: jsonSaveNo,
      lineList: lineList,
      shapeList: shapeList,
      scale: cscale,
      img_no: selectImgNo,
      json: FabCanvas.toJSON(),
    });
    const canvasJsonFile: any = new Blob([canvasJson], {
      type: "application/json",
    });
    if (!S3) S3 = await createS3();
    let fileName =
      "haishin/" +
      store.getState().user.service_id +
      "/" +
      classId +
      "/board_" +
      selectBoardId +
      "/" +
      "canvas_" +
      selectBoardId +
      ".json";
    await deleteS3(S3, fileName);
    var result = await uploadS3(S3, fileName, canvasJsonFile);
    if (result) {
      selectBoardId = newboardId;
      getS3Json(newboardId);
      wbListClose();

      sendAction(ActionComand.MSG_WB_CHANGE_SLIDE, null, {
        board_no: selectBoardId,
      });
      if (callback) callback();
    } else if (retryCount <= 2) {
      retryCount++;
      S3 = await createS3();
      setTimeout(uplodeJson, 1000, null, newboardId);
    } else {
      retryCount = 0;
      if (userId === null) {
        setUploadErrorOpen(true);
        setLoading(false);
        changeBoardFlg = false;
      }
    }
  };

  const makeDataOfJson = async () => {
    let list = FabCanvas.getObjects();
    boardTime = null;
    if (list.length > 0) {
      for (let index = 0; index < list.length; index++) {
        const element = list[index];
        if (lineList[element.globalCompositeOperation]) {
          element.set({
            name: element.globalCompositeOperation,
            selectable: false,
            hoverCursor: "default",
          });
          lineList[element.globalCompositeOperation] = element;
        }
        if (shapeList[element.globalCompositeOperation]) {
          element.set({
            selectable: true,
            name: element.globalCompositeOperation,
          });
          shapeList[element.globalCompositeOperation] = element;
        }
        if (element.globalCompositeOperation === "back_ground_color") {
          element.set({
            name: element.globalCompositeOperation,
            selectable: false,
            evented: false,
          });
          imgBackground = element;
        }
        if (
          element.globalCompositeOperation.indexOf("back_ground_image") >= 0
        ) {
          element.set({
            name: element.globalCompositeOperation,
            selectable: false,
            evented: false,
          });
          nowImageData = element;
        }
        if (element.globalCompositeOperation === "back_ground_text") {
          element.set({
            selectable: false,
            evented: false,
          });
          boardTime = element;
        }
      }
    }
    if (!boardTime) {
      boardTime = new fabric.Text("0", {
        globalCompositeOperation: "back_ground_text",
        fontFamily: "Arial",
        fontSize: 1,
        fill: "rgba(0,0,0,0.1)",
        fontWeight: "bold",
        left: 0,
        top: 0,
        selectable: false,
        evented: false,
      });
      FabCanvas.add(boardTime);
    }
    FabCanvas.renderAll();
    // setLoading(false);
  };

  const getS3Json = async (boardNo: Number) => {
    //Json取得
    var fileReader = new FileReader();
    if (!S3) S3 = await createS3();
    var filePath =
      "haishin/" +
      store.getState().user.service_id +
      "/" +
      classId +
      "/board_" +
      boardNo +
      "/" +
      "canvas_" +
      boardNo +
      ".json";
    let file: any = await getFileS3(S3, filePath);
    if (file) {
      fileReader.readAsText(file);
      fileReader.onload = async function (json: any) {
        let canvasJson = json.currentTarget.result;
        if (canvasJson) {
          canvasJson = JSON.parse(canvasJson);
          if (
            canvasJson.lineList &&
            Object.keys(canvasJson.lineList).length > 0
          ) {
            lineList = canvasJson.lineList;
          }
          if (
            canvasJson.shapeList &&
            Object.keys(canvasJson.shapeList).length > 0
          ) {
            shapeList = canvasJson.shapeList;
          }
          if (canvasJson.json) {
            var newObject = [];
            for (let a = 0; a < canvasJson.json.objects.length; a++) {
              if (canvasJson.json.objects[a].type !== "group") {
                canvasJson.json.objects[a].selectable = false;
                if (
                  canvasJson.json.objects[a].type === "image" &&
                  canvasJson.json.objects[a].globalCompositeOperation ===
                    "back_ground_image"
                ) {
                  if (
                    canvasJson.img_no &&
                    Number(canvasJson.img_no) > 0 &&
                    img_file_data[canvasJson.img_no]
                  ) {
                    const s3 = await createS3();
                    const fileUrl = await getUrlS3(
                      s3,
                      img_file_data[canvasJson.img_no]
                    );
                    canvasJson.json.objects[a].src = fileUrl;
                  }
                }
                newObject.push(canvasJson.json.objects[a]);
              }
            }
            canvasJson.json.objects = newObject;
            FabCanvas.loadFromJSON(canvasJson.json, () => {
              FabCanvas.renderAll();
              // setLoading(false);
              setTimeout(() => {
                makeDataOfJson().then(() => {
                  setTimeout(() => {
                    setLoading(false);
                    changeBoardFlg = false;
                  }, 1000);
                });
              }, 2000);
            });
          }
          cscale = 1;
          if (canvasJson.scale) cscale = Number(canvasJson.scale);
          setScale(cscale);
          cscale === 1 ? setHandFlg(false) : setHandFlg(true);
          if (canvasJson.img_no && Number(canvasJson.img_no) > 0) {
            const found = imgItems.find(
              (element) => element.board_document_id === canvasJson.img_no
            );
            if (found) {
              setSelectImgId(canvasJson.img_no);
              selectImgNo = canvasJson.img_no;
              selectImgUrl = found ? found.file_url : "";
            }
          } else {
            setSelectImgId(-1);
            selectImgNo = -1;
            selectImgUrl = null;
          }
          changeJson();
        }
      };
    }
  };

  const [dialogOpen, setDialogOpen] = React.useState(false);
  const handleClickOpen = () => {
    setDialogOpen(true);
  };

  const handleClickClose = () => {
    setDialogOpen(false);
  };

  // const drawBrush = (event: any) => {
  //   if (
  //     event &&
  //     event.target &&
  //     event.target.className.length > 0 &&
  //     event.target.className.indexOf("canvas") > 0
  //   ) {
  //     let x = event.offsetX;
  //     let y = event.offsetY;
  //     if (
  //       event.type === "touchstart" ||
  //       event.type === "touchmove" ||
  //       event.type === "touchend"
  //     ) {
  //       const rect = event.target.getBoundingClientRect();
  //       x = event.touches[0].clientX - window.pageXOffset - rect.left;
  //       y = event.touches[0].clientY - window.pageYOffset - rect.top;
  //     }
  //     ctx.lineWidth = Math.floor((penWidth - 1) * zoom);
  //     if (penColor.slice(-4) === "0.1)") ctx.globalAlpha = 0.01;
  //     ctx.strokeStyle = penColor;
  //     if (clickBrushFlg === 1) {
  //       clickBrushFlg = 2;
  //       ctx.beginPath();
  //       ctx.lineCap = "round";
  //       ctx.miterLimit = 1;
  //       ctx.moveTo(x, y);
  //     } else {
  //       ctx.lineTo(x, y);
  //     }
  //     ctx.stroke();
  //   } else {
  //     if (clickBrushFlg > 0) clickBrushFlg = 1;
  //   }
  // };

  const mouDown = (event: any) => {
    drowing = true;
    var pointer = FabCanvas.getPointer(event.e);
    switch (operation) {
      case OPERATION_TYPE.PEN: //ペン
        // brush.color = penColor;
        // brush.width = penWidth;
        lineNo = 0;
        lineListData = [];
        lineId =
          "line_" +
          loginUserId +
          store.getState().user.user_class +
          "_" +
          new Date().getTime().toString();
        // brush.onMouseDown(
        //   { x: pointer.x, y: pointer.y },
        //   { e: { isPrimary: true } }
        // );
        lineListData.push({
          point: { x: pointer.x, y: pointer.y },
          no: lineNo,
          flg: "S",
        });
        let path: any = [];
        path[0] = ["M", pointer.x, pointer.y];
        lineList[lineId] = new fabric.Path(path, {
          name: lineId,
          globalCompositeOperation: lineId,
          objectCaching: false,
          selectable: false,
          evented: false,
          hoverCursor: "default",
          stroke: penColor,
          strokeWidth: penWidth,
          strokeLineCap: "round",
          strokeDashOffset: 0,
          strokeLineJoin: "round",
          strokeUniform: false,
          strokeMiterLimit: 10,
          fill: "",
        });
        FabCanvas.add(lineList[lineId]);
        FabCanvas.renderAll();
        origX = pointer.x;
        origY = pointer.y;
        // clickBrushFlg = 1;
        // drawBrush(event);
        break;
      case OPERATION_TYPE.SHAPE: //基本図形
        FabCanvas.renderAll();
        shapeId =
          "shape_" +
          loginUserId +
          store.getState().user.user_class +
          "_" +
          new Date().getTime().toString();
        switch (shapeType) {
          case SHAPE_TYPE.SQUARE:
            origX = pointer.x;
            origY = pointer.y;
            addShapeRect = new fabric.Rect({
              name: shapeId,
              globalCompositeOperation: shapeId,
              left: origX,
              top: origY,
              originX: "left",
              originY: "top",
              width: 1,
              height: 1,
              angle: 0,
              fill: "rgba(255,255,255,0.0)",
              strokeWidth: penWidth,
              stroke: penColor,
              selectable: true,
              transparentCorners: false,
              noScaleCache: false,
              strokeUniform: true,
            });
            shapeList[shapeId] = addShapeRect;
            FabCanvas.add(addShapeRect);
            FabCanvas.setActiveObject(addShapeRect);
            FabCanvas.renderAll();
            break;
          case SHAPE_TYPE.LINE:
            mousePoint.px = pointer.x;
            mousePoint.py = pointer.y;
            var points = [pointer.x, pointer.y, pointer.x, pointer.y];
            addShapeLine = new fabric.Line(points, {
              name: shapeId,
              globalCompositeOperation: shapeId,
              strokeWidth: penWidth,
              fill: penColor,
              stroke: penColor,
              selectable: true,
              originX: "center",
              originY: "center",
              noScaleCache: false,
              strokeUniform: true,
            });
            shapeList[shapeId] = addShapeLine;
            FabCanvas.add(addShapeLine);
            FabCanvas.setActiveObject(addShapeLine);
            FabCanvas.renderAll();
            break;
          case SHAPE_TYPE.CIRCLE:
            origX = pointer.x;
            origY = pointer.y;
            addShapeCircle = new fabric.Circle({
              name: shapeId,
              globalCompositeOperation: shapeId,
              left: pointer.x,
              top: pointer.y,
              radius: 1,
              strokeWidth: penWidth,
              stroke: penColor,
              selectable: true,
              fill: "rgba(255,255,255,0.0)",
              originX: "center",
              originY: "center",
              noScaleCache: false,
              strokeUniform: true,
            });
            shapeList[shapeId] = addShapeCircle;
            FabCanvas.add(addShapeCircle);
            FabCanvas.setActiveObject(addShapeCircle);
            FabCanvas.renderAll();
            break;
          case SHAPE_TYPE.TRIANGLE:
            origX = pointer.x;
            origY = pointer.y;
            addShapeTriangle = new fabric.Triangle({
              name: shapeId,
              globalCompositeOperation: shapeId,
              left: pointer.x,
              top: pointer.y,
              width: 1,
              height: 1,
              angle: 0,
              fill: "rgba(255,255,255,0.0)",
              strokeWidth: penWidth,
              stroke: penColor,
              selectable: true,
              noScaleCache: false,
              strokeUniform: true,
            });
            shapeList[shapeId] = addShapeTriangle;
            FabCanvas.add(addShapeTriangle);
            FabCanvas.setActiveObject(addShapeTriangle);
            FabCanvas.renderAll();
            break;
        }
        break;
      case OPERATION_TYPE.CLEAR: //消しゴム
        break;
      case OPERATION_TYPE.HAND: //スクロール
        origX = pointer.x;
        origY = pointer.y;
        break;
      case OPERATION_TYPE.SELECT: //指先表示
        handId = "hand_" + new Date().getTime().toString();
        handNo = 0;
        sendAction(actionComand.MSG_WB_DRAW, null, {
          point: {
            x: pointer.x,
            y: pointer.y,
          },
          id: handId,
          no: handNo,
          flg: "S",
          board_event_type: 2,
        });
        handImg.set({ left: pointer.x, top: pointer.y });
        FabCanvas.add(handImg);
        FabCanvas.renderAll();

        break;
    }
  };

  const mouMove = (event: any) => {
    if (!drowing) return;
    var pointer = FabCanvas.getPointer(event);
    switch (operation) {
      case OPERATION_TYPE.PEN: //ペン
        lineNo++;
        lineListData.push({
          point: { x: pointer.x, y: pointer.y },
          no: lineNo,
          flg: "M",
        });
        if (lineListData.length > 29) {
          var mouse_point = lineListData;
          sendAction(actionComand.MSG_WB_DRAW, null, {
            mouse_point: mouse_point,
            user_id: lineId,
            pen_color: penColor,
            pen_width: penWidth,
            board_event_type: 1,
          });
          lineListData = [];
        }
        // brush.onMouseMove(
        //   { x: pointer.x, y: pointer.y },
        //   { e: { isPrimary: true } }
        // );
        lineList[lineId].path.push(["Q", origX, origY, pointer.x, pointer.y]);
        FabCanvas.renderAll();
        origX = pointer.x;
        origY = pointer.y;
        // drawBrush(event);
        break;
      case OPERATION_TYPE.SHAPE: //基本図形
        switch (shapeType) {
          case SHAPE_TYPE.SQUARE:
            if (origX > pointer.x) {
              addShapeRect.set({
                left: Math.abs(pointer.x),
              });
            }
            if (origY > pointer.y) {
              addShapeRect.set({
                top: Math.abs(pointer.y),
              });
            }
            addShapeRect.set({
              width: Math.abs(origX - pointer.x),
            });
            addShapeRect.set({
              height: Math.abs(origY - pointer.y),
            });
            FabCanvas.renderAll();
            break;
          case SHAPE_TYPE.LINE:
            mousePoint.x = pointer.x;
            mousePoint.y = pointer.y;
            addShapeLine.set({
              x2: pointer.x,
              y2: pointer.y,
            });
            FabCanvas.renderAll();
            break;
          case SHAPE_TYPE.CIRCLE:
            addShapeCircle.set({
              radius: Math.abs(origX - pointer.x),
            });
            FabCanvas.renderAll();
            break;
          case SHAPE_TYPE.TRIANGLE:
            if (origX > pointer.x) {
              addShapeTriangle.set({
                left: pointer.x,
              });
            }
            if (origY > pointer.y) {
              addShapeTriangle.set({
                top: pointer.y,
              });
            }
            addShapeTriangle.set({
              width: Math.abs(origX - pointer.x),
            });
            addShapeTriangle.set({
              height: Math.abs(origY - pointer.y),
            });
            FabCanvas.renderAll();
            break;
        }

        break;

      case OPERATION_TYPE.SELECT: //指先表示
        handNo++;
        sendAction(actionComand.MSG_WB_DRAW, null, {
          point: {
            x: pointer.x,
            y: pointer.y,
          },
          id: handId,
          no: handNo,
          flg: "M",
          board_event_type: 2,
        });
        handImg.set({ left: pointer.x, top: pointer.y });
        FabCanvas.renderAll();
        break;

      case OPERATION_TYPE.HAND: //スクロール
        if (cscale > 1) {
          FabCanvas.discardActiveObject();
          var canvasW = defaultWidth;
          var canvasH = defaultWidth / (16 / 9);
          var dataList = FabCanvas.getObjects();
          var selectData = [];
          for (let index = 0; index < dataList.length; index++) {
            if (
              dataList[index].globalCompositeOperation &&
              dataList[index].type !== "text" &&
              dataList[index].type !== "group"
            )
              selectData.push(dataList[index]);
          }
          var ibTop = imgBackground.top;
          var ibLeft = imgBackground.left;
          var sel = new fabric.ActiveSelection(selectData, {
            canvas: FabCanvas,
          });
          var checkTop = Math.abs(ibTop) - Math.abs(sel.top ? sel.top : 0);
          var checkLeft = Math.abs(ibLeft) - Math.abs(sel.left ? sel.left : 0);
          var newTop =
            Number(sel.top) - Math.floor((origY - pointer.y) * 1000) / 1000;
          var maxTop = -(canvasH * (cscale - 1) + Math.abs(checkTop));
          if (newTop < maxTop) newTop = maxTop;
          if (newTop > checkTop) newTop = checkTop;
          var newLeft =
            Number(sel.left) - Math.floor((origX - pointer.x) * 1000) / 1000;
          var maxLeft = -(canvasW * (cscale - 1) + Math.abs(checkLeft));
          if (newLeft < maxLeft) newLeft = maxLeft;
          if (newLeft > checkLeft) newLeft = checkLeft;

          sel.set({
            top: newTop,
            left: newLeft,
          });
          setActive = true;
          FabCanvas.setActiveObject(sel);
          FabCanvas.requestRenderAll();
          FabCanvas.discardActiveObject();
          setActive = false;
          movePoint = {
            scrollTop: newTop,
            scrollLeft: newLeft,
          };
        } else {
          movePoint = {
            scrollTop: 0,
            scrollLeft: 0,
          };
        }
        origX = pointer.x;
        origY = pointer.y;
        // drowCanvasDiv.scrollTop =
        //   drowCanvasDiv.scrollTop + (origY - pointer.y) / cscale;
        // drowCanvasDiv.scrollLeft =
        //   drowCanvasDiv.scrollLeft + (origX - pointer.x) / cscale;
        // imgCanvasDiv.scrollTop =
        //   imgCanvasDiv.scrollTop + (origY - pointer.y) / cscale;
        // imgCanvasDiv.scrollLeft =
        //   imgCanvasDiv.scrollLeft + (origX - pointer.x) / cscale;
        break;
    }
  };

  const mouUp = (event: any) => {
    drowing = false;
    switch (operation) {
      case OPERATION_TYPE.PEN: //ペン
        var pointer = FabCanvas.getPointer(event);
        lineNo++;
        lineListData.push({
          point: { x: pointer.x, y: pointer.y },
          no: lineNo,
          flg: "E",
        });
        var mouse_point = lineListData;
        sendAction(actionComand.MSG_WB_DRAW, null, {
          mouse_point: mouse_point,
          user_id: lineId,
          pen_color: penColor,
          pen_width: penWidth,
          board_event_type: 1,
        });
        lineListData = [];
        // brush.onMouseUp({ e: { isPrimary: true } });
        // clickBrushFlg = 0;
        // ctx.globalAlpha = 1;
        if (lineList[lineId].path.length === 1) {
          lineList[lineId].path.push(["L", pointer.x - 1, pointer.y - 1]);
        } else {
          lineList[lineId].path.push(["L", pointer.x, pointer.y]);
        }
        FabCanvas.remove(lineList[lineId]);
        lineList[lineId] = new fabric.Path(lineList[lineId].path, {
          name: lineId,
          globalCompositeOperation: lineId,
          objectCaching: false,
          selectable: false,
          evented: true,
          hoverCursor: "default",
          stroke: penColor,
          strokeWidth: penWidth,
          strokeLineCap: "round",
          strokeDashOffset: 0,
          strokeLineJoin: "round",
          strokeUniform: false,
          strokeMiterLimit: 10,
          fill: "",
        });
        FabCanvas.add(lineList[lineId]);
        FabCanvas.renderAll();
        changeJson();
        break;
      case OPERATION_TYPE.SHAPE: //基本図形
        FabCanvas.isDrawingMode = false;
        operation = "";
        switch (shapeType) {
          case SHAPE_TYPE.SQUARE:
            sendAction(actionComand.MSG_WB_DRAW, null, {
              shape_data: addShapeRect,
              shape_id: shapeId,
              scale: scale,
              board_event_type: 5,
            });
            break;
          case SHAPE_TYPE.LINE:
            sendAction(actionComand.MSG_WB_DRAW, null, {
              shape_data: addShapeLine,
              shape_id: shapeId,
              mouse_point: mousePoint,
              scale: scale,
              board_event_type: 3,
            });
            break;
          case SHAPE_TYPE.CIRCLE:
            sendAction(actionComand.MSG_WB_DRAW, null, {
              shape_data: addShapeCircle,
              shape_id: shapeId,
              scale: scale,
              board_event_type: 6,
            });
            break;
          case SHAPE_TYPE.TRIANGLE:
            sendAction(actionComand.MSG_WB_DRAW, null, {
              shape_data: addShapeTriangle,
              shape_id: shapeId,
              scale: scale,
              board_event_type: 4,
            });
            break;
        }
        shapeId = "";
        changeJson();
        break;

      case OPERATION_TYPE.SELECT: //指先表示
        handNo++;
        sendAction(actionComand.MSG_WB_DRAW, null, {
          point: { x: 0, y: 0 },
          id: handId,
          no: handNo,
          flg: "E",
          board_event_type: 2,
        });
        FabCanvas.remove(handImg);
        FabCanvas.renderAll();

        break;
      case OPERATION_TYPE.HAND: //スクロール
        if (cscale > 1) {
          FabCanvas.discardActiveObject();
          FabCanvas.renderAll();
          sendAction(actionComand.MSG_WB_DRAW, null, {
            mouse_point: movePoint,
            board_event_type: 7,
          });
          changeJson();
        }
        break;
    }
  };

  const objectChange = (e: any, type?: any, flg?: Boolean) => {
    if (e.target && e.target.globalCompositeOperation) {
      if (shapeList[e.target.globalCompositeOperation]) {
        let pama = {
          shape_data: e.target,
          shape_id: e.target.globalCompositeOperation,
          board_event_type: 9,
        };
        sendAction(actionComand.MSG_WB_DRAW, null, pama);
        changeJson();
      }
    }
  };

  const addBrushOn = () => {
    isBrush = true;
    FabCanvas.isDrawingMode = false;
    FabCanvas.selection = false;
    FabCanvas.off("mouse:down");
    FabCanvas.off("mouse:move");
    FabCanvas.off("mouse:up");
    FabCanvas.off("selection:created");
    FabCanvas.defaultCursor = "grabbing";
    FabCanvas.hoverCursor = "grabbing";
    FabCanvas.on("selection:created", function () {
      if (!setActive) FabCanvas.discardActiveObject();
    });
    FabCanvas.on("mouse:down", function () {
      drowing = true;
      delIdList = [];
    });
    FabCanvas.on("mouse:move", function (event: any) {
      if (drowing) {
        let target = FabCanvas.findTarget(event.e);
        if (
          target &&
          target.globalCompositeOperation &&
          target.globalCompositeOperation !== "back_ground_color" &&
          target.globalCompositeOperation !== "back_ground_text" &&
          target.globalCompositeOperation !== "back_ground_image"
        ) {
          delIdList.push(target.globalCompositeOperation);
          if (lineList[target.globalCompositeOperation])
            delete lineList[target.globalCompositeOperation];
          if (shapeList[target.globalCompositeOperation])
            delete shapeList[target.globalCompositeOperation];
          FabCanvas.remove(event.target);
        }
        // if (target) {
        //   var type = target.type;
        //   if (type) {
        //     if (type === "path") {
        //       if (target.globalCompositeOperation) {
        //         delIdList.push(target.globalCompositeOperation);
        //         if(lineList[target.globalCompositeOperation]) delete lineList[target.globalCompositeOperation];
        //         FabCanvas.remove(event.target);
        //       }
        //     } else if (type === "group") {
        //       if (target.globalCompositeOperation) {
        //         delIdList.push(target.globalCompositeOperation);
        //         FabCanvas.remove(event.target);
        //       }
        //     } else {
        //       if (
        //         target.globalCompositeOperation &&
        //         shapeList[target.globalCompositeOperation]
        //       ) {
        //         delIdList.push(target.globalCompositeOperation);
        //         delete shapeList[target.globalCompositeOperation];
        //         FabCanvas.remove(event.target);
        //       }
        //     }
        //   }
        // }
      }
    });
    FabCanvas.on("mouse:up", function () {
      drowing = false;
      if (delIdList.length > 0) {
        sendAction(actionComand.MSG_WB_DRAW, null, {
          brush_id: delIdList,
          board_event_type: 8,
        });
        changeJson();
      }
    });
  };

  const addBrushOff = (callback?: any) => {
    if (isBrush) {
      isBrush = false;
      FabCanvas.selection = true;
      FabCanvas.defaultCursor = "default";
      FabCanvas.hoverCursor = "move";
      FabCanvas.off("mouse:down");
      FabCanvas.off("mouse:move");
      FabCanvas.off("mouse:up");
      FabCanvas.off("selection:created");
      FabCanvas.on("mouse:down", function () {});
      FabCanvas.on("mouse:move", function () {});
      FabCanvas.on("mouse:up", function (event: any) {
        if (drowing) mouUp(event);
      });
      FabCanvas.on("selection:created", function (e: any) {
        if (!setActive) {
          if (e.target._objects && e.target._objects.length > 0) {
            FabCanvas.discardActiveObject();
          }
        }
      });
    }
    if (callback) callback();
  };

  const noScroll = (event: any) => {
    event.preventDefault();
  };

  const enterClick = (event: any) => {
    if (event.shiftKey === false && event.keyCode === 13) {
      clickEnter = true;
    }
  };

  const setChatScrollPos = (event: any) => {
    if (
      event.target.scrollHeight - event.target.scrollTop <=
      event.target.clientHeight
    ) {
      scrollMoveFlg = true;
    } else {
      scrollMoveFlg = false;
    }
  };

  //二重チェック
  const [changeStreamershow, setChangeStreamerShow] = React.useState("");
  const changeStreamerMsg: String = FormatMessage({
    id: "HS061",
    option: { name: "(NAME)" },
  });

  const StreamRecordingErrMsg: String = FormatMessage({ id: "HS067" });
  const checkClassState = async () => {
    if (store.getState().router.location.pathname === "/cls/008") {
      S3 = await createS3();
      const list: any = await getListS3(
        S3,
        "haishin/" +
          store.getState().user.service_id +
          "/" +
          classId +
          "/checkClass/"
      );
      let checkFlg = true;
      if (list && list.length > 0) {
        for (let index = 0; index < list.length; index++) {
          if (
            list[index].Size > 0 &&
            list[index].Key.substr(-4) === "json" &&
            list[index].Key.indexOf(
              store.getState().user.user_id +
                "_" +
                store.getState().user.user_class
            ) < 0
          ) {
            checkFlg = false;
            classState = true;
            getFileS3(S3, list[index].Key).then((data: any) => {
              let reader = new FileReader();
              reader.readAsText(data);
              reader.onload = function () {
                if (reader.result) {
                  let data = JSON.parse(String(reader.result));
                  setChangeStreamerShow(
                    changeStreamerMsg.replace("(NAME)", data.name)
                  );
                  setErrDifStreamerOpen(true);
                  setTimeout(() => {
                    let noC = localStorage.getItem("NotClassID");
                    localStorage.setItem(
                      "NotClassID",
                      noC === "" ? classId : noC + "," + classId
                    );
                    let ExitButton = document.getElementById("ExitButton");
                    ExitButton?.click();
                  }, 6000);
                }
              };
            });
            break;
          }
        }
      }
      if (checkFlg) setTimeout(() => checkClassState(), 3000);
    }
  };

  const getWhiteBroadVideoNo = async () => {
    // S3 = await createS3();
    // let list = await getListS3(
    //   S3,
    //   "haishin/" +
    //     store.getState().user.service_id +
    //     "/" +
    //     classId +
    //     "/movie/board/"
    // );
    // let num = 0;
    // if (list && list.length > 0) {
    //   for (let index = 0; index < list.length; index++) {
    //     if (
    //       list[index].Key?.substr(-4) === "webm" ||
    //       list[index].Key?.substr(-3) === "mp4"
    //     )
    //       num++;
    //   }
    // }
    // videoCount = num;
    // videoCount++;
    // list = await getListS3(
    //   S3,
    //   "haishin/" +
    //     store.getState().user.service_id +
    //     "/" +
    //     classId +
    //     "/movie/board_data_" +
    //     (num > 0 ? num + 1 : "1") +
    //     "/"
    // );
    // if (list && list.length > 0) {
    //   list.sort(function (a: any, b: any) {
    //     if (a.LastModified > b.LastModified) return 1;
    //     if (a.LastModified < b.LastModified) return -1;
    //     return 0;
    //   });
    //   let getFileList = [];
    //   let webmFileList = [];
    //   let mp4FileList = [];
    //   let videoType = "webm";
    //   for (let index = 0; index < list.length; index++) {
    //     const element: any = list[index];
    //     if (element && element.Key && element.Key.slice(-3) === "mp4") {
    //       mp4FileList.push(element.Key);
    //     }
    //     if (element && element.Key && element.Key.slice(-4) === "webm") {
    //       webmFileList.push(element.Key);
    //     }
    //   }
    //   if (webmFileList.length > mp4FileList.length) {
    //     getFileList = webmFileList;
    //     webmFileList = [];
    //   } else {
    //     getFileList = mp4FileList;
    //     mp4FileList = [];
    //     videoType = "mp4";
    //   }
    //   if (getFileList.length > 0) {
    //     let newVideoData: any = [];
    //     for (let index = 0; index < getFileList.length; index++) {
    //       const flt = getFileList[index];
    //       let fileData = await getFileS3(S3, flt);
    //       if (fileData) {
    //         let arrayBuffer = await readFileAsync(fileData);
    //         newVideoData.push(arrayBuffer);
    //       }
    //     }
    //     if (newVideoData.length > 0) {
    //       var videoBlob: any = new Blob(newVideoData, {
    //         type: "video/" + videoType,
    //       });
    //       let upV = await uploadS3(
    //         S3,
    //         "haishin/" +
    //           store.getState().user.service_id +
    //           "/" +
    //           classId +
    //           "/movie/board/" +
    //           classId +
    //           "_" +
    //           videoCount +
    //           "." +
    //           videoType,
    //         videoBlob
    //       );
    //       if (upV) videoCount++;
    //     }
    //   }
    // }
    let S3: any = await createS3();
    let json = await getListS3(
      S3,
      "haishin/" +
        store.getState().user.service_id +
        "/" +
        classId +
        "/chatMessage/all_list.json"
    );
    if (json) {
      let jsonData: any = await getFileS3(
        S3,
        "haishin/" +
          store.getState().user.service_id +
          "/" +
          classId +
          "/chatMessage/all_list.json"
      );
      if (jsonData) {
        var fileReader = new FileReader();
        fileReader.readAsText(jsonData);
        fileReader.onload = function (json: any) {
          let canvasJson = json.currentTarget.result;
          if (canvasJson) {
            messageListData = JSON.parse(canvasJson);
            if (
              messageListData &&
              messageListData.tmp &&
              messageListData.tmp.no &&
              messageListData.tmp.messageData &&
              messageListData.tmp.messageData.length > 0
            ) {
              messageData[messageListData.tmp.no] =
                messageListData.tmp.messageData;
            }
          }
        };
      }
    }

    // let S3: any = await createS3();
    videoCount = 1;
    let list = await getListS3(
      S3,
      "haishin/" +
        store.getState().user.service_id +
        "/" +
        classId +
        "/movie/board_data_"
    );
    if (list && list.length > 0) {
      var keyName: any = {};
      for (let index = 0; index < list.length; index++) {
        const element = String(list[index].Key).split("/");
        if (element.length > 0) keyName[element[4]] = "ok";
      }
      videoCount = Number(Object.keys(keyName).length) + 1;
    }
  };

  const setStreamerJson = async () => {
    let jsonData = JSON.stringify({ name: store.getState().user.user_name });
    var blob = new Blob([jsonData], { type: "text/json" });
    var userId = store.getState().user.user_id;
    var jsonFile = new File([blob], userId + ".json");
    S3 = await createS3();
    const list: any = await getListS3(
      S3,
      "haishin/" +
        store.getState().user.service_id +
        "/" +
        classId +
        "/checkClass/"
    );
    if (list && list.length > 0) {
      for (let index = 0; index < list.length; index++) {
        if (list[index].Key.substr(-4) === "json")
          await deleteS3(S3, list[index].Key);
      }
    }
    let upJson = await uploadS3(
      S3,
      "haishin/" +
        store.getState().user.service_id +
        "/" +
        classId +
        "/checkClass/" +
        store.getState().user.user_id +
        "_" +
        store.getState().user.user_class +
        ".json",
      jsonFile
    );

    if (upJson) {
      classState = false;
      setTimeout(() => checkClassState(), 3000);
    } else {
      setStreamerJson();
    }
  };

  const [megaBps, setMegaBps] = React.useState(0);
  const NetWorkSpeed = async () => {
    // 回線速度を計算
    const imageBytes = 30000;
    const imageBits = imageBytes * 8;
    let startTime = new Date().getTime();
    let endTime = null;
    let image = new Image();
    image.onload = () => {
      endTime = new Date().getTime();
      const diffSeconds =
        endTime - startTime === 0 ? 0.5 / 1000 : (endTime - startTime) / 1000;
      const megaBps = parseFloat(
        (imageBits / diffSeconds / (1024 * 1024)).toFixed(2)
      );
      image.src = "";
      summegaBps = summegaBps + megaBps;
      speedcount++;
      if (speedcount < 10) {
        setTimeout(NetWorkSpeed, 500);
      } else {
        const viewMegaBps = summegaBps / speedcount;
        setMegaBps(Math.round(viewMegaBps * 10) / 10);
        speedcount = 0;
        summegaBps = 0;
        setTimeout(NetWorkSpeed, 60000);
      }
    };
    image.src = "/img/3ue--Live.png?" + startTime;
  };

  useEffect(() => {
    if (!getDateValue || getDateValue === "") {
      dispatch(push("/cls/001"));
      return;
    }
    ST0326(true);
    setGideMessage(showText.GuideMsg);
    startFlg = false;
    videoRecFlg = false;
    endFlg = false;
    zoom = null;
    cscale = 1;
    setScale(1);
    movePoint = null;
    largeFlg = false;
    chatsedFlg = false;
    disNameFlg = false;
    ViewLogInterval = undefined;
    lastViewLog = -1;
    joinList = [];
    lastLog = false;
    jsonSaveNo = 0;
    socketSendNo = 0;
    screenSmall = false;
    classTimeAddFlg = true;
    checkSaveTmpMessage = 0;
    lineList = {};
    lineData = {};
    lineOrder = [];
    shapeList = {};
    graphShapeList = {};
    isBrush = false;
    delIdList = [];
    alertFlg = false;
    videoNo = 0;
    alertCnt = 0;
    offlineCnt = 0;
    requestList = [];
    localVideoTrackFlg = false;
    changeBoardFlg = false;
    clearInterval(befClassInterval);
    befClassIntervalFlg = false;
    incomingCommandList = [];
    questionResFlg = false;
    checkVideoFlg = false;
    agoraResorceId = null;
    agoraSId = null;
    agoraResorceId2 = null;
    agoraSId2 = null;
    saveLogFlg = false;
    agoraUserId = "";
    setStreamerJson();
    if (localVideo && localVideo.srcObject !== null) stopStreamedVideo();
    setLoading(true);
    getUserData();
    getClassData(getDateValue?.classId).then(() => {
      //updateStatus("2");
      if (classInfo.channelId !== "") {
        const getSocket = () => {
          socket = new WebSocket(
            process.env.REACT_APP_WEB_SOCKET_URL +
              classInfo.channelId +
              "&userId=" +
              classInfo.channelId +
              store.getState().user.user_id +
              store.getState().user.user_class
          );
          socket.onopen = (event: any) => {
            // クライアント接続時
            console.log("接続しました。");
            if (offlineCnt > 2) window.location.reload();
            offlineCnt = 0;
            setOfflineEndOpen(false);
            //視聴情報再取得
            /*if (!startFlg) {
              let list = JSON.stringify({
                actionId: actionComand.MSG_GVIEW_USER_INFO,
                userId: null,
                currentTime: new Date().getTime(),
                option: {},
                roomId: classInfo.channelId,
                classId: classId,
                sessionId: localStorage.getItem("session_id")
                  ? localStorage.getItem("session_id")
                  : null,
              });
              socket.send(
                JSON.stringify({
                  action: "MESSAGE_SEND",
                  message: list,
                  roomId: classInfo.channelId,
                })
              );
            }*/
            startFlg = true;
          };

          socket.onmessage = (event: any) => {
            // 受信時
            incomingCommand(JSON.parse(event.data));
          };

          socket.onclose = (event: any) => {
            // クライアント切断時
            console.log("切断しました。");
            let path = store.getState().router.location.pathname;
            if (!endFlg && path === "/cls/008") {
              if (offlineCnt > 10) window.location.reload();
              if (offlineCnt > 2) setOfflineEndOpen(true);
              offlineCnt++;
              setTimeout(() => getSocket(), 1000);
            }
          };
          socket.error = (event: any) => {
            // コネクションエラー
            console.log("error", event);
          };
        };
        getSocket();
        const agoraWebS = () => {
          if (agoraInfo && agoraInfo.app_id && agoraInfo.token_rtm) {
            let RtmConfig: any = {
              logFilter: AgoraRTM.LOG_FILTER_OFF,
            };
            if (process.env.REACT_APP_AGORA_RTM_SHOW_LOG === "true")
              RtmConfig = {
                enableLogUpload: true,
                logFilter: AgoraRTM.LOG_FILTER_WARNING,
              };
            clientRTM = AgoraRTM.createInstance(agoraInfo.app_id, RtmConfig);
            clientRTM.on(
              "ConnectionStateChanged",
              (newState: any, reason: any) => {
                const Log: any[] = [];
                Log.push("newState:" + JSON.stringify(newState));
                Log.push("reason" + JSON.stringify(reason));
                uploadLog(Log, "_RTMConnectionStateChanged_");
              }
            );
            clientRTM
              .login({
                uid: myuserData.user_id + myuserData.user_class,
                token: agoraInfo.token_rtm,
              })
              .then((data: any) => {
                channelRTM = clientRTM.createChannel(classInfo.channelId);
                if (channelRTM) {
                  channelRTM?.on("ChannelMessage", (...args: any) => {
                    try {
                      if (args[0].text !== "chatCheck") {
                        let data = JSON.parse(args[0].text);
                        incomingCommand(JSON.parse(data.message));
                      }
                      setRTMErrFlg(false);
                    } catch (e: any) {
                      console.log("chatReceveErr:", e);
                      channelRTM?.leave().then(() => {
                        clientRTM
                          ?.logout()
                          .then(() => {
                            console.log("RTM Re-Login");
                            clientRTM
                              .login({
                                uid: myuserData.user_id + myuserData.user_class,
                                token: agoraInfo.token_rtm,
                              })
                              .then(() => {
                                console.log("RTM Re-Join");
                                channelRTM
                                  ?.join()
                                  .then(() => {
                                    RTMLoginCheck();
                                  })
                                  .catch(() => {
                                    let path =
                                      store.getState().router.location.pathname;
                                    if (!endFlg && path === "/cls/008")
                                      setRTMErrFlg(true); //alert(RTMConectErr);
                                  });
                              })
                              .catch(() => {
                                let path =
                                  store.getState().router.location.pathname;
                                if (!endFlg && path === "/cls/008")
                                  setRTMErrFlg(true); //alert(RTMConectErr);
                              });
                          })
                          .catch(() => {
                            let path =
                              store.getState().router.location.pathname;
                            if (!endFlg && path === "/cls/008")
                              setRTMErrFlg(true); //alert(RTMConectErr);
                          });
                      });
                    }
                  });
                  // channelRTM?.on("MemberJoined", userJoin);
                  // channelRTM?.on("MemberLeft", userLeft);
                  channelRTM
                    ?.join()
                    .then(() => {
                      RTMLoginCheck();
                    })
                    .catch(() => {
                      let path = store.getState().router.location.pathname;
                      if (!endFlg && path === "/cls/008") setRTMErrFlg(true); //alert(RTMConectErr);
                    });
                }
              });
          } else {
            setTimeout(() => agoraWebS(), 100);
          }
        };
        agoraWebS();
      } else {
        console.log("チャンネルIDが取得できませんでした。");
      }
    });
    canvas = document.getElementById("canvas");
    chatBox = document.getElementsByClassName("chat-box");
    selectImgUrl = null;
    ctx = canvas.getContext("2d");
    concatFlg = false;
    //写真表示リセット
    ctx.clearRect(0, 0, ctx.canvas.clientWidth, ctx.canvas.clientHeight);
    speedcount = 0;
    summegaBps = 0;
    //NetWorkSpeed();
    FabCanvas = new fabric.Canvas("canvas");
    FabCanvas.setHeight(Number(canvasHeight));
    FabCanvas.setWidth(Number(canvasWide));
    FabCanvas.isDrawingMode = true;
    FabCanvas.backgroundColor = "#fff";
    var canvasW = defaultWidth;
    var canvasH = defaultWidth / (16 / 9);
    imgBackground = new fabric.Rect({
      name: "back_ground_color",
      width: canvasW,
      height: canvasH,
      selectable: false,
      evented: false,
      left: 0,
      top: 0,
      fill: "white",
      globalCompositeOperation: "back_ground_color",
    });
    FabCanvas.add(imgBackground);
    imgBackground.moveTo(0);

    boardTime = new fabric.Text("-", {
      globalCompositeOperation: "back_ground_text",
      fontFamily: "Arial",
      fontSize: 1,
      fill: "rgba(0,0,0,0.1)",
      fontWeight: "bold",
      left: 0,
      top: 0,
      selectable: false,
      evented: false,
    });
    FabCanvas.add(boardTime);

    // brush = new fabric.PencilBrush();
    // brush.initialize(FabCanvas);
    fabric.Image.fromURL(finger, function (oImg: any) {
      handImg = oImg;
      handImg.scaleToWidth(30);
      handImg.scaleToHeight(30);
    });

    fabric.Object.prototype.set({
      padding: 10,
    });

    FabCanvas._onMouseDownInDrawingMode = function (event: any) {
      mouDown(event);
    };

    FabCanvas._onMouseMoveInDrawingMode = function (event: any) {
      mouMove(event);
    };

    FabCanvas._onMouseUpInDrawingMode = function (event: any) {
      mouUp(event);
    };

    FabCanvas.on("mouse:down", function () {});

    FabCanvas.on("mouse:move", function () {});

    FabCanvas.on("mouse:up", function (event: any) {
      if (drowing) mouUp(event);
    });
    // FabCanvas.on("mouse:over", function () {
    //   if (operation === OPERATION_TYPE.VIDEO) {
    //     setShowVideoMenu(false);
    //   }
    // });
    // FabCanvas.on("mouse:out", function () {
    //   if (operation === OPERATION_TYPE.VIDEO) {
    //     setShowVideoMenu(true);
    //   }
    // });
    FabCanvas.on("path:created", function (event: any) {
      if (!lineList[lineId]) {
        event.path.name = lineId;
        event.path.globalCompositeOperation = lineId;
        event.path.selectable = false;
        event.path.hoverCursor = "default";
        lineList[lineId] = event.path;
      }
    });

    FabCanvas.on("selection:created", function (e: any) {
      if (!setActive) {
        if (
          (e.target._objects && e.target._objects.length > 0) ||
          e.target.type === "path"
        ) {
          FabCanvas.discardActiveObject();
        }
      }
    });

    FabCanvas.on("object:moved", function (event: any) {
      objectChange(event, "1", true);
    });

    FabCanvas.on("object:scaled", function (event: any) {
      objectChange(event, "2", true);
    });

    FabCanvas.on("object:rotated", function (event: any) {
      objectChange(event, "3", true);
    });

    FabCanvas.on("object:skewed", function (event: any) {
      objectChange(event, "4", true);
    });

    client.on("network-quality", function (stats: any) {
      if (stats?.downlinkNetworkQuality > stats?.uplinkNetworkQuality) {
        setIngegate(stats?.downlinkNetworkQuality);
      } else {
        setIngegate(stats?.uplinkNetworkQuality);
      }
      if (stats) {
        if (
          stats.downlinkNetworkQuality === 0 ||
          stats.downlinkNetworkQuality === 5 ||
          stats.downlinkNetworkQuality === 6 ||
          stats.uplinkNetworkQuality === 0 ||
          stats.uplinkNetworkQuality === 5 ||
          stats.uplinkNetworkQuality === 6
        ) {
          alertFlg = true;
        } else {
          alertFlg = false;
          alertCnt = 0;
          setNetworkAlertOpen(true);
        }
      }
    });

    const networkCheckInterval = setInterval(() => {
      let path = store.getState().router.location.pathname;
      if (path !== "/cls/008") {
        clearInterval(networkCheckInterval);
      }
      if (alertFlg) {
        alertCnt++;
        if (alertCnt >= 10) {
          setNetworkAlertOpen(false);
        }
      }
    }, 1000);

    // video = document.getElementById("showvideo") as HTMLVideoElement;
    getWhiteBroadVideoNo();
    // drowCanvasDiv.addEventListener("mousewheel", noScroll, { passive: false });
    if (typeof props.location.state !== "undefined") {
      localStorage.setItem(
        "Cls008_session",
        JSON.stringify(props.location.state)
      );
    }

    checkBrowserType();
    checkDeviceType();
    /*AgoraRTC.onMicrophoneChanged = async (changeDevice) => {
      microphoneSetAutoDevice(changeDevice);
    };
    AgoraRTC.onCameraChanged = async (changeDevice) => {
      cameraSetAutoDevice(changeDevice);
    };*/
    // AgoraRTC.onCameraChanged = (info: any) => {
    //   console.log("onCameraChanged info", info);
    //   console.log("onCameraChanged selectCamera", selectCamera);
    //   if (info.label === selectCamera && info.state === "INACTIVE")
    //     setRetryErrorOpen(true);
    // };
    // AgoraRTC.onMicrophoneChanged = (info: any) => {
    //   console.log("onMicrophoneChanged info", info);
    //   console.log("onMicrophoneChanged selectMic",selectMic);
    //   if (info.label === selectMic && info.state === "INACTIVE")
    //     setRetryErrorOpen(true);
    // };

    client.on("exception", function (event: any) {
      console.log("agoraErr", event);
      if (event.code === "NO_ICE_CANDIDATE") setJoinCheckEndOpen(true);
      // agoraError(event.code);
    });

    navigator.mediaDevices.ondevicechange = function (event) {
      getDeviceList();
    };
    getDeviceList();
    whiteboardDataList = [];
    setWhiteBord();
    operation = OPERATION_TYPE.PEN;
    penColor = "#000";
    penWidth = 3;
    imageData = undefined;
    clickEnter = false;
    recordTime = 0;
    selectBoardId = 1;
    joinUser = [];
    deviceConfig = undefined;
    chatSendState = 1;
    chatdisplayState = 1;
    reactionList = [];
    reactionData = [];
    reactionFlg = false;
    messageList = [];
    startDateTime = undefined;
    startDate = 0;
    messageData = {};
    messageListData = {};
    messageFlg = false;
    msgIndex = 0;
    msgLength = 300;
    questionLists = [
      { check: false, answer: "", answer_no: 1, color: "#78e00b" },
      { check: false, answer: "", answer_no: 2, color: "#26d4e0" },
      { check: false, answer: "", answer_no: 3, color: "#e0b501" },
      { check: false, answer: "", answer_no: 4, color: "#c504e0" },
      { check: false, answer: "", answer_no: 5, color: "#e0610b" },
      { check: false, answer: "", answer_no: 6, color: "#ace072" },
      { check: false, answer: "", answer_no: 7, color: "#8ddbe0" },
      { check: false, answer: "", answer_no: 8, color: "#e0c868" },
      { check: false, answer: "", answer_no: 9, color: "#c45dd4" },
      { check: false, answer: "", answer_no: 10, color: "#e09f72" },
    ];
    if (
      props.location.state &&
      Object.keys(props.location.state).indexOf("classId") >= 0
    ) {
      classInfo.classId = props.location.state.classId;
    }

    const sendStreamerCheck = () => {
      if (store.getState().router.location.pathname === "/cls/008") {
        if (socket && socket.readyState && socket.readyState === 1) {
          sendAction(ActionComand.MSG_STREAMER_CHECK, null, {});
          setTimeout(() => {
            sendStreamerCheck();
          }, 60000);
        } else {
          setTimeout(() => {
            sendStreamerCheck();
          }, 500);
        }
      }
    };
    sendStreamerCheck();
    // client.on("user-joined", userJoin);
    // client.on("user-left", userLeft);
    let registeredListener = () => {
      setTimeout(() => {
        winResize();
      }, 5000);
    };
    window.history.pushState(null, "", window.location.href);
    window.addEventListener("popstate", popState);
    let textForm = document.getElementById("text_form");

    textForm?.addEventListener("keypress", enterClick);
    chatBox[0].addEventListener("scroll", setChatScrollPos);
    registeredListener();
    window.addEventListener("resize", registeredListener);
    chatnotTestFlg = process.env.REACT_APP_AGORA_REC_FLG === "1" ? true : false;
    sessionCheck();
    document.addEventListener("fullscreenchange", () => {
      winResize();
    });
    document.addEventListener("webkitfullscreenchange", () => {
      winResize();
    });
    document.addEventListener("mozfullscreenchange", () => {
      winResize();
    });
    document.addEventListener("MSFullscreenChange", () => {
      winResize();
    });

    agora_view_div = document.getElementById("agora_view_div");
    var agoraDivIsDown = false;
    var agoraOffset: any = [];
    const handleStart = (event: any) => {
      let clientX;
      let clientY;

      if (event.type === "touchstart") {
        clientX = event.changedTouches[0].clientX;
        clientY = event.changedTouches[0].clientY;
      } else {
        clientX = event.clientX;
        clientY = event.clientY;
      }
      agoraDivIsDown = true;
      agoraOffset = [
        agora_view_div.offsetLeft - clientX,
        agora_view_div.offsetTop - clientY,
      ];
    };
    const handleMove = (event: any) => {
      if (agoraDivIsDown) {
        event.preventDefault();
        let clientX;
        let clientY;

        if (event.type === "touchmove") {
          clientX = event.changedTouches[0].clientX;
          clientY = event.changedTouches[0].clientY;
        } else {
          clientX = event.clientX;
          clientY = event.clientY;
        }

        let mousePosition: any = {
          x: clientX,
          y: clientY,
        };
        agora_view_div.style.left = mousePosition.x + agoraOffset[0] + "px";
        agora_view_div.style.top = mousePosition.y + agoraOffset[1] + "px";
      }
    };
    const handleEnd = () => {
      agoraDivIsDown = false;
    };
    agora_view_div.addEventListener("mousedown", handleStart, true);
    agora_view_div.addEventListener("mouseup", handleEnd, true);
    agora_view_div.addEventListener("mousemove", handleMove, true);
    agora_view_div.addEventListener("touchstart", handleStart, false);
    agora_view_div.addEventListener("touchend", handleEnd, false);
    agora_view_div.addEventListener("touchmove", handleMove, false);

    // let el: any = document.getElementById("canvas");
    // if (el) {
    //   el.addEventListener("touchstart", mouDown, false);
    //   el.addEventListener("touchmove", mouMove, false);
    //   el.addEventListener("touchend", mouUp, false);
    // }
    let whiteBoard = document.getElementsByClassName(
      "whiteBoard"
    ) as HTMLCollectionOf<HTMLElement>;
    if (whiteBoard && whiteBoard.length > 0) {
      for (let index = 0; index < whiteBoard.length; index++) {
        const element = whiteBoard[index];
        element.oncontextmenu = function (eve: any) {
          console.log("event", eve);
          if (drowing) mouUp(eve);
          return false;
        };
      }
    }

    createS3().then((s3: any) => {
      getListS3(
        s3,
        "haishin/" +
          store.getState().user.service_id +
          "/" +
          classId +
          "/backup/"
      ).then((list: any) => {
        BackupNo = 0;
        if (list && list.length > 0) {
          for (let index = 0; index < list.length; index++) {
            if (list[index].Key.substr(-3) === "jpg") BackupNo++;
          }
        }
        BackupNo++;
        console.log("BackupNo", BackupNo);
      });
    });

    return () => {
      window.removeEventListener("resize", registeredListener);
      // client.off("user-joined", userJoin);
      // client.off("user-left", userLeft);
      if (RTMTimerID) {
        clearTimeout(RTMTimerID);
        RTMTimerID = undefined;
      }
      if (getViewUserTimeout) {
        clearTimeout(getViewUserTimeout);
        getViewUserTimeout = undefined;
      }
      if (getRTCUserTimeout) {
        clearTimeout(getRTCUserTimeout);
        getRTCUserTimeout = undefined;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (scrollMoveFlg) {
      if (chatBox) chatBox[0]?.scrollTo(0, chatBox[0]?.scrollHeight);
    }
    if (
      navigator.userAgent.indexOf("iPad") > 0 ||
      (navigator.userAgent.includes("Macintosh") && "ontouchend" in document)
    ) {
      const Document: any = document;
      var hidden: any, visibilityChange: any;
      if (typeof Document.hidden !== "undefined") {
        hidden = "hidden";
        visibilityChange = "visibilitychange";
      } else if (typeof Document.msHidden !== "undefined") {
        hidden = "msHidden";
        visibilityChange = "msvisibilitychange";
      } else if (typeof Document.webkitHidden !== "undefined") {
        hidden = "webkitHidden";
        visibilityChange = "webkitvisibilitychange";
      }
      let hiddenChangeFlg = false;
      const visibilityChanged = () => {
        if (Document[hidden]) {
          hiddenChangeFlg = true;
        } else {
          if (hiddenChangeFlg) window.location.reload();
        }
      };
      document.addEventListener(visibilityChange, visibilityChanged, false);
    }
  }, []);

  useEffect(() => {
    if (checkVideoFlg && selectCamera === "")
      unpublish(() => {
        checkVideoFlg = false;
        setRetryErrorOpen(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectCamera]);

  return (
    <div className={classes.root} ref={elm}>
      <div className="live_header">
        <div id="header1" className={"floatleft mt05 ml10"}>
          <Button1
            startRecording={startRecording}
            setRecordingState={setRecordingState}
            deleteImg={deleteImg}
            updateStatus={updateStatus}
            classInfo={classInfo}
            classStatus={classStatus}
            JoinStart={JoinStart}
            agoraLeave={agoraLeave}
            joinButtonState={joinButtonState}
            confirmClassEnd={confirmClassEnd}
          />
          {process.env.REACT_APP_TEST_BUTTON === "true" ? (
            <button
              onClick={() => {
                consoleLogOut(null, null, true);
                // if (largeFlg) {
                //   largeFlg = false;
                //   setLargeNowFlg(false);
                // } else {
                //   largeFlg = true;
                // }
                // setLargeNowFlg(true);
              }}
              hidden={chatnotTestFlg}
            >
              8倍データ（{largeNowFlg ? "オフ" : "オン"}）
            </button>
          ) : null}
          {process.env.REACT_APP_TEST_BUTTON === "true" ? (
            <button
              onClick={() => {
                if (disNameFlg) {
                  disNameFlg = false;
                  setDisplayNameFlg(false);
                } else {
                  disNameFlg = true;
                  setDisplayNameFlg(true);
                }
              }}
              hidden={chatnotTestFlg}
            >
              送信者名表示とメッセージグルービク（
              {displayNameFlg ? "オフ" : "オン"}）
            </button>
          ) : null}
        </div>
        <div id="header2" className="floatright mt10 mr20 fs20">
          <img src={educastLogo} height="24" alt=""></img>
        </div>
        <div id="header3" className={"floatright mt05 mr20"}>
          <Button
            id="ExitButton"
            startIcon={<FontAwesomeIcon icon={faSignOutAlt} />}
            size="small"
            variant="contained"
            style={{ backgroundColor: "white", border: "1px solid #ccc" }}
            disabled={classStatus === "4" || classStatus === "5" ? true : false}
            onClick={() => {
              updateStatus("1");
              agoraLeave(false);
            }}
          >
            {showText.Exit}
          </Button>
        </div>
        <div id="header4" className="floatright mt10 mr20 fs20">
          <Typography variant="body1" gutterBottom>
            {showText.Time} {classTime}
          </Typography>
        </div>
        <div
          id="header5"
          className="floatright mt10 mr40 fs20"
          hidden={
            recordingFlg && (classStatus === "4" || classStatus === "5")
              ? false
              : true
          }
        >
          <StyledBadge
            overlap="circle"
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            variant="dot"
          >
            <span style={{ color: "#f21e3f" }}> {showText.Record} </span>
          </StyledBadge>
        </div>
        <div
          className="floatleft mt10 ml20 shoryaku"
          style={{ maxWidth: classNameWidth }}
        >
          <Tooltip
            title={classInfo.className}
            arrow
            classes={{
              tooltip: classes.tooltip,
              arrow: classes.arrow,
            }}
          >
            <Typography variant="body1" gutterBottom noWrap={true}>
              {classInfo.className}
            </Typography>
          </Tooltip>
        </div>
      </div>

      <table width="100%">
        <tbody>
          <tr>
            {!allWidth || screenIsSmall ? (
              <td
                id="left_table"
                className={screenIsSmall ? "all_left_table" : "tableLeft"}
              >
                <div className="leftTable">
                  <div
                    id="agora_view_div"
                    className={screenIsSmall ? "all_video_left" : "leftTable"}
                    style={
                      screenIsSmall
                        ? {
                            display: "block",
                            position: "absolute",
                            top: 10,
                            left: 10,
                            zIndex: 2,
                          }
                        : {
                            backgroundColor: videoLyoutSetting
                              ? "black"
                              : "red",
                            padding: 1,
                          }
                    }
                  >
                    {/*Agora*/}
                    <div className="player-wrapper" style={{ marginLeft: 5 }}>
                      <div
                        style={{
                          display: "contents",
                        }}
                        className={
                          remoteUsers.length === 2
                            ? "remote-player-wrapper-3"
                            : remoteUsers.length === 1
                            ? "remote-player-wrapper-2"
                            : "remote-player-wrapper-1"
                        }
                      >
                        <MediaPlayer
                          videoTrack={localVideoTrack}
                          audioTrack={localAudioTrack}
                        ></MediaPlayer>
                      </div>

                      {remoteUsers.map((user, index) => (
                        <div
                          key={"remote_users_" + index}
                          className={
                            remoteUsers.length === 2
                              ? localVideoTrack &&
                                !localVideoTrack._video_muted_
                                ? "remote-player-wrapper-3"
                                : "remote-player-wrapper-2"
                              : remoteUsers.length === 1
                              ? localVideoTrack &&
                                !localVideoTrack._video_muted_
                                ? "remote-player-wrapper-2"
                                : "remote-player-wrapper-1"
                              : "remote-player-wrapper-1"
                          }
                        >
                          <MediaPlayer
                            videoTrack={user.videoTrack}
                            audioTrack={user.audioTrack}
                          ></MediaPlayer>
                        </div>
                      ))}
                    </div>
                    <div
                      style={{
                        float: "left",
                        marginTop: -164,
                        height: 163,
                        width: 5,
                      }}
                      id="ing"
                    >
                      <div
                        className="ing"
                        style={{
                          backgroundColor: ingegate === 1 ? "#00ff66" : "black",
                        }}
                      />
                      <div
                        className="ing"
                        style={{
                          backgroundColor:
                            ingegate !== 0 && ingegate <= 2
                              ? "#66ff66"
                              : "black",
                        }}
                      />
                      <div
                        className="ing"
                        style={{
                          backgroundColor:
                            ingegate !== 0 && ingegate <= 3
                              ? "#ccff66"
                              : "black",
                        }}
                      />
                      <div
                        className="ing"
                        style={{
                          backgroundColor:
                            ingegate !== 0 && ingegate <= 4
                              ? "#ffcc66"
                              : "black",
                        }}
                      />
                      <div
                        className="ing"
                        style={{
                          backgroundColor:
                            ingegate !== 0 && ingegate <= 5
                              ? "#ff9933"
                              : "black",
                        }}
                      />
                      <div
                        className="ing"
                        style={{
                          backgroundColor:
                            ingegate !== 0 && ingegate <= 6
                              ? "#ff6600"
                              : "black",
                        }}
                      />
                    </div>
                    <div
                      hidden={cameraStatus}
                      style={{
                        position: "absolute",
                        transform: "translateY(-197%) translateX(255%)",
                        WebkitTransform: "translateY(-197%) translateX(255%)",
                      }}
                    >
                      <FaVideoSlash
                        id="video_slash"
                        color="#ffffff"
                        style={{
                          fontSize: "50px",
                          margin: "0 auto",
                        }}
                      />
                    </div>

                    <div
                      hidden={!micStatus}
                      style={{
                        position: "absolute",
                        margin: "-32px 0px 0px 10px",
                        color: "#ff0000",
                      }}
                    >
                      <MicOff
                        className="chatIcon"
                        style={{ marginRight: "5px" }}
                      />
                    </div>
                    <div
                      id="networkAlertMsg"
                      hidden={networkAlertOpen}
                      style={{
                        color: "rgb(0 0 0 / 100%)",
                        backgroundColor: "rgb(255 255 255 / 50%)",
                        position: "absolute",
                        width: 296,
                        height: 20,
                        marginTop: -20,
                        marginLeft: 5,
                        pointerEvents: "none",
                      }}
                    >
                      {DialogText.networkUnstable}
                    </div>
                  </div>

                  {/*<div className="NetworkState">
                    <span
                      style={{
                        color:
                          dLL === 1
                            ? "lightgreen"
                            : dLL === 2 || dLL === 3
                            ? "yellow"
                            : dLL === 0
                            ? "gray"
                            : "red",
                      }}
                    >
                      {"DownloadState:" + dLL}
                    </span>
                    <span
                      style={{
                        color: "lightgreen",
                      }}
                    >
                      {" " + megaBps + "Mbps"}
                    </span>
                    <span
                      style={{
                        color:
                          uLL === 1
                            ? "lightgreen"
                            : uLL === 2 || uLL === 3
                            ? "yellow"
                            : uLL === 0
                            ? "gray"
                            : "red",
                      }}
                    >
                      {" UploadState:" + uLL}
                    </span>
                    </div>*/}
                  <div className={screenIsSmall ? "nodisplay" : "wrapper"}>
                    <div
                      id="chat-box"
                      className="chat-box"
                      style={{ marginTop: chatMT }}
                    >
                      <div className="chat-body">
                        <div className="msg-insert">
                          {/* {message.map((data, index) => {
                            return (
                              <div
                                className={
                                  data.id === loginUserId
                                    ? "msg-sends"
                                    : "msg-receives"
                                }
                                key={"message_" + index}
                                style={{
                                  color:
                                    data.send_to_class === null
                                      ? "black" //"red"
                                      : "red", //"black",
                                }}
                              >
                                {data.name + ":"}
                                <br />
                                {data.messageType === 0 ? (
                                  data.message
                                ) : data.messageType === 1 ? (
                                  <span>🙂</span>
                                ) : data.messageType === 2 ? (
                                  <span>❌</span>
                                ) : (
                                  <span>✋</span>
                                )}
                              </div>
                            );
                          })} */}
                          {/* <video
                            hidden
                            //preload="auto"
                            controls
                            //autoPlay
                            id="showvideo"
                            crossOrigin="anonymous"
                            src={classVideo}
                          ></video> */}
                          {/* <video
                            hidden
                            //preload="auto"
                            controls
                            //autoPlay
                            id="showvideo"
                            crossOrigin="anonymous"
                          >
                            <source src={classVideo} />
                          </video> */}
                        </div>
                      </div>
                      <div ref={messagesEndRef} />
                    </div>
                    <div id="other_send_box">
                      <div
                        className="chat-send"
                        style={{ padding: "5px 0 0 0" }}
                      >
                        <div className="alignCenter">
                          <span style={{ marginRight: "5px" }}>
                            {" "}
                            {showText.SendUser}
                          </span>
                          <select
                            name="status"
                            id="status-select"
                            className="selectBox"
                            value={chatSendUser}
                            onChange={sendChange}
                            style={{ width: "138px" }}
                          >
                            <option value="none"></option>
                            <option value="-1">{showText.AllUser}</option>
                            {UserData.map((user, index) =>
                              user.status !== USER_STATUS.EXIT ? (
                                <option
                                  key={"user_list_" + index}
                                  value={user.user_id}
                                >
                                  {user.handle_name}
                                </option>
                              ) : null
                            )}
                          </select>
                          <span>
                            {chatSettingItem.sendSetting === "1" ? (
                              <LightTooltip
                                title={ToolTipText.ChatIcon1}
                                placement="top"
                              >
                                <CastForEducationIcon
                                  className="chatIcon"
                                  style={{ marginRight: "5px" }}
                                />
                              </LightTooltip>
                            ) : chatSettingItem.sendSetting === "2" ? (
                              <LightTooltip
                                title={ToolTipText.ChatIcon2}
                                placement="top"
                              >
                                <SchoolIcon
                                  className="chatIcon"
                                  style={{ marginRight: "5px" }}
                                />
                              </LightTooltip>
                            ) : chatSettingItem.sendSetting === "3" ? (
                              <LightTooltip
                                title={ToolTipText.ChatIcon3}
                                placement="top"
                              >
                                <SpeakerNotesOffIcon
                                  className="chatIcon"
                                  style={{ marginRight: "5px" }}
                                />
                              </LightTooltip>
                            ) : (
                              <LightTooltip
                                title={ToolTipText.ChatIcon4}
                                placement="top"
                              >
                                <ForumIcon
                                  className="chatIcon"
                                  style={{ marginRight: "5px" }}
                                />
                              </LightTooltip>
                            )}
                          </span>
                          <Button
                            size="small"
                            variant="contained"
                            className="ml10"
                            onClick={chatSettingClick}
                            style={{
                              backgroundColor: "white",
                              border: "1px solid #ccc",
                            }}
                            startIcon={<AiFillSetting />}
                          >
                            {showText.ChatControl}
                          </Button>
                          <Popover
                            id={idChatSetting}
                            open={openChatSetting}
                            anchorEl={chatSetting}
                            onClose={chatSettingClose}
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "center",
                            }}
                            transformOrigin={{
                              vertical: "bottom",
                              horizontal: "center",
                            }}
                          >
                            <ChatSetting
                              setChatSettingItem={setChatSettingItem}
                              chatSettingItem={chatSettingItem}
                              sendAction={sendAction}
                              setLog={setLog}
                            />
                          </Popover>
                        </div>

                        <div
                          className="mt05 alignCenter"
                          style={{ display: "flex" }}
                        >
                          <textarea
                            id="text_form"
                            className="send_box_textarea"
                            style={{ height: textAreaMT }}
                            maxLength={150}
                            value={chatMassage}
                            onMouseOver={() => {
                              setChatMT(-33);
                              setTextAreaMT(62);
                            }}
                            onMouseOut={() => {
                              setChatMT(0);
                              setTextAreaMT(32);
                            }}
                            onChange={(event: any) => {
                              if (clickEnter) {
                                sendMessage();
                                clickEnter = false;
                              } else {
                                setChatMassage(event.target.value);
                              }
                            }}
                          ></textarea>
                          <LightTooltip
                            title={ToolTipText.Chat}
                            placement="top"
                          >
                            <IconButton
                              aria-label="delete"
                              size="small"
                              onClick={sendMessage}
                            >
                              <SendIcon style={{ color: "#3993b2" }} />
                            </IconButton>
                          </LightTooltip>
                          <div hidden={!RTMErrFlg}>
                            <LightTooltip2
                              title={
                                <React.Fragment>
                                  {RTMConectErr1}
                                  <br />
                                  {RTMConectErr2}
                                  <br />
                                  {RTMConectErr3}
                                </React.Fragment>
                              }
                              placement="top"
                            >
                              <IconButton aria-label="delete" size="small">
                                <AiOutlineExclamationCircle
                                  color="#e32315"
                                  style={{ fontSize: "16px" }}
                                />
                              </IconButton>
                            </LightTooltip2>
                          </div>
                        </div>
                      </div>
                      <div
                        className="chat-Avatar"
                        style={{ textAlign: "left", lineHeight: "10px" }}
                      >
                        <div>{userItems}</div>
                      </div>
                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            padding: "5px",
                          }}
                        >
                          <div className="colorR">
                            {joinUserCount}
                            {FormatMessage({ id: "Cls008.Text.UserCount" })}
                          </div>
                          <div>
                            <Button
                              onClick={(e: any) => {
                                ListViewList = UserData.filter(
                                  (element: any) =>
                                    element.status !== USER_STATUS.EXIT
                                );
                                nameClick(e);
                              }}
                              className="name_button"
                              disabled={
                                operation === OPERATION_TYPE.VIDEO
                                  ? true
                                  : operation === OPERATION_TYPE.SHARE
                                  ? true
                                  : false
                              }
                            >
                              {showText.UserSelect}
                            </Button>
                          </div>
                          <Popover
                            id={idName}
                            open={nameOpen}
                            anchorEl={name}
                            onClose={nameClose}
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "center",
                            }}
                            transformOrigin={{
                              vertical: "bottom",
                              horizontal: "center",
                            }}
                          >
                            <ListName
                              ListViewList={ListViewList}
                              sendAction={sendAction}
                              requestUser={requestUser}
                            />
                          </Popover>
                        </div>

                        <div
                          className="colorR"
                          style={{ fontSize: "9px", padding: "5px" }}
                        >
                          {joinUser1 !== null &&
                          joinUser1.joinType !== undefined
                            ? joinUser1.joinType === 1
                              ? RequestText.joinType1.replace(
                                  "(NAME)",
                                  joinUser1.userName
                                )
                              : joinUser1.joinType === 2
                              ? RequestText.joinType2.replace(
                                  "(NAME)",
                                  joinUser1.userName
                                )
                              : joinUser1.joinType === 3
                              ? RequestText.joinType3.replace(
                                  "(NAME)",
                                  joinUser1.userName
                                )
                              : joinUser1.joinType === 4
                              ? RequestText.joinType4.replace(
                                  "(NAME)",
                                  joinUser1.userName
                                )
                              : null
                            : null}
                          <br />
                          {joinUser2 !== null &&
                          joinUser2.joinType !== undefined
                            ? joinUser2.joinType === 1
                              ? RequestText.joinType1.replace(
                                  "(NAME)",
                                  joinUser2.userName
                                )
                              : joinUser2.joinType === 2
                              ? RequestText.joinType2.replace(
                                  "(NAME)",
                                  joinUser2.userName
                                )
                              : joinUser2.joinType === 3
                              ? RequestText.joinType3.replace(
                                  "(NAME)",
                                  joinUser2.userName
                                )
                              : joinUser2.joinType === 4
                              ? RequestText.joinType4.replace(
                                  "(NAME)",
                                  joinUser2.userName
                                )
                              : null
                            : null}
                          {joinUser3 !== null &&
                          joinUser3.joinType !== undefined
                            ? joinUser3.joinType === 1
                              ? RequestText.joinType1.replace(
                                  "(NAME)",
                                  joinUser3.userName
                                )
                              : joinUser3.joinType === 2
                              ? RequestText.joinType2.replace(
                                  "(NAME)",
                                  joinUser3.userName
                                )
                              : joinUser3.joinType === 3
                              ? RequestText.joinType3.replace(
                                  "(NAME)",
                                  joinUser3.userName
                                )
                              : joinUser3.joinType === 4
                              ? RequestText.joinType4.replace(
                                  "(NAME)",
                                  joinUser3.userName
                                )
                              : null
                            : null}
                          {joinUser4 !== null &&
                          joinUser4.joinType !== undefined
                            ? joinUser4.joinType === 1
                              ? RequestText.joinType1.replace(
                                  "(NAME)",
                                  joinUser4.userName
                                )
                              : joinUser4.joinType === 2
                              ? RequestText.joinType2.replace(
                                  "(NAME)",
                                  joinUser4.userName
                                )
                              : joinUser4.joinType === 3
                              ? RequestText.joinType3.replace(
                                  "(NAME)",
                                  joinUser4.userName
                                )
                              : joinUser4.joinType === 4
                              ? RequestText.joinType4.replace(
                                  "(NAME)",
                                  joinUser4.userName
                                )
                              : null
                            : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={screenIsSmall ? "all_chat_b" : "nodisplay"}>
                  <IconButton onClick={chatClick}>
                    <BsChatDotsFill style={{ color: "#7cc3dc" }} />
                  </IconButton>
                  <Popover
                    id={idChat}
                    open={chatOpen}
                    anchorEl={chat}
                    onClose={chatClose}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                  >
                    <div className="chat-popup">
                      <div className="chat-body">
                        <div className="msg-insert"></div>
                      </div>
                    </div>
                  </Popover>
                  <IconButton onClick={userClick}>
                    <PersonIcon style={{ color: "#7cc3dc" }} />
                  </IconButton>
                  <Popover
                    id={idUser}
                    open={userOpen}
                    anchorEl={user}
                    onClose={userClose}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                  >
                    <div
                      className="chat-Avatar alignCenter"
                      style={{ textAlign: "left", lineHeight: "10px" }}
                    >
                      <div>{userItems}</div>
                    </div>
                  </Popover>
                  <IconButton
                    onClick={(e: any) => {
                      ListViewList = UserData.filter(
                        (element: any) => element.status !== USER_STATUS.EXIT
                      );
                      nameClick(e);
                    }}
                    disabled={
                      operation === OPERATION_TYPE.VIDEO
                        ? true
                        : operation === OPERATION_TYPE.SHARE
                        ? true
                        : false
                    }
                  >
                    <PersonAddIcon style={{ color: "#7cc3dc" }} />
                  </IconButton>
                </div>
              </td>
            ) : null}
            <td className="tableRight">
              <div
                className="whiteBoard"
                style={{
                  border: 1,
                  borderStyle: "solid",
                  borderColor: boardLyoutSetting ? "#ccc" : "red",
                }}
              >
                <div id="share-win-player"></div>
                <div>
                  <div
                    id="drowCanvas"
                    style={{
                      top: 41,
                      overflow: "hidden",
                      WebkitOverflowScrolling: "touch",
                      width: Number(canvasWide) - 1,
                      height: Number(canvasHeight) - 1,
                      padding: 0,
                    }}
                    className="canvasStyle"
                  >
                    <canvas
                      id="canvas"
                      // width={canvasWide}
                      // height={canvasHeight}
                      className="canvasStyle"
                      style={{
                        top: 0,
                        left: 0,
                        transformOrigin: "0 0",
                      }}
                    ></canvas>
                  </div>
                </div>
                <Popover
                  hideBackdrop={true}
                  className={classes.popover + " drag-and-drop"}
                  classes={{
                    paper: classes.popoverContent,
                  }}
                  id={idQuestionResult}
                  open={openQuestionResult}
                  anchorEl={questionResult}
                  anchorOrigin={{
                    vertical: "center",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                >
                  <QuestionResult
                    sendAction={sendAction}
                    actionComand={actionComand}
                    choiceId={choiceId}
                    setAnswerCatch={setAnswerCatch}
                    setSentAnswerCatch={setSentAnswerCatch}
                    switchCountDisplay={switchCountDisplay}
                    questionResultClose={questionResultClose}
                    result={result}
                    setResult={setResult}
                    getQuestionResultList={getQuestionResultList}
                    popupMousedown={popupMousedown}
                    drawShowAnswer={drawShowAnswer}
                    closeAnswerToLog={closeAnswerToLog}
                  />
                </Popover>
                <div hidden={gideMessageHidden}>
                  <div
                    className="guideMsg"
                    style={{
                      position: "absolute",
                      width: "",
                      zIndex: 5,
                      color: "white",
                      backgroundColor: "#f44336",
                      padding: 2,
                    }}
                  >
                    {gideMessage.split("\n").map((item, index) => {
                      return (
                        <React.Fragment key={index}>
                          {item}
                          <br />
                        </React.Fragment>
                      );
                    })}
                  </div>
                </div>
                {/* <div
                  className="videoMenu"
                  onMouseOver={() => {
                    setShowVideoMenu(false);
                  }}
                >
                  <button
                    onClick={playVideo}
                    className="leftIcon videoMenu_button"
                    hidden={showVideoMenu}
                  >
                    <ImPlay3></ImPlay3>
                  </button>
                  <button
                    onClick={pauseVideo}
                    className="leftIcon videoMenu_button"
                    hidden={showVideoMenu}
                  >
                    <ImPause2></ImPause2>
                  </button>
                  <button
                    onClick={stopVideo}
                    className="leftIcon videoMenu_button"
                    hidden={showVideoMenu}
                  >
                    <ImStop2></ImStop2>
                  </button>
                  <div className="leftIcon videobar" hidden={showVideoMenu}>
                    <div
                      id="seekbar"
                      onClick={seekClick}
                      className="seekbar"
                    ></div>
                  </div>
                  <span className="rightIcon videoTime" hidden={showVideoMenu}>
                    {nowVideoTime}/{videoTime}
                  </span>
                </div> */}
              </div>
              <div style={{ display: "none" }}>
                {remoteLiveUsers.map((user, index) => (
                  <div key={"remote_live_users_" + index}>
                    <MediaPlayer
                      videoTrack={user.videoTrack}
                      audioTrack={user.audioTrack}
                    ></MediaPlayer>
                  </div>
                ))}
              </div>
              <div className="live_footer">
                <span className="leftIcon">
                  <LightTooltip title={ToolTipText.Mic} placement="top">
                    <IconButton className="buttonLarge" onClick={micClick}>
                      {micStatus ? (
                        <FaMicrophoneAltSlash color="#000000" />
                      ) : (
                        <MicIcon />
                      )}
                    </IconButton>
                  </LightTooltip>
                </span>
                <span className="leftIcon">
                  <LightTooltip title={ToolTipText.Camera} placement="top">
                    <IconButton className="buttonLarge" onClick={cameraClick}>
                      {cameraStatus ? (
                        <VideocamIcon />
                      ) : (
                        <FaVideoSlash color="#000000" />
                      )}
                    </IconButton>
                  </LightTooltip>
                </span>

                <span className="leftIcon">
                  <LightTooltip title={ToolTipText.Device} placement="top">
                    <IconButton onClick={videoSetClick}>
                      <TuneIcon />
                    </IconButton>
                  </LightTooltip>
                </span>
                <Popover
                  id={idVideoSet}
                  open={videoSetOpen}
                  anchorEl={videoSet}
                  onClose={videoSetClose}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                >
                  <VideoSetting
                    micList={micList}
                    cameraList={cameraList}
                    selectMic={selectMic}
                    selectCamera={selectCamera}
                    cameraChange={cameraChange}
                    setSelectCamera={setSelectCamera}
                    setSelectMic={setSelectMic}
                    micChange={micChange}
                  />
                </Popover>
                <span className="centerIcon">
                  <LightTooltip title={ToolTipText.Finger} placement="top">
                    <IconButton
                      aria-label="delete"
                      className="buttonLarge"
                      onClick={() => {
                        addBrushOff(() => {
                          operation = OPERATION_TYPE.SELECT;
                          FabCanvas.isDrawingMode = true;
                          FabCanvas.defaultCursor = "default";
                          FabCanvas.hoverCursor = "move";
                        });
                      }}
                      disabled={
                        disableType !== ""
                          ? operation === OPERATION_TYPE.VIDEO
                            ? true
                            : operation === OPERATION_TYPE.SHARE
                            ? true
                            : false
                          : false
                      }
                    >
                      <FaRegHandPointer />
                    </IconButton>
                  </LightTooltip>
                </span>

                <span className="centerIcon">
                  <LightTooltip title={ToolTipText.Color} placement="top">
                    <IconButton
                      aria-label="delete"
                      className="buttonLarge"
                      onClick={penSetClick}
                      disabled={
                        disableType !== ""
                          ? operation === OPERATION_TYPE.VIDEO
                            ? true
                            : operation === OPERATION_TYPE.SHARE
                            ? true
                            : false
                          : false
                      }
                    >
                      {/* <Avatar src="/button/b1.JPG" /> */}
                      <FontAwesomeIcon icon={faPen} />
                    </IconButton>
                  </LightTooltip>
                </span>
                <Popover
                  id={idPenSet}
                  open={penSetOpen}
                  anchorEl={penSet}
                  onClose={penSetClose}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                >
                  <PenSetting
                    setPenColor={(v: any) => {
                      setPenSet(null);
                      penColor = v.color;
                      FabCanvas.isDrawingMode = true;
                      FabCanvas.discardActiveObject();
                    }}
                    setPenWidth={(v: any) => {
                      penWidth = v;
                      setPenSet(null);
                      FabCanvas.isDrawingMode = true;
                      FabCanvas.discardActiveObject();
                    }}
                    setOperetion={(v: any) => {
                      setPenSet(null);
                      if (v === "clear") {
                        addBrushOn();
                      } else {
                        addBrushOff(() => {
                          FabCanvas.defaultCursor = "default";
                          FabCanvas.hoverCursor = "move";
                        });
                      }
                      operation = v;
                    }}
                    sendAction={(a1: any, a2: any, a3: any) =>
                      sendAction(a1, a2, a3)
                    }
                    penColor={penColor}
                    penWidth={penWidth}
                  />
                </Popover>

                <span className="centerIcon">
                  <LightTooltip title={ToolTipText.Elaser} placement="top">
                    <IconButton
                      aria-label="FaEraser"
                      className="buttonLarge"
                      disabled={
                        disableType !== ""
                          ? operation === OPERATION_TYPE.VIDEO
                            ? true
                            : operation === OPERATION_TYPE.SHARE
                            ? true
                            : false
                          : false
                      }
                      onClick={() => {
                        operation = OPERATION_TYPE.CLEAR;
                        addBrushOn();
                      }}
                    >
                      <FaEraser />
                    </IconButton>
                  </LightTooltip>
                </span>
                <span className="centerIcon">
                  <LightTooltip title={ToolTipText.Clear} placement="top">
                    <IconButton
                      aria-label="delete"
                      className="buttonLarge"
                      disabled={
                        disableType !== ""
                          ? operation === OPERATION_TYPE.VIDEO
                            ? true
                            : operation === OPERATION_TYPE.SHARE
                            ? true
                            : false
                          : false
                      }
                      onClick={handleClickOpen}
                    >
                      <FontAwesomeIcon icon={faMagic} />
                    </IconButton>
                  </LightTooltip>
                </span>

                <span className="centerIcon">
                  <LightTooltip title={ToolTipText.Shape} placement="top">
                    <IconButton
                      aria-label="delete"
                      className="buttonLarge"
                      onClick={shapeSetClick}
                      disabled={
                        disableType !== ""
                          ? operation === OPERATION_TYPE.VIDEO
                            ? true
                            : operation === OPERATION_TYPE.SHARE
                            ? true
                            : false
                          : false
                      }
                    >
                      <FaShapes />
                    </IconButton>
                  </LightTooltip>
                </span>
                <Popover
                  id={idShapeSet}
                  open={shapeSetOpen}
                  anchorEl={shapeSet}
                  onClose={shapeSetClose}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                >
                  <ShapeSetting
                    callback={(value: string) => {
                      addBrushOff(() => {
                        shapeType = value;
                        FabCanvas.defaultCursor = "default";
                        FabCanvas.hoverCursor = "move";
                        operation = "shape";
                        setShapeSet(null);
                        FabCanvas.isDrawingMode = true;
                        FabCanvas.discardActiveObject();
                      });
                    }}
                  />
                </Popover>
                <span className="left_line"></span>
                {/* <span className="centerIcon">
                  <LightTooltip title={ToolTipText.Video} placement="top">
                    <IconButton
                      aria-label="delete"
                      className="buttonLarge"
                      onClick={videoListClick}
                      disabled={
                        disableType !== ""
                          ? operation === OPERATION_TYPE.SHARE
                            ? true
                            : false
                          : false
                      }
                    >
                      <FontAwesomeIcon icon={faFilm} />
                    </IconButton>
                  </LightTooltip>
                </span>
                <Popover
                  id={idVideoList}
                  open={videoListOpen}
                  anchorEl={videoList}
                  onClose={videoListClose}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                >
                  <VideoList
                    classId={classId}
                    userData={myuserData}
                    orderList={orderList}
                    videoLists={videoLists}
                    callback={(value: any) => {
                      //classVideo = ;
                      sendAction(ActionComand.MSG_WB_SHOW_MOVIE, null, {
                        video_id: value.video_id,
                        first_flg: true,
                      });
                      videoListClose();
                      setVideoId(value.video_id);
                      selectVideoId = value.video_id;
                      uplodeJson(null, selectBoardId, () => {
                        allClear(false);
                        changevideoScale(false);
                        drawVideo(value.video_id);
                      });
                    }}
                  />
                </Popover> */}

                <span className="centerIcon">
                  <LightTooltip title={ToolTipText.Before} placement="top">
                    <IconButton
                      aria-label="delete"
                      className="buttonLarge"
                      onClick={backArrowClick}
                      disabled={
                        disableType !== ""
                          ? operation === OPERATION_TYPE.VIDEO
                            ? true
                            : operation === OPERATION_TYPE.SHARE
                            ? true
                            : false
                          : false
                      }
                    >
                      {/* <Avatar src="/button/b4.JPG" /> */}
                      <FontAwesomeIcon icon={faChevronLeft} />
                    </IconButton>
                  </LightTooltip>
                </span>
                <span className="centerIcon">
                  <LightTooltip title={ToolTipText.Image} placement="top">
                    <IconButton
                      aria-label="delete"
                      className="buttonLarge"
                      onClick={imgListClick}
                      disabled={
                        disableType !== ""
                          ? operation === OPERATION_TYPE.VIDEO
                            ? true
                            : operation === OPERATION_TYPE.SHARE
                            ? true
                            : false
                          : false
                      }
                    >
                      {/* <Avatar src="/button/b4.JPG" /> */}
                      <FontAwesomeIcon icon={faImage} />
                    </IconButton>
                  </LightTooltip>
                </span>
                <Popover
                  id={idImgList}
                  open={imgListOpen}
                  anchorEl={imgList}
                  onClose={imgListClose}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                >
                  <ImgList
                    classId={classId}
                    setBoardImg={setBoardImg}
                    setImgItems={setImgItems}
                    imgItems={imgItems}
                    userData={myuserData}
                    changeImg={changeImg}
                    setSelectImgId={setSelectImgId}
                    drawImg={drawImg}
                    deleteImg={deleteImg}
                    sendAction={sendAction}
                    selectImgId={selectImgId}
                    selectImgList={(id: any) => {
                      selectImgNo = id;
                    }}
                    selectBoardId={selectBoardId}
                    setImgUploadDialogOpen={setImgUploadDialogOpen}
                    orderList={img_file_data}
                    imgUploadDialogOpen={imgUploadDialogOpen}
                    uploadFlg={uploadFlg}
                    setUploadFlg={setUploadFlg}
                    setUploading={setUploading}
                    setUploadFile={setUploadFile}
                    setNoChooseFileFlg={setNoChooseFileFlg}
                    setUploadFileUrl={setUploadFileUrl}
                    setChooseFileName={setChooseFileName}
                    setErrorMsgImgUp={setErrorMsgImgUp}
                  />
                </Popover>
                <span className="centerIcon">
                  <LightTooltip title={ToolTipText.Next} placement="top">
                    <IconButton
                      aria-label="delete"
                      className="buttonLarge"
                      onClick={forwardArrowClick}
                      disabled={
                        disableType !== ""
                          ? operation === OPERATION_TYPE.VIDEO
                            ? true
                            : operation === OPERATION_TYPE.SHARE
                            ? true
                            : false
                          : false
                      }
                    >
                      {/* <Avatar src="/button/b4.JPG" /> */}
                      <FontAwesomeIcon icon={faChevronRight} />
                    </IconButton>
                  </LightTooltip>
                </span>
                <span className="centerIcon">
                  <LightTooltip
                    title={
                      operation === OPERATION_TYPE.SHARE
                        ? ToolTipText.ShareStop
                        : ToolTipText.Share
                    }
                    placement="top"
                  >
                    <IconButton
                      aria-label="delete"
                      className="buttonLarge"
                      onClick={windowShare}
                      disabled={
                        deviceType === "PC"
                          ? disableType !== ""
                            ? operation === OPERATION_TYPE.VIDEO
                              ? true
                              : false
                            : false
                          : true
                      }
                    >
                      {/* <Avatar src="/button/b5.JPG" /> */}
                      {operation === OPERATION_TYPE.SHARE ? (
                        <DeleteForeverIcon />
                      ) : (
                        <ScreenShareRoundedIcon />
                      )}
                    </IconButton>
                  </LightTooltip>
                </span>
                <span className="left_line"></span>
                <span className="centerIcon">
                  <LightTooltip title={ToolTipText.Quiz} placement="top">
                    <IconButton
                      aria-label="delete"
                      className="buttonLarge"
                      onClick={questionClick}
                      disabled={
                        disableType !== ""
                          ? operation === OPERATION_TYPE.VIDEO
                            ? true
                            : operation === OPERATION_TYPE.SHARE
                            ? true
                            : false
                          : false
                      }
                    >
                      <FontAwesomeIcon icon={faListOl} />
                    </IconButton>
                  </LightTooltip>
                </span>
                <Popover
                  id={idQuestion}
                  open={openQuestion}
                  anchorEl={question}
                  onClose={questionClose}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                >
                  <Question
                    setQuestionList={setQuestionList}
                    sendAction={sendAction}
                    questionLists={questionLists}
                    questionClose={questionClose}
                  />
                </Popover>
                <span className="centerIcon">
                  <LightTooltip title={ToolTipText.Expansion} placement="top">
                    <IconButton
                      aria-label="delete"
                      className="buttonLarge"
                      onClick={expansionClick}
                      disabled={
                        disableType !== ""
                          ? operation === OPERATION_TYPE.VIDEO
                            ? true
                            : operation === OPERATION_TYPE.SHARE
                            ? true
                            : false
                          : false
                      }
                    >
                      <ImZoomIn />
                    </IconButton>
                  </LightTooltip>
                </span>
                <Popover
                  id={idExpansion}
                  open={expansionOpen}
                  anchorEl={expansion}
                  onClose={expansionClose}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                >
                  <Expansion
                    changeScale={changeScale}
                    scale={scale}
                    sendAction={sendAction}
                  />
                </Popover>
                {handFlg ? (
                  <span className="centerIcon">
                    <LightTooltip title={ToolTipText.Hand} placement="top">
                      <IconButton
                        aria-label="delete"
                        className="buttonLarge"
                        onClick={() => {
                          addBrushOff(() => {
                            operation = OPERATION_TYPE.HAND;
                            FabCanvas.isDrawingMode = true;
                            FabCanvas.defaultCursor = "default";
                            FabCanvas.hoverCursor = "move";
                          });
                        }}
                        disabled={
                          disableType !== ""
                            ? operation === OPERATION_TYPE.VIDEO
                              ? true
                              : operation === OPERATION_TYPE.SHARE
                              ? true
                              : false
                            : false
                        }
                      >
                        <FaRegHandPaper />
                      </IconButton>
                    </LightTooltip>
                  </span>
                ) : null}
                <span className="left_line"></span>
                <span className="centerIcon">
                  <LightTooltip title={ToolTipText.Board} placement="top">
                    <IconButton
                      aria-label="delete"
                      className="buttonLarge"
                      onClick={wbListClick}
                      disabled={
                        disableType !== ""
                          ? operation === OPERATION_TYPE.VIDEO
                            ? true
                            : operation === OPERATION_TYPE.SHARE
                            ? true
                            : false
                          : false
                      }
                    >
                      {/* <Avatar src="/button/b8.JPG" /> */}
                      <FontAwesomeIcon icon={faClone} />
                    </IconButton>
                  </LightTooltip>
                </span>
                <Popover
                  id={idWbList}
                  open={wbListOpen}
                  anchorEl={wbList}
                  onClose={wbListClose}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                >
                  <WhiteBoardList
                    whiteboardDataList={whiteboardDataList}
                    boardChange={boardChange}
                    selectBoardId={selectBoardId}
                  />
                </Popover>
                <span className="rightIcon">
                  <LightTooltip
                    title={
                      screenIsSmall
                        ? ToolTipText.LyoutDefolt
                        : ToolTipText.LyoutExpansion
                    }
                    placement="top"
                  >
                    <IconButton
                      className="buttonLarge"
                      onClick={() => {
                        if (screenIsSmall) {
                          exitFullScreen();
                        } else {
                          requestFullScreen();
                        }
                      }}
                    >
                      {!screenIsSmall ? (
                        <FontAwesomeIcon
                          icon={faExpandAlt}
                          className="buttonLarge"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faCompressAlt}
                          className="buttonLarge"
                        />
                      )}
                    </IconButton>
                  </LightTooltip>
                </span>
                <span className="rightIcon">
                  <LightTooltip
                    title={ToolTipText.RequestLyout}
                    placement="top"
                  >
                    <IconButton className="buttonLarge" onClick={lyoutClick}>
                      <SettingsOverscanIcon className="fontSizeLarge" />
                    </IconButton>
                  </LightTooltip>
                </span>
                <Popover
                  id={idLyout}
                  open={lyoutOpen}
                  anchorEl={lyout}
                  onClose={layoutClose}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                >
                  <LyoutSetting
                    videoLyoutSetting={videoLyoutSetting}
                    boardLyoutSetting={boardLyoutSetting}
                    vLSetting={vLSetting}
                    bLSetting={bLSetting}
                    setLog={setLog}
                    sendAction={sendAction}
                    setVideoLyoutSetting={setVideoLyoutSetting}
                    setBoardLyoutSetting={setBoardLyoutSetting}
                    setVLSetting={setVLSetting}
                    setBLSetting={setBLSetting}
                    layoutClose={layoutClose}
                  />
                </Popover>
              </div>
              <div className="live_footer_change">
                <span className="changleftIcon">
                  <LightTooltip title={ToolTipText.Mic} placement="top">
                    <IconButton className="buttonLarge" onClick={micClick}>
                      {micStatus ? (
                        <FaMicrophoneAltSlash color="#000000" />
                      ) : (
                        <MicIcon />
                      )}
                    </IconButton>
                  </LightTooltip>
                </span>
                <span className="changleftIcon">
                  <LightTooltip title={ToolTipText.Camera} placement="top">
                    <IconButton className="buttonLarge" onClick={cameraClick}>
                      {cameraStatus ? (
                        <VideocamIcon />
                      ) : (
                        <FaVideoSlash color="#000000" />
                      )}
                    </IconButton>
                  </LightTooltip>
                </span>

                <span className="changleftIcon">
                  <LightTooltip title={ToolTipText.Device} placement="top">
                    <IconButton onClick={videoSetClick}>
                      <TuneIcon />
                    </IconButton>
                  </LightTooltip>
                </span>
                <span className="changrightIcon">
                  <LightTooltip
                    title={
                      screenIsSmall
                        ? ToolTipText.LyoutDefolt
                        : ToolTipText.LyoutExpansion
                    }
                    placement="top"
                  >
                    <IconButton
                      className="buttonLarge"
                      onClick={() => {
                        if (screenIsSmall) {
                          exitFullScreen();
                        } else {
                          requestFullScreen();
                        }
                      }}
                    >
                      {!screenIsSmall ? (
                        <FontAwesomeIcon
                          icon={faExpandAlt}
                          className="buttonLarge"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faCompressAlt}
                          className="buttonLarge"
                        />
                      )}
                    </IconButton>
                  </LightTooltip>
                </span>
                <span className="changrightIcon">
                  <LightTooltip
                    title={ToolTipText.RequestLyout}
                    placement="top"
                  >
                    <IconButton className="buttonLarge" onClick={lyoutClick}>
                      <SettingsOverscanIcon className="fontSizeLarge" />
                    </IconButton>
                  </LightTooltip>
                </span>
                <Popover
                  id={idLyout}
                  open={lyoutOpen}
                  anchorEl={lyout}
                  onClose={layoutClose}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                >
                  <LyoutSetting
                    videoLyoutSetting={videoLyoutSetting}
                    boardLyoutSetting={boardLyoutSetting}
                    vLSetting={vLSetting}
                    bLSetting={bLSetting}
                    setLog={setLog}
                    sendAction={sendAction}
                    setVideoLyoutSetting={setVideoLyoutSetting}
                    setBoardLyoutSetting={setBoardLyoutSetting}
                    setVLSetting={setVLSetting}
                    setBLSetting={setBLSetting}
                    // userVideo1={userVideo1}
                    // userVideo2={userVideo2}
                    layoutClose={layoutClose}
                  />
                </Popover>
              </div>
            </td>
          </tr>
          {allWidth ? (
            <tr>
              <td className={screenIsSmall ? "nodisplay" : "small-table-left"}>
                <div style={{ height: "1px" }}>
                  <div className="video_left">
                    <div className="player-wrapper">
                      {localVideoTrack && !localVideoTrack._video_muted_ ? (
                        <div
                          className={
                            remoteUsers.length === 2
                              ? "remote-player-wrapper-3"
                              : remoteUsers.length === 1
                              ? "remote-player-wrapper-2"
                              : "remote-player-wrapper-1"
                          }
                        >
                          <MediaPlayer
                            videoTrack={localVideoTrack}
                            audioTrack={localAudioTrack}
                          ></MediaPlayer>
                        </div>
                      ) : null}

                      {remoteUsers.map((user, index) => (
                        <div
                          key={"remote_users_" + index}
                          className={
                            remoteUsers.length === 2
                              ? localVideoTrack &&
                                !localVideoTrack._video_muted_
                                ? "remote-player-wrapper-3"
                                : "remote-player-wrapper-2"
                              : remoteUsers.length === 1
                              ? localVideoTrack &&
                                !localVideoTrack._video_muted_
                                ? "remote-player-wrapper-2"
                                : "remote-player-wrapper-1"
                              : "remote-player-wrapper-1"
                          }
                        >
                          <MediaPlayer
                            videoTrack={user.videoTrack}
                            audioTrack={user.audioTrack}
                          ></MediaPlayer>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="wrapper">
                    <div
                      id="chat-box"
                      className="chat-box"
                      style={{ height: 200 }}
                    >
                      <div className="chat-body">
                        <div className="msg-insert">
                          {/* {message.map((data, index) => {
                            return (
                              <div
                                className={
                                  data.id === loginUserId
                                    ? "msg-sends"
                                    : "msg-receives"
                                }
                                key={"message_" + index}
                                style={{
                                  color:
                                    data.send_to_class === null
                                      ? "black" //"red"
                                      : "red", //"black",
                                }}
                              >
                                {data.name + ":"}
                                <br />
                                {data.messageType === 0 ? (
                                  data.message
                                ) : data.messageType === 1 ? (
                                  <span>🙂</span>
                                ) : data.messageType === 2 ? (
                                  <span>❌</span>
                                ) : (
                                  <span>✋</span>
                                )}
                              </div>
                            );
                          })} */}
                        </div>
                      </div>
                      <div ref={messagesEndRef} />
                    </div>
                  </div>
                  <div className="mt10 alignCenter">
                    <span style={{ marginRight: "5px" }}>
                      {showText.SendUser}
                    </span>
                    <select
                      name="status"
                      id="status-select"
                      className="selectBox"
                      onChange={sendChange}
                      value={chatSendUser}
                      style={{ width: "98%" }}
                    >
                      <option value="none"></option>
                      <option value="-1">{showText.AllUser}</option>
                      {UserData.map((user, index) =>
                        user.status !== USER_STATUS.EXIT ? (
                          <option
                            key={"user_list_" + index}
                            value={user.user_id}
                          >
                            {user.handle_name}
                          </option>
                        ) : null
                      )}
                    </select>
                    <span>
                      {chatSettingItem.sendSetting === "1" ? (
                        <LightTooltip
                          title={ToolTipText.ChatIcon1}
                          placement="top"
                        >
                          <CastForEducationIcon
                            className="chatIcon"
                            style={{ marginRight: "5px" }}
                          />
                        </LightTooltip>
                      ) : chatSettingItem.sendSetting === "2" ? (
                        <LightTooltip
                          title={ToolTipText.ChatIcon2}
                          placement="top"
                        >
                          <SchoolIcon
                            className="chatIcon"
                            style={{ marginRight: "5px" }}
                          />
                        </LightTooltip>
                      ) : chatSettingItem.sendSetting === "3" ? (
                        <LightTooltip
                          title={ToolTipText.ChatIcon3}
                          placement="top"
                        >
                          <SpeakerNotesOffIcon
                            className="chatIcon"
                            style={{ marginRight: "5px" }}
                          />
                        </LightTooltip>
                      ) : (
                        <LightTooltip
                          title={ToolTipText.ChatIcon4}
                          placement="top"
                        >
                          <ForumIcon
                            className="chatIcon"
                            style={{ marginRight: "5px" }}
                          />
                        </LightTooltip>
                      )}
                    </span>
                    <Button
                      size="small"
                      variant="contained"
                      className="ml10"
                      onClick={chatSettingClick}
                      style={{
                        backgroundColor: "white",
                        border: "1px solid #ccc",
                      }}
                      startIcon={<AiFillSetting />}
                    >
                      {showText.ChatControl}
                    </Button>
                    <Popover
                      id={idChatSetting}
                      open={openChatSetting}
                      anchorEl={chatSetting}
                      onClose={chatSettingClose}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                    >
                      <ChatSetting
                        setChatSettingItem={setChatSettingItem}
                        chatSettingItem={chatSettingItem}
                        sendAction={sendAction}
                        setLog={setLog}
                      />
                    </Popover>
                  </div>
                  <div className="mt05 alignCenter">
                    <textarea
                      id="text_form"
                      className="send_box_textarea"
                      style={{ height: textAreaMT, width: "95%" }}
                      maxLength={150}
                      value={chatMassage}
                      onMouseOver={() => {
                        setChatMT(-33);
                        setTextAreaMT(62);
                      }}
                      onMouseOut={() => {
                        setChatMT(0);
                        setTextAreaMT(32);
                      }}
                      onChange={(event: any) => {
                        if (clickEnter) {
                          sendMessage();
                          clickEnter = false;
                        } else {
                          setChatMassage(event.target.value);
                        }
                      }}
                    ></textarea>
                    <LightTooltip title={ToolTipText.Chat} placement="top">
                      <IconButton
                        aria-label="delete"
                        size="small"
                        onClick={sendMessage}
                        style={{ verticalAlign: "top" }}
                      >
                        <SendIcon style={{ color: "#3993b2" }} />
                      </IconButton>
                    </LightTooltip>
                  </div>
                  <div
                    className="chat-Avatar t05"
                    style={{ textAlign: "left" }}
                  >
                    <div>{userItems}</div>
                  </div>
                  <div className="ml05 mt05 mb20 alignLeft">
                    <span className="colorR">
                      {joinUserCount}
                      {showText.UserCount}
                    </span>
                    <span className="colorR">
                      {" "}
                      {joinUser1 !== null && joinUser1.joinType !== undefined
                        ? joinUser1.joinType === 1
                          ? RequestText.joinType1.replace(
                              "(NAME)",
                              joinUser1.userName
                            )
                          : joinUser1.joinType === 2
                          ? RequestText.joinType2.replace(
                              "(NAME)",
                              joinUser1.userName
                            )
                          : joinUser1.joinType === 3
                          ? RequestText.joinType3.replace(
                              "(NAME)",
                              joinUser1.userName
                            )
                          : joinUser1.joinType === 4
                          ? RequestText.joinType4.replace(
                              "(NAME)",
                              joinUser1.userName
                            )
                          : null
                        : null}
                      　<br />
                      {joinUser2 !== null && joinUser2.joinType !== undefined
                        ? joinUser2.joinType === 1
                          ? RequestText.joinType1.replace(
                              "(NAME)",
                              joinUser2.userName
                            )
                          : joinUser2.joinType === 2
                          ? RequestText.joinType2.replace(
                              "(NAME)",
                              joinUser2.userName
                            )
                          : joinUser2.joinType === 3
                          ? RequestText.joinType3.replace(
                              "(NAME)",
                              joinUser2.userName
                            )
                          : joinUser2.joinType === 4
                          ? RequestText.joinType4.replace(
                              "(NAME)",
                              joinUser2.userName
                            )
                          : null
                        : null}
                      <br />
                      {joinUser3 !== null && joinUser3.joinType !== undefined
                        ? joinUser3.joinType === 1
                          ? RequestText.joinType1.replace(
                              "(NAME)",
                              joinUser3.userName
                            )
                          : joinUser3.joinType === 2
                          ? RequestText.joinType2.replace(
                              "(NAME)",
                              joinUser3.userName
                            )
                          : joinUser3.joinType === 3
                          ? RequestText.joinType3.replace(
                              "(NAME)",
                              joinUser3.userName
                            )
                          : joinUser3.joinType === 4
                          ? RequestText.joinType4.replace(
                              "(NAME)",
                              joinUser3.userName
                            )
                          : null
                        : null}
                      　<br />
                      {joinUser4 !== null && joinUser4.joinType !== undefined
                        ? joinUser4.joinType === 1
                          ? RequestText.joinType1.replace(
                              "(NAME)",
                              joinUser4.userName
                            )
                          : joinUser4.joinType === 2
                          ? RequestText.joinType2.replace(
                              "(NAME)",
                              joinUser4.userName
                            )
                          : joinUser4.joinType === 3
                          ? RequestText.joinType3.replace(
                              "(NAME)",
                              joinUser4.userName
                            )
                          : joinUser4.joinType === 4
                          ? RequestText.joinType4.replace(
                              "(NAME)",
                              joinUser4.userName
                            )
                          : null
                        : null}
                    </span>
                    <span className="floatRight">
                      <Button onClick={nameClick} className="name_button">
                        {showText.UserSelect}
                      </Button>
                      <Popover
                        id={idName}
                        open={nameOpen}
                        anchorEl={name}
                        onClose={nameClose}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "center",
                        }}
                        transformOrigin={{
                          vertical: "bottom",
                          horizontal: "center",
                        }}
                      >
                        <ListName
                          UserData={UserData}
                          sendAction={sendAction}
                          requestUser={requestUser}
                        />
                      </Popover>
                    </span>
                  </div>
                </div>
              </td>
            </tr>
          ) : null}
        </tbody>
      </table>
      {loading ? <LoadingBox /> : null}
      <Dialog
        open={joinLecDialogOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {replyType?.status === 2
              ? RequestText.Text1.replace("(NAME)", replyType.userName).replace(
                  "(TYPE)",
                  replyType?.replyType === 1
                    ? RequestText.Option1
                    : replyType?.replyType === 2
                    ? RequestText.Option2
                    : replyType?.replyType === 3
                    ? RequestText.Option3
                    : RequestText.Option4
                )
              : replyType?.status === 3
              ? RequestText.Text2.replace("(NAME)", replyType.userName).replace(
                  "(TYPE)",
                  replyType?.replyType === 1
                    ? RequestText.Option1
                    : replyType?.replyType === 2
                    ? RequestText.Option2
                    : replyType?.replyType === 3
                    ? RequestText.Option3
                    : RequestText.Option4
                )
              : RequestText.Text3}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setJoinLecDialogOpen(false);
            }}
            color="primary"
            autoFocus
          >
            {DialogText.Button}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={dialogOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {DialogText.Text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickClose} color="primary" autoFocus>
            {DialogText.Button1}
          </Button>
          <Button
            onClick={() => {
              allClear(true);
            }}
            color="primary"
          >
            {DialogText.Button2}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={uploadErrorOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {DialogText.JsonUploadError}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeUploadErrorDialg} color="primary" autoFocus>
            {DialogText.Button}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={imgUploadDialogOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {DialogText.ImageTitle2}
        </DialogTitle>
        <DialogContent>
          <table width="100%">
            <tbody>
              <tr>
                <td>
                  <label style={{ verticalAlign: "super" }}>
                    {DialogText.ImgUploadExeT}
                    <TextFieldBox
                      className="App-input-text"
                      id="uploadFileName"
                      value={chooseFileName}
                      variant="outlined"
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        setChooseFileName(event.target.value);
                      }}
                    />
                  </label>
                </td>
              </tr>
              <tr>
                <td style={{ textAlign: "center" }}>
                  <label className="upload-label">
                    <ImageIcon />
                    <span style={{ verticalAlign: "super" }}>
                      {DialogText.ImgUploadExeF}
                    </span>
                    <input type="file" onChange={changeUploadImage} />
                  </label>
                  <Button
                    onClick={handleCameraClickOpen}
                    color="primary"
                    startIcon={<CameraAltIcon />}
                    style={{ verticalAlign: "baseline" }}
                  >
                    {DialogText.ImgUploadExe2}
                  </Button>
                </td>
              </tr>
              <tr>
                <td>
                  <span style={{ color: "red" }}>{errorMsgImgUp}</span>
                </td>
              </tr>
              <tr>
                <td style={{ textAlign: "center" }}>
                  <img
                    className={"App-Image-DropItem-Selected "}
                    src={uploadFileUrl}
                    alt=""
                  ></img>
                </td>
              </tr>
              <tr>
                <td style={{ textAlign: "center" }}>
                  <Button
                    onClick={() => {
                      setUploading(true);
                      clickUploadExecute();
                    }}
                    color="primary"
                    startIcon={<PublishIcon />}
                    autoFocus
                    disabled={noChooseFileFlg || uploading ? true : false}
                    style={{ marginRight: "28px" }}
                  >
                    {DialogText.ImgUploadB1}
                  </Button>
                  <Button
                    onClick={handleImgUploadExeClickClose}
                    startIcon={<CloseIcon />}
                    color="primary"
                  >
                    {DialogText.ImgUploadExe}
                  </Button>
                </td>
              </tr>
            </tbody>
          </table>
        </DialogContent>
      </Dialog>
      <Dialog
        open={cameraDialogOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {DialogText.ImageTitle}
        </DialogTitle>
        <DialogContent>
          <Camera
            handleCameraClickClose={handleCameraClickClose}
            changeCameraImage={changeCameraImage}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={agoraErrOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {"[" + agoraErrCode + "]" + agoraErrCode === "NOT_SUPPORTED"
              ? agoraErrText.NOT_SUPPORTED
              : agoraErrCode === "OPERATION_ABORTED"
              ? agoraErrText.OPERATION_ABORTED
              : agoraErrCode === "NETWORK_TIMEOUT"
              ? agoraErrText.NETWORK_TIMEOUT
              : agoraErrCode === "WEB_SECURITY_RESTRICT"
              ? agoraErrText.WEB_SECURITY_RESTRICT
              : agoraErrCode === "NETWORK_RESPONSE_ERROR"
              ? agoraErrText.NETWORK_RESPONSE_ERROR
              : agoraErrCode === "UID_CONFLICT"
              ? agoraErrText.UID_CONFLICT
              : agoraErrText.default}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={setAgoraErrClose} color="primary" autoFocus>
            {DialogText.Button}
          </Button>
        </DialogActions>
      </Dialog>

      {/* 二重ユーザ時 */}
      <Dialog
        open={errDifStreamerOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className="alignCenter">
          {changeStreamershow}
        </DialogContent>
      </Dialog>

      <Dialog
        open={retryErrorOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <table width="100%">
            <tbody>
              <tr>{agoraErrText.NO_USE_DEVICE}</tr>
              <tr>
                <div>
                  <FormControl style={{ width: "200px", margin: "0 10px" }}>
                    <InputLabel id="demo-customized-select-label">
                      カメラ
                    </InputLabel>

                    <Select
                      labelId="demo-customized-select-label"
                      id="demo-customized-select"
                      value={selectCamera}
                      onChange={(event) => {
                        setSelectCamera(event.target.value);
                      }}
                    >
                      {cameraList?.map((camera: any) => (
                        <MenuItem key={camera.deviceId} value={camera.label}>
                          {camera.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                <div /*className={classes.marginT10}*/>
                  <FormControl style={{ width: "200px", margin: "0 10px" }}>
                    <InputLabel id="demo-customized-select-label">
                      マイク
                    </InputLabel>
                    <Select
                      labelId="demo-customized-select-label"
                      id="demo-customized-select"
                      value={selectMic}
                      onChange={(event) => {
                        setSelectMic(event.target.value);
                      }}
                    >
                      {micList?.map((mic: any) => (
                        <MenuItem key={mic.deviceId} value={mic.label}>
                          {mic.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </tr>
              <tr>
                <td>
                  <Button
                    id="ExitButton"
                    startIcon={<FontAwesomeIcon icon={faSignOutAlt} />}
                    size="small"
                    variant="contained"
                    style={{
                      backgroundColor: "white",
                      border: "1px solid #ccc",
                      float: "right",
                      marginRight: 10,
                    }}
                    onClick={() => {
                      updateStatus("1");
                      agoraLeave(false);
                    }}
                  >
                    {showText.Exit}
                  </Button>
                  <Button
                    id="ReconectButton"
                    startIcon={<CastForEducationIcon />}
                    size="small"
                    variant="contained"
                    style={{
                      backgroundColor: "white",
                      border: "1px solid #ccc",
                      float: "right",
                      marginRight: 10,
                    }}
                    disabled={
                      selectMic !== "" && selectCamera !== "" ? false : true
                    }
                    onClick={slectAgoraJoin}
                  >
                    {DialogText.Reconect}
                  </Button>
                </td>
              </tr>
            </tbody>
          </table>
        </DialogContent>
      </Dialog>
      {/* 退出確認 */}
      <Dialog
        open={confirmClassEndOpen}
        onClose={ClassEndCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className="alignCenter">
          {DialogText.ConfirmClassEnd}
        </DialogContent>
        <DialogActions>
          <Button onClick={ClassEnd} color="primary" autoFocus>
            {DialogText.Button3}
          </Button>
          <Button onClick={ClassEndCancel} color="primary">
            {DialogText.Button4}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={offlineEndOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className="alignCenter">
          {DialogText.offlineText}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              localStorage.setItem("Cls008_session", "");
              localStorage.setItem("classTimeCount", "");
              localStorage.setItem("classStartTime", "");
              dispatch(push("/cls/001"));
            }}
            color="primary"
            autoFocus
          >
            {showText.Exit}
          </Button>
        </DialogActions>
      </Dialog>
      {/* P2P チェック */}
      <Dialog
        open={joinCheckEndOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {DialogText.p2pCheck}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              window.location.reload();
            }}
            color="primary"
            autoFocus
          >
            {DialogText.closeButton}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={p2pCheckEndOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {DialogText.p2pErrText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              window.location.reload();
            }}
            color="primary"
            autoFocus
          >
            {DialogText.closeButton}
          </Button>
        </DialogActions>
      </Dialog>
      <div id="show_pdf" style={{ display: "none" }}></div>
    </div>
  );
};
