import { apigw } from "../awsApi";
import { Dispatch } from "@reduxjs/toolkit";

const rows = {
    status:0,
    error_code : null,
    body:[
        {
        user_id:"1",
        user_name:"A"
        },
        {
        user_id:"2",
        user_name:"B"
        }
    ] 
}

/**
 * 視聴ユーザ取得
 * @param {Dispatch} dispatch reducerディスパッチ
 * @param {string} classId 授業ID
 * @param {string} envFlg 環境フラグ
 * @return コンポーネントHTMLコード内容
 */
export const ST0318 = async (
  dispatch: Dispatch,
  classId:string,
  envFlg:string
) => {
  if (process.env.REACT_APP_API_USE_FLG === "true") {
    return apigw(
      "/ST0318",
      {
        class_id:classId,
        envFlg:envFlg
      },
      dispatch
    );
  } else {
    //サンプルデータ用
    return { body: rows };
  }
};