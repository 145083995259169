import axios from "axios";
import { Dispatch } from "@reduxjs/toolkit";
import sanitize from "sanitize-html";
// import { checkData } from "./api/checkData";
// import { addNotification } from "../reducks/notificationSlice";
// import { useIntl } from "react-intl";
// import { FormatMessage } from "../components/atoms/FormatMessage";

const customerKey = process.env.REACT_APP_AGORA_REC_CUSTOMERKEY;
const customerSecret =  process.env.REACT_APP_AGORA_REC_CUSTOMERSECRET;
const plainCredential = customerKey + ":" + customerSecret;
const encodedCredential = Buffer.from(plainCredential).toString('base64')
const authorizationField = "Basic " + encodedCredential;

const instance = axios.create({
  baseURL: "https://api.agora.io/v1/apps",
  withCredentials: true,
  timeout: 100000,
  headers: {
    'Authorization': authorizationField,
    'Accept': "application/json",
    "Content-Type": "application/json;charset=utf-8",
  },
});

//get resource ID
export const apiAgoraCloudRec = async (url: string, param: any, dispatch: Dispatch) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  //const { formatMessage } = useIntl();
  let responceData;
  for (let i = 0; i < param.length; i++) {
    param[i] = sanitize(param[i])
  }
  await instance
    .post(url, param)
    //通信成功時
    .then((response) => {
      // 200リクエストが成功
      if (response.status === 200) {
        responceData = response;
      } else {

        console.log("err");
        responceData = null;
      }
    })
    // catchでエラー時
    .catch((err) => {
      console.log("err:", err);
      responceData = null;
    });
  return responceData;
};

export const apiAgoraCloudCheck = async (url: string) => {
  let responceData;
  await instance
    .get(url)
    //通信成功時
    .then((response) => {
      // 200リクエストが成功
      if (response.status === 200) {
        responceData = response;
      } else {
        console.log("err");
        responceData = null;
      }
    })
    // catchでエラー時
    .catch((err) => {
      console.log("err:", err);
      responceData = null;
    });
  return responceData;
};