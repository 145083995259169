import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getSessionUser } from "./authFlow";
import { setUser, signOut } from "../reducks/userSlice";
import { push } from "connected-react-router";

export const AuthGuard = (props: any) => {
  localStorage.redirecturl = props.location.pathname + props.location.search;

  const dispatch = useDispatch();
  const [login, setLogin] = useState(false);

  const authCheck = async () => {
    let session_id = localStorage.getItem("session_id");
    if (!session_id) {
      var userTypeFlg = localStorage.getItem("userTypeFlg")
        ? localStorage.getItem("userTypeFlg")
        : "";
      var url = localStorage.getItem("redirecturl");
      if (!userTypeFlg) {
        switch (url) {
          case "/top/001":
            userTypeFlg = "1";
            break;
          case "/cls/001":
            userTypeFlg = "1";
            break;
          case "/cls/002":
            userTypeFlg = "1";
            break;
          case "/cls/003":
            userTypeFlg = "1";
            break;
          case "/cls/004":
            userTypeFlg = "1";
            break;
          case "/cls/005":
            userTypeFlg = "1";
            break;
          case "/cls/006":
            userTypeFlg = "1";
            break;
          case "/cls/007":
            userTypeFlg = "1";
            break;
          case "/cls/008":
            userTypeFlg = "1";
            break;
          case "/cls/009":
            userTypeFlg = "1";
            break;
          case "/cls/010":
            userTypeFlg = "1";
            break;
          case "/sts/001":
            userTypeFlg = "1";
            break;
          case "/sts/002":
            userTypeFlg = "1";
            break;
          case "/sts/003":
            userTypeFlg = "1";
            break;
          case "/sts/004":
            userTypeFlg = "1";
            break;
          case "/set/001":
            userTypeFlg = "1";
            break;
          case "/set/002":
            userTypeFlg = "1";
            break;
          case "/set/003":
            userTypeFlg = "1";
            break;
          case "/set/004":
            userTypeFlg = "1";
            break;
          default:
            userTypeFlg = "2";
            break;
        }
      }
      localStorage.clear();
      sessionStorage.clear();
      dispatch(push(userTypeFlg === "1" ? "/streamer/" : "/"));
      // dispatch(push("./"));
      return;
    }

    const session = await getSessionUser();
    if (session) {
      //ログイン済みの場合
      dispatch(setUser(session));
      setLogin(true);
    } else {
      dispatch(signOut());
    }
  };

  useEffect(() => {
    //ログインチェック
    authCheck();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Fragment>{login && props.children}</Fragment>;
};
