import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { store } from "../../reducks/store";
import { MainLayout } from "../../layouts/MainLayout";
import { FormatMessage } from "../../components/atoms/FormatMessage";
import EditIcon from "@material-ui/icons/Edit";
import VpnKeyIcon from "@material-ui/icons/VpnKey";

import { US0301 } from "../../utils/api/US0301";
import { LoadingBox } from "../../components/atoms/LoadingBox";
import sanitize from "sanitize-html";

// let getDateValue: any;
/**
 * アカウント情報（配信ユーザ）
 * @param {object} props 引き継いだオブジェクト
 * @return コンポーネントHTMLコード内容
 */
export const Set001 = (props: any) => {
  // let sessionDateValue = localStorage.getItem("Set001_session") as string;
  // getDateValue =
  //   props && props.location && typeof props.location.state === "undefined"
  //     ? JSON.parse(sessionDateValue)
  //     : props.location.state;
  // if (typeof props.location.state !== "undefined") {
  //   localStorage.setItem(
  //     "Set001_session",
  //     JSON.stringify(props.location.state)
  //   );
  // }
  const dispatch = useDispatch();

  //input データ
  const [serviceId, setServiceId] = React.useState("");
  const [userId, setUserId] = React.useState("");
  const [userName, setUserName] = React.useState("");
  const [mailAddress, setMailAddress] = React.useState("");
  const [updateDate, setUpdateDate] = React.useState("");
  // loadingフラグ
  const [loading, setLoading] = React.useState(false);

  const pcCilck = () => {
    dispatch(push("/pas/001"));
  };
  const editCilck = () => {
    // dispatch(push("/set/002", { serviceId, userId }));
    dispatch(
      push("/set/002", {
        serviceId: sanitize(serviceId),
        userId: sanitize(userId),
        userName: sanitize(userName),
        mailAddress: sanitize(mailAddress),
        updateDate: sanitize(updateDate),
      })
    );
  };

  const getListData = async () => {
    setLoading(true);
    let getData = await US0301(dispatch);
    if (getData && Object.keys(getData).indexOf("body") >= 0) {
      if (getData && Object.keys(getData.body).indexOf("user_name") >= 0)
        setUserName(getData.body.user_name);
      if (getData && Object.keys(getData.body).indexOf("mail_address") >= 0)
        setMailAddress(getData.body.mail_address);
      if (getData && Object.keys(getData.body).indexOf("update_date") >= 0)
        setUpdateDate(getData.body.update_date);
    }
    setLoading(false);
  };

  // 初回1回のみ実施 → useEffectの第2引数に[]を追加
  useEffect(() => {
    const user = store.getState().user;
    setServiceId(user.service_id);
    setUserId(user.user_id);
    getListData();
  }, []);

  return (
    <MainLayout>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className="App-paper">
            <Typography
              component="h2"
              variant="h6"
              color="inherit"
              gutterBottom
            >
              {FormatMessage({ id: "Set001.Title" })}
            </Typography>
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
            >
              <Grid className="App-pLR5">
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<EditIcon />}
                  className="App-button_bottom"
                  onClick={editCilck}
                >
                  {FormatMessage({ id: "Button.Edit" })}
                </Button>
              </Grid>
              <Grid className="App-pLR5">
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<VpnKeyIcon />}
                  className="App-button_bottom"
                  onClick={pcCilck}
                >
                  {FormatMessage({ id: "Set001.Button.changepPass" })}
                </Button>
              </Grid>
            </Grid>
            <Grid className="App-box">
              <Grid container spacing={3} className="App-mT10">
                <Grid
                  item
                  xs={12}
                  sm={2}
                  style={{
                    width: "190px",
                    minWidth: "190px",
                    maxWidth: "190px",
                  }}
                >
                  {FormatMessage({ id: "Set001.Text.UserId" })}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="App-text">{userId}</div>
                </Grid>
              </Grid>
              <Grid container spacing={3} className="App-mT10">
                <Grid
                  item
                  xs={12}
                  sm={2}
                  style={{
                    width: "190px",
                    minWidth: "190px",
                    maxWidth: "190px",
                  }}
                >
                  {FormatMessage({ id: "Set001.Text.UserName" })}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="App-text">{userName}</div>
                </Grid>
              </Grid>
              <Grid container spacing={3} className="App-mT10">
                <Grid
                  item
                  xs={12}
                  sm={2}
                  style={{
                    width: "190px",
                    minWidth: "190px",
                    maxWidth: "190px",
                  }}
                >
                  {FormatMessage({ id: "Set001.Text.MailAddress" })}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="App-text">{mailAddress}</div>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      {loading ? <LoadingBox /> : null}
    </MainLayout>
  );
};
