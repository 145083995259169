import { apigw } from "../awsApi";
import { Dispatch } from "@reduxjs/toolkit";


//サンプルデータ
const state = 0;

/**
 * カスタムアンケート更新
 * @param {Dispatch} dispatch           reducerディスパッチ
 * @param {string} classId              授業ID
 * @param {string} beforeAnswerMsg      回答前メッセージ
 * @param {string} afterAnswerMsg       回答後メッセージ
 * @return コンポーネントHTMLコード内容
 */

export const OQ0310 = async (
  dispatch: Dispatch,
  classId: string,
  beforeAnswerMsg: string,
  afterAnswerMsg: string
  ) => {
    if (process.env.REACT_APP_API_USE_FLG === "true") {
      return apigw(
        "/OQ0310",
        {
          class_id:classId,
          before_answer_msg:beforeAnswerMsg,
          after_answer_msg:afterAnswerMsg
        },
        dispatch
      );
    } else {
      //サンプルデータ用
      return {state};
    }
  };