import { apigw } from "../awsApi";
import { store } from "../../reducks/store";
/**
 * セッションチェック
 * @return レスポンスデータ
 */
export const AU0314 = async () => {
  if (process.env.REACT_APP_API_USE_FLG === "true") {
    let getSession = localStorage.getItem("AU0314");
    console.log("getSession:",getSession)
    let nowTime = new Date().getTime();
    let userInfo: any = store.getState().user;
    if (
      getSession &&
      userInfo.service_id !== "" &&
      userInfo.user_id !== "" &&
      nowTime < Number(getSession) + 60 * 1000
    ) {
      console.log("NoRequestSession")
      return {
        state: "0",
        error_code: "",
        body: userInfo,
      };
    }
    let session_data = await apigw("/AU0314", {}, null);
    console.log("ResponceSessionAU0314",session_data)
    if (session_data && session_data.state === "0")
      localStorage.setItem("AU0314", String(new Date().getTime()));
    return session_data;
  } else {
    //サンプルデータ用
    return {
      state: "0",
      error_code: "",
      body: {
        service_id: "service003",
        user_id: "apitest01",
        user_name: "apitest01",
        password: "apitest01",
        password_expire_date: null,
        session_id: "c8ZKegqOfNnYp1FgsBtHntrqQdo4yccF",
        is_first_login: "0",
        user_class: "3",
        language: "ja",
        system_class: "3",
        system_version: "1.0.0",
        mailaddress: "d.akita@lincrea.co.jp",
      },
    };
  }
};
