import { apigw } from "../awsApi";

/**
 * CloudFront認証情報取得
 * @param {Dispatch} dispatch reducerディスパッチ
 * @return コンポーネントHTMLコード内容
 */

export const ST0326 = async (flg?: any) => {
  if (process.env.REACT_APP_API_USE_FLG === "true") {
    if (!flg) {
      let nowTime = Math.ceil(new Date().getTime() / 1000) + 300;
      let sessionToken: any = JSON.parse(
        String(localStorage.getItem("ST0326_session"))
      );
      if (sessionToken && Number(sessionToken.Expires) > nowTime) {
        return {
          state: "0",
          error_code: "",
          body: sessionToken,
        };
      }
    }
    if (localStorage.getItem("session_id")) {
      const result = await apigw("/ST0326", {}, null);
      if (result && result.state === "0") {
        localStorage.setItem("ST0326_session", JSON.stringify(result.body));
        return result;
      }
    }
    return null;
  } else {
    //サンプルデータ用
    return {
      state: "0",
      error_code: "",
      body: {},
    };
  }
};
