import React, { useState, useEffect } from "react";
import { store } from "../../reducks/store";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { TextFieldBox } from "../../components/atoms/TextFieldBox";
import Button from "@material-ui/core/Button";
import { FormatMessage } from "../../components/atoms/FormatMessage";
import { MainLayout } from "../../layouts/MainLayout";
import { Copyright } from "../../layouts/Copyright";
import { User, setUser } from "../../reducks/userSlice";
import { LoadingBox } from "../../components/atoms/LoadingBox";
import { CF0304 } from "../../utils/api/CF0304";
import { AU0309 } from "../../utils/api/AU0309";
import { AU0310 } from "../../utils/api/AU0310";
import { AU0304 } from "../../utils/api/AU0304";
import { AU0308 } from "../../utils/api/AU0308";
import { AU0301 } from "../../utils/api/AU0301";
import { AU0305 } from "../../utils/api/AU0305";
//import { constants } from "os";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { constants } from "os";
import RefreshIcon from "@material-ui/icons/Refresh";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
/**
 * パスワード変更
 * @return メッセージ内容
 */

export const Pas001 = (props: any) => {
  const dispatch = useDispatch();

  const REQUIRED = FormatMessage({ id: "Pas001.Text.Required" });
  const [userId, setUserId] = useState("");
  const [serviceId, setServiceId] = useState("");
  const [userClass, setUserClass] = useState("");
  const [passwordPolicy, setPasswordPolicy] = useState("");
  const [nowPassword, setNowPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmedPassword, setConfirmedPassword] = useState("");
  const [message, setMessage] = useState("");
  const [root, setRoot] = useState("");
  const [isFirstLogin, setIsFirstLogin] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const [showNowPassword, setShowNowPassword] = React.useState(false);
  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [showConfirmedPassword, setShowConfirmedPassword] =
    React.useState(false);
  const [isPhone, setIsPhone] = React.useState(false);

  //エラーフラグ
  const [incorrectPassword, setIncorrectPassword] = useState(false);
  const [duplicatePassword, setDuplicatePassword] = useState(false);
  const [discrepantPassword, setDiscrepantPassword] = useState(false);
  const [nowPasswordEmpty, setNowPasswordEmpty] = useState(false);
  const [newPasswordEmpty, setNewPasswordEmpty] = useState(false);
  const [confirmedEmpty, setConfirmedEmpty] = useState(false);
  const [nowPasswordFormat, setNowPasswordFormat] = useState(false);
  const [newPasswordFormat, setNewPasswordFormat] = useState(false);
  const [confirmedFormat, setConfirmedFormat] = useState(false);
  const [policyError, setPolicyError] = useState(false);
  const [upDateError, setUpDateError] = useState(false);

  //loadingフラグ
  const [loading, setLoading] = useState(false);

  // メッセージの定義
  const messages = {
    // 初期表示メッセージ
    fromAccounutInfo: FormatMessage({ id: "Pas001.Message.Top1" }),
    firstLogIn: FormatMessage({ id: "Pas001.Message.Top2" }),
    expired: FormatMessage({ id: "Pas001.Message.Top3" }),
    //パスワードポリシー
    CharacterClass0Str: FormatMessage({
      id: "Policy.Text1",
    }),
    CharacterClassEtcStr: FormatMessage({
      id: "Policy.Text2",
    }),
  };

  const backClick = () => {
    if (userClass === "3") {
      dispatch(push("/set/001")); //アカウント情報
    } else if (userClass === "4") {
      dispatch(push("/set/005")); //アカウント情報
    }
  };

  const cpeClick = () => {
    setNowPasswordEmpty(required(nowPassword));
    setNewPasswordEmpty(required(newPassword));
    setConfirmedEmpty(required(confirmedPassword));
    setIncorrectPassword(false);
    setDuplicatePassword(false);
    setPolicyError(false);
    setUpDateError(false);

    //入力チェック
    if (nowPassword !== "" && newPassword !== "" && confirmedPassword !== "") {
      setNowPasswordFormat(format(nowPassword));
      setNewPasswordFormat(format(newPassword));
      setConfirmedFormat(format(confirmedPassword));
      if (
        nowPassword.match(/^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/) &&
        newPassword.match(/^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/) &&
        confirmedPassword.match(/^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/)
      ) {
        //確認用パスワード一致チェック
        if (newPassword !== confirmedPassword) {
          setDiscrepantPassword(true);
        } else {
          setDiscrepantPassword(false);
          setLoading(true);
          checkPasswordPolicy().then((res) => {
            if (res) {
              if (res.state === "0") {
                upDate().then((res) => {
                  if (res.state !== "0") {
                    if (res.error_code === "04074") {
                      setIncorrectPassword(true);
                    } else {
                      setUpDateError(true);
                    }
                    setLoading(false);
                  } else {
                    if (userClass === "3") {
                      const subDomain = window.location.hostname
                        .split(".")
                        .shift();
                      // localStorage.setItem("Pas001_password", newPassword);
                      AU0301(
                        dispatch,
                        userId,
                        newPassword,
                        serviceId,
                        subDomain,
                        "1"
                      ).then((requst: any) => {
                        if (requst && requst.state === "0") {
                          localStorage.setItem(
                            "session_id",
                            requst.body.session_id
                          );
                          var userTypeFlg = localStorage.getItem("userTypeFlg")
                            ? localStorage.getItem("userTypeFlg")
                            : "1";
                          const loginData: User = {
                            service_id: requst.body.service_id,
                            user_id: requst.body.user_id,
                            user_name: requst.body.user_name,
                            password: requst.body.password,
                            password_expire_date:
                              requst.body.password_expire_date,
                            session_id: requst.body.session_id,
                            is_first_login: requst.body.is_first_login,
                            user_class: requst.body.user_class,
                            language: requst.body.language,
                            system_class: requst.body.system_class,
                            system_version: requst.body.system_class,
                            mailaddress: requst.body.mailaddress,
                            type: userTypeFlg === "1" ? "stream" : "viewer",
                            download_existence: true,
                          };
                          dispatch(setUser(loginData));
                        }
                        setLoading(false);
                        dispatch(
                          push("/pas/002", {
                            userClass: userClass,
                            root: root,
                          })
                        ); //パスワード変更完了
                      });
                    }
                    if (userClass === "4") {
                      // localStorage.setItem("Pas001_password", newPassword);
                      AU0305(
                        dispatch,
                        userId,
                        newPassword,
                        serviceId,
                        "1"
                      ).then((requst: any) => {
                        if (requst && requst.state === "0") {
                          localStorage.setItem(
                            "session_id",
                            requst.body.session_id
                          );
                          var userTypeFlg = localStorage.getItem("userTypeFlg")
                            ? localStorage.getItem("userTypeFlg")
                            : "2";
                          const loginData: User = {
                            service_id: requst.body.service_id,
                            user_id: requst.body.user_id,
                            user_name: requst.body.user_name,
                            password: requst.body.password,
                            password_expire_date:
                              requst.body.password_expire_date,
                            session_id: requst.body.session_id,
                            is_first_login: requst.body.is_first_login,
                            user_class: requst.body.user_class,
                            language: requst.body.language,
                            system_class: requst.body.system_class,
                            system_version: requst.body.system_class,
                            mailaddress: requst.body.mailaddress,
                            type: userTypeFlg === "1" ? "stream" : "viewer",
                            download_existence: true,
                          };
                          dispatch(setUser(loginData));
                        }
                        setLoading(false);
                        dispatch(
                          push("/pas/002", {
                            userClass: userClass,
                            root: root,
                          })
                        ); //パスワード変更完了
                      });
                    }
                  }
                });
              } else {
                switch (res.error_code) {
                  case "04074": // 現在PWエラー
                    setIncorrectPassword(true);
                    setLoading(false);
                    break;
                  case "04075": // PW重複エラー
                    setDuplicatePassword(true);
                    setLoading(false);
                    break;
                  case "04003": // PWポリシーエラー
                    setPolicyError(true);
                    setLoading(false);
                    break;
                  default:
                    setLoading(false);
                }
              }
            } else {
              setLoading(false);
            }
          });
        }
      }
    }
  };

  //パスワード表示切り替え
  const onClickShowNowPassword = () => {
    setShowNowPassword(!showNowPassword);
  };
  const onClickShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };
  const onClickShowConfirmedPassword = () => {
    setShowConfirmedPassword(!showConfirmedPassword);
  };

  //必須チェック
  const required = (value: string) => {
    if (value === "") {
      return true;
    } else {
      return false;
    }
  };
  //書式チェック
  const format = (value: string) => {
    if (value.match(/^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/)) {
      return false;
    } else {
      return true;
    }
  };

  //パスワードポリシーチェック
  const checkPasswordPolicy = async () => {
    let getData;
    if (userClass === "3") {
      getData = await AU0309(dispatch, newPassword, serviceId, nowPassword);
      if (getData) {
        return getData;
      }
    } else if (userClass === "4") {
      getData = await AU0310(dispatch, newPassword, serviceId, nowPassword);
      if (getData) {
        return getData;
      }
    }
  };
  //パスワード更新
  const upDate = async () => {
    let getData;
    if (userClass === "3") {
      getData = await AU0304(dispatch, "0", nowPassword, newPassword);
      if (getData && Object.keys(getData).indexOf("state") >= 0) {
        return getData;
      }
    } else if (userClass === "4") {
      getData = await AU0308(dispatch, "0", nowPassword, newPassword);
      if (getData && Object.keys(getData).indexOf("state") >= 0) {
        return getData;
      }
    }
  };

  const getListData = async () => {
    let sessionDataValue = localStorage.getItem("Pas001_session");
    const getDataValue =
      typeof props.location.state === "undefined"
        ? sessionDataValue
          ? JSON.parse(sessionDataValue)
          : sessionDataValue
        : props.location.state;
    if (typeof props.location.state !== "undefined") {
      localStorage.setItem(
        "Pas001_session",
        JSON.stringify(props.location.state)
      );
    }

    const user = store.getState().user;
    if (user) {
      setServiceId(user.service_id);
      setUserId(user.user_id);
      setUserClass(user.user_class);
      getPolicyData(user.user_class, user.service_id);

      if (
        Object.keys(user).indexOf("is_first_login") >= 0 ||
        Object.keys(user).indexOf("password_expire_date") >= 0
      ) {
        if (user.is_first_login === "1") {
          setMessage(messages.firstLogIn);
          setIsFirstLogin(true);
        } else if (
          Boolean(user.password_expire_date) &&
          lowerThanDateOnly(new Date(), new Date(user.password_expire_date))
        ) {
          //セッション情報から有効期限外か判断
          setMessage(messages.expired);
          setIsExpired(true);
        } else {
          setMessage(messages.fromAccounutInfo);
          setRoot("acount");
        }
      }
    }
  };

  //日付の比較
  const lowerThanDateOnly = (date1: any, date2: any) => {
    var year1 = date1.getFullYear();
    var month1 = date1.getMonth() + 1;
    var day1 = date1.getDate();

    var year2 = date2.getFullYear();
    var month2 = date2.getMonth() + 1;
    var day2 = date2.getDate();

    if (year1 === year2) {
      if (month1 === month2) {
        return day1 > day2;
      } else {
        return month1 > month2;
      }
    } else {
      return year1 > year2;
    }
  };

  //パスワードポリシー取得
  const getPolicyData = async (userClass: any, serviceId: any) => {
    let value;
    let length;
    let code;
    let getData = await CF0304(dispatch, userClass, serviceId, true);
    if (getData && Object.keys(getData).indexOf("body") >= 0) {
      if (Object.keys(getData.body).indexOf("code") >= 0) {
        code = getData.body.code;
      }
      if (Object.keys(getData.body).indexOf("value") >= 0) {
        value = getData.body.value;
      }
      if (Object.keys(getData.body).indexOf("string_length") >= 0) {
        length = getData.body.string_length;
      }
    }
    code === "0"
      ? setPasswordPolicy(
          messages.CharacterClass0Str.replace("【stringLength】", length)
        )
      : setPasswordPolicy(
          messages.CharacterClassEtcStr.replace("【value】", value).replace(
            "【stringLength】",
            length
          )
        );
  };

  useEffect(() => {
    getListData();
    if (
      navigator.userAgent.indexOf("Android") > 0 ||
      navigator.userAgent.indexOf("iPhone") > 0
    ) {
      setIsPhone(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const nowPasswordChange = (e: any) => {
    setNowPassword(String(e.target.value).replaceAll(" ","").replaceAll("　",""));
  };
  const newPasswordChange = (e: any) => {
    setNewPassword(String(e.target.value).replaceAll(" ","").replaceAll("　",""));
  };
  const confirmedPasswordChange = (e: any) => {
    setConfirmedPassword(String(e.target.value).replaceAll(" ","").replaceAll("　",""));
  };

  //必須項目の空欄解消時
  const nowEmptyCheck = () => {
    setNowPasswordEmpty(false);
  };
  const newEmptyCheck = () => {
    setNewPasswordEmpty(false);
  };
  const confirmedEmptyCheck = () => {
    setConfirmedEmpty(false);
  };
  //必須項目の半角エラー解消時
  const nowFormatCheck = (e: any) => {
    if (e.target.value.match(/^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/))
      setNowPasswordFormat(false);
  };
  const newFormatCheck = (e: any) => {
    if (e.target.value.match(/^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/))
      setNewPasswordFormat(false);
  };
  const confirmedFormatCheck = (e: any) => {
    if (e.target.value.match(/^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/))
      setConfirmedFormat(false);
  };
  //新しいパスワードと確認用パスワードの不一致解消時
  const newMatchCheck = (e: any) => {
    if (confirmedPassword === e.target.value) setDiscrepantPassword(false);
  };
  const confirmedMatchCheck = (e: any) => {
    if (newPassword === e.target.value) setDiscrepantPassword(false);
  };

  return isFirstLogin || isExpired ? (
    <div className="mainContent">
      <Grid item xs={12} className="App-login-header">
        {/*<img src="/logo.svg" height="24" />*/}
      </Grid>
      <Grid
        xs={12}
        container
        spacing={3}
        style={{
          minHeight: "calc(100% - 50px)",
          width: "100%",
          margin: "0 auto",
          padding: "50px 0 0 0",
        }}
      >
        <Grid item xs={12}>
          <Paper className="App-paper">
            <Typography
              component="h2"
              variant="h6"
              color="inherit"
              gutterBottom
            >
              {FormatMessage({ id: "Pas001.Title" })}
            </Typography>
            <Grid item xs={12} className="App-alignLeft">
              <Typography
                component="h2"
                variant="subtitle1"
                color="error"
                gutterBottom
              >
                {upDateError
                  ? FormatMessage({ id: "Error.Message.UpdateError" })
                  : ""}
              </Typography>
            </Grid>
            <Grid item xs={12} className="App-alignCenter">
              <Typography
                component="h2"
                variant="body1"
                color="inherit"
                gutterBottom
              >
                {message}
              </Typography>
            </Grid>
            {isPhone ? (
              <Grid className="App-box">
                <Grid
                  container
                  spacing={3}
                  className="App-List"
                  alignItems="center"
                >
                  <Grid item xs={12} sm={2} className="item-width-edit_Phone">
                    {FormatMessage({ id: "Pas001.label1" })}
                  </Grid>
                  <Grid item xs={12} sm={2} className="required_Phone">
                    {REQUIRED}
                  </Grid>

                  <Grid item xs={12} sm={6} className="item-padding">
                    <TextFieldBox
                      fullWidth
                      label={FormatMessage({ id: "Pas001.label1" })}
                      // margin="normal"
                      variant="outlined"
                      className="App-input-text"
                      value={nowPassword}
                      type={showNowPassword ? "text" : "password"}
                      onChange={(e) => {
                        nowPasswordChange(e);
                      }}
                      helperText={
                        nowPasswordEmpty
                          ? FormatMessage({
                              id: "Error.Message.RequiredError",
                              option: {
                                name: FormatMessage({ id: "Pas001.label1" }),
                              },
                            })
                          : nowPasswordFormat
                          ? FormatMessage({
                              id: "Error.Message.TypeError",
                              option: {
                                name: FormatMessage({ id: "Pas001.label1" }),
                                type: "半角",
                              },
                            })
                          : incorrectPassword
                          ? FormatMessage({ id: "Pas001.Text.Error1" })
                          : ""
                      }
                      error={
                        nowPasswordEmpty ||
                        incorrectPassword ||
                        nowPasswordFormat
                          ? true
                          : false
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={onClickShowNowPassword}
                              edge="end"
                            >
                              {showNowPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={3}
                  className="App-List"
                  alignItems="center"
                >
                  <Grid container spacing={1}>
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      className="item-width-edit"
                    ></Grid>
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      style={{
                        whiteSpace: "pre-line",
                        padding: "20px",
                        paddingBottom: "0px",
                      }}
                    >
                      {passwordPolicy}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={2} className="item-width-edit_Phone">
                    {FormatMessage({ id: "Pas001.label2" })}
                  </Grid>
                  <Grid item xs={12} sm={3} className="required_Phone">
                    {REQUIRED}
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={7}
                    style={{ padding: "20px", paddingTop: "0px" }}
                  >
                    <TextFieldBox
                      fullWidth
                      label={FormatMessage({ id: "Pas001.label2" })}
                      //margin="normal"
                      variant="outlined"
                      className="App-input-text"
                      value={newPassword}
                      type={showNewPassword ? "text" : "password"}
                      onChange={(e) => {
                        newPasswordChange(e);
                      }}
                      helperText={
                        newPasswordEmpty
                          ? FormatMessage({
                              id: "Error.Message.RequiredError",
                              option: {
                                name: FormatMessage({ id: "Pas001.label2" }),
                              },
                            })
                          : newPasswordFormat
                          ? FormatMessage({
                              id: "Error.Message.TypeError",
                              option: {
                                name: FormatMessage({ id: "Pas001.label2" }),
                                type: "半角",
                              },
                            })
                          : duplicatePassword
                          ? FormatMessage({ id: "Pas001.Text.Error2" })
                          : policyError
                          ? FormatMessage({ id: "Error.Message.PolicyError" })
                          : ""
                      }
                      error={
                        newPasswordEmpty ||
                        duplicatePassword ||
                        newPasswordFormat ||
                        policyError
                          ? true
                          : false
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={onClickShowNewPassword}
                              edge="end"
                            >
                              {showNewPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={3}
                  className="App-List"
                  alignItems="center"
                >
                  <Grid container spacing={1}>
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      className="item-width-edit"
                    ></Grid>
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      className="App-alignLeft"
                      style={{
                        padding: "20px",
                        paddingBottom: "0px",
                      }}
                    >
                      確認のためもう一度新パスワードを入力してください。
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={2} className="item-width-edit_Phone">
                    {FormatMessage({ id: "Pas001.label3" })}
                  </Grid>
                  <Grid item xs={12} sm={3} className="required_Phone">
                    {REQUIRED}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={7}
                    style={{ padding: "20px", paddingTop: "0px" }}
                  >
                    <TextFieldBox
                      fullWidth
                      label={FormatMessage({ id: "Pas001.label2" })}
                      //margin="normal"
                      variant="outlined"
                      className="App-input-text"
                      value={confirmedPassword}
                      type={showConfirmedPassword ? "text" : "password"}
                      onChange={(e) => {
                        confirmedPasswordChange(e);
                      }}
                      helperText={
                        confirmedEmpty
                          ? FormatMessage({
                              id: "Error.Message.RequiredError",
                              option: {
                                name: FormatMessage({ id: "Pas001.label3" }),
                              },
                            })
                          : confirmedFormat
                          ? FormatMessage({
                              id: "Error.Message.TypeError",
                              option: {
                                name: FormatMessage({ id: "Pas001.label3" }),
                                type: "半角",
                              },
                            })
                          : discrepantPassword
                          ? FormatMessage({ id: "Error.Message.MismatchError" })
                          : ""
                      }
                      error={
                        confirmedEmpty || discrepantPassword || confirmedFormat
                          ? true
                          : false
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={onClickShowConfirmedPassword}
                              edge="end"
                            >
                              {showConfirmedPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Grid className="App-box">
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={12} sm={6} className="item-width-edit">
                    {FormatMessage({ id: "Pas001.label1" })}
                    <span className="required_box">{REQUIRED}</span>
                  </Grid>

                  <Grid item xs={12} sm={6} className="item-padding">
                    <TextFieldBox
                      fullWidth
                      label={FormatMessage({ id: "Pas001.label1" })}
                      // margin="normal"
                      variant="outlined"
                      className="App-input-text"
                      value={nowPassword}
                      type={showNowPassword ? "text" : "password"}
                      onChange={(e) => {
                        nowPasswordChange(e);
                      }}
                      helperText={
                        nowPasswordEmpty
                          ? FormatMessage({
                              id: "Error.Message.RequiredError",
                              option: {
                                name: FormatMessage({ id: "Pas001.label1" }),
                              },
                            })
                          : nowPasswordFormat
                          ? FormatMessage({
                              id: "Error.Message.TypeError",
                              option: {
                                name: FormatMessage({ id: "Pas001.label1" }),
                                type: "半角",
                              },
                            })
                          : incorrectPassword
                          ? FormatMessage({ id: "Pas001.Text.Error1" })
                          : ""
                      }
                      error={
                        nowPasswordEmpty ||
                        incorrectPassword ||
                        nowPasswordFormat
                          ? true
                          : false
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={onClickShowNowPassword}
                              edge="end"
                            >
                              {showNowPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1} alignItems="center">
                  <Grid container spacing={1}>
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      className="item-width-edit"
                    ></Grid>
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      style={{
                        whiteSpace: "pre-line",
                        padding: "20px",
                        paddingBottom: "0px",
                      }}
                    >
                      {passwordPolicy}
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={6} className="item-width-pass">
                    {FormatMessage({ id: "Pas001.label2" })}
                    <span className="required_box">{REQUIRED}</span>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={6}
                    style={{ padding: "20px", paddingTop: "0px" }}
                  >
                    <TextFieldBox
                      fullWidth
                      label={FormatMessage({ id: "Pas001.label2" })}
                      //margin="normal"
                      variant="outlined"
                      className="App-input-text"
                      value={newPassword}
                      type={showNewPassword ? "text" : "password"}
                      onChange={(e) => {
                        newPasswordChange(e);
                      }}
                      helperText={
                        newPasswordEmpty
                          ? FormatMessage({
                              id: "Error.Message.RequiredError",
                              option: {
                                name: FormatMessage({ id: "Pas001.label2" }),
                              },
                            })
                          : newPasswordFormat
                          ? FormatMessage({
                              id: "Error.Message.TypeError",
                              option: {
                                name: FormatMessage({ id: "Pas001.label2" }),
                                type: "半角",
                              },
                            })
                          : duplicatePassword
                          ? FormatMessage({ id: "Pas001.Text.Error2" })
                          : policyError
                          ? FormatMessage({ id: "Error.Message.PolicyError" })
                          : ""
                      }
                      error={
                        newPasswordEmpty ||
                        duplicatePassword ||
                        newPasswordFormat ||
                        policyError
                          ? true
                          : false
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={onClickShowNewPassword}
                              edge="end"
                            >
                              {showNewPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1} alignItems="center">
                  <Grid container spacing={1}>
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      className="item-width-edit"
                    ></Grid>
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      className="App-alignLeft"
                      style={{
                        padding: "20px",
                        paddingBottom: "0px",
                      }}
                    >
                      確認のためもう一度新パスワードを入力してください。
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={6} className="item-width-pass">
                    {FormatMessage({ id: "Pas001.label3" })}
                    <span className="required_box">{REQUIRED}</span>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={6}
                    style={{ padding: "20px", paddingTop: "0px" }}
                  >
                    <TextFieldBox
                      fullWidth
                      label={FormatMessage({ id: "Pas001.label2" })}
                      //margin="normal"
                      variant="outlined"
                      className="App-input-text"
                      value={confirmedPassword}
                      type={showConfirmedPassword ? "text" : "password"}
                      onChange={(e) => {
                        confirmedPasswordChange(e);
                      }}
                      helperText={
                        confirmedEmpty
                          ? FormatMessage({
                              id: "Error.Message.RequiredError",
                              option: {
                                name: FormatMessage({ id: "Pas001.label3" }),
                              },
                            })
                          : confirmedFormat
                          ? FormatMessage({
                              id: "Error.Message.TypeError",
                              option: {
                                name: FormatMessage({ id: "Pas001.label3" }),
                                type: "半角",
                              },
                            })
                          : discrepantPassword
                          ? FormatMessage({ id: "Error.Message.MismatchError" })
                          : ""
                      }
                      error={
                        confirmedEmpty || discrepantPassword || confirmedFormat
                          ? true
                          : false
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={onClickShowConfirmedPassword}
                              edge="end"
                            >
                              {showConfirmedPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid item xs={12} className="App-alignCenter">
              <Button
                variant="contained"
                color="primary"
                startIcon={<RefreshIcon />}
                className="App-mT20 App-button_bottom"
                onClick={cpeClick}
              >
                {FormatMessage({ id: "Button.Update" })}
              </Button>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Copyright />
      {loading ? <LoadingBox /> : null}
    </div>
  ) : (
    <MainLayout>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className="App-paper">
            <Typography
              component="h2"
              variant="h6"
              color="inherit"
              gutterBottom
            >
              {FormatMessage({ id: "Pas001.Title" })}
            </Typography>
            <Grid item xs={12} className="App-alignLeft">
              <Typography
                component="h2"
                variant="subtitle1"
                color="error"
                gutterBottom
              >
                {upDateError
                  ? FormatMessage({ id: "Error.Message.UpdateError" })
                  : ""}
              </Typography>
            </Grid>
            <BackAccountInfoButton
              root={root}
              accountInfoFlg="acount"
              backClick={backClick}
            />
            <Grid item xs={12} className="App-alignCenter">
              <Typography
                component="h2"
                variant="body1"
                color="inherit"
                gutterBottom
              >
                {message}
              </Typography>
            </Grid>
            {isPhone ? (
              <Grid className="App-box">
                <Grid
                  container
                  spacing={3}
                  className="App-List"
                  alignItems="center"
                >
                  <Grid item xs={12} sm={2} className="item-width-edit_Phone">
                    {FormatMessage({ id: "Pas001.label1" })}
                  </Grid>
                  <Grid item xs={12} sm={2} className="required_Phone">
                    {REQUIRED}
                  </Grid>
                  <Grid item xs={12} sm={8} className="item-padding">
                    <TextFieldBox
                      fullWidth
                      label={FormatMessage({ id: "Pas001.label1" })}
                      //margin="normal"
                      variant="outlined"
                      className="App-input-text"
                      value={nowPassword}
                      type={showNowPassword ? "text" : "password"}
                      onChange={(e) => {
                        nowPasswordChange(e);
                      }}
                      helperText={
                        nowPasswordEmpty
                          ? FormatMessage({
                              id: "Error.Message.RequiredError",
                              option: {
                                name: FormatMessage({ id: "Pas001.label1" }),
                              },
                            })
                          : nowPasswordFormat
                          ? FormatMessage({
                              id: "Error.Message.TypeError",
                              option: {
                                name: FormatMessage({ id: "Pas001.label1" }),
                                type: "半角",
                              },
                            })
                          : incorrectPassword
                          ? FormatMessage({ id: "Pas001.Text.Error1" })
                          : ""
                      }
                      error={
                        nowPasswordEmpty ||
                        incorrectPassword ||
                        nowPasswordFormat
                          ? true
                          : false
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={onClickShowNowPassword}
                              edge="end"
                            >
                              {showNowPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={3}
                  className="App-List"
                  alignItems="center"
                >
                  <Grid container spacing={1}>
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      className="item-width-edit"
                    ></Grid>
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      style={{
                        whiteSpace: "pre-line",
                        padding: "20px",
                        paddingBottom: "0px",
                      }}
                    >
                      {passwordPolicy}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={2} className="item-width-edit_Phone">
                    {FormatMessage({ id: "Pas001.label2" })}
                  </Grid>
                  <Grid item xs={12} sm={2} className="required_Phone">
                    {REQUIRED}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={8}
                    style={{ padding: "20px", paddingTop: "0px" }}
                  >
                    <TextFieldBox
                      fullWidth
                      label={FormatMessage({ id: "Pas001.label2" })}
                      //margin="normal"
                      variant="outlined"
                      className="App-input-text"
                      value={newPassword}
                      type={showNewPassword ? "text" : "password"}
                      onChange={(e) => {
                        newPasswordChange(e);
                      }}
                      helperText={
                        newPasswordEmpty
                          ? FormatMessage({
                              id: "Error.Message.RequiredError",
                              option: {
                                name: FormatMessage({ id: "Pas001.label2" }),
                              },
                            })
                          : newPasswordFormat
                          ? FormatMessage({
                              id: "Error.Message.TypeError",
                              option: {
                                name: FormatMessage({ id: "Pas001.label2" }),
                                type: "半角",
                              },
                            })
                          : duplicatePassword
                          ? FormatMessage({ id: "Pas001.Text.Error2" })
                          : policyError
                          ? FormatMessage({ id: "Error.Message.PolicyError" })
                          : ""
                      }
                      error={
                        newPasswordEmpty ||
                        duplicatePassword ||
                        newPasswordFormat ||
                        policyError
                          ? true
                          : false
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={onClickShowNewPassword}
                              edge="end"
                            >
                              {showNewPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={3}
                  className="App-List"
                  alignItems="center"
                >
                  <Grid container spacing={1}>
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      className="item-width-edit"
                    ></Grid>
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      className="App-alignLeft"
                      style={{
                        padding: "20px",
                        paddingBottom: "0px",
                      }}
                    >
                      確認のためもう一度新パスワードを入力してください。
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={2} className="item-width-edit_Phone">
                    {FormatMessage({ id: "Pas001.label3" })}
                  </Grid>
                  <Grid item xs={12} sm={2} className="required_Phone">
                    {REQUIRED}
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={8}
                    style={{ padding: "20px", paddingTop: "0px" }}
                  >
                    <TextFieldBox
                      fullWidth
                      label={FormatMessage({ id: "Pas001.label2" })}
                      //margin="normal"
                      variant="outlined"
                      className="App-input-text"
                      value={confirmedPassword}
                      type={showConfirmedPassword ? "text" : "password"}
                      onChange={(e) => {
                        confirmedPasswordChange(e);
                      }}
                      helperText={
                        confirmedEmpty
                          ? FormatMessage({
                              id: "Error.Message.RequiredError",
                              option: {
                                name: FormatMessage({ id: "Pas001.label3" }),
                              },
                            })
                          : confirmedFormat
                          ? FormatMessage({
                              id: "Error.Message.TypeError",
                              option: {
                                name: FormatMessage({ id: "Pas001.label3" }),
                                type: "半角",
                              },
                            })
                          : discrepantPassword
                          ? FormatMessage({ id: "Error.Message.MismatchError" })
                          : ""
                      }
                      error={
                        confirmedEmpty || discrepantPassword || confirmedFormat
                          ? true
                          : false
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={onClickShowConfirmedPassword}
                              edge="end"
                            >
                              {showConfirmedPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Grid className="App-box">
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={12} sm={4} className="item-width-edit">
                    {FormatMessage({ id: "Pas001.label1" })}
                    <span className="required_box">{REQUIRED} </span>
                  </Grid>
                  <Grid item xs={12} sm={8} className="item-padding">
                    <TextFieldBox
                      fullWidth
                      label={FormatMessage({ id: "Pas001.label1" })}
                      //margin="normal"
                      variant="outlined"
                      className="App-input-text"
                      value={nowPassword}
                      type={showNowPassword ? "text" : "password"}
                      onChange={(e) => {
                        nowPasswordChange(e);
                      }}
                      helperText={
                        nowPasswordEmpty
                          ? FormatMessage({
                              id: "Error.Message.RequiredError",
                              option: {
                                name: FormatMessage({ id: "Pas001.label1" }),
                              },
                            })
                          : nowPasswordFormat
                          ? FormatMessage({
                              id: "Error.Message.TypeError",
                              option: {
                                name: FormatMessage({ id: "Pas001.label1" }),
                                type: "半角",
                              },
                            })
                          : incorrectPassword
                          ? FormatMessage({ id: "Pas001.Text.Error1" })
                          : ""
                      }
                      error={
                        nowPasswordEmpty ||
                        incorrectPassword ||
                        nowPasswordFormat
                          ? true
                          : false
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={onClickShowNowPassword}
                              edge="end"
                            >
                              {showNowPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={1} alignItems="center">
                  <Grid container spacing={1}>
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      className="item-width-edit"
                    ></Grid>
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      style={{
                        whiteSpace: "pre-line",
                        padding: "20px",
                        paddingBottom: "0px",
                      }}
                    >
                      {passwordPolicy}
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={2} className="item-width-pass">
                    {FormatMessage({ id: "Pas001.label2" })}
                    <span className="required_box">{REQUIRED}</span>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={8}
                    style={{ padding: "20px", paddingTop: "0px" }}
                  >
                    <TextFieldBox
                      fullWidth
                      label={FormatMessage({ id: "Pas001.label2" })}
                      //margin="normal"
                      variant="outlined"
                      className="App-input-text"
                      value={newPassword}
                      type={showNewPassword ? "text" : "password"}
                      onChange={(e) => {
                        newPasswordChange(e);
                      }}
                      helperText={
                        newPasswordEmpty
                          ? FormatMessage({
                              id: "Error.Message.RequiredError",
                              option: {
                                name: FormatMessage({ id: "Pas001.label2" }),
                              },
                            })
                          : newPasswordFormat
                          ? FormatMessage({
                              id: "Error.Message.TypeError",
                              option: {
                                name: FormatMessage({ id: "Pas001.label2" }),
                                type: "半角",
                              },
                            })
                          : duplicatePassword
                          ? FormatMessage({ id: "Pas001.Text.Error2" })
                          : policyError
                          ? FormatMessage({ id: "Error.Message.PolicyError" })
                          : ""
                      }
                      error={
                        newPasswordEmpty ||
                        duplicatePassword ||
                        newPasswordFormat ||
                        policyError
                          ? true
                          : false
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={onClickShowNewPassword}
                              edge="end"
                            >
                              {showNewPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1} alignItems="center">
                  <Grid container spacing={1}>
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      className="item-width-edit"
                    ></Grid>
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      className="App-alignLeft"
                      style={{
                        padding: "20px",
                        paddingBottom: "0px",
                      }}
                    >
                      確認のためもう一度新パスワードを入力してください。
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={4} className="item-width-pass">
                    {FormatMessage({ id: "Pas001.label3" })}
                    <span className="required_box">{REQUIRED}</span>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={8}
                    style={{ padding: "20px", paddingTop: "0px" }}
                  >
                    <TextFieldBox
                      fullWidth
                      label={FormatMessage({ id: "Pas001.label2" })}
                      //margin="normal"
                      variant="outlined"
                      className="App-input-text"
                      value={confirmedPassword}
                      type={showConfirmedPassword ? "text" : "password"}
                      onChange={(e) => {
                        confirmedPasswordChange(e);
                      }}
                      helperText={
                        confirmedEmpty
                          ? FormatMessage({
                              id: "Error.Message.RequiredError",
                              option: {
                                name: FormatMessage({ id: "Pas001.label3" }),
                              },
                            })
                          : confirmedFormat
                          ? FormatMessage({
                              id: "Error.Message.TypeError",
                              option: {
                                name: FormatMessage({ id: "Pas001.label3" }),
                                type: "半角",
                              },
                            })
                          : discrepantPassword
                          ? FormatMessage({ id: "Error.Message.MismatchError" })
                          : ""
                      }
                      error={
                        confirmedEmpty || discrepantPassword || confirmedFormat
                          ? true
                          : false
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={onClickShowConfirmedPassword}
                              edge="end"
                            >
                              {showConfirmedPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid item xs={12} className="App-alignCenter">
              <Button
                variant="contained"
                startIcon={<RefreshIcon />}
                color="primary"
                className="App-mT20 App-button_bottom"
                onClick={cpeClick}
              >
                {FormatMessage({ id: "Button.Update" })}
              </Button>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      {loading ? <LoadingBox /> : null}
    </MainLayout>
  );
};

/**
 * アカウント情報に戻るボタン切り替え
 * @param {object} editFlg 遷移元判定フラグ
 * @param {object} isInitPassword パスワード初期化フラグ
 * @return コンポーネントHTMLコード内容
 */
const BackAccountInfoButton = (props: any) => {
  const { root, accountInfoFlg, backClick } = props;

  if (root === accountInfoFlg) {
    return (
      <React.Fragment>
        <Grid item xs={12} className="App-alignRight">
          <Button
            variant="contained"
            startIcon={<ArrowBackIcon />}
            color="primary"
            onClick={backClick}
          >
            {FormatMessage({ id: "Pas001.Button.Back" })}
          </Button>
        </Grid>
      </React.Fragment>
    );
  } else {
    return <React.Fragment></React.Fragment>;
  }
};
