import { User } from "../reducks/userSlice";
import { AU0313 } from "../utils/api/AU0313";
import { AU0314 } from "../utils/api/AU0314";
let beforeUser: any;

export const getSessionUser = async () => {
  var userTypeFlg = localStorage.getItem("userTypeFlg")
    ? localStorage.getItem("userTypeFlg")
    : "2";
  let session_id = localStorage.getItem("session_id");
  let session_expiration = localStorage.getItem("session_expiration");
  if (
    beforeUser &&
    session_expiration &&
    new Date().getTime() < Number(session_expiration)
  ) {
    return beforeUser;
  }
  if (session_id) {
    const sessionData = userTypeFlg === "1" ? await AU0313() : await AU0314();
    console.log("sessionDataRes:", sessionData);
    if (sessionData && Object.keys(sessionData).indexOf("body") >= 0) {
      const user: User = {
        service_id: sessionData.body.service_id,
        user_id: sessionData.body.user_id,
        user_name: sessionData.body.user_name,
        password: sessionData.body.password,
        password_expire_date: sessionData.body.password_expire_date,
        session_id: sessionData.body.session_id,
        type: userTypeFlg === "1" ? "stream" : "viewer",
        is_first_login: sessionData.body.is_first_login,
        language: sessionData.body.language,
        user_class: sessionData.body.user_class,
        system_class: sessionData.body.system_class,
        system_version: sessionData.body.system_version,
        mailaddress: sessionData.body.mailaddress,
        download_existence: sessionData.body.download_existence,
      };
      beforeUser = user;
      localStorage.setItem("session_id", sessionData.body.session_id);
      localStorage.setItem(
        "session_expiration",
        String(new Date().getTime() + 30 * 60 * 1000)
      );
      return user;
    } else if (sessionData === -1) {
      const sessionData = userTypeFlg === "1" ? await AU0313() : await AU0314();
      console.log("sessionDataReRes:", sessionData);
      if (sessionData && Object.keys(sessionData).indexOf("body") >= 0) {
        const user: User = {
          service_id: sessionData.body.service_id,
          user_id: sessionData.body.user_id,
          user_name: sessionData.body.user_name,
          password: sessionData.body.password,
          password_expire_date: sessionData.body.password_expire_date,
          session_id: sessionData.body.session_id,
          type: userTypeFlg === "1" ? "stream" : "viewer",
          is_first_login: sessionData.body.is_first_login,
          language: sessionData.body.language,
          user_class: sessionData.body.user_class,
          system_class: sessionData.body.system_class,
          system_version: sessionData.body.system_version,
          mailaddress: sessionData.body.mailaddress,
          download_existence: sessionData.body.download_existence,
        };
        beforeUser = user;
        localStorage.setItem("session_id", sessionData.body.session_id);
        localStorage.setItem(
          "session_expiration",
          String(new Date().getTime() + 30 * 60 * 1000)
        );
        return user;
      } else if (sessionData === -1 && beforeUser) {
        console.log("sessionDatafalse1:");
        return beforeUser;
      }
      console.log("sessionDatafalse3:");
    }
    console.log("sessionDatafalse2:");
  }
  return false;
};
