import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    safariInputDate: {
      fontSize: "small",
      color: "#FF0000",
    },
  }));

/**
 * @type (safari用)input日付選択インターフェイス
 */
 type InputDateProps = {
    error?: boolean;
    name?: string;
    value?: string;
    helperText?: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    disabled?: boolean;
  };
  /**
   * (Safari用)input日付選択
   * @param {Item[]} name 名前
   * @param {string} value 開始日or終了日の値
   * @param {boolean} error 開始日or終了日エラーフラグ
   * @param {string} helperText 開始日or終了日ボックスの下に表示内容
   * @param {function} onChange 開始日or終了日変更時のハンドル処理
   * @param {function} onBlur 開始日or終了日入力時のハンドル処理
   * @param {boolean} disabled 非活性or活性フラグ
   * @return 共有日付選択
   */
  
  export const InputDate: React.FC<InputDateProps> = ({
    name = "",
    value = "",
    error = false,
    helperText = "",
    onChange = () => {},
    onBlur = () => {},
    disabled = false,
  }: InputDateProps) => {
    const classes = useStyles();
  
    return (
      <div>
        <input
          style={{
            height: "34px",
            padding: "6px 10px",
          }}
          type="date"
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
        />
        <div style={{ marginTop: "3px", marginLeft: "15px" }}>
          {error ? (
            <label className={classes.safariInputDate}>{helperText}</label>
          ) : null}
        </div>
      </div>
    );
  };