import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import { FormatMessage } from "../../components/atoms/FormatMessage";
import "../../css/cls/cls008.css";

let video: any;
let ctxCam: any;
let canvasCamera: any;

export default function camera(props: any) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [cameraStatus, setCameraStatus] = React.useState(true);
  const imgCameraOn = () => {
    video = document.getElementById("camera") as HTMLVideoElement;
    canvasCamera = document.getElementById("canvasCamera");
    ctxCam = canvasCamera.getContext("2d");

    /** カメラ設定 */
    var constraints = {
      audio: false,
      video: {
        width: 400,
        height: 300,
        facingMode: "user", // フロントカメラを利用する
        // facingMode: { exact: "environment" }  // リアカメラを利用する場合
      },
    };

    /**
     * カメラを<video>と同期
     */
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        video.srcObject = stream;
        video.onloadedmetadata = () => {
          video.play();
        };
      })
      .catch((err) => {
        console.log(err.name + ": " + err.message);
      });
  };

  const onShutter = () => {
    if (cameraStatus) {
      video.pause(); // 映像を停止
      // canvasに画像を貼り付ける
      setCameraStatus(false);
      ctxCam.canvas.height = video.videoHeight;
      ctxCam.canvas.width = video.videoWidth;
      ctxCam.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
    } else {
      const image = ctxCam.canvas.toDataURL();
      var bin = atob(image.replace(/^.*,/, ""));
      var buffer = new Uint8Array(bin.length);
      for (var i = 0; i < bin.length; i++) {
        buffer[i] = bin.charCodeAt(i);
      }
      const blob: any = new Blob([buffer.buffer], { type: "image/png" });
      props.changeCameraImage(blob);
      video = document.getElementById("camera") as HTMLVideoElement;
      video.srcObject.getVideoTracks().forEach((track: any) => {
        track.stop();
      });
      video.srcObject.getAudioTracks().forEach((track: any) => {
        track.stop();
      });
      video.srcObject = null;
      props.handleCameraClickClose();
    }
  };

  const onRetry = () => {
    video.play(); // 映像を再開
    // canvasに画像を貼り付ける
    setCameraStatus(true);
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    imgCameraOn();
  }, []);

  return (
    <table width="100%">
      <tbody>
        <tr>
          <td>
            <Button onClick={onRetry} color="primary" hidden={cameraStatus}>
              {FormatMessage({
                id: "Cls008.Dialog.Camera.Button1",
              })}
            </Button>
            <Button onClick={onShutter} color="primary">
              {FormatMessage({
                id: cameraStatus
                  ? "Cls008.Dialog.Camera.Button2"
                  : "Cls008.Dialog.Camera.Button3",
              })}
            </Button>
            <Button onClick={props.handleCameraClickClose} color="primary">
              {FormatMessage({
                id: "Cls008.Dialog.Camera.Button4",
              })}
            </Button>
          </td>
        </tr>
        <tr>
          <td>
            <div className="camera_video_wrapper">
              <video
                id="camera"
                playsInline
                style={{ width: 400, height: 300 }}
              ></video>
            </div>
            <canvas
              id="canvasCamera"
              width="400px"
              height="300px"
              style={{
                backgroundColor: "red",
              }}
              hidden
            ></canvas>
          </td>
        </tr>
      </tbody>
    </table>
  );
}
