import { apigw } from "../awsApi";
import { Dispatch } from "@reduxjs/toolkit";

/**
 * キーワード一覧取得
 * @param {Dispatch} dispatch reducerディスパッチ
 * @param {string} userClass ユーザ区分
 * @return コンポーネントHTMLコード内容
 */
export const KY0301 = async (dispatch: Dispatch) => {
  if (process.env.REACT_APP_API_USE_FLG === "true") {
    return await apigw(
      "/KY0301",
      {},
      dispatch
    );
  } else {
    //サンプルデータ用
    return { state: "0", body:[
      { keyword_id: 1, service_id: "service003", sort_order: 1, keyword: "キーワードテキストサンプル００１" },
      { keyword_id: 2, service_id: "service003", sort_order: 2, keyword: "キーワードテキストサンプル００２" },
      { keyword_id: 3, service_id: "service003", sort_order: 3, keyword: "キーワードテキストサンプル００３" },
      { keyword_id: 4, service_id: "service003", sort_order: 4, keyword: "キーワードテキストサンプル００４" },
      { keyword_id: 5, service_id: "service003", sort_order: 5, keyword: "キーワードテキストサンプル００５" },
    ]};
  }
};