import { apigw } from "../awsApi";
import { Dispatch } from "@reduxjs/toolkit";

/**
 * 操作ログ一覧取得（視聴ユーザ）
 * @param {Dispatch} dispatch reducerディスパッチ
 * @param {string} classId 授業ID
 * @return コンポーネントHTMLコード内容
 */
export const ST0321 = async (dispatch: Dispatch, classId: string) => {
  if (process.env.REACT_APP_API_USE_FLG === "true") {
    return apigw(
      "/ST0321",
      {
        class_id: classId,
      },
      dispatch
    );
  } else {
    //サンプルデータ
    let rows: {
      choice_id: string; //択一問題ID
      recording_video_id: string; //録画動画ID
      elapsed_time: number; //経過
      operation_date: string; //操作日時
      operation_class: string; //操作区分
      //   1:チャット送信　2:チャット非表示
      //   3:チャット表示　4:択一問題表示
      //   5:映像最大化　　6:映像元に戻す
      //   7:ボード最大化　8:ボード元に戻す
      //   9:視聴者参加
    }[] = [];
    for (let index = 1; index < 50; index++) {
      rows.push({
        choice_id: "test01",
        recording_video_id: "",
        elapsed_time: index * 10,
        operation_date: "",
        operation_class: "4",
      });
    }
    return { state: "0", error_code: "", body: rows };
  }
};
