import React, { useEffect, useLayoutEffect } from "react";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import AddToPhotosIcon from "@material-ui/icons/AddToPhotos";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import ButtonBase from "@material-ui/core/ButtonBase";
import { useDispatch } from "react-redux";
import Typography from "@material-ui/core/Typography";
import CardMedia from "@material-ui/core/CardMedia";
import { CT0307 } from "../../utils/api/CT0307";
import { ST0326 } from "../../utils/api/ST0326";
import ActionComand from "./comand";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { withStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { FormatMessage } from "../../components/atoms/FormatMessage";

import { createS3, getUrlS3 } from "../../components/function/AwsS3Fun";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    borderBottom: "1px solid #ccc",
    height: 70,
  },

  icon: {
    color: "rgba(255, 255, 255, 0.54)",
  },
  colorWhite: {
    color: "white",
  },
  alignCenter: {
    textAlign: "center",
  },
  details: {
    display: "flex",
    flexDirection: "column",
    padding: "4px 8px",
    width: 208,
  },
  content: {
    flex: "1 0 auto",
    padding: "4px 8px",
  },
  cover: {
    width: 65,
    height: 65,
    objectFit: "contain",
  },
  region: {
    height: 300,
    width: 280,
    overflow: "hidden",
  },
  item: {
    height: 250,
    overflowX: "hidden",
    overflowY: "auto",
  },
}));
// let ref: any;
const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: "#7cc3dc",
    border: "1px solid #ccc",
    color: "rgba(255, 255, 255, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

// let img_file_data: any = {};
let orderList: any;
let changeFlg: any;

export default function TitlebarGridList(props: any) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const classId = props.classId;

  type imgItem = {
    board_document_id: number; //画像ID,
    board_document_name: string; //画像ファイル名
    s3_id: any; //画像URL
    file_url: any;
  };
  const [imgList, setImgList] = React.useState<imgItem[]>([]);
  const [selectImgId, setSelectImgId] = React.useState(props.selectImgId);
  // const [pop, setPop] = React.useState(false);
  // const changeContentType = (fileName: any, file: any) => {
  //   switch (fileName) {
  //     case "jpg":
  //     case "jpeg":
  //       return file.replace(
  //         "data:application/octet-stream;base64,",
  //         "data:image/jpeg;base64,"
  //       );
  //     case "png":
  //       return file.replace(
  //         "data:application/octet-stream;base64,",
  //         "data:image/png;base64,"
  //       );
  //     case "gif":
  //       return file.replace(
  //         "data:application/octet-stream;base64,",
  //         "data:image/gif;base64,"
  //       );
  //     case "bmp":
  //       return file.replace(
  //         "data:application/octet-stream;base64,",
  //         "data:image/bmp;base64,"
  //       );
  //     case "json":
  //       return file.replace(
  //         "data:application/octet-stream;base64,",
  //         "data:application/json;base64,"
  //       );
  //     case "pdf":
  //       return file.replace(
  //         "data:application/octet-stream;base64,",
  //         "data:application/pdf;base64,"
  //       );

  //     default:
  //       return file;
  //   }
  // };

  const selectedItem = () => {
    document.getElementById("selected")?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  const clickImg = (vulue: any) => {
    setSelectImgId(vulue.board_document_id);
    props.setBoardImg(vulue.file_url);
    props.setSelectImgId(vulue.board_document_id);
    props.sendAction(ActionComand.MSG_WB_SHOW_PICTURE, null, {
      board_document_id: vulue.board_document_id,
    });
    // props.drawImg(vulue.file_url);
    props.drawImg(vulue.s3_id);
    props.selectImgList(vulue.board_document_id);
    setTimeout(() => selectedItem(), 300);
  };

  const deleteImg = () => {
    props.setBoardImg("");
    setSelectImgId(-1);
    props.setSelectImgId(-1);
    props.sendAction(ActionComand.MSG_WB_SHOW_PICTURE, null, {
      board_document_id: -1,
    });
    props.deleteImg();
  };

  const backArrowClick = () => {
    if (!changeFlg) {
      changeFlg = true;
      let index = orderList.indexOf(Number(selectImgId));
      if (index === 0) {
        changeFlg = false;
        return;
      }
      const changeId = orderList[index - 1];
      props.changeImg(changeId).then((id: any) => {
        if (id) setSelectImgId(id);
        setTimeout(() => selectedItem(), 300);
        changeFlg = false;
      });
    }
  };

  const forwardArrowClick = () => {
    if (!changeFlg) {
      changeFlg = true;
      let index = orderList.indexOf(Number(selectImgId));
      if (index === orderList.length) {
        changeFlg = false;
        return;
      }
      const changeId = orderList[index + 1];
      props.changeImg(changeId).then((id: any) => {
        if (id) setSelectImgId(id);
        setTimeout(() => selectedItem(), 300);
        changeFlg = false;
      });
    }
  };

  const chengeScale = (event: any) => {
    const image: any = document.getElementById(event.target.id);
    if (image) {
      image.style.transform = "scale(1.2,1.2)";
    }
  };

  const scaleReset = (event: any) => {
    const image: any = document.getElementById(event.target.id);
    if (image) {
      image.style.transform = "scale(1.0,1.0)";
    }
  };

  const update = async () => {
    let getData = await CT0307(dispatch, classId);
    if (getData && Object.keys(getData).indexOf("body") >= 0) {
      const S3: any = await createS3();
      let list = getData.body;
      let newList = props.imgItems;
      let newFileData: any = {};
      if (list.length > 0) {
        for (let index = newList.length; index < list.length; index++) {
          const element = list[index];
          let file =
            element.s3_id && element.s3_id !== ""
              ? await getUrlS3(S3, element.s3_id)
              : null;
          newFileData[element.s3_id] = file;
          element.file_url = file;
          newList.push(element);
        }
      }
      orderList = [];
      for (let index = 0; index < newList.length; index++) {
        orderList.push(newList[index].board_document_id);
      }
      setImgList(newList);
      props.setImgItems(newList);
      props.setUploadFlg(false);
    }
  };

  useLayoutEffect(() => {
    ST0326().then((urlInfo: any) => {
      let imgItems = props.imgItems;
      if (urlInfo && urlInfo.state === "0") {
        var info: any = urlInfo.body;
        let url =
          "?Policy=" +
          info["Policy"] +
          "&Signature=" +
          info["Signature"] +
          "&Key-Pair-Id=" +
          info["Key-Pair-Id"];
        console.log("url", url);
        imgItems = [];
        for (let index = 0; index < props.imgItems.length; index++) {
          let img = props.imgItems[index];
          let d = String(img.file_url).split("?");
          img.file_url = d[0] + url;
          imgItems.push(img);
        }
      }
      if (props.selectImgId) setSelectImgId(props.selectImgId);
      setImgList(imgItems);
      // ref = React.createRef<HTMLDivElement>();
      orderList = [];
      for (let index = 0; index < props.imgItems.length; index++) {
        orderList.push(props.imgItems[index].board_document_id);
      }
    });
    //scrollToBottomOfList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.imgItems]);

  useEffect(() => {
    if (props.uploadFlg === true) {
      update();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.uploadFlg]);
  // useEffect(() => {
  //   setPop(true);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // useEffect(() => {
  //   setTimeout(() => {
  //     if (ref && ref.current) {
  //       ref.current.scrollIntoView();
  //     }
  //   }, 300);
  // }, [ref]);

  return (
    <div className={classes.region}>
      <div style={{ height: "auto", textAlign: "center" }}>
        <LightTooltip
          title={FormatMessage({ id: "Clslist6.Button.Left" })}
          placement="top"
        >
          <IconButton onClick={backArrowClick}>
            <ArrowBackIosIcon fontSize="large" />
          </IconButton>
        </LightTooltip>
        <LightTooltip
          title={FormatMessage({ id: "Clslist6.Button.Delete" })}
          placement="top"
        >
          <IconButton onClick={deleteImg}>
            <DeleteForeverIcon fontSize="large" />
          </IconButton>
        </LightTooltip>
        <LightTooltip
          title={FormatMessage({ id: "Clslist6.Button.Add" })}
          placement="top"
        >
          <IconButton
            onClick={() => {
              props.setUploading(false);
              props.setUploadFile(null);
              props.setNoChooseFileFlg(true);
              props.setUploadFileUrl("");
              props.setChooseFileName("");
              props.setErrorMsgImgUp("");
              props.setImgUploadDialogOpen(true);
            }}
          >
            <AddToPhotosIcon fontSize="large" />
          </IconButton>
        </LightTooltip>
        <LightTooltip
          title={FormatMessage({ id: "Clslist6.Button.Right" })}
          placement="top"
        >
          <IconButton onClick={forwardArrowClick}>
            <ArrowForwardIosIcon fontSize="large" />
          </IconButton>
        </LightTooltip>
      </div>

      <div id="imgBox" className={classes.item}>
        {imgList.map((img: any, index: number) => (
          <div
            key={"show_img_" + index}
            id={
              Number(selectImgId) === img.board_document_id
                ? "selected"
                : "list_" + index
            }
            style={{
              backgroundColor:
                Number(selectImgId) === img.board_document_id ? "red" : "white",
              padding: 1,
            }}
          >
            <Card className={classes.root}>
              <ButtonBase
                onClick={() => {
                  clickImg(img);
                }}
              >
                <div onMouseOver={chengeScale} onMouseOut={scaleReset}>
                  <img
                    className={classes.cover}
                    src={img.file_url}
                    alt=""
                    id={"img" + img.board_document_id}
                  />
                </div>
                <div className={classes.details}>
                  <CardContent className={classes.content}>
                    <Typography component="h6" variant="h6">
                      {img.board_document_name}
                    </Typography>
                  </CardContent>
                </div>
              </ButtonBase>
            </Card>
          </div>
        ))}
      </div>
    </div>
  );
}
