import axios from "axios";
import { checkData } from "./api/checkData";
import { addNotification } from "../reducks/notificationSlice";
import { store } from "../reducks/store";
import { getMessagesId } from "./getMessagesId";
import { operations } from "./operations";
import sanitize from "sanitize-html";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  decompress: false,
  withCredentials: true,
  timeout: 15000,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json;charset=utf-8",
  },
});

export const apigw = async (url: string, param: any, dispatch: any) => {
  for (let i = 0; i < param.length; i++) {
    param[i] = sanitize(param[i]);
  }
  const language = store.getState().user.language;
  let session_id = localStorage.getItem("session_id");
  if (session_id) param["session_id"] = sanitize(session_id);
  param["api_id"] = sanitize(url.slice(1)); //識別ID
  let storeInfo: any = store.getState();
  if (storeInfo) param["screen_id"] = sanitize(storeInfo.router.location.pathname); //画面ID
  var userTypeFlg = localStorage.getItem("userTypeFlg")
    ? localStorage.getItem("userTypeFlg")
    : "2";
  param["system_class"] = userTypeFlg === "1" ? "3" : "4"; //システム区分
  let operation = operations[url.slice(1)];
  param["operations"] = operation ? sanitize(operation) : "operation not find"; //操作内容
  return await instance
    .post(url, param)
    //通信成功時
    .then((response) => {
      // 200リクエストが成功
      if (response.status === 200) {
        if (typeof response.data === "string") {
          const zlib = require("zlib");
          const Buffer = require("buffer").Buffer;
          const buffer = Buffer.from(response.data, "base64");
          const str = zlib.unzipSync(buffer);
          response.data = JSON.parse(str);
        }
        const errcord = checkData(response.data, param["api_id"]);
        if (errcord === true) {
          return response.data;
        } else {
          return errcord;
        }
      } else {
        //エラーメッセージ表示
        let path = store.getState().router.location.pathname;
        if (path !== "/cls/008" && path !== "/cls/012" && path !== "/cls/013") {
          store.dispatch(
            addNotification({
              message: getMessagesId("Error.Message.PostErr", language),
              variant: "error",
            })
          );
        }
        if(response.config && response.config.url){
          if (
            response.config.url === "/AU0314" ||
            response.config.url === "/AU0313"
          ) {
            return -1;
          }
        }
        return null;
      }
    })
    // catchでエラー時
    .catch((err) => {
      console.log("err:", err);
      //エラーメッセージ表示
      let path = store.getState().router.location.pathname;
      if (path !== "/cls/008" && path !== "/cls/012" && path !== "/cls/013") {
        store.dispatch(
          addNotification({
            message: getMessagesId("Error.Message.PostErr", language),
            variant: "error",
          })
        );
      }
      if(err.config && err.config.url){
        if (err.config.url === "/AU0314" || err.config.url === "/AU0313") {
          return -1;
        }
      }
      return null;
    });
};
