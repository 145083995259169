import { apigw } from "../awsApi";
import { Dispatch } from "@reduxjs/toolkit";


//サンプルデータ
const state = 0;

/**
 * カスタムアンケート更新
 * @param {Dispatch} dispatch               reducerディスパッチ
 * @param {string} classId                  授業ID
 * @param {string} pageAfterClass           授業後ページ区分
 * @param {string} pageAfterUrl             授業後ページＵＲＬ
 * @param {string} lectureUpdateDate        授業マスタ更新日時
 * @param {string} beforeAnswerMsg          回答前メッセージ
 * @param {string} answerMsg                回答後メッセージ
 * @param {string} customEnqueteUpdateDate  カスタムアンケート更新日時
 * @return コンポーネントHTMLコード内容
 */

export const OQ0309 = async (
  dispatch: Dispatch,
  class_id: string,
  page_after_class: string,
  page_after_url: string,
  lecture_update_date: string,
  before_answer_msg: string,
  after_answer_msg: string,
  custom_enquete_update_date: string
  ) => {
    if (process.env.REACT_APP_API_USE_FLG === "true") {
      return apigw(
        "/OQ0309",
        {
          class_id,
          page_after_class,
          page_after_url,
          lecture_update_date,
          before_answer_msg,
          after_answer_msg,
          custom_enquete_update_date
        },
        dispatch
      );
    } else {
      //サンプルデータ用
      return {state};
    }
  };