// import "../../css/cls/webvtt_chrome.css";
import React, { useEffect } from "react";
export const VideoVtt = (props: any) => {
  const { existingUseClass, video2, vttSrc } = props;
  useEffect(() => {
    var styleTag = document.createElement("style");
    var userAgent = window.navigator.userAgent.toLowerCase();
    var PCflg = true;
    if (
      userAgent.indexOf("iphone") > 0 ||
      userAgent.indexOf("android") > 0 ||
      userAgent.indexOf("mobile") > 0 ||
      userAgent.indexOf("ipad") > 0 ||
      (userAgent.indexOf("macintosh") > 0 && "ontouchend" in document)
    ) {
      PCflg = false;
    }
    if (userAgent.indexOf("android") > 0 || userAgent.indexOf("windows") > 0) {
      //window chrome
      styleTag.innerText =
        "video::cue{color :white;font-size:.9em;background-color:rgb(0 0 0 / 50%);}video::cue(.background-black){background-color:rgb(0 0 0 / 0%);}";
    } else {
      if (
        userAgent.indexOf("safari") !== -1 &&
        userAgent.indexOf("chrome") === -1 &&
        userAgent.indexOf("edge") === -1
      ) {
        //mac safari
        if (PCflg) {
          styleTag.innerText =
            "video::cue{color :white;font-size:0.9em;background-color:rgb(0 0 0 /50%);}video::cue(.background-black){background-color:rgb(0 0 0 /50%);}";
        } else {
          styleTag.innerText =
            "video::cue{color :white;font-size:0.74em;}video::cue(.background-black){height:1.5em;background-color:rgb(0 0 0 /50%);}";
        }
      } else {
        //mac chrome
        styleTag.innerText =
          "video::cue(.white){color :white;font-size:0.8em;}video::cue(.background-black){background-color:rgb(0 0 0/50%);}";
      }
    }

    document
      .getElementsByTagName("head")[0]
      .insertAdjacentElement("beforeend", styleTag);
  }, []);
  return (
    <video
      id="video2"
      playsInline={true}
      muted={existingUseClass === "2" ? false : true}
      src={video2}
      width="100%"
      height="100%"
      crossOrigin="anonymous"
    >
      {vttSrc && vttSrc !== "" ? <track src={vttSrc} default></track> : null}
    </video>
  );
};
