import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { LoadingBox } from "../../components/atoms/LoadingBox";
import { CH0301 } from "../../utils/api/CH0301";
import { FormatMessage } from "../../components/atoms/FormatMessage";
import { PaginationBox } from "../../components/atoms/PaginationBox";

let key: any;
let chatData: any[];
let concatData: any[];
let getDateValue: any;
let pageNo: number = 1;
let itemCount = 0;
const pageMaxCount = 100;
let totalCount = 0;
let pageCount = 0;
/**
 * チャット履歴
 * @param {object} props 引き継いだオブジェクト
 * @return コンポーネントHTMLコード内容
 */
export const Sts003 = (props: any) => {
  const dispatch = useDispatch();

  type rowType = {
    chat_send_log_id: string;
    elapsed_time: number;
    sender_user_class: string;
    sender_user_class_value: string;
    sender_user_id: string;
    sender_streaming_user_name: string;
    sender_attendance_user_name: string;
    handle_name: string;
    destination_user_class: string;
    destination_user_class_value: string;
    destination_class: string;
    destination_class_value: string;
    destination: string;
    destination_streaming_user_name: string;
    destination_attendance_user_name: string;
    log_class: string;
    log_class_value: string;
    chat_detail: string;
    problem_answer_detail: string;
    simple_reaction_detail: string;
  };

  // チャット履歴データ
  const [rows, setRows] = React.useState<rowType[]>([]);
  const [loading, setLoading] = React.useState(false);

  const setData = async (data: any) => {
    let dif = 0;
    if (data.length > 0) {
      for (let index = 0; index < data.length; index++) {
        if (
          dif === 0 &&
          Number(data[index].simple_reaction_detail) > 0 &&
          Number(data[index].elapsed_time) >
            Number(data[index].simple_reaction_detail)
        )
          dif =
            Number(data[index].elapsed_time) -
            Number(data[index].simple_reaction_detail);
        data[index].elapsed_time = data[index].elapsed_time - dif;
      }
    }
    return data;
  };
  // 検索処理
  const getListData = async (callback: any) => {
    key = [];
    chatData = [];
    concatData = [];
    getDateValue = null;
    let sessionDateValue = localStorage.getItem("Sts003_session") as string;
    getDateValue = props.classId === "" ? JSON.parse(sessionDateValue) : props;
    if (props.classId !== "") {
      localStorage.setItem("Sts003_session", JSON.stringify(props));
    }

    let getData = await CH0301(dispatch, getDateValue?.classId);
    if (getData && Object.keys(getData).indexOf("body") >= 0) {
      concatData = getData.body.chat_results;
      totalCount = concatData.length;
      console.log("concatData", concatData);
      console.log("last_evaluated_keys", getData.body.last_evaluated_keys);
      if (concatData.length > 0) {
        if (getData.body.last_evaluated_keys.length > 0) {
          key = getData.body.last_evaluated_keys;
          await getAllChat();
        } else {
          pageCount = Math.ceil(concatData.length / 100);
          concatData.sort(function (a: any, b: any) {
            if (a.chat_send_time > b.chat_send_time) return 1;
            if (a.chat_send_time < b.chat_send_time) return -1;
            return 0;
          });
          concatData = await setData(concatData);
          let pegedatastart = 0;
          let pegedataend = 100;
          for (let i = 0; i < pageCount; i++) {
            chatData.push(concatData.slice(pegedatastart, pegedataend));
            pegedatastart = pegedataend;
            pegedataend = pegedataend + 100;
          }
          if (chatData && chatData[0] && chatData[0].length > 0) {
            itemCount = chatData[0].length;
            setRows(chatData[0]);
            pageNo = 1;
          }
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }

    if (callback) callback();
  };

  const listChange = () => {
    const index = pageNo - 1;
    itemCount = chatData[index].length;
    setRows(chatData[index]);
  };

  const getAllChat = async () => {
    let getData = await CH0301(dispatch, getDateValue?.classId, key);
    if (getData && Object.keys(getData).indexOf("body") >= 0) {
      let concatData2 = concatData;
      concatData = concatData2.concat(getData.body.chat_results);
      totalCount = concatData.length;
      key = getData.body.last_evaluated_keys;
      if (getData.body.last_evaluated_keys.length !== 0) {
        getAllChat();
      } else {
        pageCount = Math.ceil(concatData.length / 100);
        concatData.sort(function (a: any, b: any) {
          if (a.chat_send_time > b.chat_send_time) return 1;
          if (a.chat_send_time < b.chat_send_time) return -1;
          return 0;
        });
        concatData = await setData(concatData);
        let pegedatastart = 0;
        let pegedataend = 100;
        for (let i = 0; i < pageCount; i++) {
          chatData.push(concatData.slice(pegedatastart, pegedataend));
          pegedatastart = pegedataend;
          pegedataend = pegedataend + 100;
        }
        console.log("chatData", chatData);
        if (chatData && chatData[0] && chatData[0].length > 0) {
          itemCount = chatData[0].length;
          setRows(chatData[0]);
          pageNo = 1;
        }
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  // 秒から分に変換
  const changeSecondsToMinute = (seconds: number) => {
    if (seconds < 0) seconds = 0;
    var timeH = Math.floor(seconds / (60 * 60));
    var timeM = Math.floor((seconds % (60 * 60)) / 60);
    var timeS = (seconds % (60 * 60)) % 60;
    return (
      ("00" + timeH).slice(-2) +
      ":" +
      ("00" + timeM).slice(-2) +
      ":" +
      ("00" + timeS).slice(-2)
    );
  };

  //初期処理
  useEffect(() => {
    setLoading(true);
    getListData(null);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  return (
    <React.Fragment>
      <Table size="small" className="sticky_table">
        <TableHead>
          <TableRow>
            <TableCell rowSpan={2} width={110} align="center">
              {FormatMessage({ id: "Sts003.Label.text1" })}
            </TableCell>
            <TableCell colSpan={4} width={300} align="left">
              {FormatMessage({ id: "Sts003.Label.text2" })}
            </TableCell>
            <TableCell colSpan={3} width={200} align="left">
              {FormatMessage({ id: "Sts003.Label.text3" })}
            </TableCell>
            <TableCell rowSpan={2} width={110} align="center">
              {FormatMessage({ id: "Sts003.Label.text4" })}
            </TableCell>
            <TableCell rowSpan={2} width={110} align="center">
              {FormatMessage({ id: "Sts003.Label.text5" })}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell width={110} align="center">
              {FormatMessage({ id: "Sts003.Label.text6" })}
            </TableCell>
            <TableCell width={110} align="center">
              {FormatMessage({ id: "Sts003.Label.text7" })}
            </TableCell>
            <TableCell width={110} align="center">
              {FormatMessage({ id: "Sts003.Label.text8" })}
            </TableCell>
            <TableCell width={110} align="center">
              {FormatMessage({ id: "Sts003.Label.text9" })}
            </TableCell>
            <TableCell width={110} align="center">
              {FormatMessage({ id: "Sts003.Label.text10" })}
            </TableCell>
            <TableCell width={110} align="center">
              {FormatMessage({ id: "Sts003.Label.text11" })}
            </TableCell>
            <TableCell width={110} align="center">
              {FormatMessage({ id: "Sts003.Label.text12" })}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row) => (
            <TableRow key={row.chat_send_log_id}>
              <TableCell align="center">
                {/* 位置 */}
                {changeSecondsToMinute(row.elapsed_time)}
              </TableCell>
              <TableCell align="center">
                {/* 送信者区分 */}
                {row.sender_user_class_value}
              </TableCell>
              <TableCell align="center">
                {/* 送信者ID */}
                {row.sender_user_id}
              </TableCell>
              <TableCell align="center">
                {/* 送信者名前 */}
                {row.sender_user_class === "1"
                  ? row.sender_streaming_user_name !== "null"
                    ? row.sender_streaming_user_name
                    : null
                  : row.sender_attendance_user_name !== "null"
                  ? row.sender_attendance_user_name
                  : null}
              </TableCell>
              <TableCell align="center">
                {/* 送信者ハンドルネーム */}
                {row.handle_name !== "null" ? row.handle_name : null}
              </TableCell>
              <TableCell align="center">
                {/* 受信者区分 */}
                {row.destination_class === "1" //宛先チェック
                  ? row.destination_class_value
                  : row.destination_user_class === ""
                  ? null
                  : row.destination_user_class_value}
              </TableCell>
              <TableCell align="center">
                {/* 受信者ID */}
                {row.destination_class === "2" ? row.destination : ""}
              </TableCell>
              <TableCell align="center">
                {/* 受信者名前 */}
                {row.destination_user_class === "1"
                  ? row.destination_streaming_user_name !== "null"
                    ? row.destination_streaming_user_name
                    : null
                  : row.destination_attendance_user_name !== "null"
                  ? row.destination_attendance_user_name
                  : null}
              </TableCell>
              <TableCell align="center">
                {/* タイプ */}
                {row.log_class_value}
              </TableCell>
              <TableCell align="left">
                {/* 内容 */}
                {row.log_class === "1"
                  ? row.chat_detail
                  : row.problem_answer_detail}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {rows.length > 0 ? (
        <PaginationBox
          pageNo={pageNo}
          itemCount={itemCount}
          diaplayCount={pageMaxCount}
          totalCount={totalCount}
          pageCount={pageCount}
          onChange={(e: any, page: any) => {
            let newPageNo = Number(page);
            if (pageNo !== newPageNo) {
              pageNo = newPageNo;
              listChange();
            }
          }}
        />
      ) : null}
      {loading ? <LoadingBox /> : null}
    </React.Fragment>
  );
};
