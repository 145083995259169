import React, { useEffect } from "react";
import clsx from "clsx";
import {
  makeStyles,
  Theme,
  withStyles,
  createStyles,
} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Container from "@material-ui/core/Container";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Popover from "@material-ui/core/Popover";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { FormatMessage } from "../components/atoms/FormatMessage";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Menu } from "./Menu";
import { Copyright } from "./Copyright";
import { useSelector } from "react-redux";
import { RootState, store } from "../reducks/store";
import { getSessionUser } from "../utils/authFlow";
import { setUser, signOut } from "../reducks/userSlice";
import sanitize from "sanitize-html";
import { unescapeStr } from "../components/function/Function";
import { SearchDialog } from "./SearchDialog";
import SearchOutlined from "@material-ui/icons/SearchOutlined";

import { setCloseSwitchKeywordSearchDialog } from "../reducks/storedKeywordSeachConditionSlice";
import Badge from "@material-ui/core/Badge";
import { sessionHandlerForKeywordSearch } from "../utils/sessionHandlerForKeywordSearch";

const drawerWidth = 260;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    // paddingBottom: theme.spacing(4),
    paddingBottom: "84px",
  },
  paper: {
    padding: theme.spacing(2),
    margin: "20px 0",
    display: "flex",
    overflow: "hiddle",
    flexDirection: "column",
    width: "100%",
    borderBottom: "1px solid #ccc",
  },
  itemList: {
    margin: "20px 0",
    width: "100%",
    borderBottom: "1px solid #ccc",
  },
  fixedHeight: {
    height: 240,
  },
  image: {
    width: 250,
    height: 170,
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  alignCenter: {
    textAlign: "center",
  },
  alignRight: {
    textAlign: "end",
  },
  mT20: {
    marginTop: "20px",
  },
  button: {
    margin: `${theme.spacing(1)}px auto`,
  },
  button2: {
    margin: `20px auto`,
  },
  button3: {
    margin: `0 10px`,
  },
}));

type MainLayoutProps = {
  children: React.ReactNode;
};

const StyledBadge = withStyles((theme: Theme) =>
  createStyles({
    badge: {
      right: "25%",
      backgroundColor: "#f44336",
      color: "#f44336",
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "$ripple 1.2s infinite ease-in-out",
        border: "1px solid currentColor",
        content: '""',
      },
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  })
)(Badge);

export const MainLayout: React.FC<MainLayoutProps> = ({
  children,
}: MainLayoutProps) => {
  const user = useSelector((state: RootState) => state.user);
  const classes = useStyles();
  const menuOpenFlg = localStorage.getItem("menuOpenFlg");
  const [open, setOpen] = React.useState(
    menuOpenFlg && menuOpenFlg === "false" ? false : true
  );
  const handleDrawerOpen = () => {
    localStorage.setItem("menuOpenFlg", "true");
    setOpen(true);
  };
  const handleDrawerClose = () => {
    localStorage.setItem("menuOpenFlg", "false");
    setOpen(false);
  };

  const [anchorEl, setAnchorEl] =
    React.useState<HTMLButtonElement | null>(null);

  const menuHandleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const menuHandleClose = () => {
    setAnchorEl(null);
  };

  const dispatch = useDispatch();
  const onClickLink = (url: string) => {
    if (url && url.length > 0) {
      dispatch(push(sanitize(url)));
    }
  };

  const openMenu = Boolean(anchorEl);
  const menuId = "menu-popover";

  const userTypeFlg = localStorage.getItem("userTypeFlg")
    ? localStorage.getItem("userTypeFlg")
    : "2";
  const authCheck = async () => {
    const session = await getSessionUser();
    if (session) {
      dispatch(setUser(session));
    } else {
      dispatch(signOut());
    }
  };

  const [logo, setLogo] = React.useState("");
  const getLogo = async () => {
    const user = store.getState().user;
    if (user && user.service_id) {
      setLogo("/logo/" + user.service_id + "/logo.jpg");
    }
  };

  const [SearchDiglogFlg, setSearchDiglogFlg] = React.useState(false);
  const SearchDiglogShow = async () => {
    await setSearchDiglogFlg(true);
  };
  const SearchDiglogClose = async () => {
    await setSearchDiglogFlg(false);
    dispatch(setCloseSwitchKeywordSearchDialog({}));
  };

  const getPathname = (): string => {
    const _storeInfo: any = store.getState();
    return sanitize(_storeInfo.router.location.pathname);
  };

  const nameChange = (str: string) => {
    let len = 0;
    let name = "";

    for (let i = 0; i < str.length; i++) {
      str[i].match(/[ -~]/) ? (len += 1) : (len += 2);
      if (len > 12) {
        name += "...";
        break;
      } else {
        name += str[i];
      }
    }
    return name;
  };

  // ブラウザがsafariかどうかのフラグ
  const [isSafari, setIsSafari] = React.useState(false);
  useEffect(() => {
    // 使用ブラウザの判定
    const userAgent = window.navigator.userAgent.toLowerCase();
    // Safari以外
    if (
      userAgent.indexOf("edge") !== -1 ||
      userAgent.indexOf("chrome") !== -1
    ) {
      setIsSafari(false);
      // Safari
    } else if (userAgent.indexOf("safari") !== -1) {
      setIsSafari(true);
    }
    authCheck();
    getLogo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <Box flexGrow={1}>
            <img src={logo} height="24" alt="ロゴ" />
          </Box>
          <Box>
            {sessionHandlerForKeywordSearch.isSetCondition() === true ? (
              getPathname() === "/top/002" && user.user_class === "4" ? (
                <StyledBadge
                  color="secondary"
                  overlap="circle"
                  badgeContent=" "
                  variant="dot"
                >
                  <Button color="inherit" onClick={SearchDiglogShow}>
                    <SearchOutlined />
                  </Button>
                </StyledBadge>
              ) : null
            ) : getPathname() === "/top/002" && user.user_class === "4" ? (
              <Button color="inherit" onClick={SearchDiglogShow}>
                <SearchOutlined />
              </Button>
            ) : null}
            <SearchDialog
              openClose={SearchDiglogFlg}
              closeClick={SearchDiglogClose}
              isSafari={isSafari}
            />
          </Box>
          <Box>
            <Button color="inherit" onClick={menuHandleClick}>
              <div className="userName-text-transform-none">
                {unescapeStr(nameChange(user.user_name))}
              </div>
            </Button>
          </Box>
          <Popover
            id={menuId}
            open={openMenu}
            anchorEl={anchorEl}
            onClose={menuHandleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <List component="nav" aria-label="secondary mailbox folder">
              <ListItem
                button
                selected={false}
                onClick={() =>
                  onClickLink(userTypeFlg === "1" ? "/set/001" : "/set/005")
                }
              >
                <ListItemIcon style={{ fontSize: 24 + "px" }}>
                  <AccountCircleIcon />
                </ListItemIcon>
                <ListItemText
                  primary={FormatMessage({ id: "Menu.CountInfo" })}
                />
              </ListItem>
              <ListItem
                button
                selected={false}
                onClick={() => dispatch(signOut())}
              >
                <ListItemIcon style={{ fontSize: 24 + "px" }}>
                  <FontAwesomeIcon icon={faSignOutAlt} />
                </ListItemIcon>
                <ListItemText primary={FormatMessage({ id: "Menu.Logout" })} />
              </ListItem>
            </List>
          </Popover>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <Menu />
        <Divider />
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} style={{ minHeight: "48px" }} />

        <Container maxWidth="lg" className={classes.container}>
          <div className="mainContent">{children}</div>
          <Copyright />
        </Container>
      </main>
    </div>
  );
};
