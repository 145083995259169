/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { TextFieldBox } from "../../components/atoms/TextFieldBox";
import Checkbox from "@material-ui/core/Checkbox";
import SearchIcon from "@material-ui/icons/Search";

import { FormatMessage } from "../../components/atoms/FormatMessage";
import { MainLayout } from "../../layouts/MainLayout";
import { TableDate } from "./018_1";
// import { getSessionUser } from "../../utils/authFlow";
// import { TG0301 } from "../../utils/api/TG0301";
// import { LC0301 } from "../../utils/api/LC0301";
import { LC0306 } from "../../utils/api/LC0306";
import { LoadingBox } from "../../components/atoms/LoadingBox";
import { PaginationBox } from "../../components/atoms/PaginationBox";

// ページング処理を保持している箇所
let s1: any = {
  pageNo: 1,
  itemCount: 0,
  totalCount: 0,
  pageCount: 0,
};

/**
 * 受講記録一覧
 * @param {object} props 引き継いだオブジェクト
 * @return コンポーネントHTMLコード内容
 */
export const Cls018 = (props: any) => {
  const dispatch = useDispatch();

  // 【変数・定数宣言】
  const [className, setClassName] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  // チェックボックスのフラグ
  const [isAttendance, setIsAttendance] = React.useState(true);
  const [isAbsence, setIsAbsence] = React.useState(true);
  const [isWatched, setIsWatched] = React.useState(true);
  const [isPartWatched, setIsPartWatched] = React.useState(true);
  const [isNotWatched, setIsNotWatched] = React.useState(true);

  const [totalCount, setTotalCount] = React.useState(-1);

  type itemType = {
    class_name: string; //	授業名
    class_time: number; //	授業時間
    viewing_time: number; //	生配信　視聴時間
    viewing_datetime: string; //	生配信　視聴日時
    viewing_time_ondemand: number; //	録画版　視聴時間
    viewing_datetime_ondemand: string; //	録画版 視聴日時
  };
  const [lessonRows, setLessonRows] = React.useState<itemType[]>([]);

  // 【処理】
  const getListData = async (callback: any) => {
    setLessonRows([])
    let getData = await LC0306(
      dispatch,
      className,
      isAttendance,
      isAbsence,
      isWatched,
      isPartWatched,
      isNotWatched,
      s1.pageNo
    );
    if (getData && getData.state === "0") {
      if (getData.body) {
        if (getData.body.result.length > 0) {
          for (let index = 0; index < getData.body.result.length; index++) {
            getData.body.result[index].viewing_datetime = ConvertJST(
              getData.body.result[index].viewing_datetime
            );
            getData.body.result[index].viewing_datetime_ondemand = ConvertJST(
              getData.body.result[index].viewing_datetime_ondemand
            );
            getData.body.result[index].viewing_time =
              getData.body.result[index].viewing_time ?
                getData.body.result[index].viewing_time : 0;
            getData.body.result[index].viewing_time_ondemand =
              getData.body.result[index].viewing_time_ondemand ?
                getData.body.result[index].viewing_time_ondemand : 0;
          }
          setLessonRows(getData.body.result);
        }
        s1.itemCount = getData.body.result.length;
        s1.totalCount = getData.body.total_count;
        s1.pageCount = getData.body.total_page;

        if (Object.keys(getData.body).indexOf("total_count") >= 0) {
          setTotalCount(getData.body.total_count);
        }
      } else {
        setLessonRows([]);
      }
    }
    if (callback) callback();
  };

  //初期処理
  useEffect(() => {
    //データ取得
    setLoading(true);
    getListData(() => setLoading(false));
  }, []);

  // 【イベント】
  // 検索ボタン
  const searchCilck = () => {
    s1.pageNo = 1;
    setLoading(true);
    getListData(() => setLoading(false));
  };

  // ページング処理
  const hundlePagination = (e: React.ChangeEvent, page: number) => {
    setLoading(true);
    s1.pageNo = page;
    getListData(null);
    setLoading(false);
  };

  const ConvertJST = (timeData: any) => {
    if (timeData && timeData !== null) {
      let time = new Date(timeData);
      time.setHours(time.getHours() - 9);
      return (
        time.getFullYear() +
        "/" +
        ("00" + (time.getMonth() + 1)).slice(-2) +
        "/" +
        ("00" + time.getDate()).slice(-2) +
        " " +
        ("00" + time.getHours()).slice(-2) +
        ":" +
        ("00" + time.getMinutes()).slice(-2)
      );
    } else {
      return null;
    }
  };

  return (
    <MainLayout>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className="App-paper">
            <Typography
              component="h2"
              variant="h6"
              color="initial"
              gutterBottom
            >
              {FormatMessage({ id: "Cls018.Title" })}
            </Typography>
            <Grid style={{
              padding: "10px",
              width: "100 %",
              margin: "10px 0 0 0"
            }}>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={10} sm={2} >
                  {FormatMessage({ id: "Cls018.Label.text1" })}
                </Grid>
                <Grid xs={10} sm={8} className="item-padding">
                  <TextFieldBox
                    className="App-input-text"
                    id="outlined-basic"
                    value={className}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setClassName(event.target.value);
                    }}
                    variant="outlined"
                    fullWidth
                    inputProps={{ maxLength: 256 }}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={1} alignItems="center">
                <Grid item xs={10} sm={2} >
                  {FormatMessage({ id: "Cls018.Label.text2" })}
                </Grid>
                <Grid xs={10} sm={8} className="item-padding">
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="checkedC"
                        color="default"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setIsAttendance(e.target.checked);
                        }}
                        checked={isAttendance}
                      />
                    }
                    label={FormatMessage({ id: "Cls018.Label.text3" })}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="checkedC"
                        color="default"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setIsAbsence(e.target.checked);
                        }}
                        checked={isAbsence}
                      />
                    }
                    label={FormatMessage({ id: "Cls018.Label.text4" })}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={10} sm={2} >
                  {FormatMessage({ id: "Cls018.Label.text5" })}
                </Grid>
                <Grid xs={10} sm={8} className="item-padding">
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="checkedC"
                        color="default"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setIsWatched(e.target.checked);
                        }}
                        checked={isWatched}
                      />
                    }
                    label={FormatMessage({ id: "Cls018.Label.text6" })}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="checkedC"
                        color="default"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setIsPartWatched(e.target.checked);
                        }}
                        checked={isPartWatched}
                      />
                    }
                    label={FormatMessage({ id: "Cls018.Label.text7" })}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="checkedC"
                        color="default"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setIsNotWatched(e.target.checked);
                        }}
                        checked={isNotWatched}
                      />
                    }
                    label={FormatMessage({ id: "Cls018.Label.text8" })}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="center"
                style={{ border: "none" }}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<SearchIcon />}
                  onClick={searchCilck}
                >
                  {FormatMessage({ id: "Button.Search" })}
                </Button>
              </Grid>
            </Grid>

            <Grid className="App-mT10">
              <Grid item xs={12}>
                {totalCount !== 0 ? (
                  totalCount === -1 ? (
                    <span className="App-mR10">
                      {FormatMessage({
                        id: "Sts001.Label.SearchResult",
                        option: { count: "0" },
                      })}
                    </span>
                  ) : (
                    <span className="App-mR10">
                      {FormatMessage({
                        id: "Sts001.Label.SearchResult",
                        option: { count: String(totalCount) },
                      })}
                    </span>
                  )
                ) : (
                  <span>
                    <Typography
                      component="h1"
                      variant="h6"
                      color="error"
                      gutterBottom
                    >
                      {FormatMessage({ id: "Sts001.Search.noFind" })}
                    </Typography>
                    <span className="App-mR10">
                      {FormatMessage({
                        id: "Sts001.Label.SearchResult",
                        option: { count: String(totalCount) },
                      })}
                    </span>
                  </span>
                )}
              </Grid>
            </Grid>
            <TableDate lesson_rows={lessonRows} />
            {lessonRows.length > 0 ? (
              <PaginationBox
                pageNo={s1.pageNo}
                itemCount={s1.itemCount}
                diaplayCount={100}
                totalCount={s1.totalCount}
                pageCount={s1.pageCount}
                onChange={(e: React.ChangeEvent, page: number) => {
                  hundlePagination(e, page);
                }}
              />
            ) : null}
          </Paper>
        </Grid>
      </Grid>
      {loading ? <LoadingBox /> : null}
    </MainLayout>
  );
};
