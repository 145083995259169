import React from "react";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import {InputBanChange} from "../function/Function";

/**
 * @type テキストボックスインターフェイス
 */
type TextAreaProps = {
  defaultValue?: string;
  value?: string;
  label?: string;
  rows?: number;
  placeholder?: string;
  id?: string;
  name?: string;
  className?: string;
  onChange?: (e: any) => void;
  onBlur?: (e: any) => void;
  style?: any;
  maxLength?: any;
};
/**
 * 共有テキストエリアボックス
 * @param {TextAreaProps} TextAreaProps
 * @return コンポーネントHTMLコード内容
 */
export const TextareaAutoSize: React.FC<TextAreaProps> = ({
  defaultValue = undefined,
  value = undefined,
  label = "",
  rows = 3,
  placeholder = "",
  id = "",
  name = "",
  className = "",
  onChange = () => {},
  onBlur = () => {},
  style="",
  maxLength="",
}: TextAreaProps) => {

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (onChange) onChange({target:{value:InputBanChange(event.target.value)}});
  };

  const handleBlur = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (onBlur) onBlur({target:{value:InputBanChange(event.target.value)}});
  };

  return (
    <TextareaAutosize
      id={id}
      name={name}
      defaultValue={defaultValue}
      value={value}
      aria-label={label}
      placeholder={placeholder}
      rows={rows}
      className={className}
      onChange={handleChange}
      onBlur={handleBlur}
      style={style}
      maxLength={maxLength}
    />
  );
};
