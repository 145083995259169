import { apigw } from "../awsApi";
import { Dispatch } from "@reduxjs/toolkit";

//サンプルデータ
const state = 0;

/**
 *　視聴ユーザパスワードポリシーチェック
 * @param {Dispatch} dispatch reducerディスパッチ
 * @param {string} mailAddress メールアドレス
 * @param {string} serviceId サービスID
 * @param {string} userClass ユーザ区分
 * @return コンポーネントHTMLコード内容
 */
export const AU0311 = async (
  dispatch: Dispatch,
  mailAddress: string,
  serviceId: string,
  userClass: string,
) => {
  if (process.env.REACT_APP_API_USE_FLG === "true") {
    return apigw(
      "/AU0311",
      {
        mail_address: mailAddress,
        service_id: serviceId,
        user_class: userClass,
      },
      dispatch
    );
  } else {
    //サンプルデータ用
    return {state};
  }
};
