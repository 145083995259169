/* eslint-disable @typescript-eslint/no-redeclare */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable no-loop-func */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { store } from "../../reducks/store";
import Button from "@material-ui/core/Button";
import Popper from "@material-ui/core/Popper";
// import LayersClearIcon from "@material-ui/icons/LayersClear";
import IconButton from "@material-ui/core/IconButton";
import ReplayIcon from "@material-ui/icons/Replay";
import { faMagic, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles, makeStyles, Theme } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { saveAs } from "file-saver";
import {
  createS3,
  uploadS3,
  getListS3,
  // getFileBase64S3,
  getUrlS3,
  getFileS3,
} from "../../components/function/AwsS3Fun";
import "../../css/cls/pen.css";
import "../../css/cls/cls008.css";
import Popover from "@material-ui/core/Popover";
import { PenSetting } from "./PenSetting";
import { ShapeSetting } from "./ShapeSetting";
import { signOut } from "../../reducks/userSlice";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { TextFieldBox } from "../../components/atoms/TextFieldBox";
//反応アイコン　スマイル
import SentimentSatisfiedRoundedIcon from "@material-ui/icons/SentimentSatisfiedRounded";
//反応アイコン　×
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import { HiSave } from "react-icons/hi";
import SendIcon from "@material-ui/icons/Send";
import {
  FaPencilAlt,
  FaShapes,
  FaRegHandPaper,
  FaEraser,
} from "react-icons/fa";
import educastLogo from "../../css/educast.jpg";

import { BiMenu } from "react-icons/bi";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import { FaVideoSlash } from "react-icons/fa";
import MicOff from "@material-ui/icons/MicOff";
// import { fabric } from "fabric";
//import fscreen from 'fscreen';
import { AiOutlineExclamationCircle } from "react-icons/ai";
import AgoraRTC from "agora-rtc-sdk-ng";
import AgoraRTM from "agora-rtm-sdk";
import useAgora from "../../utils/useAgora";
import MediaPlayer from "../../utils/MediaPlayer";
import { FormatMessage } from "../../components/atoms/FormatMessage";
import { getSessionUser } from "../../utils/authFlow";
import { US0304 } from "../../utils/api/US0304";
import { CT0307 } from "../../utils/api/CT0307";
// import { CT0308 } from "../../utils/api/CT0308";
import { LC0305 } from "../../utils/api/LC0305";
import { CH0305 } from "../../utils/api/CH0305";
import { ST0302 } from "../../utils/api/ST0302";
import { ST0310 } from "../../utils/api/ST0310";
import { ST0311 } from "../../utils/api/ST0311";
import { ST0318 } from "../../utils/api/ST0318";
import { ST0323 } from "../../utils/api/ST0323";
import { QZ0305 } from "../../utils/api/QZ0305";
// import { ST0324 } from "../../utils/api/ST0324";
import { ST0326 } from "../../utils/api/ST0326";
import actionComand from "./comand";
import Camera from "./camera";
// 絵文字データ
import { setData } from "./setData";
import { pdfjs } from "react-pdf";
import sanitize from "sanitize-html";
import { LoadingBox } from "../../components/atoms/LoadingBox";
import NoSleep from 'nosleep.js';
const fabricImp = require("fabric");
const fabric = fabricImp.fabric;
fabric.Object.prototype.objectCaching = false;
fabric.Object.prototype.noScaleCache = false;
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
if (process.env.REACT_APP_AGORA_RTC_SHOW_LOG === "false")
  AgoraRTC.setLogLevel(4);
const useStyles = makeStyles(() => ({
  root: {
    display: "block",
  },
  b50: {
    // float: "left",
    // margin: "10px 20px"
  },
  b60: {
    float: "left",
    margin: "10px 0 0 10px",
  },
  b70: {
    float: "right",
    margin: "10px 0 0 10px",
  },
  t50: {
    float: "left",
    margin: "10px 0 0 10px",
    color: "#fff",
  },
  headerIcon: {
    fontSize: "23px",
    color: "#3498db",
  },
  popBackClr: {
    backgroundColor: "#fff",
  },
  popoverContent: {
    pointerEvents: "auto",
  },
  audio_slash: {
    display: "none",
  },
  video_slash: {
    display: "none",
  },
}));

const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    border: "1px solid #ccc",
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

let socket: any;
let FabCanvas: any;
let GraphFabCanvas: any;
let canvas: any;
let joinList: any;
let getViewUserTimeout: any;
let chatsedFlg = false;
// let canvasImg: any;
let canvasConcat: any;
let canvasFabric: any;
let boradImgChangeFlg = false;
// let canvasGraph: any;
let drowCanvasDiv: any;
// let imgCanvasDiv: any;
let ctx: any;
// let ctxI: any;
let captureFlg: any;
let boradChangeFlg: any;
let ctxC: any;
let speedcount = 0;
let summegaBps = 0;
let clickEnter: boolean = false;
let mousePoint: any = { x: 0, y: 0, px: 0, py: 0 };
let drowing = false;
let retryCount = 0;
let penColor: string = "#000",
  penWidth: number = 3,
  defaultWidth: number = 1280;
let expantionData: any;
let canvasData: any;
let endAnswer: boolean = false;
let startTime: number = 0;
let uploadFlg = false;
let operation: any,
  shapeType: any,
  addShapeLine: any,
  addShapeCircle: any,
  addShapeRect: any,
  addShapeTriangle: any,
  origX: any,
  origY: any,
  lineList: any,
  brush: any,
  lineId: string,
  shapeId: string,
  lineNo: number,
  lineListData: any,
  lineData: any,
  lineOrder: any,
  handData: any,
  shapeList: any,
  graphShapeList: any,
  isBrush: boolean,
  delIdList: any;
let msgIndex: number = 0;
let afterClassType = "";
let voiceJoinFlg = true;
let joinButtonDisable = false;
let msgLength = 300;
// let imageData: any;
// let userData: any;
let lectureChannelId: any;
let streamIsAllDisplay: any;
let agoraUserId: any;
let userName: any;
let handleName: any;
let className: any;
let isVisibleDefaultEnquete: any;
let checkStreamer: any;
let firstJoin = false;
let browserType: any;
let selectBoardNo: any;
let RTMTimerID: any;
type streamer = {
  uid: string;
  streamerId: string;
  streamerName: string;
};
const streamerIni = {
  uid: "",
  streamerId: "",
  streamerName: "",
};
let ext = 0;
let streamerInfo: streamer = streamerIni;

const USER_STATUS = {
  JOIN: "JOIN",
  EXIT: "EXIT",
  CHAT: "CHAT",
  REACTION_OK: "REACTION_OK",
  REACTION_NG: "REACTION_NG",
  REACTION_HAND: "REACTION_HAND",
  CHOICE_1: "CHOICE1",
  CHOICE_2: "CHOICE2",
  CHOICE_3: "CHOICE3",
  CHOICE_4: "CHOICE4",
  CHOICE_5: "CHOICE5",
  CHOICE_6: "CHOICE6",
  CHOICE_7: "CHOICE7",
  CHOICE_8: "CHOICE8",
  CHOICE_9: "CHOICE9",
  CHOICE_10: "CHOICE10",
  NOACTION: "NOACTION",
  AGORAEXIT: "AGORAEXIT",
};

let Users: any;

let orderFileName = "order.json";
let recordInterval: any;
// let sessionInterval: any;
let reClassStartDate: any;
type orderType = {
  imgOrder: any;
  videoOrder: any;
  pdfOrder: any;
};
let LastChatReceiveTime = 0;
let orderList: orderType = {
  imgOrder: [],
  videoOrder: [],
  pdfOrder: [],
};

type imgType = {
  board_document_id: string;
  board_document_name: string;
  s3_id: string;
  file_data?: any;
};
let imageRows: imgType[];
let imageDatas: any;
// let videoRows: any = {};
// let videoId: String;
let agoraOption: any;
// let classVideo = "";
let handImg: any;
let screenShareTime: any;
let endLec: any;
let endFlg: any;
//let viewLogState: any;
let checkStartLecture: any;
const viewLogStatus = {
  ini: 0,
  wait: 1,
  inService: 2,
  outService: 3,
  restartIni: 4,
  restartWait: 5,
};
//let timeCount: any;
let videoTrack: any;
let micTrack: any;
// let chatBox: any;
// let scrollMoveFlg = true;
let testFlg: any;
let testSending: any;
let chattestFlg: any;
let lowStreamFlg: any;
let chattestSending: any;
let msgCount = 1;
// let sendReTime: any;
let sendReBack: any, sendReData: any;
let disabled: boolean;
let reactionList: any, reactionFlg: any, messageFlg: any;
let chatList: any = [];
let clientRTM: any;
let channelRTM: any;
let imgBackground: any;
let leftClass: any;
let maxHeightMessageDiv = 9600;
let leaveStreamerCnt: any;
let setActive: any;
let userListJson: any;
let incomingCommandList: any;
const OPERATION_TYPE = {
  SELECT: "select",
  PEN: "pen",
  CLEAR: "clear",
  SHAPE: "shape",
  HAND: "hand",
  VIDEO: "video",
};

const SHAPE_TYPE = {
  LINE: "line",
  CIRCLE: "circle",
  TRIANGLE: "triangle",
  SQUARE: "square",
};

let questionListsColor: any = {
  shape_id: "",
  answer_1: "#78e00b",
  answer_2: "#26d4e0",
  answer_3: "#e0b501",
  answer_4: "#c504e0",
  answer_5: "#e0610b",
  answer_6: "#ace072",
  answer_7: "#8ddbe0",
  answer_8: "#e0c868",
  answer_9: "#c45dd4",
  answer_10: "#e09f72",
};
// 択一問題グラフ間の隙間の割合
const GAP_PERCENTAGE_SELECT_QUESTIONS = 2;
let percentFlg: boolean = false;
let questCheckIntervalFlg: any;
// 反応集計グラフの情報
let drawReactionCountInfo: any = {
  shape_id: "",
  COLOR_REACTION_OK: "#005aff",
  COLOR_REACTION_NG: "#ff4b00",
  COLOR_REACTION_HAND: "#03af7a",
};
type answerOption = {
  answer_no: number;
  answer: string;
};
let userId: any;
let userNo: any;
let serviceId: any;

let quizOptions: answerOption[] = [];
let maxDivScrollTop: any;
let maxDivScrollLeft: any;
let noResize: any;

let imgLoadFlg: any;
let imgCmdId: any;
let imgCmdTime: any;
let boardCmdTime: any;
let alertFlg: any;
let alertCnt: any;

let nonStreamerFlg: any;
let classUserNum: any;
AgoraRTC.enableLogUpload();
const client = AgoraRTC.createClient({ codec: "vp8", mode: "live" });

let fileNameCnt: number;
let returnFileName: any;
let b1: any, b2: any, b3: any;
interface FsDocument extends HTMLDocument {
  webkitFullscreenElement: Element | undefined;
  mozFullScreenElement?: Element;
  msFullscreenElement?: Element;
  //msExitFullscreen?: () => void;
  //mozCancelFullScreen?: () => void;
}
let onWrittenFlg: any;
let nowImageData: any;
let zoom: any;
let deviceType: any;
let chatnotTestFlg: boolean = true;
let changeJsonSaveNo: any;
let makeLineFlg: any;
let addLineFlg: any;
let reloop: any;
let classId: any;
let classEndFlg: any;
let timeLag: any;
let joinStartTime: any;
export function isFullScreen(): boolean {
  const fsDoc = document as FsDocument;
  return !!(
    fsDoc.fullscreenElement ||
    fsDoc.mozFullScreenElement ||
    fsDoc.webkitFullscreenElement ||
    fsDoc.msFullscreenElement
  );
}
let msgList: any = [];
let fristWebSocketFlg: any;
var noSleep = new NoSleep();

export const Cls012 = (props: any) => {
  const dispatch = useDispatch();
  const sessionDateValue = localStorage.getItem("Cls012_session") as string;
  const getDateValue =
    props &&
    props.location &&
    props.location.state &&
    typeof props.location.state !== "undefined"
      ? props.location.state
      : JSON.parse(sessionDateValue);
  if (typeof props.location.state !== "undefined") {
    localStorage.setItem(
      "Cls012_session",
      JSON.stringify(props.location.state)
    );
  }

  // 授業ID

  classId = getDateValue?.class_id ? getDateValue?.class_id : "";
  const consoleLogOut = async (level: any, msg: any, flg?: any) => {
    if (!classId || classId === "") return;
    let saveData = [];
    if (flg) {
      saveData = msgList;
      msgList = [];
    } else {
      msg =
        "[" +
        new Date().toLocaleTimeString() +
        "][" +
        level +
        "]:" +
        JSON.stringify(msg);
      msgList.push(msg);

      if (msgList.length > 1000) {
        saveData = msgList;
        msgList = [];
      }
    }
    if (saveData.length > 0) {
      var jsontext = JSON.stringify(saveData);
      var blob = new Blob([jsontext], { type: "text/json" });
      var jsonFile = new File([blob], "message.json");
      const date = new Date().getTime();
      var S3: any = await createS3();
      await uploadS3(
        S3,
        "haishin/" +
          store.getState().user.service_id +
          "/" +
          classId +
          "/Log/console/" +
          store.getState().user.user_id +
          "/" +
          date +
          ".json",
        jsonFile
      );
    }
  };
  if (process.env.REACT_APP_AWS_S3_UPLOG === "true") {
    let console: any = {};
    console.error = (...args: any) => {
      consoleLogOut("ERROR", args);
    };
    console.warn = (...args: any) => {
      consoleLogOut("WARN", args);
    };
    console.info = (...args: any) => {
      consoleLogOut("INFO", args);
    };
    console.debug = (...args: any) => {
      consoleLogOut("DEBUG", args);
    };
    console.log = (...args: any) => {
      consoleLogOut("DEBUG", args);
    };
    (window as any).console = console;
  }
  const maxclassUserNum = Number(process.env.REACT_APP_CHAT_GROUP_FLG);
  const {
    localAudioTrack,
    localVideoTrack,
    // joinState,
    leave,
    join,
    publish,
    unpublish,
    remoteUsers,
    streamer,
    remoteLiveUsers,
    shareWindow,
    setDevice,
    // cameraSetEnabled,
  } = useAgora(client, async (camTrack: any, list: any, share: any) => {
    if (!camTrack) return;
    let uidList = list.map((d: any) => {
      return d.uid;
    });
    if (uidList.indexOf(agoraUserId)) {
      if (share.length > 0) {
        switch (list.length) {
          case 0://不要
            await camTrack.setEncoderConfiguration({
              width: 360,
              height: 360,
              frameRate: 15,
            });
            break;
          case 1://画面共有+配信+視聴1
            await camTrack.setEncoderConfiguration({
              width: 360,
              height: 360,
              frameRate: 15,
            });
            break;
          case 2://画面共有+配信+視聴1+視聴2
            await camTrack.setEncoderConfiguration({
              width: 320,
              height: 180,
              frameRate: 15,
            });
            break;
        }
      } else {
        switch (list.length) {
          case 0://不要
            await camTrack.setEncoderConfiguration({
              width: 848,
              height: 480,
              frameRate: 15,
            });
            break;
          case 1://配信+視聴1
            await camTrack.setEncoderConfiguration({
              width: 848,
              height: 480,
              frameRate: 15,
            });
            break;
          case 2://配信+視聴1+視聴2
            await camTrack.setEncoderConfiguration({
              width: 480,
              height: 480,
              frameRate: 15,
            });
            break;
        }
      }
    }
  });

  const DialogText = {
    networkUnstable: FormatMessage({
      id: "Network.Message1",
    }),
    ClassInfoErr: FormatMessage({
      id: "Cls012.Err.Text2",
    }),
  };

  const RTMConectErr1 = FormatMessage({ id: "Cls012.RTMErr.Text1" });
  const RTMConectErr2 = FormatMessage({ id: "Cls012.RTMErr.Text2" });
  const RTMConectErr3 = FormatMessage({ id: "Cls012.RTMErr.Text3" });
  const [RTMErrFlg, setRTMErrFlg] = React.useState(false);

  const getUserListOfJson = async () => {
    var file_name =
      "haishin/" +
      store.getState().user.service_id +
      "/" +
      classId +
      "/userList/userList.json";
    let s3 = await createS3();
    if (s3) {
      let file: any = await getFileS3(s3, file_name);
      if (file) {
        var fileReader = new FileReader();
        fileReader.onload = function (json: any) {
          let jsonData = json.currentTarget.result;
          if (jsonData) {
            let data: any = JSON.parse(jsonData);
            if (data && data.length > 0) {
              userListJson = {};
              userListJson["id"] = {};
              userListJson["agora"] = {};
              for (let index = 0; index < data.length; index++) {
                userListJson["id"][data[index]["userId"]] =
                  data[index]["AgoraId"];
                userListJson["agora"][data[index]["AgoraId"]] =
                  data[index]["userId"];
              }
            }
          } else {
            setTimeout(() => getUserListOfJson(), 1000);
          }
        };
        fileReader.readAsText(file);
      } else {
        setTimeout(() => getUserListOfJson(), 1000);
      }
    } else {
      setTimeout(() => getUserListOfJson(), 1000);
    }
  };

  const updataUserListOfJson = async (agoraId: any) => {
    var file_name =
      "haishin/" +
      store.getState().user.service_id +
      "/" +
      classId +
      "/userList/userList.json";
    let s3: any = await createS3();
    if (s3) {
      let file: any = await getFileS3(s3, file_name);
      if (file) {
        var fileReader = new FileReader();
        fileReader.onload = function (json: any) {
          let jsonData = json.currentTarget.result;
          if (jsonData) {
            let data: any = JSON.parse(jsonData);
            let newData = [];
            if (data && data.length > 0) {
              newData = data;
            }
            newData.push({
              userId: userId,
              AgoraId: agoraId,
            });
            let saveJsonData = JSON.stringify(newData);
            var blob = new Blob([saveJsonData], { type: "text/json" });
            var jsonFile = new File([blob], "userList.json");
            uploadS3(s3, file_name, jsonFile);
          } else {
            setTimeout(() => updataUserListOfJson(agoraId), 1000);
          }
        };
        fileReader.readAsText(file);
      } else {
        setTimeout(() => updataUserListOfJson(agoraId), 1000);
      }
    } else {
      setTimeout(() => updataUserListOfJson(agoraId), 1000);
    }
  };

  // 授業情報取得
  const getClassInfo = async () => {
    const userinfo = await getSessionUser();
    userId = userinfo.user_id;
    userNo = 4;
    serviceId = userinfo.service_id;
    const Log: any[] = [];

    let getData1 = await LC0305(dispatch, classId);
    if (getData1 && getData1.state === "0" && getData1.body) {
      Log.push("LC0305 Clear:" + JSON.stringify(getData1.body));
      if (getData1.body.class_name) className = getData1.body.class_name;
      isVisibleDefaultEnquete = getData1.body.is_visible_default_enquete;

      if (getData1.body.page_after_class)
        afterClassType = getData1.body.page_after_class;

      if (getData1.body.status_class) {
        if (
          getData1.body.status_class !== "3" &&
          getData1.body.status_class !== "4"
        ) {
          setErrLecNotStartedOpen(true);
          endLec = true;
          //viewLogState = viewLogStatus.outService;
        }

        // if (getData1.body[0].m1_status_class === "4") {
        //   viewLogState = viewLogStatus.inService;
        // }
        /*if (getData1.body.status_class === "3") {
          if (getData1.body.start_date) {
            viewLogState = viewLogStatus.restartIni;
          } else {
            viewLogState = viewLogStatus.wait;
            registViewLog(timeCount, "0");
          }
        }*/
      }
      if (getData1 && Object.keys(getData1.body).indexOf("user") >= 0) {
        let user: any = [];
        for (let i = 0; i < getData1.body.user.length; i++) {
          user[i] = {
            user_id: getData1.body.user[i].user_id,
            user_name: getData1.body.user[i].user_name,
            handle_name: getData1.body.user[i].handle_name,
            status: USER_STATUS.EXIT,
            lastActionTime: 0,
            user_agoraid: "",
          };
          if (
            userListJson &&
            userListJson["id"] &&
            userListJson["id"][user[i].user_id]
          )
            user[i].user_agoraid = userListJson["id"][user[i].user_id];
        }
        setUserData(user);
        Users = user;
        getViewUser();
      }
      if (Object.keys(getData1.body).indexOf("channel_id") >= 0) {
        lectureChannelId = getData1.body.channel_id;
        let getData2 = await ST0302(
          dispatch,
          lectureChannelId,
          userId + store.getState().user.user_class
        );
        if (getData2 && getData2.state === "0") {
          Log.push("ST0302 Clear:" + JSON.stringify(getData2.body));
          agoraOption = {
            appID: getData2.body.app_id,
            channel: lectureChannelId,
            uid: userId,
            token: getData2.body.token,
            token_rtm: getData2.body.token_rtm,
          };
          if (
            client.connectionState === "DISCONNECTED" &&
            agoraOption.appID &&
            agoraOption.channel &&
            agoraOption.token
          ) {
            await client.setClientRole("audience");
            let joinData: any = await join(
              agoraOption.appID,
              agoraOption.channel,
              agoraOption.token,
              userListJson && userListJson["id"] && userListJson["id"][userId]
                ? userListJson["id"][userId]
                : null,
              true,
              { userId: userId, classId: classId, service_id: serviceId },
              true
            );
            if (joinData && joinData.state && joinData.state === "OK") {
              setLoading(false);
              setTimeout(checkBrowserType, 5000);
              Log.push("ST0302 Clear:" + JSON.stringify(joinData));
              agoraUserId = joinData.agoraUserId;
              if (
                userListJson &&
                userListJson["agora"] &&
                !userListJson["agora"][agoraUserId]
              ) {
                userListJson["id"][userId] = agoraUserId;
                userListJson["agora"][agoraUserId] = userId;
                updataUserListOfJson(agoraUserId);
              }
              checkStreamer = true;
              firstJoin = true;
              socketSendData();
              uploadLog(Log, "_getClassInfo_");
              setTimeout(() => {
                let agoraList = [];
                for (
                  let index = 0;
                  index < client.remoteUsers.length;
                  index++
                ) {
                  agoraList.push(client.remoteUsers[index].uid);
                }
                for (let i = 0; i < Users.length; i++) {
                  let useragoraid = userListJson["id"][Users[i].user_id];
                  if (!useragoraid) continue;
                  Users[i].status =
                    agoraList.indexOf(useragoraid) >= 0
                      ? USER_STATUS.JOIN
                      : USER_STATUS.EXIT;
                }
                setUserData(Users);
              }, 3000);
            } else {
              console.log("joinData err", joinData);
              Log.push("joinData err:" + JSON.stringify(joinData));
              uploadLog(Log, "_getClassInfo_");
              setLoading(false);
              alert(DialogText.ClassInfoErr);
              getOut(true);
            }
          } else {
            console.log("agoraOption err", agoraOption);
            uploadLog(Log, "_getClassInfo_");
            setLoading(false);
            alert(DialogText.ClassInfoErr);
            getOut(true);
          }
        } else {
          Log.push("ST0302 err:" + JSON.stringify(getData2));
          uploadLog(Log, "_getClassInfo_");
          setLoading(false);
          alert(DialogText.ClassInfoErr);
          getOut(true);
        }
      }
    } else {
      Log.push("LC0305 err:" + JSON.stringify(getData1));
      uploadLog(Log, "_getClassInfo_");
      setLoading(false);
      alert(DialogText.ClassInfoErr);
      getOut(true);
    }
  };

  const uploadLog = async (Log: any, mesod: string) => {
    if (process.env.REACT_APP_AWS_S3_UPLOG === "true") {
      var jsontext = JSON.stringify(Log);
      var blob = new Blob([jsontext], { type: "text/json" });
      var jsonFile = new File([blob], "message.json");
      const date = new Date().getTime();
      var S3: any = await createS3();
      await uploadS3(
        S3,
        "haishin/" +
          serviceId +
          "/" +
          classId +
          "/Log/" +
          userId +
          "/" +
          userId +
          mesod +
          date +
          ".json",
        jsonFile
      );
    }
  };

  const elm = useRef(null);
  const classes = useStyles();

  const [screenIsSmall, setscreenIsSmall] = useState<Boolean>(false);
  const refScreenIsSmall = useRef(screenIsSmall);
  refScreenIsSmall.current = screenIsSmall;

  const [streamIsSmall, setStreamIsSmall] = useState(true);
  const [allWidth, setAllWidth] = useState(false);
  // const [headerColor, setHeaderColor] = useState("#666666");
  const [joinLecDialogOpen, setJoinLecDialogOpen] = useState(false);
  const [imgUploadReqDialogOpen, setImgUploadReqDialogOpen] = useState(false);
  const [imgUploadDialogOpen, setImgUploadDialogOpen] = useState(false);
  const [joinLecVoiceDialogOpen, setJoinLecVoiceDialogOpen] = useState(false);
  const [handWrittenJoinDialogOpen, setHandWrittenJoinDialogOpen] =
    useState(false);
  // const [message, setMessage] = useState<chatMessageData[]>([]);
  const [chatMessage, setChatMessage] = useState("");
  const [textAreaMT, setTextAreaMT] = useState(32);
  const [chatMT, setChatMT] = useState(0);
  const [loading, setLoading] = useState(true);
  //  const [streamerDrowing, setStreamerDrowing] = useState(false);
  const [canvasWide, setCanvasWide] = useState("");
  const [canvasHeight, setCanvasHeight] = useState("");
  const [uploadFileUrl, setUploadFileUrl] = useState("");
  const [chooseFileName, setChooseFileName] = useState("");
  const [uploadFile, setUploadFile] = useState<any>();
  const [questionDialogOpen, setQuestionDialogOpen] = useState(false);
  const [questionResult, setQuestionResult] = useState<any>(null);
  const [ingegate, setIngegate] = useState(0);
  // const [classVideo, setClassVideo] = useState("");
  // const [playMovie, setPlayMovie] = useState(false);
  // const refPlayMovie = useRef(playMovie);
  // refPlayMovie.current = playMovie;

  // const [nowVideoTime, setNowVideoTime] = useState("");
  const [chatPermission, setChatPermission] = useState(true);
  const [chatSendControlType, setChatSendControlType] = useState(1);
  const [confirmGetOutOpen, setconFirmGetOutOpen] = useState(false);
  const [cameraDialogOpen, setCameraDialogOpen] = useState(false);
  const [penDisableFlg, setPenDisableFlg] = useState(true);
  const refPenDisableFlg = useRef(penDisableFlg);
  refPenDisableFlg.current = penDisableFlg;
  const [errLecNotStartedOpen, setErrLecNotStartedOpen] = useState(false);
  const [errNotStreamerOpen, setErrNotStreamerOpen] = useState(false);
  const [selectSendTo, setSelectSendTo] = useState<{
    selVal: string;
  }>({
    selVal: "all",
  });
  const [menuSFlg, setMenuSFlg] = useState(false);
  const [screenShare, setScreenShare] = useState("null");
  const [leftMenu, setLeftMenu] = useState(282);
  const [checkLocalVideo, setCheckLocalVideo] = useState(false);
  const [testDoFlg, setTestDoFlg] = useState(false);
  const [chattestDoFlg, setChatTestDoFlg] = useState(false);
  const [sendDoFlg, setSendDoFlg] = useState(false);
  const [b1ColorFlg, setB1ColorFlg] = useState(false);
  const [b2ColorFlg, setB2ColorFlg] = useState(false);
  const [b3ColorFlg, setB3ColorFlg] = useState(false);
  const [RTMstate, setRTMstate] = useState("");
  const sendDoingFlg = useRef(sendDoFlg);
  sendDoingFlg.current = sendDoFlg;

  const [networkAlertOpen, setNetworkAlertOpen] = useState(true);
  const [UserData, setUserData] = useState([
    {
      user_id: "",
      user_name: "",
      handle_name: "",
      status: USER_STATUS.EXIT,
      user_agoraid: [-1],
    },
  ]);

  const finger = "/img/icon_058480_16.png";

  const newWhiteBoard = "/img/whitebord.png";
  const chimeMelody = "/westminster.mp3";
  const timeLimit = FormatMessage({ id: "Error.Message.ClassTime" });
  const playChime = async () => {
    if (music) music.play().catch(() => setTimeout(() => playChime(), 1000));
  };

  const [quizId, setQuizId] = useState(0);

  // ユーザ名取得
  const getUserName = async () => {
    let getData = await US0304(dispatch);
    if (getData && Object.keys(getData.body).indexOf("user_name") >= 0) {
      userName = getData.body.user_name;
    }
    if (getData && Object.keys(getData.body).indexOf("handle_name") >= 0) {
      handleName = getData.body.handle_name;
    }
  };

  const filePath: string = "haishin/" + serviceId + "/" + classId + "/doc/";

  const userStatusChange = (
    userid: string,
    status: string,
    useragoraid?: any
  ) => {
    const Index = Users.findIndex((element: any) => element.user_id === userid);
    if (Index < 0) return;
    const stg = serviceId + "_" + classId;
    const user = Users[Index];
    const leftUser = joinList?.find(
      (element: any) => element === stg + user.user_id + 4
    );
    if (leftUser && status === USER_STATUS.AGORAEXIT) {
      return;
    } else if (!leftUser && status === USER_STATUS.AGORAEXIT) {
      Users[Index].status = USER_STATUS.EXIT;
    } else {
      Users[Index].status = status;
    }
    setUserData(Users);
    // setUserChange(!UserChange);
  };

  const reactionDoing = () => {
    if (!messageFlg && !reactionFlg) {
      if (reactionList.length > 0) {
        reactionFlg = true;
        setTimeout(() => {
          try {
            var data = reactionList;
            if (sendDoingFlg.current) {
              data = data
                .concat(data)
                .concat(data)
                .concat(data)
                .concat(data)
                .concat(data)
                .concat(data)
                .concat(data);
            }
            reactionList = [];
            if (classUserNum > maxclassUserNum) {
              var reaction1 = 0;
              var reaction2 = 0;
              var reaction3 = 0;
              for (let index = 0; index < data.length; index++) {
                const commandProp = data[index];
                if (commandProp) {
                  if (commandProp.option.reaction === 1) {
                    reaction1++;
                  } else if (commandProp.option.reaction === 2) {
                    reaction2++;
                  } else {
                    reaction3++;
                  }
                }
              }
              data = null;
              let msgInsert: any =
                document.getElementsByClassName("msg-insert");
              for (let index = 0; index < msgInsert.length; index++) {
                let div = document.createElement("div");
                div.setAttribute("class", "msg-receives");
                let span = document.createElement("span");
                if (reaction1 > 0) {
                  span.append("🙂 X " + reaction1);
                }
                if (reaction2 > 0) {
                  if (reaction1 > 0) span.append(document.createElement("br"));
                  span.append("❌ X " + reaction2);
                }
                if (reaction3 > 0) {
                  if (reaction1 > 0 || reaction2 > 0)
                    span.append(document.createElement("br"));
                  span.append("✋ X " + reaction3);
                }
                div.append(span);

                msgInsert[index].append(div);
                if (
                  msgInsert &&
                  msgInsert[index] &&
                  msgInsert[index].clientHeight &&
                  msgInsert[index].clientHeight > maxHeightMessageDiv
                ) {
                  for (let i = 0; i < 10; i++) {
                    msgInsert[index].children[i].remove();
                    if (msgInsert[index].clientHeight < maxHeightMessageDiv)
                      break;
                  }
                }
              }
            } else {
              var users: any = {};
              for (let index = 0; index < data.length; index++) {
                const commandProp = data[index];
                if (commandProp) {
                  if (!users[commandProp.option.send_user_id])
                    users[commandProp.option.send_user_id] = {
                      name: commandProp.option.send_handle_name,
                      reaction1: 0,
                      reaction2: 0,
                      reaction3: 0,
                    };
                  if (commandProp.option.reaction === 1) {
                    users[commandProp.option.send_user_id].reaction1++;
                  } else if (commandProp.option.reaction === 2) {
                    users[commandProp.option.send_user_id].reaction2++;
                  } else {
                    users[commandProp.option.send_user_id].reaction3++;
                  }
                }
              }
              data = null;
              let msgInsert: any =
                document.getElementsByClassName("msg-insert");
              for (let index = 0; index < msgInsert.length; index++) {
                let div = document.createElement("div");
                div.setAttribute("class", "msg-receives");
                let span = document.createElement("span");
                var userKeys = Object.keys(users);
                for (let index = 0; index < userKeys.length; index++) {
                  const element = users[userKeys[index]];
                  if (index > 0) span.append(document.createElement("br"));
                  if (element.reaction1 > 0) {
                    span.append(element.name + ":🙂 X " + element.reaction1);
                  }
                  if (element.reaction2 > 0) {
                    if (element.reaction1 > 0)
                      span.append(document.createElement("br"));
                    span.append(element.name + ":❌ X " + element.reaction2);
                  }
                  if (element.reaction3 > 0) {
                    if (element.reaction1 > 0 || element.reaction2 > 0)
                      span.append(document.createElement("br"));
                    span.append(element.name + ":✋ X " + element.reaction3);
                  }
                }
                div.append(span);

                msgInsert[index].append(div);
                if (
                  msgInsert &&
                  msgInsert[index] &&
                  msgInsert[index].clientHeight &&
                  msgInsert[index].clientHeight > maxHeightMessageDiv
                ) {
                  for (let i = 0; i < 10; i++) {
                    msgInsert[index].children[i].remove();
                    if (msgInsert[index].clientHeight < maxHeightMessageDiv)
                      break;
                  }
                }
              }
            }
            const clist = document.getElementsByClassName("chat-box");
            if (clist && clist.length > 0) {
              clist[0].scrollTop = clist[0].scrollHeight;
              clist[1].scrollTop = clist[1].scrollHeight;
            }
            if (reactionList.length > 0) {
              setTimeout(() => reactionDoing(), 1000);
            } else {
              reactionFlg = false;
            }
          } catch (err: any) {
            console.log("MessageInsertErr");
            reactionFlg = false;
          }
        }, 1000);
      }
    }
  };

  const chatDoing = () => {
    if (!messageFlg) {
      if (chatList.length > 0) {
        messageFlg = true;
        setTimeout(() => {
          try {
            var data = chatList;
            if (sendDoingFlg.current) {
              data = data
                .concat(data)
                .concat(data)
                .concat(data)
                .concat(data)
                .concat(data)
                .concat(data)
                .concat(data);
            }
            chatList = [];
            if (classUserNum > maxclassUserNum) {
              if (data && data.length > 0) {
                let div = document.createElement("div");
                div.setAttribute("class", "msg-receives");
                for (let index = 0; index < data.length; index++) {
                  const commandProp = data[index];
                  if (commandProp) {
                    if (index > 0) div.append(document.createElement("br"));
                    let span = document.createElement("span");
                    if (commandProp.userId === userId) {
                      span.style.color = "red";
                    }
                    if (commandProp.option.send_handle_name) {
                      span.append(commandProp.option.send_handle_name + ":");
                    } else {
                      span.append(commandProp.option.send_user_name + ":");
                    }
                    let messages = commandProp.option.message.split("\n");
                    for (let mi = 0; mi < messages.length; mi++) {
                      if (mi > 0) span.append(document.createElement("br"));
                      span.append(messages[mi]);
                    }
                    div.append(span);
                  }
                }
                data = null;
                let msgInsert: any =
                  document.getElementsByClassName("msg-insert");
                for (let index = 0; index < msgInsert.length; index++) {
                  var element = div.cloneNode(true);
                  msgInsert[index].append(element);
                  if (
                    msgInsert &&
                    msgInsert[index] &&
                    msgInsert[index].clientHeight &&
                    msgInsert[index].clientHeight > maxHeightMessageDiv
                  ) {
                    for (let i = 0; i < 10; i++) {
                      msgInsert[index].children[i].remove();
                      if (msgInsert[index].clientHeight < maxHeightMessageDiv)
                        break;
                    }
                  }
                }
              }
            } else {
              //1件件表示
              if (data && data.length > 0) {
                let msgInsert: any =
                  document.getElementsByClassName("msg-insert");
                for (let index = 0; index < data.length; index++) {
                  const commandProp = data[index];
                  if (commandProp) {
                    let div = document.createElement("div");
                    div.setAttribute("class", "msg-receives");
                    let span = document.createElement("span");
                    if (commandProp.userId === userId) {
                      span.style.color = "red";
                    }
                    if (commandProp.option.send_handle_name) {
                      span.append(commandProp.option.send_handle_name + ":");
                    } else {
                      span.append(commandProp.option.send_user_name + ":");
                    }
                    let messages = commandProp.option.message.split("\n");
                    for (let mi = 0; mi < messages.length; mi++) {
                      if (mi > 0) span.append(document.createElement("br"));
                      span.append(messages[mi]);
                    }
                    div.append(span);
                    for (let index = 0; index < msgInsert.length; index++) {
                      var element = div.cloneNode(true);
                      msgInsert[index].append(element);
                      if (
                        msgInsert &&
                        msgInsert[index] &&
                        msgInsert[index].clientHeight &&
                        msgInsert[index].clientHeight > maxHeightMessageDiv
                      ) {
                        for (let i = 0; i < 10; i++) {
                          msgInsert[index].children[i].remove();
                          if (
                            msgInsert[index].clientHeight < maxHeightMessageDiv
                          )
                            break;
                        }
                      }
                    }
                  }
                }
                data = null;
              }
            }
            const clist = document.getElementsByClassName("chat-box");
            if (clist && clist.length > 0) {
              clist[0].scrollTop = clist[0].scrollHeight;
              clist[1].scrollTop = clist[1].scrollHeight;
            }
            if (chatList.length > 0) {
              setTimeout(() => chatDoing(), 1000);
            } else {
              messageFlg = false;
            }
          } catch (err: any) {
            console.log("MessageInsertErr");
            messageFlg = false;
          }
        }, 1000);
      }
    }
  };

  const reComingCommand = () => {
    if (incomingCommandList.length > 0) {
      let list = incomingCommandList;
      incomingCommandList = [];
      for (let index = 0; index < list.length; index++) {
        setTimeout(() => incomingCommand(list[index]), 100 * Number(index));
      }
    }
  };

  // const setQuestCheckInterval = async (choiceId: any) => {
  //   let path = store.getState().router.location.pathname;
  //   if (endLec || path !== "/cls/012" || !questCheckIntervalFlg) return;
  //   let s3 = await createS3();
  //   if (s3) {
  //     let file: any = await getFileS3(
  //       s3,
  //       "haishin/" +
  //         store.getState().user.service_id +
  //         "/" +
  //         classId +
  //         "/QuestJson/quest.json"
  //     );
  //     if (file && file.size > 0) {
  //       let data: any = await new Promise((resolve, reject) => {
  //         let reader = new FileReader();
  //         reader.onload = () => {
  //           if (reader.result) {
  //             var data: any = JSON.parse(String(reader.result));
  //             return resolve(data);
  //           }
  //         };
  //         reader.readAsText(file);
  //       });
  //       if (data && data.choiceId) {
  //         if (
  //           data.choiceId !== choiceId ||
  //           (data.choiceId === choiceId && !data.display)
  //         ) {
  //           if (graphShapeList[questionListsColor.shape_id]) {
  //             GraphFabCanvas.remove(
  //               graphShapeList[questionListsColor.shape_id]
  //             );
  //             delete graphShapeList[questionListsColor.shape_id];
  //             GraphFabCanvas.renderAll();
  //           }
  //           percentFlg = false;
  //           setQuestionDialogOpen(false);
  //           setQuestionResult(null);
  //           endAnswer = false;
  //           questCheckIntervalFlg = false;
  //         }
  //       }
  //     }
  //   }
  //   if (questCheckIntervalFlg)
  //     setTimeout(() => setQuestCheckInterval(choiceId), 60000);
  // };

  // 受信時処理
  const incomingCommand = async (commandProp: any) => {
    if (
      (reloop || boradChangeFlg) &&
      (commandProp.actionId === actionComand.MSG_WB_DRAW ||
        commandProp.actionId === actionComand.MSG_WB_SHOW_PICTURE ||
        commandProp.actionId === actionComand.MSG_WB_CHANGE_SLIDE ||
        commandProp.actionId === actionComand.MSG_WB_EXPANTION ||
        commandProp.actionId === actionComand.MSG_WB_RESULT)
    ) {
      incomingCommandList.push(commandProp);
      setTimeout(() => incomingCommand("null"), 1000);
      return;
    }
    if (!commandProp || commandProp === "null") {
      reComingCommand();
      return;
    }
    if (Number(commandProp.save_no) > Number(changeJsonSaveNo))
      changeJsonSaveNo = commandProp.save_no;
    // 異常時視聴ログ停止
    if (errNotStreamerOpen) setErrNotStreamerOpen(false);
    switch (commandProp.actionId) {
      // 手書き
      case actionComand.MSG_WB_DRAW:
        sendReBack = false;
        if (commandProp.option.board_event_type === 1) {
          streamerDrawline(commandProp.option);
        }
        if (commandProp.option.board_event_type === 2) {
          streamerDrawHand(commandProp.option);
        }
        if (
          commandProp.option.board_event_type === 3 ||
          commandProp.option.board_event_type === 4 ||
          commandProp.option.board_event_type === 5 ||
          commandProp.option.board_event_type === 6
        ) {
          let shape_data: any = commandProp.option.shape_data;
          let shape_id: any = commandProp.option.shape_id;
          shape_data.selectable = false;
          shape_data.evented = false;
          if (FabCanvas) {
            if (shape_data.type === "line") {
              let mouse_point: any = commandProp.option.mouse_point;
              shapeList[shape_id] = new fabric.Line(
                [mouse_point.px, mouse_point.py, mouse_point.x, mouse_point.y],
                {
                  globalCompositeOperation: shape_id,
                  strokeWidth: shape_data.strokeWidth,
                  fill: shape_data.fill,
                  stroke: shape_data.stroke,
                  originX: "center",
                  originY: "center",
                  selectable: false,
                  evented: false,
                }
              );
            }
            shape_data.name = shape_id;
            if (shape_data.type === "rect") {
              shapeList[shape_id] = new fabric.Rect(shape_data);
            }
            if (shape_data.type === "circle") {
              shapeList[shape_id] = new fabric.Circle(shape_data);
            }
            if (shape_data.type === "triangle") {
              shapeList[shape_id] = new fabric.Triangle(shape_data);
            }
            if (shapeList[shape_id]) FabCanvas.add(shapeList[shape_id]);
          }
        }
        if (commandProp.option.board_event_type === 8) {
          sendReBack = false;
          let brush_id = commandProp.option.brush_id;
          if (brush_id && brush_id.length > 0) {
            let objects = FabCanvas.getObjects();
            let ids = [];
            for (let index = 0; index < objects.length; index++) {
              ids.push(objects[index].globalCompositeOperation);
            }
            for (let index = 0; index < brush_id.length; index++) {
              const id = brush_id[index];
              if (lineList[id]) delete lineList[id];
              if (shapeList[id]) delete shapeList[id];
              for (let a = 0; a < ids.length; a++) {
                if (ids[a] === id) FabCanvas.remove(objects[a]);
              }
            }
            FabCanvas.renderAll();
          }
        }
        if (commandProp.option.board_event_type === 9) {
          let shape_id = commandProp.option.shape_id;
          let activeObjects = commandProp.option.shape_data;
          activeObjects.globalCompositeOperation = shape_id;
          let objects = FabCanvas.getObjects();
          if (objects.length > 0) {
            for (let index = 0; index < objects.length; index++) {
              const element = objects[index];
              if (element.globalCompositeOperation === shape_id) {
                if (element.type === "line") {
                  FabCanvas.remove(element);
                  shapeList[shape_id] = new fabric.Line(
                    [
                      activeObjects.x1,
                      activeObjects.y1,
                      activeObjects.x2,
                      activeObjects.y2,
                    ],
                    activeObjects
                  );
                  FabCanvas.add(shapeList[shape_id]);
                } else {
                  shapeList[shape_id] = element;
                  shapeList[shape_id].set(activeObjects);
                }
                break;
              }
            }
            FabCanvas.renderAll();
          }
          // if (shapeList[shape_id]) {
          //   if (shapeList[shape_id].type === "line") {
          //     FabCanvas.remove(shapeList[shape_id]);
          //     shapeList[shape_id] = new fabric.Line(
          //       [
          //         activeObjects.x1,
          //         activeObjects.y1,
          //         activeObjects.x2,
          //         activeObjects.y2,
          //       ],
          //       activeObjects
          //     );
          //     FabCanvas.add(shapeList[shape_id]);
          //   } else {
          //     if (shapeList[shape_id].canvas)
          //       shapeList[shape_id].set(activeObjects);
          //   }
          //   FabCanvas.renderAll();
          // }
          // streamerShapeChange(commandProp.option);
        }
        if (commandProp.option.board_event_type === 10) {
          sendReBack = false;
          let clear_uesr_id = commandProp.option.clear_uesr_id;
          if (clear_uesr_id) {
            clearUserData(clear_uesr_id, true);
          } else {
            let objects = FabCanvas.getObjects();
            if (objects.length > 0) {
              for (let index = 0; index < objects.length; index++) {
                const element = objects[index];
                if (
                  element.globalCompositeOperation !== "back_ground_color" &&
                  element.type !== "image"
                )
                  FabCanvas.remove(element);
              }
            }
            FabCanvas.renderAll();
            shapeList = {};
            lineList = {};
            lineData = {};
            lineOrder = [];
            makeLineFlg = false;
          }
        }
        if (commandProp.option.board_event_type === 7) {
          expantionData.point = commandProp.option.mouse_point;
          if (expantionData.point) {
            FabCanvas.discardActiveObject();
            var dataList = FabCanvas.getObjects();
            var sel = new fabric.ActiveSelection(dataList, {
              canvas: FabCanvas,
            });
            sel.set({
              top: expantionData.point.scrollTop,
              left: expantionData.point.scrollLeft,
            });
            setActive = true;
            FabCanvas.setActiveObject(sel);
            FabCanvas.requestRenderAll();
            FabCanvas.discardActiveObject();
            setActive = false;
            // drowCanvasDiv.scrollTop =
            //   expantionData.point.scrollTop * maxDivScrollTop;
            // drowCanvasDiv.scrollLeft =
            //   expantionData.point.scrollLeft * maxDivScrollLeft;
            // imgCanvasDiv.scrollTop =
            //   expantionData.point.scrollTop * maxDivScrollTop;
            // imgCanvasDiv.scrollLeft =
            //   expantionData.point.scrollLeft * maxDivScrollLeft;
          }
        }

        break;
      // 配信者入れ替え
      case actionComand.MSG_STREAMER_CHANGE:
        makeLineFlg = true;
        addLineFlg = true;
        lineData = {};
        lineOrder = [];
        FabCanvas.clear();
        imgCmdId = -1;
        // setMessage([]);
        exitFullStream();
        exitFullScreen(true);
        makeLineFlg = false;
        addLineFlg = false;
        // stopVideo();
        break;
      // 写真リスト更新
      case actionComand.MSG_IMGLIST_RESET:
        getImagesData();
        break;
      // 授業開始
      case actionComand.MSG_CLASS_START:
        /* if (
          viewLogState === viewLogStatus.wait ||
          viewLogState === viewLogStatus.restartWait
        ) {
          viewLogState = viewLogStatus.inService;
        }*/
        ext = commandProp.option.extraTime;
        startTime = commandProp.option.startDate;
        // } else {
        //   nonStreamerFlg = false;
        // }
        break;
      // 参加依頼
      case actionComand.MSG_JOIN_REQUEST:
        if (commandProp.userId === userId) {
          joinButtonDisable = false;
          if (commandProp.option.status === 2) {
            switch (commandProp.option.request_type) {
              // 授業映像音声参加依頼
              case 1:
                setCheckLocalVideo(false);
                handleClickClose();
                unpublish();
                await client.setClientRole("audience");
                break;
              // 音声参加依頼
              case 2:
                joinLecVoiceClickClose();
                unpublish();
                await client.setClientRole("audience");
                break;
              // 手書き参加依頼
              case 3:
                handWrittenJoinClickClose();
                penJoinEnd();
                break;
              // 画像提出依頼
              case 4:
                handleImgUploadClickClose();
                setImgUploadReqDialogOpen(false);
                setImgUploadDialogOpen(false);
                setUploadFile(null);
                setUploadFileUrl("");
                setChooseFileName("");
                setErrorMsgImgUp("");
            }
            if (micTrack) {
              micTrack.stop();
              micTrack.close();
            }
            if (videoTrack) {
              videoTrack.stop();
              videoTrack.close();
            }
          } else {
            switch (commandProp.option.request_type) {
              // 授業映像音声参加依頼
              case 1:
                lecDialogOpen();
                break;
              // 音声参加依頼
              case 2:
                lecVoiceDialogOpen();
                break;
              // 手書き参加依頼
              case 3:
                setHandWrittenJoinDialogOpen(true);
                break;
              // 画像提出依頼
              case 4:
                setImgUploadReqDialogOpen(true);
                break;
            }
          }
        }
        break;
      // 反応
      case actionComand.MSG_REACTION:
        if (Object.keys(commandProp.option).indexOf("reaction_counts") >= 0) {
          let whiteBoard = document.getElementsByClassName(
            "whiteBoard"
          ) as HTMLCollectionOf<HTMLElement>;
          let zoom =
            Number(whiteBoard[0].style.width.replace("px", "")) / defaultWidth;
          drawUserReactionCount(commandProp.option.reaction_counts, zoom);
        } else {
          reactionList.push(commandProp);
          reactionDoing();
        }
        if (commandProp.option.send_handle_name) {
          userStatusChange(commandProp.option.send_user_id, USER_STATUS.JOIN);
        }
        LastChatReceiveTime = new Date().getTime();
        break;
      // チャット
      case actionComand.MSG_CHAT_SEND_MESSAGE:
        if (commandProp.option.send_to_class !== 1) {
          if (!commandProp.userId || commandProp.userId === userId) {
            chatList.push(commandProp);
            chatDoing();
          }
          if (commandProp.option.send_user_handle_name) {
            userStatusChange(commandProp.option.send_user_id, USER_STATUS.JOIN);
          }
          LastChatReceiveTime = new Date().getTime();
        }
        break;
      // レイアウト切替
      case actionComand.MSG_LAYOUT_CHANGE:
        sendReBack = false;
        console.log("レイアウト切替", commandProp);
        switch (commandProp.option.layout_type) {
          case 1:
            console.log("レイアウト切替：視聴映像最大化実施");
            setLeftMenu(282);
            exitFullScreen(true);
            setTimeout(() => requestFullStream(), 100);
            break;
          case 2:
            console.log("レイアウト切替：視聴映像最大化解除");
            exitFullStream();
            break;
          case 3:
            console.log("レイアウト切替：ボード最大化実施");
            setLeftMenu(0);
            requestFullScreen();
            exitFullStream();
            break;
          case 4:
            console.log("レイアウト切替：ボード最大化解除");
            setLeftMenu(282);
            exitFullScreen(true);
            break;
        }
        break;
      // 画像資料切り替え
      case actionComand.MSG_WB_SHOW_PICTURE:
        sendReBack = false;
        if (imgCmdTime < Number(commandProp.currentTime)) {
          imgCmdTime = Number(commandProp.currentTime);
          imgCmdId = commandProp.option.board_document_id;
          comingImgCommand();
        }
        break;
      // スライド切替
      case actionComand.MSG_WB_CHANGE_SLIDE:
        makeLineFlg = true;
        addLineFlg = true;
        lineData = {};
        lineOrder = [];
        FabCanvas.clear();
        lineList = {};
        shapeList = {};
        sendReBack = false;
        makeLineFlg = false;
        addLineFlg = false;
        // 画像表示コマンドテレコ対策
        if (boardCmdTime < Number(commandProp.currentTime)) {
          imgCmdTime = Number(commandProp.currentTime);
          boardCmdTime = Number(commandProp.currentTime);
          getBoardInfo(commandProp);
        }
        break;

      // 択一問題
      case actionComand.MSG_QUIZ_QUESTIONS:
        if (commandProp.option && Object.keys(commandProp.option).length > 0) {
          let whiteBoard = document.getElementsByClassName(
            "whiteBoard"
          ) as HTMLCollectionOf<HTMLElement>;
          let zoom =
            Number(whiteBoard[0].style.width.replace("px", "")) / defaultWidth;

          if (Object.keys(commandProp.option).indexOf("percent_flg") >= 0) {
            percentFlg = commandProp.option.percent_flg;
          } else {
            disabled = false;
            quizOptions = commandProp.option.answer_options;
            if (
              !endAnswer &&
              commandProp.option.choice_id &&
              !questionDialogOpen
            ) {
              setQuizId(commandProp.option.choice_id);
              setQuestionDialogOpen(true);
              setQuestionResult(true);
            }
          }
          drawShowAnswer(commandProp.option.answer_options, zoom);
          if (commandProp.option.choice_id) {
            var choiceId = commandProp.option.choice_id;
            questCheckIntervalFlg = true;
            // setTimeout(() => setQuestCheckInterval(choiceId), 60000);
          }
        } else {
          if (graphShapeList[questionListsColor.shape_id]) {
            GraphFabCanvas.remove(graphShapeList[questionListsColor.shape_id]);
            delete graphShapeList[questionListsColor.shape_id];
            GraphFabCanvas.renderAll();
          }
          percentFlg = false;
          setQuestionDialogOpen(false);
          setQuestionResult(null);
          endAnswer = false;
          questCheckIntervalFlg = false;
        }
        break;

      // 拡大
      case actionComand.MSG_WB_EXPANTION:
        sendReBack = false;
        expantionData.point = commandProp.option.mouse_point;
        setExpantion(commandProp.option.scale);
        break;

      // チャット送信制御
      case actionComand.MSG_CHAT_SEND_SETTING:
        console.log("チャット送信制御", commandProp);
        setChatSendControlType(Number(commandProp.option.chat_send_setting));
        break;

      // チャット表示制御
      case actionComand.MSG_CHAT_DISPLAY_SETTINGS:
        console.log("チャット表示制御", commandProp);
        switch (commandProp.option.chat_display_setting) {
          case 1:
            console.log("チャット表示制御：表示");
            setChatPermission(true);
            const clist = document.getElementsByClassName("chat-box");
            //スクロールバーの位置をリストの最下部に設定
            if (clist && clist.length > 0) {
              clist[0].scrollTop = clist[0].scrollHeight;
              clist[1].scrollTop = clist[1].scrollHeight;
            }
            break;
          case 2:
            console.log("チャット表示制御： 非表示");
            setChatPermission(false);
            break;
        }
        break;

      // ボードリクエスト
      case actionComand.MSG_WB_RESULT:
        if (userId === commandProp.userId) {
          ext = Number(commandProp.option.extraTime);
          startTime = commandProp.option.startDate;
          joinStartTime = Number(commandProp.option.join_start_time);
          if (!checkStreamer) {
            makeLineFlg = true;
            addLineFlg = true;
            lineData = {};
            lineOrder = [];
            FabCanvas.clear();
            lineList = {};
            shapeList = {};
            sendReBack = false;
            makeLineFlg = false;
            addLineFlg = false;
          } else {
            if (commandProp.option.chat_data) {
              getChatHistry(
                commandProp.option.chat_no,
                commandProp.option.chat_data
              );
            }
          }
          var nowDate = new Date().getTime();
          if (Math.abs(Number(commandProp.currentTime) - nowDate) > 1000)
            timeLag = Number(commandProp.currentTime) - nowDate;
          if (boardCmdTime < Number(commandProp.currentTime)) {
            boardCmdTime = Number(commandProp.currentTime);
            getBoardInfo(commandProp, true);
          }
          // 画像表示コマンドテレコ対策
          if (imgCmdTime < Number(commandProp.currentTime)) {
            if (
              commandProp.option.img_no &&
              Number(commandProp.option.img_no) > 0
            ) {
              imgCmdTime = Number(commandProp.currentTime);
              imgCmdId = Number(commandProp.option.img_no);
              // comingImgCommand();
            }
          }
          if (commandProp.option.vl_setting) {
            exitFullScreen(false);
            requestFullStream();
          } else if (commandProp.option.bl_setting) {
            requestFullScreen();
            exitFullStream();
          }
          if (!commandProp.option.scale) {
            expantionData.scale = 1;
          } else {
            expantionData.scale = commandProp.option.scale;
          }
          if (commandProp.option.move_point) {
            expantionData.point = commandProp.option.move_point;
          }
          classUserNum = commandProp.option.class_user_num
            ? commandProp.option.class_user_num
            : 1;
          streamerInfo = {
            uid: commandProp.option.uid,
            streamerId: commandProp.option.send_user_id,
            streamerName: commandProp.option.send_user_name,
          };
          switch (commandProp.option.layout_type) {
            case 1:
              requestFullStream();
              break;
            case 2:
              winResize();
              break;
            case 3:
              requestFullScreen();
              break;
          }

          setChatSendControlType(Number(commandProp.option.chat_send_setting));
          switch (commandProp.option.chat_display_setting) {
            case 1:
              setChatPermission(true);
              break;
            case 2:
              setChatPermission(false);
              break;
          }
        }
        break;

      // 制御リクエスト
      // case actionComand.MSG_SETTING_RESULT:
      //   if (userId === commandProp.userId || commandProp.userId === null) {
      //     streamerInfo = {
      //       uid: commandProp.option.uid,
      //       streamerId: commandProp.option.send_user_id,
      //       streamerName: commandProp.option.send_user_name,
      //     };
      //     switch (commandProp.option.layout_type) {
      //       case 1:
      //         requestFullStream();
      //         break;
      //       case 2:
      //         // ノーマルレイアウト 処理なし
      //         // exitFullStream();
      //         winResize();
      //         break;
      //       case 3:
      //         requestFullScreen();
      //         break;
      //       // case 4: 使用しない
      //       //   break;
      //     }

      //     setChatSendControlType(commandProp.option.chat_send_setting);
      //     //if (commandProp.option.chat_send_setting !== 3) {
      //     //setSelectSendTo({
      //     //selVal: streamerInfo.streamerId,
      //     //});
      //     //}

      //     switch (commandProp.option.chat_display_setting) {
      //       case 1:
      //         setChatPermission(true);
      //         break;
      //       case 2:
      //         setChatPermission(false);
      //         break;
      //     }
      //   }
      //   break;

      // 終了チャイムリクエスト
      case actionComand.MSG_PLAY_CHIME:
        playChime();
        break;

      // 画面共用
      case actionComand.MSG_WB_SCREEN_SHARE:
        let option = commandProp.option;
        if (!screenShareTime) screenShareTime = option.time;
        if (option.time >= screenShareTime) {
          if (option.status === 1) {
            setScreenShare(option.img_data);
          }
          if (option.status === 2) {
            setScreenShare("null");
          }
          screenShareTime = option.time;
        }
        break;

      // 他視聴ユーザ参加
      case actionComand.MSG_WB_REQUEST:
        if (commandProp.option.send_user_id === userId) {
          getSessionUser().then((result) => {
            console.log("actionComand.MSG_WB_REQUEST getSession:", result);
            if (result === false) {
              // if (!sessionInterval) clearInterval(sessionInterval);
              // if (!recordInterval) clearInterval(recordInterval);
              getOut(true);
            }
          });
        } else {
          userStatusChange(commandProp.option.send_user_id, USER_STATUS.JOIN);
        }
        // else {
        //
        //   // 入室ユーザへ自分の情報を送信
        //   const nowTime = new Date().getTime();
        //   let welcomeSign = JSON.stringify({
        //     actionId: actionComand.MSG_VIEW_USER_INFO,
        //     userId: null,
        //     currentTime: nowTime,
        //     option: {
        //       send_user_id: userId,
        //       send_user_handle_name: handleName,
        //       send_user_agoraid: agoraUserId,
        //     },
        //     roomId: lectureChannelId,
        //     classId: classId,
        //     sessionId: localStorage.getItem("session_id")
        //       ? localStorage.getItem("session_id")
        //       : null,
        //   });
        //   socket.send(
        //     JSON.stringify({
        //       action: "MESSAGE_SEND",
        //       message: welcomeSign,
        //       roomId: lectureChannelId,
        //     })
        //   );
        // }
        break;

      // 視聴ユーザ情報取得
      // case actionComand.MSG_VIEW_USER_INFO:
      //   if (commandProp.option.send_user_id !== userId) {
      //     userStatusChange(
      //       commandProp.option.send_user_id,
      //       USER_STATUS.JOIN,
      //       commandProp.option.send_user_agoraid
      //     );
      //   }
      //   break;

      // 他視聴ユーザ退出
      case actionComand.MSG_CLASS_EXIT:
        userStatusChange(commandProp.option.send_user_id, USER_STATUS.EXIT, "");
        break;

      case actionComand.MSG_CLASS_END:
        classEndFlg = true;
        if (micTrack) {
          micTrack.stop();
          micTrack.close();
        }
        if (videoTrack) {
          videoTrack.stop();
          videoTrack.close();
        }
        await leave();
        await endClass();
        break;

      // 配信者から視聴ユーザ情報の要求
      /*case actionComand.MSG_GVIEW_USER_INFO:
        //sendAgoraUid();
        break;*/
      // case actionComand.MSG_AGORAID:
      //   userStatusChange(
      //     commandProp.option.send_user_id,
      //     USER_STATUS.JOIN,
      //     commandProp.option.send_user_agoraid
      //   );
      //   break;
      case actionComand.MSG_DISPLAY_CLICK:
        var userAgent = window.navigator.userAgent.toLowerCase();
        if (
          userAgent.indexOf("msie") !== -1 ||
          userAgent.indexOf("trident") !== -1
        ) {
        } else if (userAgent.indexOf("edge") !== -1) {
        } else if (userAgent.indexOf("chrome") !== -1) {
        } else if (userAgent.indexOf("crios") !== -1) {
        } else if (userAgent.indexOf("safari") !== -1) {
          if (
            userAgent.indexOf("ipad") > 0 ||
            (userAgent.indexOf("macintosh") > 0 && "ontouchend" in document)
          ) {
            setTapOpen(true);
          }
        }
        break;
      case actionComand.MSG_ClASSTIME_LIMIT:
        //localStorage.removeItem("playclass");
        localStorage.removeItem("websdk_ng_global_parameter");
        //localStorage.removeItem("ClassClose");
        makeLineFlg = true;
        addLineFlg = true;
        lineData = {};
        lineOrder = [];
        FabCanvas.clear();
        lineList = {};
        shapeList = {};
        sendReBack = false;
        makeLineFlg = false;
        addLineFlg = false;
        if (micTrack) {
          micTrack.stop();
          micTrack.close();
        }
        if (videoTrack) {
          videoTrack.stop();
          videoTrack.close();
        }
        if (screenIsSmall) {
          exitFullScreen(true);
        }
        endLec = true;
        await leave();
        alert(timeLimit);
        if (socket && socket.readyState === WebSocket.OPEN) {
          const nowTime = new Date().getTime();
          let list = JSON.stringify({
            actionId: actionComand.MSG_CLASS_EXIT,
            userId: streamerInfo.streamerId,
            currentTime: nowTime,
            option: {
              send_user_id: userId,
            },
            roomId: lectureChannelId,
            classId: classId,
            sessionId: localStorage.getItem("session_id")
              ? localStorage.getItem("session_id")
              : null,
          });
          socket.send(
            JSON.stringify({
              action: "MESSAGE_SEND",
              message: list,
              roomId: lectureChannelId,
            })
          );
          socket.close();
          channelRTM?.leave();
          clientRTM?.logout();
        }
        //viewLogState = viewLogStatus.outService;
        // if (!recordInterval) clearInterval(recordInterval);
        // if (!sessionInterval) clearInterval(sessionInterval);
        localStorage.setItem("Cls012_session", "");

        //window.open("about:blank", "_self")?.close();
        dispatch(push("/top/002"));
        break;
    }
    lineList = {};
    shapeList = {};
  };

  const lecVoiceDialogOpen = () => {
    setJoinLecVoiceDialogOpen(true);
  };

  const lecDialogOpen = () => {
    setJoinLecDialogOpen(true);
  };

  const penJoinEnd = () => {
    setPenDisableFlg(true);
    operation = "";
    drowing = false;
    FabCanvas.isDrawingMode = true;
    FabCanvas.discardActiveObject();
    FabCanvas.renderAll();
    setTimeout(() => {
      onWrittenFlg = false;
    }, 3000);
  };

  const chatSendClick = () => {
    if (chatMessage) {
      if (!chatsedFlg) {
        chatsedFlg = true;

        /*if (
        chatMessage.indexOf("*") === 0 &&
        chatMessage.lastIndexOf("*") === chatMessage.length - 1
      ) {
        const strNum = chatMessage.substring(1, chatMessage.length - 1);
        const num = Number(strNum);
        let msgInsert = document.getElementsByClassName("msg-insert");
        if (msgInsert[0].firstChild) {
          const child: any = msgInsert[0].firstChild;
          const firstId = child.id;
          const firstIndex = Number(firstId.substring(6));
          if (num) {
            msgLength = num;
            const nowmsgIndex = msgIndex;
            const delIndex = nowmsgIndex - msgLength;
            if (delIndex > 0) {
              for (let index = firstIndex; index < delIndex; index++) {
                for (let i = 0; i < msgInsert.length; i++) {
                  const delId = "msg_" + i + "_" + index;
                  const delDiv = document.getElementById(delId);
                  delDiv?.remove();
                }
              }
            }
          }
        }
      }*/

        let list = "";
        let send_to_class: any = null;
        const nowTime = new Date().getTime() + timeLag;
        let chatTime = nowTime;
        // if (reClassStartDate) {
        //   const reClassTime = new Date(reClassStartDate);
        //   chatTime = reClassTime.setSeconds(reClassTime.getSeconds() + ext);
        //   if (startTime > 0) {
        //     const dif = nowTime - startTime;
        //     chatTime = chatTime + dif;
        //   }
        // }
        const viewsendUser: any = document.getElementById("send-select");
        if (viewsendUser.value === "none") {
          chatsedFlg = false;
          return;
        }
        let sentToUser: any;
        if (viewsendUser.value === selectSendTo.selVal) {
          sentToUser =
            selectSendTo.selVal === "all" ? null : selectSendTo.selVal;
        } else {
          sentToUser = viewsendUser.value === "all" ? null : viewsendUser.value;
        }

        switch (chatSendControlType) {
          case 1:
            if (selectSendTo.selVal === "all") {
              list = JSON.stringify({
                actionId: actionComand.MSG_CHAT_SEND_MESSAGE,
                userId: sentToUser,
                currentTime: chatTime,
                option: {
                  message: chatMessage,
                  send_to_class: null,
                  send_user_id: userId,
                  send_user_name: userName,
                  send_handle_name: handleName,
                  class_id: classId,
                  session_id: store.getState().user.session_id,
                },
                roomId: lectureChannelId,
                classId: classId,
                sessionId: localStorage.getItem("session_id")
                  ? localStorage.getItem("session_id")
                  : null,
              });
              send_to_class = null;
            } else {
              list = JSON.stringify({
                actionId: actionComand.MSG_CHAT_SEND_MESSAGE,
                userId: sentToUser,
                currentTime: chatTime,
                option: {
                  message: chatMessage,
                  send_to_class: 1,
                  send_user_id: userId,
                  send_user_name: userName,
                  class_id: classId,
                  send_handle_name: handleName,
                  session_id: store.getState().user.session_id,
                },
                roomId: lectureChannelId,
                classId: classId,
                sessionId: localStorage.getItem("session_id")
                  ? localStorage.getItem("session_id")
                  : null,
              });
              send_to_class = 1;
            }
            break;

          case 2:
            list = JSON.stringify({
              actionId: actionComand.MSG_CHAT_SEND_MESSAGE,
              userId: streamerInfo.streamerId,
              currentTime: chatTime,
              option: {
                message: chatMessage,
                send_to_class: 1,
                send_user_id: userId,
                send_user_name: userName,
                send_handle_name: handleName,
                class_id: classId,
                session_id: store.getState().user.session_id,
              },
              roomId: lectureChannelId,
              classId: classId,
              sessionId: localStorage.getItem("session_id")
                ? localStorage.getItem("session_id")
                : null,
            });
            send_to_class = 1;
            break;

          case 4:
            switch (sentToUser) {
              case streamerInfo.streamerId:
                list = JSON.stringify({
                  actionId: actionComand.MSG_CHAT_SEND_MESSAGE,
                  userId: sentToUser,
                  currentTime: chatTime,
                  option: {
                    message: chatMessage,
                    send_to_class: 1,
                    send_user_id: userId,
                    send_user_name: userName,
                    send_handle_name: handleName,
                    class_id: classId,
                    session_id: store.getState().user.session_id,
                  },
                  roomId: lectureChannelId,
                  classId: classId,
                  sessionId: localStorage.getItem("session_id")
                    ? localStorage.getItem("session_id")
                    : null,
                });
                send_to_class = 1;
                break;

              case "all":
              case null:
                list = JSON.stringify({
                  actionId: actionComand.MSG_CHAT_SEND_MESSAGE,
                  userId: sentToUser,
                  currentTime: chatTime,
                  option: {
                    message: chatMessage,
                    send_to_class: null,
                    send_user_id: userId,
                    send_user_name: userName,
                    send_handle_name: handleName,
                    class_id: classId,
                    session_id: store.getState().user.session_id,
                  },
                  roomId: lectureChannelId,
                  classId: classId,
                  sessionId: localStorage.getItem("session_id")
                    ? localStorage.getItem("session_id")
                    : null,
                });
                send_to_class = null;
                break;

              default:
                list = JSON.stringify({
                  actionId: actionComand.MSG_CHAT_SEND_MESSAGE,
                  userId: sentToUser,
                  currentTime: chatTime,
                  option: {
                    message: chatMessage,
                    send_to_class: 2,
                    send_user_id: userId,
                    send_user_name: userName,
                    send_handle_name: handleName,
                    class_id: classId,
                    session_id: store.getState().user.session_id,
                  },
                  roomId: lectureChannelId,
                  classId: classId,
                  sessionId: localStorage.getItem("session_id")
                    ? localStorage.getItem("session_id")
                    : null,
                });
                send_to_class = 2;
            }
            break;
        }

        channelRTM
          ?.sendMessage({
            text: JSON.stringify({
              action: "MESSAGE_SEND",
              message: list,
              roomId: lectureChannelId,
            }),
          })
          .then(() => {
            let nowSec =
              startTime && Number(startTime) > 0
                ? Math.floor(Number(nowTime - startTime) / 1000)
                : 0;
            ST0323(
              dispatch,
              classId,
              chatTime,
              sentToUser ? sentToUser : "",
              send_to_class,
              chatMessage,
              String(Number(ext + nowSec)),
              sentToUser
            );
            // socket.send(
            //   JSON.stringify({
            //     action: "MESSAGE_SEND",
            //     message: list,
            //     roomId: lectureChannelId,
            //   })
            // );
            let msgInsert: any = document.getElementsByClassName("msg-insert");
            for (let index = 0; index < msgInsert.length; index++) {
              let div = document.createElement("div");
              div.setAttribute("class", "msg-sends");
              let span = document.createElement("span");
              if (sentToUser || chatSendControlType === 2) {
                span.style.color = "red";
              }
              span.append(handleName + ":");
              let messages = chatMessage.split("\n");
              for (let index = 0; index < messages.length; index++) {
                if (index > 0) span.append(document.createElement("br"));
                span.append(messages[index]);
              }
              div.append(span);

              msgInsert[index].append(div);
              if (
                msgInsert &&
                msgInsert[index] &&
                msgInsert[index].clientHeight &&
                msgInsert[index].clientHeight > maxHeightMessageDiv
              ) {
                for (let i = 0; i < 10; i++) {
                  msgInsert[index].children[i].remove();
                  if (msgInsert[index].clientHeight < maxHeightMessageDiv)
                    break;
                }
              }
            }
            const clist = document.getElementsByClassName("chat-box");
            //スクロールバーの位置をリストの最下部に設定
            if (clist && clist.length > 0) {
              clist[0].scrollTop = clist[0].scrollHeight;
              clist[1].scrollTop = clist[1].scrollHeight;
            }
            // const breakedText = chatMessage.split("\n").map((line: any, key: any) => (
            //   <span key={key}>
            //     {line}
            //     <br />
            //   </span>
            // ));
            // setMessage((prev) => [
            //   ...prev,
            //   {
            //     userName: userId ? userId : "",
            //     chatMessageText: breakedText,
            //     chatType: 1,
            //     sendToClass: null,
            //   },
            // ]);
            setChatMessage("");
            chatsedFlg = false;
            setRTMErrFlg(false);
          })
          .catch((err: any) => {
            setRTMErrFlg(true);
            chatsedFlg = false;
            const Log: any[] = [];
            Log.push("chat sendErr");
            Log.push(JSON.stringify(err));
            uploadLog(Log, "_chatSendErr_");
          });
      }
    }
  };

  const messagesEndRef = useRef<null | HTMLDivElement>(null);

  const scrollToBottom = () => {
    if (messagesEndRef && messagesEndRef.current)
      messagesEndRef.current.scrollIntoView();
  };
  useEffect(scrollToBottom, []);
  const [audioSlashFlg, setAudioSlashFlg] = useState(false);
  const [videoSlashFlg, setVideoSlashFlg] = useState(false);
  useEffect(() => {
    if (streamer) {
      let checkMute = setInterval(() => {
        let path = store.getState().router.location.pathname;
        if (endLec || path !== "/cls/012") {
          checkStartLecture = false;
          clearInterval(checkMute);
        }
        if (streamer._audio_muted_) {
          setAudioSlashFlg(false);
        } else {
          setAudioSlashFlg(true);
        }
        if (streamer._video_muted_) {
          setVideoSlashFlg(false);
        } else {
          setVideoSlashFlg(true);
        }
      }, 500);
    }
  }, [streamer]);

  const RTMLoginCheck = async () => {
    let path = store.getState().router.location.pathname;
    if (path === "/cls/012") {
      const nowTime = new Date().getTime();
      if (nowTime > LastChatReceiveTime + 300000) {
        channelRTM
          .getMembers()
          .then((result: any) => {
            setRTMErrFlg(false);
            if (result.find((element: any) => element === userId + 4)) {
              channelRTM
                ?.sendMessage({
                  text: "chatCheck",
                })
                .then(() => {
                  console.log("RTMLoginNow");
                  RTMTimerID = setTimeout(RTMLoginCheck, 300000);
                  setRTMErrFlg(false);
                })
                .catch((err: any) => {
                  setRTMErrFlg(true);
                  console.log(err);
                  if (err.code === 5) {
                    channelRTM?.join();
                    RTMTimerID = setTimeout(RTMLoginCheck, 300000);
                  } else {
                    clientRTM
                      .login({
                        uid: userId + store.getState().user.user_class,
                        token: agoraOption.token_rtm,
                      })
                      .then(() => {
                        channelRTM?.join();
                        RTMTimerID = setTimeout(RTMLoginCheck, 300000);
                        setRTMErrFlg(false);
                      })
                      .catch((e: any) => {
                        setRTMErrFlg(true);
                        const Log: any[] = [];
                        Log.push("RTMReLoginErr");
                        Log.push(JSON.stringify(e));
                        uploadLog(Log, "_RTMReLoginErr_");
                        // alert(DialogText.RTMConectErr);
                      });
                  }
                });
            } else {
              channelRTM?.join();
              RTMTimerID = setTimeout(RTMLoginCheck, 300000);
            }
          })
          .catch((err: any) => {
            console.log(err);
            setRTMErrFlg(true);
            if (err.code === 5) {
              channelRTM?.join();
              RTMTimerID = setTimeout(RTMLoginCheck, 300000);
            } else {
              clientRTM
                .login({
                  uid: userId + store.getState().user.user_class,
                  token: agoraOption.token_rtm,
                })
                .then(() => {
                  channelRTM?.join();
                  RTMTimerID = setTimeout(RTMLoginCheck, 300000);
                })
                .catch((e: any) => {
                  const Log: any[] = [];
                  Log.push("RTMReLoginErr");
                  Log.push(JSON.stringify(e));
                  uploadLog(Log, "_RTMReLoginErr_");
                  // alert(DialogText.RTMConectErr);
                });
            }
          });
      } else {
        RTMTimerID = setTimeout(RTMLoginCheck, 300000);
      }
    }
  };

  // アクティブ視聴ユーザ取得
  const getViewUser = async () => {
    let getData = await ST0318(
      dispatch,
      classId,
      process.env.REACT_APP_AGORA_REC_FLG === "1" ||
        process.env.REACT_APP_AGORA_REC_FLG === "2"
        ? "1"
        : "2"
    );
    if (getData && getData.body.length >= 0) {
      joinList = getData.body.filter(
        (element: any) => element.slice(-1) !== "3"
      );
      const stg = serviceId + "_" + classId;
      for (let i = 0; i < Users.length; i++) {
        const userId = Users[i].user_id;
        const find = joinList.find(
          (element: any) => element === stg + userId + 4
        );
        if (!find) {
          Users[i].status = USER_STATUS.EXIT;
        } else {
          if (Users[i].status === USER_STATUS.EXIT) {
            Users[i].status = USER_STATUS.JOIN;
          }
        }
      }
    } else if (getData.error_code === "01001") {
      endFlg = true;
      if (socket && socket.readyState === WebSocket.OPEN) {
        socket.close();
        channelRTM?.leave();
        clientRTM?.logout();
      }
      if (micTrack) {
        micTrack.stop();
        micTrack.close();
      }
      if (videoTrack) {
        videoTrack.stop();
        videoTrack.close();
      }
      if (screenIsSmall) {
        exitFullScreen(true);
      }
      await leave();

      endLec = true;
      makeLineFlg = true;
      addLineFlg = true;
      lineData = {};
      lineOrder = [];
      FabCanvas.clear();
      lineList = {};
      shapeList = {};
      sendReBack = false;
      makeLineFlg = false;
      addLineFlg = false;
      //viewLogState = viewLogStatus.outService;
      if (!recordInterval) clearInterval(recordInterval);
      //if (!sessionInterval) clearInterval(sessionInterval);
      if (process.env.REACT_APP_AWS_S3_UPLOG === "true")
        consoleLogOut(null, null, true);
      localStorage.removeItem("Cls012_session");
      dispatch(signOut());
    }
    getViewUserTimeout = setTimeout(getViewUser, 60000);
  };
  const [megaBps, setMegaBps] = useState(0);
  const NetWorkSpeed = async () => {
    // 回線速度を計算
    const imageBytes = 30000;
    const imageBits = imageBytes * 8;
    let startTime = new Date().getTime();
    let endTime = null;
    let image = new Image();
    image.onload = () => {
      endTime = new Date().getTime();
      const diffSeconds =
        endTime - startTime === 0 ? 0.5 / 1000 : (endTime - startTime) / 1000;
      const megaBps = parseFloat(
        (imageBits / diffSeconds / (1024 * 1024)).toFixed(2)
      );
      image.src = "";
      summegaBps = summegaBps + megaBps;
      speedcount++;
      if (speedcount < 10) {
        setTimeout(NetWorkSpeed, 500);
      } else {
        const viewMegaBps = summegaBps / speedcount;
        setMegaBps(Math.round(viewMegaBps * 10) / 10);
        speedcount = 0;
        summegaBps = 0;
        setTimeout(NetWorkSpeed, 60000);
      }
    };
    image.src = "/img/3ue--Live.png?" + startTime;
  };

  //ボード資料取得処理
  const getImagesData = async (callback?: any) => {
    imgLoadFlg = false;
    let getData = await CT0307(dispatch, classId);
    if (getData && getData.body.length >= 0) {
      imageRows = getData.body;
      imageDatas = {};
      for (let index = 0; index < imageRows.length; index++) {
        imageDatas[imageRows[index].board_document_id] = imageRows[index].s3_id;
      }
      // let S3 = await createS3();
      // if (S3) {
      //   for (let index = 0; index < imageRows.length; index++) {
      //     const element = imageRows[index];
      //     let file_data = await getFileBase64S3(S3, element.s3_id);
      //     if (file_data) {
      //       imageRows[index].file_data = file_data;
      //     }
      //   }
      //   imgLoadFlg = true;
      // }
    }
    imgLoadFlg = true;
    if (callback) callback();
  };

  //動画資料取得処理
  // const getVdosData = async (callback: any) => {
  //   videoRows = {};
  //   // videoId = "";
  //   let getData = await CT0308(dispatch, classId);
  //   if (getData && Object.keys(getData).indexOf("body") >= 0) {
  //     getData.body.forEach(async (data: any) => {
  //       videoRows[data.video_id] = data.s3_id;
  //       // let S3 = await createS3();
  //       // if (S3) {
  //       //   getFileBase64S3(S3, filePath + "movie/" + data.video_name).then(
  //       //     (file: any) => {
  //       //       if (file && file.length > 0) {
  //       //         videoRows[data.video_id] = file.replace(
  //       //           "data:application/octet-stream;base64,",
  //       //           "data:video/mp4;base64,"
  //       //         );
  //       //       }
  //       //     }
  //       //   );
  //       // }
  //     });
  //   }
  //   if (callback) callback();
  // };

  // const agoraStartJoin = () => {
  //   try {
  //     join(agoraOption.appID, agoraOption.channel, agoraOption.token, 0);
  //   } catch (e: any) {

  //   }
  // };
  const camTrackErr = FormatMessage({ id: "Cls012.camera.err" });
  const micTrackErr = FormatMessage({ id: "Cls012.mic.err" });

  const agoraJoinLecHost = async () => {
    try {
      let track = await setDevice("all");
      if (track.micTrack && track.camTrack) {
        await client.setClientRole("host");
        await publish(track.micTrack, track.camTrack);
        setCheckLocalVideo(true);
        clickJoinLec(true);
      } else if (retryCount <= 5) {
        retryCount++;
        setTimeout(agoraJoinLecHost, 2000);
      } else {
        retryCount = 0;
        clickJoinLec(false);
        alert(camTrackErr);
      }
    } catch (e: any) {
      console.log(
        "映像授業参加AgoraPublish---ERR_CODE:",
        e.code,
        "/ERR_LOG:",
        e
      );
      clickJoinLec(false);
    }
  };

  const agoraJoinLecVoice = async () => {
    try {
      let track = await setDevice("voice");
      if (track.micTrack) {
        //track.camTrack.setMuted(true).then(() => {
        await client.setClientRole("host");
        publish(track.micTrack);
        //});
        voiceJoinFlg ? clickJoinVoice(true) : clickJoinLecVoice(true);
      } else if (retryCount <= 5) {
        retryCount++;
        setTimeout(agoraJoinLecVoice, 2000);
      } else {
        retryCount = 0;
        voiceJoinFlg ? clickJoinVoice(false) : clickJoinLecVoice(false);
        alert(micTrackErr);
      }
    } catch (e: any) {
      voiceJoinFlg ? clickJoinVoice(false) : clickJoinLecVoice(false);
    }
  };

  const voiceJoin = async (flg: boolean) => {
    voiceJoinFlg = flg;
    agoraJoinLecVoice();
  };

  const winResize = () => {
    if (noResize) return;
    let window_H = document.getElementsByTagName("body")[0].offsetHeight;
    let window_W = document.getElementsByTagName("body")[0].offsetWidth;
    let whiteBoard = document.getElementsByClassName(
      "whiteBoard"
    ) as HTMLCollectionOf<HTMLElement>;

    if (refScreenIsSmall.current) {
      let nw = 0;
      if (window_H > window_W && window_W < 768) {
        nw = window_W - 10;
        whiteBoard[0].style.width = nw + "px";
        whiteBoard[0].style.height = nw / (16 / 9) + "px";
      } else {
        setAllWidth(false);
        let videoW = window_W - 10;
        nw = Math.ceil((window_H - 25) * (16 / 9));
        if (nw > videoW) {
          nw = videoW;
        }
        whiteBoard[0].style.width = nw + "px";
        whiteBoard[0].style.height = nw / (16 / 9) + "px";
      }
    } else {
      if (window_H > window_W && window_W < 768) {
        setAllWidth(true);
        let nw = window_W - 6;
        let nh = nw / (16 / 9);
        whiteBoard[0].style.width = nw + "px";
        whiteBoard[0].style.height = nh + "px";
        let chatBox = document.getElementsByClassName(
          "chat-box"
        ) as HTMLCollectionOf<HTMLElement>;
        let sendBox = document.getElementsByClassName(
          "send_box"
        ) as HTMLCollectionOf<HTMLElement>;
        if (chatBox && chatBox.length > 0 && sendBox && sendBox.length > 0) {
          let ndh = Math.ceil(
            window_H - Number(nh * 2) - Number(sendBox[1].offsetHeight)
          );
          chatBox[1].style.height = String(ndh > 70 ? ndh : 70) + "px";
        }
      } else {
        setAllWidth(false);
        let chatBox = document.getElementsByClassName(
          "chat-box"
        ) as HTMLCollectionOf<HTMLElement>;
        let leftTable = document.getElementById("left_table");
        let videoW = window_W - (leftTable ? leftTable.offsetWidth : 0) - 10;
        let nw = Math.ceil((window_H - 0) * (16 / 9));
        let cameraDiv = document.getElementsByClassName(
          "leftTable"
        ) as HTMLCollectionOf<HTMLElement>;
        let sendBox = document.getElementsByClassName(
          "send_box"
        ) as HTMLCollectionOf<HTMLElement>;

        if (
          chatBox &&
          chatBox.length > 0 &&
          cameraDiv &&
          cameraDiv.length > 0 &&
          sendBox &&
          sendBox.length > 0
        ) {
          let newHight = Math.ceil(
            window_H -
              Number(cameraDiv[0].offsetHeight) -
              Number(sendBox[0].offsetHeight) -
              2
          );
          chatBox[0].style.height = newHight > 70 ? newHight + "px" : "70px";
        }

        if (nw > videoW) nw = videoW;

        if (whiteBoard && whiteBoard.length > 0) {
          whiteBoard[0].style.width = nw + "px";
          whiteBoard[0].style.height = nw / (16 / 9) + "px";
        }
      }
    }

    if (whiteBoard && whiteBoard.length > 0) {
      var width = whiteBoard[0].style.width.replace("px", "");
      var height = whiteBoard[0].style.height.replace("px", "");
      setCanvasWide(width);
      setCanvasHeight(height);
      canvasData = { w: width, h: height };
      let upperCanvas = document.getElementsByClassName(
        "upper-canvas"
      ) as HTMLCollectionOf<HTMLElement>;
      let canvasContainer = document.getElementsByClassName(
        "canvas-container"
      ) as HTMLCollectionOf<HTMLElement>;
      if (upperCanvas.length > 0) {
        upperCanvas[0].style.width = width + "px";
        upperCanvas[0].style.height = height + "px";
        canvasContainer[0].style.width = width + "px";
        canvasContainer[0].style.height = height + "px";
        FabCanvas.setWidth(Number(width));
        FabCanvas.setHeight(Number(height));
        GraphFabCanvas.setWidth(Number(width));
        GraphFabCanvas.setHeight(Number(height));
        zoom = Number(width) / defaultWidth;
        FabCanvas.setZoom(Math.abs(zoom * 100) / 100);
        GraphFabCanvas.setZoom(Math.abs(zoom * 100) / 100);
        // comingImgCommand();
        // if (Number(expantionData.scale) > 1) {
        //   setExpantion(expantionData.scale, () => {
        //     if (
        //       expantionData.point.scrollTop > 0 &&
        //       expantionData.point.scrollLeft > 0
        //     ) {
        //       drowCanvasDiv.scrollTop =
        //         expantionData.point.scrollTop * maxDivScrollTop;
        //       drowCanvasDiv.scrollLeft =
        //         expantionData.point.scrollLeft * maxDivScrollLeft;
        //       // imgCanvasDiv.scrollTop =
        //       //   expantionData.point.scrollTop * maxDivScrollTop;
        //       // imgCanvasDiv.scrollLeft =
        //       //   expantionData.point.scrollLeft * maxDivScrollLeft;
        //     }
        //   });
        // }
      }
      let playerWrapper: any =
        document.getElementsByClassName("player-wrapper");
      let inge: any = document.getElementById("ing");
      let inge2: any = document.getElementById("ing2");
      if (streamIsAllDisplay) {
        if (playerWrapper && playerWrapper.length > 0) {
          playerWrapper[0].style.width = window_W + "px";
          playerWrapper[0].style.height = window_H + "px";
          playerWrapper[0].style.position = "fixed";
          playerWrapper[0].style.top = "0px";
          playerWrapper[0].style.left = "0px";
          playerWrapper[0].style.zIndex = "1";
        }
        if (inge) {
          inge.style.marginTop = "0px";
          inge.style.position = "absolute";
          inge.style.height = window_H + "px";
          inge.style.top = "0px";
          inge.style.width = "10px";
        }
        if (inge2) {
          //inge.style.marginTop = -window_H + "px";
          inge2.style.height = window_H + "px";
          inge2.style.marginTop = "0px";
          inge2.style.position = "absolute";
          inge2.style.top = "0px";
          inge2.style.width = "10px";
        }
      } else {
        if (window_H > window_W && window_W < 768) {
          let nw = window.outerWidth - 2;
          let nh = nw * (9 / 16);
          if (playerWrapper && playerWrapper.length > 0) {
            playerWrapper[0].style.width = nw + "px";
            playerWrapper[0].style.height = nh + "px";
            playerWrapper[0].style.position = "inherit";
            playerWrapper[0].style.top = "0px";
            playerWrapper[0].style.left = "0px";
            playerWrapper[0].style.zIndex = "1";
          }
          if (inge2) {
            inge2.style.marginTop = -nh + "px";
            inge2.style.height = nh + "px";
            inge2.style.position = "relative";
            inge2.style.width = "5px";
          }
        } else {
          if (playerWrapper && playerWrapper.length > 0) {
            playerWrapper[0].style.width = "294px";
            playerWrapper[0].style.height = "164px";
            playerWrapper[0].style.position = "inherit";
            playerWrapper[0].style.top = "42px";
            playerWrapper[0].style.left = "0px";
            playerWrapper[0].style.zIndex = "1";
          }
          if (inge) {
            inge.style.marginTop = "-164px";
            inge.style.height = "164px";
            inge.style.position = "relative";
            inge.style.width = "5px";
          }
        }
      }
    }
  };

  const checkDeviceType = () => {
    var ua = navigator.userAgent.toLowerCase();
    if (
      ua.indexOf("ipad") > 0 ||
      (ua.indexOf("macintosh") > 0 && "ontouchend" in document)
    ) {
      deviceType = "NoPC";
    } else if (
      ua.indexOf("iphone") > 0 ||
      ua.indexOf("android") > 0 ||
      ua.indexOf("mobile") > 0
    ) {
      deviceType = "Phone";
    } else {
      deviceType = "PC";
    }
  };

  const requestFullScreen = async () => {
    setStreamIsSmall(true);
    setscreenIsSmall(true);
    if (deviceType === "PC") {
      if (document.body.webkitRequestFullscreen) {
        await document.body.webkitRequestFullscreen(); //Chrome15+, Safari5.1+, Opera15+
      } else if (document.body.requestFullscreen) {
        await document.body.requestFullscreen(); // HTML5 Fullscreen API仕様
      }
    }
    streamIsAllDisplay = true;
    let window_H = document.getElementsByTagName("body")[0].offsetHeight;
    let window_W = document.getElementsByTagName("body")[0].offsetWidth;
    let playerWrapper: any = document.getElementsByClassName("player-wrapper");
    if (streamIsAllDisplay) {
      if (playerWrapper && playerWrapper.length > 0) {
        playerWrapper[0].style.width = window_W + "px";
        playerWrapper[0].style.height = window_H + "px";
        playerWrapper[0].style.position = "fixed";
        playerWrapper[0].style.top = "0px";
        playerWrapper[0].style.left = "0px";
        playerWrapper[0].style.zIndex = "1";
      }
    }

    allClose("");
    setTimeout(() => winResize(), 100);
  };
  const exitFullScreen = (resizeFlg: boolean) => {
    setStreamIsSmall(true);
    streamIsAllDisplay = false;
    setscreenIsSmall(false);
    let playerWrapper: any = document.getElementsByClassName("player-wrapper");
    let inge: any = document.getElementById("ing");
    let inge2: any = document.getElementById("ing2");
    let window_H = document.getElementsByTagName("body")[0].offsetHeight;
    let window_W = document.getElementsByTagName("body")[0].offsetWidth;
    if (!streamIsAllDisplay) {
      if (window_H > window_W && window_W < 768) {
        let nw = window.outerWidth - 2;
        let nh = nw * (9 / 16);
        if (playerWrapper && playerWrapper.length > 0) {
          playerWrapper[0].style.width = nw - 10 + "px";
          playerWrapper[0].style.height = nh + "px";
          playerWrapper[0].style.position = "inherit";
          playerWrapper[0].style.top = "0px";
          playerWrapper[0].style.left = "0px";
          playerWrapper[0].style.zIndex = "1";
        }
        if (inge2) {
          inge2.style.marginTop = -nh + "px";
          inge2.style.height = nh + "px";
          inge2.style.position = "relative";
          inge2.style.width = "5px";
        }
        resizeFlg = false;
      } else {
        if (playerWrapper && playerWrapper.length > 0) {
          playerWrapper[0].style.width = "294px";
          playerWrapper[0].style.height = "164px";
          playerWrapper[0].style.position = "inherit";
          playerWrapper[0].style.top = "42px";
          playerWrapper[0].style.left = "0px";
          playerWrapper[0].style.zIndex = "1";
        }
        if (inge) {
          inge.style.marginTop = "-164px";
          inge.style.height = "164px";
          inge.style.position = "relative";
          inge.style.width = "5px";
        }
      }
    }
    if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.exitFullscreen) {
      document.exitFullscreen(); // HTML5 Fullscreen API仕様
    }
    setMenuSFlg(false);
    allClose("");
    const clist = document.getElementsByClassName("chat-box");
    if (clist && clist.length > 0) {
      clist[0].scrollTop = clist[0].scrollHeight;
      clist[1].scrollTop = clist[1].scrollHeight;
    }
    if (resizeFlg) {
      setTimeout(() => winResize(), 100);
    }
  };

  //全画面表示切替時
  const screenChange = () => {
    if (
      (document.fullscreenElement !== undefined &&
        document.fullscreenElement !== null) || // HTML5 標準
      (document.mozFullScreenElement !== undefined &&
        document.mozFullScreenElement !== null) || // Firefox
      (document.webkitFullscreenElement !== undefined &&
        document.webkitFullscreenElement !== null) || // Chrome・Safari
      (document.msFullscreenElement !== undefined &&
        document.msFullscreenElement !== null)
    ) {
      // IE・Edge Legacy
    } else {
      exitFullScreen(true);
    }
  };
  const [allVideoShowFlg, setAllVideoShowFlg] = useState(false);
  const [allAudioShowFlg, setAllAudioShowFlg] = useState(false);
  const requestFullStream = () => {
    setStreamIsSmall(false);
    streamIsAllDisplay = true;
    let window_H = document.getElementsByTagName("body")[0].offsetHeight;
    let window_W = document.getElementsByTagName("body")[0].offsetWidth;
    let playerWrapper: any = document.getElementsByClassName("player-wrapper");
    if (streamIsAllDisplay) {
      if (playerWrapper && playerWrapper.length > 0) {
        playerWrapper[0].style.width = window_W + "px";
        playerWrapper[0].style.height = window_H + "px";
        playerWrapper[0].style.position = "fixed";
        playerWrapper[0].style.top = "0px";
        playerWrapper[0].style.left = "0px";
        playerWrapper[0].style.zIndex = "1";
      }
    }
    let inge: any = document.getElementById("ing");
    if (inge) {
      //inge.style.marginTop = -window_H + "px";
      inge.style.height = window_H + "px";
      inge.style.marginTop = "0px";
      inge.style.position = "absolute";
      inge.style.top = "0px";
      inge.style.width = "10px";
    }
    let inge2: any = document.getElementById("ing2");
    if (inge2) {
      //inge.style.marginTop = -window_H + "px";
      inge2.style.height = window_H + "px";
      inge2.style.marginTop = "0px";
      inge2.style.position = "absolute";
      inge2.style.top = "0px";
      inge2.style.width = "10px";
    }
    setAllVideoShowFlg(true);
    setAllAudioShowFlg(true);
  };
  const exitFullStream = () => {
    setStreamIsSmall(true);
    streamIsAllDisplay = false;
    let playerWrapper: any = document.getElementsByClassName("player-wrapper");
    let inge: any = document.getElementById("ing");
    let inge2: any = document.getElementById("ing2");
    let window_H = document.getElementsByTagName("body")[0].offsetHeight;
    let window_W = document.getElementsByTagName("body")[0].offsetWidth;
    if (!streamIsAllDisplay) {
      if (window_H > window_W && window_W < 768) {
        let nw = window.outerWidth - 2;
        let nh = nw * (9 / 16);
        if (playerWrapper && playerWrapper.length > 0) {
          playerWrapper[0].style.width = nw - 10 + "px";
          playerWrapper[0].style.height = nh + "px";
          playerWrapper[0].style.position = "inherit";
          playerWrapper[0].style.top = "0px";
          playerWrapper[0].style.left = "0px";
          playerWrapper[0].style.zIndex = "1";
        }
        if (inge2) {
          inge2.style.marginTop = -nh + "px";
          inge2.style.height = nh + "px";
          inge2.style.position = "relative";
          inge2.style.width = "5px";
        }
      } else {
        if (playerWrapper && playerWrapper.length > 0) {
          playerWrapper[0].style.width = "294px";
          playerWrapper[0].style.height = "164px";
          playerWrapper[0].style.position = "inherit";
          playerWrapper[0].style.top = "42px";
          playerWrapper[0].style.left = "0px";
          playerWrapper[0].style.zIndex = "1";
        }
        if (inge) {
          inge.style.marginTop = "-164px";
          inge.style.height = "164px";
          inge.style.position = "relative";
          inge.style.width = "5px";
        }
      }
      setAllVideoShowFlg(false);
      setAllAudioShowFlg(false);
    }
    //setTimeout(() => winResize(), 100);
  };

  //チャット一覧表
  const [anchorEl, setAnchorEl] = useState(null);

  function getImagefromCanvasNoPC(canvasSample: any) {
    var canvasG: any = document.getElementById(canvasSample);
    if (canvasG) {
      return new Promise((resolve, reject) => {
        var ctx = canvasG.getContext("2d");
        const image = new Image();
        image.onload = () => resolve(image);
        image.onerror = (e) => reject(e);
        image.src = ctx.canvas.toDataURL();
      });
    } else {
      return false;
    }
  }

  function getImagefromCanvas(canvasSample: any) {
    var canvasG: any = document.getElementById(canvasSample);
    if (canvasG) {
      return new Promise((resolve, reject) => {
        var width = Number(canvasData.w);
        var height = Number(canvasData.h);
        ctxC.clearRect(0, 0, width, height);
        var ctx = canvasG.getContext("2d");
        var imgData;
        if (expantionData.scale > 1) {
          imgData = ctx.getImageData(
            drowCanvasDiv.scrollLeft,
            drowCanvasDiv.scrollTop,
            drowCanvasDiv.scrollLeft + width,
            drowCanvasDiv.scrollTop + height
          );
        } else {
          imgData = ctx.getImageData(0, 0, ctx.canvas.width, ctx.canvas.height);
        }
        ctxC.putImageData(imgData, 0, 0);
        const image = new Image();
        image.onload = () => resolve(image);
        image.onerror = (e) => reject(e);
        image.src = ctxC.canvas.toDataURL();
      });
    } else {
      return false;
    }
  }

  const screenShotOnClick = async () => {
    let file: any;
    let url: any;
    var userAgent = window.navigator.userAgent.toLowerCase();
    if (shareWindow.length > 0) {
      ctxC.clearRect(0, 0, ctxC.canvas.clientWidth, ctxC.canvas.clientHeight);
      let shareVideo = document.getElementById(
        "video_" + shareWindow[0].videoTrack._ID
      ) as any;
      ctxC.canvas.height = shareVideo?.videoHeight;
      ctxC.canvas.width = shareVideo?.videoWidth;
      ctxC.drawImage(
        shareVideo,
        0,
        0,
        shareVideo?.videoWidth,
        shareVideo?.videoHeight
      );
      file = canvasConcat.toDataURL();
      url = file;
    } else {
      ctxC.canvas.height = ctx.canvas.height;
      ctxC.canvas.width = ctx.canvas.width;
      if (
        userAgent.indexOf("android") > 0 ||
        userAgent.indexOf("iphone") > 0 ||
        userAgent.indexOf("ipad") > 0 ||
        userAgent.indexOf("mobile") > 0 ||
        userAgent.indexOf("mac") > 0 ||
        (userAgent.indexOf("macintosh") > 0 && "ontouchend" in document)
      ) {
        let getfile = await getImagefromCanvasNoPC("canvas");
        var width = Number(canvasData.w);
        var height = Number(canvasData.h);
        ctxC.clearRect(0, 0, width, height);
        if (expantionData.scale > 1) {
          ctxC.drawImage(
            getfile,
            drowCanvasDiv.scrollLeft * 2,
            drowCanvasDiv.scrollTop * 2,
            width * 2,
            height * 2,
            0,
            0,
            ctxC.canvas.width,
            ctxC.canvas.height
          );
        } else {
          ctxC.drawImage(
            getfile,
            0,
            0,
            ctx.canvas.width,
            ctx.canvas.height,
            0,
            0,
            ctxC.canvas.width,
            ctxC.canvas.height
          );
        }
        file = await getImagefromCanvasNoPC("canvasConcat");
      } else {
        file = await getImagefromCanvas("canvas");
      }
      url = file.src;
    }
    if (url) {
      let fileName;

      if (
        userAgent.indexOf("android") > 0 ||
        userAgent.indexOf("iphone") > 0 ||
        userAgent.indexOf("mobile") > 0
      ) {
        fileName = "download_" + className;
      } else {
        fileName = "download_" + className + ".jpg";
      }
      saveAs(url, fileName);
    }
    captureFlg = false;
  };

  const allClose = (id: string) => {
    if (id !== "name") {
      setName(null);
      setNameOpen(false);
    }
    if (id !== "penSet") {
      setPenSet(null);
      setPenSetOpen(false);
    }
    if (id !== "shapeSet") {
      setShapeSet(null);
      setShapeSetOpen(false);
    }
  };

  //指名一覧表
  const [name, setName] = useState(null);
  const [nameOpen, setNameOpen] = useState(false);
  const nameClick = (event: any) => {
    if (name) {
      setName(null);
      setNameOpen(false);
    } else {
      setName(event.currentTarget);
      setNameOpen(true);
      allClose("name");
    }
  };

  //pen設定一覧表
  const [penSet, setPenSet] = useState(null);
  const [penSetOpen, setPenSetOpen] = useState(false);
  const penSetClick = (event: any) => {
    if (!penSet) {
      setPenSet(event.currentTarget);
    } else {
      setPenSet(null);
    }
  };
  const openPen = Boolean(penSet);

  //図形設定一覧表
  const [shapeSet, setShapeSet] = useState(null);
  const [shapeSetOpen, setShapeSetOpen] = useState(false);
  const shapeSetClick = (event: any) => {
    addBrushOff();
    if (!shapeSet) {
      setShapeSet(event.currentTarget);
    } else {
      setShapeSet(null);
    }
  };
  const openShape = Boolean(shapeSet);

  // const changeHeaderColor = (event: {
  //   target: { value: React.SetStateAction<string> };
  // }) => {
  //   setHeaderColor(event.target.value);
  // };

  const whiteBClick = () => {
    if (screenIsSmall) {
      if (menuSFlg) {
        setMenuSFlg(false);
      } else {
        setMenuSFlg(true);
      }
    }
  };

  const getDateTime = () => {
    let nt = new Date();
    return (
      nt.getFullYear() +
      "/" +
      ("00" + (nt.getMonth() + 1)).slice(-2) +
      "/" +
      ("00" + nt.getDate()).slice(-2) +
      " " +
      ("00" + nt.getHours()).slice(-2) +
      ":" +
      ("00" + nt.getMinutes()).slice(-2) +
      ":" +
      ("00" + nt.getSeconds()).slice(-2)
    );
  };

  // 視聴ログ更新
  /*const registViewLog = async (minute: any, lecFlg: string) => {
    if (!captureFlg) {
      ST0311(
        dispatch,
        classId,
        minute,
        getDateTime(),
        "0",
        lecFlg,
        String(agoraUserId)
      );
    } else {
      setTimeout(registViewLog, 2000, minute, lecFlg);
    }
  };*/

  const sameDoing = () => {
    if (
      !boradChangeFlg &&
      !boradImgChangeFlg &&
      !onWrittenFlg &&
      sendReBack &&
      sendReData &&
      sendReData.json &&
      sendReData.Key &&
      !captureFlg
    ) {
      createS3().then((s3) => {
        if (s3) {
          var file_name =
            "haishin/" +
            serviceId +
            "/" +
            classId +
            "/board_" +
            selectBoardNo +
            "/" +
            "canvas_" +
            selectBoardNo +
            ".json";
          console.log("同期JSONファイル取得 sameDoing path:", file_name);
          getListS3(s3, file_name).then(async (list: any) => {
            if (
              list &&
              list.length > 0 &&
              sendReData &&
              sendReData.Key &&
              file_name === sendReData.Key &&
              sendReData.selectBoardNo &&
              sendReData.selectBoardNo === selectBoardNo &&
              new Date(list[0].LastModified).getTime() ===
                sendReData.LastModified
            ) {
              let backData = sendReData.json;
              sendReData = null;
              const shapeChange = (data: any) => {
                let shapeList: any;
                if (data.type === "rect") {
                  shapeList = new fabric.Rect(data);
                }
                if (data.type === "line") {
                  shapeList = new fabric.Line(
                    [data.x1, data.y1, data.x2, data.y2],
                    data
                  );
                }
                if (data.type === "circle") {
                  shapeList = new fabric.Circle(data);
                }
                if (data.type === "triangle") {
                  shapeList = new fabric.Triangle(data);
                }
                if (shapeList) {
                  FabCanvas.add(shapeList);
                }
              };

              if (sendReBack && backData.json) {
                reloop = true;
                addLineFlg = true;
                makeLineFlg = true;
                let changeFlg = false;
                if (Number(backData.scale) > 1) {
                  expantionData.scale = Number(backData.scale);
                } else {
                  expantionData.scale = 1;
                }
                if (backData.img_no && Number(backData.img_no) > 0) {
                  imgCmdId = String(backData.img_no);
                } else {
                  imgCmdId = "-1";
                }
                var newObject = [];
                var nowObject = FabCanvas.getObjects();
                let checkBackGroundImage = false;
                for (let a = 0; a < backData.json.objects.length; a++) {
                  if (backData.json.objects[a].type !== "group") {
                    newObject.push(
                      backData.json.objects[a].globalCompositeOperation
                    );
                    if (backData.json.objects[a].type === "path") {
                      let id =
                        backData.json.objects[a].globalCompositeOperation;
                      let data = backData.json.objects[a];
                      let checkLine = false;
                      for (let index = 0; index < nowObject.length; index++) {
                        if (nowObject[index].globalCompositeOperation === id) {
                          checkLine = true;
                          const before = nowObject[index].toObject();
                          const after = backData.json.objects[a];
                          after.selectable = false;
                          if (id.indexOf(userId + userNo) < 0)
                            after.evented = false;
                          if (
                            JSON.stringify(before) !== JSON.stringify(after)
                          ) {
                            FabCanvas.remove(nowObject[index]);
                            let newLine: any = new fabric.Path(
                              data.path,
                              after
                            );
                            FabCanvas.add(newLine);
                            FabCanvas.requestRenderAll();
                            newLine = null;
                          }
                          break;
                        }
                      }
                      if (!checkLine) {
                        let newLine: any = new fabric.Path(data.path, data);
                        FabCanvas.add(newLine);
                        FabCanvas.requestRenderAll();
                        newLine = null;
                      }
                      id = "";
                      data = null;
                    } else if (
                      backData.json.objects[a].globalCompositeOperation ===
                      "back_ground_color"
                    ) {
                      for (let index = 0; index < nowObject.length; index++) {
                        if (
                          nowObject[index].globalCompositeOperation ===
                          "back_ground_color"
                        ) {
                          imgBackground = nowObject[index];
                        }
                      }
                      if (imgBackground) {
                        let before = imgBackground;
                        if (before.canvas) before = before.toObject();
                        const after = backData.json.objects[a];
                        if (JSON.stringify(before) !== JSON.stringify(after)) {
                          imgBackground.set(after);
                        }
                      } else {
                        backData.json.objects[a].name = "back_ground_color";
                        imgBackground = new fabric.Rect(
                          backData.json.objects[a]
                        );
                        FabCanvas.add(imgBackground);
                        imgBackground.moveTo(0);
                        changeFlg = true;
                      }
                    } else if (
                      backData.json.objects[a].globalCompositeOperation ===
                        "back_ground_image" &&
                      !boradImgChangeFlg
                    ) {
                      checkBackGroundImage = true;
                      let checkImageData = false;
                      let checkNowImageData;
                      for (let index = 0; index < nowObject.length; index++) {
                        if (
                          nowObject[index].globalCompositeOperation ===
                          "back_ground_image"
                        ) {
                          checkImageData = true;
                          checkNowImageData = nowObject[index];
                          break;
                        }
                      }
                      let fileUrl;
                      if (checkNowImageData && checkImageData) {
                        let before = checkNowImageData;
                        // if (before.canvas) before = before.toObject();
                        const after = backData.json.objects[a];
                        if (
                          before.width !== after.width ||
                          before.height !== after.height ||
                          before.top !== after.top ||
                          before.left !== after.left ||
                          before.src !== after.src
                        ) {
                          if (
                            backData.img_no &&
                            Number(backData.img_no) > 0 &&
                            imageDatas[backData.img_no]
                          ) {
                            const s3 = await createS3();
                            fileUrl = await getUrlS3(
                              s3,
                              imageDatas[backData.img_no]
                            );
                          }
                          if (Number(backData.img_no) === Number(imgCmdId)) {
                            nowImageData.set(after);
                            nowImageData.setSrc(
                              fileUrl ? fileUrl : after.src,
                              function () {
                                FabCanvas.requestRenderAll();
                              },
                              { crossOrigin: "annonymous" }
                            );
                          }
                        }
                      } else {
                        if (
                          backData.img_no &&
                          Number(backData.img_no) > 0 &&
                          imageDatas[backData.img_no] &&
                          Number(backData.img_no) === Number(imgCmdId)
                        ) {
                          const s3 = await createS3();
                          fileUrl = await getUrlS3(
                            s3,
                            imageDatas[backData.img_no]
                          );
                          var imageData: any = backData.json.objects[a];
                          var image = new Image();
                          image.crossOrigin = "Anonymous";
                          image.src = fileUrl ? fileUrl : imageData.src;
                          let checkImgNo = backData.img_no;
                          image.onload = () => {
                            if (
                              sendReData &&
                              sendReData.selectBoardNo &&
                              sendReData.selectBoardNo === selectBoardNo &&
                              checkImgNo === imgCmdId
                            ) {
                              nowImageData = new fabric.Image(image, imageData);
                              FabCanvas.add(nowImageData);
                              nowImageData.moveTo(1);
                              FabCanvas.requestRenderAll();
                              imageData = null;
                            }
                          };
                        }
                      }
                    } else {
                      var id =
                        backData.json.objects[a].globalCompositeOperation;
                      const after = backData.json.objects[a];
                      if (id.indexOf(userId + userNo) < 0) {
                        after.selectable = false;
                        after.evented = false;
                      }
                      let shapeCheck = false;
                      for (let index = 0; index < nowObject.length; index++) {
                        if (nowObject[index].globalCompositeOperation === id) {
                          shapeCheck = true;
                          let before = nowObject[index].toObject();
                          if (
                            JSON.stringify(before) !== JSON.stringify(after)
                          ) {
                            if (nowObject[index].type === "line") {
                              FabCanvas.remove(nowObject[index]);
                              shapeChange(after);
                              changeFlg = true;
                            } else {
                              nowObject[index].set(after);
                            }
                          }
                          break;
                        }
                      }
                      if (!shapeCheck) {
                        shapeChange(after);
                        changeFlg = true;
                      }
                    }
                  }
                }
                for (let index = 0; index < nowObject.length; index++) {
                  const ob = nowObject[index];
                  if (newObject.indexOf(ob.globalCompositeOperation) < 0) {
                    FabCanvas.remove(ob);
                    changeFlg = true;
                  }
                }
                if (!checkBackGroundImage && !boradImgChangeFlg) {
                  for (let index = 0; index < nowObject.length; index++) {
                    if (
                      nowObject[index].globalCompositeOperation ===
                      "back_ground_image"
                    ) {
                      nowImageData = null;
                      FabCanvas.remove(nowObject[index]);
                      changeFlg = true;
                    }
                  }
                }
                setTimeout(() => {
                  if (changeFlg) FabCanvas.requestRenderAll();
                  lineData = {};
                  lineOrder = [];
                  makeLineFlg = false;
                  addLineFlg = false;
                  reloop = false;
                }, 1000);
              }

              backData = null;
            }
          });
        }
      });
    }
  };

  const getSameData = () => {
    if (!boradChangeFlg && !boradImgChangeFlg) {
      var fileReader = new FileReader();
      var file_name =
        "haishin/" +
        serviceId +
        "/" +
        classId +
        "/board_" +
        selectBoardNo +
        "/" +
        "canvas_" +
        selectBoardNo +
        ".json";
      console.log("同期JSONファイル取得 getSameData path:", file_name);
      createS3().then((s3) => {
        if (s3) {
          sendReBack = true;
          getListS3(s3, file_name).then((list: any) => {
            if (list && list.length > 0) {
              getFileS3(s3, file_name).then((file: any) => {
                if (file === undefined || file === null) return;
                sendReData = {
                  Key: list[0].Key,
                  LastModified: new Date(list[0].LastModified).getTime(),
                  selectBoardNo: selectBoardNo,
                  json: null,
                };
                fileReader.onload = function (json: any) {
                  let canvasJson = json.currentTarget.result;
                  if (canvasJson) {
                    sendReData.json = JSON.parse(canvasJson);
                    if (
                      sendReData.json.next_board_no &&
                      Number(sendReData.json.next_board_no) > 0
                    ) {
                      selectBoardNo = sendReData.json.next_board_no;
                      getSameData();
                    } else {
                      if (sendReData.json.save_no >= changeJsonSaveNo)
                        setTimeout(() => sameDoing(), 5000);
                    }
                  }
                };
                fileReader.readAsText(file);
              });
            }
          });
        }
      });
    }
  };

  const userPublish = async (agorauser: any) => {
    if (20000 === agorauser.uid) {
      consoleLogOut("WARN", "userPublish_20000");
      console.log("WARN", "userPublish_20000");
      checkStreamer = true;
      consoleLogOut("WARN", "checkStreamer_true");
      console.log("WARN", "checkStreamer_true");
      leftClass = true;
      //winResize();
    }
  };
  const [userL, setUserL] = useState(true);
  const [UserChange, setUserChange] = useState(true);

  const userJoin = (agorauser: any) => {
    // const joinUser = Users?.find((element) =>
    //   element.user_agoraid.find((element) => element === agorauser.uid)
    // );
    const joinUser =
      userListJson &&
      userListJson["agora"] &&
      userListJson["agora"][agorauser.uid]
        ? userListJson["agora"][agorauser.uid]
        : null;
    if (joinUser) {
      userStatusChange(joinUser?.user_id, USER_STATUS.JOIN);
    }
  };

  const userLeft = async (agorauser: any) => {
    if (20000 === agorauser.uid) {
      consoleLogOut("WARN", "userLeft_20000");
      console.log("WARN", "userLeft_20000");
      if (!classEndFlg) {
        checkStreamer = false;
        consoleLogOut("WARN", "checkStreamer_false");
        console.log("WARN", "checkStreamer_false");
        setTimeout(() => {
          if (!checkStreamer) setErrNotStreamerOpen(true);
        }, 5000);
        setTimeout(() => {
          if (!checkStreamer) {
            if (micTrack) {
              micTrack.stop();
              micTrack.close();
            }
            if (videoTrack) {
              videoTrack.stop();
              videoTrack.close();
            }
            leave().then(() => {
              agoraUserId = "";
              endClass();
            });
          }
        }, 8000);
      }
    } else {
      let uid = agorauser.uid;
      setTimeout(() => {
        let check = client.remoteUsers.find((a: any) => uid === a.uid);
        if (!check) {
          // const leftUser = Users?.find((element) =>
          //   element.user_agoraid.find((element2) => element2 === agorauser.uid)
          // );
          const leftUser =
            userListJson &&
            userListJson["agora"] &&
            userListJson["agora"][agorauser.uid]
              ? userListJson["agora"][agorauser.uid]
              : null;
          if (leftUser) {
            userStatusChange(leftUser?.user_id, USER_STATUS.AGORAEXIT);
          }
        }
      }, 30000);
    }
  };

  const checkClassEndJson = () => {
    try {
      if (!checkStreamer && !captureFlg) {
        createS3().then((s3) => {
          if (s3) {
            getListS3(
              s3,
              "haishin/" + serviceId + "/" + classId + "/doc/"
            ).then((list) => {
              let existJson = false;
              if (list && list.length > 0) {
                for (let index = 0; index < list.length; index++) {
                  const element: any = list[index];
                  if (
                    element &&
                    element.Key &&
                    element.Key.slice(-8) === "end.json"
                  ) {
                    existJson = true;
                  }
                }
              }
              if (!existJson) {
                leaveStreamerCnt++;
                // if (leaveStreamerCnt === 9 && !errNotStreamerOpen) {
                //   setErrNotStreamerOpen(true);
                // }
                let timerCnt = 0;
                if (leaveStreamerCnt > 3) {
                  timerCnt = 20000;
                } else {
                  timerCnt = 3000;
                }
                setTimeout(() => {
                  checkClassEndJson();
                }, timerCnt);
              } else {
                let file_name =
                  "haishin/" + serviceId + "/" + classId + "/doc/end.json";
                getFileS3(s3, file_name).then((file: any) => {
                  var fileReader = new FileReader();
                  let classEndFlg: any;
                  //let lastClassTime: any;
                  fileReader.onload = function (json: any) {
                    let classEndJson = json.currentTarget.result;
                    if (classEndJson) {
                      classEndJson = JSON.parse(classEndJson);
                      classEndFlg = Boolean(classEndJson.status_flg);
                      //lastClassTime = classEndJson.class_time;
                      if (classEndFlg) {
                        //timeCount++;
                        entryLastWacthLog();
                      } else {
                        getOut(false);
                      }
                    }
                  };
                  fileReader.readAsText(file);
                });
              }
            });
          }
        });
      } else {
        setTimeout(checkClassEndJson, 2000);
      }
    } catch (e: any) {
      const Log = [];
      Log.push("err:" + JSON.stringify(e));
      uploadLog(Log, "_checkClassEndJson_");
      getOut(false);
    }
  };

  const entryLastWacthLog = async () => {
    try {
      /*let classTimeOver = viewTimeCnt <= lastClassTime ? "0" : "1";
      ST0324(
        dispatch,
        classId,
        classTimeOver,
        viewTimeCnt,
        getDateTime(),
        String(agoraUserId),
        lastClassTime
      );*/
      if (screenIsSmall) {
        exitFullScreen(true);
      }
      const endDoing = (callback: any) => {
        endLec = true;
        makeLineFlg = true;
        addLineFlg = true;
        lineData = {};
        lineOrder = [];
        FabCanvas.clear();
        lineList = {};
        shapeList = {};
        sendReBack = false;
        makeLineFlg = false;
        addLineFlg = false;
        lectureChannelId = null;
        localStorage.setItem("Cls012_session", "");
        if (process.env.REACT_APP_AWS_S3_UPLOG === "true")
          consoleLogOut(null, null, true);
        if (socket) {
          socket.close();
          setTimeout(() => {
            socket = null;
            if (callback) callback();
          }, 500);
        }
      };
      switch (afterClassType) {
        case "0":
          if (isVisibleDefaultEnquete && isVisibleDefaultEnquete === "2") {
            endDoing(() => {
              dispatch(push("/cls/014", { class_id: sanitize(classId) }));
            });
          } else {
            getOut(false);
          }
          break;
        case "1":
        case "2":
          endDoing(() => {
            dispatch(push("/cls/014", { class_id: sanitize(classId) }));
          });
          break;
        case "3":
          endDoing(() => {
            dispatch(push("/cls/017", { class_id: sanitize(classId) }));
          });
          break;
        default:
          getOut(false);
          break;
      }
    } catch (e: any) {
      console.log("getout Err");
      getOut(false);
    }
  };

  const endClass = async () => {
    checkStreamer = false;
    //viewLogState = viewLogStatus.restartWait;
    if (!refPenDisableFlg.current) penJoinEnd();
    if (screenShare !== "null") setScreenShare("null");
    if (leftClass) {
      leftClass = false;
      checkClassEndJson();
    }
  };

  /*const statusCheck = async () => {
    let getData1 = await LC0305(dispatch, classId);
    if (getData1 && getData1.state === "0" && getData1.body) {
      if (getData1.body.status_class) {
        if (getData1.body.status_class === "4") {
          if (checkStreamer) {
            viewLogState = viewLogStatus.inService;
          } else {
            viewLogState = viewLogStatus.restartWait;
          }
        } else if (getData1.body.status_class === "3") {
          if (viewLogState !== viewLogStatus.inService) {
            if (getData1.body.start_date) {
              viewLogState = viewLogStatus.restartWait;
            } else {
              viewLogState = viewLogStatus.wait;
            }
          }
        } else {
          viewLogState = viewLogStatus.outService;
        }
      }
    }
  };*/

  // 授業スタート
  const startLecture = () => {
    if (checkStartLecture) return;
    checkStartLecture = true;
    let count = 0;
    // 視聴情報更新
    recordInterval = setInterval(() => {
      let path = store.getState().router.location.pathname;
      if (endLec || path !== "/cls/012") {
        checkStartLecture = false;
        clearInterval(recordInterval);
      }
      /*statusCheck();
      if (checkStreamer) {
        if (viewLogState === viewLogStatus.inService) {
          timeCount++;
          registViewLog(timeCount, "1");
        } else if (
          viewLogState === viewLogStatus.wait ||
          viewLogState === viewLogStatus.restartWait
        ) {
          registViewLog(timeCount, "0");
        }
      }*/
      const res = client.getRemoteVideoStats();
      console.log("getRemoteVideoStats:");
      console.log("20000:", res[20000]);
      count++;
      if (count % 10 === 0) sessionCheck();
    }, 60000);
  };

  // チャット履歴取得
  const getChatHistry = async (no: any, data: any, flg?: any) => {
    if (!captureFlg) {
      const s3 = await createS3();
      const readAsData = (file: any) => {
        return new Promise((resolve, reject) => {
          let reader = new FileReader();
          reader.onload = () => {
            resolve(reader.result);
          };
          reader.onerror = () => {
            reject(reader.error);
          };
          reader.readAsText(file);
        });
      };

      const mkChat = (element: any) => {
        if (element.user_id && element.user_id !== userId) return;
        if (joinStartTime > 0 && joinStartTime > Number(element.currentTime))
          return;
        let div = document.createElement("div");
        div.setAttribute("class", "msg-receives");
        div.innerHTML = element.html;
        // div.setAttribute("class", "msg-receives");
        // let span = document.createElement("span");
        // if (Object.keys(element).indexOf("reaction") >= 0) {
        //   if (Number(element.reaction) === 1) {
        //     span.append(element.send_user_name + ": 🙂");
        //   }
        //   if (Number(element.reaction) === 2) {
        //     span.append(element.send_user_name + ": ❌");
        //   }
        //   if (Number(element.reaction) === 3) {
        //     span.append(element.send_user_name + ": ✋");
        //   }
        // }
        // if (Object.keys(element).indexOf("message") >= 0) {
        //   if (element.user_id) {
        //     if (element.user_id === userId) {
        //       span.style.color = "red";
        //       span.append(element.send_user_name + ":");
        //       let messages = element.message.split("\n");
        //       for (let mi = 0; mi < messages.length; mi++) {
        //         if (mi > 0) span.append(document.createElement("br"));
        //         span.append(messages[mi]);
        //       }
        //     }
        //   } else {
        //     span.append(element.send_user_name + ":");
        //     let messages = element.message.split("\n");
        //     for (let mi = 0; mi < messages.length; mi++) {
        //       if (mi > 0) span.append(document.createElement("br"));
        //       span.append(messages[mi]);
        //     }
        //   }
        // }
        // div.append(span);
        let msgInsert = document.getElementsByClassName("msg-insert");
        if (msgInsert[0].clientHeight < maxHeightMessageDiv) {
          for (let mi = 0; mi < msgInsert.length; mi++) {
            msgInsert[mi].prepend(div.cloneNode(true));
          }
        }
      };

      let checkConcat = false;
      if (s3) {
        let msgPath =
          "haishin/" +
          serviceId +
          "/" +
          classId +
          "/chatMessage/chat_" +
          no +
          ".json";
        let fileData: any;
        let fileList = await getListS3(s3, msgPath);
        if (fileList) fileData = await getFileS3(s3, msgPath);
        if (fileData) {
          let jsonData: any = await readAsData(fileData);
          let messageJson = JSON.parse(jsonData);
          messageJson = messageJson.concat(data);
          checkConcat = true;
          if (messageJson && messageJson.length > 0) {
            for (let index = messageJson.length - 1; index >= 0; index--) {
              const element = messageJson[index];
              mkChat(element);
            }
          }
        } else {
          if (!flg) {
            let tmpPath =
              "haishin/" +
              serviceId +
              "/" +
              classId +
              "/chatMessage/all_list.json";
            let fileData: any;
            let fileList = await getListS3(s3, tmpPath);
            if (fileList) fileData = await getFileS3(s3, tmpPath);
            if (fileData) {
              let jsonData: any = await readAsData(fileData);
              let messageJson = JSON.parse(jsonData);
              if (
                messageJson.tmp &&
                messageJson.tmp.messageData &&
                messageJson.tmp.messageData.length > 0
              ) {
                let messageData = messageJson.tmp.messageData;

                if (
                  data.length > 0 &&
                  data[0].nowSeconds !== messageData[0].nowSeconds
                )
                  messageData = messageData.concat(data);
                checkConcat = true;
                for (let index = messageData.length - 1; index >= 0; index--) {
                  const element = messageData[index];
                  mkChat(element);
                }
              }
            }
          }
        }
      }
      if (!checkConcat && data.length > 0) {
        for (let index = data.length - 1; index >= 0; index--) {
          mkChat(data[index]);
        }
      }
      const clist = document.getElementsByClassName("chat-box");
      //スクロールバーの位置をリストの最下部に設定
      if (clist && clist.length > 0) {
        clist[0].scrollTop = clist[0].scrollHeight;
        clist[1].scrollTop = clist[1].scrollHeight;
      }

      let msgInsert = document.getElementsByClassName("msg-insert");
      if (msgInsert[0]) {
        if (
          msgInsert[0].clientHeight < maxHeightMessageDiv &&
          Number(no) - 1 >= 0
        )
          getChatHistry(Number(no) - 1, [], true);
      }
    } else {
      setTimeout(getChatHistry, 2000, no, data, flg);
    }
  };

  // 退出
  const confirmGetOut = () => {
    setconFirmGetOutOpen(true);
  };

  const getOutCancel = () => {
    setconFirmGetOutOpen(false);
  };

  const getOut = async (flg: any) => {
    //localStorage.removeItem("playclass");
    localStorage.removeItem("websdk_ng_global_parameter");
    //localStorage.removeItem("ClassClose");
    noSleep.disable();
    try {
      setconFirmGetOutOpen(false);
      setErrLecNotStartedOpen(false);
      endFlg = true;
      if (socket && socket.readyState === WebSocket.OPEN) {
        const nowTime = new Date().getTime();
        let list = JSON.stringify({
          actionId: actionComand.MSG_CLASS_EXIT,
          userId: streamerInfo.streamerId,
          currentTime: nowTime,
          option: {
            send_user_id: userId,
          },
          roomId: lectureChannelId,
          classId: classId,
          sessionId: localStorage.getItem("session_id")
            ? localStorage.getItem("session_id")
            : null,
        });
        socket.send(
          JSON.stringify({
            action: "MESSAGE_SEND",
            message: list,
            roomId: lectureChannelId,
          })
        );
        socket.close();
        channelRTM?.leave();
        clientRTM?.logout();
      }
      if (micTrack) {
        micTrack.stop();
        micTrack.close();
      }
      if (videoTrack) {
        videoTrack.stop();
        videoTrack.close();
      }
      if (screenIsSmall) {
        exitFullScreen(true);
      }
      if (flg) {
        await leave();
      }
      endLec = true;
      makeLineFlg = true;
      addLineFlg = true;
      lineData = {};
      lineOrder = [];
      FabCanvas.clear();
      lineList = {};
      shapeList = {};
      sendReBack = false;
      makeLineFlg = false;
      addLineFlg = false;
      lectureChannelId = null;
      //viewLogState = viewLogStatus.outService;
      if (!recordInterval) clearInterval(recordInterval);
      // if (!sessionInterval) clearInterval(sessionInterval);
      if (process.env.REACT_APP_AWS_S3_UPLOG === "true")
        consoleLogOut(null, null, true);
      localStorage.removeItem("Cls012_session");

      //window.open("about:blank", "_self")?.close();
      dispatch(push("/top/002"));
    } catch (e: any) {
      const Log = [];
      Log.push("err:" + JSON.stringify(e));
      uploadLog(Log, "_getout_Err_");
      //window.open("about:blank", "_self")?.close();
      dispatch(push("/top/002"));
    }
  };

  const makeDrawPen = () => {
    if (!makeLineFlg && lineOrder.length > 0) {
      makeLineFlg = true;
      var line_d: any;
      const doing = (lid: string) => {
        var ref = true;
        if (lineData[lid]) {
          if (
            lineData[lid] &&
            lineData[lid][0].flg === "S" &&
            lineData[lid][1].flg === "E"
          ) {
            let path: any = [
              ["M", lineData[lid][0].point.x, lineData[lid][0].point.y],
              ["L", lineData[lid][0].point.x - 1, lineData[lid][0].point.y - 1],
            ];
            line_d = new fabric.Path(path, {
              name: lid,
              globalCompositeOperation: lid,
              objectCaching: false,
              selectable: false,
              evented: true,
              hoverCursor: "default",
              stroke: lineData[lid][0].color,
              strokeWidth: lineData[lid][0].width,
              fill: "",
              strokeLineCap: "round",
              strokeLineJoin: "round",
            });
            FabCanvas.add(line_d);
            FabCanvas.renderAll();
            ref = false;
            makeLineFlg = false;
            delete lineData[lid];
          } else {
            if (!lineData[lid]["goC"]) {
              lineData[lid]["goC"] = 0;
              lineData[lid]["goE"] = 0;
              lineData[lid]["goR"] = 0;
              lineData[lid]["path"] = [];
            }
            var key = lineData[lid]["goC"];
            if (lineData[lid][key]) {
              lineData[lid]["goE"] = 0;
              if (key === 0 && lineData[lid][key]["flg"] !== "S") {
                ref = false;
                makeLineFlg = false;
                delete lineData[lid];
              } else {
                if (lineData[lid][key]["flg"] === "S") {
                  lineData[lid].path.push([
                    "M",
                    lineData[lid][key].point.x,
                    lineData[lid][key].point.y,
                  ]);
                  line_d = new fabric.Path(lineData[lid].path, {
                    name: lid,
                    globalCompositeOperation: lid,
                    objectCaching: false,
                    selectable: false,
                    evented: false,
                    hoverCursor: "default",
                    stroke: lineData[lid][key].color,
                    strokeWidth: lineData[lid][key].width,
                    strokeLineCap: "round",
                    strokeDashOffset: 0,
                    strokeLineJoin: "round",
                    strokeUniform: false,
                    strokeMiterLimit: 10,
                    fill: "",
                  });
                  FabCanvas.add(line_d);
                  FabCanvas.renderAll();
                }
                if (lineData[lid][key]["flg"] === "M") {
                  lineData[lid].path.push([
                    "Q",
                    lineData[lid][key - 1].point.x,
                    lineData[lid][key - 1].point.y,
                    lineData[lid][key].point.x,
                    lineData[lid][key].point.y,
                  ]);
                  if (line_d && line_d.path) {
                    line_d.path.push([
                      "Q",
                      lineData[lid][key - 1].point.x,
                      lineData[lid][key - 1].point.y,
                      lineData[lid][key].point.x,
                      lineData[lid][key].point.y,
                    ]);
                  }
                }
                if (lineData[lid][key]["flg"] === "E") {
                  FabCanvas.remove(line_d);
                  lineData[lid].path.push([
                    "L",
                    lineData[lid][key].point.x,
                    lineData[lid][key].point.y,
                  ]);
                  let new_line = new fabric.Path(lineData[lid].path, {
                    name: lid,
                    globalCompositeOperation: lid,
                    objectCaching: false,
                    selectable: false,
                    evented: false,
                    hoverCursor: "default",
                    stroke: lineData[lid][key].color,
                    strokeWidth: lineData[lid][key].width,
                    strokeLineCap: "round",
                    strokeDashOffset: 0,
                    strokeLineJoin: "round",
                    strokeUniform: false,
                    strokeMiterLimit: 10,
                    fill: "",
                  });
                  FabCanvas.add(new_line);
                  ref = false;
                  makeLineFlg = false;
                  delete lineData[lid];
                  line_d = null;
                }
                FabCanvas.renderAll();
                if (lineData[lid])
                  lineData[lid]["goC"] = lineData[lid]["goC"] + 1;
              }
            } else {
              if (lineData[lid]["goR"] > 100) {
                ref = false;
                makeLineFlg = false;
                // FabCanvas.remove(lineList[lid]);
                // delete lineList[lid];
                delete lineData[lid];
              } else if (lineData[lid]["goE"] > 100) {
                ref = false;
                makeLineFlg = false;
                lineData[lid]["goE"] = 0;
                lineData[lid]["goR"]++;
                lineOrder.push(lid);
                // delete lineData[lid];
              } else {
                lineData[lid]["goE"]++;
              }
            }
          }
        }
        if (ref) {
          setTimeout(() => {
            doing(lid);
          }, 10);
        }
      };
      doing(lineOrder[0]);
      lineOrder.splice(0, 1);
    }
  };

  const streamerDrawline = (drawPoint: any) => {
    if (addLineFlg) {
      setTimeout(() => streamerDrawline(drawPoint), 500);
      return;
    }
    if (FabCanvas) {
      // var flg = false;
      var id = drawPoint.user_id;
      var mousePoint = drawPoint.mouse_point;
      var color = drawPoint.pen_color;
      var width = drawPoint.pen_width;
      if (!lineData[id] && mousePoint[0].flg === "S") {
        // flg = true;
        lineData[id] = {};
        lineOrder.push(id);
      }
      for (let index = 0; index < mousePoint.length; index++) {
        const element = mousePoint[index];
        if (
          lineData[id] &&
          element &&
          Object.keys(element).indexOf("point") >= 0
        ) {
          if (!lineData[id][element.no]) lineData[id][element.no] = {};
          lineData[id][element.no]["point"] = element.point;
          lineData[id][element.no]["flg"] = element.flg;
          lineData[id][element.no]["color"] = color;
          lineData[id][element.no]["width"] = width;
        }
      }
    }
  };

  const makeDrawHand = (id: string) => {
    var ref = true;
    if (handData[id]) {
      if (!handData[id]["goC"]) handData[id]["goC"] = 0;
      var key = handData[id]["goC"];
      if (handData[id][key]) {
        var x = handData[id][key]["point"]["x"]
          ? handData[id][key]["point"]["x"]
          : 0;
        var y = handData[id][key]["point"]["y"]
          ? handData[id][key]["point"]["y"]
          : 0;
        if (handData[id][key]["flg"] === "S") {
          handImg.set({ left: x, top: y });
          FabCanvas.add(handImg);
          FabCanvas.renderAll();
        }
        if (handData[id][key]["flg"] === "M") {
          handImg.set({ left: x, top: y });
          FabCanvas.renderAll();
        }
        if (handData[id][key]["flg"] === "E") {
          ref = false;
          FabCanvas.remove(handImg);
          FabCanvas.renderAll();
          delete handData[id];
        }
        if (handData[id]) handData[id]["goC"] = handData[id]["goC"] + 1;
      }
    }
    if (ref)
      setTimeout(() => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        makeDrawHand(id);
      }, 10);
  };

  const streamerDrawHand = (drawPoint: any) => {
    if (FabCanvas) {
      var flg = false;
      var id = drawPoint.id;
      var no = drawPoint.no;
      var mouseFlg = drawPoint.flg;
      var mousePoint = drawPoint.point;
      var size = drawPoint.size;
      if (!handData[id]) {
        flg = true;
        handData[id] = {};
      }
      if (!handData[id][no]) handData[id][no] = {};
      handData[id][no]["point"] = mousePoint;
      handData[id][no]["flg"] = mouseFlg;
      handData[id][no]["size"] = size;
      if (flg) {
        makeDrawHand(id);
      }
    }
  };

  // 画像資料キュー
  const comingImgCommand = () => {
    // 画像リスト取得完了チェック
    if (imgLoadFlg) {
      exeImgCommand();
    } else {
      setTimeout(() => comingImgCommand(), 200);
    }
  };

  // 画像資料コマンド実行
  const exeImgCommand = () => {
    if (imgCmdId) {
      if (Number(imgCmdId) < 0) {
        deleteImg();
      } else {
        drawImg(imgCmdId);
      }
    }
  };

  // 画像資料表示
  const drawImg = async (value: any) => {
    // const found = imageRows.find(
    //   (element) => element.board_document_id === value
    // );
    const found = imageDatas[value];
    if (found && !captureFlg) {
      const s3 = await createS3();
      const fileUrl = await getUrlS3(s3, found);
      if (fileUrl) {
        boradImgChangeFlg = true;
        var image = new Image();
        // image.src = found.file_data;
        image.crossOrigin = "Anonymous";
        image.src = fileUrl;
        var left;
        var top;
        var width;
        var height;
        var canvasW = defaultWidth;
        var canvasH = defaultWidth / (16 / 9);
        image.onload = () => {
          var imgAspect = image.naturalWidth / image.naturalHeight;
          var cscale = expantionData.scale;
          if (image.naturalWidth > image.naturalHeight) {
            width = canvasW;
            height = canvasW / imgAspect;
            if (height > canvasH) {
              width = (canvasH - 2) * imgAspect;
              height = canvasH - 2;
              top = 0;
              left = Math.floor(((canvasW - width) / 2) * cscale);
            } else {
              width = canvasW - 2;
              height = (canvasW - 2) / imgAspect;
              left = 0;
              top = Math.floor(((canvasH - height) / 2) * cscale);
            }
          } else {
            width = canvasH * imgAspect;
            height = canvasH;
            if (width > canvasW) {
              width = canvasW - 2;
              height = (canvasW - 2) / imgAspect;
              left = 0;
              top = Math.floor(((canvasH - height) / 2) * cscale);
            } else {
              width = (canvasH - 2) * imgAspect;
              height = canvasH - 2;
              top = 0;
              left = Math.floor(((canvasW - width) / 2) * cscale);
            }
          }
          if (cscale > 1) {
            top = imgBackground.top + top;
            left = imgBackground.left + left;
          }
          if (nowImageData) {
            let objects = FabCanvas.getObjects();
            for (let index = 0; index < objects.length; index++) {
              const element = objects[index];
              if (element.type === "image") FabCanvas.remove(element);
            }
          }
          nowImageData = new fabric.Image(image, {
            globalCompositeOperation: "back_ground_image",
            width: Math.floor(image.naturalWidth),
            height: Math.floor(image.naturalHeight),
            scaleX:
              Math.round((width / image.naturalWidth) * cscale * 100) / 100,
            scaleY:
              Math.round((height / image.naturalHeight) * cscale * 100) / 100,
            selectable: false,
            evented: false,
            top: Math.floor(top),
            left: Math.floor(left),
          });
          FabCanvas.add(nowImageData);
          nowImageData.moveTo(1);
          FabCanvas.renderAll();
          var checkLoadImg = setInterval(() => {
            FabCanvas.getObjects().forEach((element: any) => {
              if (
                element.type === "image" &&
                element.globalCompositeOperation === "back_ground_image"
              ) {
                setTimeout(() => {
                  boradImgChangeFlg = false;
                }, 1000);
                clearInterval(checkLoadImg);
              }
            });
          }, 1000);
        };
      }
    }
    if (value !== imgCmdId) {
      drawImg(imgCmdId);
    }
  };

  //画像削除
  const deleteImg = () => {
    if (nowImageData) {
      FabCanvas.remove(nowImageData);
      nowImageData = null;
    }
    imgCmdId = -1;
  };

  //拡大
  const setExpantion = (value: any, callback?: any) => {
    makeLineFlg = true;
    addLineFlg = true;
    var cscale = expantionData.scale;
    FabCanvas.discardActiveObject();
    var selectData = FabCanvas.getObjects();
    var canvasW = defaultWidth;
    var canvasH = defaultWidth / (16 / 9);
    var ib = null;
    for (let index = 0; index < selectData.length; index++) {
      if (selectData[index].globalCompositeOperation === "back_ground_color") {
        ib = selectData[index];
        break;
      }
    }
    var checkTop = ib.top;
    var checkLeft = ib.left;
    lineData = {};
    lineOrder = [];
    if (String(value) === "1") {
      var sel = new fabric.ActiveSelection(selectData, {
        canvas: FabCanvas,
      });
      var newTop =
        (Math.abs(checkTop) - Math.abs(sel.top ? sel.top : 0)) / cscale;
      var newLeft =
        (Math.abs(checkLeft) - Math.abs(sel.left ? sel.left : 0)) / cscale;
      sel.set({
        left: newLeft,
        top: newTop,
        scaleX: 1 / cscale,
        scaleY: 1 / cscale,
        hasControls: false,
        hasBorders: false,
        hoverCursor: "pointer",
        moveCursor: "pointer",
        angle: 0,
      });
      setActive = true;
      FabCanvas.setActiveObject(sel);
      FabCanvas.requestRenderAll();
      FabCanvas.discardActiveObject();
      setActive = false;
      // exeImgCommand();
      FabCanvas.renderAll();
    } else {
      var left = (Number(canvasW) * (Number(value) - 1)) / 2;
      var top = (Number(canvasH) * (Number(value) - 1)) / 2;
      var sel = new fabric.ActiveSelection(selectData, {
        canvas: FabCanvas,
      });
      var newTop =
        top +
        Math.abs(
          ((Math.abs(checkTop) - Math.abs(sel.top ? sel.top : 0)) / cscale) *
            Number(value)
        );
      var newLeft =
        left +
        Math.abs(
          ((Math.abs(checkLeft) - Math.abs(sel.left ? sel.left : 0)) / cscale) *
            Number(value)
        );
      sel.set({
        left: -newLeft,
        top: -newTop,
        scaleX: (1 / cscale) * Number(value),
        scaleY: (1 / cscale) * Number(value),
        hasControls: false,
        hasBorders: false,
        hoverCursor: "pointer",
        moveCursor: "pointer",
        angle: 0,
      });
      setActive = true;
      FabCanvas.setActiveObject(sel);
      FabCanvas.requestRenderAll();
      FabCanvas.discardActiveObject();
      setActive = false;
      // exeImgCommand();
      FabCanvas.renderAll();
    }
    expantionData.scale = value;
    makeLineFlg = false;
    addLineFlg = false;
    if (callback) callback();
  };

  const getBoardInfo = (data: any, flg?: any) => {
    // ボード初期化
    selectBoardNo = data.option.board_no;
    boradChangeFlg = true;
    getS3(data.option.board_no, data.classId, flg);
  };

  const handleClickClose = () => {
    setJoinLecDialogOpen(false);
  };

  const clickJoinLec = async (flg: boolean) => {
    if (flg) {
      const nowTime = new Date().getTime();
      let list = JSON.stringify({
        actionId: actionComand.MSG_JOIN_RESULT,
        userId: null,
        currentTime: nowTime,
        option: {
          reply_type: 1,
          status: 1,
          send_user_id: userId,
        },
        roomId: lectureChannelId,
        classId: classId,
        sessionId: localStorage.getItem("session_id")
          ? localStorage.getItem("session_id")
          : null,
      });
      socket.send(
        JSON.stringify({
          action: "MESSAGE_SEND",
          message: list,
          roomId: lectureChannelId,
        })
      );
    } else {
      clickImpossibleJoin();
    }
  };

  const clickJoinVoice = async (flg: boolean) => {
    if (flg) {
      const nowTime = new Date().getTime();
      let list = JSON.stringify({
        actionId: actionComand.MSG_JOIN_RESULT,
        userId: null,
        currentTime: nowTime,
        option: {
          reply_type: 2,
          status: 1,
          send_user_id: userId,
        },
        roomId: lectureChannelId,
        classId: classId,
        sessionId: localStorage.getItem("session_id")
          ? localStorage.getItem("session_id")
          : null,
      });
      socket.send(
        JSON.stringify({
          action: "MESSAGE_SEND",
          message: list,
          roomId: lectureChannelId,
        })
      );
    } else {
      clickImpossibleJoin();
    }
  };

  const clickRejectJoin = async () => {
    const nowTime = new Date().getTime();
    let list = JSON.stringify({
      actionId: actionComand.MSG_JOIN_RESULT,
      userId: null,
      currentTime: nowTime,
      option: {
        reply_type: 1,
        status: 2,
        send_user_id: userId,
      },
      roomId: lectureChannelId,
      classId: classId,
      sessionId: localStorage.getItem("session_id")
        ? localStorage.getItem("session_id")
        : null,
    });
    socket.send(
      JSON.stringify({
        action: "MESSAGE_SEND",
        message: list,
        roomId: lectureChannelId,
      })
    );

    unpublish();
    await client.setClientRole("audience");
  };

  const clickImpossibleJoin = async () => {
    const nowTime = new Date().getTime();
    let list = JSON.stringify({
      actionId: actionComand.MSG_JOIN_RESULT,
      userId: null,
      currentTime: nowTime,
      option: {
        reply_type: 1,
        status: 3,
        send_user_id: userId,
      },
      roomId: lectureChannelId,
      classId: classId,
      sessionId: localStorage.getItem("session_id")
        ? localStorage.getItem("session_id")
        : null,
    });
    socket.send(
      JSON.stringify({
        action: "MESSAGE_SEND",
        message: list,
        roomId: lectureChannelId,
      })
    );
    unpublish();
    await client.setClientRole("audience");
  };

  const joinLecVoiceClickClose = async () => {
    setJoinLecVoiceDialogOpen(false);
    unpublish();
    await client.setClientRole("audience");
  };

  const clickJoinLecVoice = async (flg: boolean) => {
    if (flg) {
      const nowTime = new Date().getTime();
      let list = JSON.stringify({
        actionId: actionComand.MSG_JOIN_RESULT,
        userId: null,
        currentTime: nowTime,
        option: {
          reply_type: 2,
          status: 1,
          send_user_id: userId,
        },
        roomId: lectureChannelId,
        classId: classId,
        sessionId: localStorage.getItem("session_id")
          ? localStorage.getItem("session_id")
          : null,
      });
      socket.send(
        JSON.stringify({
          action: "MESSAGE_SEND",
          message: list,
          roomId: lectureChannelId,
        })
      );
    } else {
      clickImpossibleJoinVoice();
    }
  };

  const clickRejectJoinVoice = async () => {
    const nowTime = new Date().getTime();
    let list = JSON.stringify({
      actionId: actionComand.MSG_JOIN_RESULT,
      userId: null,
      currentTime: nowTime,
      option: {
        reply_type: 2,
        status: 2,
        send_user_id: userId,
      },
      roomId: lectureChannelId,
      classId: classId,
      sessionId: localStorage.getItem("session_id")
        ? localStorage.getItem("session_id")
        : null,
    });
    socket.send(
      JSON.stringify({
        action: "MESSAGE_SEND",
        message: list,
        roomId: lectureChannelId,
      })
    );
    unpublish();
    await client.setClientRole("audience");
  };

  const clickImpossibleJoinVoice = async () => {
    const nowTime = new Date().getTime();
    let list = JSON.stringify({
      actionId: actionComand.MSG_JOIN_RESULT,
      userId: null,
      currentTime: nowTime,
      option: {
        reply_type: 2,
        status: 3,
        send_user_id: userId,
      },
      roomId: lectureChannelId,
      classId: classId,
      sessionId: localStorage.getItem("session_id")
        ? localStorage.getItem("session_id")
        : null,
    });
    socket.send(
      JSON.stringify({
        action: "MESSAGE_SEND",
        message: list,
        roomId: lectureChannelId,
      })
    );
    unpublish();
    await client.setClientRole("audience");
  };

  const handWrittenJoinClickClose = () => {
    setHandWrittenJoinDialogOpen(false);
  };

  const clickJoinLecWritten = async () => {
    const nowTime = new Date().getTime();
    let list = JSON.stringify({
      actionId: actionComand.MSG_JOIN_RESULT,
      userId: null,
      currentTime: nowTime,
      option: {
        reply_type: 3,
        status: 1,
        send_user_id: userId,
      },
      roomId: lectureChannelId,
      classId: classId,
      sessionId: localStorage.getItem("session_id")
        ? localStorage.getItem("session_id")
        : null,
    });
    socket.send(
      JSON.stringify({
        action: "MESSAGE_SEND",
        message: list,
        roomId: lectureChannelId,
      })
    );
    setPenDisableFlg(false);
    operation = OPERATION_TYPE.PEN;
    onWrittenFlg = true;
  };

  const clickRejectJoinWritten = async () => {
    const nowTime = new Date().getTime();
    let list = JSON.stringify({
      actionId: actionComand.MSG_JOIN_RESULT,
      userId: null,
      currentTime: nowTime,
      option: {
        reply_type: 3,
        status: 2,
        send_user_id: userId,
      },
      roomId: lectureChannelId,
      classId: classId,
      sessionId: localStorage.getItem("session_id")
        ? localStorage.getItem("session_id")
        : null,
    });
    socket.send(
      JSON.stringify({
        action: "MESSAGE_SEND",
        message: list,
        roomId: lectureChannelId,
      })
    );
  };

  const clickImpossibleJoinWritten = async () => {
    const nowTime = new Date().getTime();
    let list = JSON.stringify({
      actionId: actionComand.MSG_JOIN_RESULT,
      userId: null,
      currentTime: nowTime,
      option: {
        reply_type: 3,
        status: 3,
        send_user_id: userId,
      },
      roomId: lectureChannelId,
      classId: classId,
      sessionId: localStorage.getItem("session_id")
        ? localStorage.getItem("session_id")
        : null,
    });
    socket.send(
      JSON.stringify({
        action: "MESSAGE_SEND",
        message: list,
        roomId: lectureChannelId,
      })
    );
  };

  const handleImgUploadClickClose = () => {
    setImgUploadReqDialogOpen(false);
    setUploadFile(null);
    setUploadFileUrl("");
    setChooseFileName("");
  };

  const clickAgleeUpload = async () => {
    const nowTime = new Date().getTime();
    let list = JSON.stringify({
      actionId: actionComand.MSG_JOIN_RESULT,
      userId: null,
      currentTime: nowTime,
      option: {
        reply_type: 4,
        status: 1,
        send_user_id: userId,
      },
      roomId: lectureChannelId,
      classId: classId,
      sessionId: localStorage.getItem("session_id")
        ? localStorage.getItem("session_id")
        : null,
    });
    socket.send(
      JSON.stringify({
        action: "MESSAGE_SEND",
        message: list,
        roomId: lectureChannelId,
      })
    );
    setImgUploadDialogOpen(true);
  };

  const clickRejectUpload = () => {
    const nowTime = new Date().getTime();
    let list = JSON.stringify({
      actionId: actionComand.MSG_JOIN_RESULT,
      userId: null,
      currentTime: nowTime,
      option: {
        reply_type: 4,
        status: 2,
        send_user_id: userId,
      },
      roomId: lectureChannelId,
      classId: classId,
      sessionId: localStorage.getItem("session_id")
        ? localStorage.getItem("session_id")
        : null,
    });
    socket.send(
      JSON.stringify({
        action: "MESSAGE_SEND",
        message: list,
        roomId: lectureChannelId,
      })
    );
  };

  const handleImgUploadExeClickClose = () => {
    setImgUploadDialogOpen(false);
    const nowTime = new Date().getTime();
    let list = JSON.stringify({
      actionId: actionComand.MSG_JOIN_RESULT,
      userId: null,
      currentTime: nowTime,
      option: {
        reply_type: 4,
        status: 4,
        send_user_id: userId,
      },
      roomId: lectureChannelId,
      classId: classId,
      sessionId: localStorage.getItem("session_id")
        ? localStorage.getItem("session_id")
        : null,
    });
    socket.send(
      JSON.stringify({
        action: "MESSAGE_SEND",
        message: list,
        roomId: lectureChannelId,
      })
    );
    setUploadFile(null);
    setUploadFileUrl("");
    setChooseFileName("");
    setErrorMsgImgUp("");
  };

  const clickUpload = () => {
    setImgUploadDialogOpen(false);
    const nowTime = new Date().getTime();
    let list = JSON.stringify({
      actionId: actionComand.MSG_JOIN_RESULT,
      userId: null,
      currentTime: nowTime,
      option: {
        reply_type: 4,
        status: 5,
        send_user_id: userId,
      },
      roomId: lectureChannelId,
      classId: classId,
      sessionId: localStorage.getItem("session_id")
        ? localStorage.getItem("session_id")
        : null,
    });
    socket.send(
      JSON.stringify({
        action: "MESSAGE_SEND",
        message: list,
        roomId: lectureChannelId,
      })
    );
    uploadFlg = false;
    setImgUploadReqDialogOpen(false);
    setUploadFile(null);
    setUploadFileUrl("");
    setChooseFileName("");
    setErrorMsgImgUp("");
  };

  const handleCameraClickClose = () => {
    setCameraDialogOpen(false);
  };

  const handleCameraClickOpen = () => {
    setCameraDialogOpen(true);
  };

  const changeCameraImage = (img: any) => {
    const imageFile = img;
    setUploadFile(imageFile);
    const imageUrl = URL.createObjectURL(imageFile);
    setUploadFileUrl(imageUrl);
    var date = new Date();
    var name =
      date.getFullYear() +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      ("0" + date.getDate()).slice(-2) +
      ("0" + date.getHours()).slice(-2) +
      ("0" + date.getMinutes()).slice(-2) +
      ".png";
    setChooseFileName(name);
  };

  const createJpegFile = (base64: any, fileName: any) => {
    var bin = atob(base64.replace(/^.*,/, ""));
    var buffer = new Uint8Array(bin.length);
    for (var i = 0; i < bin.length; i++) {
      buffer[i] = bin.charCodeAt(i);
    }
    return new File([buffer.buffer], fileName, { type: "image/jpeg" });
  };

  /*const readPdfFile = async (file: any) => {
    const loadingTask = pdfjs.getDocument({
      url: URL.createObjectURL(file),
      cMapUrl: "https://cdn.jsdelivr.net/npm/pdfjs-dist@2.0.385/cmaps/",
      cMapPacked: true,
    });
    let loadedPdf: any = await loadingTask.promise;
    const pdfNum: number = Number(loadedPdf._pdfInfo.numPages);
    let files: any[] = [];
    for (let index = 1; index <= pdfNum; index++) {
      let page = await loadedPdf.getPage(index);
      var viewport = page.getViewport({ scale: 1 });
      var canvas = document.createElement("canvas");
      var ctx = canvas.getContext("2d");
      var renderContext: any = {
        canvasContext: ctx,
        viewport: viewport,
      };
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      let show_pdf = document.getElementById("show_pdf");
      if (show_pdf) {
        show_pdf.appendChild(canvas);
        await page.render(renderContext).promise;
        const base64 = canvas.toDataURL("image/jpg");
        let fileNane = file.name.slice(0, -4) + ".jpg";
        if (pdfNum > 1)
          fileNane = file.name.slice(0, -4) + "_" + index + ".jpg";
        let uploadFile = createJpegFile(base64, fileNane);
        if (show_pdf) show_pdf.innerHTML = "";
        files.push(uploadFile);
      }
    }
    return files;
  };*/

  const FILE_FORMAT_ERROR = FormatMessage({ id: "Cls001.Err.Msg5" });
  const [errorMsgImgUp, setErrorMsgImgUp] = useState("");

  const changeUploadImage = async (event: any) => {
    setErrorMsgImgUp("");
    if (event.target.files[0].length !== 0) {
      const imageFile = event.target.files[0];
      if (
        imageFile.type === "image/png" ||
        imageFile.type === "image/jpg" ||
        imageFile.type === "image/jpeg" ||
        imageFile.type === "image/bmp"
      ) {
        setUploadFile(imageFile);
        const imageUrl = URL.createObjectURL(imageFile);
        setUploadFileUrl(imageUrl);
        setChooseFileName(event.target.files[0].name);
      } else {
        setErrorMsgImgUp(FILE_FORMAT_ERROR);
      }
    }
  };

  const registUploadFileData = async (s3id: any, filename: any) => {
    await ST0310(dispatch, classId, filename, "1", s3id);
  };

  const [noChooseFileOpen, setNoChooseFileOpen] = useState(false);

  // 画像資料表示順ファイル更新
  const updateOrderList = async () => {
    if (!captureFlg) {
      const S3 = await createS3();
      if (S3) {
        let file: any = await getFileS3(S3, filePath + orderFileName);
        if (file && file?.size > 0) {
          let reader = new FileReader();
          reader.readAsText(file);
          reader.onload = function () {
            if (reader.result) {
              orderList = JSON.parse(String(reader.result));
              const newImgId: imgType = imageRows.slice(-1)[0];
              orderList.imgOrder.push(newImgId.board_document_id);
              // orderListをJSONファイル変換
              let jsonData = JSON.stringify(orderList);
              var blob = new Blob([jsonData], { type: "text/json" });
              var jsonFile = new File([blob], orderFileName);
              uploadS3(S3, filePath + orderFileName, jsonFile);
            }
          };
        } else {
          const newImgId: imgType = imageRows.slice(-1)[0];
          orderList.imgOrder.push(newImgId.board_document_id);
          // orderListをJSONファイル変換
          let jsonData = JSON.stringify(orderList);
          var blob = new Blob([jsonData], { type: "text/json" });
          var jsonFile = new File([blob], orderFileName);
          uploadS3(S3, filePath + orderFileName, jsonFile);
        }
      }
    } else {
      setTimeout(updateOrderList, 2000);
    }
  };

  const macNFC = (name: any) => {
    var myReg =
      /[^\u{0340}\u{0341}\u{0343}\u{0344}\u{0374}\u{037E}\u{0387}\u{0958}-\u{095F}\u{09DC}\u{09DD}\u{09DF}\u{0A33}\u{0A36}\u{0A59}-\u{0A5B}\u{0A5E}\u{0B5C}\u{0B5D}\u{0F43}\u{0F4D}\u{0F52}\u{0F57}\u{0F5C}\u{0F69}\u{0F73}\u{0F75}\u{0F76}\u{0F78}\u{0F81}\u{0F93}\u{0F9D}\u{0FA2}\u{0FA7}\u{0FAC}\u{0FB9}\u{1F71}\u{1F73}\u{1F75}\u{1F77}\u{1F79}\u{1F7B}\u{1F7D}\u{1FBB}\u{1FBE}\u{1FC9}\u{1FCB}\u{1FD3}\u{1FDB}\u{1FE3}\u{1FEB}\u{1FEE}\u{1FEF}\u{1FF9}\u{1FFB}\u{1FFD}\u{2000}\u{2001}\u{2126}\u{212A}\u{212B}\u{2329}\u{232A}\u{2ADC}\u{F900}-\u{FAFF}\u{FB1D}\u{FB1F}\u{FB2A}-\u{FB36}\u{FB38}-\u{FB3C}\u{FB3E}\u{FB40}\u{FB41}\u{FB43}\u{FB44}\u{FB46}-\u{FB4E}\u{1D15E}-\u{1D164}\u{1D1BB}-\u{1D1C0}\u{2F800}-\u{2FA1F}]+/gu;
    if (name.normalize)
      name = name.replace(myReg, function (r: any) {
        return r.normalize("NFC");
      });
    return name;
  };

  const checkImgFileName = (fileName: any) => {
    returnFileName = fileName;
    if (imageRows.length > 0) {
      for (let e = 0; e < imageRows.length; e++) {
        if (imageRows[e].board_document_name === fileName) {
          if (fileNameCnt > 0) {
            let reCutLength: number;
            let reExtension = ".";
            reCutLength = Number(String(fileNameCnt).length);
            fileNameCnt++;
            switch (fileName.slice(-2)) {
              case "ng":
                reCutLength += 4;
                reExtension += "png";
                break;
              case "pg":
                reCutLength += 4;
                reExtension += "jpg";
                break;
              case "mp":
                reCutLength += 4;
                reExtension += "bmp";
                break;
              case "eg":
                reCutLength += 5;
                reExtension += "jpeg";
                break;
            }
            let reNewFileName = fileName.slice(0, -reCutLength);
            reNewFileName = reNewFileName + fileNameCnt + reExtension;
            returnFileName = reNewFileName;
            checkImgFileName(reNewFileName);
          } else {
            let cutLength = 0;
            let extension = ".";
            fileNameCnt++;
            switch (fileName.slice(-2)) {
              case "ng":
                cutLength = 4;
                extension += "png";
                break;
              case "pg":
                cutLength = 4;
                extension += "jpg";
                break;
              case "mp":
                cutLength = 4;
                extension += "bmp";
                break;
              case "eg":
                cutLength += 5;
                extension += "jpeg";
                break;
            }
            let newFileName = fileName.slice(0, -cutLength);
            newFileName = newFileName + "_" + fileNameCnt + extension;
            returnFileName = newFileName;
            checkImgFileName(newFileName);
          }
        }
      }
    }
  };

  //画像ファイルアップロード
  const clickUploadExecute = async () => {
    if (uploadFile) {
      setImgUploadDialogOpen(false);

      const S3 = await createS3();
      if (S3) {
        const filenamebef = chooseFileName ? chooseFileName : uploadFile.name;
        let filename = macNFC(filenamebef);
        checkImgFileName(filename);
        const checkedFileName = fileNameCnt > 0 ? returnFileName : filename;
        fileNameCnt = 0;
        await uploadS3(
          S3,
          filePath + "pictuire/" + checkedFileName,
          uploadFile
        );
        await uploadS3(
          S3,
          filePath + "thumbnail/" + checkedFileName,
          uploadFile
        );
        // DB登録
        await registUploadFileData(
          filePath + "pictuire/" + checkedFileName,
          checkedFileName
        );
        // 最新画像資料情報取得
        await getImagesData(null);
        // 画像資料表示順ファイル更新
        updateOrderList();
        sendAction(actionComand.MSG_IMGLIST_RESET, null, {});
      }
      clickUpload();
    } else {
      setNoChooseFileOpen(true);
      uploadFlg = false;
    }
  };

  const noChooseFileClose = () => {
    setNoChooseFileOpen(false);
  };

  // ボードJSONファイル取得
  const getS3 = (boardNo: any, classId: any, flg?: any) => {
    if (!captureFlg) {
      //Json取得
      FabCanvas.clear();
      expantionData.scale = 1;
      imgCmdId = "-1";
      let checkSelectBoardNo = selectBoardNo;
      var fileReader = new FileReader();
      var file_name =
        "haishin/" +
        serviceId +
        "/" +
        classId +
        "/board_" +
        boardNo +
        "/" +
        "canvas_" +
        boardNo +
        ".json";
      console.log("ボードJSONファイル取得 file_path:", file_name);
      createS3().then((s3) => {
        if (s3) {
          getFileS3(s3, file_name).then((file: any) => {
            if (file === undefined || file === null) {
              setTimeout(() => getS3(boardNo, classId, flg), 500);
              return;
            }
            fileReader.onload = async function (json: any) {
              let canvasJson = json.currentTarget.result;
              if (canvasJson) {
                let backData = JSON.parse(canvasJson);
                // FabCanvas.clear();
                canvasJson = null;
                const shapeChange = (data: any) => {
                  let shapeList: any;
                  if (data.type === "rect") {
                    shapeList = new fabric.Rect(data);
                  }
                  if (data.type === "line") {
                    shapeList = new fabric.Line(
                      [data.x1, data.y1, data.x2, data.y2],
                      data
                    );
                  }
                  if (data.type === "circle") {
                    shapeList = new fabric.Circle(data);
                  }
                  if (data.type === "triangle") {
                    shapeList = new fabric.Triangle(data);
                  }
                  if (shapeList) {
                    FabCanvas.add(shapeList);
                    FabCanvas.renderAll();
                    shapeList = null;
                  }
                };
                if (backData.json) {
                  if (Number(backData.scale) > 1) {
                    expantionData.scale = Number(backData.scale);
                  }
                  if (backData.img_no && Number(backData.img_no) > 0) {
                    imgCmdId = String(backData.img_no);
                  }
                  makeLineFlg = true;
                  addLineFlg = true;
                  lineData = {};
                  lineOrder = [];
                  // const imgFind = backData.json.objects.find(
                  //   (element: any) =>
                  //     element.globalCompositeOperation === "back_ground_image"
                  // );
                  // if (!imgFind && Number(backData.img_no) < 0) {
                  //   setTimeout(() => {
                  //     boradImgChangeFlg = false;
                  //   }, 1000);
                  // }
                  for (let a = 0; a < backData.json.objects.length; a++) {
                    if (backData.json.objects[a].type !== "group") {
                      if (backData.json.objects[a].type === "path") {
                        let data = backData.json.objects[a];
                        data.selectable = false;
                        if (
                          data.globalCompositeOperation.indexOf(
                            userId + userNo
                          ) < 0
                        )
                          data.evented = false;
                        let newLine = new fabric.Path(data.path, data);
                        FabCanvas.add(newLine);
                        FabCanvas.renderAll();
                        data = null;
                      } else if (
                        backData.json.objects[a].globalCompositeOperation ===
                        "back_ground_color"
                      ) {
                        backData.json.objects[a].selectable = false;
                        backData.json.objects[a].evented = false;
                        imgBackground = new fabric.Rect(
                          backData.json.objects[a]
                        );
                        FabCanvas.add(imgBackground);
                        FabCanvas.renderAll();
                        imgBackground.moveTo(0);
                      } else if (
                        backData.json.objects[a].globalCompositeOperation ===
                        "back_ground_image"
                      ) {
                        let fileUrl;
                        boradImgChangeFlg = true;
                        if (
                          backData.img_no &&
                          Number(backData.img_no) > 0 &&
                          imageDatas[backData.img_no]
                        ) {
                          const s3 = await createS3();
                          fileUrl = await getUrlS3(
                            s3,
                            imageDatas[backData.img_no]
                          );
                        }
                        var imageData: any = backData.json.objects[a];
                        imageData.selectable = false;
                        imageData.evented = false;
                        var image = new Image();
                        image.crossOrigin = "Anonymous";
                        image.src = fileUrl ? fileUrl : imageData.src;
                        image.onload = () => {
                          if (checkSelectBoardNo === selectBoardNo) {
                            nowImageData = new fabric.Image(image, imageData);
                            FabCanvas.add(nowImageData);
                            nowImageData.moveTo(1);
                            FabCanvas.renderAll();
                            // setTimeout(() => {
                            //   boradImgChangeFlg = false;
                            // }, 3000);
                            let checkMakeImgCount = 0;
                            var checkLoadImg = setInterval(() => {
                              if (checkMakeImgCount > 20) {
                                boradImgChangeFlg = false;
                                clearInterval(checkLoadImg);
                              }
                              checkMakeImgCount++;
                              FabCanvas.getObjects().forEach((element: any) => {
                                if (
                                  element.type === "image" &&
                                  element.globalCompositeOperation ===
                                    "back_ground_image"
                                ) {
                                  setTimeout(() => {
                                    boradImgChangeFlg = false;
                                  }, 1000);
                                  clearInterval(checkLoadImg);
                                }
                              });
                            }, 1000);
                          }
                          imageData = null;
                        };
                      } else {
                        if (
                          backData.json.objects[
                            a
                          ].globalCompositeOperation.indexOf(userId + userNo) <
                          0
                        ) {
                          backData.json.objects[a].selectable = false;
                          backData.json.objects[a].evented = false;
                        }
                        shapeChange(backData.json.objects[a]);
                      }
                    }
                  }
                  backData = null;
                  setTimeout(() => {
                    FabCanvas.renderAll();
                    makeLineFlg = false;
                    addLineFlg = false;
                    const changeFlg = () => {
                      if (boradImgChangeFlg) {
                        setTimeout(() => {
                          changeFlg();
                        }, 1000);
                      } else {
                        boradChangeFlg = false;
                      }
                    };
                    setTimeout(() => {
                      changeFlg();
                    }, 1000);
                  }, 1000);
                }
              }
            };
            fileReader.readAsText(file);
          });
        }
      });
      setTimeout(() => {
        boradChangeFlg = false;
      }, 60000);
    } else {
      setTimeout(getS3, 2000, boardNo, classId, flg);
    }
  };

  const sendAction = async (actionId: string, sendUserId: any, option: any) => {
    const nowTime = new Date().getTime();
    let list = JSON.stringify({
      actionId: actionId,
      channelId: lectureChannelId,
      userId: sendUserId,
      currentTime: nowTime,
      option: option,
      classId: classId,
      sessionId: localStorage.getItem("session_id")
        ? localStorage.getItem("session_id")
        : null,
    });
    if (socket.readyState === WebSocket.OPEN) {
      socket.send(
        JSON.stringify({
          action: "MESSAGE_SEND",
          message: list,
          roomId: lectureChannelId,
        })
      );
    }
  };

  const mouDown = (event: any) => {
    if (!refPenDisableFlg.current) drowing = true;
    var pointer = FabCanvas.getPointer(event.e);
    switch (operation) {
      case OPERATION_TYPE.PEN: //ペン
        brush.color = penColor;
        brush.width = penWidth;
        lineNo = 0;
        lineListData = [];
        lineId =
          "line_" + userId + userNo + "_" + new Date().getTime().toString();
        brush.onMouseDown(
          { x: pointer.x, y: pointer.y },
          { e: { isPrimary: true } }
        );
        lineListData.push({
          point: { x: pointer.x, y: pointer.y },
          no: lineNo,
          flg: "S",
        });
        break;
      case OPERATION_TYPE.SHAPE: //基本図形
        FabCanvas.renderAll();
        shapeId =
          "shape_" + userId + userNo + "_" + new Date().getTime().toString();
        switch (shapeType) {
          case SHAPE_TYPE.SQUARE:
            origX = pointer.x;
            origY = pointer.y;
            addShapeRect = new fabric.Rect({
              globalCompositeOperation: shapeId,
              left: origX,
              top: origY,
              originX: "left",
              originY: "top",
              width: 1,
              height: 1,
              angle: 0,
              fill: "rgba(255,255,255,0.0)",
              strokeWidth: penWidth,
              stroke: penColor,
              transparentCorners: false,
              noScaleCache: false,
              strokeUniform: true,
            });
            shapeList[shapeId] = addShapeRect;
            FabCanvas.add(addShapeRect);
            FabCanvas.setActiveObject(addShapeRect);
            FabCanvas.renderAll();
            break;
          case SHAPE_TYPE.LINE:
            mousePoint.px = pointer.x;
            mousePoint.py = pointer.y;
            var points = [pointer.x, pointer.y, pointer.x, pointer.y];
            addShapeLine = new fabric.Line(points, {
              globalCompositeOperation: shapeId,
              strokeWidth: penWidth,
              fill: penColor,
              stroke: penColor,
              originX: "center",
              originY: "center",
              noScaleCache: false,
              strokeUniform: true,
            });
            shapeList[shapeId] = addShapeLine;
            FabCanvas.add(addShapeLine);
            FabCanvas.setActiveObject(addShapeLine);
            FabCanvas.renderAll();
            break;
          case SHAPE_TYPE.CIRCLE:
            origX = pointer.x;
            origY = pointer.y;
            addShapeCircle = new fabric.Circle({
              globalCompositeOperation: shapeId,
              left: pointer.x,
              top: pointer.y,
              radius: 1,
              strokeWidth: penWidth,
              stroke: penColor,
              fill: "rgba(255,255,255,0.0)",
              originX: "center",
              originY: "center",
              noScaleCache: false,
              strokeUniform: true,
            });
            shapeList[shapeId] = addShapeCircle;
            FabCanvas.add(addShapeCircle);
            FabCanvas.setActiveObject(addShapeCircle);
            FabCanvas.renderAll();
            break;
          case SHAPE_TYPE.TRIANGLE:
            origX = pointer.x;
            origY = pointer.y;
            addShapeTriangle = new fabric.Triangle({
              globalCompositeOperation: shapeId,
              left: pointer.x,
              top: pointer.y,
              width: 1,
              height: 1,
              angle: 0,
              fill: "rgba(255,255,255,0.0)",
              strokeWidth: penWidth,
              stroke: penColor,
              noScaleCache: false,
              strokeUniform: true,
            });
            shapeList[shapeId] = addShapeTriangle;
            FabCanvas.add(addShapeTriangle);
            FabCanvas.setActiveObject(addShapeTriangle);
            FabCanvas.renderAll();
            break;
        }
    }
  };

  const mouMove = (event: any) => {
    if (!drowing) return;

    var pointer = FabCanvas.getPointer(event);
    switch (operation) {
      case OPERATION_TYPE.PEN: //ペン
        lineNo++;
        lineListData.push({
          point: { x: pointer.x, y: pointer.y },
          no: lineNo,
          flg: "M",
        });
        if (lineListData.length > 29) {
          var mouse_point = lineListData;
          sendAction(actionComand.MSG_WB_DRAW, null, {
            mouse_point: mouse_point,
            user_id: lineId,
            pen_color: penColor,
            pen_width: penWidth,
            board_event_type: 1,
          });
          lineListData = [];
        }
        brush.onMouseMove(
          { x: pointer.x, y: pointer.y },
          { e: { isPrimary: true } }
        );
        break;
      case OPERATION_TYPE.SHAPE: //基本図形
        switch (shapeType) {
          case SHAPE_TYPE.SQUARE:
            if (origX > pointer.x) {
              addShapeRect.set({
                left: Math.abs(pointer.x),
              });
            }
            if (origY > pointer.y) {
              addShapeRect.set({
                top: Math.abs(pointer.y),
              });
            }
            addShapeRect.set({
              width: Math.abs(origX - pointer.x),
            });
            addShapeRect.set({
              height: Math.abs(origY - pointer.y),
            });
            FabCanvas.renderAll();
            break;
          case SHAPE_TYPE.LINE:
            mousePoint.x = pointer.x;
            mousePoint.y = pointer.y;
            addShapeLine.set({
              x2: pointer.x,
              y2: pointer.y,
            });
            FabCanvas.renderAll();
            break;
          case SHAPE_TYPE.CIRCLE:
            addShapeCircle.set({
              radius: Math.abs(origX - pointer.x),
            });
            FabCanvas.renderAll();
            break;
          case SHAPE_TYPE.TRIANGLE:
            if (origX > pointer.x) {
              addShapeTriangle.set({
                left: pointer.x,
              });
            }
            if (origY > pointer.y) {
              addShapeTriangle.set({
                top: pointer.y,
              });
            }
            addShapeTriangle.set({
              width: Math.abs(origX - pointer.x),
            });
            addShapeTriangle.set({
              height: Math.abs(origY - pointer.y),
            });
            FabCanvas.renderAll();
            break;
        }
        break;
    }
  };

  const mouUp = (event: any) => {
    if (!drowing) return;
    drowing = false;
    switch (operation) {
      case OPERATION_TYPE.PEN: //ペン
        var pointer = FabCanvas.getPointer(event);
        lineNo++;
        if (lineListData.length === 1) {
          lineListData.push({
            point: { x: pointer.x - 1, y: pointer.y - 1 },
            no: lineNo,
            flg: "E",
          });
        } else {
          lineListData.push({
            point: { x: pointer.x, y: pointer.y },
            no: lineNo,
            flg: "E",
          });
        }
        var mouse_point = lineListData;
        sendAction(actionComand.MSG_WB_DRAW, null, {
          mouse_point: mouse_point,
          user_id: lineId,
          pen_color: penColor,
          pen_width: penWidth,
          board_event_type: 1,
        });
        lineListData = [];
        brush.onMouseUp({ e: { isPrimary: true } });
        break;
      case OPERATION_TYPE.SHAPE: //基本図形
        FabCanvas.isDrawingMode = false;
        operation = "";
        switch (shapeType) {
          case SHAPE_TYPE.SQUARE:
            sendAction(actionComand.MSG_WB_DRAW, userId, {
              shape_data: addShapeRect,
              shape_id: shapeId,
              board_event_type: 5,
            });
            break;
          case SHAPE_TYPE.LINE:
            sendAction(actionComand.MSG_WB_DRAW, userId, {
              shape_data: addShapeLine,
              shape_id: shapeId,
              mouse_point: mousePoint,
              board_event_type: 3,
            });
            break;
          case SHAPE_TYPE.CIRCLE:
            sendAction(actionComand.MSG_WB_DRAW, userId, {
              shape_data: addShapeCircle,
              shape_id: shapeId,
              board_event_type: 6,
            });
            break;
          case SHAPE_TYPE.TRIANGLE:
            sendAction(actionComand.MSG_WB_DRAW, userId, {
              shape_data: addShapeTriangle,
              shape_id: shapeId,
              board_event_type: 4,
            });
            break;
        }
        break;
    }
    lineList = {};
    shapeList = {};
  };

  const objectChange = (e: any) => {
    sendAction(actionComand.MSG_WB_DRAW, null, {
      shape_data: e.target.toObject(),
      shape_id: e.target.globalCompositeOperation,
      board_event_type: 9,
    });
  };
  const tapMsg = FormatMessage({ id: "Cls012.safari.tap" });
  const [tapOpen, setTapOpen] = useState(false);
  const checkBrowserType = async () => {
    var userAgent = window.navigator.userAgent.toLowerCase();
    if (
      userAgent.indexOf("msie") !== -1 ||
      userAgent.indexOf("trident") !== -1
    ) {
      browserType = "Internet Explorer";
    } else if (userAgent.indexOf("edge") !== -1) {
      browserType = "Edge";
    } else if (userAgent.indexOf("chrome") !== -1) {
      browserType = "Google Chrome";
      if (deviceType === "Phone") {
        setTapOpen(true);
      }
    } else if (userAgent.indexOf("crios") !== -1) {
      browserType = "Google Chrome";
      if (deviceType === "Phone") {
        setTapOpen(true);
      }
    } else if (userAgent.indexOf("safari") !== -1) {
      browserType = "Safari";
      if (userAgent.indexOf("iPhone") > 0 || userAgent.indexOf("iPad") > 0) {
        setTapOpen(true);
      } else if (typeof document.ontouchstart !== "undefined") {
        setTapOpen(true);
      }
    }
  };

  const makeFabCanvas = () => {
    FabCanvas = new fabric.Canvas("canvas");
    FabCanvas.setHeight(Number(canvasHeight));
    FabCanvas.setWidth(Number(canvasWide));
    FabCanvas.backgroundColor = "#fff";
    // FabCanvas.freeDrawingBrush = "";
    // var canvasW = defaultWidth;
    // var canvasH = defaultWidth / (16 / 9);
    // imgBackground = new fabric.Rect({
    //   globalCompositeOperation: "back_ground_color",
    //   width: canvasW,
    //   height: canvasH,
    //   selectable: false,
    //   evented: false,
    //   left: 0,
    //   top: 0,
    //   fill: "white",
    // });
    // FabCanvas.add(imgBackground);
    // imgBackground.moveTo(0);

    brush = new fabric.PencilBrush();
    brush.initialize(FabCanvas);
    fabric.Image.fromURL(finger, function (oImg: any) {
      handImg = oImg;
      handImg.scaleToWidth(30);
      handImg.scaleToHeight(30);
    });

    FabCanvas._onMouseDownInDrawingMode = function (event: any) {
      mouDown(event);
    };

    FabCanvas._onMouseMoveInDrawingMode = function (event: any) {
      mouMove(event);
    };

    FabCanvas._onMouseUpInDrawingMode = function (event: any) {
      mouUp(event);
    };

    FabCanvas.on("mouse:down", function () {});

    FabCanvas.on("mouse:move", function () {});

    FabCanvas.on("mouse:up", function (event: any) {
      mouUp(event);
    });

    FabCanvas.on("selection:created", function (e: any) {
      if (setActive) return;
      if (!onWrittenFlg) {
        FabCanvas.discardActiveObject();
      } else if (e.target._objects && e.target._objects.length > 0) {
        FabCanvas.discardActiveObject();
      } else if (e.target.type === "path") {
        FabCanvas.discardActiveObject();
      }
    });

    FabCanvas.on("path:created", function (event: any) {
      if (lineId !== "") {
        event.path.set({
          globalCompositeOperation: lineId,
          selectable: false,
          hoverCursor: "default",
        });
        // event.path.name = lineId;
        // event.path.globalCompositeOperation = lineId;
        // event.path.selectable = false;
        // event.path.hoverCursor = "default";
        // lineList[lineId] = event.path;
        // sendAction(actionComand.MSG_WB_DRAW, null, {
        //   mouse_point: event.path.path,
        //   user_id: lineId,
        //   pen_color: penColor,
        //   pen_width: penWidth,
        //   board_event_type: 1,
        // });
      }
      lineId = "";
    });

    FabCanvas.on("object:moved", function (event: any) {
      objectChange(event);
    });

    FabCanvas.on("object:scaled", function (event: any) {
      objectChange(event);
    });

    FabCanvas.on("object:rotated", function (event: any) {
      objectChange(event);
    });

    FabCanvas.on("object:skewed", function (event: any) {
      objectChange(event);
    });
  };

  const makeGraphFabCanvas = () => {
    GraphFabCanvas = new fabric.Canvas("canvasGraph");
    GraphFabCanvas.setHeight(Number(canvasHeight));
    GraphFabCanvas.setWidth(Number(canvasWide));
  };

  const handleQuestionClickClose = () => {
    setQuestionDialogOpen(false);
    setQuestionResult(null);
  };

  const sendToSelectChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const sendTo = String(event.target.value);
    setSelectSendTo({
      selVal: sendTo,
    });
    const elem: any = document.activeElement;
    if (elem !== null) elem.blur();
  };

  // 択一問題回答登録
  const sendQuizAnswer = async (answerNo: any, answer: any) => {
    const nowTimedef = new Date();
    setQuestionResult(null);
    const nowTime = nowTimedef.getTime() + timeLag;
    let list = JSON.stringify({
      actionId: actionComand.MSG_QUIZ_ANSWER,
      userId: null,
      currentTime: nowTime,
      option: {
        answer_no: answer,
        answer_id: answerNo,
        send_user_id: userId,
      },
      roomId: lectureChannelId,
      classId: classId,
      sessionId: localStorage.getItem("session_id")
        ? localStorage.getItem("session_id")
        : null,
    });
    channelRTM.sendMessage({
      text: JSON.stringify({
        action: "MESSAGE_SEND",
        message: list,
        roomId: lectureChannelId,
      }),
    });
    // socket.send(
    //   JSON.stringify({
    //     action: "MESSAGE_SEND",
    //     message: list,
    //     roomId: lectureChannelId,
    //   })
    // );
    const result = await QZ0305(
      dispatch,
      classId,
      quizId,
      String(nowTimedef),
      Number(answerNo),
      answer,
      nowTime
    );
    if (!result) {
      await QZ0305(
        dispatch,
        classId,
        quizId,
        String(nowTimedef),
        Number(answerNo),
        answer,
        nowTime
      );
    }
  };

  type inputProps = {
    row: any;
  };

  // const QuizOptionButton: React.FC<inputProps> = ({ row = {} }) => {
  //   return (
  //     <Button
  //       variant="contained"
  //       onClick={() => {
  //         disabled = true;
  //         sendQuizAnswer(row.answer_no);
  //       }}
  //       disabled={disabled}
  //     >
  //       {row.answer}
  //     </Button>
  //   );
  // };

  // 択一問題結果描写
  const drawShowAnswer = (mergeShowAnswer: any, zoom: number) => {
    if (graphShapeList[questionListsColor.shape_id]) {
      GraphFabCanvas.remove(graphShapeList[questionListsColor.shape_id]);
      delete graphShapeList[questionListsColor.shape_id];
    }
    const gap_percentage = GAP_PERCENTAGE_SELECT_QUESTIONS;
    const rectHScale = (100 - gap_percentage * 11) / 10 / 100;
    const clientHeightformated = ctx.canvas.clientHeight / zoom;
    const clientWidthformated = ctx.canvas.clientWidth / zoom;
    const barHeight = clientHeightformated * rectHScale;
    questionListsColor.shape_id = "shape_" + userId + "_drawShowAnswer";
    const textBoxWidth = barHeight * 1.4;
    const fontSize = barHeight * 0.6;

    let answers: any = [];
    if (mergeShowAnswer.length > 0) {
      // eslint-disable-next-line array-callback-return
      mergeShowAnswer.map((answer: any, index: number) => {
        const rect = new fabric.Rect({
          left: 0,
          top:
            barHeight * index +
            clientHeightformated * (gap_percentage / 100) * (index + 1),
          originX: "left",
          originY: "top",
          width: (clientWidthformated - textBoxWidth) * (answer.percent / 100),
          height: barHeight,
          angle: 0,
          fill: questionListsColor["answer_" + String(answer.answer_no)],
          strokeWidth: 2,
          stroke: "black",
          opacity: 0.75,
          scaleX: 1, //初期倍率
          scaleY: 1, //初期倍率
          selectable: false,
          transparentCorners: false,
          noScaleCache: false,
          strokeUniform: false,
        });
        const text = new fabric.Textbox(
          percentFlg ? String(answer.percent) + "%" : String(answer.count),
          {
            originX: "center",
            originY: "center",
            width: textBoxWidth,
            top:
              barHeight * index +
              clientHeightformated * (gap_percentage / 100) * (index + 1) +
              barHeight * 0.5,
            left:
              (clientWidthformated - textBoxWidth) * (answer.percent / 100) +
              textBoxWidth * 0.5,
            fontSize: fontSize,
            textAlign: "center",
          }
        );
        answers = [...answers, rect, text];
      });
    }
    const answerGroup = new fabric.Group(answers, {
      left: 0,
      top: clientHeightformated * (gap_percentage / 100),
    });
    graphShapeList[questionListsColor.shape_id] = answerGroup;
    GraphFabCanvas.add(answerGroup);
    GraphFabCanvas.renderAll();
    return false;
  };

  // ユーザのリアクション数描写
  const drawUserReactionCount = (reactionCounts: any, zoom: number) => {
    if (graphShapeList[drawReactionCountInfo.shape_id]) {
      GraphFabCanvas.remove(graphShapeList[drawReactionCountInfo.shape_id]);
      delete graphShapeList[drawReactionCountInfo.shape_id];
    }

    const clientHeightformated = ctx.canvas.clientHeight / zoom;
    const clientWidthformated = ctx.canvas.clientWidth / zoom;
    const barWidth = clientWidthformated / 16;
    drawReactionCountInfo.shape_id =
      "shape_" + userId + "_drawUserReactionCount";
    const fontSize = barWidth * 0.6;
    const overHeight = clientHeightformated / 10;

    let count: any = [];
    if (
      reactionCounts.REACTION_OK > 0 ||
      reactionCounts.REACTION_NG > 0 ||
      reactionCounts.REACTION_HAND > 0
    ) {
      const dummyCanvasRect = new fabric.Rect({
        left: 0,
        top: -overHeight,
        originX: "left",
        originY: "top",
        width: clientWidthformated,
        height: clientHeightformated + overHeight,
        angle: 0,
        fill: "white",
        strokeWidth: 0,
        stroke: "white",
        opacity: 0,
        scaleX: 1, //初期倍率
        scaleY: 1, //初期倍率
        selectable: false,
        transparentCorners: false,
        noScaleCache: false,
        strokeUniform: false,
      });
      count = [...count, dummyCanvasRect];

      Object.keys(reactionCounts).forEach((key: string, index: number) => {
        const rect = new fabric.Rect({
          left: clientWidthformated / 2 - barWidth * 2 + barWidth * 1.5 * index,
          top:
            clientHeightformated -
            (clientHeightformated / 100) * reactionCounts[key],
          originX: "left",
          originY: "top",
          width: barWidth,
          height: (clientHeightformated / 100) * reactionCounts[key],
          angle: 0,
          fill: drawReactionCountInfo["COLOR_" + key],
          strokeWidth: 2,
          stroke: "black",
          opacity: 0.75,
          scaleX: 1, //初期倍率
          scaleY: 1, //初期倍率
          selectable: false,
          transparentCorners: false,
          noScaleCache: false,
          strokeUniform: false,
        });
        const emoji = new fabric.Textbox(
          setData["simple_reaction_" + String(index + 1)],
          {
            left:
              clientWidthformated / 2 - barWidth * 2 + barWidth * 1.5 * index,
            top:
              clientHeightformated -
              (clientHeightformated / 100) * reactionCounts[key] +
              barWidth / 4,
            originX: "left",
            originY: "top",
            width: barWidth,
            fontSize: fontSize,
            opacity: 0.75,
            textAlign: "center",
          }
        );
        count = [...count, rect, emoji];
      });
    } else {
      GraphFabCanvas.remove(graphShapeList[drawReactionCountInfo.shape_id]);
      delete graphShapeList[drawReactionCountInfo.shape_id];
      GraphFabCanvas.renderAll();
    }
    const countGroup = new fabric.Group(count, {
      left: 0,
      top: -overHeight,
    });
    graphShapeList[drawReactionCountInfo.shape_id] = countGroup;
    GraphFabCanvas.add(countGroup);
    GraphFabCanvas.renderAll();
  };

  // ？反応
  const sendFillQuestion = () => {
    // if (!sendReTime) sendReTime = new Date().getTime();
    const nowTime = new Date().getTime() + timeLag;
    // if (nowTime - sendReTime < 1000) return;
    let list = JSON.stringify({
      actionId: actionComand.MSG_REACTION,
      userId: userId,
      currentTime: nowTime,
      option: {
        reaction: 2,
        send_user_id: userId,
        send_user_name: userName,
        send_handle_name: handleName,
      },
      roomId: lectureChannelId,
      classId: classId,
      sessionId: localStorage.getItem("session_id")
        ? localStorage.getItem("session_id")
        : null,
    });
    channelRTM?.sendMessage({
      text: JSON.stringify({
        action: "MESSAGE_SEND",
        message: list,
        roomId: lectureChannelId,
      }),
    });
    // ST0323(dispatch, classId, nowTime, userId, null, null, "2");
    reactionList.push(JSON.parse(list));
    reactionDoing();
    if (!b2) b2 = 0;
    if (b2 === 0)
      setTimeout(() => {
        setB2ColorFlg(false);
        b2 = 0;
      }, 2000);
    b2++;
    if (b2 > 4) setB2ColorFlg(true);
    // socket.send(
    //   JSON.stringify({
    //     action: "MESSAGE_SEND",
    //     message: list,
    //     roomId: lectureChannelId,
    //   })
    // );
    // sendReTime = new Date().getTime();
    // setMessage((prev) => [
    //   ...prev,
    //   {
    //     userName: "",
    //     chatMessageText: "",
    //     chatType: 5,
    //     sendToClass: null,
    //   },
    // ]);

    // let msgInsert = document.getElementsByClassName("msg-insert");
    // for (let index = 0; index < msgInsert.length; index++) {
    //   let div = document.createElement("div");
    //   div.setAttribute("class", "msg-receives");
    //   let span = document.createElement("span");
    //   span.append("❌");
    //   div.append(span);
    //   msgInsert[index].append(div);
    // }
    // const clist = document.getElementsByClassName("chat-box");
    // //スクロールバーの位置をリストの最下部に設定
    // if (clist && clist.length > 0) {
    //   clist[0].scrollTop = clist[0].scrollHeight;
    //   clist[1].scrollTop = clist[1].scrollHeight;
    // }
  };

  // good反応
  const sendFillLike = () => {
    // if (!sendReTime) sendReTime = new Date().getTime();
    const nowTime = new Date().getTime() + timeLag;
    // if (nowTime - sendReTime < 1000) return;
    let list = JSON.stringify({
      actionId: actionComand.MSG_REACTION,
      userId: userId,
      currentTime: nowTime,
      option: {
        reaction: 1,
        send_user_id: userId,
        send_user_name: userName,
        send_handle_name: handleName,
      },
      roomId: lectureChannelId,
      classId: classId,
      sessionId: localStorage.getItem("session_id")
        ? localStorage.getItem("session_id")
        : null,
    });
    channelRTM?.sendMessage({
      text: JSON.stringify({
        action: "MESSAGE_SEND",
        message: list,
        roomId: lectureChannelId,
      }),
    });
    // ST0323(dispatch, classId, nowTime, userId, null, null, "1");
    reactionList.push(JSON.parse(list));
    reactionDoing();
    if (!b1) b1 = 0;
    if (b1 === 0)
      setTimeout(() => {
        setB1ColorFlg(false);
        b1 = 0;
      }, 2000);
    b1++;
    if (b1 > 4) setB1ColorFlg(true);
    // socket.send(
    //   JSON.stringify({
    //     action: "MESSAGE_SEND",
    //     message: list,
    //     roomId: lectureChannelId,
    //   })
    // );
    // sendReTime = new Date().getTime();
    // setMessage((prev) => [
    //   ...prev,
    //   {
    //     userName: "",
    //     chatMessageText: "",
    //     chatType: 4,
    //     sendToClass: null,
    //   },
    // ]);
    // let msgInsert = document.getElementsByClassName("msg-insert");
    // for (let index = 0; index < msgInsert.length; index++) {
    //   let div = document.createElement("div");
    //   div.setAttribute("class", "msg-receives");
    //   let span = document.createElement("span");
    //   span.append("🙂");
    //   div.append(span);
    //   msgInsert[index].append(div);
    // }
    // const clist = document.getElementsByClassName("chat-box");
    // //スクロールバーの位置をリストの最下部に設定
    // if (clist && clist.length > 0) {
    //   clist[0].scrollTop = clist[0].scrollHeight;
    //   clist[1].scrollTop = clist[1].scrollHeight;
    // }
  };

  // 手を挙げる反応
  const sendHandsUp = () => {
    // if (!sendReTime) sendReTime = new Date().getTime();
    const nowTime = new Date().getTime() + timeLag;
    // if (nowTime - sendReTime < 1000) return;
    let list = JSON.stringify({
      actionId: actionComand.MSG_REACTION,
      userId: userId,
      currentTime: nowTime,
      option: {
        reaction: 3,
        send_user_id: userId,
        send_user_name: userName,
        send_handle_name: handleName,
      },
      roomId: lectureChannelId,
      classId: classId,
      sessionId: localStorage.getItem("session_id")
        ? localStorage.getItem("session_id")
        : null,
    });
    channelRTM?.sendMessage({
      text: JSON.stringify({
        action: "MESSAGE_SEND",
        message: list,
        roomId: lectureChannelId,
      }),
    });
    // ST0323(dispatch, classId, nowTime, userId, null, null, "3");
    reactionList.push(JSON.parse(list));
    reactionDoing();
    if (!b3) b3 = 0;
    if (b3 === 0)
      setTimeout(() => {
        setB3ColorFlg(false);
        b3 = 0;
      }, 2000);
    b3++;
    if (b3 > 4) setB3ColorFlg(true);
    // socket.send(
    //   JSON.stringify({
    //     action: "MESSAGE_SEND",
    //     message: list,
    //     roomId: lectureChannelId,
    //   })
    // );
    // sendReTime = new Date().getTime();
    // setMessage((prev) => [
    //   ...prev,
    //   {
    //     userName: "",
    //     chatMessageText: "",
    //     chatType: 6,
    //     sendToClass: null,
    //   },
    // ]);
    // let msgInsert = document.getElementsByClassName("msg-insert");
    // for (let index = 0; index < msgInsert.length; index++) {
    //   let div = document.createElement("div");
    //   div.setAttribute("class", "msg-receives");
    //   let span = document.createElement("span");
    //   span.append("✋");
    //   div.append(span);
    //   msgInsert[index].append(div);
    // }
    // const clist = document.getElementsByClassName("chat-box");
    // //スクロールバーの位置をリストの最下部に設定
    // if (clist && clist.length > 0) {
    //   clist[0].scrollTop = clist[0].scrollHeight;
    //   clist[1].scrollTop = clist[1].scrollHeight;
    // }
  };

  const addBrushOn = () => {
    isBrush = true;
    FabCanvas.isDrawingMode = false;
    FabCanvas.selection = false;
    FabCanvas.off("mouse:down");
    FabCanvas.off("mouse:move");
    FabCanvas.off("mouse:up");
    FabCanvas.off("selection:created");
    FabCanvas.defaultCursor = "grabbing";
    FabCanvas.hoverCursor = "grabbing";
    FabCanvas.on("selection:created", function () {
      if (setActive) return;
      FabCanvas.discardActiveObject();
    });
    FabCanvas.on("mouse:down", function () {
      drowing = true;
      delIdList = [];
    });
    FabCanvas.on("mouse:move", function (event: any) {
      if (drowing) {
        let target = FabCanvas.findTarget(event.e);
        if (target) {
          var type = target.type;
          if (type) {
            if (type === "path") {
              if (
                target.globalCompositeOperation &&
                // lineList[target.globalCompositeOperation] &&
                target.globalCompositeOperation.indexOf(userId + userNo) >= 0
              ) {
                delIdList.push(target.globalCompositeOperation);
                // delete lineList[target.globalCompositeOperation];
                FabCanvas.remove(event.target);
              }
            } else {
              if (
                target.globalCompositeOperation &&
                // shapeList[target.globalCompositeOperation] &&
                target.globalCompositeOperation.indexOf(userId + userNo) >= 0
              ) {
                delIdList.push(target.globalCompositeOperation);
                // delete shapeList[target.globalCompositeOperation];
                FabCanvas.remove(event.target);
              }
            }
          }
        }
      }
    });
    FabCanvas.on("mouse:up", function () {
      drowing = false;
      if (delIdList.length > 0) {
        sendAction(actionComand.MSG_WB_DRAW, null, {
          brush_id: delIdList,
          board_event_type: 8,
        });
      }
    });
  };

  const addBrushOff = () => {
    if (isBrush) {
      isBrush = false;
      FabCanvas.selection = true;
      FabCanvas.defaultCursor = "default";
      FabCanvas.hoverCursor = "move";
      FabCanvas.off("mouse:down");
      FabCanvas.off("mouse:move");
      FabCanvas.off("mouse:up");
      FabCanvas.off("selection:created");
      FabCanvas.on("mouse:down", function () {});
      FabCanvas.on("mouse:move", function () {});
      FabCanvas.on("mouse:up", function (event: any) {
        if (drowing) mouUp(event);
      });
      FabCanvas.on("selection:created", function (e: any) {
        if (setActive) return;
        if (e.target._objects && e.target._objects.length > 0) {
          FabCanvas.discardActiveObject();
        } else if (e.target.type === "path") {
          FabCanvas.discardActiveObject();
        } else if (
          e.target.type !== "path" &&
          e.target.globalCompositeOperation.indexOf(userId + userNo) < 0
        ) {
          FabCanvas.discardActiveObject();
        }
      });
    }
  };

  const allClear = () => {
    clearUserData(userId);
    sendAction(actionComand.MSG_WB_DRAW, null, {
      clear_uesr_id: userId + userNo,
      board_event_type: 10,
    });
    setDialogOpen(false);
  };

  const clearUserData = (userId: string, flg?: any) => {
    let delId = flg ? userId : userId + userNo;
    let objects = FabCanvas.getObjects();
    if (objects.length > 0) {
      for (let index = 0; index < objects.length; index++) {
        const element = objects[index];
        if (element.globalCompositeOperation.indexOf(delId) >= 0)
          FabCanvas.remove(element);
      }
    }
    // Object.keys(lineList).forEach((key: string) => {
    //   if (key.indexOf(delId) >= 0) {
    //     FabCanvas.remove(lineList[key]);
    //     delete lineList[key];
    //   }
    // });
    // Object.keys(shapeList).forEach((key: string) => {
    //   if (key.indexOf(delId) >= 0) {
    //     FabCanvas.remove(shapeList[key]);
    //     delete shapeList[key];
    //   }
    // });
  };

  const [dialogOpen, setDialogOpen] = useState(false);
  const handleClickOpen = () => {
    addBrushOff();
    setDialogOpen(true);
  };

  const handleClickAllResetClose = () => {
    setDialogOpen(false);
  };

  const enterClick = (event: any) => {
    if (event.shiftKey === false && event.keyCode === 13) {
      clickEnter = true;
    }
  };

  const popState = (e: any) => {
    if (store.getState().router.location.pathname === "/cls/012") {
      window.history.go(1);
    }
  };

  const [soundConfirmOpen, setSoundConfirmOpen] = useState(false);
  const clickSoundConfirmClose = () => {
    setSoundConfirmOpen(false);
    soundSilent();
  };

  let music: any;

  //音声をロード（iPad,iPhone用）
  const soundSilent = () => {
    music = new Audio(chimeMelody);
    if (music) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      music.load;
    }
  };

  /*const sendAgoraUid = async () => {
    const nowTime = new Date().getTime();
    let list = JSON.stringify({
      actionId: actionComand.MSG_AGORAID,
      userId: null,
      currentTime: nowTime,
      option: {
        send_user_id: userId,
        send_user_handle_name: handleName,
        send_user_agoraid: agoraUserId,
      },
      roomId: lectureChannelId,
      classId: classId,
      sessionId: localStorage.getItem("session_id")
        ? localStorage.getItem("session_id")
        : null,
    });
    if (socket && agoraUserId !== "" && socket.readyState === 1) {
      socket.send(
        JSON.stringify({
          action: "MESSAGE_SEND",
          message: list,
          roomId: lectureChannelId,
        })
      );
    }
  };*/

  const socketSendData = () => {
    if (socket && socket.readyState && socket.readyState === 1) {
      const nowTime = new Date().getTime();
      // let list = JSON.stringify({
      //   actionId: actionComand.MSG_SETTNG_REQUEST,
      //   userId: null,
      //   currentTime: nowTime,
      //   option: {
      //     send_user_id: userId,
      //   },
      //   roomId: lectureChannelId,
      //   classId: classId,
      //   sessionId: localStorage.getItem("session_id")
      //     ? localStorage.getItem("session_id")
      //     : null,
      // });
      // socket.send(
      //   JSON.stringify({
      //     action: "MESSAGE_SEND",
      //     message: list,
      //     roomId: lectureChannelId,
      //   })
      // );

      let boardList = JSON.stringify({
        actionId: actionComand.MSG_WB_REQUEST,
        userId: null,
        currentTime: nowTime,
        option: {
          send_user_id: userId,
          send_user_handle_name: handleName,
          send_user_agoraid: agoraUserId,
        },
        roomId: lectureChannelId,
        classId: classId,
        sessionId: localStorage.getItem("session_id")
          ? localStorage.getItem("session_id")
          : null,
      });
      socket.send(
        JSON.stringify({
          action: "MESSAGE_SEND",
          message: boardList,
          roomId: lectureChannelId,
        })
      );
      // let agoralist = JSON.stringify({
      //   actionId: actionComand.MSG_AGORAID,
      //   userId: null,
      //   currentTime: nowTime,
      //   option: {
      //     send_user_id: userId,
      //     send_user_handle_name: handleName,
      //     send_user_agoraid: agoraUserId,
      //   },
      //   roomId: lectureChannelId,
      //   classId: classId,
      //   sessionId: localStorage.getItem("session_id")
      //     ? localStorage.getItem("session_id")
      //     : null,
      // });
      // if (agoraUserId !== "") {
      //   socket.send(
      //     JSON.stringify({
      //       action: "MESSAGE_SEND",
      //       message: agoralist,
      //       roomId: lectureChannelId,
      //     })
      //   );
      // }
    } else {
      setTimeout(() => socketSendData(), 500);
    }
  };

  const sessionCheck = async () => {
    getSessionUser().then((result) => {
      if (result === false) {
        // clearInterval(sessionInterval);
        if (!recordInterval) clearInterval(recordInterval);
        getOut(true);
      }
    });
  };

  useEffect(() => {
    /*if (!getDateValue || getDateValue === "") {
      localStorage.removeItem("playclass");
      localStorage.removeItem("websdk_ng_global_parameter");
      localStorage.removeItem("ClassClose");
      localStorage.removeItem("Cls012_session");
      window.close();
      //dispatch(push("/top/002"));
      return;
    }*/
    ST0326(true);
    soundSilent();
    checkDeviceType();
    Users = [];
    if (deviceType !== "PC") maxHeightMessageDiv = 960;

    //var userAgent = navigator.userAgent;
    //if (userAgent.indexOf("iPhone") > 0) {
    //setSoundConfirmOpen(true);
    //}
    /*window.onbeforeunload = function () {
      localStorage.removeItem("playclass");
      localStorage.removeItem("websdk_ng_global_parameter");
      localStorage.removeItem("ClassClose");
    };
    if (localStorage.getItem("Cls012_session")) {
      localStorage.setItem(
        "playclass",
        String(localStorage.getItem("Cls012_session"))
      );
    }
    if (localStorage.getItem("ClassClose") === "1") {
      localStorage.removeItem("playclass");
      localStorage.removeItem("websdk_ng_global_parameter");
      localStorage.removeItem("ClassClose");
      localStorage.removeItem("Cls012_session");
      window.open("about:blank", "_self")?.close();
    }
    window.onpagehide = function () {
      localStorage.removeItem("playclass");
      localStorage.removeItem("websdk_ng_global_parameter");
      localStorage.removeItem("ClassClose");
    };
    window.onunload = function () {
      localStorage.removeItem("playclass");
      localStorage.removeItem("websdk_ng_global_parameter");
      localStorage.removeItem("ClassClose");
    };
*/
    socket?.close();
    checkStreamer = false;
    socket = null;
    firstJoin = false;
    uploadFlg = false;
    chatsedFlg = false;
    joinList = [];
    nonStreamerFlg = false;
    endLec = false;
    endFlg = false;
    noResize = false;
    agoraUserId = "";
    disabled = false;
    imgLoadFlg = true;
    imgCmdId = -1;
    startTime = 0;
    imgCmdTime = 0;
    boardCmdTime = 0;
    ext = 0;
    boradImgChangeFlg = false;
    selectBoardNo = 1;
    //timeCount = 1;
    expantionData = { scale: 1, point: { scrollTop: 0, scrollLeft: 0 } };
    reactionList = [];
    onWrittenFlg = false;
    changeJsonSaveNo = 0;
    msgLength = 300;
    reactionFlg = false;
    messageFlg = false;
    makeLineFlg = false;
    leftClass = true;
    //viewLogState = viewLogStatus.ini;
    lineList = {};
    brush = null;
    lineId = "";
    shapeId = "";
    lineNo = 0;
    lineListData = null;
    lineData = {};
    lineOrder = [];
    handData = {};
    shapeList = {};
    graphShapeList = {};
    captureFlg = false;
    boradChangeFlg = false;
    isBrush = false;
    delIdList = [];
    reloop = false;
    leaveStreamerCnt = 0;
    alertFlg = false;
    alertCnt = 0;
    classEndFlg = false;
    lowStreamFlg = false;
    incomingCommandList = [];
    questCheckIntervalFlg = false;
    lectureChannelId = null;
    fristWebSocketFlg = false;
    timeLag = 0;
    joinStartTime = 0;
    window.addEventListener("keypress", enterClick);
    noSleep.enable();
    //window.addEventListener("keydown", escClick);

    //全画面表示切替時
    document.addEventListener("fullscreenchange", screenChange);
    document.addEventListener("webkitfullscreenchange", screenChange);
    document.addEventListener("mozfullscreenchange", screenChange);
    document.addEventListener("webkitcurrentfullscreenchange", screenChange);
    document.addEventListener("msfullscreenchange", screenChange);
    //fscreen.addEventListener('fullscreenchange', screenChange, false );
    client.on("user-published", userPublish);
    client.on("user-joined", userJoin);
    client.on("user-left", userLeft);
    client.on("stream-type-changed", (uid: any, mediaType: any) => {
      const Log = [];
      console.log(
        "stream-type-changed" + uid + ":" + JSON.stringify(mediaType)
      );
      Log.push("stream-type-changed" + uid + ":" + JSON.stringify(mediaType));
      uploadLog(Log, "_stream-type-changed_");
    });
    client.on("stream-fallback", (uid: any, isFallbackOrRecover: any) => {
      const Log = [];
      console.log(
        "stream-fallback" + uid + ":" + JSON.stringify(isFallbackOrRecover)
      );
      Log.push(
        "stream-fallback" + uid + ":" + JSON.stringify(isFallbackOrRecover)
      );
      uploadLog(Log, "_stream-fallback_");
    });
    client.on("exception", (event: any) => {
      const Log: any[] = [];
      Log.push("exception code" + event.code);
      Log.push("exception" + JSON.stringify(event));
      uploadLog(Log, "_exception_");
    });
    client.on("crypt-error", (listener: any) => {
      const Log: any[] = [];
      Log.push("crypt-error" + JSON.stringify(listener));
      uploadLog(Log, "_crypt-error_");
    });
    client.on("live-streaming-warning", (warning: any) => {
      const Log: any[] = [];
      Log.push("live-streaming-warning" + JSON.stringify(warning));
      uploadLog(Log, "_live-streaming-warning_");
    });
    client.on("live-streaming-error", (error: any) => {
      const Log: any[] = [];
      Log.push("live-streaming-error" + JSON.stringify(error));
      uploadLog(Log, "_live-streaming-error_");
    });
    client.on("connection-state-change", (event: any) => {
      const Log: any[] = [];
      Log.push(JSON.stringify(event));
      uploadLog(Log, "_RTCconnection-state-change_");
    });

    speedcount = 0;
    summegaBps = 0;
    //NetWorkSpeed();
    clickEnter = false;
    // clearInterval(sessionInterval);
    // clearInterval(recordInterval);
    recordInterval = null;
    streamIsAllDisplay = false;

    getUserListOfJson().then(() => {
      // 授業情報取得
      getClassInfo().then(() => {
        const webSocket = () => {
          const Log: any[] = [];
          if (lectureChannelId) {
            console.log("socket チャンネルID", lectureChannelId);
            Log.push("ChannelId" + lectureChannelId);
            socket = new WebSocket(
              process.env.REACT_APP_WEB_SOCKET_URL +
                lectureChannelId +
                "&userId=" +
                lectureChannelId +
                store.getState().user.user_id +
                store.getState().user.user_class
            );
            Log.push("socket creat OK");
            socket.onopen = () => {
              console.log("socket接続ができました");
              if (!fristWebSocketFlg) fristWebSocketFlg = true;
              Log.push("socket conect OK");
              uploadLog(Log, "_webSocket_");
            };
            socket.onmessage = (event: any) => {
              let data = JSON.parse(event.data);
              console.log(
                "socket受信しました/time:" + new Date().getTime(),
                data
              );
              if (
                data &&
                data.send_no &&
                Number(data.send_no) > 0 &&
                data.save_no &&
                Number(data.save_no) > 0
              ) {
                if (client.connectionState === "DISCONNECTED") {
                  if (
                    agoraOption.appID &&
                    agoraOption.channel &&
                    agoraOption.token
                  ) {
                    let reJoinErr = 0;
                    const reJoin = () => {
                      join(
                        agoraOption.appID,
                        agoraOption.channel,
                        agoraOption.token,
                        userListJson["id"][userId],
                        true,
                        {
                          userId: userId,
                          classId: classId,
                          service_id: serviceId,
                        },
                        true
                      ).then((joinData: any) => {
                        if (
                          joinData &&
                          joinData.state &&
                          joinData.state === "OK"
                        ) {
                          reJoinErr = 0;
                          agoraUserId = joinData.agoraUserId;
                          checkStreamer = true;
                          socketSendData();
                        } else {
                          if (reJoinErr > 3) {
                            alert(DialogText.ClassInfoErr);
                            getOut(true);
                          } else {
                            reJoinErr++;
                            setTimeout(() => reJoin(), 1000);
                          }
                        }
                      });
                    };
                    reJoin();
                  }
                }
              }
              incomingCommand(data);
            };
            socket.onclose = () => {
              console.log("socket接続が切れました");
              Log.push("socket disconect");
              uploadLog(Log, "_webSocket_");
              let path = store.getState().router.location.pathname;
              if (navigator.onLine && !endFlg && path === "/cls/012") {
                // if (socket && socket.readyState === WebSocket.CLOSED)
                //   webSocket();
                const reBack = () => {
                  if (navigator.onLine) {
                    console.log("socket接続 setTimeout", socket);
                    if (socket && socket.readyState === WebSocket.CLOSED)
                      webSocket();
                  } else {
                    setTimeout(() => reBack(), 1000);
                  }
                };
                setTimeout(() => reBack(), 1000);
              }
            };
            // socket.onerror = function (event: any) {
            //   console.log("socket接続エラーが発生しました", event);
            //   socket = null;
            //   Log.push("socket disconect");
            //   uploadLog(Log, "_webSocket_");
            //   let path = store.getState().router.location.pathname;
            //   if (navigator.onLine && !endFlg && path === "/cls/012") {
            //     webSocket();
            //     setTimeout(() => {
            //       if (navigator.onLine) {
            //         if (!socket || socket.readyState === WebSocket.CLOSED)
            //           webSocket();
            //       }
            //     }, 2000);
            //   }
            // };
          } else {
            setTimeout(() => webSocket(), 100);
          }
        };
        webSocket();

        const makePenDoing = setInterval(() => {
          let path = store.getState().router.location.pathname;
          if (!endFlg && path === "/cls/012") {
            makeDrawPen();
          } else {
            clearInterval(makePenDoing);
          }
        }, 100);
        // let checkOffLineCount = 0;
        // const checkWebSocket = setInterval(() => {
        //   let path = store.getState().router.location.pathname;
        //   if (!endFlg && path === "/cls/012") {
        //     if (fristWebSocketFlg) {
        //       if (navigator.onLine) {
        //         console.log("socket接続 checkWebSocket new", socket);
        //         if (socket && socket.readyState === WebSocket.CLOSED)
        //           webSocket();
        //       } else {
        //         console.log("socket接続 checkWebSocket close", socket);
        //         if (socket && socket.readyState === WebSocket.OPEN)
        //           socket.close();
        //       }
        //     }
        //   } else {
        //     clearInterval(checkWebSocket);
        //   }
        // }, 5000);
        const agoraWebS = () => {
          const Log: any[] = [];
          if (lectureChannelId && agoraOption.appID && agoraOption.token_rtm) {
            try {
              let RtmConfig: any = {
                logFilter: AgoraRTM.LOG_FILTER_OFF,
              };
              if (process.env.REACT_APP_AGORA_RTM_SHOW_LOG === "true")
                RtmConfig = {
                  enableLogUpload: true,
                  logFilter: AgoraRTM.LOG_FILTER_WARNING,
                };
              clientRTM = AgoraRTM.createInstance(agoraOption.appID, RtmConfig);
              Log.push("clientRTM creat");
              clientRTM.on(
                "ConnectionStateChanged",
                (newState: any, reason: any) => {
                  const Log: any[] = [];
                  Log.push("newState:" + JSON.stringify(newState));
                  Log.push("reason" + JSON.stringify(reason));
                  uploadLog(Log, "_RTMConnectionStateChanged_");
                }
              );
              clientRTM
                .login({
                  uid: userId + store.getState().user.user_class,
                  token: agoraOption.token_rtm,
                })
                .then((data: any) => {
                  Log.push("clientRTM login");
                  channelRTM = clientRTM.createChannel(lectureChannelId);
                  channelRTM?.on("ChannelMessage", (...args: any) => {
                    try {
                      if (args[0].text !== "chatCheck") {
                        let data = JSON.parse(args[0].text);
                        incomingCommand(JSON.parse(data.message));
                      }
                    } catch (e: any) {
                      console.log("chatReceveErr:", args);
                      setRTMErrFlg(true);
                      ST0302(
                        dispatch,
                        lectureChannelId,
                        userId + store.getState().user.user_class
                      ).then((getData2: any) => {
                        if (getData2 && getData2.state === "0") {
                          agoraOption.token_rtm = getData2.body.token_rtm;
                          channelRTM
                            ?.leave()
                            .then(() => {
                              clientRTM
                                ?.logout()
                                .then(() => {
                                  console.log("RTM Re-Login");
                                  clientRTM
                                    .login({
                                      uid:
                                        userId +
                                        store.getState().user.user_class,
                                      token: agoraOption.token_rtm,
                                    })
                                    .then(() => {
                                      console.log("RTM Re-Join");
                                      channelRTM
                                        ?.join()
                                        .then(() => {
                                          setRTMErrFlg(false);
                                        })
                                        .catch(() => {
                                          setRTMErrFlg(true);
                                        });
                                    })
                                    .catch(() => {
                                      // alert(DialogText.RTMConectErr);
                                      setRTMErrFlg(true);
                                    });
                                })
                                .catch(() => {
                                  // alert(DialogText.RTMConectErr);
                                  setRTMErrFlg(true);
                                });
                            })
                            .catch(() => {
                              // alert(DialogText.RTMConectErr);
                              setRTMErrFlg(true);
                            });
                        }
                      });
                    }
                  });
                  channelRTM?.join().then(() => {
                    Log.push("channelRTM join");
                    setTimeout(RTMLoginCheck, 300000);
                  });
                })
                .catch((e: any) => {
                  setRTMErrFlg(true);
                  console.log("RTM login err", e);
                  Log.push("channelRTM err");
                  Log.push(JSON.stringify(e));
                  agoraWebS();
                });
            } catch (e: any) {
              setRTMErrFlg(true);
              Log.push("channelRTM err");
              Log.push(JSON.stringify(e));
            } finally {
              uploadLog(Log, "_agoraWebS_");
            }
          } else {
            setTimeout(() => agoraWebS(), 100);
          }
        };
        agoraWebS();
        startLecture();

        var userAgent = window.navigator.userAgent.toLowerCase();
        if (
          userAgent.indexOf("ipad") > 0 ||
          (userAgent.indexOf("macintosh") > 0 && "ontouchend" in document)
        ) {
          const Document: any = document;
          var hidden: any, visibilityChange: any;
          if (typeof Document.hidden !== "undefined") {
            hidden = "hidden";
            visibilityChange = "visibilitychange";
          } else if (typeof Document.msHidden !== "undefined") {
            hidden = "msHidden";
            visibilityChange = "msvisibilitychange";
          } else if (typeof Document.webkitHidden !== "undefined") {
            hidden = "webkitHidden";
            visibilityChange = "webkitvisibilitychange";
          }
          var checkVisibilityChange = false;
          const visibilityChangeFun = () => {
            if (fristWebSocketFlg) {
              if (Document[hidden]) {
                console.log("checkVisibilityChange", checkVisibilityChange);
                checkVisibilityChange = true;
                console.log("socket接続 checkVisibilityChange close", socket);
                if (socket && socket.readyState === WebSocket.OPEN)
                  socket.close();
              } else {
                if (checkVisibilityChange) {
                  console.log("checkVisibilityChange", checkVisibilityChange);
                  checkVisibilityChange = false;
                  console.log("socket接続 checkVisibilityChange new", socket);
                  if (socket && socket.readyState === WebSocket.CLOSED)
                    webSocket();
                }
              }
            }
          };
          document.addEventListener(
            visibilityChange,
            visibilityChangeFun,
            false
          );
          const closeVisibilityChangeFun = setInterval(() => {
            let path = store.getState().router.location.pathname;
            if (endLec || path !== "/cls/012") {
              clearInterval(closeVisibilityChangeFun);
              document.removeEventListener(
                visibilityChange,
                visibilityChangeFun,
                false
              );
            }
          }, 1000);
        }
      });
    });
    Promise.all([
      getUserName(),
      // getChatHistry(),
      getImagesData(null),
      // getVdosData(null),
    ]); // ユーザ名取得,チャット履歴取得,画像情報取得,動画情報取得
    //手書きCanvas作成
    makeFabCanvas();
    makeGraphFabCanvas();
    canvas = document.getElementById("canvas");
    // canvasImg = document.getElementById("canvasImg");
    canvasConcat = document.getElementById("canvasConcat");
    drowCanvasDiv = document.getElementById("drowCanvas") as HTMLElement;
    // imgCanvasDiv = document.getElementById("imgCanvas") as HTMLElement;
    canvasFabric = document.getElementsByClassName("upper-canvas");
    ctx = canvas.getContext("2d");
    // ctxI = canvasImg.getContext("2d");
    ctxC = canvasConcat.getContext("2d");

    client.on("network-quality", function (stats: any) {
      if (stats?.downlinkNetworkQuality > stats?.uplinkNetworkQuality) {
        setIngegate(stats?.downlinkNetworkQuality);
      } else {
        setIngegate(stats?.uplinkNetworkQuality);
      }
      if (stats) {
        if (
          stats.downlinkNetworkQuality === 0 ||
          stats.downlinkNetworkQuality === 5 ||
          stats.downlinkNetworkQuality === 6 ||
          stats.uplinkNetworkQuality === 0 ||
          stats.uplinkNetworkQuality === 5 ||
          stats.uplinkNetworkQuality === 6
        ) {
          alertFlg = true;
        } else {
          alertFlg = false;
          alertCnt = 0;
          setNetworkAlertOpen(true);
        }
      }
    });

    const networkCheckInterval = setInterval(() => {
      let path = store.getState().router.location.pathname;
      if (path !== "/cls/012") {
        clearInterval(networkCheckInterval);
      }
      if (alertFlg) {
        alertCnt++;
        if (alertCnt >= 10) {
          setNetworkAlertOpen(false);
        }
      }
    }, 1000);

    window.history.pushState(null, "", window.location.href);
    window.addEventListener("popstate", popState);
    const setWhiteBoard = () => {
      let wb: any = document.getElementsByClassName("whiteBoard");
      if (wb && wb.length > 0) {
        wb[0].addEventListener(
          "mousewheel",
          (e: any) => {
            e.preventDefault();
          },
          { passive: false }
        );
        let keyDownEvent = (e: any) => {
          if (store.getState().router.location.pathname !== "/cls/012") {
            document.removeEventListener("keydown", keyDownEvent);
          }
          if (!noResize && e.keyCode >= 37 && e.keyCode <= 40) {
            e.preventDefault();
          }
        };
        document.addEventListener("keydown", keyDownEvent, { passive: false });
      } else {
        setTimeout(() => setWhiteBoard(), 500);
      }
    };
    setWhiteBoard();
    exitFullStream();
    msgCount = 1;
    chatnotTestFlg = process.env.REACT_APP_AGORA_REC_FLG === "1" ? true : false;

    // 手書き同期するのため
    const whiteBoardInterval = setInterval(() => {
      let path = store.getState().router.location.pathname;
      if (endLec || path !== "/cls/012") {
        clearInterval(whiteBoardInterval);
      } else {
        if (classId && shareWindow.length === 0 && !captureFlg) getSameData();
      }
    }, 10000);

    const conectInterval = setInterval(() => {
      let path = store.getState().router.location.pathname;
      if (path !== "/cls/012") {
        clearInterval(conectInterval);
        if (!endFlg) {
          channelRTM?.leave();
          clientRTM?.logout();
          leave();
        }
        if (micTrack) {
          micTrack.stop();
          micTrack.close();
        }
        if (videoTrack) {
          videoTrack.stop();
          videoTrack.close();
        }
        if (screenIsSmall) {
          exitFullScreen(true);
        }
        localStorage.setItem("Cls012_session", "");
      }
    }, 10000);

    let whiteBoard = document.getElementsByClassName(
      "whiteBoard"
    ) as HTMLCollectionOf<HTMLElement>;
    if (whiteBoard && whiteBoard.length > 0) {
      for (let index = 0; index < whiteBoard.length; index++) {
        const element = whiteBoard[index];
        element.oncontextmenu = function () {
          return false;
        };
      }
    }
    return () => {
      client.off("user-published", userPublish);
      client.off("user-joined", userJoin);
      client.off("user-left", userLeft);
      agoraOption = undefined;
      if (RTMTimerID) {
        clearTimeout(RTMTimerID);
        RTMTimerID = undefined;
      }
      if (getViewUserTimeout) {
        clearTimeout(getViewUserTimeout);
        getViewUserTimeout = undefined;
      }
    };
  }, []);

  useEffect(() => {
    document.addEventListener("fullscreenchange", function (event) {});
    let registeredListener = () => {
      setTimeout(() => {
        winResize();
      }, 500);
    };

    registeredListener();
    window.addEventListener("resize", registeredListener);

    let moveDiv = document.getElementsByClassName(
      "moveMenu"
    ) as HTMLCollectionOf<HTMLElement>;

    let moveDivB = document.getElementsByClassName(
      "moveMenuB"
    ) as HTMLCollectionOf<HTMLElement>;

    function moveAt(pageX: any, pageY: any) {
      if (moveDiv && moveDiv.length > 0) {
        let newLeft = Number(pageX);
        let newTop = Number(pageY);
        moveDiv[0].style.left = newLeft < 1 ? "1" : String(newLeft) + "px";
        moveDiv[0].style.top = newTop < 1 ? "1" : String(newTop) + "px";
      }
    }
    function onMouseMove(event: any) {
      const isSP = window.matchMedia("screen and (max-width: 767px)").matches;
      if (!isSP) {
        moveAt(event.pageX, event.pageY);
      }
    }
    var moveFlg = false;

    moveDivB[0].onmousedown = function () {
      moveFlg = true;
      document.addEventListener("mousemove", onMouseMove);
    };
    document.getElementsByTagName("body")[0].onmouseup = function () {
      if (moveFlg) {
        moveFlg = false;
        document.removeEventListener("mousemove", onMouseMove);
      }
    };
    testFlg = false;
    return () => window.removeEventListener("resize", registeredListener);
  }, [lectureChannelId]);

  const testSendDoing = () => {
    if (testFlg) {
      testFlg = false;
      setTestDoFlg(false);
      clearInterval(testSending);
    } else {
      testFlg = true;
      setTestDoFlg(true);
      testSending = setInterval(() => {
        let path = store.getState().router.location.pathname;
        if (path !== "/cls/012") clearInterval(testSending);
        sendFillLike();
      }, 100);
    }
  };

  const lowStreamTest = async () => {
    lowStreamFlg = !lowStreamFlg;
    const num = lowStreamFlg ? 1 : 0;
    await client.setRemoteVideoStreamType(20000, num);
  };

  const chattestSendDoing = () => {
    if (chattestFlg) {
      chattestFlg = false;
      setChatTestDoFlg(false);
      clearInterval(chattestSending);
    } else {
      chattestFlg = true;
      setChatTestDoFlg(true);
      chattestSending = setInterval(() => {
        let path = store.getState().router.location.pathname;
        if (path !== "/cls/012") clearInterval(chattestSending);
        chatSendClickDamy();
      }, 100);
    }
  };

  const chatSendClickDamy = () => {
    let DamyMsg = "送信" + msgCount;
    msgCount++;
    let list = "";
    let send_to_class = null;
    const nowTime = new Date().getTime() + timeLag;
    switch (chatSendControlType) {
      case 1:
        if (selectSendTo.selVal === "all") {
          list = JSON.stringify({
            actionId: actionComand.MSG_CHAT_SEND_MESSAGE,
            userId: null,
            currentTime: nowTime,
            option: {
              message: DamyMsg,
              send_to_class: null,
              send_user_id: userId,
              send_user_name: userName,
              send_handle_name: handleName,
              class_id: classId,
              session_id: store.getState().user.session_id,
            },
            roomId: lectureChannelId,
            classId: classId,
            sessionId: localStorage.getItem("session_id")
              ? localStorage.getItem("session_id")
              : null,
          });
          send_to_class = null;
        } else {
          list = JSON.stringify({
            actionId: actionComand.MSG_CHAT_SEND_MESSAGE,
            userId: null,
            currentTime: nowTime,
            option: {
              message: DamyMsg,
              send_to_class: 1,
              send_user_id: userId,
              send_user_name: userName,
              class_id: classId,
              send_handle_name: handleName,
              session_id: store.getState().user.session_id,
            },
            roomId: lectureChannelId,
            classId: classId,
            sessionId: localStorage.getItem("session_id")
              ? localStorage.getItem("session_id")
              : null,
          });
          send_to_class = 1;
        }
        break;

      case 2:
        list = JSON.stringify({
          actionId: actionComand.MSG_CHAT_SEND_MESSAGE,
          userId: null,
          currentTime: nowTime,
          option: {
            message: DamyMsg,
            send_to_class: 1,
            send_user_id: userId,
            send_user_name: userName,
            send_handle_name: handleName,
            class_id: classId,
            session_id: store.getState().user.session_id,
          },
          roomId: lectureChannelId,
          classId: classId,
          sessionId: localStorage.getItem("session_id")
            ? localStorage.getItem("session_id")
            : null,
        });
        send_to_class = 1;
        break;

      case 4:
        switch (selectSendTo.selVal) {
          case streamerInfo.streamerId:
            list = JSON.stringify({
              actionId: actionComand.MSG_CHAT_SEND_MESSAGE,
              userId: null,
              currentTime: nowTime,
              option: {
                message: DamyMsg,
                send_to_class: 1,
                send_user_id: userId,
                send_user_name: userName,
                send_handle_name: handleName,
                class_id: classId,
                session_id: store.getState().user.session_id,
              },
              roomId: lectureChannelId,
              classId: classId,
              sessionId: localStorage.getItem("session_id")
                ? localStorage.getItem("session_id")
                : null,
            });
            send_to_class = 1;
            break;

          case "all":
          case null:
            list = JSON.stringify({
              actionId: actionComand.MSG_CHAT_SEND_MESSAGE,
              userId: null,
              currentTime: nowTime,
              option: {
                message: DamyMsg,
                send_to_class: null,
                send_user_id: userId,
                send_user_name: userName,
                send_handle_name: handleName,
                class_id: classId,
                session_id: store.getState().user.session_id,
              },
              roomId: lectureChannelId,
              classId: classId,
              sessionId: localStorage.getItem("session_id")
                ? localStorage.getItem("session_id")
                : null,
            });
            send_to_class = null;
            break;

          default:
            list = JSON.stringify({
              actionId: actionComand.MSG_CHAT_SEND_MESSAGE,
              userId: selectSendTo.selVal,
              currentTime: nowTime,
              option: {
                message: DamyMsg,
                send_to_class: 2,
                send_user_id: userId,
                send_user_name: userName,
                send_handle_name: handleName,
                class_id: classId,
                session_id: store.getState().user.session_id,
              },
              roomId: lectureChannelId,
              classId: classId,
              sessionId: localStorage.getItem("session_id")
                ? localStorage.getItem("session_id")
                : null,
            });
            send_to_class = 2;
        }
        break;
    }
    channelRTM
      .sendMessage({
        text: JSON.stringify({
          action: "MESSAGE_SEND",
          message: list,
          roomId: lectureChannelId,
        }),
      })
      .then(() => {
        setRTMErrFlg(false);
      })
      .catch(() => {
        setRTMErrFlg(false);
      });
    let nowSec =
      startTime && Number(startTime) > 0
        ? Math.floor(Number(nowTime - startTime) / 1000)
        : 0;
    ST0323(
      dispatch,
      classId,
      nowTime,
      "",
      send_to_class,
      DamyMsg,
      String(Number(ext + nowSec)),
      selectSendTo.selVal === "all" ? null : selectSendTo.selVal
    );
    // socket.send(
    //   JSON.stringify({
    //     action: "MESSAGE_SEND",
    //     message: list,
    //     roomId: lectureChannelId,
    //   })
    // );
    let msgInsert: any = document.getElementsByClassName("msg-insert");
    for (let index = 0; index < msgInsert.length; index++) {
      let div = document.createElement("div");
      div.setAttribute("class", "msg-sends");
      let span = document.createElement("span");
      span.append(handleName + ":");
      let messages = DamyMsg.split("\n");
      for (let index = 0; index < messages.length; index++) {
        if (index > 0) span.append(document.createElement("br"));
        span.append(messages[index]);
      }
      div.append(span);

      msgInsert[index].append(div);
      if (
        msgInsert &&
        msgInsert[index] &&
        msgInsert[index].clientHeight &&
        msgInsert[index].clientHeight > maxHeightMessageDiv
      ) {
        for (let i = 0; i < 10; i++) {
          msgInsert[index].children[i].remove();
          if (msgInsert[index].clientHeight < maxHeightMessageDiv) break;
        }
      }
    }
    const clist = document.getElementsByClassName("chat-box");
    //スクロールバーの位置をリストの最下部に設定
    if (clist && clist.length > 0) {
      clist[0].scrollTop = clist[0].scrollHeight;
      clist[1].scrollTop = clist[1].scrollHeight;
    }
  };

  return (
    <div className={classes.root} ref={elm}>
      <Popover
        hideBackdrop={true}
        classes={{
          paper: classes.popoverContent,
        }}
        open={questionDialogOpen}
        anchorEl={questionResult}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <div className="quizOption_content" style={{ padding: "20px 8px" }}>
          {quizOptions.length > 0
            ? quizOptions.map((rows) => (
                <div
                  key={rows.answer_no}
                  className={"quizOption_div answer_" + rows.answer_no}
                >
                  <Button
                    variant="contained"
                    onClick={() => {
                      disabled = true;
                      setQuestionDialogOpen(false);
                      endAnswer = true;
                      sendQuizAnswer(rows.answer_no, rows.answer);
                    }}
                    disabled={disabled}
                    style={{ textTransform: "none" }}
                  >
                    {rows.answer}
                  </Button>
                </div>
              ))
            : null}
        </div>
      </Popover>
      <table width="100%">
        <tbody>
          <tr>
            <td className="tableRight">
              <div
                className="hiddleMenu"
                style={
                  screenIsSmall
                    ? window.outerHeight > window.outerWidth &&
                      window.outerWidth < 500
                      ? {
                          position: "absolute",
                          left: 5,
                          right: 5,
                          bottom: 0,
                          border: "1px solid #ccc",
                          borderRadius: "5px",
                          backgroundColor: "rgb(0 0 0 / 20%)",
                          paddingLeft: "10px",
                          zIndex: 1,
                        }
                      : {
                          position: "absolute",
                          left: 5,
                          right: 5,
                          bottom: 0,
                          border: "1px solid #ccc",
                          borderRadius: "5px",
                          backgroundColor: "rgb(0 0 0 / 20%)",
                          paddingLeft: "10px",
                          display: "flex",
                          zIndex: 1,
                        }
                    : {
                        display: "none",
                      }
                }
              >
                <div className="mt10" style={{ display: "flex", zIndex: 1 }}>
                  <textarea
                    id="textform"
                    className="send_box_textarea"
                    style={{ height: "35px" }}
                    maxLength={150}
                    value={chatMessage}
                    onClick={() => {
                      clickEnter = false;
                    }}
                    onChange={(event: any) => {
                      if (clickEnter) {
                        if (chatSendControlType !== 3) {
                          chatSendClick();
                        }
                        clickEnter = false;
                      } else {
                        setChatMessage(event.target.value);
                      }
                    }}
                    onMouseOver={() => {
                      noResize = true;
                    }}
                    onMouseOut={() => {
                      noResize = false;
                    }}
                  ></textarea>
                  <IconButton
                    aria-label="delete"
                    className="buttonLarge"
                    onClick={chatSendClick}
                    disabled={chatSendControlType === 3 ? true : false}
                  >
                    <SendIcon style={{ color: "#3993b2" }} />
                  </IconButton>
                  <div hidden={!RTMErrFlg}>
                    <LightTooltip
                      title={
                        <React.Fragment>
                          {RTMConectErr1}
                          <br />
                          {RTMConectErr2}
                          <br />
                          {RTMConectErr3}
                        </React.Fragment>
                      }
                      placement="top"
                    >
                      <IconButton aria-label="delete" size="small">
                        <AiOutlineExclamationCircle
                          color="#e32315"
                          style={{ fontSize: "16px" }}
                        />
                      </IconButton>
                    </LightTooltip>
                  </div>
                </div>
                <div className="mt10 alignLeft" style={{ zIndex: 1 }}>
                  <span className="centerIcon">
                    <IconButton
                      aria-label="delete"
                      className="buttonLarge"
                      onClick={sendFillLike}
                    >
                      <SentimentSatisfiedRoundedIcon
                        style={b1ColorFlg ? { color: "red" } : {}}
                      />
                    </IconButton>
                  </span>

                  <span className="centerIcon">
                    <IconButton
                      aria-label="delete"
                      className="buttonLarge"
                      onClick={sendFillQuestion}
                    >
                      <CloseRoundedIcon
                        style={b2ColorFlg ? { color: "red" } : {}}
                      />
                    </IconButton>
                  </span>

                  <span className="centerIcon">
                    <IconButton
                      aria-label="delete"
                      className="buttonLarge"
                      onClick={sendHandsUp}
                    >
                      <FaRegHandPaper
                        style={b3ColorFlg ? { color: "red" } : {}}
                      />
                    </IconButton>
                  </span>
                </div>
              </div>
              <div
                className="moveMenu"
                style={{
                  position: "absolute",
                  top: 5,
                  left: 80,
                  width: "auto",
                  border: "1px solid #ccc",
                  borderRadius: "15px",
                  backgroundColor: "rgb(0 0 0 / 20%)",
                  cursor: "pointer",
                  zIndex: 1,
                  display: refPenDisableFlg.current ? "none" : "block",
                }}
              >
                <div
                  className="moveMenuB"
                  style={{
                    padding: "5px",
                  }}
                ></div>
                <div>
                  {/* 色指定 */}
                  <span className="centerIcon">
                    <LightTooltip
                      title={FormatMessage({ id: "Cls008.ToolTip.Color" })}
                      placement="top"
                    >
                      <IconButton
                        aria-label="delete"
                        className="buttonLarge"
                        onClick={penSetClick}
                      >
                        {/* <Avatar src="/button/b1.JPG" /> */}
                        <FaPencilAlt
                          style={{ color: "rgb(255 255 255 / 50%)" }}
                        />
                      </IconButton>
                    </LightTooltip>
                  </span>
                  <Popper open={openPen} anchorEl={penSet} placement={"top"}>
                    <PenSetting
                      setPenColor={(v: any) => {
                        addBrushOff();
                        setPenSet(null);
                        penColor = v.color;
                        FabCanvas.isDrawingMode = true;
                        FabCanvas.discardActiveObject();
                      }}
                      setPenWidth={(v: any) => {
                        addBrushOff();
                        penWidth = v;
                        setPenSet(null);
                        FabCanvas.isDrawingMode = true;
                        FabCanvas.discardActiveObject();
                      }}
                      setOperetion={(v: any) => {
                        setPenSet(null);
                        if (v === "clear") {
                          addBrushOn();
                        } else {
                          FabCanvas.defaultCursor = "default";
                          FabCanvas.hoverCursor = "move";
                          addBrushOff();
                        }
                        operation = v;
                      }}
                      sendAction={(a1: any, a2: any, a3: any) =>
                        sendAction(a1, a2, a3)
                      }
                      allClear={() => {
                        addBrushOff();
                        allClear();
                        setPenSet(null);
                      }}
                      penColor={penColor}
                      penWidth={penWidth}
                    />
                  </Popper>
                  {/* 消しゴム */}
                  <span className="centerIcon">
                    <LightTooltip
                      title={FormatMessage({ id: "Cls008.ToolTip.Elaser" })}
                      placement="top"
                    >
                      <IconButton
                        aria-label="FaEraser"
                        className="buttonLarge"
                        onClick={() => {
                          operation = OPERATION_TYPE.CLEAR;
                          addBrushOn();
                        }}
                      >
                        <FaEraser style={{ color: "rgb(255 255 255 / 50%)" }} />
                      </IconButton>
                    </LightTooltip>
                  </span>
                  {/* 全消し */}
                  <span className="centerIcon">
                    <LightTooltip
                      title={FormatMessage({ id: "Cls008.ToolTip.Clear" })}
                      placement="top"
                    >
                      <IconButton
                        aria-label="delete"
                        className="buttonLarge"
                        onClick={handleClickOpen}
                      >
                        <FontAwesomeIcon
                          icon={faMagic}
                          style={{ color: "rgb(255 255 255 / 50%)" }}
                        />
                      </IconButton>
                    </LightTooltip>
                  </span>
                  {/* 図形選択 */}
                  <span className="centerIcon">
                    <LightTooltip
                      title={FormatMessage({ id: "Cls008.ToolTip.Shape" })}
                      placement="top"
                    >
                      <IconButton
                        aria-label="delete"
                        className="buttonLarge"
                        onClick={shapeSetClick}
                      >
                        {/* <Avatar src="/button/b2.JPG" /> */}
                        <FaShapes style={{ color: "rgb(255 255 255 / 50%)" }} />
                      </IconButton>
                    </LightTooltip>
                  </span>
                  <Popper
                    open={openShape}
                    anchorEl={shapeSet}
                    placement={"top"}
                  >
                    <ShapeSetting
                      callback={(value: string) => {
                        shapeType = value;
                        operation = "shape";
                        setShapeSet(null);
                        FabCanvas.isDrawingMode = true;
                        FabCanvas.discardActiveObject();
                      }}
                    />
                  </Popper>
                </div>
              </div>
              <div className="whiteBoard" onClick={whiteBClick}>
                <div>
                  <div style={{ float: "left", marginLeft: "5px" }}>
                    <IconButton
                      aria-label="delete"
                      onClick={nameClick}
                      style={{
                        backgroundColor: "rgb(0 0 0 / 26%)",
                        zIndex: 1,
                      }}
                    >
                      <BiMenu
                        style={{
                          fontSize: "30px",
                          color: "rgb(255 255 255 / 26%)",
                        }}
                      />
                    </IconButton>
                    <Menu
                      anchorEl={name}
                      keepMounted
                      open={nameOpen}
                      onClose={nameClick}
                    >
                      <MenuItem button={false}>{className}</MenuItem>
                      <MenuItem onClick={confirmGetOut}>
                        <FontAwesomeIcon icon={faSignOutAlt} />
                        {FormatMessage({
                          id: "Cls012.Button1",
                        })}
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          window.location.reload();
                        }}
                      >
                        <ReplayIcon style={{ fontSize: "1rem" }} />
                        {FormatMessage({
                          id: "Cls012.Button4",
                        })}
                      </MenuItem>
                      <MenuItem button={false}>
                        <img src={educastLogo} height="24" />
                      </MenuItem>
                    </Menu>
                  </div>
                  <div style={{ float: "right", marginRight: "5px" }}>
                    <IconButton
                      aria-label="delete"
                      style={{
                        backgroundColor: "rgb(0 0 0 / 26%)",
                        zIndex: 1,
                        display: streamIsSmall ? "block" : "none",
                      }}
                      onClick={() => {
                        if (screenIsSmall) {
                          setLeftMenu(282);
                          exitFullScreen(true);
                        } else {
                          setLeftMenu(0);
                          requestFullScreen();
                        }
                      }}
                    >
                      {!screenIsSmall ? (
                        <FullscreenIcon
                          style={{
                            fontSize: "30px",
                            color: "rgb(255 255 255 / 50%)",
                          }}
                        />
                      ) : (
                        <FullscreenExitIcon
                          style={{
                            fontSize: "30px",
                            color: "rgb(255 255 255 / 50%)",
                          }}
                        />
                      )}
                    </IconButton>
                  </div>
                  <div
                    style={
                      Number(canvasHeight) > 0
                        ? {
                            float: "right",
                            marginTop: Number(canvasHeight) - 70 + "px",
                            marginRight: "-50px",
                            zIndex: 1,
                            display: streamIsSmall ? "block" : "none",
                          }
                        : {
                            float: "right",
                            marginTop: "5px",
                            marginRight: "-50px",
                            zIndex: 1,
                            display: streamIsSmall ? "block" : "none",
                          }
                    }
                  >
                    <IconButton
                      aria-label="delete"
                      className="buttonLarge"
                      onClick={() => {
                        captureFlg = true;
                        setTimeout(screenShotOnClick, 2000);
                      }}
                      style={{
                        backgroundColor: "rgb(0 0 0 / 26%)",
                        zIndex: 1,
                      }}
                    >
                      <HiSave
                        style={{
                          fontSize: "30px",
                          color: "rgb(255 255 255 / 50%)",
                        }}
                      />
                    </IconButton>
                  </div>
                </div>

                {/* <div
                  id="imgCanvas"
                  style={{
                    overflow: "scroll",
                    WebkitOverflowScrolling: "touch",
                    width: canvasWide,
                    height: canvasHeight,
                    top: 0,
                    padding: 0,
                    backgroundColor: "#ffffff",
                    display: screenShare !== "null" ? "none" : "block",
                  }}
                  className="canvasStyle"
                >
                  <canvas
                    id="canvasConcat"
                    width={canvasWide}
                    height={canvasHeight}
                    style={{ display: "none" }}
                  ></canvas>
                  <canvas
                    id="canvasImg"
                    width={canvasWide}
                    height={canvasHeight}
                    style={{
                      top: 0,
                      left: 0,
                      transformOrigin: "0 0",
                    }}
                  ></canvas>
                </div> */}
                <div
                  id="drowCanvas"
                  style={{
                    overflow: "scroll",
                    WebkitOverflowScrolling: "touch",
                    width: Number(canvasWide) - 1,
                    height: Number(canvasHeight) - 1,
                    top: 0,
                    left: 0,
                    padding: 0,
                    display: screenShare !== "null" ? "none" : "contents",
                  }}
                  className="canvasStyle"
                >
                  <canvas
                    id="canvasConcat"
                    width={canvasWide}
                    height={canvasHeight}
                    style={{ display: "none" }}
                  ></canvas>
                  <canvas
                    id="canvas"
                    width={canvasWide}
                    height={canvasHeight}
                    className="canvasStyle"
                    style={{
                      top: 0,
                      left: 0,
                      transformOrigin: "0 0",
                    }}
                  ></canvas>
                </div>
                {/* <div
                  id="videoDiv"
                  style={{
                    width: canvasWide,
                    height: canvasHeight,
                    top: 0,
                    padding: 0,
                    display: screenShare !== "" ? "none" : "block",
                    backgroundColor: playMovie ? "white" : "",
                  }}
                  className="canvasStyle"
                >
                  {navigator.userAgent.indexOf("iPhone") > 0 ||
                    (navigator.userAgent.includes("Macintosh") &&
                      "ontouchend" in document) ? (
                    <video
                      controls
                      playsInline
                      id="showvideo"
                      width={canvasWide}
                      height={canvasHeight}
                      crossOrigin="anonymous"
                      className="canvasStyle"
                      style={{
                        top: 0,
                        left: 0,
                        height: "100%",
                        transformOrigin: "0 0",
                      }}
                      hidden={playMovie ? false : true}
                      src={classVideo}
                    ></video>
                  ) : (
                    <video
                      playsInline
                      id="showvideo"
                      width={canvasWide}
                      height={canvasHeight}
                      crossOrigin="anonymous"
                      className="canvasStyle"
                      style={{
                        top: 0,
                        left: 0,
                        height: "100%",
                        transformOrigin: "0 0",
                      }}
                      hidden={playMovie ? false : true}
                      src={classVideo}
                    ></video>
                  )}
                </div> */}
                <div
                  id="graphCanvas"
                  style={{
                    overflow: "scroll",
                    WebkitOverflowScrolling: "touch",
                    width: Number(canvasWide) - 1,
                    height: Number(canvasHeight) - 1,
                    top: 0,
                    padding: 0,
                    //display: screenShare !== "null" ? "none" : "block",
                    pointerEvents: "none",
                  }}
                  className="canvasStyle"
                >
                  <canvas
                    id="canvasGraph"
                    width={canvasWide}
                    height={canvasHeight}
                    className="canvasStyle"
                    style={{
                      top: 0,
                      left: 0,
                      transformOrigin: "0 0",
                    }}
                  ></canvas>
                </div>
                {shareWindow.length > 0 ? (
                  <div
                    id="ScreenShare"
                    style={{
                      overflow: "scroll",
                      WebkitOverflowScrolling: "touch",
                      width: Number(canvasWide) + 2,
                      height: Number(canvasHeight) + 2,
                      top: 0,
                      padding: 0,
                      pointerEvents: "none",
                    }}
                    className="canvasStyle"
                  >
                    <MediaPlayer
                      videoTrack={shareWindow[0].videoTrack}
                      audioTrack={shareWindow[0].audioTrack}
                    ></MediaPlayer>
                  </div>
                ) : null}
              </div>
              <div style={{ display: "none" }}>
                {remoteLiveUsers.map((user, index) => (
                  <div key={"remote_live_users_" + index}>
                    <MediaPlayer
                      videoTrack={user.videoTrack}
                      audioTrack={user.audioTrack}
                    ></MediaPlayer>
                  </div>
                ))}
              </div>
            </td>
            <td
              id="left_table"
              className={screenIsSmall ? "nodisplay" : "tableLeft"}
              style={
                allWidth
                  ? { display: "none" }
                  : { borderRight: "none", borderLeft: "1px solid #CCC" }
              }
            >
              {process.env.REACT_APP_TEST_BUTTON === "true" ? (
                <div>
                  <button onClick={testSendDoing} hidden={chatnotTestFlg}>
                    {RTMstate}[送信テスト]{testDoFlg ? "停止" : "開始"}
                  </button>
                  <button
                    onClick={() => setSendDoFlg(!sendDoFlg)}
                    hidden={chatnotTestFlg}
                  >
                    8倍受信{sendDoFlg ? "停止" : "開始"}
                  </button>
                  <button onClick={chattestSendDoing} hidden={chatnotTestFlg}>
                    チャット送信テスト{chattestDoFlg ? "停止" : "開始"}
                  </button>
                  <button onClick={lowStreamTest} hidden={chatnotTestFlg}>
                    画質切替
                  </button>
                </div>
              ) : null}
              <div className="tableLeft">
                <div className={screenIsSmall ? "all_video_left" : "leftTable"}>
                  {!allWidth ? (
                    <div>
                      <div
                        style={{
                          textAlign: "right",
                          position: "relative",
                          top: "50px",
                          right: "10px",
                          marginTop: "-44px",
                        }}
                      >
                        <IconButton
                          aria-label="delete"
                          style={{
                            zIndex: 2,
                            backgroundColor: "rgb(0 0 0 / 35%)",
                            display: streamIsAllDisplay
                              ? screenIsSmall
                                ? "none"
                                : "initial"
                              : "initial",
                          }}
                          onClick={() => {
                            if (!streamIsAllDisplay) {
                              requestFullStream();
                            } else {
                              exitFullStream();
                            }
                          }}
                        >
                          {streamIsSmall ? (
                            <FullscreenIcon
                              style={{
                                fontSize: "20px",
                                color: "rgb(255 255 255 / 50%)",
                              }}
                            />
                          ) : (
                            <FullscreenExitIcon
                              style={{
                                fontSize: "20px",
                                color: "rgb(255 255 255 / 50%)",
                              }}
                            />
                          )}
                        </IconButton>
                      </div>
                      <div className="player-wrapper">
                        {remoteUsers.map((user) =>
                          user.uid === 20000 ? (
                            <div
                              className={
                                remoteUsers.length === 3 ||
                                (remoteUsers.length === 2 && checkLocalVideo)
                                  ? "remote-player-wrapper-3"
                                  : remoteUsers.length === 2 ||
                                    (remoteUsers.length === 1 &&
                                      checkLocalVideo)
                                  ? "remote-player-wrapper-2"
                                  : "remote-player-wrapper-1"
                              }
                            >
                              <MediaPlayer
                                videoTrack={streamer?.videoTrack}
                                audioTrack={streamer?.audioTrack}
                                streamer={streamer}
                              ></MediaPlayer>
                            </div>
                          ) : null
                        )}
                        {remoteUsers.map((user) =>
                          user.uid !== 20000 ? (
                            <div
                              className={
                                remoteUsers.length === 3 ||
                                (remoteUsers.length === 2 && checkLocalVideo)
                                  ? "remote-player-wrapper-3"
                                  : remoteUsers.length === 2 ||
                                    (remoteUsers.length === 1 &&
                                      checkLocalVideo)
                                  ? "remote-player-wrapper-2"
                                  : "remote-player-wrapper-1"
                              }
                            >
                              <MediaPlayer
                                videoTrack={user.videoTrack}
                                audioTrack={user.audioTrack}
                              ></MediaPlayer>
                            </div>
                          ) : null
                        )}
                        {checkLocalVideo ? (
                          <div
                            className={
                              remoteUsers.length === 2
                                ? "remote-player-wrapper-3"
                                : remoteUsers.length === 1
                                ? "remote-player-wrapper-2"
                                : "remote-player-wrapper-1"
                            }
                          >
                            <MediaPlayer
                              videoTrack={localVideoTrack}
                              audioTrack={localAudioTrack}
                            ></MediaPlayer>
                          </div>
                        ) : null}
                      </div>
                      <div
                        hidden={videoSlashFlg}
                        style={
                          allVideoShowFlg
                            ? {
                                position: "fixed",
                                top: "50%",
                                left: "50%",
                                zIndex: 10,
                                transform: "translateY(-50%) translateX(-50%)",
                                WebkitTransform:
                                  "translateY(-50%) translateX(-50%)",
                              }
                            : {
                                position: "absolute",
                                transform: "translateY(-197%) translateX(255%)",
                                WebkitTransform:
                                  "translateY(-197%) translateX(255%)",
                              }
                        }
                      >
                        <FaVideoSlash
                          id="video_slash"
                          color="#ffffff"
                          style={{
                            fontSize: "50px",
                            margin: "0 auto",
                          }}
                        />
                      </div>
                      <div
                        hidden={audioSlashFlg}
                        style={
                          allAudioShowFlg
                            ? {
                                position: "fixed",
                                left: "20px",
                                bottom: "30px",
                                zIndex: 10,
                                color: "#ff0000",
                              }
                            : {
                                position: "absolute",
                                margin: "-32px 0px 0px 10px",
                                color: "#ff0000",
                              }
                        }
                      >
                        <MicOff
                          className="chatIcon"
                          style={{ marginRight: "5px" }}
                        />
                      </div>
                      <div
                        id="networkAlertMsg"
                        hidden={networkAlertOpen}
                        style={{
                          color: "rgb(0 0 0 / 100%)",
                          backgroundColor: "rgb(255 255 255 / 50%)",
                          position: "absolute",
                          width: 296,
                          height: 20,
                          marginTop: -20,
                          pointerEvents: "none",
                          textAlign: "center",
                        }}
                      >
                        {DialogText.networkUnstable}
                      </div>
                    </div>
                  ) : null}
                </div>
                <div
                  style={{
                    float: "right",
                    marginTop: -164,
                    height: 164,
                    width: 5,
                    position: "relative",
                    zIndex: 6,
                    right: 0,
                  }}
                  id="ing"
                >
                  <div
                    className="ing"
                    style={{
                      backgroundColor: ingegate === 1 ? "#00ff66" : "black",
                    }}
                  />
                  <div
                    className="ing"
                    style={{
                      backgroundColor:
                        ingegate !== 0 && ingegate <= 2 ? "#66ff66" : "black",
                    }}
                  />
                  <div
                    className="ing"
                    style={{
                      backgroundColor:
                        ingegate !== 0 && ingegate <= 3 ? "#ccff66" : "black",
                    }}
                  />
                  <div
                    className="ing"
                    style={{
                      backgroundColor:
                        ingegate !== 0 && ingegate <= 4 ? "#ffcc66" : "black",
                    }}
                  />
                  <div
                    className="ing"
                    style={{
                      backgroundColor:
                        ingegate !== 0 && ingegate <= 5 ? "#ff9933" : "black",
                    }}
                  />
                  <div
                    className="ing"
                    style={{
                      backgroundColor:
                        ingegate !== 0 && ingegate <= 6 ? "#ff6600" : "black",
                    }}
                  />
                </div>

                <div className={screenIsSmall ? "nodisplay" : "wrapper2"}>
                  <div
                    id="chat-box"
                    className="chat-box"
                    style={{ minHeight: 70, marginTop: chatMT }}
                  >
                    <div className="chat-body">
                      <div
                        className="msg-insert"
                        style={
                          chatPermission
                            ? { display: "flex" }
                            : { display: "none" }
                        }
                      >
                        {/* {message.map((msgInf, index) => {
                            return (
                              <div
                                key={"chat_massage" + index}
                                className={
                                  msgInf.chatType === 1
                                    ? "msg-sends"
                                    : "msg-receives"
                                }
                                style={{
                                  color:
                                    msgInf.sendToClass === null
                                      ? "black" //"red"
                                      : "red", //"black",
                                }}
                              >
                                {msgInf.chatType !== 1 && msgInf.userName
                                  ? msgInf.userName + ":"
                                  : null}
                                {msgInf.chatType === 1 ||
                                msgInf.chatType === 2 ||
                                msgInf.chatType === 3 ? (
                                  <div
                                    style={{
                                      color:
                                        msgInf.sendToClass === null
                                          ? "black" //"red"
                                          : "red", //"black",
                                    }}
                                  >
                                    {msgInf.chatMessageText}
                                  </div>
                                ) : //) : msgInf.chatType === 3 ? (
                                //<div style={{ color: "#ff0000" }}>
                                //{msgInf.chatMessageText}
                                //</div>
                                msgInf.chatType === 4 ? (
                                  <div>🙂</div>
                                ) : msgInf.chatType === 5 ? (
                                  <div>❌</div>
                                ) : msgInf.chatType === 6 ? (
                                  <div>✋</div>
                                ) : null}
                              </div>
                            );
                          })} */}
                      </div>
                    </div>
                    <div />
                  </div>
                  <div
                    style={{
                      backgroundColor: "white",
                    }}
                  >
                    <div className="send_box">
                      <div className="chat-send">
                        <div style={{ padding: "3px" }}>
                          {chatSendControlType === 1 ? (
                            <div>
                              <select
                                name="sendTo"
                                id="send-select"
                                className="selectBox"
                                onChange={sendToSelectChange}
                                value={selectSendTo.selVal}
                              >
                                <option value="none"></option>
                                <option value={streamerInfo.streamerId}>
                                  {streamerInfo.streamerName}
                                </option>
                                <option value="all">
                                  {FormatMessage({
                                    id: "Cls012.Chat.Option1",
                                  })}
                                </option>
                              </select>
                            </div>
                          ) : null}
                          {chatSendControlType === 2 ? (
                            <div>
                              <select
                                name="sendTo"
                                id="send-select"
                                className="selectBox"
                                onChange={sendToSelectChange}
                                value={selectSendTo.selVal}
                              >
                                <option value={streamerInfo.streamerId}>
                                  {streamerInfo.streamerName}
                                </option>
                              </select>
                            </div>
                          ) : null}
                          {chatSendControlType === 3 ? (
                            <div>
                              <select
                                name="sendTo"
                                id="send-select"
                                className="selectBox"
                                value={"ban"}
                              >
                                <option value="none"></option>
                              </select>
                            </div>
                          ) : null}
                          {chatSendControlType === 4 ? (
                            <div>
                              <select
                                name="sendTo"
                                id="send-select"
                                className="selectBox"
                                onChange={sendToSelectChange}
                                onMouseOver={() => {
                                  setUserL(!userL);
                                }}
                                onClick={() => {
                                  setUserL(!userL);
                                }}
                                value={selectSendTo.selVal}
                              >
                                <option value="none"></option>
                                <option value="all">
                                  {FormatMessage({
                                    id: "Cls012.Chat.Option1",
                                  })}
                                </option>
                                <option value={streamerInfo.streamerId}>
                                  {streamerInfo.streamerName}
                                </option>

                                {UserData.map((user) =>
                                  user.status !== USER_STATUS.EXIT &&
                                  user.user_id !== userId ? (
                                    <option value={user.user_id}>
                                      {user.handle_name}
                                    </option>
                                  ) : null
                                )}
                              </select>
                            </div>
                          ) : null}
                        </div>
                        <div style={{ padding: "3px", display: "flex" }}>
                          <textarea
                            id="textform"
                            className="send_box_textarea"
                            style={{ height: textAreaMT }}
                            maxLength={150}
                            onMouseOver={() => {
                              setChatMT(-33);
                              setTextAreaMT(62);
                              noResize = true;
                            }}
                            onMouseOut={() => {
                              setChatMT(0);
                              setTextAreaMT(32);
                              noResize = false;
                            }}
                            value={chatMessage}
                            onClick={() => {
                              clickEnter = false;
                            }}
                            onChange={(event: any) => {
                              if (clickEnter) {
                                if (chatSendControlType !== 3) {
                                  chatSendClick();
                                }
                                clickEnter = false;
                              } else {
                                setChatMessage(event.target.value);
                              }
                            }}
                          ></textarea>
                          <IconButton
                            aria-label="delete"
                            className="buttonLarge"
                            onClick={chatSendClick}
                            disabled={chatSendControlType === 3 ? true : false}
                            style={{ padding: "8px 10px" }}
                          >
                            <SendIcon style={{ color: "#3993b2" }} />
                          </IconButton>
                          <div hidden={!RTMErrFlg}>
                            <LightTooltip
                              title={
                                <React.Fragment>
                                  {RTMConectErr1}
                                  <br />
                                  {RTMConectErr2}
                                  <br />
                                  {RTMConectErr3}
                                </React.Fragment>
                              }
                              placement="top"
                            >
                              <IconButton aria-label="delete" size="small">
                                <AiOutlineExclamationCircle
                                  color="#e32315"
                                  style={{ fontSize: "16px" }}
                                />
                              </IconButton>
                            </LightTooltip>
                          </div>
                        </div>
                      </div>
                      <div className="alignLeft" style={{ padding: "0px" }}>
                        <span className="centerIcon">
                          <IconButton
                            aria-label="delete"
                            className="buttonLarge"
                            onClick={sendFillLike}
                          >
                            <SentimentSatisfiedRoundedIcon
                              style={b1ColorFlg ? { color: "red" } : {}}
                            />
                          </IconButton>
                        </span>

                        <span className="centerIcon">
                          <IconButton
                            aria-label="delete"
                            className="buttonLarge"
                            onClick={sendFillQuestion}
                          >
                            <CloseRoundedIcon
                              style={b2ColorFlg ? { color: "red" } : {}}
                            />
                          </IconButton>
                        </span>

                        <span className="centerIcon">
                          <IconButton
                            aria-label="delete"
                            className="buttonLarge"
                            onClick={sendHandsUp}
                          >
                            <FaRegHandPaper
                              style={b3ColorFlg ? { color: "red" } : {}}
                            />
                          </IconButton>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
          <tr style={allWidth ? { display: "block" } : { display: "none" }}>
            <td className={screenIsSmall ? "nodisplay" : "small-table-left"}>
              <div style={{ height: "1px" }}>
                <div className={screenIsSmall ? "video_left" : "leftTable"}>
                  {allWidth ? (
                    <div>
                      <div
                        style={{
                          textAlign: "right",
                          position: "absolute",
                          right: "10px",
                        }}
                      >
                        <IconButton
                          aria-label="delete"
                          style={{
                            float: "right",
                            zIndex: 2,
                            backgroundColor: "rgb(0 0 0 / 35%)",
                            display: streamIsAllDisplay
                              ? screenIsSmall
                                ? "none"
                                : "initial"
                              : "initial",
                          }}
                          onClick={() => {
                            if (!streamIsAllDisplay) {
                              requestFullStream();
                            } else {
                              exitFullStream();
                            }
                          }}
                        >
                          {streamIsSmall ? (
                            <FullscreenIcon
                              style={{
                                fontSize: "20px",
                                color: "rgb(255 255 255 / 50%)",
                              }}
                            />
                          ) : (
                            <FullscreenExitIcon
                              style={{
                                fontSize: "20px",
                                color: "rgb(255 255 255 / 50%)",
                              }}
                            />
                          )}
                        </IconButton>
                      </div>
                      <div>
                        <div className="player-wrapper">
                          {remoteUsers.map((user) =>
                            user.uid === 20000 ? (
                              <div
                                className={
                                  remoteUsers.length === 3 ||
                                  (remoteUsers.length === 2 && checkLocalVideo)
                                    ? "remote-player-wrapper-3"
                                    : remoteUsers.length === 2 ||
                                      (remoteUsers.length === 1 &&
                                        checkLocalVideo)
                                    ? "remote-player-wrapper-2"
                                    : "remote-player-wrapper-1"
                                }
                              >
                                <MediaPlayer
                                  videoTrack={user.videoTrack}
                                  audioTrack={user.audioTrack}
                                ></MediaPlayer>
                              </div>
                            ) : null
                          )}
                          {remoteUsers.map((user) =>
                            user.uid !== 20000 ? (
                              <div
                                className={
                                  remoteUsers.length === 3 ||
                                  (remoteUsers.length === 2 && checkLocalVideo)
                                    ? "remote-player-wrapper-3"
                                    : remoteUsers.length === 2 ||
                                      (remoteUsers.length === 1 &&
                                        checkLocalVideo)
                                    ? "remote-player-wrapper-2"
                                    : "remote-player-wrapper-1"
                                }
                              >
                                <MediaPlayer
                                  videoTrack={user.videoTrack}
                                  audioTrack={user.audioTrack}
                                ></MediaPlayer>
                              </div>
                            ) : null
                          )}
                          {checkLocalVideo ? (
                            <div
                              className={
                                remoteUsers.length === 3 ||
                                (remoteUsers.length === 2 && checkLocalVideo)
                                  ? "remote-player-wrapper-3"
                                  : remoteUsers.length === 2 ||
                                    (remoteUsers.length === 1 &&
                                      checkLocalVideo)
                                  ? "remote-player-wrapper-2"
                                  : "remote-player-wrapper-1"
                              }
                            >
                              <MediaPlayer
                                videoTrack={localVideoTrack}
                                audioTrack={localAudioTrack}
                              ></MediaPlayer>
                            </div>
                          ) : null}
                        </div>
                        <div
                          style={{
                            float: "right",
                            width: 6,
                            position: "relative",
                            zIndex: 6,
                            right: 2,
                          }}
                          id="ing2"
                        >
                          <div
                            className="ing"
                            style={{
                              backgroundColor:
                                ingegate === 1 ? "#00ff66" : "black",
                            }}
                          />
                          <div
                            className="ing"
                            style={{
                              backgroundColor:
                                ingegate !== 0 && ingegate <= 2
                                  ? "#66ff66"
                                  : "black",
                            }}
                          />
                          <div
                            className="ing"
                            style={{
                              backgroundColor:
                                ingegate !== 0 && ingegate <= 3
                                  ? "#ccff66"
                                  : "black",
                            }}
                          />
                          <div
                            className="ing"
                            style={{
                              backgroundColor:
                                ingegate !== 0 && ingegate <= 4
                                  ? "#ffcc66"
                                  : "black",
                            }}
                          />
                          <div
                            className="ing"
                            style={{
                              backgroundColor:
                                ingegate !== 0 && ingegate <= 5
                                  ? "#ff9933"
                                  : "black",
                            }}
                          />
                          <div
                            className="ing"
                            style={{
                              backgroundColor:
                                ingegate !== 0 && ingegate <= 6
                                  ? "#ff6600"
                                  : "black",
                            }}
                          />
                        </div>
                        <div
                          hidden={videoSlashFlg}
                          style={
                            allVideoShowFlg
                              ? {
                                  position: "fixed",
                                  top: "50%",
                                  left: "50%",
                                  zIndex: 10,
                                  transform:
                                    "translateY(-50%) translateX(-50%)",
                                  WebkitTransform:
                                    "translateY(-50%) translateX(-50%)",
                                }
                              : {
                                  position: "absolute",
                                  width: "100%",
                                  marginTop:
                                    -25 - Math.ceil(Number(canvasHeight) / 2),
                                }
                          }
                        >
                          <FaVideoSlash
                            id="video_slash"
                            color="#ffffff"
                            style={{
                              fontSize: "50px",
                              margin: "0 auto",
                            }}
                          />
                        </div>
                        <div
                          hidden={audioSlashFlg}
                          style={
                            allAudioShowFlg
                              ? {
                                  position: "fixed",
                                  left: "20px",
                                  bottom: "30px",
                                  zIndex: 10,
                                  color: "#ff0000",
                                }
                              : {
                                  position: "absolute",
                                  margin: "-32px 0px 0px 10px",
                                  color: "#ff0000",
                                }
                          }
                        >
                          <MicOff
                            className="chatIcon"
                            style={{ marginRight: "5px" }}
                          />
                        </div>
                        <div
                          id="networkAlertMsg"
                          hidden={networkAlertOpen}
                          style={{
                            color: "rgb(0 0 0 / 100%)",
                            backgroundColor: "rgb(255 255 255 / 50%)",
                            position: "absolute",
                            width: "100%",
                            height: 20,
                            marginTop: -20,
                            textAlign: "center",
                            pointerEvents: "none",
                          }}
                        >
                          {DialogText.networkUnstable}
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>

                <div className="wrapper2">
                  <div
                    id="chat-box"
                    className="chat-box"
                    style={{ minHeight: 70, marginTop: chatMT }}
                  >
                    <div className="chat-body">
                      <div
                        className="msg-insert"
                        style={
                          chatPermission
                            ? { display: "flex" }
                            : { display: "none" }
                        }
                      >
                        {/* {message.map((msgInf, index) => {
                            return (
                              <div
                                key={"chat_massage" + index}
                                className={
                                  msgInf.chatType === 1
                                    ? "msg-sends"
                                    : "msg-receives"
                                }
                                style={{
                                  color:
                                    msgInf.sendToClass === null
                                      ? "black" //"red"
                                      : "red", //"black",
                                }}
                              >
                                {msgInf.chatType !== 1 && msgInf.userName
                                  ? msgInf.userName + ":"
                                  : null}
                                {msgInf.chatType === 1 ||
                                msgInf.chatType === 2 ||
                                msgInf.chatType === 3 ? (
                                  <div
                                    style={{
                                      color:
                                        msgInf.sendToClass === null
                                          ? "black" //"red"
                                          : "red", //"black",
                                    }}
                                  >
                                    {msgInf.chatMessageText}
                                  </div>
                                ) : //) : msgInf.chatType === 3 ? (
                                //<div style={{ color: "#ff0000" }}>
                                //{msgInf.chatMessageText}
                                //</div>
                                msgInf.chatType === 4 ? (
                                  <div>🙂</div>
                                ) : msgInf.chatType === 5 ? (
                                  <div>❌</div>
                                ) : msgInf.chatType === 6 ? (
                                  <div>✋</div>
                                ) : null}
                              </div>
                            );
                          })} */}
                      </div>
                    </div>
                    <div />
                  </div>
                </div>
                <div
                  style={{
                    backgroundColor: "white",
                  }}
                >
                  <div className="send_box">
                    <div style={{ display: "flex" }}>
                      <div
                        className="mt10"
                        style={{
                          textAlign: "left",
                          width: "40%",
                          marginLeft: "5px",
                        }}
                      >
                        {chatSendControlType === 1 ? (
                          <div>
                            <select
                              name="sendTo"
                              id="send-select"
                              className="selectBox"
                              onChange={sendToSelectChange}
                              value={selectSendTo.selVal}
                            >
                              <option value="none"></option>
                              <option value={streamerInfo.streamerId}>
                                {streamerInfo.streamerName}
                              </option>
                              <option value="all">
                                {FormatMessage({
                                  id: "Cls012.Chat.Option1",
                                })}
                              </option>
                            </select>
                          </div>
                        ) : null}
                        {chatSendControlType === 2 ? (
                          <div>
                            <select
                              name="sendTo"
                              id="send-select"
                              className="selectBox"
                              onChange={sendToSelectChange}
                              value={selectSendTo.selVal}
                            >
                              <option value={streamerInfo.streamerId}>
                                {streamerInfo.streamerName}
                              </option>
                            </select>
                          </div>
                        ) : null}
                        {chatSendControlType === 3 ? (
                          <div>
                            <select
                              name="sendTo"
                              id="send-select"
                              className="selectBox"
                              value={"ban"}
                            >
                              <option value="none"></option>
                            </select>
                          </div>
                        ) : null}
                        {chatSendControlType === 4 ? (
                          <div>
                            <select
                              name="sendTo"
                              id="send-select"
                              className="selectBox"
                              onChange={sendToSelectChange}
                              onClick={() => {
                                setUserL(!userL);
                              }}
                              value={selectSendTo.selVal}
                            >
                              <option value="none"></option>
                              <option value="all">
                                {FormatMessage({
                                  id: "Cls012.Chat.Option1",
                                })}
                              </option>
                              <option value={streamerInfo.streamerId}>
                                {streamerInfo.streamerName}
                              </option>

                              {UserData.map((user) =>
                                user.status !== USER_STATUS.EXIT &&
                                user.user_id !== userId ? (
                                  <option value={user.user_id}>
                                    {user.handle_name}
                                  </option>
                                ) : null
                              )}
                            </select>
                          </div>
                        ) : null}
                      </div>
                      <div
                        className="mt10 ml10"
                        style={{
                          display: "flex",
                          width: "60%",
                          marginLeft: "5px",
                        }}
                      >
                        <textarea
                          id="textform"
                          className="send_box_textarea"
                          style={{ height: textAreaMT }}
                          maxLength={150}
                          value={chatMessage}
                          onMouseOver={() => {
                            setChatMT(-33);
                            setTextAreaMT(62);
                            noResize = true;
                          }}
                          onMouseOut={() => {
                            setChatMT(0);
                            setTextAreaMT(32);
                            noResize = false;
                          }}
                          onClick={() => {
                            clickEnter = false;
                          }}
                          onChange={(event: any) => {
                            if (clickEnter) {
                              if (chatSendControlType !== 3) {
                                chatSendClick();
                              }
                              clickEnter = false;
                            } else {
                              setChatMessage(event.target.value);
                            }
                          }}
                        ></textarea>
                        <IconButton
                          aria-label="delete"
                          className="buttonLarge"
                          onClick={chatSendClick}
                          disabled={chatSendControlType === 3 ? true : false}
                        >
                          <SendIcon style={{ color: "#3993b2" }} />
                        </IconButton>
                        <div hidden={!RTMErrFlg}>
                          <LightTooltip
                            title={
                              <React.Fragment>
                                {RTMConectErr1}
                                <br />
                                {RTMConectErr2}
                                <br />
                                {RTMConectErr3}
                              </React.Fragment>
                            }
                            placement="top"
                          >
                            <IconButton aria-label="delete" size="small">
                              <AiOutlineExclamationCircle
                                color="#e32315"
                                style={{ fontSize: "16px" }}
                              />
                            </IconButton>
                          </LightTooltip>
                        </div>
                      </div>
                    </div>
                    <div className="ml05 mt05 alignLeft">
                      <span className="centerIcon">
                        <IconButton
                          aria-label="delete"
                          className="buttonLarge"
                          onClick={sendFillLike}
                        >
                          <SentimentSatisfiedRoundedIcon
                            style={b1ColorFlg ? { color: "red" } : {}}
                          />
                        </IconButton>
                      </span>

                      <span className="centerIcon">
                        <IconButton
                          aria-label="delete"
                          className="buttonLarge"
                          onClick={sendFillQuestion}
                        >
                          <CloseRoundedIcon
                            style={b2ColorFlg ? { color: "red" } : {}}
                          />
                        </IconButton>
                      </span>

                      <span className="centerIcon">
                        <IconButton
                          aria-label="delete"
                          className="buttonLarge"
                          onClick={sendHandsUp}
                        >
                          <FaRegHandPaper
                            style={b3ColorFlg ? { color: "red" } : {}}
                          />
                        </IconButton>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      {loading ? <LoadingBox /> : null}
      {/* 授業映像音声参加依頼 */}
      <Dialog
        open={joinLecDialogOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {FormatMessage({
              id: "Cls012.Dialog.JoinLec.Text1",
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: "space-around" }}>
          <Button
            onClick={() => {
              setJoinLecDialogOpen(false);
              joinButtonDisable = true;
              agoraJoinLecHost();
            }}
            disabled={joinButtonDisable}
            color="primary"
            autoFocus
          >
            {FormatMessage({
              id: "Cls012.Dialog.JoinLec.Button1",
            })}
          </Button>
          <Button
            onClick={() => {
              setJoinLecDialogOpen(false);
              joinButtonDisable = true;
              voiceJoin(true);
            }}
            disabled={joinButtonDisable}
            color="primary"
          >
            {FormatMessage({
              id: "Cls012.Dialog.JoinLec.Button2",
            })}
          </Button>
          <Button
            onClick={() => {
              setJoinLecDialogOpen(false);
              joinButtonDisable = true;
              clickRejectJoin();
            }}
            disabled={joinButtonDisable}
            color="primary"
          >
            {FormatMessage({
              id: "Cls012.Dialog.JoinLec.Button3",
            })}
          </Button>
          <Button
            onClick={() => {
              setJoinLecDialogOpen(false);
              joinButtonDisable = true;
              clickImpossibleJoin();
            }}
            disabled={joinButtonDisable}
            color="primary"
          >
            {FormatMessage({
              id: "Cls012.Dialog.JoinLec.Button4",
            })}
          </Button>
        </DialogActions>
      </Dialog>

      {/* 授業音声参加依頼 */}
      <Dialog
        open={joinLecVoiceDialogOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {FormatMessage({
              id: "Cls012.Dialog.JoinLec.Text2",
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setJoinLecVoiceDialogOpen(false);
              joinButtonDisable = true;
              voiceJoin(false);
            }}
            disabled={joinButtonDisable}
            color="primary"
            autoFocus
          >
            {FormatMessage({
              id: "Cls012.Dialog.JoinLec.Button1",
            })}
          </Button>
          <Button
            onClick={() => {
              setJoinLecVoiceDialogOpen(false);
              joinButtonDisable = true;
              clickRejectJoinVoice();
            }}
            disabled={joinButtonDisable}
            color="primary"
          >
            {FormatMessage({
              id: "Cls012.Dialog.JoinLec.Button3",
            })}
          </Button>
          <Button
            onClick={() => {
              setJoinLecVoiceDialogOpen(false);
              joinButtonDisable = true;
              clickImpossibleJoinVoice();
            }}
            disabled={joinButtonDisable}
            color="primary"
          >
            {FormatMessage({
              id: "Cls012.Dialog.JoinLec.Button4",
            })}
          </Button>
        </DialogActions>
      </Dialog>

      {/* 手書き参加依頼 */}
      <Dialog
        open={handWrittenJoinDialogOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className="alignCenter">
          {FormatMessage({
            id: "Cls012.Dialog.JoinLec.Text3",
          })}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setHandWrittenJoinDialogOpen(false);
              joinButtonDisable = true;
              clickJoinLecWritten();
            }}
            disabled={joinButtonDisable}
            color="primary"
            autoFocus
          >
            {FormatMessage({
              id: "Cls012.Dialog.JoinLec.Button1",
            })}
          </Button>
          <Button
            onClick={() => {
              setHandWrittenJoinDialogOpen(false);
              joinButtonDisable = true;
              clickRejectJoinWritten();
            }}
            disabled={joinButtonDisable}
            color="primary"
          >
            {FormatMessage({
              id: "Cls012.Dialog.JoinLec.Button3",
            })}
          </Button>
          <Button
            onClick={() => {
              setHandWrittenJoinDialogOpen(false);
              joinButtonDisable = true;
              clickImpossibleJoinWritten();
            }}
            disabled={joinButtonDisable}
            color="primary"
          >
            {FormatMessage({
              id: "Cls012.Dialog.JoinLec.Button4",
            })}
          </Button>
        </DialogActions>
      </Dialog>

      {/* ファイル追加依頼 */}
      <Dialog
        open={imgUploadReqDialogOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {FormatMessage({
              id: "Cls012.Dialog.ImgUpload.Text1",
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setImgUploadReqDialogOpen(false);
              joinButtonDisable = true;
              clickAgleeUpload();
            }}
            disabled={joinButtonDisable}
            color="primary"
            autoFocus
          >
            {FormatMessage({
              id: "Cls012.Dialog.ImgUpload.Button1",
            })}
          </Button>
          <Button
            onClick={() => {
              setImgUploadReqDialogOpen(false);
              joinButtonDisable = true;
              clickRejectUpload();
            }}
            disabled={joinButtonDisable}
            color="primary"
          >
            {FormatMessage({
              id: "Cls012.Dialog.ImgUpload.Button2",
            })}
          </Button>
        </DialogActions>
      </Dialog>

      {/* 画像追加ダイアログ */}
      <Dialog
        open={imgUploadDialogOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <table width="100%">
            <tbody>
              <tr>
                <td>
                  {FormatMessage({
                    id: "Cls012.Dialog.ImgUploadExe.Text1",
                  })}
                  <TextFieldBox
                    className="App-input-text"
                    id="uploadFileName"
                    value={chooseFileName}
                    variant="outlined"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setChooseFileName(event.target.value);
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td style={{ textAlign: "center" }}>
                  <label className="upload-label">
                    {FormatMessage({
                      id: "Cls012.Dialog.ImgUploadExe.File",
                    })}
                    <input type="file" onChange={changeUploadImage} />
                  </label>
                  <Button onClick={handleCameraClickOpen} color="primary">
                    {FormatMessage({
                      id: "Cls012.Dialog.ImgUploadExe.Button2",
                    })}
                  </Button>
                </td>
              </tr>
              <tr>
                <td>
                  <span style={{ color: "red" }}>{errorMsgImgUp}</span>
                </td>
              </tr>
              <tr>
                <td style={{ textAlign: "center" }}>
                  <img
                    className={"App-Image-DropItem-Selected "}
                    src={uploadFileUrl}
                    alt=""
                  ></img>
                </td>
              </tr>
              <tr>
                <td style={{ textAlign: "center" }}>
                  <Button
                    onClick={() => {
                      if (!uploadFlg) {
                        uploadFlg = true;
                        clickUploadExecute();
                      }
                    }}
                    color="primary"
                    autoFocus
                    style={{ marginRight: "28px" }}
                  >
                    {FormatMessage({
                      id: "Cls012.Dialog.ImgUpload.Button1",
                    })}
                  </Button>
                  <Button
                    onClick={handleImgUploadExeClickClose}
                    color="primary"
                  >
                    {FormatMessage({
                      id: "Cls012.Dialog.ImgUploadExe.Button1",
                    })}
                  </Button>
                </td>
              </tr>
            </tbody>
          </table>
        </DialogContent>
      </Dialog>

      <Dialog
        open={noChooseFileOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {FormatMessage({
              id: "Cls012.Dialog.ImgUploadExe.Error",
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={noChooseFileClose} color="primary" autoFocus>
            {FormatMessage({
              id: "Cls012.Dialog.Button",
            })}
          </Button>
        </DialogActions>
      </Dialog>

      {/* 画像撮影 */}
      <Dialog
        open={cameraDialogOpen}
        onClose={handleCameraClickClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Camera
            handleCameraClickClose={handleCameraClickClose}
            changeCameraImage={changeCameraImage}
          />
        </DialogContent>
      </Dialog>

      {/* 択一問題
      <Dialog
        open={questionDialogOpen}
        onClose={handleQuestionClickClose}
        disableBackdropClick={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xl"
      >
        <DialogContent className="alignCenter">
          <div className="quizOption_content">
            {quizOptions.length > 0
              ? quizOptions.map((rows) => (
                  <div
                    key={rows.answer_no}
                    className={"quizOption_div answer_" + rows.answer_no}
                  >
                    <QuizOptionButton row={rows} />
                  </div>
                ))
              : null}
          </div>
        </DialogContent>
      </Dialog>*/}

      {/* 退出確認 */}
      <Dialog
        open={confirmGetOutOpen}
        onClose={getOutCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className="alignCenter">
          {FormatMessage({
            id: "Cls012.Confirm.Text1",
          })}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => getOut(true)} color="primary" autoFocus>
            {FormatMessage({
              id: "Cls012.Confirm.Button1",
            })}
          </Button>
          <Button onClick={getOutCancel} color="primary">
            {FormatMessage({
              id: "Cls001.Dialog.textNo",
            })}
          </Button>
        </DialogActions>
      </Dialog>

      {/* 授業ステータスエラー */}
      <Dialog
        open={errLecNotStartedOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className="alignCenter">
          {FormatMessage({
            id: "Cls012.Err.Text1",
          })}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => getOut(true)} color="primary" autoFocus>
            {FormatMessage({
              id: "Cls012.Dialog.Button",
            })}
          </Button>
        </DialogActions>
      </Dialog>

      {/* 配信ユーザが切断しました */}
      <Dialog
        open={errNotStreamerOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className="alignCenter">
          {FormatMessage({
            id: "HS059",
          })}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setErrNotStreamerOpen(false)}
            color="primary"
            autoFocus
          >
            {FormatMessage({
              id: "Cls012.Dialog.Button",
            })}
          </Button>
        </DialogActions>
      </Dialog>

      {/* 全消し確認 */}
      <Dialog
        open={dialogOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {FormatMessage({
              id: "Clslist3.Dialog.Text",
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickAllResetClose} color="primary" autoFocus>
            {FormatMessage({
              id: "Clslist3.Dialog.Button1",
            })}
          </Button>
          <Button onClick={allClear} color="primary">
            {FormatMessage({
              id: "Clslist3.Dialog.Button2",
            })}
          </Button>
        </DialogActions>
      </Dialog>
      {/*// 音声有効化用のダイアログ*/}
      <Dialog open={soundConfirmOpen}>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {FormatMessage({
              id: "Cls012.Dialog.Ipad",
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={clickSoundConfirmClose} color="primary" autoFocus>
            {FormatMessage({
              id: "Cls012.Confirm.Button1",
            })}
          </Button>
        </DialogActions>
      </Dialog>

      {/* 画面タップ　iPad用 */}
      <Dialog
        open={tapOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className="alignCenter">{tapMsg}</DialogContent>
        <DialogActions>
          <Button onClick={() => setTapOpen(false)} color="primary" autoFocus>
            {FormatMessage({
              id: "Cls012.Dialog.Button",
            })}
          </Button>
        </DialogActions>
      </Dialog>
      {!streamIsSmall && !allWidth ? (
        <div
          id="networkAlertMsg"
          hidden={networkAlertOpen}
          style={{
            color: "rgb(0 0 0 / 100%)",
            backgroundColor: "rgb(255 255 255 / 50%)",
            position: "absolute",
            width: "100%",
            height: 40,
            fontSize: 30,
            marginTop: 40,
            pointerEvents: "none",
            textAlign: "center",
            zIndex: 5,
          }}
        >
          {DialogText.networkUnstable}
        </div>
      ) : null}
      <div id="show_pdf" style={{ display: "none" }}></div>
    </div>
  );
};
