import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { Checkbox } from "@material-ui/core";
import { DateBox } from "../components/atoms/DateBox";
import CloseOutlined from "@material-ui/icons/CloseOutlined";

import { RootState } from "../reducks/store";
import { useSelector, useDispatch } from "react-redux";
import {
  setStateKeywordSearchProc,
  setTrueGetKeywordListsFlg,
} from "../reducks/storedKeywordSeachConditionSlice";
import { sessionHandlerForKeywordSearch as sessionHandler } from "../utils/sessionHandlerForKeywordSearch";
import { FormatMessage } from "../components/atoms/FormatMessage";
import { KY0301 } from "../utils/api/KY0301";

/**
 * 共有ポップアップ
 * @param {boolean} openClose ポップアップ表示非表示フラグ
 * @param {string} Title タイトル
 * @param {string} Text 表示内容
 * @param {function} leftHandleClose 左ボタンのハンドル処理
 * @param {string} leftButtonText 左ボタンの表示内容
 * @param {function} rightHandleClose 左ボタンのハンドル処理
 * @param {string} rightButtonText 左ボタンの表示内容
 * @return コンポーネントHTMLコード内容
 */
export const SearchDialog = (props: any) => {
  const { openClose, closeClick, isSafari } = props;

  const [searchText, setSearchText] = React.useState<string | null>(null);
  const [keywordIds, setKeywordIds] = React.useState<number[]>([]);
  const [keywords, setKeywords] = React.useState<string[]>([]);
  const [unwatchedOndemandFlag, setUnwatchedOndemandFlag] =
    React.useState<boolean>(false);
  const [planDateFrom, setPlanDateFrom] = React.useState<string>("");
  const [planDateTo, setPlanDateTo] = React.useState<string>("");

  // バリデーション関連state定義：項目「授業名/説明文からさがす」
  const [
    flagValidationResultOfSearchText,
    setFlagValidationResultOfSearchText,
  ] = React.useState<boolean>(false);
  const [
    validationErrorMessageOfSearchText,
    setValidationErrorMessageOfSearchText,
  ] = React.useState<string>("");

  // バリデーション関連state定義：項目「配信日(FROM)」
  const [
    flagValidationResultOfPlanDateFrom,
    setFlagValidationResultOfPlanDateFrom,
  ] = React.useState<boolean>(false);
  const [
    validationErrorMessageOfPlanDateFrom,
    setValidationErrorMessageOfPlanDateFrom,
  ] = React.useState<string>("");

  // バリデーション関連state定義：項目「配信日(TO)」
  const [
    flagValidationResultOfPlanDateTo,
    setFlagValidationResultOfPlanDateTo,
  ] = React.useState<boolean>(false);
  const [
    validationErrorMessageOfPlanDateTo,
    setValidationErrorMessageOfPlanDateTo,
  ] = React.useState<string>("");

  const errorMessageList = {
    // 入力文字数がオーバーしています。検索文字は125文字以下で入力してください。
    "Top002.KeywordSearch.ValidationError.TooManyCharacters": FormatMessage({
      id: "Top002.KeywordSearch.ValidationError.TooManyCharacters",
    }),
    // 入力された日付が不正です。有効な日付を入力してください。
    "Top002.KeywordSearch.ValidationError.InvalidDate": FormatMessage({
      id: "Top002.KeywordSearch.ValidationError.InvalidDate",
    }),
  };

  const dispatch = useDispatch();
  // store定義読み込み：ダイアログ開閉サブスイッチ
  const openSwitchKeywordSearchDialog: boolean = useSelector(
    (state: RootState) => state.openSwitchKeywordSearchDialog
  );
  // store定義読み込み：キーワード一覧
  // const keywordListsOnStore: any = useSelector(
  //   (state: RootState) => state.keywordLists
  // );
  const [keywordListsOnStore, setKeywordListsOnStore] = React.useState<any[]>(
    []
  );

  // store定義読み込み：エラーメッセージ
  const errorMessageOnKeywordSearch: string = useSelector(
    (state: RootState) => state.errorMessageOnKeywordSearch
  );

  const eventHandler = {
    searchText: {
      init: () => {
        setSearchText(sessionHandler.getSearchText());
      },
      onChage: (event: React.ChangeEvent<HTMLInputElement>) => {
        if (
          typeof event.target.value === "string" &&
          event.target.value.length > 125
        ) {
          setFlagValidationResultOfSearchText(true);
          // 入力文字数がオーバーしています。検索文字は125文字以下で入力してください。
          setValidationErrorMessageOfSearchText(
            errorMessageList[
              "Top002.KeywordSearch.ValidationError.TooManyCharacters"
            ]
          );
        } else {
          setFlagValidationResultOfSearchText(false);
          setValidationErrorMessageOfSearchText("");
          setSearchText(event.target.value);
          sessionHandler.setSearchText(event.target.value);
        }
      },
      clear: () => {
        setSearchText("");
        sessionHandler.setSearchText("");
        setFlagValidationResultOfSearchText(false);
        setValidationErrorMessageOfSearchText("");
      },
    },

    keywordIds: {
      init: () => {
        setKeywordIds(sessionHandler.getKeywordIds());
        setKeywords(sessionHandler.getKeywords());
      },
      // キーワードボタンが押された際の処理
      switchSelected: (keywordId: number, keyword: string): void => {
        let _selectedIds: number[] = [];
        let _selectedKeyword: string[] = [];
        if (keywordIds.indexOf(keywordId) > -1) {
          _selectedIds = keywordIds.filter(
            (_keywordId: number) => _keywordId !== keywordId
          );
          _selectedKeyword = keywords.filter(
            (_keyword: string) => _keyword !== keyword
          );
        } else {
          _selectedIds = [...keywordIds, keywordId];
          _selectedKeyword = [...keywords, keyword];
        }
        // 選択されているキーワードをstateにセット
        setKeywordIds(_selectedIds);
        setKeywords(_selectedKeyword);

        // 選択されているキーワードのIDをセッションに格納する。
        sessionHandler.setKeywordIds(_selectedIds);
        sessionHandler.setKeywords(_selectedKeyword);
      },
      clear: () => {
        setKeywordIds([]);
        sessionHandler.setKeywordIds([]);
        setKeywords([]);
        sessionHandler.setKeywords([]);
      },
    },

    unwatchedOndemandFlag: {
      init: () => {
        setUnwatchedOndemandFlag(sessionHandler.getUnwatchedOndemandFlag());
      },
      onChage: (event: React.ChangeEvent<HTMLInputElement>) => {
        setUnwatchedOndemandFlag(event.target.checked);
        sessionHandler.setUnwatchedOndemandFlag(event.target.checked);
      },
      clear: () => {
        setUnwatchedOndemandFlag(false);
        sessionHandler.setUnwatchedOndemandFlag(false);
      },
    },

    planDateFrom: {
      init: () => {
        setPlanDateFrom(sessionHandler.getPlanDateFrom());
      },
      onChage: (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value === "") {
          setPlanDateFrom(event.target.value);
          sessionHandler.setPlanDateFrom(event.target.value);
        } else {
          const _inputDate = new Date(event.target.value);
          if (
            isNaN(_inputDate.getTime()) === true ||
            _inputDate.getTime() > 253402214400000 // 9999/12/31のgetTime()値
          ) {
            setFlagValidationResultOfPlanDateFrom(true);
            // 日付が不正です
            setValidationErrorMessageOfPlanDateFrom(
              errorMessageList[
                "Top002.KeywordSearch.ValidationError.InvalidDate"
              ]
            );
          } else {
            setFlagValidationResultOfPlanDateFrom(false);
            setValidationErrorMessageOfPlanDateFrom("");
            setPlanDateFrom(event.target.value);
            sessionHandler.setPlanDateFrom(event.target.value);
          }
        }
      },
      clear: () => {
        setPlanDateFrom("");
        sessionHandler.setPlanDateFrom("");
        setFlagValidationResultOfPlanDateFrom(false);
        setValidationErrorMessageOfPlanDateFrom("");
      },
    },

    planDateTo: {
      init: () => {
        setPlanDateTo(sessionHandler.getPlanDateTo());
      },
      onChage: (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value === "") {
          setPlanDateTo(event.target.value);
          sessionHandler.setPlanDateTo(event.target.value);
        } else {
          const _inputDate = new Date(event.target.value);
          if (
            isNaN(_inputDate.getTime()) === true ||
            _inputDate.getTime() > 253402214400000 // 9999/12/31のgetTime()値
          ) {
            setFlagValidationResultOfPlanDateTo(true);
            // 日付が不正です
            setValidationErrorMessageOfPlanDateTo(
              errorMessageList[
                "Top002.KeywordSearch.ValidationError.InvalidDate"
              ]
            );
          } else {
            setFlagValidationResultOfPlanDateTo(false);
            setValidationErrorMessageOfPlanDateTo("");
            setPlanDateTo(event.target.value);
            sessionHandler.setPlanDateTo(event.target.value);
          }
        }
      },
      clear: () => {
        setPlanDateTo("");
        sessionHandler.setPlanDateTo("");
        setFlagValidationResultOfPlanDateTo(false);
        setValidationErrorMessageOfPlanDateTo("");
      },
    },

    common: {
      setStateAllItems: () => {
        eventHandler.searchText.init();
        eventHandler.keywordIds.init();
        eventHandler.unwatchedOndemandFlag.init();
        eventHandler.planDateFrom.init();
        eventHandler.planDateTo.init();
      },
      submit: async () => {
        // 現在表示している授業一覧レコードをクリアする。
        await dispatch(
          setStateKeywordSearchProc({ stateFlagResetCurrentRows: true })
        );
        // トップ画面で授業を再検索すようにstateを更新する。
        await dispatch(
          setStateKeywordSearchProc({ refreshKey: new Date().getTime() })
        );
        sessionHandler.setRunSearchProc(true);
        await handleClose();
      },
      clearAllItems: () => {
        eventHandler.searchText.clear();
        eventHandler.keywordIds.clear();
        eventHandler.unwatchedOndemandFlag.clear();
        eventHandler.planDateFrom.clear();
        eventHandler.planDateTo.clear();
        let PlanDateFrom: any = document.getElementById("valueFrom");
        if (PlanDateFrom) PlanDateFrom.value = "";
        let PlanDateTo: any = document.getElementById("valueTo");
        if (PlanDateTo) PlanDateTo.value = "";
      },
    },
  };

  const getKeywordLists = async () => {
    const responceKY0301 = await KY0301(dispatch);
    setKeywordListsOnStore(responceKY0301.body);
    if (sessionHandler.getPlanDateFrom() !== "") {
      let PlanDateFrom: any = document.getElementById("valueFrom");
      if (PlanDateFrom) PlanDateFrom.value = sessionHandler.getPlanDateFrom();
    }
    if (sessionHandler.getPlanDateTo() !== "") {
      let PlanDateTo: any = document.getElementById("valueTo");
      if (PlanDateTo) PlanDateTo.value = sessionHandler.getPlanDateTo();
    }
  };

  useEffect(() => {
    // クリックにてダイアログが開かれた場合にのみ実行する処理
    if (openClose === true || openSwitchKeywordSearchDialog === true) {
      dispatch(setTrueGetKeywordListsFlg(true));
      // セッションに記憶されている選択済みキーワードがAPIから取得したキーワード一覧にない状態を検知した場合、
      // セッションの選択済みキーワード一覧をクリアする。
      // if (chekeingSelectedIdsOnLoadedList() === false) {
      //   eventHandler.keywordIds.clear();
      // }
      eventHandler.common.setStateAllItems();
      getKeywordLists();
    }
  }, [openClose, openSwitchKeywordSearchDialog]);

  const chekeingSelectedIdsOnLoadedList = () => {
    const storedKeywordIdsOnSessions = sessionHandler.getKeywordIds();
    let KeywordIdsOnLoadedList: number[] = keywordListsOnStore.map(
      (_keyword: any) => _keyword["keyword_id"]
    );
    for (let i = 0; i < storedKeywordIdsOnSessions.length; i++) {
      if (
        KeywordIdsOnLoadedList.includes(storedKeywordIdsOnSessions[i]) === false
      ) {
        return false;
      }
    }
    return true;
  };

  const handleClose = () => {
    closeClick();
  };

  return (
    <div>
      <Dialog
        open={openClose || openSwitchKeywordSearchDialog}
        onClose={handleClose}
      >
        <DialogTitle id="alert-dialog-title">
          <div style={{ position: "absolute", top: "0", right: "0" }}>
            <Button color="inherit" onClick={closeClick}>
              <CloseOutlined />
            </Button>
          </div>
          <Grid item xs={12} style={{ marginTop: "30px" }}>
            <TextField
              size="small"
              value={searchText}
              variant="outlined"
              label={FormatMessage({ id: "Top002.KeywordSearch.SearchLabel" })}
              onChange={eventHandler.searchText.onChage}
              fullWidth
              error={flagValidationResultOfSearchText}
              helperText={validationErrorMessageOfSearchText}
            />
          </Grid>
        </DialogTitle>
        <DialogContent dividers={false}>
          <div
            style={{
              maxWidth: "1000px",
              minWidth: "500px",
              display: "flex",
              justifyContent: "flex-start",
              flexWrap: "wrap",
            }}
          >
            {keywordListsOnStore.map((keyword: any, i: any) => (
              <button
                key={"key_no_" + i}
                style={
                  keywordIds.indexOf(keyword["keyword_id"]) > -1
                    ? {
                        margin: "3px",
                        backgroundColor: "#deebf7",
                        color: "#31c4d6",
                        borderRadius: "5px",
                        border: "1px solid",
                        padding: "5px 10px",
                      }
                    : {
                        margin: "3px",
                        backgroundColor: "#edf0f3",
                        borderRadius: "5px",
                        border: "1px solid",
                        padding: "5px 10px",
                      }
                }
                onClick={() => {
                  eventHandler.keywordIds.switchSelected(
                    keyword["keyword_id"],
                    keyword["keyword"]
                  );
                }}
              >
                {keyword["keyword"]}
              </button>
            ))}
          </div>
        </DialogContent>
        <DialogActions>
          <div style={{ width: "100%" }}>
            <div>
              {FormatMessage({ id: "Top002.KeywordSearch.SearchText" })}
              <Checkbox
                size="small"
                color="primary"
                checked={unwatchedOndemandFlag}
                onChange={eventHandler.unwatchedOndemandFlag.onChage}
              />
            </div>
            <div style={{ maxWidth: "510px" }}>
              {/* <DateBox
                fullWidth
                name={""}
                label={FormatMessage({
                  id: "Top002.KeywordSearch.SearchLabel2",
                })}
                valueFrom={planDateFrom}
                onChangeFrom={eventHandler.planDateFrom.onChage}
                valueTo={planDateTo}
                onChangeTo={eventHandler.planDateTo.onChage}
                isSafari={isSafari ? true : false}
                errorFrom={flagValidationResultOfPlanDateFrom}
                helperTextFrom={validationErrorMessageOfPlanDateFrom}
                errorTo={flagValidationResultOfPlanDateTo}
                helperTextTo={validationErrorMessageOfPlanDateTo}
              /> */}
              <div>
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="center"
                >
                  <Grid item xs={4} sm={3}>
                    <label>
                      {FormatMessage({
                        id: "Top002.KeywordSearch.SearchLabel2",
                      })}
                    </label>
                  </Grid>
                  <Grid item xs={8} sm={8}>
                    <Grid
                      container
                      direction="row"
                      justify="flex-start"
                      alignItems="center"
                    >
                      <Grid item xs={"auto"} sm={"auto"}>
                        <input
                          type="date"
                          id="valueFrom"
                          onChange={eventHandler.planDateFrom.onChage}
                          style={
                            isSafari
                              ? {
                                  height: "34px",
                                  padding: "6px 10px",
                                }
                              : {
                                  height: " 38px",
                                  padding: " 6px 10px",
                                  font: "inherit",
                                  color: "currentColor",
                                  border: "1px solid #ccc",
                                  borderRadius: "5px",
                                  fontSize: "16px",
                                }
                          }
                        />
                      </Grid>
                      <Grid item xs={"auto"} sm={"auto"}>
                        <span style={{ padding: "0 5px" }}>～</span>
                      </Grid>
                      <Grid item xs={"auto"} sm={"auto"}>
                        <input
                          type="date"
                          id="valueTo"
                          onChange={eventHandler.planDateTo.onChage}
                          style={
                            isSafari
                              ? {
                                  height: "34px",
                                  padding: "6px 10px",
                                }
                              : {
                                  height: " 38px",
                                  padding: " 6px 10px",
                                  font: "inherit",
                                  color: "currentColor",
                                  border: "1px solid #ccc",
                                  borderRadius: "5px",
                                  fontSize: "16px",
                                }
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </div>
            <div style={{ color: "red" }}>{errorMessageOnKeywordSearch}</div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ margin: "10px" }}>
                <Button
                  onClick={() => {
                    eventHandler.common.clearAllItems();
                  }}
                  variant="contained"
                  color="primary"
                  style={{
                    backgroundColor: "#ffffff",
                    color: "#3993b2",
                    border: "1px solid rgba(57,147,178,.5)",
                  }}
                >
                  {FormatMessage({
                    id: "Top002.KeywordSearch.ConditionClearButton",
                  })}
                </Button>
              </div>
              <div style={{ margin: "10px" }}>
                <Button
                  onClick={() => {
                    eventHandler.common.submit();
                  }}
                  variant="contained"
                  color="primary"
                >
                  {FormatMessage({ id: "Top002.KeywordSearch.SearchButton" })}
                </Button>
              </div>
            </div>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};
