import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Tab from "@material-ui/core/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";

import { MainLayout } from "../../layouts/MainLayout";
import { Sts002 } from "./002";
import { Sts004 } from "./004";
import { Sts003 } from "./003";
import { LoadingBox } from "../../components/atoms/LoadingBox";
import { FormatMessage } from "../../components/atoms/FormatMessage";
import { LC0302 } from "../../utils/api/LC0302";
import { unescapeStr } from "../../components/function/Function";
/**
 * 授業視聴状況一覧
 * @param {object} props 引き継いだオブジェクト
 * @return コンポーネントHTMLコード内容
 */
export const Sts001 = (props: any) => {
  // page_after_class
  const [pageAfterClass, setPageAfterClass] = React.useState("1");
  const [tabValue, setTabValue] = React.useState("1");
  const dispatch = useDispatch();

  let sessionDateValue = localStorage.getItem("Sts001_session") as string;
  const getDateValue =
    typeof props.location.state === "undefined"
      ? JSON.parse(sessionDateValue)
      : props.location.state;
  if (typeof props.location.state !== "undefined") {
    localStorage.setItem(
      "Sts001_session",
      JSON.stringify(props.location.state)
    );
  }

  type dataType = {
    class_id: string; //授業ID
    class_name: string; //授業名
    class_time: number; //授業時間
    class_user_num: number; //ユーザ数
  };

  const [showData, setShowData] = React.useState<dataType>({
    class_id: "", //授業ID
    class_name: "", //授業名
    class_time: 0, //授業時間
    class_user_num: 0, //ユーザ数
  });

  //loadingフラグ
  const [loading, setLoading] = React.useState(false);

  const handleChange = (event: any, newValue: React.SetStateAction<string>) => {
    setTabValue(newValue);
  };

  const showDate = async () => {
    setLoading(true);
    let dateList = await LC0302(dispatch, getDateValue?.class_id, false, true);
    if (
      dateList &&
      Object.keys(dateList).indexOf("body") >= 0 &&
      Object.keys(dateList.body).length > 0
    ) {
      setShowData({
        class_id: getDateValue?.class_id, //授業ID
        class_name: dateList.body.class_name, //授業名
        class_time: dateList.body.class_time, //授業時間
        class_user_num: dateList.body.user.length, //授業時間
      });
      if (Object.keys(dateList.body).indexOf("page_after_class") >= 0) {
        setPageAfterClass(dateList.body.page_after_class);
      }
    }
    setLoading(false);
  };
  const [addText, setAddText] = React.useState("");
  const addTitleText = (text: string) => {
    setAddText(text);
  };

  const setParentLoading = (isLoading: boolean) => {
    setLoading(isLoading);
  };

  //初期処理
  useEffect(() => {
    showDate();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  return (
    <MainLayout>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className="App-paper">
            <Typography
              component="h2"
              variant="h6"
              color="inherit"
              gutterBottom
            >
              {FormatMessage(
                tabValue === "1"
                  ? addText !== ""
                    ? { id: "Sts002.Title" }
                    : { id: "Sts001.Title" }
                  : tabValue === "2"
                  ? { id: "Sts004.Title" }
                  : { id: "Sts003.Title" }
              )}
            </Typography>
            <Grid container spacing={3} className="App-mT10">
              <Grid item xs className="App-pLR10">
                {FormatMessage({ id: "Sts001.Label.Class_Id" })} :
                {showData.class_id}
              </Grid>
              <Grid item xs className="App-pLR10">
                {FormatMessage({ id: "Sts001.Label.Class_Name" })} :
                {showData.class_name}
              </Grid>
              <Grid item xs className="App-pLR10">
                {FormatMessage({
                  id: "Sts001.Label.Class_Time",
                  option: { val: showData.class_time },
                })}
              </Grid>
            </Grid>
            <Grid item xs={12} className="App-mT10">
              <TabContext value={tabValue}>
                <AppBar position="static" color="inherit">
                  <TabList onChange={handleChange}>
                    <Tab
                      label={FormatMessage({ id: "Sts001.Label.text1" })}
                      value="1"
                    />
                    <Tab
                      label={FormatMessage({ id: "Sts001.Label.text2" })}
                      value="2"
                    />
                    <Tab
                      label={FormatMessage({ id: "Sts001.Label.text3" })}
                      value="3"
                    />
                  </TabList>
                </AppBar>
                <TabPanel value="1">
                  {showData.class_id !== "" ? (
                    <Sts002
                      dispatch={dispatch}
                      classId={showData.class_id}
                      className={showData.class_name}
                      classTime={showData.class_time}
                      classUserNum={showData.class_user_num}
                      callback={addTitleText}
                    />
                  ) : null}
                </TabPanel>
                <TabPanel value="2">
                  <Sts004
                    class_id={unescapeStr(getDateValue?.class_id)}
                    page_after_class={pageAfterClass}
                    setParentLoading={setParentLoading}
                  />
                </TabPanel>
                <TabPanel value="3">
                  {showData.class_id !== "" ? (
                    <Sts003 dispatch={dispatch} classId={showData.class_id} />
                  ) : null}
                </TabPanel>
              </TabContext>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      {loading ? <LoadingBox /> : null}
    </MainLayout>
  );
};
