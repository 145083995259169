import { apigw } from "../awsApi";
import { Dispatch } from "@reduxjs/toolkit";


/**
 * 授業資料アップロード
 * @param {Dispatch} dispatch           reducerディスパッチ
 * @param {string} classId              授業ID
 * @param {string} boardDocumentName    ボード資料名
 * @param {string} boardDocumentType    ボード資料種類(1:画像、2:動画)
 * @param {string} videoName            動画ファイル名
 * @param {string} downloadDocumentName ダウンロード資料名
 * @param {string} downloadDocumentType ダウンロード資料種類(1:画像、2:動画、3:PDF、4:音声)
 * @param {string} documentClass        資料区分(1：ボード　2：動画 3：ダウンロード　4：録画)
 * @param {string} videoclass        動画区分(録画動画用)
 * @param {string} s3id              S3id
 * @return コンポーネントHTMLコード内容
 */

export const CT0303 = async (
  dispatch: Dispatch,
  classId: string,
  boardDocumentName: any,
  boardDocumentType: any,
  videoName: any,
  downloadDocumentName: any,
  downloadDocumentType: any,
  documentClass: any,
  videoclass:any,
  s3id:any
) => {
  return apigw(
      "/CT0303",
      {
        class_id:classId,
        board_document_name:boardDocumentName,
        board_document_type: boardDocumentType,
        video_name:videoName,
        download_document_name:downloadDocumentName,
        download_document_type:downloadDocumentType,
        document_class:documentClass,
        video_class:videoclass,
        s3_id:s3id
      },
      dispatch
  );
};
