import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import { FormatMessage } from "../../components/atoms/FormatMessage";
import { MainLayout } from "../../layouts/MainLayout";
import sanitize from "sanitize-html";

let getDateValue: any;
/**
 * カスタムアンケート登録編集完了
 * @param {object} props 引き継いだオブジェクト
 * @return コンポーネントHTMLコード内容
 */
export const Cls007 = (props: any) => {
  let sessionDateValue = localStorage.getItem("Cls007_session") as string;
  getDateValue =
    props && props.location && typeof props.location.state === "undefined"
      ? JSON.parse(sessionDateValue)
      : props.location.state;
  if (typeof props.location.state !== "undefined") {
    localStorage.setItem(
      "Cls007_session",
      JSON.stringify(props.location.state)
    );
  }
  let classId = getDateValue?.classId ? getDateValue?.classId : "";
  const dispatch = useDispatch();

  const listCilck = () => {
    dispatch(push("/cls/004", { classId: sanitize(classId) }));
  };

  return (
    <MainLayout>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className="App-paper">
            <Typography
              component="h2"
              variant="h6"
              color="inherit"
              gutterBottom
            >
              {FormatMessage({ id: "Cls007.Title" })}
            </Typography>
            <Grid item xs={12} className="App-alignRight">
              <Button
                variant="contained"
                color="primary"
                className="App-button_bottom"
                startIcon={<ArrowBackIcon />}
                onClick={listCilck}
              >
                {FormatMessage({ id: "Cls007.Button.back" })}
              </Button>
            </Grid>
            <Grid item xs={12} className="App-alignCenter">
              <Typography
                component="h1"
                variant="body1"
                color="inherit"
                gutterBottom
              >
                {FormatMessage({ id: "Cls007.Content.text" })}
              </Typography>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </MainLayout>
  );
};
