import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { MainLayout } from "../../layouts/MainLayout";
import { FormatMessage } from "../../components/atoms/FormatMessage";

/**
 * アカウント編集完了（配信ユーザ）
 * @param {object} props 引き継いだオブジェクト
 * @return コンポーネントHTMLコード内容
 */
export const Set004 = () => {
  const dispatch = useDispatch();

  const backCilck = () => {
    dispatch(push("/set/001"));
  };

  return (
    <MainLayout>
      <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper className="App-paper">
                <Typography
                  component="h2"
                  variant="h6"
                  color="inherit"
                  gutterBottom
                >
                  {FormatMessage({ id: "Set004.Title" })}
                </Typography>
                <Grid item xs={12} className="App-alignRight">
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<ArrowBackIcon />}
                    className="App-button_bottom"
                    onClick={backCilck}
                  >
                    {FormatMessage({ id: "Set004.Button.back" })}
                  </Button>
                </Grid>
                <Grid container spacing={3} className="App-mT10">
                  <Grid item xs={12} className="App-alignCenter">
                    <Typography
                      align="center"
                      component="div"
                      variant="body1"
                      color="textSecondary"
                      gutterBottom
                    >
                      {FormatMessage({ id: "Set004.Content.text" })}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
    </MainLayout>
  );
}
