import React from "react";
import TextField from "@material-ui/core/TextField";
import {InputBanChange} from "../function/Function";

/**
 * @type テキストボックスインターフェイス
 * defaultの設定は以下ページを参考してください。
 * https://material-ui.com/api/text-field/ 
 */
type TextBoxProps = {
  autoComplete?: string;
  autoFocus?: boolean;
  classes?: object;
  color?: "primary" | "secondary";
  defaultValue?: any;
  disabled?: boolean;
  error?: boolean;
  FormHelperTextProps?: object;
  fullWidth?: boolean;
  helperText?: any;
  id?: string;
  InputLabelProps?: object;
  inputProps?: object;
  InputProps?: object;
  inputRef?: any;
  label?: any;
  margin?: "dense" | "none" | "normal";
  maxRows?: number | string;
  multiline?: boolean;
  name?: string;
  placeholder?: string;
  required?: boolean;
  rows?: number | string;
  rowsMax?: number | string;
  select?: boolean;
  SelectProps?: object;
  size?: "medium" | "small";
  type?: string;
  value?: any;
  variant?: "filled" | "outlined" | "standard";
  className?: string;
  onChange?: (e: any) => void;
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
};
/**
 * 共有テキストボックス
 * @param {TextBoxProps} TextBoxProps
 * @return コンポーネントHTMLコード内容
 */
export const TextFieldBox: React.FC<TextBoxProps> = ({
  autoComplete = "",
  autoFocus = false,
  classes = {},
  color = "primary",
  defaultValue = "",
  disabled = false,
  error = false,
  FormHelperTextProps = {},
  fullWidth = false,
  helperText = "",
  id = "",
  InputLabelProps = {},
  inputProps = {},
  InputProps = {},
  inputRef = {},
  label = "",
  margin = "dense",
  maxRows = 1,
  multiline = false,
  name = "",
  placeholder = "",
  required = false,
  rows = "",
  rowsMax = "",
  select = false,
  SelectProps = {},
  size = "small",
  type = "",
  value = "",
  variant = "outlined",
  className = "",
  onChange = () => {},
  onBlur = () => {},
}: TextBoxProps) => {
  
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) onChange({target:{value:InputBanChange(event.target.value)}});
  };

  return (
    <TextField
      autoComplete={autoComplete}
      autoFocus={autoFocus}
      classes={classes}
      color={color}
      // defaultValue={defaultValue}
      disabled={disabled}
      error={error}
      FormHelperTextProps={FormHelperTextProps}
      fullWidth={fullWidth}
      helperText={helperText}
      id={id}
      InputLabelProps={InputLabelProps}
      inputProps={inputProps}
      InputProps={InputProps}
      inputRef={inputRef}
      label={label}
      margin={margin}
      multiline={multiline}
      name={name}
      placeholder={placeholder}
      required={required}
      rows={rows}
      rowsMax={rowsMax}
      select={select}
      SelectProps={SelectProps}
      size="small"
      type={type}
      value={value}
      variant={variant}
      className={className}
      onBlur={onBlur}
      onChange={handleChange}
    />
  );
};
