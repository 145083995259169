import { apigw } from "../awsApi";
import { Dispatch } from "@reduxjs/toolkit";
//テストデータ
const state = 0;
const error_code = "04070";
/**
 * 配信ユーザ詳細取得取得
 * @param {Dispatch} dispatch reducerディスパッチ
 * @param {string} userName ユーザ名
 * @param {string} mailAddress メールアドレス
 * @param {string} updateDate 更新日時
 * @return コンポーネントHTMLコード内容
 */
export const US0302 = async (
  dispatch: Dispatch,
  userName: string,
  mailAddress: string,
  updateDate: string,
) => {
  if (process.env.REACT_APP_API_USE_FLG === "true") {
    return apigw(
      "/US0302",
      {
        user_name: userName,
        mail_address: mailAddress,
        update_date: updateDate
      },
      dispatch
    );
  } else {
    //サンプルデータ用
    return { state, error_code };
  }
};
