import { apigw } from "../awsApi";
import { Dispatch } from "@reduxjs/toolkit";

const rows = {
  class_name: "", //	授業名
  class_time: "", //	授業時間
  viewing_time: "", //	ライブ配信　視聴時間
  viewing_datetime: "", //	ライブ配信　視聴日時
  viewing_time_ondemand: "0", //	オンデマンド　視聴時間
  viewing_datetime_ondemand: "2021/05/17", //	オンデマンド　視聴日時
};

/**
 * 授業視聴状況詳細取得
 * @param {Dispatch} dispatch reducerディスパッチ
 * @param {string} tagId タグID
 * @param {string} classId 授業ID
 * @param {string} className 授業名
 * @param {boolean} isAttendance ライブ配信授業 出席 
 * @param {boolean} isAbsence ライブ配信授業 欠席
 * @param {boolean} isWatched オンデマンド授業 視聴済 
 * @param {boolean} isPartWatched オンデマンド授業 一部視聴済
 * @param {boolean} isNotWatched オンデマンド授業 未視聴
 * @return コンポーネントHTMLコード内容
 */
export const LC0306 = async (
  dispatch: Dispatch,
  className:string,
  isAttendance: boolean,
  isAbsence: boolean,
  isWatched: boolean,
  isPartWatched: boolean,
  isNotWatched: boolean,
  pageNo: number,
) => {
  if (process.env.REACT_APP_API_USE_FLG === "true") {
    return apigw(
      "/LC0306",
      {
        class_name:className,
        attendance: isAttendance,
        absence: isAbsence,
        watched: isWatched,
        partially_watched: isPartWatched,
        not_watched: isNotWatched,
        page_no:pageNo,
      },
      dispatch
    );
  } else {
    //サンプルデータ用
    return { body: rows };
  }
};
