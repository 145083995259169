import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextFieldBox } from "../atoms/TextFieldBox";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  textBox: {
    padding: `${theme.spacing(1)}px 0`,
  },
  label: {
    width: "140px",
    textAlign: "right",
    paddingRight: "10px",
  },
  inputGroup: {
    display: "flex",
  },
}));
/**
 * @type テキストボックスインターフェイス
 */
type TextBoxProps = {
  label?: string;
  name?: string;
  value?: string;
  width?: string;
  maxWidth?: string;
  icon?: string;
  placeholder?: string;
  error?: boolean;
  helperText?: string;
  multiline?: boolean;
  fullWidth: boolean;
  row?: number;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
};
/**
 * 共有テキストボックス
 * @param {string} label タイトル内容
 * @param {Item[]} name ボックス名前
 * @param {string} value 入力値
 * @param {string} width 横幅
 * @param {string} maxWidth 最大横幅
 * @param {boolean} error 開始日エラーフラグ
 * @param {string} helperText テキストボックスの下に表示内容
 * @param {boolean} fullWidth 横幅100％フラグ
 * @param {function} onChange 変更時のハンドル処理
 * @param {function} onBlur 入力時のハンドル処理
 * @return 共有テキストボックス
 */
export const TextBox: React.FC<TextBoxProps> = ({
  label = "",
  name = "",
  value = "",
  width = "",
  maxWidth = "",
  error = false,
  helperText = "",
  placeholder = "",
  fullWidth = false,
  onChange = () => {},
  onBlur = () => {},
}: TextBoxProps) => {
  const classes = useStyles();
  //const { title, value, width, icon, placeholder, onChange, error } = props

  return (
    <div
      className={classes.textBox}
      style={{ minWidth: "275px" }}
    >
      <Grid container direction="row" justify="flex-start" alignItems="center">
        <Grid item xs={"auto"} sm={"auto"} className={classes.label}>
          <label className={classes.label}>{label}</label>
        </Grid>
        <Grid item xs={"auto"} sm={"auto"}>
          <TextFieldBox
            error={error}
            fullWidth={fullWidth}
            helperText={helperText}
            label={placeholder}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            type="text"
            value={value}
            variant="outlined"
            inputProps={{
              style: {
                height: "26px",
                padding: "6px 10px",
                width: width,
                maxWidth: maxWidth
              },
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};
