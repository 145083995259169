import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import { FormatMessage } from "../../components/atoms/FormatMessage";
import { MainLayout } from "../../layouts/MainLayout";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { TextFieldBox } from "../../components/atoms/TextFieldBox";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CheckIcon from "@material-ui/icons/Check";
import AddIcon from "@material-ui/icons/Add";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { LoadingBox } from "../../components/atoms/LoadingBox";

import { OQ0301 } from "../../utils/api/_OQ0301";
import { CF0303 } from "../../utils/api/CF0303";
import sanitize from "sanitize-html";

let origin: number;
/**
 * カスタムアンケート登録編集
 * @param {object} props 引き継いだオブジェクト
 * @return コンポーネントHTMLコード内容
 */
export const Cls005 = (props: any) => {
  const dispatch = useDispatch();
  const [action, setAction] = React.useState("");
  const [classId, setClassId] = React.useState("");
  // const [typeBox, setTypeBox] = React.useState("1");
  const [characterClass, setCharacterClass] = React.useState("1");
  const [question, setQuestion] = React.useState("");
  const [questionNo, setQuestionNo] = React.useState("");
  const [isRequired, setIsRequired] = React.useState("0");
  const [questionClassValue, setQuestionClassValue] = React.useState("");
  const [option, setOption] = React.useState<any>([]);
  const [updateDate, setUpdateDate] = React.useState(new Date());

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      customTextWidth: {
        width: "calc(100% - 91px)",
      },
    })
  );
  const classes = useStyles();
  // 回答方式コンボボックス
  type characterClassListType = {
    code: string; // コード
    value: string; // 内容
  }[];
  const [characterClassList, setCharacterClassList] =
    React.useState<characterClassListType>([]);

  // loadingフラグ
  const [loading, setLoading] = React.useState(false);

  // 入力エラーフラグ
  type inputError = {
    error: boolean;
    error_message: any;
  };
  const [countError, setCountError] = React.useState<inputError>({
    error: false,
    error_message: "",
  });
  const [topError, setTopError] = React.useState<inputError>({
    error: false,
    error_message: "",
  });
  const [questionError, setQuestionError] = React.useState<inputError>({
    error: false,
    error_message: "",
  });
  const [digitError, setDigitError] = React.useState<any>([]);
  const [optionRequiredError, setOptionRequiredError] = React.useState<any>([]);

  const OPTION_MAX_DIGITS = "500";
  const QUESTION_MAX_DIGITS = "4000";

  // メッセージの定義
  const messages = {
    // API
    // 問題数上限エラー
    exceededCount: FormatMessage({ id: "Cls005.Message.itemMax" }),
    delButton: FormatMessage({ id: "Button.Del" }),
    addButton: FormatMessage({ id: "Button.Add" }),
    // requiredStr: FormatMessage({ id: "Def001.Label.Required" }),
    questionRequiredError: FormatMessage({
      id: "Cls005.Message.question",
    }),
    questionCountError: FormatMessage({
      id: "Cls005.Message.questionMax",
      option: {
        item: QUESTION_MAX_DIGITS,
      },
    }),
    optionRequiredError: FormatMessage({
      id: "Cls005.Message.option",
    }),
    digitsError: FormatMessage({
      id: "Cls005.Message.optionMax",
      option: {
        item: OPTION_MAX_DIGITS,
      },
    }),
  };

  // 【 処理 】

  const backCilck = () => {
    dispatch(push("/cls/004", { classId: sanitize(classId) }));
  };

  const checkCilck = () => {
    let flg = true;
    setTopError({
      error: false,
      error_message: "",
    });
    setCountError({
      error: false,
      error_message: "",
    });
    setQuestionError({
      error: false,
      error_message: "",
    });
    setDigitError([]);
    setOptionRequiredError([]);
    // 質問の必須チェック

    // 選択肢の文字数チェック
    let dgtError: any = [];
    let optError: any = [];
    option.forEach((item: string, index: number) => {
      console.log("エラー", item.length);
      if (item === "") {
        optError.push(index);
        console.log("エラー１", optError);
      }
      if (item.length > Number(OPTION_MAX_DIGITS)) {
        dgtError.push(index);
        console.log("エラー2", dgtError);
      }
    });
    setOptionRequiredError(optError);
    setDigitError(dgtError);

    if (question === "") {
      setQuestionError({
        error: true,
        error_message: messages.questionRequiredError,
      });
      flg = false;
    } else if (question.length > Number(QUESTION_MAX_DIGITS)) {
      setQuestionError({
        error: true,
        error_message: messages.questionCountError,
      });
      flg = false;
    }
    if (optError.length > 0 || dgtError.length > 0) {
      //setOptionRequiredError(optError);
      // setTopError({
      //   error: true,
      //   error_message: FormatMessage({ id: "Cls005.Message.option" }),
      // });
      flg = false;
      //} else if (dgtError.length > 0) {
      //setDigitError(dgtError);
      // setTopError({
      //   error: true,
      //   error_message: FormatMessage({ id: "Cls005.Message.optionMax" }),
      // });
      //flg = false;
    }
    if (flg) {
      dispatch(
        push("/cls/006", {
          class_id: classId,
          action: action,
          question_no: questionNo,
          question: question,
          character_class: characterClass,
          is_required: isRequired,
          option: option,
          question_class_value: questionClassValue,
          update_date: updateDate,
          originOption: origin,
        })
      );
    }
  };

  const addClick = () => {
    setTopError({
      error: false,
      error_message: "",
    });
    setCountError({
      error: false,
      error_message: "",
    });
    setQuestionError({
      error: false,
      error_message: "",
    });
    // setDigitError([]);
    // setOptionRequiredError([]);

    switch (characterClass) {
      case "2":
      case "3":
        if (option.length >= 10) {
          setCountError({
            error: true,
            error_message: messages.exceededCount,
          });
        } else {
          setOption([...option, ""]);
        }
        break;
      case "4":
        if (option.length >= 50) {
          setCountError({
            error: true,
            error_message: messages.exceededCount,
          });
        } else {
          setOption([...option, ""]);
        }
        break;
    }
  };

  const deleteClick = (index: number) => {
    setTopError({
      error: false,
      error_message: "",
    });
    setCountError({
      error: false,
      error_message: "",
    });
    setQuestionError({
      error: false,
      error_message: "",
    });
    setDigitError([]);
    setOptionRequiredError([]);
    let opt = option;
    opt.splice(index, 1);
    setOption([...option]);
  };

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setTopError({
      error: false,
      error_message: "",
    });
    setCountError({
      error: false,
      error_message: "",
    });
    setQuestionError({
      error: false,
      error_message: "",
    });
    setDigitError([]);
    setOptionRequiredError([]);
    if (
      typeof event.target.value === "string" &&
      ["2", "3", "4"].includes(event.target.value)
    ) {
      //setQuestion("");
      //setIsRequired("0");
      setOption([""]);
    } else {
      //setQuestion("");
      //setIsRequired("0");
      setOption([]);
    }
    setCharacterClass(event.target.value as string);
    characterClassList.map((row) => {
      if (row.code === (event.target.value as string)) {
        setQuestionClassValue(row.value);
      }
    });
  };

  const checkChangeHandle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsRequired(event.target.checked ? "1" : "0");
  };

  //初期表示データの取得
  const getListData = async (callback: any) => {
    let sessionDataValue = localStorage.getItem("Cls005_session");
    const sessionData =
      typeof props.location.state === "undefined"
        ? sessionDataValue
          ? JSON.parse(sessionDataValue)
          : sessionDataValue
        : props.location.state;
    if (typeof props.location.state !== "undefined") {
      localStorage.setItem(
        "Cls005_session",
        JSON.stringify(props.location.state)
      );
    }
    if (sessionData && Object.keys(sessionData).indexOf("class_id") >= 0) {
      setClassId(sessionData.class_id);

      setLoading(true);

      let getCharacterClass = await CF0303(dispatch, "QuestionClass");
      //console.log("getCharacterClass", getCharacterClass);
      if (
        getCharacterClass &&
        Object.keys(getCharacterClass).indexOf("body") >= 0
      ) {
        setCharacterClassList(getCharacterClass.body);
        const list: characterClassListType = getCharacterClass.body;

        if (sessionData && Object.keys(sessionData).indexOf("action") >= 0) {
          setAction(sessionData.action);
        }

        if (
          sessionData &&
          Object.keys(sessionData).indexOf("question_no") >= 0
        ) {
          setQuestionNo(sessionData.question_no);
        }

        if (
          Object.keys(sessionData).indexOf("action") >= 0 &&
          sessionData.action === "edit" &&
          Object.keys(sessionData).indexOf("question_no") >= 0 &&
          Object.keys(sessionData).indexOf("backFlg") < 0
        ) {
          await OQ0301(
            dispatch,
            sessionData.class_id,
            sessionData.question_no
          ).then((getData) => {
            if (
              getData.body &&
              Object.keys(getData.body).indexOf("question") >= 0
            ) {
              if (getData.body.question.length > 0) {
                const getDataItem = getData.body.question[0];

                if (
                  getDataItem &&
                  Object.keys(getDataItem).indexOf("question") >= 0
                ) {
                  // setInitialQuestion(getDataItem.question);
                  setQuestion(getDataItem.question);
                }

                if (
                  getDataItem &&
                  Object.keys(getDataItem).indexOf("character_class") >= 0
                ) {
                  setCharacterClass(getDataItem.character_class);
                  // 回答方式セット
                  list.map((row) => {
                    if (row.code === getDataItem.character_class) {
                      setQuestionClassValue(row.value);
                    }
                  });
                }
                if (
                  getDataItem &&
                  Object.keys(getDataItem).indexOf("is_required") >= 0
                ) {
                  setIsRequired(getDataItem.is_required);
                }
                if (
                  getDataItem &&
                  Object.keys(getDataItem).indexOf("update_date") >= 0
                ) {
                  setUpdateDate(getDataItem.update_date);
                }

                let optionsIndex = 1;
                let optionArray = [];
                if (
                  getDataItem &&
                  Object.keys(getDataItem).indexOf("option") >= 0
                ) {
                  while (
                    Object.keys(getDataItem.option[0]).indexOf(
                      "option_" + optionsIndex
                    ) >= 0
                  ) {
                    if (getDataItem.option[0]["option_" + optionsIndex]) {
                      optionArray.push(
                        getDataItem.option[0]["option_" + optionsIndex]
                      );
                    }
                    optionsIndex++;
                  }
                  setOption(optionArray);
                  origin = optionArray.length;
                }
              }
            }
          });
        } else if (
          Object.keys(sessionData).indexOf("action") >= 0 &&
          sessionData.action === "add" &&
          Object.keys(sessionData).indexOf("backFlg") < 0
        ) {
          setQuestionClassValue(list[0].value);
        } else if (
          Object.keys(sessionData).indexOf("backFlg") >= 0 &&
          sessionData.backFlg === true &&
          Object.keys(sessionData).indexOf("question_no") >= 0
        ) {
          if (Object.keys(sessionData).indexOf("question") >= 0) {
            if (Object.keys(sessionData).indexOf("question") >= 0) {
              // setInitialQuestion(getDataItem.question);
              setQuestion(sessionData.question);
            }
            if (Object.keys(sessionData).indexOf("character_class") >= 0) {
              setCharacterClass(sessionData.character_class);
              // 回答方式セット
              list.map((row) => {
                if (row.code === sessionData.character_class) {
                  setQuestionClassValue(row.value);
                }
              });
            }
            if (Object.keys(sessionData).indexOf("is_required") >= 0) {
              setIsRequired(sessionData.is_required);
            }
            if (Object.keys(sessionData).indexOf("update_date") >= 0) {
              setUpdateDate(sessionData.update_date);
            }
            let optionArray = [];
            if (Object.keys(sessionData).indexOf("option") >= 0) {
              for (let i = 0; i < sessionData.option.length; i++) {
                if (sessionData.option[i]) {
                  optionArray.push(sessionData.option[i]);
                }
              }
              setOption(optionArray);
              origin = optionArray.length;
            }
          }
        }
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    getListData(null);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  return (
    <MainLayout>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className="App-paper">
            <Typography
              component="h2"
              variant="h6"
              color="inherit"
              gutterBottom
            >
              {FormatMessage({ id: "Cls005.Title" })}
            </Typography>
            <Grid item xs={12} className="App-alignRight">
              <span className="App-mR10">
                <Button
                  variant="contained"
                  color="primary"
                  className="App-button_bottom"
                  startIcon={<ArrowBackIcon />}
                  onClick={backCilck}
                >
                  {FormatMessage({ id: "Cls005.Button.back" })}
                </Button>
              </span>
              <span className="App-mR10">
                <Button
                  variant="contained"
                  color="primary"
                  className="App-button_bottom"
                  startIcon={<CheckIcon />}
                  onClick={checkCilck}
                >
                  {FormatMessage({ id: "Cls005.Button.check" })}
                </Button>
              </span>
            </Grid>
            <Grid className="App-box">
              <Grid container spacing={3} className="App-mT10">
                <Grid
                  item
                  xs={12}
                  sm={2}
                  style={{
                    width: "300px",
                    minWidth: "300px",
                    maxWidth: "300px",
                  }}
                >
                  {FormatMessage({ id: "Cls005.Label.question" })}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={10}
                  style={{
                    width: "calc(100% - 300px)",
                    minWidth: "calc(100% - 300px)",
                    maxWidth: "calc(100% - 300px)",
                  }}
                >
                  <TextFieldBox
                    className="App-input-text"
                    id="question"
                    error={questionError.error}
                    value={question}
                    fullWidth
                    multiline
                    rows={1}
                    rowsMax={20}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setQuestion(event.target.value)
                    }
                    variant="outlined"
                    helperText={questionError.error_message}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3} className="App-mT10">
                <Grid
                  item
                  xs={12}
                  sm={6}
                  style={{
                    width: "300px",
                    minWidth: "300px",
                    maxWidth: "300px",
                  }}
                >
                  {FormatMessage({ id: "Cls005.Label.questionClass" })}
                </Grid>
                <Grid item xs={4}>
                  <Select native value={characterClass} onChange={handleChange}>
                    {characterClassList.map((row) => (
                      <option value={row.code}>{row.value}</option>
                    ))}
                  </Select>
                </Grid>
                <FormControlLabel
                  label={FormatMessage({ id: "Cls005.Label.Required" })}
                  control={
                    <Checkbox
                      name="isrequired"
                      color="default"
                      checked={isRequired === "1"}
                      onChange={checkChangeHandle}
                      inputProps={{ "aria-label": "uncontrolled-checkbox" }}
                    />
                  }
                />
              </Grid>

              {characterClass === "1" ? (
                <Grid container spacing={3} className="App-mT10">
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    style={{
                      width: "300px",
                      minWidth: "300px",
                      maxWidth: "300px",
                    }}
                  >
                    {FormatMessage({ id: "Cls005.Label.option" })}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextFieldBox
                      id="outlined-basic"
                      label="記述式"
                      value=""
                      variant="outlined"
                      className="App-w350"
                    />
                  </Grid>
                </Grid>
              ) : (
                <Grid
                  container
                  spacing={3}
                  className="App-mT10"
                  // justify="center"
                >
                  <Grid
                    item
                    xs={12}
                    sm={2}
                    style={{
                      width: "300px",
                      minWidth: "300px",
                      maxWidth: "300px",
                    }}
                  >
                    {FormatMessage({ id: "Cls005.Label.option" })}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={10}
                    style={{
                      width: "calc(100% - 300px)",
                      minWidth: "calc(100% - 300px)",
                      maxWidth: "calc(100% - 300px)",
                    }}
                  >
                    {option.map((val: string, index: number) => {
                      return (
                        <Grid
                          container
                          spacing={1}
                          alignItems="center"
                          key={index}
                        >
                          <Grid item xs={12} key={index}>
                            <TextFieldBox
                              id="outlined-basic"
                              value={option[index]}
                              label={"選択肢" + String(index + 1)}
                              variant="outlined"
                              className={classes.customTextWidth}
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                let opt = option;
                                opt[index] = event.target.value;

                                setOption([...option]);
                              }}
                              error={
                                optionRequiredError.includes(index) ||
                                digitError.includes(index)
                              }
                              helperText={
                                optionRequiredError.includes(index)
                                  ? messages.optionRequiredError
                                  : digitError.includes(index)
                                  ? messages.digitsError
                                  : ""
                              }
                            />
                            <span className="App-mL10">
                              <Button
                                variant="contained"
                                color="default"
                                className="App-button_bottom"
                                style={{ marginTop: "8px", width: "81px" }}
                                startIcon={<DeleteForeverIcon />}
                                onClick={() => {
                                  if (option.length > 1) {
                                    deleteClick(index);
                                  }
                                }}
                              >
                                {FormatMessage({ id: "Button.Del" })}
                              </Button>
                            </span>
                          </Grid>
                        </Grid>
                      );
                    })}
                  </Grid>

                  <Grid container className="" alignItems="center">
                    <Grid item xs={12} sm={12} className="font-red">
                      <span style={{ color: "red" }}>
                        {countError.error_message}
                      </span>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} className="App-mT10">
                    <Grid item xs={12} className="App-alignCenter">
                      <Button
                        variant="contained"
                        color="primary"
                        className="App-button_bottom"
                        startIcon={<AddIcon />}
                        onClick={addClick}
                      >
                        {FormatMessage({ id: "Cls005.Button.add" })}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      {loading ? <LoadingBox /> : null}
    </MainLayout>
  );
};
