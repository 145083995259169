import { apigw } from "../awsApi";
import { Dispatch } from "@reduxjs/toolkit";

/**
 * 授業詳細取得
 * @param {Dispatch} dispatch reducerディスパッチ
 * @param {string} classId 授業ID
 * @param {boolean} statusJugeFlg ステータス判定フラグ
 * @return コンポーネントHTMLコード内容
 */
export const LC0302 = async (
  dispatch: Dispatch,
  classId: string,
  statusJugeFlg: boolean,
  switchingUserClass:boolean
) => {
  if (process.env.REACT_APP_API_USE_FLG === "true") {

    return apigw(
      "/LC0302",
      {
        class_id: classId,
        status_juge_flg: statusJugeFlg,
        switchingUserClass:switchingUserClass
      },
      dispatch
    );
  } else {
    //サンプルデータ用
    return { body: rows };
  }
};

//サンプルデータ
const rows = {
  class_name: "■■資格講座　第1回", //授業名
  page_after_class: 2, //授業後ページ区分
  page_after_url: "https:○○", //授業後ページURL
  class_time: 60, //授業時間
  status_class: "", //ステータス
  channel_id: "fukuyama9999", //チャンネルID
  S3ID: "", //チャイムS3ID
  lecture_update_date: "1970-01-01 00:00:01.000000", //授業マスタ更新日時
  custom_before_answer_msg: "回答してください", //回答前メッセージ
  custom_after_answer_msg: "回答ありがとうございます", //回答完了メッセージ
  custom_enquete_update_date: "1970-01-01 00:00:01.000000", //カスタムアンケート更新日時
  user: [
    {
      user_id: "apitest01", //視聴ユーザID
      user_name: "A", //視聴ユーザ名
      handle_name: "エー", //ハンドルネーム
    },
    {
      user_id: "2", //視聴ユーザID
      user_name: "B", //視聴ユーザ名
      handle_name: "ビー", //ハンドルネーム
    },
    {
      user_id: "3", //視聴ユーザID
      user_name: "C", //視聴ユーザ名
      handle_name: "シー", //ハンドルネーム
    },
    {
      user_id: "4", //視聴ユーザID
      user_name: "A", //視聴ユーザ名
      handle_name: "エー", //ハンドルネーム
    },
    {
      user_id: "5", //視聴ユーザID
      user_name: "B", //視聴ユーザ名
      handle_name: "ビー", //ハンドルネーム
    },
    {
      user_id: "6", //視聴ユーザID
      user_name: "C", //視聴ユーザ名
      handle_name: "シー", //ハンドルネーム
    },
    {
      user_id: "7", //視聴ユーザID
      user_name: "A", //視聴ユーザ名
      handle_name: "エー", //ハンドルネーム
    },
    {
      user_id: "8", //視聴ユーザID
      user_name: "B", //視聴ユーザ名
      handle_name: "ビー", //ハンドルネーム
    },
    {
      user_id: "9", //視聴ユーザID
      user_name: "C", //視聴ユーザ名
      handle_name: "シー", //ハンドルネーム
    },
    {
      user_id: "1", //視聴ユーザID
      user_name: "A", //視聴ユーザ名
      handle_name: "エー", //ハンドルネーム
    },
    {
      user_id: "2", //視聴ユーザID
      user_name: "B", //視聴ユーザ名
      handle_name: "ビー", //ハンドルネーム
    },
    {
      user_id: "3", //視聴ユーザID
      user_name: "C", //視聴ユーザ名
      handle_name: "シー", //ハンドルネーム
    },
    {
      user_id: "4", //視聴ユーザID
      user_name: "A", //視聴ユーザ名
      handle_name: "エー", //ハンドルネーム
    },
    {
      user_id: "5", //視聴ユーザID
      user_name: "B", //視聴ユーザ名
      handle_name: "ビー", //ハンドルネーム
    },
    {
      user_id: "6", //視聴ユーザID
      user_name: "C", //視聴ユーザ名
      handle_name: "シー", //ハンドルネーム
    },
    {
      user_id: "7", //視聴ユーザID
      user_name: "A", //視聴ユーザ名
      handle_name: "エー", //ハンドルネーム
    },
    {
      user_id: "8", //視聴ユーザID
      user_name: "B", //視聴ユーザ名
      handle_name: "ビー", //ハンドルネーム
    },
    {
      user_id: "9", //視聴ユーザID
      user_name: "C", //視聴ユーザ名
      handle_name: "シー", //ハンドルネーム
    },
    {
      user_id: "1", //視聴ユーザID
      user_name: "A", //視聴ユーザ名
      handle_name: "エー", //ハンドルネーム
    },
    {
      user_id: "2", //視聴ユーザID
      user_name: "B", //視聴ユーザ名
      handle_name: "ビー", //ハンドルネーム
    },
    {
      user_id: "3", //視聴ユーザID
      user_name: "C", //視聴ユーザ名
      handle_name: "シー", //ハンドルネーム
    },
    {
      user_id: "4", //視聴ユーザID
      user_name: "A", //視聴ユーザ名
      handle_name: "エー", //ハンドルネーム
    },
    {
      user_id: "5", //視聴ユーザID
      user_name: "B", //視聴ユーザ名
      handle_name: "ビー", //ハンドルネーム
    },
    {
      user_id: "6", //視聴ユーザID
      user_name: "C", //視聴ユーザ名
      handle_name: "シー", //ハンドルネーム
    },
    {
      user_id: "7", //視聴ユーザID
      user_name: "A", //視聴ユーザ名
      handle_name: "エー", //ハンドルネーム
    },
    {
      user_id: "8", //視聴ユーザID
      user_name: "B", //視聴ユーザ名
      handle_name: "ビー", //ハンドルネーム
    },
    {
      user_id: "9", //視聴ユーザID
      user_name: "C", //視聴ユーザ名
      handle_name: "シー", //ハンドルネーム
    },
    {
      user_id: "1", //視聴ユーザID
      user_name: "A", //視聴ユーザ名
      handle_name: "エー", //ハンドルネーム
    },
    {
      user_id: "2", //視聴ユーザID
      user_name: "B", //視聴ユーザ名
      handle_name: "ビー", //ハンドルネーム
    },
    {
      user_id: "3", //視聴ユーザID
      user_name: "C", //視聴ユーザ名
      handle_name: "シー", //ハンドルネーム
    },
    {
      user_id: "4", //視聴ユーザID
      user_name: "A", //視聴ユーザ名
      handle_name: "エー", //ハンドルネーム
    },
    {
      user_id: "5", //視聴ユーザID
      user_name: "B", //視聴ユーザ名
      handle_name: "ビー", //ハンドルネーム
    },
    {
      user_id: "6", //視聴ユーザID
      user_name: "C", //視聴ユーザ名
      handle_name: "シー", //ハンドルネーム
    },
    {
      user_id: "7", //視聴ユーザID
      user_name: "A", //視聴ユーザ名
      handle_name: "エー", //ハンドルネーム
    },
    {
      user_id: "8", //視聴ユーザID
      user_name: "B", //視聴ユーザ名
      handle_name: "ビー", //ハンドルネーム
    },
    {
      user_id: "9", //視聴ユーザID
      user_name: "C", //視聴ユーザ名
      handle_name: "シー", //ハンドルネーム
    },
    {
      user_id: "1", //視聴ユーザID
      user_name: "A", //視聴ユーザ名
      handle_name: "エー", //ハンドルネーム
    },
    {
      user_id: "2", //視聴ユーザID
      user_name: "B", //視聴ユーザ名
      handle_name: "ビー", //ハンドルネーム
    },
    {
      user_id: "3", //視聴ユーザID
      user_name: "C", //視聴ユーザ名
      handle_name: "シー", //ハンドルネーム
    },
    {
      user_id: "4", //視聴ユーザID
      user_name: "A", //視聴ユーザ名
      handle_name: "エー", //ハンドルネーム
    },
    {
      user_id: "5", //視聴ユーザID
      user_name: "B", //視聴ユーザ名
      handle_name: "ビー", //ハンドルネーム
    },
    {
      user_id: "6", //視聴ユーザID
      user_name: "C", //視聴ユーザ名
      handle_name: "シー", //ハンドルネーム
    },
    {
      user_id: "7", //視聴ユーザID
      user_name: "A", //視聴ユーザ名
      handle_name: "エー", //ハンドルネーム
    },
    {
      user_id: "8", //視聴ユーザID
      user_name: "B", //視聴ユーザ名
      handle_name: "ビー", //ハンドルネーム
    },
    {
      user_id: "9", //視聴ユーザID
      user_name: "C", //視聴ユーザ名
      handle_name: "シー", //ハンドルネーム
    },
    {
      user_id: "1", //視聴ユーザID
      user_name: "A", //視聴ユーザ名
      handle_name: "エー", //ハンドルネーム
    },
    {
      user_id: "2", //視聴ユーザID
      user_name: "B", //視聴ユーザ名
      handle_name: "ビー", //ハンドルネーム
    },
    {
      user_id: "3", //視聴ユーザID
      user_name: "C", //視聴ユーザ名
      handle_name: "シー", //ハンドルネーム
    },
    {
      user_id: "4", //視聴ユーザID
      user_name: "A", //視聴ユーザ名
      handle_name: "エー", //ハンドルネーム
    },
    {
      user_id: "5", //視聴ユーザID
      user_name: "B", //視聴ユーザ名
      handle_name: "ビー", //ハンドルネーム
    },
    {
      user_id: "6", //視聴ユーザID
      user_name: "C", //視聴ユーザ名
      handle_name: "シー", //ハンドルネーム
    },
    {
      user_id: "7", //視聴ユーザID
      user_name: "A", //視聴ユーザ名
      handle_name: "エー", //ハンドルネーム
    },
    {
      user_id: "8", //視聴ユーザID
      user_name: "B", //視聴ユーザ名
      handle_name: "ビー", //ハンドルネーム
    },
    {
      user_id: "9", //視聴ユーザID
      user_name: "C", //視聴ユーザ名
      handle_name: "シー", //ハンドルネーム
    },
    {
      user_id: "1", //視聴ユーザID
      user_name: "A", //視聴ユーザ名
      handle_name: "エー", //ハンドルネーム
    },
    {
      user_id: "2", //視聴ユーザID
      user_name: "B", //視聴ユーザ名
      handle_name: "ビー", //ハンドルネーム
    },
    {
      user_id: "3", //視聴ユーザID
      user_name: "C", //視聴ユーザ名
      handle_name: "シー", //ハンドルネーム
    },
    {
      user_id: "4", //視聴ユーザID
      user_name: "A", //視聴ユーザ名
      handle_name: "エー", //ハンドルネーム
    },
    {
      user_id: "5", //視聴ユーザID
      user_name: "B", //視聴ユーザ名
      handle_name: "ビー", //ハンドルネーム
    },
    {
      user_id: "6", //視聴ユーザID
      user_name: "C", //視聴ユーザ名
      handle_name: "シー", //ハンドルネーム
    },
    {
      user_id: "7", //視聴ユーザID
      user_name: "A", //視聴ユーザ名
      handle_name: "エー", //ハンドルネーム
    },
    {
      user_id: "8", //視聴ユーザID
      user_name: "B", //視聴ユーザ名
      handle_name: "ビー", //ハンドルネーム
    },
    {
      user_id: "9", //視聴ユーザID
      user_name: "C", //視聴ユーザ名
      handle_name: "シー", //ハンドルネーム
    },
    {
      user_id: "1", //視聴ユーザID
      user_name: "A", //視聴ユーザ名
      handle_name: "エー", //ハンドルネーム
    },
    {
      user_id: "2", //視聴ユーザID
      user_name: "B", //視聴ユーザ名
      handle_name: "ビー", //ハンドルネーム
    },
    {
      user_id: "3", //視聴ユーザID
      user_name: "C", //視聴ユーザ名
      handle_name: "シー", //ハンドルネーム
    },
    {
      user_id: "4", //視聴ユーザID
      user_name: "A", //視聴ユーザ名
      handle_name: "エー", //ハンドルネーム
    },
    {
      user_id: "5", //視聴ユーザID
      user_name: "B", //視聴ユーザ名
      handle_name: "ビー", //ハンドルネーム
    },
    {
      user_id: "6", //視聴ユーザID
      user_name: "C", //視聴ユーザ名
      handle_name: "シー", //ハンドルネーム
    },
    {
      user_id: "7", //視聴ユーザID
      user_name: "A", //視聴ユーザ名
      handle_name: "エー", //ハンドルネーム
    },
    {
      user_id: "8", //視聴ユーザID
      user_name: "B", //視聴ユーザ名
      handle_name: "ビー", //ハンドルネーム
    },
    {
      user_id: "9", //視聴ユーザID
      user_name: "C", //視聴ユーザ名
      handle_name: "シー", //ハンドルネーム
    },
    {
      user_id: "1", //視聴ユーザID
      user_name: "A", //視聴ユーザ名
      handle_name: "エー", //ハンドルネーム
    },
    {
      user_id: "2", //視聴ユーザID
      user_name: "B", //視聴ユーザ名
      handle_name: "ビー", //ハンドルネーム
    },
    {
      user_id: "3", //視聴ユーザID
      user_name: "C", //視聴ユーザ名
      handle_name: "シー", //ハンドルネーム
    },
    {
      user_id: "4", //視聴ユーザID
      user_name: "A", //視聴ユーザ名
      handle_name: "エー", //ハンドルネーム
    },
    {
      user_id: "5", //視聴ユーザID
      user_name: "B", //視聴ユーザ名
      handle_name: "ビー", //ハンドルネーム
    },
    {
      user_id: "6", //視聴ユーザID
      user_name: "C", //視聴ユーザ名
      handle_name: "シー", //ハンドルネーム
    },
    {
      user_id: "7", //視聴ユーザID
      user_name: "A", //視聴ユーザ名
      handle_name: "エー", //ハンドルネーム
    },
    {
      user_id: "8", //視聴ユーザID
      user_name: "B", //視聴ユーザ名
      handle_name: "ビー", //ハンドルネーム
    },
    {
      user_id: "9", //視聴ユーザID
      user_name: "C", //視聴ユーザ名
      handle_name: "シー", //ハンドルネーム
    },
    {
      user_id: "1", //視聴ユーザID
      user_name: "A", //視聴ユーザ名
      handle_name: "エー", //ハンドルネーム
    },
    {
      user_id: "2", //視聴ユーザID
      user_name: "B", //視聴ユーザ名
      handle_name: "ビー", //ハンドルネーム
    },
    {
      user_id: "3", //視聴ユーザID
      user_name: "C", //視聴ユーザ名
      handle_name: "シー", //ハンドルネーム
    },
    {
      user_id: "4", //視聴ユーザID
      user_name: "A", //視聴ユーザ名
      handle_name: "エー", //ハンドルネーム
    },
    {
      user_id: "5", //視聴ユーザID
      user_name: "B", //視聴ユーザ名
      handle_name: "ビー", //ハンドルネーム
    },
    {
      user_id: "6", //視聴ユーザID
      user_name: "C", //視聴ユーザ名
      handle_name: "シー", //ハンドルネーム
    },
    {
      user_id: "7", //視聴ユーザID
      user_name: "A", //視聴ユーザ名
      handle_name: "エー", //ハンドルネーム
    },
    {
      user_id: "8", //視聴ユーザID
      user_name: "B", //視聴ユーザ名
      handle_name: "ビー", //ハンドルネーム
    },
    {
      user_id: "9", //視聴ユーザID
      user_name: "C", //視聴ユーザ名
      handle_name: "シー", //ハンドルネーム
    },
  ],
};
