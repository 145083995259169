import { apigw } from "../awsApi";
import { Dispatch } from "@reduxjs/toolkit";
import { store } from "../../reducks/store";

//サンプルデータ
const rows = [
  {
    status_stream_time:100,
    status_stream_time_limit:5000,
  },
];

/**
 * サービス利用状況
 * @param {Dispatch} dispatch           reducerディスパッチ
 * @param {string} useMonth 利用月
 * @return コンポーネントHTMLコード内容
 */

export const CF0305 = async (dispatch: Dispatch) => {
  if (process.env.REACT_APP_API_USE_FLG === "true") {
    var date = new Date();
    var useMonth =
      date.getFullYear() + ("00" + (date.getMonth() + 1)).slice(-2);
    return apigw(
      "/CF0305",
      {
        service_id: store.getState().user.service_id,
        use_month: useMonth,
      },
      dispatch
    );
  } else {
    //サンプルデータ用
    return { body: rows };
  }
};
