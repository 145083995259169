import { apigw } from "../awsApi";
import { Dispatch } from "@reduxjs/toolkit";

//サンプルデータ
const rows = [
    {
      download_document_id : "111",
      download_document_name :"pdf1",
      download_document_type :"3",
      download_s3_id : "http://www.shikisensha.com/nuno2009.pdf"
    },
    {
      download_document_id : "222",
      download_document_name :"pdf2",
      download_document_type :"3",
      download_s3_id : "https://s3-ap-northeast-1.amazonaws.com/cdn.aprico-media.com/resources/sample.pdf"
    },
  ];
  
/**
 * コンテンツ一覧取得
 * @param {Dispatch} dispatch reducerディスパッチ
 * @param {string} classId 授業ID
 * @return コンポーネントHTMLコード内容
 */
export const CT0309 = async (
  dispatch: Dispatch,
  classId: string
) => {
  if (process.env.REACT_APP_API_USE_FLG === "true") {
    return apigw(
      "/CT0309",
      {
        class_id:classId
      },
      dispatch
    );
  } else {
    //サンプルデータ用
    return { body: rows };
  }
};
