import { apigw } from "../awsApi";
import { Dispatch } from "@reduxjs/toolkit";

//サンプルデータ
const state = 0;
const error_code = "04072"

/**
 * 配信ユーザパスワード再設定
 * @param {Dispatch} dispatch reducerディスパッチ
 * @param {string} serviceId サービスID
 * @param {string} userClass ユーザ区分
 * @param {string} newPassword 　新しいパスワード
 * @param {string} resetCode リセットコード
 * @param {string} mailAddress メールアドレス
 * @return コンポーネントHTMLコード内容
 */
export const AU0315 = async (
  dispatch: Dispatch,
  serviceId: string,
  userClass: string,
  newPassword: string,
  resetCode: string,
  mailAddress: string,

) => {
  if (process.env.REACT_APP_API_USE_FLG === "true") {
    return apigw(
      "/AU0315",
      {
        service_id: serviceId,
        user_class: userClass,
        new_password: newPassword,
        reset_code: resetCode,
        mail_address: mailAddress
      },
      dispatch
    );
  } else {
    //サンプルデータ用
    return {state, error_code};
  }
};
