const actionComand = {
  MSG_CLASS_START: "MSG_CLASS_START", //授業開始
  MSG_CLASS_END: "MSG_CLASS_END", //授業終了
  MSG_WB_SHOW_PICTURE: "MSG_WB_SHOW_PICTURE", //背景画像表示
  MSG_WB_CHANGE_SLIDE: "MSG_WB_CHANGE_SLIDE", //スライド切替
  MSG_WB_SHOW_MOVIE: "MSG_WB_SHOW_MOVIE", //動画再生
  MSG_WB_MOVIE_PAUSE: "MSG_WB_MOVIE_PAUSE", //動画一時停止
  MSG_WB_MOVIE_FORWARD_REWIND: "MSG_WB_MOVIE_FORWARD_REWIND", //動画早送り／巻き戻し
  MSG_WB_MOVIE_STOP: "MSG_WB_MOVIE_STOP", //動画停止
  MSG_WB_DRAW: "MSG_WB_DRAW", //手書き
  MSG_WB_DRAW_FAT: "MSG_WB_DRAW_FAT", //ペンの太さ
  MSG_WB_DRAW_COLOR: "MSG_WB_DRAW_COLOR", //ペン色
  MSG_WB_EXPANTION: "MSG_WB_EXPANTION", //拡大率変更
  MSG_LAYOUT_CHANGE: "MSG_LAYOUT_CHANGE", //レイアウト切替
  MSG_JOIN_REQUEST: "MSG_JOIN_REQUEST", //参加依頼
  MSG_JOIN_RESULT: "MSG_JOIN_RESULT", //参加依頼結果
  MSG_QUIZ_QUESTIONS: "MSG_QUIZ_QUESTIONS", //択一問題出題
  MSG_QUIZ_ANSWER: "MSG_QUIZ_ANSWER", //択一問題回答
  MSG_REACTION: "MSG_REACTION", //反応
  MSG_CHAT_SEND_MESSAGE: "MSG_CHAT_SEND_MESSAGE", //チャット送信
  MSG_CHAT_SEND_SETTING: "MSG_CHAT_SEND_SETTING", //チャット送信制御
  MSG_CHAT_DISPLAY_SETTINGS: "MSG_CHAT_DISPLAY_SETTINGS", //チャット表示制御
  MSG_WB_REQUEST: "MSG_WB_REQUEST", //ボードリクエスト
  MSG_WB_RESULT: "MSG_WB_RESULT", //ボードリクエスト結果
  MSG_SETTNG_REQUEST: "MSG_SETTNG_REQUEST", //制御リクエスト
  MSG_SETTING_RESULT: "MSG_SETTING_RESULT", //制御リクエスト結果
  MSG_CLASS_EXIT: "MSG_CLASS_EXIT", //退出
  MSG_WB_SCREEN_SHARE: "MSG_WB_SCREEN_SHARE", //画面共有
  MSG_STREAMER_CHECK: "MSG_STREAMER_CHECK", //配信者存在チェック
  MSG_STREAMER_CHANGE: "MSG_STREAMER_CHANGE", //配信者入れ替え
  MSG_IMGLIST_RESET: "MSG_IMGLIST_RESET", //写真リセット
  MSG_VIEW_USER_INFO: "MSG_VIEW_USER_INFO", //視聴ユーザ情報
  MSG_GVIEW_USER_INFO: "MSG_GVIEW_USER_INFO", //再度視聴ユーザ情報取得
  MSG_PLAY_CHIME: "MSG_PLAY_CHIME", //授業終了時チャイム
  MSG_AGORAID: "MSG_AGORAID", //agora uid
  MSG_DISPLAY_CLICK: "MSG_DISPLAY_CLICK", //画面タップ
  MSG_ClASSTIME_LIMIT: "MSG_ClASSTIME_LIMIT", //授業時間制限
  MSG_TIME_REQUEST: "MSG_TIME_REQUEST", //経過時間リクエスト
  MSG_TIME_RESULT: "MSG_TIME_RESULT", //経過時間結果
};

export default actionComand;
