import { apigw } from "../awsApi";
import { Dispatch } from "@reduxjs/toolkit";

/**
 * OQ0301 カスタムアンケート詳細取得
 * @param {Dispatch} dispatch reducerディスパッチ
 * @param {string} classId 授業ID
 * @param {string|null} questionNo 質問番号
 * @return コンポーネントHTMLコード内容
 */
export const OQ0301 = async (
  dispatch: Dispatch,
  classId: string,
  questionNo: string | null
) => {
  let result: any;
  if (process.env.REACT_APP_API_USE_FLG === "true") {
    await apigw(
      "/OQ0301",
      {
        class_id: classId,
        question_no: questionNo,
      },
      dispatch
    ).then((ret) => {
      if (
        ret &&
        Object.keys(ret).indexOf("state") >= 0
      ) {
        result = ret;
        // if (ret.state === 0) {
        //   return ret;
        // } else {
        //   return false;
        // }
      }
    });
    return result;
  } else {
    if (questionNo === null) {
      result = {
        state: 0,
        error_code: "",
        body: {
          // 質問
          question: [
            {
              question_no: "1", // 質問No
              question: "ラジオボタンを選択する質問", // 質問（内容）
              character_class: "2", // 回答方式 1:記述式、2:ラジオボタン、3:チェックボックス、4:プルダウン
              is_required: "1", // 必須フラグ
              option: [
                {
                  option_1: "ラジオ選択肢1",
                  option_2: "ラジオ選択肢2",
                  option_3: "ラジオ選択肢3",
                  // 本当は50まで入るが、省略。
                },
              ],
              update_date: new Date(2021, 8 - 1, 5),
            },
            {
              question_no: "2", // 質問No
              question: "記述してもらう質問", // 質問（内容）
              character_class: "1", // 回答方式 1:記述式、2:ラジオボタン、3:チェックボックス、4:プルダウン
              is_required: "0", // 必須フラグ
              option: [
                { option_1: "記述式なので表示されたらどこかにバグがある。" },
              ],
              // 記述式では不必要だが、一つだけ置いておく。
              update_date: new Date(2021, 8 - 1, 6),
            },
            {
              question_no: "3", // 質問No
              question: "ラジオボタンを選択する質問", // 質問（内容）
              character_class: "2", // 回答方式 1:記述式、2:ラジオボタン、3:チェックボックス、4:プルダウン
              is_required: "1", // 必須フラグ
              option: [
                {
                  option_1: "ラジオ選択肢1",
                  option_2: "ラジオ選択肢2",
                  option_3: "ラジオ選択肢3",
                },
              ],

              // 本当は50まで入るが、省略。
              update_date: new Date(2021, 8 - 1, 7),
            },
            {
              question_no: "4", // 質問No
              question: "チェックボックスを選択する質問", // 質問（内容）
              character_class: "3", // 回答方式 1:記述式、2:ラジオボタン、3:チェックボックス、4:プルダウン
              is_required: "0", // 必須フラグ
              option: [
                {
                  option_1: "チェックボックス選択肢1",
                  option_2: "チェックボックス選択肢2",
                  option_3: "チェックボックス選択肢3",
                  option_4: "チェックボックス選択肢4",
                  option_5: "チェックボックス選択肢5",
                },
              ],
              // 本当は50まで入るが、省略。
              update_date: new Date(2021, 8 - 1, 8),
            },
            {
              question_no: "5", // 質問No
              question: "プルダウンを選択する質問", // 質問（内容）
              character_class: "4", // 回答方式 1:記述式、2:ラジオボタン、3:チェックボックス、4:プルダウン
              is_required: "0", // 必須フラグ
              option: [
                {
                  option_1: "リスト1",
                  option_2: "リスト2",
                  option_3: "リスト3",
                  option_4: "リスト4",
                  option_5: "リスト5",
                  option_6: "リスト6",
                  option_7: "リスト7",
                  option_8: "リスト8",
                  option_9: "リスト9",
                  option_10: "リスト10",
                  option_11: "リスト11",
                  option_12: "リスト12",
                  option_13: "リスト13",
                  option_14: "リスト14",
                  option_15: "リスト15",
                  option_16: "リスト16",
                  option_17: "リスト17",
                },
              ],
              // 本当は50まで入るが、省略。
              update_date: new Date(2021, 8 - 1, 9),
            },
          ],
        },
      };
      if (
        result &&
        Object.keys(result).indexOf("state") >= 0 &&
        Object.keys(result).indexOf("body") >= 0
      ) {
        if (result.state === 0) {
          return result;
        } else {
          return false;
        }
      }
    } else if (questionNo === "1") {
      result = {
        state: 0,
        error_code: "",
        body: {
          // 質問
          question: [
            {
              question_no: "1", // 質問No
              question: "ラジオボタンを選択する質問", // 質問（内容）
              character_class: "2", // 回答方式 1:記述式、2:ラジオボタン、3:チェックボックス、4:プルダウン
              is_required: "1", // 必須フラグ
              option: [
                {
                  option_1: "ラジオ選択肢1",
                  option_2: "ラジオ選択肢2",
                  option_3: "ラジオ選択肢3",
                  // 本当は50まで入るが、省略。
                },
              ],
              update_date: new Date(2021, 8 - 1, 5),
            },
          ],
        },
      };
      if (
        result &&
        Object.keys(result).indexOf("state") >= 0 &&
        Object.keys(result).indexOf("body") >= 0
      ) {
        if (result.state === 0) {
          return result;
        } else {
          return false;
        }
      }
    } else if (questionNo === "2") {
      result = {
        state: 0,
        error_code: "",
        body: {
          // 質問
          question: [
            {
              question_no: "2", // 質問No
              question: "記述してもらう質問", // 質問（内容）
              character_class: "1", // 回答方式 1:記述式、2:ラジオボタン、3:チェックボックス、4:プルダウン
              is_required: "0", // 必須フラグ
              option: [
                { option_1: "記述式なので表示されたらどこかにバグがある。" },
              ],
              // 記述式では不必要だが、一つだけ置いておく。
              update_date: new Date(2021, 8 - 1, 6),
            },
          ],
        },
      };
      if (
        result &&
        Object.keys(result).indexOf("state") >= 0 &&
        Object.keys(result).indexOf("body") >= 0
      ) {
        if (result.state === 0) {
          return result;
        } else {
          return false;
        }
      }
    } else if (questionNo === "3") {
      result = {
        state: 0,
        error_code: "",
        body: {
          // 質問
          question: [
            {
              question_no: "3", // 質問No
              question: "ラジオボタンを選択する質問", // 質問（内容）
              character_class: "2", // 回答方式 1:記述式、2:ラジオボタン、3:チェックボックス、4:プルダウン
              is_required: "1", // 必須フラグ
              option: [
                {
                  option_1: "ラジオ選択肢1",
                  option_2: "ラジオ選択肢2",
                  option_3: "ラジオ選択肢3",
                },
              ],

              // 本当は50まで入るが、省略。
              update_date: new Date(2021, 8 - 1, 7),
            },
          ],
        },
      };
      if (
        result &&
        Object.keys(result).indexOf("state") >= 0 &&
        Object.keys(result).indexOf("body") >= 0
      ) {
        if (result.state === 0) {
          return result;
        } else {
          return false;
        }
      }
    } else if (questionNo === "4") {
      result = {
        state: 0,
        error_code: "",
        body: {
          // 質問
          question: [
            {
              question_no: "4", // 質問No
              question: "チェックボックスを選択する質問", // 質問（内容）
              character_class: "3", // 回答方式 1:記述式、2:ラジオボタン、3:チェックボックス、4:プルダウン
              is_required: "0", // 必須フラグ
              option: [
                {
                  option_1: "チェックボックス選択肢1",
                  option_2: "チェックボックス選択肢2",
                  option_3: "チェックボックス選択肢3",
                  option_4: "チェックボックス選択肢4",
                  option_5: "チェックボックス選択肢5",
                },
              ],
              // 本当は50まで入るが、省略。
              update_date: new Date(2021, 8 - 1, 8),
            },
          ],
        },
      };
      if (
        result &&
        Object.keys(result).indexOf("state") >= 0 &&
        Object.keys(result).indexOf("body") >= 0
      ) {
        if (result.state === 0) {
          return result;
        } else {
          return false;
        }
      }
    } else if (questionNo === "5") {
      result = {
        state: 0,
        error_code: "",
        body: {
          // 質問
          question: [
            {
              question_no: "5", // 質問No
              question: "プルダウンを選択する質問", // 質問（内容）
              character_class: "4", // 回答方式 1:記述式、2:ラジオボタン、3:チェックボックス、4:プルダウン
              is_required: "0", // 必須フラグ
              option: [
                {
                  option_1: "リスト1",
                  option_2: "リスト2",
                  option_3: "リスト3",
                  option_4: "リスト4",
                  option_5: "リスト5",
                  option_6: "リスト6",
                  option_7: "リスト7",
                  option_8: "リスト8",
                  option_9: "リスト9",
                  option_10: "リスト10",
                  option_11: "リスト11",
                  option_12: "リスト12",
                  option_13: "リスト13",
                  option_14: "リスト14",
                  option_15: "リスト15",
                  option_16: "リスト16",
                  option_17: "リスト17",
                },
              ],
              // 本当は50まで入るが、省略。
              update_date: new Date(2021, 8 - 1, 9),
            },
          ],
        },
      };
      if (
        result &&
        Object.keys(result).indexOf("state") >= 0 &&
        Object.keys(result).indexOf("body") >= 0
      ) {
        if (result.state === 0) {
          return result;
        } else {
          return false;
        }
      }
    }
  }
};
