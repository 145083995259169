// import React, { useEffect } from "react";
// import LayersClearIcon from "@material-ui/icons/LayersClear";
import IconButton from "@material-ui/core/IconButton";
// import { FaEraser, FaRegHandPointer } from "react-icons/fa";
import Brightness1Icon from "@material-ui/icons/Brightness1";
// import Dialog from "@material-ui/core/Dialog";
// import DialogActions from "@material-ui/core/DialogActions";
// import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from "@material-ui/core/DialogContentText";
// import DialogTitle from "@material-ui/core/DialogTitle";
// import Button from "@material-ui/core/Button";
// import { FormatMessage } from "../../components/atoms/FormatMessage";
import ActionComand from "./comand";

const colorList1 = [
  { colorName: "red", color: "rgba(255,75,0,1)" },
  { colorName: "orange", color: "rgba(246,170,0,1)" },
  { colorName: "yellow", color: "rgba(255,241,0,1)" },
  { colorName: "green", color: "rgba(3,175,122,1)" },
  { colorName: "blue", color: "rgba(0,90,255,1)" },
  { colorName: "skyblue", color: "rgba(77,196,255,1)" },
  { colorName: "brown", color: "rgba(128,64,0,1)" },
  { colorName: "pink", color: "rgba(255,202,191,1)" },
  { colorName: "gray", color: "rgba(200,200,203,1)" },
  { colorName: "black", color: "rgba(0,0,0,1)" },
];

const colorList2 = [
  { colorName: "trans_red", color: "rgba(255,75,0,0.1)" },
  { colorName: "trans_orange", color: "rgba(246,170,0,0.1)" },
  { colorName: "trans_yellow", color: "rgba(255,241,0,0.1)" },
  { colorName: "trans_green", color: "rgba(3,175,122,0.1)" },
  { colorName: "trans_blue", color: "rgba(0,90,255,0.1)" },
  { colorName: "trans_skyblue", color: "rgba(77,196,255,0.1)" },
  { colorName: "trans_brown", color: "rgba(128,64,0,0.1)" },
  { colorName: "trans_pink", color: "rgba(255,202,191,0.1)" },
  { colorName: "trans_gray", color: "rgba(200,200,203,0.1)" },
  { colorName: "trans_black", color: "rgba(0,0,0,0.1)" },
];

export const PenSetting = (props: any) => {
  const { color, width } = props;

  const handleChange = (item: any) => {
    props.setPenColor(item);
    props.setOperetion("pen");
    // props.changeDrowPen();
    props.sendAction(ActionComand.MSG_WB_DRAW_COLOR, null, {
      pen_color: item.color,
    });
  };

  const handlesizeChange = (value: number) => {
    props.setPenWidth(value);
    props.setOperetion("pen");
    props.sendAction(ActionComand.MSG_WB_DRAW_FAT, null, {
      pen_fat: value,
    });
  };

  return (
    <div className="color-picker block-center">
      <ul className="color-list">
        {colorList1.map((item, index) => {
          return (
            <li
              key={"pen_color1_" + index}
              className={
                color === item.color
                  ? "color active " + item.colorName
                  : "color " + item.colorName
              }
              id={item.colorName}
              onClick={() => {
                handleChange(item);
              }}
            ></li>
          );
        })}
      </ul>
      <ul className="color-list">
        {colorList2.map((item, index) => {
          return (
            <li
              key={"pen_color2_" + index}
              className={
                color === item.color
                  ? "color active " + item.colorName
                  : "color " + item.colorName
              }
              id={item.colorName}
              onClick={() => {
                handleChange(item);
              }}
            ></li>
          );
        })}
      </ul>
      <ul className="color-list">
        <li>
          <IconButton
            style={{ color: width === 3 ? "black" : "gray" }}
            onClick={() => {
              handlesizeChange(3);
            }}
          >
            <Brightness1Icon style={{ fontSize: "10px" }} />
          </IconButton>
        </li>
        <li>
          <IconButton
            style={{ color: width === 7 ? "black" : "gray" }}
            onClick={() => {
              handlesizeChange(7);
            }}
          >
            <Brightness1Icon style={{ fontSize: "20px" }} />
          </IconButton>
        </li>
        <li>
          <IconButton
            style={{ color: width === 11 ? "black" : "gray" }}
            onClick={() => {
              handlesizeChange(11);
            }}
          >
            <Brightness1Icon style={{ fontSize: "30px" }} />
          </IconButton>
        </li>
        <li>
          <IconButton
            style={{ color: width === 15 ? "black" : "gray" }}
            onClick={() => {
              handlesizeChange(15);
            }}
          >
            <Brightness1Icon style={{ fontSize: "40px" }} />
          </IconButton>
        </li>
      </ul>
    </div>
  );
};
