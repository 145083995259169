import React from "react";
import { AuthGuard } from "./utils/AuthGuard";
import { Route, Switch } from "react-router";

import { NotFound } from "./views/NotFound";
import { ErrorPage } from "./views/ErrorPage";

import { Log001 } from "./views/log/001"; //配信ユーザログイン
import { Log002 } from "./views/log/002"; //視聴ユーザログイン
import { Pas001 } from "./views/pas/001"; //パスワード変更
import { Pas002 } from "./views/pas/002"; //パスワード変更完了
import { Pas003 } from "./views/pas/003"; //パスワード初期化
import { Pas004 } from "./views/pas/004"; //パスワード再設定
import { Top001 } from "./views/top/001"; //配信ユーザトップ
import { Cls001 } from "./views/cls/001"; //授業設定
import { Cls004 } from "./views/cls/004"; //カスタムアンケート参照
import { Cls005 } from "./views/cls/005"; //カスタムアンケート登録編集
import { Cls006 } from "./views/cls/006"; //カスタムアンケート登録編集確認
import { Cls007 } from "./views/cls/007"; //カスタムアンケート登録編集完了
import { Cls008 } from "./views/cls/008"; //配信ユーザ授業
import { Cls010 } from "./views/cls/010"; //公開日時設定
import { Cls012 } from "./views/cls/012"; //ライブ配信授業
import { Cls013 } from "./views/cls/013"; //オンデマンド授業
import { Cls014 } from "./views/cls/014"; //授業後アンケート
import { Cls015 } from "./views/cls/015"; //授業後アンケート確認
import { Cls016 } from "./views/cls/016"; //送信完了
import { Cls017 } from "./views/cls/017"; //授業後外部URL表示
import { Cls018 } from "./views/cls/018"; //受講記録一覧

import { Sts001 } from "./views/sts/001"; //授業視聴状況一覧

import { Top002 } from "./views/top/002"; //視聴ユーザトップ
import { Set001 } from "./views/set/001"; //アカウント情報（配信ユーザ）
import { Set002 } from "./views/set/002"; //アカウント編集（配信ユーザ）
import { Set003 } from "./views/set/003"; //アカウント編集確認（配信ユーザ）
import { Set004 } from "./views/set/004"; //アカウント編集完了（配信ユーザ）
import { Set005 } from "./views/set/005"; //アカウント情報（視聴ユーザ）
import { Set006 } from "./views/set/006"; //アカウント編集（視聴ユーザ）
import { Set007 } from "./views/set/007"; //アカウント編集確認（視聴ユーザ）
import { Set008 } from "./views/set/008"; //アカウント編集完了（視聴ユーザ）

import { Rtm001 } from "./views/rtm/001"; //RTM+RTC接続チェック

export const Router = () => {
  return (
    <Switch>
      <Route exact path={"/"} component={Log002} />
      <Route exact path={"/streamer/"} component={Log001} />
      <Route exact path={"/pas/003"} component={Pas003} />
      <Route exact path={"/pas/004"} component={Pas004} />
      <Route exact path={"/err/500"} component={ErrorPage} />
      <Route exact path={"/rtm/001"} component={Rtm001} />
      <AuthGuard>
        <Switch>
          <Route exact path={"/top/001"} component={Top001} />
          <Route exact path={"/pas/001"} component={Pas001} />
          <Route exact path={"/pas/002"} component={Pas002} />
          <Route exact path={"/cls/001"} component={Cls001} />
          <Route exact path={"/cls/004"} component={Cls004} />
          <Route exact path={"/cls/005"} component={Cls005} />
          <Route exact path={"/cls/006"} component={Cls006} />
          <Route exact path={"/cls/007"} component={Cls007} />
          <Route exact path={"/cls/008"} component={Cls008} />
          <Route exact path={"/cls/010"} component={Cls010} />
          <Route exact path={"/cls/012"} component={Cls012} />
          <Route exact path={"/cls/013"} component={Cls013} />
          <Route exact path={"/cls/014"} component={Cls014} />
          <Route exact path={"/cls/015"} component={Cls015} />
          <Route exact path={"/cls/016"} component={Cls016} />
          <Route exact path={"/cls/017"} component={Cls017} />
          <Route exact path={"/cls/018"} component={Cls018} />
          <Route exact path={"/sts/001"} component={Sts001} />

          <Route exact path={"/top/002"} component={Top002} />
          <Route exact path={"/set/001"} component={Set001} />
          <Route exact path={"/set/002"} component={Set002} />
          <Route exact path={"/set/003"} component={Set003} />
          <Route exact path={"/set/004"} component={Set004} />
          <Route exact path={"/set/005"} component={Set005} />
          <Route exact path={"/set/006"} component={Set006} />
          <Route exact path={"/set/007"} component={Set007} />
          <Route exact path={"/set/008"} component={Set008} />

          <Route component={NotFound} />
        </Switch>
      </AuthGuard>
    </Switch>
  );
};
