/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import IconButton from "@material-ui/core/IconButton";
import Slider from "@material-ui/core/Slider";
import { FormatMessage } from "../../components/atoms/FormatMessage";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import "../../css/cls/cls008.css";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import { BsArrowsFullscreen } from "react-icons/bs";
import { BiMenu } from "react-icons/bi";
import KeyboardArrowLeftRoundedIcon from "@material-ui/icons/KeyboardArrowLeftRounded";
import KeyboardArrowRightRoundedIcon from "@material-ui/icons/KeyboardArrowRightRounded";
import PlayArrowIcon from "@material-ui/icons/PlayArrow"; //再生//授業再開
import VolumeUpIcon from "@material-ui/icons/VolumeUp"; //ミュートの切り替え（ミュートにする）
import VolumeOffIcon from "@material-ui/icons/VolumeOff"; //ミュートの切り替え（ミュート解除）
import PauseIcon from "@material-ui/icons/Pause"; //一時停止
import ListAltIcon from "@material-ui/icons/ListAlt"; //チャプターリスト表示
import SpeedIcon from "@material-ui/icons/Speed"; //倍速再生
import CloudDownloadIcon from "@material-ui/icons/CloudDownload"; //キャプチャ保存
import Replay5Icon from "@material-ui/icons/Replay5"; //5秒戻る
import Forward5Icon from "@material-ui/icons/Forward5"; //5秒進む
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Dialog from "@material-ui/core/Dialog";
// import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { getSessionUser } from "../../utils/authFlow";
import { signOut } from "../../reducks/userSlice";
import { store } from "../../reducks/store";
// import { CH0305 } from "../../utils/api/CH0305";
import { LC0305 } from "../../utils/api/LC0305";
import { ST0311 } from "../../utils/api/ST0311";
import { ST0321 } from "../../utils/api/ST0321";
// import { CT0308 } from "../../utils/api/CT0308";
import { QZ0304 } from "../../utils/api/QZ0304";
import { CF0305 } from "../../utils/api/CF0305";
import { ST0326 } from "../../utils/api/ST0326";
// import { setData } from "./setData";
import {
  createS3,
  getListS3,
  getFileS3,
  // getFileBase64S3,
  getUrlS3,
} from "../../components/function/AwsS3Fun";
import educastLogo from "../../css/educast.jpg";
// import { Divider, StepIconClasskey } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import sanitize from "sanitize-html";
import { LoadingBox } from "../../components/atoms/LoadingBox";
import ReplayIcon from "@material-ui/icons/Replay";
import { VideoVtt } from "./VideoVtt";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
  },
  b50: {
    float: "left",
    margin: "10px 20px",
  },
  b60: {
    float: "left",
    margin: "10px 0 0 10px",
  },
  b70: {
    float: "right",
    margin: "10px 0 0 10px",
  },
  t50: {
    float: "left",
    margin: "10px 0 0 10px",
    color: "#fff",
  },
  headerIcon: {
    fontSize: "23px",
    color: "#3498db",
  },
  popoverContent: {
    pointerEvents: "auto",
  },
}));

declare global {
  interface Document {
    mozCancelFullScreen?: () => Promise<void>;
    msExitFullscreen?: () => Promise<void>;
    webkitExitFullscreen?: () => Promise<void>;
    mozFullScreenElement?: Element;
    msFullscreenElement?: Element;
    webkitFullscreenElement?: Element;
    webkitIsFullScreen?: any;
  }

  interface HTMLElement {
    msRequestFullscreen?: () => Promise<void>;
    mozRequestFullscreen?: () => Promise<void>;
    webkitRequestFullscreen?: () => Promise<void>;
  }
}
// let lastCount = 0;
type answerOption = {
  answer_No: number;
  answer: string;
};
let registeredListener: any;
let stayFlg = false;
// let userId: string;
let serverId: string, classId: string, existingUseClass: string;
// let chatList: any,
let maxNum: number = 0;
let imgSelect: number = 0;
let msgcount = 0;
let filePath: any;
// let count = 0;
let pageAfterClass: string;
let PCflg = true;
let isVisibleDefaultEnquete: string;
let recordInterval: any, screenIsSmall: any;
let left_table = 308;
let dispb: boolean;
let operationLog: any = {};
let dbTouch: any;
let canvasDraw: any;
let playbarDis: boolean = false;
let quizOptions: answerOption[] = [];
let s3: any;
let getdate: any;
let videoRows: any;
let captureFlg = false;
// let msgLength = 300;
let seekTime = 0;
let seekTimeSend = 0;
let seekTimecheck = 0;
let chatListNo: any;
let chatBoxData: any;
let seekFlg: any;
let chatChangeFlg: any;
let befsaveTime = 0;
let limitTime = 3.5 * 60 * 60 * 1000;
let saveLogTime: any;

export const Cls013 = (props: any) => {
  const dispatch = useDispatch();
  let sessionDateValue = localStorage.getItem("Cls013_session") as string;
  const getDateValue =
    typeof props.location.state === "undefined"
      ? JSON.parse(sessionDateValue)
      : props.location.state;
  if (typeof props.location.state !== "undefined") {
    localStorage.setItem(
      "Cls013_session",
      JSON.stringify(props.location.state)
    );
  }
  const goBack = () => {
    props.history.goBack();
  };

  if (!getDateValue) {
    goBack();
  }

  const classes = useStyles();
  const [video1, setVideo1] = React.useState("");
  const [video2, setVideo2] = React.useState("");
  const [vttSrc, setVttSrc] = React.useState("");
  const [play, setPlay] = React.useState(false);
  const refPlay = useRef(play);
  refPlay.current = play;
  const [Release, setRelease] = React.useState(false);
  const [allWidth, setAllWidth] = React.useState(false);
  const [allLefeWidth, setAllLeftWidth] = React.useState(false);
  const [seek, setSeek] = React.useState<number | number[]>(0);
  const [maxSeek, setMaxSeek] = React.useState<number>(0);
  // const [chatData, setChatData] = React.useState<any>();
  const [chatListData, setChatListData] = React.useState<any[]>([]);
  const [imgListData, setImgListData] = React.useState<any>({});
  const [className, setClassName] = React.useState("");
  const [chatDisp, setChatDisp] = React.useState(true);
  const [disabled, setDisabled] = React.useState(false);
  const [wbHeight, setWbHeight] = React.useState(0);

  const winResize = () => {
    let path = store.getState().router.location.pathname;
    if (path !== "/cls/013") {
      return;
    }
    let window_H = document.getElementsByTagName("body")[0].offsetHeight;
    let window_W = document.getElementsByTagName("body")[0].offsetWidth;

    if (screenIsSmall || existingUseClass === "2") {
      let whiteBoard = document.getElementsByClassName(
        "whiteBoard"
      ) as HTMLCollectionOf<HTMLElement>;
      if (window_H > window_W) {
        whiteBoard[0].style.width = window_W - 10 + "px";
      } else {
        let videoW = window_W;
        let nw = Math.ceil((window_H - 5) * (16 / 9));
        if (nw > videoW) {
          nw = videoW;
        }
        whiteBoard[0].style.width = nw + "px";
        whiteBoard[0].style.height = window_H - 2 + "px";
        setWbHeight(window_H - 2);
      }
    } else {
      if (window_H > window_W && window_W < 768) {
        setAllWidth(true);
        let whiteBoard1 = document.getElementsByClassName(
          "whiteBoard"
        ) as HTMLCollectionOf<HTMLElement>;
        let nw = 0;
        nw = window_W - 10;
        if (whiteBoard1[0] && whiteBoard1[0].style) {
          whiteBoard1[0].style.width = nw + "px";
          whiteBoard1[0].style.height = nw / (16 / 9) + "px";
        }
        setWbHeight(nw / (16 / 9));
        setTimeout(() => {
          let chatBox = document.getElementsByClassName(
            "chat_div_2"
          ) as HTMLCollectionOf<HTMLElement>;
          let whiteBoard = document.getElementsByClassName(
            "whiteBoard"
          ) as HTMLCollectionOf<HTMLElement>;
          let streamer = document.getElementsByClassName(
            "video_view_2"
          ) as HTMLCollectionOf<HTMLElement>;
          if (
            whiteBoard &&
            whiteBoard[0] &&
            streamer &&
            streamer[0] &&
            chatBox &&
            chatBox[0]
          ) {
            let hightOff =
              whiteBoard[0].offsetHeight + streamer[0].offsetHeight;
            chatBox[0].style.height =
              document.getElementsByTagName("body")[0].offsetHeight -
              hightOff -
              4 +
              "px";
          }
        }, 500);
      } else {
        setAllWidth(false);
        let chatBox = document.getElementsByClassName(
          "chat_div_1"
        ) as HTMLCollectionOf<HTMLElement>;
        let whiteBoard = document.getElementsByClassName(
          "whiteBoard"
        ) as HTMLCollectionOf<HTMLElement>;
        let streamer = document.getElementsByClassName(
          "video_view_1"
        ) as HTMLCollectionOf<HTMLElement>;
        let videoW = window_W - left_table;
        let nw = Math.ceil(window_H * (16 / 9));
        if (nw > videoW) {
          nw = videoW;
        }
        if (whiteBoard && whiteBoard.length > 0) {
          whiteBoard[0].style.width = nw + "px";
          whiteBoard[0].style.height = nw / (16 / 9) + "px";
        }
        setWbHeight(nw / (16 / 9));
        setTimeout(() => {
          if (streamer && chatBox && chatBox[0]) {
            if (
              document.getElementsByTagName("body")[0].offsetHeight -
                streamer[0].offsetHeight -
                2 >
              50
            ) {
              chatBox[0].style.height =
                document.getElementsByTagName("body")[0].offsetHeight -
                streamer[0].offsetHeight -
                2 +
                "px";
            } else {
              chatBox[0].style.height = "50px";
            }
          }
        }, 500);
      }
    }
  };

  const getChatLog = async (classId: string) => {
    if (existingUseClass !== "2") {
      const s3 = await createS3();

      const readAsData = (file: any) => {
        return new Promise((resolve, reject) => {
          let reader = new FileReader();
          reader.onload = () => {
            resolve(reader.result);
          };
          reader.onerror = () => {
            reject(reader.error);
          };
          reader.readAsText(file);
        });
      };
      if (s3) {
        let tmpPath =
          "haishin/" + serverId + "/" + classId + "/chatMessage/all_list.json";
        let fileData = await getFileS3(s3, tmpPath);
        if (fileData) {
          let jsonData: any = await readAsData(fileData);
          let messageJson = JSON.parse(jsonData);
          if (messageJson) {
            let keys = Object.keys(messageJson);
            for (let index = 0; index < keys.length; index++) {
              chatBoxData[Number(keys[index])] = messageJson[keys[index]];
            }
          }
        }
        await chatDataAdd(0);
      }
    }

    // if (existingUseClass !== "2") {
    //   const s3 = await createS3();

    //   const readAsData = (file: any) => {
    //     return new Promise((resolve, reject) => {
    //       let reader = new FileReader();
    //       reader.onload = () => {
    //         resolve(reader.result);
    //       };
    //       reader.onerror = () => {
    //         reject(reader.error);
    //       };
    //       reader.readAsText(file);
    //     });
    //   };
    //   if (s3) {
    //     let chatData: any = {};
    //     // let reaPath = "haishin/" + serverId + "/" + classId + "/reaction/";
    //     // let reaList = await getListS3(s3, reaPath);
    //     // if (reaList && reaList.length > 0) {
    //     //   reaList.sort(function (a: any, b: any) {
    //     //     if (a.LastModified > b.LastModified) return 1;
    //     //     if (a.LastModified < b.LastModified) return -1;
    //     //     return 0;
    //     //   });
    //     //   for (let index = 0; index < reaList.length; index++) {
    //     //     let fileKey = reaList[index].Key;
    //     //     if (fileKey && typeof fileKey !== "undefined") {
    //     //       let fileData = await getFileS3(s3, fileKey);
    //     //       if (fileData) {
    //     //         let jsonData: any = await readAsData(fileData);
    //     //         let reactionJson = JSON.parse(jsonData);
    //     //         if (reactionJson && reactionJson.length > 0) {
    //     //           for (let jsi = 0; jsi < reactionJson.length; jsi++) {
    //     //             const reactionInf = reactionJson[jsi];
    //     //             if (!chatData[reactionInf.nowSeconds])
    //     //               chatData[reactionInf.nowSeconds] = { rd: [], md: [] };
    //     //             chatData[reactionInf.nowSeconds].rd.push({
    //     //               send_time: reactionInf.currentTime,
    //     //               user_id: reactionInf.send_user_id,
    //     //               user_name: reactionInf.send_user_name
    //     //                 ? reactionInf.send_user_name
    //     //                 : "",
    //     //               reaction: reactionInf.reaction,
    //     //             });
    //     //           }
    //     //         }
    //     //       }
    //     //     }
    //     //   }
    //     // }
    //     let msgPath = "haishin/" + serverId + "/" + classId + "/chatMessage/";
    //     let msgList = await getListS3(s3, msgPath);
    //     if (msgList && msgList.length > 0) {
    //       msgList.sort(function (a: any, b: any) {
    //         if (a.LastModified > b.LastModified) return 1;
    //         if (a.LastModified < b.LastModified) return -1;
    //         return 0;
    //       });
    //       for (let index = 0; index < msgList.length; index++) {
    //         let fileKey = msgList[index].Key;
    //         if (fileKey && typeof fileKey !== "undefined") {
    //           let fileData = await getFileS3(s3, fileKey);
    //           if (fileData) {
    //             let jsonData: any = await readAsData(fileData);
    //             let messageJson = JSON.parse(jsonData);
    //             if (messageJson && messageJson.length > 0) {
    //               for (let jsi = 0; jsi < messageJson.length; jsi++) {
    //                 const messageInf = messageJson[jsi];
    //                 if (!chatData[messageInf.nowSeconds])
    //                   chatData[messageInf.nowSeconds] = { rd: [], md: [] };
    //                 if (Object.keys(messageInf).indexOf("message") >= 0) {
    //                   chatData[messageInf.nowSeconds].md.push({
    //                     send_time: messageInf.currentTime,
    //                     user_id: messageInf.send_user_id,
    //                     user_name: messageInf.send_user_name
    //                       ? messageInf.send_user_name
    //                       : "",
    //                     message: messageInf.message,
    //                   });
    //                 } else {
    //                   chatData[messageInf.nowSeconds].rd.push({
    //                     send_time: messageInf.currentTime,
    //                     user_id: messageInf.send_user_id,
    //                     user_name: messageInf.send_user_name
    //                       ? messageInf.send_user_name
    //                       : "",
    //                     reaction: messageInf.reaction,
    //                   });
    //                 }
    //               }
    //             }
    //           }
    //         }
    //       }
    //     }

    //     let chatKey = Object.keys(chatData);
    //     if (chatKey.length > 0) {
    //       chatKey.sort(function (a: any, b: any) {
    //         if (Number(a) > Number(b)) return 1;
    //         if (Number(a) < Number(b)) return -1;
    //         return 0;
    //       });
    //       let chatS = 0;
    //       let chatG = 0;
    //       let lineG = 0;
    //       let lineR = 0;
    //       let lineY = 0;
    //       let htmlText: any[] = [];
    //       let chat_data: any = {};
    //       let chatDataList: any[] = [];
    //       let chatNum = Math.floor(Number(chatKey[chatKey.length - 1]) / 60);
    //       for (let ci = 0; ci <= chatNum + 1; ci++) {
    //         chatS = ci * 60;
    //         chatG = (ci + 1) * 60;
    //         htmlText = [];
    //         lineG = 0;
    //         lineR = 0;
    //         lineY = 0;

    //         for (let index = 0; index < chatKey.length; index++) {
    //           const key = chatKey[index];
    //           if (Number(key) >= chatS && Number(key) < chatG) {
    //             let chatList = chatData[key].rd.concat(chatData[key].md);
    //             chatList.sort(function (a: any, b: any) {
    //               if (a.send_time > b.send_time) return 1;
    //               if (a.send_time < b.send_time) return -1;
    //               return 0;
    //             });
    //             let text = "";
    //             let textNum = 0;
    //             let userRea: any = {};
    //             for (let a = 0; a < chatList.length; a++) {
    //               const element = chatList[a];
    //               let message = "";
    //               if (element.message) {
    //                 message = element.message;
    //                 if (text !== "") text += "\n";
    //                 text += element.user_name + ":" + message;
    //                 textNum++;
    //               } else {
    //                 if (!userRea[element.user_id])
    //                   userRea[element.user_id] = {
    //                     name: element.user_name,
    //                     reaction1: 0,
    //                     reaction2: 0,
    //                     reaction3: 0,
    //                   };
    //                 if (String(element.reaction) === "1") {
    //                   message = setData.simple_reaction_1;
    //                   lineG++;
    //                   userRea[element.user_id].reaction1++;
    //                 }
    //                 if (String(element.reaction) === "2") {
    //                   message = setData.simple_reaction_2;
    //                   lineR++;
    //                   userRea[element.user_id].reaction2++;
    //                 }
    //                 if (String(element.reaction) === "3") {
    //                   message = setData.simple_reaction_3;
    //                   lineY++;
    //                   userRea[element.user_id].reaction3++;
    //                 }
    //               }
    //               htmlText.push(
    //                 <div key={"chat_data_" + key + "_" + a}>
    //                   {element.user_name}:{message}
    //                 </div>
    //               );
    //             }
    //             let userReaKey = Object.keys(userRea);
    //             for (let index = 0; index < userReaKey.length; index++) {
    //               const uk = userReaKey[index];
    //               if (userRea[uk].reaction1 > 0) {
    //                 if (text !== "") text += "\n";
    //                 text +=
    //                   userRea[uk].name +
    //                   ":" +
    //                   setData.simple_reaction_1 +
    //                   " X " +
    //                   userRea[uk].reaction1;
    //                 textNum++;
    //               }
    //               if (userRea[uk].reaction2 > 0) {
    //                 if (text !== "") text += "\n";
    //                 text +=
    //                   userRea[uk].name +
    //                   ":" +
    //                   setData.simple_reaction_2 +
    //                   " X " +
    //                   userRea[uk].reaction2;
    //                 textNum++;
    //               }
    //               if (userRea[uk].reaction3 > 0) {
    //                 if (text !== "") text += "\n";
    //                 text +=
    //                   userRea[uk].name +
    //                   ":" +
    //                   setData.simple_reaction_3 +
    //                   " X " +
    //                   userRea[uk].reaction3;
    //                 textNum++;
    //               }
    //             }

    //             chatDataList.push({
    //               time: Number(key),
    //               text: text,
    //               num: textNum,
    //             });
    //           }
    //         }
    //         chat_data[ci] = {
    //           htmlList: htmlText,
    //           lineG: lineG,
    //           lineR: lineR,
    //           lineY: lineY,
    //         };
    //         let totalLine = lineG + lineR + lineY;
    //         maxNum = totalLine > maxNum ? totalLine : maxNum;
    //       }
    //       setChatData(chat_data);
    //       setChatListData(chatDataList);
    //     }
    //     chatKey = [];
    //     chatData = {};
    //   }
    //   // let log = await CH0305(dispatch, classId, "0");
    //   // if (
    //   //   log &&
    //   //   log.state === "0" &&
    //   //   log.body &&
    //   //   log.body.chat_results &&
    //   //   log.body.chat_results.length > 0
    //   // ) {
    //   //   chatList = log.body.chat_results;
    //   //   if (chatList.length > 0) {
    //   //     chatList.sort(function (a: any, b: any) {
    //   //       if (a.elapsed_time !== b.elapsed_time) {
    //   //         if (Number(a.elapsed_time) < Number(b.elapsed_time)) return -1;
    //   //         if (Number(a.elapsed_time) > Number(b.elapsed_time)) return 1;
    //   //       }
    //   //       return 0;
    //   //     });
    //   //     let chatS = 0;
    //   //     let chatG = 0;
    //   //     let lineG = 0;
    //   //     let lineR = 0;
    //   //     let lineY = 0;
    //   //     let htmlText: any[] = [];
    //   //     let chat_data: any = {};
    //   //     let chatDataList: any[] = [];
    //   //     let chatNum = Math.floor(
    //   //       chatList[chatList.length - 1].elapsed_time / 60
    //   //     );
    //   //     for (let ci = 0; ci <= chatNum + 1; ci++) {
    //   //       chatS = ci * 60;
    //   //       chatG = (ci + 1) * 60;
    //   //       chat_data[ci] = {
    //   //         htmlList: htmlText,
    //   //         lineG: lineG,
    //   //         lineR: lineR,
    //   //         lineY: lineY,
    //   //       };
    //   //       let totalLine = lineG + lineR + lineY;
    //   //       maxNum = totalLine > maxNum ? totalLine : maxNum;
    //   //       htmlText = [];
    //   //       lineG = 0;
    //   //       lineR = 0;
    //   //       lineY = 0;

    //   //       for (let index = 0; index < chatList.length; index++) {
    //   //         const element = chatList[index];
    //   //         if (
    //   //           element.elapsed_time >= chatS &&
    //   //           element.elapsed_time < chatG
    //   //         ) {
    //   //           let name = "";
    //   //           if (
    //   //             element.streaming_user_name &&
    //   //             element.streaming_user_name !== "null"
    //   //           )
    //   //             name = element.streaming_user_name;
    //   //           if (
    //   //             element.attendance_handle_name &&
    //   //             element.attendance_handle_name !== "null"
    //   //           )
    //   //             name = element.attendance_handle_name;
    //   //           let message = "";
    //   //           if (element.chat_detail && element.chat_detail !== "null") {
    //   //             message = element.chat_detail;
    //   //           }
    //   //           if (
    //   //             element.simple_reaction_detail &&
    //   //             element.simple_reaction_detail !== "null"
    //   //           ) {
    //   //             if (element.simple_reaction_detail === "1") {
    //   //               message = setData.simple_reaction_1;
    //   //               lineG++;
    //   //             }
    //   //             if (element.simple_reaction_detail === "2") {
    //   //               message = setData.simple_reaction_2;
    //   //               lineR++;
    //   //             }
    //   //             if (element.simple_reaction_detail === "3") {
    //   //               message = setData.simple_reaction_3;
    //   //               lineY++;
    //   //             }
    //   //           }

    //   //           htmlText.push(
    //   //             <div>
    //   //               {name}:{message}
    //   //             </div>
    //   //           );
    //   //           chatDataList.push({
    //   //             time: element.elapsed_time,
    //   //             text: name + ":" + message,
    //   //           });
    //   //         }
    //   //       }
    //   //     }
    //   //     setChatData(chat_data);
    //   //     setChatListData(chatDataList);
    //   //   }
    //   // } else {
    //   //   chatList = [];
    //   // }
    // }
  };

  const getAfterClassInfo = async (class_id: string) => {
    let getData = await LC0305(dispatch, class_id);
    if (getData && getData.state === "0" && getData.body) {
      const afterClassInfo = getData.body;
      setClassName(afterClassInfo.class_name);
      isVisibleDefaultEnquete = afterClassInfo.is_visible_default_enquete;
      pageAfterClass = afterClassInfo.page_after_class;
      if (afterClassInfo.class_time)
        seekTimeSend = Number(afterClassInfo.class_time);
    }
  };

  const getQuestionInfo = async (data: any) => {
    let question = await QZ0304(dispatch, classId, data.choice_id);
    if (question && question.state === "0" && question.body) {
      data.choice_id = question.body;
      return data;
    }
    data.choice_id = null;
    return data;
  };

  const getOperationLog = async (classId: string) => {
    if (existingUseClass !== "2") {
      let log = await ST0321(dispatch, classId);
      if (log && log.state === "0" && log.body && log.body.length > 0) {
        const data: any = log.body;
        if (data) {
          for (let index = 0; index < data.length; index++) {
            const element = data[index];
            operationLog[Number(element.elapsed_time)] = element;
          }
          let keys = Object.keys(operationLog);
          for (let index = 0; index < keys.length; index++) {
            const element = operationLog[keys[index]];
            if (
              element.operation_class === "4" &&
              element.choice_id.length > 0
            ) {
              await getQuestionInfo(element).then((data: any) => {
                operationLog[Number(element.elapsed_time)] = data;
              });
            }
          }
        }
      }
    }
  };

  const checkTouchEvent = (event: any) => {
    let path = store.getState().router.location.pathname;
    if (path !== "/cls/013") return;
    if (!event) return;
    if (!dispb) {
      setTimeout(function () {
        dbTouch = 0;
      }, 300);
      let window_H = document.getElementsByTagName("body")[0].offsetHeight;
      let window_W = document.getElementsByTagName("body")[0].offsetWidth;
      let clientX = event.changedTouches[0].clientX;
      let clientY = event.changedTouches[0].clientY;
      let tableRight = document.getElementsByClassName(
        "tableRight"
      ) as HTMLCollectionOf<HTMLElement>;
      if (
        window_H > window_W ||
        (tableRight && tableRight[0].style.display === "none")
      ) {
        let check_width = Math.floor(window_W / 3);
        if (
          dbTouch !== 0 &&
          (dbTouch - clientX >= -5 || dbTouch - clientX <= 5) &&
          clientX <= check_width &&
          clientY < window_H - 80
        ) {
          onReplay5min();
        } else if (
          dbTouch !== 0 &&
          (dbTouch - clientX >= -5 || dbTouch - clientX <= 5) &&
          clientX >= check_width * 2 &&
          clientX <= check_width * 3 &&
          clientY < window_H - 80
        ) {
          onForward5min();
        } else if (
          dbTouch !== 0 &&
          (dbTouch - clientX >= -5 || dbTouch - clientX <= 5) &&
          clientX >= check_width * 1 &&
          clientX <= check_width * 2 &&
          clientY < window_H - 80
        ) {
          palybaronoff();
        }
      } else {
        let whiteBoard = document.getElementsByClassName(
          "whiteBoard"
        ) as HTMLCollectionOf<HTMLElement>;
        if (whiteBoard && whiteBoard.length > 0) {
          let check_width = Math.floor(whiteBoard[0].offsetWidth / 3);
          let check_height = Math.floor(whiteBoard[0].offsetHeight);
          if (
            dbTouch !== 0 &&
            (dbTouch - clientX >= -5 || dbTouch - clientX <= 5) &&
            clientX <= check_width &&
            clientY <= check_height &&
            clientY < window_H - 55
          ) {
            onReplay5min();
          } else if (
            dbTouch !== 0 &&
            (dbTouch - clientX >= -5 || dbTouch - clientX <= 5) &&
            clientY <= check_height &&
            clientX >= check_width * 2 &&
            clientX <= check_width * 3 &&
            clientY < window_H - 55
          ) {
            onForward5min();
          } else if (
            dbTouch !== 0 &&
            (dbTouch - clientX >= -5 || dbTouch - clientX <= 5) &&
            clientX >= check_width * 1 &&
            clientX <= check_width * 2 &&
            clientY <= check_height &&
            clientY < window_H - 55
          ) {
            palybaronoff();
          }
        }
      }
      if (!PCflg) {
        if (!event.target.closest(".hiddleMenu")) {
          if (!event.target.closest(".speed_div")) {
            if (!stayFlg) {
              const vid: any = document.getElementById("video2");
              if (!vid.paused) setPlaybarD(false);
            }
          }
        }
      }
      stayFlg = false;
      dbTouch = clientX;
    }
  };

  const checkKeyDownEvent = (event: any) => {
    let path = store.getState().router.location.pathname;
    if (path !== "/cls/013") {
      return;
    }
    if (!dispb) {
      if (event.keyCode === 37) onReplay5min();
      if (event.keyCode === 39) onForward5min();
      if (document["webkitIsFullScreen"] && event.keyCode === 27)
        exitFullScreen();
    }
  };

  // const sortNumBlock = (a: any, b: any) => {
  //   const sa = String(a.name).replace(/(\d+)/g, (m) => m.padStart(30, "0"));
  //   const sb = String(b.name).replace(/(\d+)/g, (m) => m.padStart(30, "0"));
  //   return sa < sb ? -1 : sa > sb ? 1 : 0;
  // };

  const getChapter = async () => {
    let path = store.getState().router.location.pathname;
    if (path === "/cls/013") {
      const list: any = await getListS3(s3, filePath + "chapter/");
      if (list && list.length > 0) {
        list.sort((a: any, b: any) => {
          const aIndex = Number(
            a.Key.split("/").reverse()[0].split(".")[0].split("_").reverse()[0]
          );
          const bIndex = Number(
            b.Key.split("/").reverse()[0].split(".")[0].split("_").reverse()[0]
          );
          if (aIndex < bIndex) return -1;
          if (aIndex > bIndex) return 1;
          return 0;
        });
        for (let index = 0; index < list.length; index++) {
          if (list[index].Key !== "/") {
            const url = await getUrlS3(s3, list[index].Key);
            imgListData["capture_" + index] = url;

            setImgListData(imgListData);
          }
        }
      }
      setTimeout(getChapter, 1800000);
    }
  };

  const getVideoData = async () => {
    filePath = "haishin/" + serverId + "/" + classId + "/";
    s3 = await createS3();
    getdate = new Date();
    if (s3) {
      getChapter();
      const vttCheck = await getListS3(
        s3,
        "haishin/" + serverId + "/" + classId + "/movie/telop.vtt"
      );
      if (vttCheck && vttCheck.length > 0) {
        const vttUrl: any = await getUrlS3(
          s3,
          "haishin/" + serverId + "/" + classId + "/movie/telop.vtt"
        );
        setVttSrc(vttUrl);
      }
      if (existingUseClass === "2") {
        const element = document.getElementById(
          "chapterListBtn"
        ) as HTMLElement;
        element.style.display = "none";
        const original: any = await getListS3(s3, filePath + "movie/original/");
        if (original && original.length > 0) {
          for (let index = 0; index < original.length; index++) {
            if (original[index].Key.substr(-3) === "mp4") {
              videoRows["original"] = original[index].Key;
              const vidoe_data: any = await getUrlS3(s3, original[index].Key);
              if (vidoe_data) {
                setVideo2(vidoe_data);
                setVideo();
                break;
              }
            }
          }
        } else {
          goBack();
        }
      } else {
        const agora: any = await getListS3(
          s3,
          filePath + "movie/agora_convert/"
        );
        if (agora && agora.length > 0) {
          for (let index = 0; index < agora.length; index++) {
            if (agora[index].Key.substr(-3) === "mp4") {
              videoRows["agora_convert"] = agora[index].Key;
              const videoUrl: any = await getUrlS3(s3, agora[index].Key);
              if (videoUrl) {
                setVideo1(videoUrl);
                break;
              }
            }
          }
        } else {
          goBack();
          return;
        }
        const board: any = await getListS3(
          s3,
          filePath + "movie/board_convert/"
        );
        if (board && board.length > 0) {
          for (let index = 0; index < board.length; index++) {
            if (board[index].Key.substr(-3) === "mp4") {
              videoRows["board_convert"] = board[index].Key;
              const videoData: any = await getUrlS3(s3, board[index].Key);
              if (videoData) {
                setVideo2(videoData);
                setVideo();
                break;
              }
            }
          }
        } else {
          goBack();
        }
      }
    } else {
      goBack();
    }
  };

  const popState = (e: any) => {
    if (store.getState().router.location.pathname === "/cls/013") {
      window.history.go(1);
    }
  };

  const TimeErr = FormatMessage({ id: "Error.Message.ClassTime" });
  const classTimeCheck = async () => {
    let path = store.getState().router.location.pathname;
    if (path === "/cls/013") {
      const checkLiveStatus = await CF0305(dispatch);
      if (
        checkLiveStatus &&
        Object.keys(checkLiveStatus).indexOf("body") >= 0
      ) {
        if (checkLiveStatus.body && checkLiveStatus.body.length > 0) {
          if (
            checkLiveStatus.body[0].status_stream_time <
              checkLiveStatus.body[0].status_stream_time_limit ||
            checkLiveStatus.body[0].stream_time_excess === "1"
          ) {
          } else {
            //配信視聴時間エラーメッセージ表示
            onPause();
            alert(TimeErr);
            setTimeout(getOut, 1000);
          }
        }
      }

      setTimeout(classTimeCheck, 60000);
    }
  };

  useEffect(() => {
    ST0326(true);
    setDisabled(true);
    dispb = true;
    screenIsSmall = false;
    stayFlg = false;
    left_table = 308;
    seekTime = 0;
    msgcount = 0;
    seekTimeSend = 0;
    videoRows = {};
    chatBoxData = {};
    befsaveTime = 0;
    saveLogTime = 0;
    captureFlg = false;
    window.history.pushState(null, "", window.location.href);
    window.addEventListener("popstate", popState);
    var userAgent = window.navigator.userAgent.toLowerCase();
    if (
      userAgent.indexOf("iphone") > 0 ||
      userAgent.indexOf("android") > 0 ||
      userAgent.indexOf("mobile") > 0 ||
      userAgent.indexOf("ipad") > 0 ||
      (userAgent.indexOf("macintosh") > 0 && "ontouchend" in document)
    ) {
      PCflg = false;
    } else {
      PCflg = true;
    }

    /*window.onbeforeunload = function () {
      localStorage.removeItem("playclass");
      localStorage.removeItem("ClassClose");
      localStorage.removeItem("Cls013_session");
    };

    if (localStorage.getItem("ClassClose") === "1") {
      localStorage.removeItem("playclass");
      localStorage.removeItem("ClassClose");
      localStorage.removeItem("Cls013_session");
      window.open("about:blank", "_self")?.close();
    }
    window.onpagehide = function () {
      localStorage.removeItem("playclass");
      localStorage.removeItem("Cls013_session");
      localStorage.removeItem("ClassClose");
    };
    window.onunload = function () {
      localStorage.removeItem("playclass");
      localStorage.removeItem("Cls013_session");
      localStorage.removeItem("ClassClose");
    };*/
    registeredListener = () => {
      setTimeout(() => {
        winResize();
      }, 100);
    };
    registeredListener();
    window.addEventListener("resize", registeredListener);
    window.addEventListener("touchstart", checkTouchEvent);
    window.addEventListener("keydown", checkKeyDownEvent);

    let user = store.getState().user;
    if (user) {
      serverId = user.service_id;
      existingUseClass = String(getDateValue.existingUseClass);
      classId = getDateValue?.classId;
      getAfterClassInfo(classId);
      // getChatLog(classId);
      getOperationLog(classId);

      Promise.all([
        // getAfterClassInfo(classId),
        getChatLog(classId),
        // getOperationLog(classId),
        getVideoData(),
      ]);
    }
    classTimeCheck();
    let videos = document.getElementsByTagName("video");
    if (videos && videos.length > 0) {
      for (let index = 0; index < videos.length; index++) {
        const element = videos[index];
        element.oncontextmenu = function () {
          return false;
        };
      }
    }
    const Document: any = document;
    var hidden: any, visibilityChange: any;
    if (typeof Document.hidden !== "undefined") {
      hidden = "hidden";
      visibilityChange = "visibilitychange";
    } else if (typeof Document.msHidden !== "undefined") {
      hidden = "msHidden";
      visibilityChange = "msvisibilitychange";
    } else if (typeof Document.webkitHidden !== "undefined") {
      hidden = "webkitHidden";
      visibilityChange = "webkitvisibilitychange";
    }
    var checkVisibilityChange = false;
    const visibilityChanged = () => {
      if (!dispb) {
        if (Document[hidden]) {
          if (refPlay.current) {
            checkVisibilityChange = true;
            onPause();
          }
        } else {
          if (checkVisibilityChange) {
            checkVisibilityChange = false;
            onPlay(false);
            setTimeout(() => setPlay(true), 1000);
          }
        }
      }
    };
    document.addEventListener(visibilityChange, visibilityChanged, false);

    return () => {
      document.removeEventListener(visibilityChange, visibilityChanged, false);
      operationLog = {};
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const chatDataAdd = async (now: any) => {
    let newData: any = [];
    if (now > 0) {
      for (let index = now - 1; index >= 0; index--) {
        if (chatBoxData[index]) {
          let chat_m = await getChat(index);
          if (!chat_m) {
            chatDataAdd(now);
            return;
          }
          if (chat_m && chat_m.length > 0) {
            newData = chat_m.concat(newData);
          }
          if (newData.length > 300) break;
        }
      }
    }
    if (chatBoxData[now]) {
      let chat_n = await getChat(now);
      if (!chat_n) {
        chatDataAdd(now);
        return;
      }
      if (chat_n && chat_n.length > 0) {
        newData = newData.concat(chat_n);
      }
    }
    if (newData.length > 0) {
      setChatListData(newData);
      newData = [];
    }
    chatListNo = now;
    chatChangeFlg = false;
  };

  useEffect(() => {
    if (seekFlg) return;
    seekTimecheck = Math.floor(Number(seek) / 60);
    // let list: any = [];
    // let count: number = 0;
    // for (let index = 0; index < chatListData.length; index++) {
    //   const chat = chatListData[index];
    //   if (chat.time < seek) {
    //     count += Number(chat.num);
    //     list.push({ id: index, num: chat.num });
    //   }
    // }
    // if (list.length > 0) {
    //   if (count < msgLength) {
    //     setStartIndexChat(0);
    //     setEndIndexChat(list[list.length - 1].id);
    //   } else {
    //     for (let a = 0; a < list.length; a++) {
    //       count = count - list[a].num;
    //       if (count < msgLength) {
    //         setStartIndexChat(list[a].id);
    //         setEndIndexChat(list[list.length - 1].id);
    //         break;
    //       }
    //     }
    //   }
    // }
    const msgInsert = document.getElementsByClassName("msg-insert");
    if (msgcount !== msgInsert[0].children.length) {
      msgcount = msgInsert[0].children.length;
      const clist = document.getElementsByClassName("chat-box");
      if (clist && clist.length > 0) {
        clist[0].scrollTop = clist[0].scrollHeight;
        clist[1].scrollTop = clist[1].scrollHeight;
      }
    }
  }, [seek]);

  const getVideo = () => {
    return {
      video1: document.getElementById("video1") as HTMLMediaElement,
      video2: document.getElementById("video2") as HTMLMediaElement,
      video3: document.getElementById("video3") as HTMLMediaElement,
    };
  };

  const registViewEndLog = async (time: any) => {
    if (time <= seekTimeSend) {
      let check = await registViewLog(time * 60);
      if (check) {
        setTimeout(() => registViewEndLog(time + 1), 1000);
      }
    }
  };

  const setVideo = () => {
    let getVideoInfo = () => {
      let div = getVideo();
      if (
        div &&
        div.video2 &&
        div.video2.duration &&
        !isNaN(div.video2.duration)
      ) {
        div.video2.addEventListener("timeupdate", onTimeupdate);
        setMaxSeek(Number(div.video2.duration));
        let totalTime = document.getElementById("totalTime") as HTMLElement;
        totalTime.innerHTML = getTimeFormat(div.video2.duration);
        div.video2.muted = existingUseClass === "2" ? false : true;
        div.video2.onended = (event: any) => {
          removeEventListener(() => {
            let div = getVideo();
            if (div && div.video2 && div.video2.duration) {
              let saveTime = Math.ceil(Number(div.video2.duration) / 60);
              if (seekTimeSend > 0 && seekTimeSend > saveTime) {
                registViewEndLog(saveTime);
              } else if (befsaveTime !== saveTime && !captureFlg) {
                registViewLog(Math.ceil(div.video2.duration));
                befsaveTime = saveTime;
              }
            }
            switch (pageAfterClass) {
              case "0":
                if (
                  isVisibleDefaultEnquete &&
                  isVisibleDefaultEnquete === "2"
                ) {
                  dispatch(push("/cls/014", { class_id: sanitize(classId) }));
                } else {
                  getOut();
                }
                break;
              case "1":
              case "2":
                dispatch(push("/cls/014", { class_id: sanitize(classId) }));
                break;
              case "3":
                dispatch(push("/cls/017", { class_id: sanitize(classId) }));
                break;
              default:
                getOut();
                break;
            }
          });
        };
        setDisabled(false);
        winResize();
      } else {
        setVideo();
      }
      if (div && div.video3) {
        div.video3.volume = 0;
      }
      var video1: any = document.getElementById("video1");
      var video3: any = document.getElementById("video3");
      if (video1.textTracks[0]) {
        video1.textTracks[0].mode = "hidden";
      }
      if (video3.textTracks[0]) {
        video3.textTracks[0].mode = "hidden";
      }
    };
    setTimeout(getVideoInfo, 500);
  };

  const onSeek = (event: any, newValue: number | number[]) => {
    seekFlg = true;
    let div = getVideo();
    setLeftMenu(282);
    exitFullScreen();
    setAllLeftWidth(false);
    let duration = document.getElementById("duration") as HTMLElement;
    if (play) {
      setRelease(true);
      if (div && div.video1 && existingUseClass !== "2") div.video1.pause();
      if (div && div.video2) div.video2.pause();
      if (div && div.video3 && existingUseClass !== "2") div.video3.pause();
      if (div && (div.video1 || div.video2 || div.video3)) setPlay(false);
    }
    if (Number.isInteger(newValue)) {
      if (div && div.video1 && existingUseClass !== "2")
        div.video1.currentTime = Number(newValue);
      if (div && div.video2) div.video2.currentTime = Number(newValue);
      if (div && div.video3 && existingUseClass !== "2")
        div.video3.currentTime = Number(newValue);
      duration.innerHTML = getTimeFormat(Number(newValue));
      setSeek(newValue);
    }
  };
  const [loading, setLoading] = React.useState(false);
  const refUrl = async (nowdate: any, state: any) => {
    console.log("URLを再取得する");
    if (existingUseClass === "2") {
      const vidoe_data: any = await getUrlS3(s3, videoRows["original"]);
      if (vidoe_data) {
        setVideo2(vidoe_data);
        if (state === "now") {
          let div = getVideo();
          if (div && div.video1 && existingUseClass !== "2")
            div.video1.currentTime = Number(seekTime);
          if (div && div.video2) div.video2.currentTime = Number(seekTime);
          if (div && div.video3 && existingUseClass !== "2")
            div.video3.currentTime = Number(seekTime);
          let duration = document.getElementById("duration") as HTMLElement;
          duration.innerHTML = getTimeFormat(Number(seekTime));
          setSeek(seekTime);
          onPlay(false);
        } else if (state === "play") {
          setTimeout(onPlay, 1000, true);
        } else {
          setTimeout(onSeekRelease, 1000, true);
        }
        getdate = nowdate;
      } else {
        goBack();
      }
    } else {
      const video1: any = await getUrlS3(s3, videoRows["agora_convert"]);
      const video2: any = await getUrlS3(s3, videoRows["board_convert"]);
      if (video1 && video2) {
        //setVideo1(video1);
        //setVideo2(video2);
        let div = getVideo();
        if (div && div.video1 && existingUseClass !== "2") {
          div.video1.src = video1;
        }
        if (div && div.video2) {
          div.video2.src = video2;
        }
        if (div && div.video3 && existingUseClass !== "2") {
          div.video3.src = video1;
        }
        Promise.all([
          div.video1.load(),
          div.video2.load(),
          div.video3.load(),
        ]).then(() => {
          if (state === "now") {
            if (div && div.video1 && existingUseClass !== "2")
              div.video1.currentTime = Number(seekTime);
            if (div && div.video2) div.video2.currentTime = Number(seekTime);
            if (div && div.video3 && existingUseClass !== "2")
              div.video3.currentTime = Number(seekTime);
            let duration = document.getElementById("duration") as HTMLElement;
            duration.innerHTML = getTimeFormat(Number(seekTime));
            setSeek(seekTime);
            onPlay(false);
          } else if (state === "play") {
            setTimeout(onPlay, 1000, true);
          } else {
            setTimeout(onSeekRelease, 1000, true);
          }
          getdate = nowdate;
        });
      } else {
        goBack();
      }
    }
  };
  const TokenCheck = async (state: string) => {
    const nowdate = new Date();
    seekTime = Number(seek);
    // setLoading(true);
    if (nowdate.getTime() - getdate.getTime() > limitTime) {
      await refUrl(nowdate, state);
    } else {
      if (state === "play") {
        onPlay(false);
      } else {
        onSeekRelease(false);
      }
    }
    // if (state === "play") {
    //   onPlay(false);
    // } else {
    //   onSeekRelease(false);
    // }
  };

  const onSeekRelease = (retry: boolean) => {
    let div = getVideo();
    if (Release) {
      setRelease(false);
      if (div && div.video1 && existingUseClass !== "2") {
        div.video1.play();
      }
      if (div && div.video2) {
        div.video2.play();
      }
      if (div && div.video3 && existingUseClass !== "2") {
        div.video3.play();
      }
      if (div && (div.video1 || div.video2 || div.video3)) setPlay(true);
    }
    if (retry) {
      setTimeout(changSeek, 1500, seekTime);
    } else {
      setLoading(false);
    }
  };

  const changSeek = (newValue: number) => {
    let div = getVideo();
    let duration = document.getElementById("duration") as HTMLElement;
    if (play) {
      if (div && div.video1 && existingUseClass !== "2") div.video1.pause();
      if (div && div.video2) div.video2.pause();
      if (div && div.video3 && existingUseClass !== "2") div.video3.pause();
    }
    if (div && div.video1 && existingUseClass !== "2")
      div.video1.currentTime = Number(newValue);
    if (div && div.video2) div.video2.currentTime = Number(newValue);
    if (div && div.video3 && existingUseClass !== "2")
      div.video3.currentTime = Number(newValue);
    duration.innerHTML = getTimeFormat(Number(newValue));
    setSeek(newValue);
    if (play) {
      if (div && div.video1 && existingUseClass !== "2") div.video1.play();
      if (div && div.video2) div.video2.play();
      if (div && div.video3 && existingUseClass !== "2") div.video3.play();
    }
    setLoading(false);
  };

  const onReplay5min = () => {
    let div = getVideo();
    setLeftMenu(282);
    exitFullScreen();
    setAllLeftWidth(false);
    let newTime = Number(div.video2.currentTime) - 5;
    // changSeek(newTime > 0 ? newTime : 0);
    newTime = newTime > 0 ? newTime : 0;
    if (div && div.video1 && existingUseClass !== "2")
      div.video1.currentTime = Number(newTime);
    if (div && div.video2) div.video2.currentTime = Number(newTime);
    if (div && div.video3 && existingUseClass !== "2")
      div.video3.currentTime = Number(newTime);
    let duration = document.getElementById("duration") as HTMLElement;
    if (duration) duration.innerHTML = getTimeFormat(Number(newTime));
    setSeek(newTime);
  };

  const onForward5min = () => {
    let div = getVideo();
    setLeftMenu(282);
    exitFullScreen();
    setAllLeftWidth(false);
    let newTime = Number(div.video2.currentTime) + 5;
    // changSeek(newTime);
    if (div && div.video1 && existingUseClass !== "2")
      div.video1.currentTime = Number(newTime);
    if (div && div.video2) div.video2.currentTime = Number(newTime);
    if (div && div.video3 && existingUseClass !== "2")
      div.video3.currentTime = Number(newTime);
    let duration = document.getElementById("duration") as HTMLElement;
    if (duration) duration.innerHTML = getTimeFormat(Number(newTime));
    setSeek(newTime);
  };

  const getTimeFormat = (sec: number) => {
    sec = Math.floor(sec);
    var min = Math.floor(Number(sec) / 60);
    var second = Number(sec) - min * 60;
    var hour = Math.floor(min / 60);
    min = min - hour * 60;
    return (
      ("00" + hour).slice(-2) +
      ":" +
      ("00" + min).slice(-2) +
      ":" +
      ("00" + second).slice(-2)
    );
  };

  const onTimeupdate = async () => {
    if (refPlay.current) {
      var div = getVideo();
      var duration = document.getElementById("duration");
      if (div && div.video2 && div.video2.currentTime)
        setSeek(div.video2.currentTime);
      if (duration) duration.innerHTML = getTimeFormat(div.video2.currentTime);
      if (duration && existingUseClass !== "2") {
        let key = Math.floor(Number(div.video2.currentTime));
        if (operationLog[key]) {
          switch (operationLog[key].operation_class) {
            case "2": //チャット非表示
              setChatDisp(false);
              break;
            case "3": //チャット表示
              setChatDisp(true);
              setTimeout(() => {
                const msgInsert = document.getElementsByClassName("msg-insert");
                if (msgInsert) {
                  const clist = document.getElementsByClassName("chat-box");
                  if (clist && clist.length > 0) {
                    clist[0].scrollTop = clist[0].scrollHeight;
                    clist[1].scrollTop = clist[1].scrollHeight;
                  }
                }
              }, 500);

              break;
            case "4": //択一問題表示
              if (operationLog[key].choice_id) {
                let qlist = [];
                let choiceData = operationLog[key].choice_id;
                for (let index = 1; index < 11; index++) {
                  if (
                    choiceData["answer_" + index] &&
                    choiceData["answer_" + index] !== ""
                  )
                    qlist.push({
                      answer_No: index,
                      answer: choiceData["answer_" + index],
                    });
                }
                quizOptions = qlist;
                setQuizDialog(true);
                setQuestionResult(true);
              }
              break;
            case "5": //映像最大化
              setLeftMenu(282);
              exitFullScreen();
              setAllLeftDiv(true);
              break;
            case "6": //映像元に戻す
              setAllLeftDiv(false);
              break;
            case "7": //ボード最大化
              setAllLeftWidth(false);
              setLeftMenu(0);
              requestFullScreen();
              break;
            case "8": //ボード元に戻す
              setLeftMenu(282);
              exitFullScreen();
              break;
            case "A": //択一問題表示を閉じる
              setQuizDialog(false);
              setQuestionResult(null);
              break;
            default:
              break;
          }
        }
      }
      const nowdate = new Date();
      if (nowdate.getTime() - getdate.getTime() > limitTime) {
        seekTime = Number(div.video2.currentTime);
        setPlay(false);
        if (div && div.video1 && existingUseClass !== "2") div.video1.pause();
        if (div && div.video2) div.video2.pause();
        if (div && div.video3 && existingUseClass !== "2") div.video3.pause();
        await refUrl(nowdate, "now");
      }
    }
  };

  const chengeScale = (event: any) => {
    let image_list: any = document.getElementById("image_list") as HTMLElement;
    let select = Math.floor(
      Number(event.nativeEvent.offsetX) /
        (image_list.clientWidth / image_list.children.length)
    );
    for (let index = 0; index < image_list.children.length; index++) {
      const element = image_list.children[index];
      if (index === select) {
        if (element.children && element.children[0])
          element.children[0].style.transform = "scale(1.2,1.2)";
      } else {
        if (element.children && element.children[0])
          element.children[0].style.transform = "scale(1.0,1.0)";
      }
    }
  };

  const scaleReset = (event: any) => {
    let image_list: any = document.getElementById("image_list") as HTMLElement;
    for (let index = 0; index < image_list.children.length; index++) {
      const element = image_list.children[index];
      if (element.children && element.children[0]) {
        element.children[0].style.transform = "scale(1.0,1.0)";
      }
    }
  };

  const onPlay = (retry: boolean) => {
    var div = getVideo();
    if (div && div.video1 && existingUseClass !== "2") {
      div.video1.play();
    }
    if (div && div.video2) {
      div.video2.play();
    }
    if (div && div.video3 && existingUseClass !== "2") {
      div.video3.play();
    }
    if (div && (div.video1 || div.video2 || div.video2)) setPlay(true);
    if (retry) {
      setTimeout(changSeek, 1000, seekTime);
    } else {
      setLoading(false);
    }
  };

  const onPause = () => {
    var div = getVideo();
    if (div && div.video1 && existingUseClass !== "2") div.video1.pause();
    if (div && div.video2) div.video2.pause();
    if (div && div.video3 && existingUseClass !== "2") div.video3.pause();
    if (div && (div.video1 || div.video2)) setPlay(false);
  };

  const requestFullScreen = () => {
    screenIsSmall = true;
    left_table = 0;
    setLeftMenu(0);
    //winResize();
    var docEl: any = document.documentElement;
    var requestFullScreen =
      docEl.requestFullscreen ||
      docEl.mozRequestFullScreen ||
      docEl.webkitRequestFullscreen ||
      docEl.msRequestFullscreen;
    var doc: any = window.document;
    var check = true;
    if (
      (doc.fullscreenElement !== undefined && doc.fullscreenElement !== null) ||
      (doc.mozFullScreenElement !== undefined &&
        doc.mozFullScreenElement !== null) ||
      (doc.webkitFullscreenElement !== undefined &&
        doc.webkitFullscreenElement !== null) ||
      (doc.webkitCurrentFullScreenElement !== undefined &&
        doc.webkitCurrentFullScreenElement !== null) ||
      (doc.msFullscreenElement !== undefined &&
        doc.msFullscreenElement !== null)
    )
      check = false;
    if (check && requestFullScreen) requestFullScreen.call(docEl);
    setTimeout(() => winResize(), 100);
  };

  const exitFullScreen = () => {
    var video1: any = document.getElementById("video1");
    var video3: any = document.getElementById("video3");
    if (video1.textTracks[0]) {
      video1.textTracks[0].mode = "hidden";
    }
    if (video3.textTracks[0]) {
      video3.textTracks[0].mode = "hidden";
    }
    screenIsSmall = false;
    left_table = 308;
    setLeftMenu(282);
    var doc: any = window.document;
    if (
      (doc.fullscreenElement !== undefined && doc.fullscreenElement !== null) ||
      (doc.mozFullScreenElement !== undefined &&
        doc.mozFullScreenElement !== null) ||
      (doc.webkitFullscreenElement !== undefined &&
        doc.webkitFullscreenElement !== null) ||
      (doc.webkitCurrentFullScreenElement !== undefined &&
        doc.webkitCurrentFullScreenElement !== null) ||
      (doc.msFullscreenElement !== undefined &&
        doc.msFullscreenElement !== null)
    ) {
      var cancelFullScreen =
        doc.exitFullscreen ||
        doc.mozCancelFullScreen ||
        doc.webkitExitFullscreen ||
        doc.msExitFullscreen;
      cancelFullScreen.call(doc);
    }
    setTimeout(() => winResize(), 500);
  };

  //スピーカー音量
  const [speaker, setSpeaker] = React.useState(null);

  const speakerOpen = (event: any) => {
    // if (screenIsSmall) exitFullScreen();
    setExpansion(null);
    setName(null);
    setSpeaker(event.currentTarget);
  };

  const speakerClose = () => {
    setSpeaker(null);
  };

  const openSpeaker = Boolean(speaker);

  const speakerId = openSpeaker ? "speaker-popover" : undefined;

  const [volumeValue, setVolumeValue] = React.useState<number>(100);

  const changeVolume = (event: any, newValue: number | number[]) => {
    var div = getVideo();
    if (div && div.video1) div.video1.volume = (newValue as number) / 100;
    if (div && div.video2) div.video2.volume = (newValue as number) / 100;
    setVolumeValue(newValue as number);
  };

  const getDateTime = () => {
    let nt = new Date();
    return (
      nt.getFullYear() +
      "/" +
      ("00" + (nt.getMonth() + 1)).slice(-2) +
      "/" +
      ("00" + nt.getDate()).slice(-2) +
      " " +
      ("00" + nt.getHours()).slice(-2) +
      ":" +
      ("00" + nt.getMinutes()).slice(-2) +
      ":" +
      ("00" + nt.getSeconds()).slice(-2)
    );
  };

  const registViewLog = async (sec: number) => {
    if (captureFlg) {
      return true;
    } else {
      var saveTime = Math.ceil(sec / 60);
      console.log(
        "time:" +
          new Date().toLocaleTimeString() +
          "/sec:" +
          sec +
          "/saveLogTime:" +
          saveLogTime +
          "/saveTime:" +
          saveTime
      );
      if (!refPlay.current || Math.ceil(saveLogTime / 60) !== saveTime) {
        console.log("save ok" + Math.ceil(saveLogTime / 60) + "/" + saveTime);
        saveLogTime = sec;
        return await ST0311(
          dispatch,
          classId,
          saveTime,
          getDateTime(),
          "1",
          "1",
          "",
          process.env.REACT_APP_AGORA_REC_FLG === "1" ||
            process.env.REACT_APP_AGORA_REC_FLG === "2"
            ? "1"
            : "2"
        );
      } else {
        return true;
      }
    }
  };

  const removeEventListener = (callback: any) => {
    let div = getVideo();
    div.video2.removeEventListener("timeupdate", onTimeupdate);
    clearInterval(recordInterval);
    window.removeEventListener("resize", registeredListener);
    window.removeEventListener("touchstart", checkTouchEvent);
    window.removeEventListener("popstate", popState);
    if (callback) callback();
  };

  const getOut = () => {
    /*localStorage.removeItem("playclass");
    localStorage.removeItem("ClassClose");
    localStorage.removeItem("Cls013_session");*/
    try {
      exitFullScreen();
      removeEventListener(() => {
        //window.open("about:blank", "_self")?.close();
        dispatch(push("/top/002"));
      });
    } catch (e: any) {
      console.log("getout Err", e);
      //window.open("about:blank", "_self")?.close();
      dispatch(push("/top/002"));
    }
  };

  const getDateFormat = (dt: Date) => {
    let y = dt.getFullYear();
    let m = ("00" + (dt.getMonth() + 1)).slice(-2);
    let d = ("00" + dt.getDate()).slice(-2);
    let h = ("00" + dt.getHours()).slice(-2);
    let i = ("00" + dt.getMinutes()).slice(-2);
    return y + m + d + h + i;
  };

  const saveVideoData = () => {
    captureFlg = true;
    setTimeout(() => {
      var video: any = document.getElementById("video2");
      if (!canvasDraw)
        canvasDraw = document.getElementById("draw") as HTMLCanvasElement;
      canvasDraw.width = video.videoWidth;
      canvasDraw.height = video.videoHeight;
      canvasDraw
        .getContext("2d")
        .drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
      let link = document.createElement("a");
      link.href = canvasDraw.toDataURL("image/jpeg");
      let nowDate = getDateFormat(new Date());
      link.download = classId + "_" + nowDate + ".jpg";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      captureFlg = false;
    }, 2000);
  };

  const [DialogFlg, setDialogFlg] = React.useState(false);

  const DialoghandleOpen = () => {
    // if (screenIsSmall) exitFullScreen();
    setDialogFlg(true);
    let div = getVideo();
    if (play) {
      setRelease(true);
      if (div && (div.video1 || div.video2 || div.video3)) setPlay(false);
      if (div && div.video1 && existingUseClass !== "2") div.video1.pause();
      if (div && div.video2) div.video2.pause();
      if (div && div.video3 && existingUseClass !== "2") div.video3.pause();
    }

    imgSelect = Math.floor(Number(seek) / 60);
    setTimeout(() => {
      let selected = Math.floor(Number(seek) / 60);
      let image_list: any = document.getElementById(
        "image_list"
      ) as HTMLElement;
      for (let index = 0; index < image_list.children.length; index++) {
        const element = image_list.children[index];
        if (index === selected) {
          element.style.border = "1px solid";
        } else {
          element.style.border = "";
        }
      }
      if (maxNum === 0) {
        let dkeys = Object.keys(chatBoxData);
        if (dkeys && dkeys.length > 0) {
          for (let index = 0; index < dkeys.length; index++) {
            const id = dkeys[index];
            if (id !== "tmp") {
              let newMax =
                Number(chatBoxData[id].reaction1) +
                Number(chatBoxData[id].reaction2) +
                Number(chatBoxData[id].reaction3);
              if (newMax > maxNum) maxNum = newMax;
            }
          }
        }
      }
      let imageNum = Object.keys(imgListData).length;

      let x = 120;
      let y = image_list.clientWidth / image_list.children.length;
      let canvas: any = document.querySelector("#canvas") as any;
      let context = canvas.getContext("2d");
      canvas.width = y * imageNum;
      canvas.height = x;

      context.beginPath();
      context.fillStyle = "rgb( 0, 0, 0, 0)";
      context.fillRect(0, 0, canvas.width, canvas.height);
      context.beginPath();
      context.strokeStyle = "red";
      context.moveTo(0, x);
      for (let i = 1; i <= imageNum; i++) {
        let nowN =
          chatBoxData && chatBoxData[i - 1] && chatBoxData[i - 1].reaction2 > 0
            ? chatBoxData[i - 1].reaction2
            : 0;
        let c = Math.floor((nowN / maxNum) * 100) / 100;
        context.lineTo(y * i - y / 2, x - Math.floor(x * c));
      }
      context.lineTo(y * imageNum, x);
      context.stroke();

      context.beginPath();
      context.strokeStyle = "green";
      context.moveTo(0, x);
      for (let i = 1; i <= imageNum; i++) {
        let nowN =
          chatBoxData && chatBoxData[i - 1] && chatBoxData[i - 1].reaction1 > 0
            ? chatBoxData[i - 1].reaction1
            : 0;
        let c = Math.floor((nowN / maxNum) * 100) / 100;
        context.lineTo(y * i - y / 2, x - Math.floor(x * c));
      }
      context.lineTo(y * imageNum, x);
      context.stroke();

      context.beginPath();
      context.strokeStyle = "yellow";
      context.moveTo(0, x);
      for (let i = 1; i <= imageNum; i++) {
        let nowN =
          chatBoxData && chatBoxData[i - 1] && chatBoxData[i - 1].reaction3 > 0
            ? chatBoxData[i - 1].reaction3
            : 0;
        let c = Math.floor((nowN / maxNum) * 100) / 100;
        context.lineTo(y * i - y / 2, x - Math.floor(x * c));
      }
      context.lineTo(y * imageNum, x);
      context.stroke();
      setTimeout(() => {
        let chatListBox: any = document.getElementById("chatListBox");
        chatListBox.scrollLeft = 213 * Math.floor(Number(seek) / 60);
      }, 1000);
    }, 200);
  };

  const DialoghandleDone = () => {
    let div = getVideo();
    setLeftMenu(282);
    exitFullScreen();
    setAllLeftWidth(false);
    let selected = Math.floor(Number(seek) / 60);
    if (imgSelect !== selected) {
      let newValue = imgSelect * 60;
      let duration = document.getElementById("duration") as HTMLElement;
      if (div && div.video1 && existingUseClass !== "2")
        div.video1.currentTime = Number(newValue);
      if (div && div.video2) div.video2.currentTime = Number(newValue);
      if (div && div.video3 && existingUseClass !== "2")
        div.video3.currentTime = Number(newValue);
      duration.innerHTML = getTimeFormat(Number(newValue));
      setSeek(newValue);
    }
    if (Release) {
      setRelease(false);
      if (div && (div.video1 || div.video2 || div.video3)) setPlay(true);
      if (div && div.video1 && existingUseClass !== "2") div.video1.play();
      if (div && div.video2) div.video2.play();
      if (div && div.video3 && existingUseClass !== "2") div.video3.play();
    }
    setDialogFlg(false);
  };

  const DialoghandleClose = () => {
    if (Release) {
      let div = getVideo();
      setRelease(false);
      if (div && (div.video1 || div.video2 || div.video3)) setPlay(true);
      if (div && div.video1 && existingUseClass !== "2") div.video1.play();
      if (div && div.video2) div.video2.play();
      if (div && div.video3 && existingUseClass !== "2") div.video3.play();
    }
    setDialogFlg(false);
  };

  const changeImageList = (e: any) => {
    let image_list: any = document.getElementById("image_list") as HTMLElement;
    imgSelect = Math.floor(
      Number(e.nativeEvent.offsetX) /
        (image_list.clientWidth / image_list.children.length)
    );
    for (let index = 0; index < image_list.children.length; index++) {
      const element = image_list.children[index];
      if (index === imgSelect) {
        element.style.border = "1px solid";
        if (element.children && element.children[0])
          element.children[0].style.transform = "scale(1.2,1.2)";
      } else {
        element.style.border = "";
        if (element.children && element.children[0])
          element.children[0].style.transform = "scale(1.0,1.0)";
      }
    }
  };

  //palybar display(デザイン対応用)
  const [leftMenu, setLeftMenu] = React.useState(282);
  const [playbarD, setPlaybarD] = React.useState(false);
  const palybaronoff = () => {
    if (playbarDis) {
      const vid: any = document.getElementById("video2");
      if (!vid.paused) {
        playbarDis = false;
        setPlaybarD(false);
      }
    } else {
      playbarDis = true;
      setPlaybarD(true);
    }
  };

  //再生スピード
  const [speedValue, setSpeedValue] = React.useState("1");
  const [expansion, setExpansion] = React.useState(null);
  const speedHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    var div = getVideo();
    let value = (event.target as HTMLInputElement).value;
    if (div && div.video1 && existingUseClass !== "2")
      div.video1.playbackRate = Number(value);
    if (div && div.video1 && existingUseClass !== "2")
      div.video1.defaultPlaybackRate = Number(value);
    if (div && div.video2) div.video2.playbackRate = Number(value);
    if (div && div.video2) div.video2.defaultPlaybackRate = Number(value);
    if (div && div.video3 && existingUseClass !== "2")
      div.video3.playbackRate = Number(value);
    if (div && div.video3 && existingUseClass !== "2")
      div.video3.defaultPlaybackRate = Number(value);
    setSpeedValue(value);
    setExpansion(null);
    setExpansion(null);
  };
  const expansionClick = (event: any) => {
    // if (screenIsSmall) exitFullScreen();
    setSpeaker(null);
    setName(null);
    if (expansion) {
      setExpansion(null);
    } else {
      setExpansion(event.currentTarget);
    }
    stayFlg = true;
  };

  const expansionClose = () => {
    setExpansion(null);
  };

  const expansionOpen = Boolean(expansion);

  const [name, setName] = React.useState(null);
  const [nameOpen, setNameOpen] = React.useState(false);
  const nameClick = (event: any) => {
    // if (screenIsSmall) exitFullScreen();
    setExpansion(null);
    setSpeaker(null);
    if (name) {
      setName(null);
      setNameOpen(false);
    } else {
      setName(event.currentTarget);
      setNameOpen(true);
    }
  };

  const [quizDialog, setQuizDialog] = React.useState(false);
  const [questionResult, setQuestionResult] = React.useState<any>(null);

  // const QuizOptionButton: React.FC<any> = ({ row = {} }) => {
  //   return (
  //     <Button variant="contained" onClick={() => setQuizDialog(false)}>
  //       {row.answer}
  //     </Button>
  //   );
  // };

  const setAllLeftDiv = (openFlg: boolean) => {
    var video1: any = document.getElementById("video1");
    var video3: any = document.getElementById("video3");
    if (video1.textTracks[0]) {
      if (openFlg) {
        video1.textTracks[0].mode = "showing";
      } else {
        video1.textTracks[0].mode = "hidden";
      }
    }
    if (video3.textTracks[0]) {
      if (openFlg) {
        video3.textTracks[0].mode = "showing";
      } else {
        video3.textTracks[0].mode = "hidden";
      }
    }
    setAllLeftWidth(openFlg);
    setTimeout(() => winResize(), 100);
  };

  const getLeftWidth = () => {
    let window_H = document.getElementsByTagName("body")[0].offsetHeight;
    let window_W = document.getElementsByTagName("body")[0].offsetWidth;
    if (window_H > window_W) {
      return window_W - 11 + "px";
    } else {
      let videoW = window_W;
      let nw = Math.ceil((window_H - 10) * (16 / 9));
      if (nw > videoW) {
        nw = videoW;
      }
      return nw + "px";
    }
  };

  const getChat = async (id: any) => {
    try {
      const readAsData = (file: any) => {
        return new Promise((resolve, reject) => {
          let reader = new FileReader();
          reader.onload = () => {
            resolve(reader.result);
          };
          reader.onerror = () => {
            reject(reader.error);
          };
          reader.readAsText(file);
        });
      };
      if (s3) {
        let tmpPath =
          "haishin/" +
          serverId +
          "/" +
          classId +
          "/chatMessage/chat_" +
          id +
          ".json";
        let fileData = await getFileS3(s3, tmpPath);
        if (fileData) {
          let jsonData: any = await readAsData(fileData);
          let messageJson = JSON.parse(jsonData);
          if (messageJson) {
            return messageJson;
          }
        }
      }
      return null;
    } catch (error) {
      return null;
    }
  };

  return (
    <div className={classes.root}>
      <div className="display_bar" style={{ display: dispb ? "flex" : "none" }}>
        <div style={{ margin: "auto" }}>
          {disabled ? (
            <div>
              <CircularProgress
                style={{ fontSize: "100px", color: "rgb(0 0 0 / 67%)" }}
              />
            </div>
          ) : (
            <IconButton
              className="buttonLarge"
              onClick={() => {
                TokenCheck("play");
                setTimeout(() => {
                  var video1: any = document.getElementById("video1");
                  var video3: any = document.getElementById("video3");
                  if (video1.textTracks[0]) {
                    video1.textTracks[0].mode = "hidden";
                  }
                  if (video3.textTracks[0]) {
                    video3.textTracks[0].mode = "hidden";
                  }
                }, 500);
                dispb = false;
                let count = 0;
                registViewLog(1);
                recordInterval = setInterval(() => {
                  try {
                    let path = store.getState().router.location.pathname;
                    if (path !== "/cls/013") {
                      clearInterval(recordInterval);
                    }
                    count++;
                    let div = getVideo();
                    if (div && div.video2 && div.video2.currentTime) {
                      if (
                        (div.video2.playbackRate <= 1 || !refPlay.current) &&
                        count % 2 === 0
                      ) {
                        registViewLog(Math.ceil(div.video2.currentTime));
                      }
                      if (div.video2.playbackRate > 1 && refPlay.current) {
                        registViewLog(Math.ceil(div.video2.currentTime));
                      }
                    }
                    if (count % 60 === 0) {
                      getSessionUser().then((result) => {
                        if (result === false) {
                          clearInterval(recordInterval);
                          dispatch(signOut());
                        }
                      });
                    }
                  } catch (error) {
                    clearInterval(recordInterval);
                  }
                }, 30000);
                let chatInterval = setInterval(() => {
                  let path = store.getState().router.location.pathname;
                  if (path !== "/cls/013") {
                    clearInterval(chatInterval);
                  }
                  if (
                    seekTimecheck >= 0 &&
                    seekTimecheck !== chatListNo &&
                    !chatChangeFlg
                  ) {
                    chatChangeFlg = true;
                    chatDataAdd(seekTimecheck);
                  }
                }, 200);
              }}
            >
              <PlayArrowIcon
                style={{ fontSize: "100px", color: "rgb(0 0 0 / 67%)" }}
              />
            </IconButton>
          )}
        </div>
      </div>
      <div
        className="hiddleMenu"
        style={{
          position: "absolute",
          left: 5,
          right: 5,
          bottom: 0,
          border: "1px solid #ccc",
          borderRadius: "5px",
          backgroundColor: "rgb(0 0 0 / 50%)",
          paddingLeft: "10px",
          display: "flex",
          zIndex: 999,
          color: "white",
          opacity: playbarD || !play ? 1 : 0,
        }}
        onMouseOver={() => {
          if (PCflg) {
            setPlaybarD(true);
          } else {
            setTimeout(setPlaybarD, 200, true);
          }
        }}
        onMouseOut={() => {
          if (PCflg) {
            const vid: any = document.getElementById("video2");
            if (!vid.paused) setPlaybarD(false);
          }
        }}
      >
        <div className="play-bar">
          <div className="control-left">
            <IconButton
              aria-label=""
              className="fontSize14"
              onClick={() => {
                if (playbarD) {
                  !play ? TokenCheck("play") : onPause();
                }
              }}
              style={{
                color: "rgb( 255 255 255/65%)",
                fontSize: "18px",
              }}
            >
              {!play ? <PlayArrowIcon /> : <PauseIcon />}
            </IconButton>
            <IconButton
              className="buttonLarge"
              onClick={(e: any) => {
                if (playbarD) {
                  let ua: any = window.navigator;
                  if (
                    ua.userAgent.toLowerCase().match(/iphone|android|mobile/) ||
                    ua.platform === "iPad" ||
                    (ua.platform === "MacIntel" &&
                      ua.userAgent.indexOf("Safari") !== -1 &&
                      ua.userAgent.indexOf("Chrome") === -1 &&
                      ua.standalone !== undefined)
                  ) {
                    changeVolume(e, volumeValue ? 0 : 100);
                    var div = getVideo();
                    if (div && div.video1)
                      div.video1.muted = volumeValue ? true : false;
                    if (div && div.video2)
                      div.video2.muted = volumeValue ? true : false;
                  } else {
                    speakerOpen(e);
                  }
                }
              }}
              style={{
                color: "rgb( 255 255 255/65%)",
                fontSize: "30px",
              }}
            >
              {volumeValue === 0 ? <VolumeOffIcon /> : <VolumeUpIcon />}
            </IconButton>
            <Popover
              id={speakerId}
              open={openSpeaker}
              anchorEl={speaker}
              onClose={speakerClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
            >
              <div className="speaker_div" style={{ height: 120 }}>
                <Slider
                  orientation="vertical"
                  value={volumeValue}
                  aria-labelledby="vertical-slider"
                  onChange={changeVolume}
                />
              </div>
            </Popover>
            <IconButton
              className="buttonLarge"
              onClick={() => {
                if (playbarD) {
                  onReplay5min();
                }
              }}
              style={{
                color: "rgb( 255 255 255/65%)",
                fontSize: "30px",
              }}
            >
              <Replay5Icon />
            </IconButton>
            <IconButton
              className="buttonLarge"
              onClick={() => {
                if (playbarD) {
                  onForward5min();
                }
              }}
              style={{
                color: "rgb( 255 255 255/65%)",
                fontSize: "30px",
              }}
            >
              <Forward5Icon />
            </IconButton>
          </div>
          <div className="control-middle">
            <Slider
              aria-label="custom thumb label"
              onChange={(e: any, n: any) => {
                if (playbarD) {
                  onSeek(e, n);
                } else {
                  if (PCflg) {
                    setPlaybarD(true);
                  } else {
                    setTimeout(setPlaybarD, 200, true);
                  }
                }
              }}
              onChangeCommitted={() => {
                TokenCheck("seek");
                seekFlg = false;
                // let now = Math.floor(Number(seek) / 60);
                // if (now >= 0 && now !== chatListNo && !chatChangeFlg) {
                //   chatChangeFlg = true;
                //   chatDataAdd(now);
                // }
              }}
              value={seek}
              max={maxSeek}
            />
            <div style={{ padding: "0 0 0 15px" }}>
              <span id="duration">00:00</span>/<span id="totalTime">00:00</span>
            </div>
          </div>
          <div className="control-right">
            <IconButton aria-label="">
              <SpeedIcon
                style={{
                  color: "rgb( 255 255 255/65%)",
                }}
                onClick={(e: any) => {
                  if (playbarD) {
                    expansionClick(e);
                  }
                }}
              />
            </IconButton>
            <Popover
              open={expansionOpen}
              anchorEl={expansion}
              onClose={expansionClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              style={{
                zIndex: 1000,
              }}
            >
              <div className="speed_div">
                <RadioGroup
                  aria-label="gender"
                  name="gender1"
                  value={speedValue}
                  onChange={speedHandleChange}
                >
                  <FormControlLabel
                    value="1"
                    control={<Radio size="small" />}
                    label="x1.0"
                  />
                  <FormControlLabel
                    value="1.2"
                    control={<Radio size="small" />}
                    label="x1.2"
                  />
                  <FormControlLabel
                    value="1.5"
                    control={<Radio size="small" />}
                    label="x1.5"
                  />
                  <FormControlLabel
                    value="2"
                    control={<Radio size="small" />}
                    label="x2.0"
                  />
                </RadioGroup>
              </div>
            </Popover>
            <IconButton
              id="chapterListBtn"
              aria-label=""
              onClick={() => {
                if (playbarD) {
                  DialoghandleOpen();
                }
              }}
              style={{
                color: "rgb( 255 255 255/65%)",
                fontSize: "28px",
              }}
            >
              <ListAltIcon />
            </IconButton>
            <IconButton
              aria-label=""
              style={{
                color: "rgb( 255 255 255/65%)",
                fontSize: "20px",
              }}
              onClick={() => {
                if (playbarD) {
                  saveVideoData();
                }
              }}
            >
              <CloudDownloadIcon />
            </IconButton>
          </div>
        </div>
      </div>

      <IconButton
        aria-label="delete"
        onClick={nameClick}
        style={{
          position: "absolute",
          top: 5,
          left: 10,
          zIndex: 999999,
          backgroundColor: "rgb(0 0 0 / 26%)",
        }}
      >
        <BiMenu
          style={{
            fontSize: "30px",
            color: "#fff",
          }}
        />
      </IconButton>
      <Menu
        anchorEl={name}
        keepMounted
        open={nameOpen}
        onClose={nameClick}
        style={{ zIndex: 999999 }}
      >
        <MenuItem button={false}>{className}</MenuItem>
        <MenuItem onClick={getOut}>
          <ExitToAppIcon />
          {FormatMessage({ id: "Cls013.Button.Text1" })}
        </MenuItem>
        <MenuItem
          onClick={() => {
            window.location.reload();
          }}
        >
          <ReplayIcon />
          {FormatMessage({
            id: "Cls012.Button4",
          })}
        </MenuItem>
        <MenuItem button={false}>
          <img src={educastLogo} height="24" />
        </MenuItem>
      </Menu>

      <div
        className={allWidth ? "allWidthLeftMenuOpen" : "leftMenuOpen"}
        style={{
          right: allWidth ? -20 : leftMenu,
          display: allLefeWidth || existingUseClass === "2" ? "none" : "block",
          top: wbHeight / 2 - 10,
          zIndex: DialogFlg || quizDialog ? 1 : 999999,
        }}
      >
        <IconButton
          aria-label="delete"
          className="buttonLarge"
          onClick={() => {
            if (leftMenu < 282) {
              exitFullScreen();
            } else {
              requestFullScreen();
            }
          }}
        >
          {screenIsSmall ? (
            <KeyboardArrowLeftRoundedIcon />
          ) : (
            <KeyboardArrowRightRoundedIcon />
          )}
        </IconButton>
      </div>
      <Popover
        hideBackdrop={true}
        classes={{
          paper: classes.popoverContent,
        }}
        open={quizDialog}
        anchorEl={questionResult}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        style={{ zIndex: 999 }}
      >
        <div className="quizOption_content" style={{ padding: "20px 8px" }}>
          {quizOptions.length > 0
            ? quizOptions.map((rows) => (
                <div
                  key={rows.answer_No}
                  className={"quizOption_div answer_" + rows.answer_No}
                >
                  <Button
                    variant="contained"
                    onClick={() => setQuizDialog(false)}
                    style={{ textTransform: "none" }}
                  >
                    {rows.answer}
                  </Button>
                </div>
              ))
            : null}
        </div>
      </Popover>
      <table width="100%">
        <tbody>
          <tr>
            <td
              className="tableRight"
              style={{
                width: "100%",
                display: allLefeWidth ? "none" : "table-cell",
              }}
            >
              <div className="whiteBoard">
                <VideoVtt
                  existingUseClass={existingUseClass}
                  video2={video2}
                  vttSrc={vttSrc}
                />
              </div>
              <canvas id="draw" style={{ display: "none" }}></canvas>
            </td>
            <td
              className={screenIsSmall ? "all_left_table" : "tableLeft"}
              style={{
                display:
                  allWidth || screenIsSmall || existingUseClass === "2"
                    ? "none"
                    : "block",
                borderRight: "none",
                borderLeft: "1px solid #CCC",
                width: allLefeWidth ? "auto" : "300px",
              }}
            >
              <div className="leftTable">
                <div
                  className={
                    screenIsSmall
                      ? "all_video_left video_view_1"
                      : "streamer_bar video_view_1"
                  }
                  style={{
                    margin: "0 auto",
                    width: allLefeWidth ? getLeftWidth() : "auto",
                  }}
                >
                  <IconButton
                    aria-label="delete"
                    style={{
                      position: "absolute",
                      top: 5,
                      right: 5,
                      zIndex: 99,
                      backgroundColor: "rgb(0 0 0 / 26%)",
                    }}
                    onClick={() => setAllLeftDiv(!allLefeWidth)}
                  >
                    <BsArrowsFullscreen
                      style={{
                        fontSize: "20px",
                        color: "rgb(255 255 255 / 50%)",
                      }}
                    />
                  </IconButton>
                  <div>
                    <video
                      id="video1"
                      playsInline
                      src={video1}
                      width="100%"
                      height="100%"
                      crossOrigin="anonymous"
                    >
                      {vttSrc && vttSrc !== "" ? (
                        <track src={vttSrc} default></track>
                      ) : null}
                    </video>
                  </div>
                </div>
                <div
                  className={screenIsSmall ? "nodisplay" : "wrapper"}
                  style={{
                    display: allLefeWidth ? "none" : "block",
                  }}
                >
                  <div
                    onScroll={(e: any) => {
                      let nowS = e.target.scrollTop;
                      let maxS = e.target.scrollHeight - e.target.offsetHeight;
                      if (nowS === maxS) {
                      }
                    }}
                    className="chat-box chat_div_1"
                  >
                    <div className="chat-body">
                      <div
                        className="msg-insert"
                        style={{ display: chatDisp ? "flex" : "none" }}
                      >
                        {chatListData.map((chat: any, index: number) =>
                          !chat.user_id &&
                          String(chat.html).indexOf(
                            '<span style="color: red;">'
                          ) < 0 &&
                          chat.nowSeconds < seek ? (
                            <div
                              key={"msg-2sends-" + index}
                              className="msg-sends"
                              style={{ whiteSpace: "pre-wrap" }}
                              dangerouslySetInnerHTML={{
                                __html: chat.html,
                              }}
                            ></div>
                          ) : null
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
          <tr
            style={{
              display: allWidth && existingUseClass !== "2" ? "block" : "none",
            }}
          >
            <td className={screenIsSmall ? "nodisplay" : "small-table-left"}>
              <div>
                <div className="streamer_bar video_view_2">
                  <div
                    style={{
                      textAlign: "right",
                      position: "relative",
                      top: "50px",
                      right: "10px",
                      marginTop: "-44px",
                    }}
                  >
                    <IconButton
                      aria-label="delete"
                      style={{
                        zIndex: 99,
                        backgroundColor: "rgb(0 0 0 / 26%)",
                      }}
                      onClick={() => setAllLeftDiv(!allLefeWidth)}
                    >
                      <BsArrowsFullscreen
                        style={{
                          fontSize: "20px",
                          color: "rgb(255 255 255 / 50%)",
                        }}
                      />
                    </IconButton>
                  </div>
                  <div>
                    <video
                      id="video3"
                      playsInline={true}
                      muted={true}
                      src={video1}
                      width="100%"
                      height="100%"
                      crossOrigin="anonymous"
                    >
                      {vttSrc && vttSrc !== "" ? (
                        <track src={vttSrc} default></track>
                      ) : null}
                    </video>
                  </div>
                </div>
                <div
                  className="wrapper"
                  style={{
                    display: allLefeWidth ? "none" : "block",
                  }}
                >
                  <div className="chat-box chat_div_2">
                    <div className="chat-body">
                      <div
                        className="msg-insert"
                        style={{ display: chatDisp ? "flex" : "none" }}
                      >
                        {chatListData.map((chat: any, index: number) =>
                          !chat.user_id &&
                          String(chat.html).indexOf(
                            '<span style="color: red;">'
                          ) < 0 &&
                          chat.nowSeconds < seek ? (
                            <div
                              key={"msg-1sends-" + index}
                              className="msg-sends"
                              style={{ whiteSpace: "pre-wrap" }}
                              dangerouslySetInnerHTML={{
                                __html: chat.html,
                              }}
                            ></div>
                          ) : null
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      {loading ? <LoadingBox /> : null}
      <Dialog
        open={DialogFlg}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent id="chatListBox">
          <table>
            <tr>
              <td>
                <div
                  id="image_list"
                  style={{
                    display: "inline-flex",
                    height: "120px",
                    marginRight: "22px",
                  }}
                >
                  {Object.keys(imgListData).map((image: any, index: number) => (
                    <div key={"imgList_" + index}>
                      <img
                        key={index + 1}
                        src={imgListData[image]}
                        id={image}
                      />
                    </div>
                  ))}
                </div>
                <div
                  style={{
                    position: "relative",
                    top: 0,
                    marginTop: "-125px",
                  }}
                >
                  <canvas
                    id="canvas"
                    style={{
                      cursor: "pointer",
                    }}
                    onMouseOver={chengeScale}
                    onMouseMove={chengeScale}
                    onMouseOut={scaleReset}
                    onClick={changeImageList}
                  ></canvas>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                {(() => {
                  const items = [];
                  for (let i = 0; i < Object.keys(imgListData).length; i++) {
                    var h = Math.floor(i / 60);
                    var s = i - h * 60;
                    items.push(
                      <div
                        key={"image_time_" + i}
                        style={{
                          width: "211px",
                          border: "1px solid #ccc",
                          textAlign: "center",
                        }}
                      >
                        {("0" + h).slice(-2) +
                          ":" +
                          ("0" + s).slice(-2) +
                          ":00"}
                      </div>
                    );
                  }
                  return <div style={{ display: "inline-flex" }}>{items}</div>;
                })()}
              </td>
            </tr>
            <tr>
              <td>
                {(() => {
                  const items = [];
                  for (let i = 1; i <= Object.keys(imgListData).length; i++) {
                    items.push(
                      <div
                        key={"image_div_" + (i - 1)}
                        id={"img-" + (i - 1)}
                        style={{
                          width: "211px",
                          height: "150px",
                          border: "1px solid #ccc",
                          overflowY: "scroll",
                        }}
                        onScroll={(e: any) => {
                          let nowS = e.target.scrollTop;
                          let maxS =
                            e.target.scrollHeight - e.target.offsetHeight;
                          let id = e.target.id.split("-");
                          if (id.length < 3 && nowS >= maxS) {
                            e.target.id = id[0] + "-" + id[1] + "-end";
                            getChat(id[1]).then((data: any) => {
                              if (data && data.length > 0) {
                                for (
                                  let index = 10;
                                  index < data.length;
                                  index++
                                ) {
                                  const element = data[index];
                                  if (
                                    !element.user_id &&
                                    String(element.html).indexOf(
                                      '<span style="color: red;">'
                                    ) < 0
                                  ) {
                                    let div = document.createElement("div");
                                    div.innerHTML = element.html;
                                    e.target.append(div);
                                  }
                                }
                              }
                            });
                          }
                        }}
                      >
                        {/* <div style={{ padding: "5px" }}>
                        {chatData && chatData[i - 1] && chatData[i - 1].htmlList
                          ? chatData[i - 1].htmlList
                          : null}
                      </div> */}
                        {chatBoxData &&
                        chatBoxData[i - 1] &&
                        chatBoxData[i - 1].chat_data
                          ? chatBoxData[i - 1].chat_data.map(
                              (list: any, index: any) =>
                                !list.user_id ? (
                                  <div
                                    key={"chat_text_" + index}
                                    style={{
                                      overflowWrap: "anywhere",
                                    }}
                                    dangerouslySetInnerHTML={{
                                      __html: list.html,
                                    }}
                                  ></div>
                                ) : null
                            )
                          : null}
                      </div>
                    );
                  }
                  return <div style={{ display: "inline-flex" }}>{items}</div>;
                })()}
              </td>
            </tr>
          </table>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={DialoghandleDone}
            startIcon={<PlayArrowIcon />}
            color="primary"
            style={{
              borderBlockColor: "#fff",
              border: "1px solid #ccc",
              color: "#000",
            }}
          >
            {FormatMessage({ id: "Cls013.Button.Text2" })}
          </Button>
          <Button
            onClick={DialoghandleClose}
            startIcon={<ArrowBackIcon />}
            color="primary"
            style={{
              borderBlockColor: "#fff",
              border: "1px solid #ccc",
              color: "#000",
            }}
            autoFocus
          >
            {FormatMessage({ id: "Cls013.Button.Text3" })}
          </Button>
        </DialogActions>
      </Dialog>

      {/*} <Dialog
        open={quizDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {FormatMessage({ id: "Cls013.QuizOption.Text" })}
        </DialogTitle>
        <DialogContent className="alignCenter">
          <div className="quizOption_content">
            {quizOptions.map((rows) => (
              <div
                key={rows.answer_No}
                className={"quizOption_div answer_" + rows.answer_No}
              >
                <QuizOptionButton row={rows} />
              </div>
            ))}
          </div>
        </DialogContent>
            </Dialog>*/}
    </div>
  );
};
