import { apigw } from "../awsApi";
import { Dispatch } from "@reduxjs/toolkit";

const rows = {
    status:0,
    error_code : null,
    body:null
}

/**
 * 録画ファイル変換
 * @param {Dispatch} dispatch reducerディスパッチ
 * @param {string} classId 授業ID
 * @param {string} videoTotal ビデオ合計
 * @return コンポーネントHTMLコード内容
 */
export const ST0305 = async (
  dispatch: Dispatch,
  classId:string,
  videoTotal:number,
  flg:boolean
) => {
  if (process.env.REACT_APP_API_USE_FLG === "true") {
    return apigw(
      "/ST0305",
      {
        class_id:classId,
        video_name:classId,
        video_total:videoTotal,
        safari_use:flg
      },
      dispatch
    );
  } else {
    //サンプルデータ用
    return { body: rows };
  }
};