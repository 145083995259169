import { apigw } from "../awsApi";
import { Dispatch } from "@reduxjs/toolkit";

function createData(
  user_id: string,
  user_name: string,
  handle_name: string,
  viewing_time: number,
  viewing_time_ondemand: number
) {
  return {
    user_id,
    user_name,
    handle_name,
    viewing_time,
    viewing_time_ondemand,
  };
}

/**
 * 授業視聴状況一覧取得
 * @param {Dispatch} dispatch reducerディスパッチ
 * @param {string} classId 授業ID
 * @param {string} userId 視聴ユーザID
 * @param {string} userName 視聴ユーザ名
 * @param {string} periodFrom 期間From
 * @param {string} periodTo 期間To
 * @param {string} attendanceTimeThreshold 視聴時間閾値
 * @param {string} attendance ライブ配信授業 出席
 * @param {string} partialAttendance ライブ配信授業 一部出席
 * @param {string} absence ライブ配信授業 欠席
 * @param {string} watched オンデマンド授業 視聴済
 * @param {string} partiallyWatched オンデマンド授業 一部視聴済
 * @param {string} notWatched オンデマンド授業 未視聴
 * @param {string} pageNo ページ番号
 * @return コンポーネントHTMLコード内容
 */
export const LC0303 = async (
  dispatch: Dispatch,
  classId: string, //授業ID
  userId: string, //視聴ユーザID
  userName: string, //視聴ユーザ名
  periodFrom: string, //期間From
  periodTo: string, //期間To
  attendanceTimeThreshold: number, //視聴時間閾値
  attendance: boolean, //ライブ配信授業 出席
  partialAttendance: boolean, //ライブ配信授業 一部出席
  absence: boolean, //ライブ配信授業 欠席
  watched: boolean, //オンデマンド授業 視聴済
  partiallyWatched: boolean, //オンデマンド授業 一部視聴済
  notWatched: boolean, //オンデマンド授業 未視聴
  pageNo: number //ページ番号
) => {
  if (process.env.REACT_APP_API_USE_FLG === "true") {
    return apigw(
      "/LC0303",
      {
        class_id: classId,
        user_id: userId,
        user_name: userName,
        period_from: periodFrom,
        period_to: periodTo,
        attendance_time_threshold: attendanceTimeThreshold,
        attendance: attendance,
        partial_attendance: partialAttendance,
        absence: absence,
        watched: watched,
        partially_watched: partiallyWatched,
        not_watched: notWatched,
        page_no: pageNo,
      },
      dispatch
    );
  } else {
    //サンプルデータ用
    return {
      body: [
        createData("1234", "A", "エー", 60, 60),
        createData("1235", "B", "ビー", 60, 40),
        createData("1236", "C", "シー", 0, 0),
        createData("1237", "D", "ディー", 40, 10),
        createData("1238", "E", "イー", 30, 50),
        createData("1239", "F", "エフ", 0, 0),
        createData("1240", "G", "ジー", 50, 0),
        createData("1241", "H", "エイチ", 50, 40),
      ],
    };
  }
};
