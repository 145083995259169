import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { TextFieldBox } from "../../components/atoms/TextFieldBox";
import { TextareaAutoSize } from "../../components/atoms/TextareaAutoSize";
import Select from "@material-ui/core/Select";
import CheckIcon from "@material-ui/icons/Check";
import AssignmentIcon from "@material-ui/icons/Assignment";
import { unescapeStr } from "../../components/function/Function";

import {
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
} from "@material-ui/core";

import { getSessionUser } from "../../utils/authFlow";
import { LC0301 } from "../../utils/api/LC0301";
import { LC0302 } from "../../utils/api/LC0302";
import { CQ0301 } from "../../utils/api/CQ0301";
import { OQ0301 } from "../../utils/api/OQ0301";
import { TG0301 } from "../../utils/api/TG0301";
import { FormatMessage } from "../../components/atoms/FormatMessage";
import "../../css/cls/cls001.css";
import sanitize from "sanitize-html";

let tempData: any;

type customQuesRowType = {
  question_no: string; // 質問No
  question: string; // 質問（内容）
  character_class: string; // 回答方式 1:記述式、2:ラジオボタン、3:チェックボックス、4:プルダウン
  is_required: string; // 必須フラグ
  options: any[];
  update_date: Date;
}[];

// カスタムアンケート問題Delete用
let initialCustomQuesRows: customQuesRowType[] = [];

let firstBeforeComment = "";
let firstAfterComment = "";
/**
 * 授業後ページ設定
 * @param {object} props 引き継いだオブジェクト
 * @return コンポーネントHTMLコード内容
 */

export const Tab2 = (props: any) => {
  const { Callback } = props;
  const dispatch = useDispatch();

  const [classId, setClassId] = React.useState("");
  // const [maxQuestionNo, setMaxQuestionNo] = React.useState(0);

  // 授業後ページ設定選択値
  const [select, setSelect] = React.useState<{
    value: number;
    name: string;
  }>({
    value: 0,
    name: "",
  });

  // テンプレート選択値
  const [selectTemplate, setSelectTemplate] = React.useState<{
    selOption: string;
    selVal: string;
    selName: string;
  }>({
    selOption: "0",
    selVal: "0",
    selName: "",
  });

  const [outSideUrl, setOutSideUrl] = React.useState("");
  const [outSideUrlErr, setOutSideUrlErr] = React.useState(false);
  const [template, setTemplate] = React.useState<any[]>([]);
  const [beforeComment, setBeforeComment] = React.useState("");
  const [beforeCommentErr, setBeforeCommentErr] = React.useState(false);
  const [afterComment, setAfterComment] = React.useState("");
  const [afterCommentErr, setAfterCommentErr] = React.useState(false);
  const [lecUpdate, setLecUpdate] = React.useState("");
  const [customUpdate, setCustomUpdate] = React.useState("");
  const [urlState, setUrlState] = React.useState(false);
  const [defQueState, setDefQueState] = React.useState(false);
  const [cusQueState, setCusQueState] = React.useState(false);
  const [regFlg, setRegFlg] = React.useState(0);
  const [useTempFlg, setUseTempFlg] = React.useState(0);
  const [noQuestionFlg, setNoQuestionFlg] = React.useState(false);
  const [isVisibleDefFlg, setIsVisibleDefFlg] = React.useState("");
  const [customExistence, setCustomExistence] = React.useState(false);
  const [className, setClassName] = React.useState(false);

  const addCilck = () => {
    if (select.value === 0 || select.value === 1 || select.value === 4) {
      const emptyEnq: customQuesRowType[] = [];
      var afterPageSet = {
        afterPageSetting: select.value,
        afterUrl: outSideUrl,
        templateName: selectTemplate.selName,
        templateOption: selectTemplate.selOption,
        beforeAnswerMsg: beforeComment,
        afterAnswerMsg: afterComment,
        enquete: defaultQuesRows,
        iniEnquete: emptyEnq,
        tempFlg: useTempFlg,
        enqRegFlg: regFlg,
        classId: classId,
        lecUpdate: lecUpdate,
        customUpdate: customUpdate,
        isVisibleDefFlg: isVisibleDefFlg,
      };
      Callback(afterPageSet);
    } else {
      if (select.value === 2) {
        if (beforeComment !== "" && afterComment !== "") {
          var afterPageCustomSet = {
            afterPageSetting: select.value,
            afterUrl: outSideUrl,
            templateName: selectTemplate.selName,
            templateOption: selectTemplate.selOption,
            beforeAnswerMsg: beforeComment,
            afterAnswerMsg: afterComment,
            enquete: customQuesRows,
            iniEnquete: initialCustomQuesRows,
            tempFlg: useTempFlg,
            enqRegFlg: regFlg,
            classId: classId,
            lecUpdate: lecUpdate,
            customUpdate: customUpdate,
            noQuestionFlg: noQuestionFlg,
            customExistence: customExistence,
            className: className,
          };
          Callback(afterPageCustomSet);
        } else {
          if (beforeComment === "") {
            setBeforeCommentErr(true);
          }
          if (afterComment === "") {
            setAfterCommentErr(true);
          }
        }
      } else {
        if (select.value === 3) {
          if (outSideUrl !== "") {
            const urlEnq: customQuesRowType[] = [];
            var afterPageUrlSet = {
              afterPageSetting: select.value,
              afterUrl: outSideUrl,
              templateName: selectTemplate.selName,
              templateOption: selectTemplate.selOption,
              beforeAnswerMsg: beforeComment,
              afterAnswerMsg: afterComment,
              enquete: urlEnq,
              iniEnquete: urlEnq,
              tempFlg: useTempFlg,
              enqRegFlg: regFlg,
              classId: classId,
              lecUpdate: lecUpdate,
              customUpdate: customUpdate,
            };
            Callback(afterPageUrlSet);
          } else {
            setOutSideUrlErr(true);
          }
        }
      }
    }
  };

  const qeditCilck = () => {
    dispatch(push("/cls/004", { classId: sanitize(classId) }));
  };

  const outSideUrlErrCheck = (value: any) => {
    if (outSideUrl !== "") {
      setOutSideUrlErr(false);
    }
  };

  const beforeCommentErrCheck = (value: any) => {
    if (beforeComment !== "") {
      setBeforeCommentErr(false);
    }
  };

  const afterCommentErrCheck = (value: any) => {
    if (afterComment !== "") {
      setAfterCommentErr(false);
    }
  };

  // テンプレート
  // type itemType = {
  //   class_id: string; //授業ID
  //   class_name: string; //授業名
  //   s3_id: string; //授業写真
  //   plan_date: string; //授業予定日時
  //   explain: string; //説明文
  //   is_font_red: boolean; //フォント設定(赤字)
  //   is_font_bold: boolean; //フォント設定(太字)
  //   sort: number; //並び順
  //   status_class: string;
  //   channel_id: string; //チャンネルID
  //   stream_class: string; //授業区分
  //   open_nuclass: string; //授業区分(公開設定)
  //   existing_use_class: string; //授業区分(既存動画)
  //   is_filmed: string; //録画済みフラグ
  // };

  type defaultQuesRowType = {
    questionNo: string; // 質問No
    question: string; // 質問（内容）
    questionClass: string; // 回答方式 1:記述式、2:ラジオボタン、3:チェックボックス、4:プルダウン
    isRequired: string; // 必須フラグ
    // option_1: string;
    updateDate: Date;
  }[];

  const [defaultQuesRows, setDefaultQuesRows] =
    React.useState<defaultQuesRowType>([]);

  // カスタムアンケート問題表示用
  const [customQuesRows, setCustomQuesRows] = React.useState<customQuesRowType>(
    []
  );
  const [customflg, setCustomFlg] = React.useState(false);

  // 授業詳細情報取得処理
  const getLecData = async (callback: any) => {
    let getData = await LC0302(dispatch, classId, false, true);
    if (getData && Object.keys(getData).indexOf("body") >= 0)
      select.value = Number(getData.body.page_after_class);
    setOutSideUrl(getData.body.page_after_url);
    setBeforeComment(getData.body.custom_before_answer_msg);
    if (getData.body.custom_before_answer_msg) {
      setRegFlg(1);
    } else if (getData.body.custom_before_answer_msg === "") {
      setRegFlg(1);
    }
    setAfterComment(getData.body.custom_after_answer_msg);
    firstBeforeComment = getData.body.custom_before_answer_msg;
    firstAfterComment = getData.body.custom_after_answer_msg;
    setLecUpdate(getData.body.lecture_update_date);
    setCustomUpdate(getData.body.custom_enquete_update_date);
    setIsVisibleDefFlg(getData.body.is_visible_default_enquete);
    setClassName(getData.body.class_name);
    if (
      firstBeforeComment &&
      firstAfterComment &&
      getData.body.page_after_class
    ) {
      setCustomFlg(true);
    }
    switch (getData.body.page_after_class) {
      // サービス設定にしたがう
      case "0":
        if (getData.body.is_visible_default_enquete === "2") {
          getDefQueData(null);
        } else {
          setDefQueState(false);
        }
        break;
      // デフォルトアンケート
      case "1":
        getDefQueData(null);
        break;
      // カスタムアンケート
      case "2":
        getCustamQueData(null, "1");
        setUrlState(false);
        setDefQueState(false);
        setCusQueState(true);
        break;
      // 外部URL
      case "3":
        setUrlState(true);
        setDefQueState(false);
        setCusQueState(false);
        break;
      // 表示しない
      case "4":
        setUrlState(false);
        setDefQueState(false);
        setCusQueState(false);
        break;
    }
    if (callback) callback();
  };

  // テンプレート取得処理
  const getTemplateData = async (callback: any) => {
    const userData = await getSessionUser();
    if (userData !== false) {
      let tagList = await TG0301(dispatch, userData.user_class);
      if (tagList && Object.keys(tagList).indexOf("body") >= 0) {
        var tagId = [];
        for (let index = 0; index < tagList.body.length; index++) {
          const element = tagList.body[index];
          tagId.push(element.tag_id);
        }
        if (tagId.length > 0) {
          let getData = await LC0301(dispatch, tagId, true);
          if (getData && Object.keys(getData).indexOf("body") >= 0) {
            var classIds: string[] = [];
            var datas = [];
            for (let index = 0; index < getData.body.length; index++) {
              const element = getData.body[index];
              if (!classIds.includes(element.class_id)) {
                classIds.push(element.class_id);
                datas.push(element);
              }
            }
            setTemplate(datas);
            tempData = datas;
          }
        }
      }
    }
    if (callback) callback();
  };
  // デフォルトアンケート取得処理
  const getDefQueData = async (callback: any) => {
    await CQ0301(dispatch, null).then((getData: any) => {
      let maxNo = 1;
      if (
        getData &&
        getData.body &&
        Object.keys(getData.body).indexOf("question") >= 0
      ) {
        if (getData.body.question.length > 0) {
          let newRows = getData.body.question.map(
            (getDataItem: any, index: number) => {
              maxNo =
                maxNo < Number(getDataItem.question_no)
                  ? Number(getDataItem.question_no)
                  : maxNo;
              const options = getDataItem.option[0];
              let optionsIndex = 1;
              let optionsArray = [];
              while (
                Object.keys(options).indexOf("option_" + optionsIndex) >= 0
              ) {
                if (options["option_" + optionsIndex]) {
                  optionsArray.push(options["option_" + optionsIndex]);
                }
                optionsIndex++;
              }
              return {
                questionNo: getDataItem.question_no, // 質問No
                question: getDataItem.question, // 質問（内容）
                questionClass: getDataItem.question_class, // 回答方式 1:記述式、2:ラジオボタン、3:チェックボックス、4:プルダウン
                is_required: getDataItem.is_required, // 必須フラグ
                options: optionsArray,
                updateDate: getDataItem.update_date,
              };
            }
          );
          newRows.sort(function (a: any, b: any) {
            return Number(a.questionNo) - Number(b.questionNo);
          });
          setDefaultQuesRows(newRows);
        }
      }
      // setMaxQuestionNo(maxNo);

      if (
        getData &&
        getData.body &&
        Object.keys(getData.body).indexOf("question") >= 0
      ) {
        setDefQueState(true);
      } else {
        setUrlState(false);
        setDefQueState(false);
        setCusQueState(false);
      }
    });

    if (callback) callback();
  };

  // カスタムアンケート取得処理
  const getCustamQueData = async (callback: any, value: any) => {
    await OQ0301(dispatch, classId, null).then((getData: any) => {
      let maxNo = 1;
      if (
        getData &&
        getData.body &&
        Object.keys(getData.body).indexOf("question") >= 0
      ) {
        if (getData.body.question.length > 0) {
          initialCustomQuesRows = getData.body.question.map(
            (getDataItem: any, index: number) => {
              maxNo =
                maxNo < Number(getDataItem.question_no)
                  ? Number(getDataItem.question_no)
                  : maxNo;
              const options = getDataItem.option[0];
              let optionsIndex = 1;
              let optionsArray = [];
              while (
                Object.keys(options).indexOf("option_" + optionsIndex) >= 0
              ) {
                if (options["option_" + optionsIndex]) {
                  optionsArray.push(options["option_" + optionsIndex]);
                }
                optionsIndex++;
              }
              return {
                question_no: getDataItem.question_no, // 質問No
                question: getDataItem.question, // 質問（内容）
                character_class: getDataItem.character_class, // 回答方式 1:記述式、2:ラジオボタン、3:チェックボックス、4:プルダウン
                is_required: getDataItem.is_required, // 必須フラグ
                options: optionsArray,
                update_date: getDataItem.update_date,
              };
            }
          );
          console.log(initialCustomQuesRows);
          const sortList = getData.body.question.sort(function (
            a: any,
            b: any
          ) {
            return Number(a.question_no) - Number(b.question_no);
          });

          setCustomQuesRows(
            sortList.map((getDataItem: any, index: number) => {
              maxNo =
                maxNo < Number(getDataItem.question_no)
                  ? Number(getDataItem.question_no)
                  : maxNo;
              const options = getDataItem.option[0];
              let optionsIndex = 1;
              let optionsArray = [];
              while (
                Object.keys(options).indexOf("option_" + optionsIndex) >= 0
              ) {
                if (options["option_" + optionsIndex]) {
                  optionsArray.push(options["option_" + optionsIndex]);
                }
                optionsIndex++;
              }
              return {
                question_no: getDataItem.question_no, // 質問No
                question: getDataItem.question, // 質問（内容）
                character_class: getDataItem.character_class, // 回答方式 1:記述式、2:ラジオボタン、3:チェックボックス、4:プルダウン
                is_required: getDataItem.is_required, // 必須フラグ
                options: optionsArray,
                update_date: getDataItem.update_date,
              };
            })
          );
          setBeforeComment(firstBeforeComment);
          setAfterComment(firstAfterComment);
          if (value === "1") setCustomExistence(true);
        }
        // setMaxQuestionNo(maxNo);
      } else {
        setNoQuestionFlg(true);
      }
    });

    if (callback) callback();
  };

  // カスタムアンケートテンプレート取得処理
  const getCustamQueTempData = async (val: any) => {
    Promise.all([
      LC0302(dispatch, val, false, true),
      OQ0301(dispatch, val, null),
    ]).then((values) => {
      if (values[0])
        if (values[0] && Object.keys(values[0]).indexOf("body") >= 0) {
          setBeforeComment(values[0].body.custom_before_answer_msg);
          //if (values[0].body.custom_before_answer_msg) {
          //setRegFlg(1);
          //}
          setAfterComment(values[0].body.custom_after_answer_msg);
        }
      if (values[1]) {
        if (values[1] && Object.keys(values[1]).indexOf("body") >= 0) {
          let maxNo = 1;
          if (values[1].body.question?.length > 0) {
            const sortList = values[1].body.question.sort(function (
              a: any,
              b: any
            ) {
              return Number(a.question_no) - Number(b.question_no);
            });
            setCustomQuesRows(
              sortList.map((getDataItem: any, index: number) => {
                maxNo =
                  maxNo < Number(getDataItem.question_no)
                    ? Number(getDataItem.question_no)
                    : maxNo;
                const options = getDataItem.option[0];
                let optionsIndex = 1;
                let optionsArray = [];
                while (
                  Object.keys(options).indexOf("option_" + optionsIndex) >= 0
                ) {
                  if (options["option_" + optionsIndex]) {
                    optionsArray.push(options["option_" + optionsIndex]);
                  }
                  optionsIndex++;
                }
                return {
                  question_no: getDataItem.question_no, // 質問No
                  question: getDataItem.question, // 質問（内容）
                  character_class: getDataItem.character_class, // 回答方式 1:記述式、2:ラジオボタン、3:チェックボックス、4:プルダウン
                  is_required: getDataItem.is_required, // 必須フラグ
                  options: optionsArray,
                  update_date: getDataItem.update_date,
                };
              })
            );
          }
          // setMaxQuestionNo(maxNo);
        }
      }
    });
  };

  // 授業後ページ設定イベント
  const selectHandleChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    setSelect({
      value: Number(event.target.value),
      name: event.target.name !== undefined ? event.target.name : "",
    });
    if (event.target.value === "4") {
      setDefQueState(false);
      setCusQueState(false);
      setUrlState(false);
    }
    if (event.target.value === "3") {
      setUrlState(true);
    } else {
      setUrlState(false);
    }
    if (event.target.value === "2") {
      setCusQueState(true);
      getCustamQueData(null, "1");
    } else {
      setSelectTemplate({
        selOption: "",
        selVal: "",
        selName: "",
      });
      setCusQueState(false);
    }
    if (
      event.target.value === "1" ||
      (event.target.value === "0" && isVisibleDefFlg === "2")
    ) {
      getDefQueData(null);
    } else {
      setDefQueState(false);
    }
  };

  // テンプレートイベント
  const templateSelectHandleChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const classInfo = String(event.target.value).split(",");
    setSelectTemplate({
      selOption: String(event.target.value),
      selVal: classInfo[0],
      selName: classInfo[1],
    });
    if (classInfo[0] !== "0") {
      getCustamQueTempData(classInfo[0]);
      setUseTempFlg(1);
    } else {
      getCustamQueData(null, null);
      setUseTempFlg(0);
    }
  };

  const getBackLecData = async (callback: any) => {
    // let getData = await LC0302(dispatch, classId, false);
    let val = props.Values;
    let qval = props.QuesValues;
    if (!tempData) await getTemplateData(null);
    let option = val.templateOption;
    const classInfo = String(option).split(",");
    setSelectTemplate({
      selOption: option,
      selVal: classInfo[0],
      selName: val.templateName,
    });
    // if (getData && Object.keys(getData).indexOf("body") >= 0)
    select.value = Number(val.afterPageSetting);
    setOutSideUrl(val.afterUrl);
    setBeforeComment(val.beforeAnswerMsg);
    setAfterComment(val.afterAnswerMsg);

    setLecUpdate(val.lecUpdate);
    setCustomUpdate(val.customUpdate);
    setNoQuestionFlg(val.noQuestionFlg);
    setIsVisibleDefFlg(val.isVisibleDefFlg);
    setCustomExistence(val.customExistence);
    setClassName(val.className);
    switch (val.afterPageSetting) {
      // サービス設定にしたがう
      case 0:
        if (val.isVisibleDefFlg === "2") {
          setDefaultQuesRows(qval);
          setDefQueState(true);
        } else {
          setDefQueState(false);
        }
        break;
      // デフォルトアンケート
      case 1:
        setDefaultQuesRows(qval);
        setDefQueState(true);
        break;
      // カスタムアンケート
      case 2:
        setCustomQuesRows(qval);
        setUrlState(false);
        setDefQueState(false);
        setCusQueState(true);
        break;
      // 外部URL
      case 3:
        setUrlState(true);
        setDefQueState(false);
        setCusQueState(false);
        break;
      // 表示しない
      case 4:
        setUrlState(false);
        setDefQueState(false);
        setCusQueState(false);
        break;
    }
    if (callback) callback();
  };

  // 初期処理
  useEffect(() => {
    setClassId(props.class_id);
    if (classId) {
      if (props.Values.lecUpdate === "" || props.UpdateFlg === true) {
        getLecData(null);
        getTemplateData(null);
      } else {
        getBackLecData(null);
        getTemplateData(null);
      }
    }

    /* eslint-disable react-hooks/exhaustive-deps */
  }, [classId, props.UpdateFlg]);

  type inputProps = {
    row: any;
  };

  const TextAreaBox: React.FC<inputProps> = ({ row = {} }) => {
    return (
      <div className="App-mB20 App-text-no-width">
        <div style={{ fontSize: "1rem", lineHeight: "1.5", whiteSpace:"pre-line"}}>
          {unescapeStr(row.question)}
          {row.is_required === "1" ? (
            <span className="required_box1">
              {FormatMessage({ id: "Cls004.Label.Required" })}
            </span>
          ) : null}
        </div>
        <Grid item xs={12} style={{ width: "fullWidth", marginTop: "10px" }}>
          <TextareaAutoSize value={""} rows={3} />
        </Grid>
      </div>
    );
  };

  const RadioBox: React.FC<inputProps> = ({ row = {} }) => {
    return (
      <div className="App-mB20 App-text-no-width">
        <Typography variant="body1" style={{whiteSpace:"pre-line"}}>
          {unescapeStr(row.question)}
          {row.is_required === "1" ? (
            <span className="required_box1">
              {FormatMessage({ id: "Cls004.Label.Required" })}
            </span>
          ) : null}
        </Typography>
        <RadioGroup>
          {Object.keys(row.options).map((val: string) => (
            <div key={val}>
              {row.options[val] ? (
                <FormControlLabel
                  key={row.options[val]}
                  value={unescapeStr(row.options[val])}
                  control={<Radio size="small" color="default" />}
                  label={
                    <Typography style={{ wordBreak: "break-all" }}>
                      {row.options[val]}
                    </Typography>
                  }
                />
              ) : null}
            </div>
          ))}
        </RadioGroup>
      </div>
    );
  };

  const CheckBox: React.FC<inputProps> = ({ row = {} }) => {
    return (
      <div className="App-mB20 App-text-no-width">
        <Typography variant="body1" style={{whiteSpace:"pre-line"}}>
          {unescapeStr(row.question)}
          {row.is_required === "1" ? (
            <span className="required_box1">
              {FormatMessage({ id: "Cls004.Label.Required" })}
            </span>
          ) : null}
        </Typography>
        {Object.keys(row.options).map((val: string) => (
          <div key={val}>
            {row.options[val] ? (
              <FormControlLabel
                key={row.options[val]}
                value={unescapeStr(row.options[val])}
                control={<Checkbox size="small" color="default" />}
                label={
                  <Typography style={{ wordBreak: "break-all" }}>
                    {row.options[val]}
                  </Typography>
                }
              />
            ) : null}
          </div>
        ))}
      </div>
    );
  };

  const SelectBox: React.FC<inputProps> = ({ row = {} }) => {
    return (
      <div className="App-mB20 App-text-no-width">
        <Typography variant="body1" style={{whiteSpace:"pre-line"}}>
          {unescapeStr(row.question)}
          {row.is_required === "1" ? (
            <span className="required_box1">
              {FormatMessage({ id: "Cls004.Label.Required" })}
            </span>
          ) : null}
        </Typography>
        <Select native>{SelectItems(row.options)}</Select>
      </div>
    );
  };

  function SelectItems(items: any) {
    return [
      Object.keys(items).map((val: string) =>
        items[val] ? (
          <option key={val} id={items[val]} style={{ wordBreak: "break-all" }}>
            {unescapeStr(items[val])}
          </option>
        ) : null
      ),
    ];
  }

  return (
    <div className="App-paper-root">
      <Grid item xs={12} className="App-pTB10 App-pLR10">
        <Typography variant="h6">
          {FormatMessage({ id: "Cls001.Title.text4" })}
        </Typography>
        <Grid item xs={12} className="App-alignRight">
          <Button
            variant="contained"
            color="primary"
            startIcon={<CheckIcon />}
            onClick={addCilck}
          >
            {FormatMessage({ id: "Cls001.Button.text6" })}
          </Button>
        </Grid>
      </Grid>
      <Grid className="App-box">
        <Grid container spacing={1} className="App-List">
          <Grid
            item
            xs={12}
            sm={6}
            style={{ width: "300px", minWidth: "300px", maxWidth: "300px" }}
          >
            {FormatMessage({ id: "Cls001.Label.text5" })}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Select native onChange={selectHandleChange} value={select.value}>
              <option value={0}>
                {FormatMessage({ id: "Cls001.List.text1" })}
              </option>
              <option value={1}>
                {FormatMessage({ id: "Cls001.List.text2" })}
              </option>
              <option value={2}>
                {FormatMessage({ id: "Cls001.List.text3" })}
              </option>
              <option value={3}>
                {FormatMessage({ id: "Cls001.List.text4" })}
              </option>
              <option value={4}>
                {FormatMessage({ id: "Cls001.List.text5" })}
              </option>
            </Select>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={1}
          className="App-List"
          style={{ visibility: urlState ? "visible" : "hidden" }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            style={{ width: "300px", minWidth: "300px", maxWidth: "300px" }}
          >
            {FormatMessage({ id: "Cls001.Label.text6" })}
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextFieldBox
              className="App-input-text"
              id="outlined-basic"
              error={outSideUrlErr}
              value={outSideUrl}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setOutSideUrl(event.target.value);
                outSideUrlErrCheck(event.target.value);
              }}
              variant="outlined"
              fullWidth
              helperText={
                outSideUrlErr
                  ? FormatMessage({
                      id: "Cls001.Err.Msg9"
                    })
                  : null
              }
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={1}
          className="App-List"
          style={{ visibility: cusQueState ? "visible" : "hidden" }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            style={{ width: "300px", minWidth: "300px", maxWidth: "300px" }}
          >
            {FormatMessage({ id: "Cls001.Label.text7" })}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Select
              native
              onChange={templateSelectHandleChange}
              value={selectTemplate.selOption}
            >
              <option value={["0", ""]}>
                {customflg
                  ? className
                  : FormatMessage({
                      id: "Cls001.List.text6",
                    })}
              </option>
              {template.length > 0
                ? template.map(
                    (rows) =>
                      rows.class_id !== classId && (
                        <option
                          key={rows.class_id}
                          value={[rows.class_id, rows.class_name]}
                        >
                          {rows.class_name}
                        </option>
                      )
                  )
                : null}
            </Select>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={1}
          className="App-List"
          style={{ visibility: cusQueState ? "visible" : "hidden" }}
        >
          <Grid
            item
            xs={12}
            sm={2}
            style={{ width: "300px", minWidth: "300px", maxWidth: "300px" }}
          >
            {FormatMessage({ id: "Cls001.Label.text8" })}
          </Grid>
          <Grid
            item
            xs={12}
            sm={10}
            style={{
              width: "calc(100% - 300px)",
              minWidth: "calc(100% - 300px)",
              maxWidth: "calc(100% - 300px)",
            }}
          >
            <TextFieldBox
              className="App-input-text"
              id="befComment"
              error={beforeCommentErr}
              value={unescapeStr(beforeComment)}
              inputProps={{ maxLength: 1000 }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setBeforeComment(event.target.value);
                beforeCommentErrCheck(event.target.value);
              }}
              variant="outlined"
              fullWidth
              helperText={
                beforeCommentErr
                  ? FormatMessage({
                      id: "Error.Message.RequiredError",
                      option: { name: "回答前コメント" },
                    })
                  : null
              }
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={1}
          className="App-List"
          style={{ visibility: cusQueState ? "visible" : "hidden" }}
        >
          <Grid
            item
            xs={12}
            sm={2}
            style={{ width: "300px", minWidth: "300px", maxWidth: "300px" }}
          >
            {FormatMessage({ id: "Cls001.Label.text9" })}
          </Grid>
          <Grid
            item
            xs={12}
            sm={10}
            style={{
              width: "calc(100% - 300px)",
              minWidth: "calc(100% - 300px)",
              maxWidth: "calc(100% - 300px)",
            }}
          >
            <TextFieldBox
              className="App-input-text"
              id="afComment"
              error={afterCommentErr}
              value={unescapeStr(afterComment)}
              inputProps={{ maxLength: 1000 }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setAfterComment(event.target.value);
                afterCommentErrCheck(event.target.value);
              }}
              variant="outlined"
              fullWidth
              helperText={
                afterCommentErr
                  ? FormatMessage({
                      id: "Error.Message.RequiredError",
                      option: { name: "回答後コメント" },
                    })
                  : null
              }
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={1} className="App-pTB20">
        <Grid item xs={12} sm={6}>
          <Typography variant="h6">
            {FormatMessage({ id: "Cls001.Title.text5" })}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          className="App-alignRight"
          style={{ visibility: cusQueState ? "visible" : "hidden" }}
        >
          <Button
            variant="contained"
            color="primary"
            startIcon={<AssignmentIcon />}
            onClick={qeditCilck}
          >
            {FormatMessage({ id: "Cls001.Button.text7" })}
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={1} className="App-mB20 App-box">
        <Grid item xs={12} className="App-List">
          {defQueState
            ? defaultQuesRows.length > 0
              ? defaultQuesRows.map((rows) => (
                  <div key={rows.questionNo}>
                    {rows.questionClass === "1" ? (
                      <TextAreaBox row={rows} />
                    ) : null}
                    {rows.questionClass === "2" ? (
                      <RadioBox row={rows} />
                    ) : null}
                    {rows.questionClass === "3" ? (
                      <CheckBox row={rows} />
                    ) : null}
                    {rows.questionClass === "4" ? (
                      <SelectBox row={rows} />
                    ) : null}
                  </div>
                ))
              : null
            : cusQueState
            ? customQuesRows.length > 0
              ? customQuesRows.map((rows) => (
                  <div key={rows.question_no}>
                    {rows.character_class === "1" ? (
                      <TextAreaBox row={rows} />
                    ) : null}
                    {rows.character_class === "2" ? (
                      <RadioBox row={rows} />
                    ) : null}
                    {rows.character_class === "3" ? (
                      <CheckBox row={rows} />
                    ) : null}
                    {rows.character_class === "4" ? (
                      <SelectBox row={rows} />
                    ) : null}
                  </div>
                ))
              : null
            : null}
        </Grid>
      </Grid>
    </div>
  );
};
