import Grid from "@material-ui/core/Grid";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { FormatMessage } from "../../components/atoms/FormatMessage";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

/**
 * 授業後ページ設定完了
 * @param {object} props 引き継いだオブジェクト
 * @return コンポーネントHTMLコード内容
 */
export const Cls003 = (props: any) => {
  const { Callback } = props;

  const backCilck = () => {
    Callback();
  };

  return (
    <div className="App-paper-root">
      <Grid container spacing={1} className="App-mTB10">
        <Grid item xs={12} sm={6}>
          <Typography variant="h6">
            {FormatMessage({ id: "Cls003.Title" })}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} className="App-alignRight">
          <Button variant="contained" color="primary" startIcon={<ArrowBackIcon />} onClick={backCilck}>
            {FormatMessage({ id: "Cls002.Button.back" })}
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12} className="App-alignCenter">
        <Typography variant="body1" component="div">
          {FormatMessage({ id: "Cls003.Content.text" })}
        </Typography>
      </Grid>
    </div>
  );
};
