import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import NativeSelect from "@material-ui/core/NativeSelect";
import Button from "@material-ui/core/Button";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CheckIcon from "@material-ui/icons/Check";
import { TextFieldBox } from "../../components/atoms/TextFieldBox";
import { FormatMessage } from "../../components/atoms/FormatMessage";

/**
 * タグ一覧
 * @param {object} props 引き継いだオブジェクト
 * @return コンポーネントHTMLコード内容
 */

export const Cls020 = (props: any) => {
  const { saveData, Callback } = props;
  const [StErr, setStErr] = React.useState(false);
  const [DtErr, setDtErr] = React.useState(false);
  const [TtErr, setTtErr] = React.useState(false);
  const [TtTErr, setTtTErr] = React.useState(false);
  const [SsErr, setSsErr] = React.useState(false);
  const [EsErr, setEsErr] = React.useState(false);
  const [st, setSt] = useState("");
  const [st1, setSt1] = useState("00");
  const [st2, setSt2] = useState("00");
  const [st3, setSt3] = useState("00");
  const [dt, setDt] = useState(0);
  const [tt, setTt] = useState("");
  const [ff, setFf] = useState(false);
  const [movieTime, setmovieTime] = React.useState<any>("");
  const canselClick = () => {
    Callback(null);
  };

  const err_message = FormatMessage({ id: "Cls020.Error.telop" });

  const confClick = () => {
    let data: any = {};

    if (st === "" || dt === 0 || tt === "") {
      if (st === "") {
        setStErr(true);
      } else {
        setStErr(false);
      }
      if (dt === 0) {
        setDtErr(true);
      } else {
        setDtErr(false);
      }
      if (tt === "") {
        setTtErr(true);
      } else {
        setTtErr(false);
      }
    } else {
      setStErr(false);
      setDtErr(false);
      setTtErr(false);
      setTtTErr(false);

      const date_startTime_s = new Date(saveData.nowTime + " " + st).getTime();
      const date_movieTime_s = new Date(
        saveData.nowTime + " " + movieTime
      ).getTime();
      const date_endTime_s = date_startTime_s + dt * 1000;
      let flg = true;

      if (date_startTime_s < date_movieTime_s) {
        setSsErr(false);
        if (date_endTime_s <= date_movieTime_s) {
          setEsErr(false);
        } else {
          setEsErr(true);
          flg = false;
        }

        for (let i = 0; i < saveData.telopList.length; i++) {
          const st = saveData.telopList[i].start_Datetime.getTime();
          const et = saveData.telopList[i].end_Datetime.getTime();

          if (date_startTime_s < st) {
            setSsErr(false);
            if (date_endTime_s <= st) {
              setEsErr(false);
            } else {
              setEsErr(true);
              flg = false;
              break;
            }
          } else if (et <= date_startTime_s) {
            setSsErr(false);
          } else {
            setSsErr(true);
            flg = false;
            break;
          }
        }
      } else {
        setSsErr(true);
        flg = false;
      }

      let nt = tt.split("\n");
      for (let index = 0; index < nt.length; index++) {
        if (nt[index].length === 0) {
          setTtTErr(true);
          flg = false;
          break;
        }
      }

      if (flg) {
        data.start_time = st;
        data.display_time = dt;
        data.text = tt;
        data.state = "conf";
        data.delflg = false;
        data.start_Datetime = new Date(date_startTime_s);
        data.end_Datetime = new Date(date_endTime_s);
        data.telopList = saveData.telopList;
        data.nowTime = saveData.nowTime;
        data.class_id = saveData.class_id;
        data.movieTime = movieTime;
        Callback(data);
      }
    }
  };

  const message = {
    Display_Start: FormatMessage({ id: "Cls019.Label.Display_Start" }),
    Display_Time: FormatMessage({ id: "Cls019.Label.Display_Time" }),
    Display_Start_o: FormatMessage({ id: "Cls020.Label.Display_Start" }),
    Display_Time_o: FormatMessage({ id: "Cls020.Label.Display_Time" }),
    Trop: FormatMessage({ id: "Cls019.Label.Trop" }),
  };

  const delClick = () => {
    let data: any = {};
    data.start_time = st;
    data.display_time = dt;
    data.text = tt;
    data.state = "conf";
    data.delflg = true;
    data.telopList = saveData.telopList;
    data.class_id = saveData.class_id;
    data.movieTime = movieTime;
    Callback(data);
  };

  // 初回読み込み
  useEffect(() => {
    if (saveData && saveData.start_time) {
      setSt(saveData.start_time);
      let timeData = saveData.start_time.split(":");
      if (timeData[0]) setSt1(timeData[0]);
      if (timeData[1]) setSt2(timeData[1]);
      if (timeData[2]) setSt3(timeData[2]);
      setFf(true);
    } else {
      setSt("00:00:00");
    }
    if (saveData && saveData.display_time) {
      setDt(saveData.display_time);
    }
    if (saveData && saveData.text && saveData.text.length > 0) {
      setTt(saveData.text);
    }
    setmovieTime(saveData.movieTime);
  }, []);

  return (
    <div className="App-paper-root">
      <Grid item xs={12} className="App-pTB10 App-pLR10">
        <Typography variant="h6">
          {FormatMessage({ id: "Cls001.Label.text10" })}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6} style={{ display: "flex" }}>
            <div style={{ margin: "10px" }}>
              {FormatMessage({ id: "Cls020.Label.Class_Time" })}
            </div>
            <div style={{ margin: "10px" }}>{movieTime}</div>
          </Grid>
          <Grid item xs={6}></Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className="App-List App-alignRight">
        {ff ? (
          <div className="App-FloatLeft">
            <Button
              variant="contained"
              color="default"
              startIcon={<DeleteForeverIcon />}
              onClick={delClick}
            >
              {FormatMessage({ id: "Button.Del" })}
            </Button>
          </div>
        ) : null}

        <span className="App-mR10">
          <Button
            variant="contained"
            color="primary"
            onClick={canselClick}
            startIcon={<ArrowBackIcon />}
          >
            {FormatMessage({ id: "Cls020.Label.text1" })}
          </Button>
        </span>
        <span className="App-mR10">
          <Button
            variant="contained"
            color="primary"
            onClick={confClick}
            startIcon={<CheckIcon />}
          >
            {FormatMessage({ id: "Cls020.Label.text2" })}
          </Button>
        </span>
      </Grid>
      <Grid className="App-box">
        <Grid container spacing={2}>
          <Grid container xs={6}>
            <Grid
              xs={12}
              sm={2}
              className="App-mT10"
              style={{ minWidth: "75px" }}
            >
              {FormatMessage({ id: "Cls020.Text.StartTime" })}
            </Grid>
            <Grid item xs={12} sm={6} style={{ minWidth: "165px" }}>
              <NativeSelect
                error={StErr || SsErr}
                value={st1}
                onChange={(e: any) => {
                  setSt1(e.target.value);
                  setSt(e.target.value + ":" + st2 + ":" + st3);
                }}
                style={{ width: "45px" }}
              >
                <option value={"00"}>00</option>
                <option value={"01"}>01</option>
                <option value={"02"}>02</option>
                <option value={"03"}>03</option>
                <option value={"04"}>04</option>
                <option value={"05"}>05</option>
                <option value={"06"}>06</option>
                <option value={"07"}>07</option>
                <option value={"08"}>08</option>
                <option value={"09"}>09</option>
                <option value={"10"}>10</option>
                <option value={"11"}>11</option>
                <option value={"12"}>12</option>
                <option value={"13"}>13</option>
                <option value={"14"}>14</option>
                <option value={"15"}>15</option>
                <option value={"16"}>16</option>
                <option value={"17"}>17</option>
                <option value={"18"}>18</option>
                <option value={"19"}>19</option>
                <option value={"20"}>20</option>
                <option value={"21"}>21</option>
                <option value={"22"}>22</option>
                <option value={"23"}>23</option>
              </NativeSelect>
              ：
              <NativeSelect
                error={StErr || SsErr}
                value={st2}
                onChange={(e: any) => {
                  setSt2(e.target.value);
                  setSt(st1 + ":" + e.target.value + ":" + st3);
                }}
                style={{ width: "45px" }}
              >
                <option value={"00"}>00</option>
                <option value={"01"}>01</option>
                <option value={"02"}>02</option>
                <option value={"03"}>03</option>
                <option value={"04"}>04</option>
                <option value={"05"}>05</option>
                <option value={"06"}>06</option>
                <option value={"07"}>07</option>
                <option value={"08"}>08</option>
                <option value={"09"}>09</option>
                <option value={"10"}>10</option>
                <option value={"11"}>11</option>
                <option value={"12"}>12</option>
                <option value={"13"}>13</option>
                <option value={"14"}>14</option>
                <option value={"15"}>15</option>
                <option value={"16"}>16</option>
                <option value={"17"}>17</option>
                <option value={"18"}>18</option>
                <option value={"19"}>19</option>
                <option value={"20"}>20</option>
                <option value={"21"}>21</option>
                <option value={"22"}>22</option>
                <option value={"23"}>23</option>
                <option value={"24"}>24</option>
                <option value={"25"}>25</option>
                <option value={"26"}>26</option>
                <option value={"27"}>27</option>
                <option value={"28"}>28</option>
                <option value={"29"}>29</option>
                <option value={"30"}>30</option>
                <option value={"31"}>31</option>
                <option value={"32"}>32</option>
                <option value={"33"}>33</option>
                <option value={"34"}>34</option>
                <option value={"35"}>35</option>
                <option value={"36"}>36</option>
                <option value={"37"}>37</option>
                <option value={"38"}>38</option>
                <option value={"39"}>39</option>
                <option value={"40"}>40</option>
                <option value={"41"}>41</option>
                <option value={"42"}>42</option>
                <option value={"43"}>43</option>
                <option value={"44"}>44</option>
                <option value={"45"}>45</option>
                <option value={"46"}>46</option>
                <option value={"47"}>47</option>
                <option value={"48"}>48</option>
                <option value={"49"}>49</option>
                <option value={"50"}>50</option>
                <option value={"51"}>51</option>
                <option value={"52"}>52</option>
                <option value={"53"}>53</option>
                <option value={"54"}>54</option>
                <option value={"55"}>55</option>
                <option value={"56"}>56</option>
                <option value={"57"}>57</option>
                <option value={"58"}>58</option>
                <option value={"59"}>59</option>
              </NativeSelect>
              ：
              <NativeSelect
                error={StErr || SsErr}
                value={st3}
                onChange={(e: any) => {
                  setSt3(e.target.value);
                  setSt(st1 + ":" + st2 + ":" + e.target.value);
                }}
                style={{ width: "45px" }}
              >
                <option value={"00"}>00</option>
                <option value={"01"}>01</option>
                <option value={"02"}>02</option>
                <option value={"03"}>03</option>
                <option value={"04"}>04</option>
                <option value={"05"}>05</option>
                <option value={"06"}>06</option>
                <option value={"07"}>07</option>
                <option value={"08"}>08</option>
                <option value={"09"}>09</option>
                <option value={"10"}>10</option>
                <option value={"11"}>11</option>
                <option value={"12"}>12</option>
                <option value={"13"}>13</option>
                <option value={"14"}>14</option>
                <option value={"15"}>15</option>
                <option value={"16"}>16</option>
                <option value={"17"}>17</option>
                <option value={"18"}>18</option>
                <option value={"19"}>19</option>
                <option value={"20"}>20</option>
                <option value={"21"}>21</option>
                <option value={"22"}>22</option>
                <option value={"23"}>23</option>
                <option value={"24"}>24</option>
                <option value={"25"}>25</option>
                <option value={"26"}>26</option>
                <option value={"27"}>27</option>
                <option value={"28"}>28</option>
                <option value={"29"}>29</option>
                <option value={"30"}>30</option>
                <option value={"31"}>31</option>
                <option value={"32"}>32</option>
                <option value={"33"}>33</option>
                <option value={"34"}>34</option>
                <option value={"35"}>35</option>
                <option value={"36"}>36</option>
                <option value={"37"}>37</option>
                <option value={"38"}>38</option>
                <option value={"39"}>39</option>
                <option value={"40"}>40</option>
                <option value={"41"}>41</option>
                <option value={"42"}>42</option>
                <option value={"43"}>43</option>
                <option value={"44"}>44</option>
                <option value={"45"}>45</option>
                <option value={"46"}>46</option>
                <option value={"47"}>47</option>
                <option value={"48"}>48</option>
                <option value={"49"}>49</option>
                <option value={"50"}>50</option>
                <option value={"51"}>51</option>
                <option value={"52"}>52</option>
                <option value={"53"}>53</option>
                <option value={"54"}>54</option>
                <option value={"55"}>55</option>
                <option value={"56"}>56</option>
                <option value={"57"}>57</option>
                <option value={"58"}>58</option>
                <option value={"59"}>59</option>
              </NativeSelect>
              {SsErr ? (
                <Grid
                  xs={12}
                  sm={12}
                  style={{
                    minWidth: "75px",
                    color: "#f44336",
                    fontSize: "12px",
                    lineHeight: "1.7",
                    margin: "4px 14px 0px",
                  }}
                >
                  {FormatMessage({
                    id: "Error.Message.duplicateError",
                    option: { name: message.Display_Start },
                  })}
                </Grid>
              ) : null}
            </Grid>
          </Grid>
          <Grid container xs={6}>
            <Grid
              xs={12}
              sm={2}
              className="App-mT10"
              style={{ minWidth: "75px" }}
            >
              {FormatMessage({ id: "Cls020.Text.DisplayTime" })}
            </Grid>
            <Grid item xs={12} sm={6} style={{ minWidth: "125px" }}>
              <TextFieldBox
                className="App-input-text"
                id="outlined-basic"
                error={DtErr || EsErr}
                value={dt}
                type="number"
                onChange={(e: any) => {
                  if (e.target.value > 0) {
                    let num = Math.floor(Math.abs(Number(e.target.value)));
                    setDt(num);
                    let basic: any = document.getElementById("outlined-basic");
                    basic.value = num;
                  } else {
                    setDt(0);
                  }
                }}
                variant="outlined"
                fullWidth
                helperText={
                  DtErr
                    ? FormatMessage({
                        id: "Error.Message.RequiredError",
                        option: { name: message.Display_Time },
                      })
                    : EsErr
                    ? FormatMessage({
                        id: "Error.Message.duplicateError",
                        option: { name: message.Display_Time_o },
                      })
                    : null
                }
              />
            </Grid>
            <Grid
              xs={12}
              sm={2}
              className="App-mT10"
              style={{ maxWidth: "50px" }}
            >
              {FormatMessage({ id: "Cls020.Text.Second" })}
            </Grid>
          </Grid>
        </Grid>
        <Grid container xs={12}>
          <Grid
            xs={12}
            sm={1}
            className="App-mT10"
            style={{ minWidth: "75px" }}
          >
            {FormatMessage({ id: "Cls020.Text.telop" })}
          </Grid>
          <Grid item xs={12} sm={10}>
            <TextFieldBox
              className="App-input-text"
              id="outlined-basic"
              error={TtErr || TtTErr}
              value={tt}
              onChange={(e: any) => {
                let value = e.target.value
                  .replaceAll("<", "＜")
                  .replaceAll(">", "＞");
                setTt(value);
              }}
              variant="outlined"
              fullWidth
              multiline
              helperText={
                TtTErr
                  ? err_message
                  : TtErr
                  ? FormatMessage({
                      id: "Error.Message.RequiredError",
                      option: { name: message.Trop },
                    })
                  : null
              }
              inputProps={{ maxLength: 256 }}
              //style={{ width: "667px", hei
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
