import { useCallback, useState } from "react";
import { useDrop } from "react-dnd";
import { Card } from "./cardImg";
import update from "immutability-helper";

/**
 * 資料アップロード
 * Image DnD 並び替えコンポーネント
 */

export const ItemTypes = {
  CARD: "card",
};

export interface ContainerState {
  cards: any[];
}

type imgData = {
  board_document_id: string;
  board_document_name: string;
  s3_id: string;
  file_url: string;
  isUpLoading?: boolean;
};

type ImgDrop = {
  imgList?: imgData[];
  callback?: any;
};

export const ImgDropBox: React.FC<ImgDrop> = ({
  imgList = [],
  callback = () => {},
}: ImgDrop) => {
  const [cards, setCards] = useState(imgList);
  const [selected, setSelected] = useState<String>();
  const findCard = useCallback(
    (id: string) => {
      const card = cards.filter((c) => `${c.board_document_id}` === id)[0];
      return {
        card,
        index: cards.indexOf(card),
      };
    },
    [cards]
  );

  const moveCard = useCallback(
    (id: string, atIndex: number) => {
      const { card, index } = findCard(id);
      setCards(
        update(cards, {
          $splice: [
            [index, 1],
            [atIndex, 0, card],
          ],
        })
      );
      if (callback)
        callback(
          "2",
          update(cards, {
            $splice: [
              [index, 1],
              [atIndex, 0, card],
            ],
          })
        );
    },
    [findCard, cards, setCards, callback]
  );

  const [, drop] = useDrop(() => ({ accept: ItemTypes.CARD }));
  const ClickCallback = (id: any) => {
    setSelected(id);
    const { index } = findCard(id);
    if (callback) callback("1", index);
  };

  return (
    <div ref={drop} className="App-Image-DropArea">
      {cards.map((card, index) => (
        <div
          key={"img_show_" + index}
          className={
            selected === `${card.board_document_id}` ||
            (!selected && index === 0)
              ? "App-Image-DropItem-Selected "
              : "App-Image-DropItem"
          }
        >
          <Card
            key={card.board_document_id}
            id={`${card.board_document_id}`}
            url={card.file_url}
            moveCard={moveCard}
            findCard={findCard}
            className={""}
            onClick={ClickCallback}
          />
        </div>
      ))}
    </div>
  );
};
