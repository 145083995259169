import { apigw } from "../awsApi";
import { Dispatch } from "@reduxjs/toolkit";

/**
 * 授業資料削除処理
 * @param {Dispatch} dispatch           reducerディスパッチ
 * @param {string} delMode              削除種別
 *                                      ("1"=ボード資料個別削除
 *                                       "2"=動画資料個別削除
 *                                       "3"=ダウンロード資料個別削除
 *                                       "4"=ボード資料全件削除
 *                                       "5"=動画資料全件削除
 *                                       "6"=ダウンロード資料全件削除)
 * @param {string} classId              授業ID
 * @param {number} boardDocumentId      ボード資料ID
 * @param {string} videoId              動画ID
 * @param {string} downloadDocumentId   ダウンロード資料ID
 * @return コンポーネントHTMLコード内容
 */
export const CT0304 = async (
  dispatch: Dispatch,
  delMode: string,
  classId: null | string,
  boardDocumentId: null | string,
  videoId: null | string,
  downloadDocumentId: null | string
) => {
  let pama: any = { del_mode: delMode };
  if (classId) pama["class_id"] = classId;
  if (boardDocumentId) pama["board_document_id"] = boardDocumentId;
  if (videoId) pama["video_id"] = videoId;
  if (downloadDocumentId) pama["download_document_id"] = downloadDocumentId;

  return apigw("/CT0304", pama, dispatch);
};
