import React, { useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { DataTable } from "../../components/table/DataTable";
import AddBoxIcon from "@material-ui/icons/AddBox";
import { store } from "../../reducks/store";
import { useDispatch } from "react-redux";
import { LC0301 } from "../../utils/api/LC0301";
import { TG0301 } from "../../utils/api/TG0301";
import { getSessionUser } from "../../utils/authFlow";
import { LoadingBox } from "../../components/atoms/LoadingBox";
import {
  createS3,
  // getFileBase64S3,
  getUrlS3,
} from "../../components/function/AwsS3Fun";
import { FormatMessage } from "../../components/atoms/FormatMessage";
/**
 * タグ一覧
 * @param {object} props 引き継いだオブジェクト
 * @return コンポーネントHTMLコード内容
 */
let vttFileName = "telop.vtt";
type telopType = {
  listIndex: number;
  id: string;
  start_time: any;
  display_time: any;
  text: string;
  start_Datetime: Date;
  end_Datetime: Date;
};

export const Cls019 = (props: any) => {
  const { Callback } = props;
  const classId: string = props.class_id;
  const movieTime = props.movie_time;
  const userIndo: any = store.getState().user;
  const filePath: string =
    "haishin/" + userIndo.service_id + "/" + classId + "/movie";
  const [telopList, setShowData] = React.useState<telopType[]>([]);
  const [loading, setLoading] = React.useState(true);
  const nowTime = new Date().toLocaleDateString();
  const dispatch = useDispatch();

  const items: any = [
    {
      id: "id",
      name: "",
      func: (e: any) => tableClick(e),
      align: "left",
      width: "10%",
      minWidth: "50px",
    },
    {
      id: "start_time",
      name: FormatMessage({ id: "Cls019.Label.Display_Start" }),
      align: "left",
      width: "15%",
    },
    {
      id: "display_time",
      name: FormatMessage({ id: "Cls019.Label.Display_Time" }),
      align: "left",
      width: "15%",
    },
    {
      id: "text",
      name: FormatMessage({ id: "Cls019.Label.Trop" }),
      align: "left",
      width: "60%",
    },
  ];

  const message = {
    edit: FormatMessage({ id: "Cls019.Button.Edit" }),
  };

  const tableClick = (data: any) => {
    data.state = "add_edit";
    telopList.splice(data.listIndex, 1);
    data.telopList = telopList;
    data.nowTime = nowTime;
    data.class_id = classId;
    data.movieTime = movieTime;
    Callback(data);
  };

  const GetVttData = async () => {
    const s3 = await createS3();
    if (s3) {
      let fileurl: any = await getUrlS3(s3, filePath + "/" + vttFileName);
      const blob = await (await fetch(fileurl)).blob();
      const file = new File([blob], "telop.vtt");

      if (file && file.size > 0) {
        let reader = new FileReader();
        reader.readAsText(file);
        reader.onload = function () {
          if (reader.result) {
            let data: telopType[] = [];
            const result = (reader.result as string).split("\r\n");
            if (result && result.length > 1) {
              let i = 0;
              let item: telopType = {
                listIndex: -1,
                id: message.edit,
                start_time: "",
                display_time: "",
                text: "",
                start_Datetime: new Date(),
                end_Datetime: new Date(),
              };

              for (let index = 0; index < result.length; index++) {
                const TimeSet = result[index].split(" ");
                if (
                  TimeSet.length === 4 &&
                  (result[index].indexOf("<c.white.background-black>") < 0 ||
                    result[index].indexOf("</c>") < 0)
                ) {
                  const startTime = TimeSet[0];
                  const endTime = TimeSet[2];
                  const startTime_r = startTime.replace(".000", "");
                  const endTime_r = endTime.replace(".000", "");
                  const startDateTime = new Date(nowTime + " " + startTime_r);
                  const endDateTime = new Date(nowTime + " " + endTime_r);
                  item.start_Datetime = startDateTime;
                  item.end_Datetime = endDateTime;
                  const dispTime =
                    (endDateTime.getTime() - startDateTime.getTime()) / 1000;
                  item.start_time = startTime.split(".")[0];
                  item.display_time = dispTime;
                }
                if (
                  result[index].indexOf("<c.white.background-black>") >= 0 ||
                  result[index].indexOf("</c>") >= 0
                ) {
                  if (item.text !== "") item.text += "\r\n";

                  item.text += result[index]
                    .replace("<c.white.background-black>", "")
                    .replace("</c>", "");
                }
                if (result[index].indexOf("</c>") >= 0) {
                  console.log("item", item);
                  item.listIndex = i;
                  data.push(item);
                  item = {
                    listIndex: -1,
                    id: message.edit,
                    start_time: "",
                    display_time: "",
                    text: "",
                    start_Datetime: new Date(),
                    end_Datetime: new Date(),
                  };
                  i++;
                }
              }
            }
            setShowData(data);
          }
          setLoading(false);
        };
      } else {
        setLoading(false);
      }
    }
  };

  // 初回読み込み
  useEffect(() => {
    setLoading(true);
    GetVttData();
  }, []);

  return (
    <div className="App-paper-root">
      <Grid item xs={12} className="App-pTB10 App-pLR10">
        <Typography variant="h6">
          {FormatMessage({ id: "Cls001.Label.text10" })}
        </Typography>
        <Grid item xs={12} style={{ marginTop: "10px" }}>
          <div>{FormatMessage({ id: "Cls019.Label.text1" })}</div>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6} style={{ display: "flex" }}>
            <div style={{ margin: "10px" }}>
              {FormatMessage({ id: "Cls020.Label.Class_Time" })}
            </div>
            <div style={{ margin: "10px" }}>{movieTime}</div>
          </Grid>
          <Grid item xs={6} className="App-alignRight">
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                Callback({
                  state: "add_edit",
                  telopList,
                  nowTime: nowTime,
                  class_id: classId,
                  movieTime: movieTime,
                });
              }}
              startIcon={<AddBoxIcon />}
            >
              {FormatMessage({ id: "Button.NewRegister" })}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <DataTable rows={telopList} items={items} />
      </Grid>
      {loading ? <LoadingBox /> : null}
    </div>
  );
};
