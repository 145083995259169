import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { MemoryRouter } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HomeIcon from "@material-ui/icons/Home";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import BarChartIcon from "@material-ui/icons/BarChart";

import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { FormatMessage } from "../components/atoms/FormatMessage";
import { signOut } from "../reducks/userSlice";
import Tooltip from "@material-ui/core/Tooltip";
import sanitize from "sanitize-html";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: 258,
  },
  lists: {
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(1),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export const Menu = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const onClickLink = (url: string) => {
    if (url === "/logout") {
      dispatch(signOut());
    } else {
      dispatch(push(sanitize(url)));
    }
  };

  const userTypeFlg = localStorage.getItem("userTypeFlg")
    ? localStorage.getItem("userTypeFlg")
    : "2";

  let menuMap = [
    {
      id: "top",
      name: FormatMessage({ id: "Menu.Top" }),
      url: "/top/001",
      icon: <HomeIcon />,
    },
    {
      id: "count_info",
      name: FormatMessage({ id: "Menu.CountInfo" }),
      url: "/set/001",
      icon: <AccountCircleIcon />,
    },
    {
      id: "logout",
      name: FormatMessage({ id: "Menu.Logout" }),
      url: "/logout",
      icon: <FontAwesomeIcon icon={faSignOutAlt} />,
    },
  ];

  if (userTypeFlg !== "1") {
    menuMap = [
      {
        id: "top",
        name: FormatMessage({ id: "Menu.Top" }),
        url: "/top/002",
        icon: <HomeIcon />,
      },
      {
        id: "count_info",
        name: FormatMessage({ id: "Menu.CountInfo" }),
        url: "/set/005",
        icon: <AccountCircleIcon />,
      },
      {
        id: "course_history",
        name: FormatMessage({ id: "Menu.CourseHistory" }),
        url: "/cls/018",
        icon: <BarChartIcon />,
      },
      {
        id: "logout",
        name: FormatMessage({ id: "Menu.Logout" }),
        url: "/logout",
        icon: <FontAwesomeIcon icon={faSignOutAlt} />,
      },
    ];
  }

  return (
    <MemoryRouter initialEntries={["/top/001"]} initialIndex={0}>
      <div className={classes.root}>
        <nav className={classes.lists} aria-label="mailbox folders">
          <List>
            {menuMap.map((menu, index) => (
              <ListItem
                button
                key={menu.id}
                onClick={() => onClickLink(menu.url)}
              >
                <Tooltip title={menu.name} placement="right">
                  <ListItemIcon style={{ fontSize: 24 + "px" }}>
                    {menu.icon}
                  </ListItemIcon>
                </Tooltip>
                <ListItemText primary={menu.name} />
              </ListItem>
            ))}
          </List>
        </nav>
      </div>
    </MemoryRouter>
  );
};
