import { apigw } from "../awsApi";
import { Dispatch } from "@reduxjs/toolkit";

//サンプルデータ
const state = 0;

/**
 * 択一問題回答登録
 * @param {Dispatch} dispatch     reducerディスパッチ
 * @param {string} classId        授業ID
 * @param {string} choiceId       択一問題ID
 * @param {string} answerTime     回答時間
 * @param {string} answerNo       回答No
 * @return コンポーネントHTMLコード内容
 */

export const QZ0305 = async (
  dispatch: Dispatch,
  classId: string,
  choiceId: number,
  answerTime: string,
  answerNo: number,
  answer: string,
  currentTime: number,
) => {
  if (process.env.REACT_APP_API_USE_FLG === "true") {
    return apigw(
      "/QZ0305",
      {
        class_id: classId,
        choice_id: choiceId,
        answer_time: answerTime,
        answer_no: answerNo,
        answer:answer,
        currentTime: currentTime,
      },
      dispatch
    );
  } else {
    //サンプルデータ用
    return { state };
  }
};
