import { apigw } from "../awsApi";
import { Dispatch } from "@reduxjs/toolkit";


/**
 * RTM接続情報取得
 * @param {Dispatch} dispatch reducerディスパッチ
 * @param {string} userId 仮ユーザID
 * @param {string} ChannelI 仮チャンネルID
 * @return コンポーネントHTMLコード内容
 */
export const ST0325 = async (
    dispatch: Dispatch,
    userId:string,  
    ChannelId: string,
  ) => {
  if (process.env.REACT_APP_API_USE_FLG === "true") {
    return apigw("/ST0325", { 
        channel_id:ChannelId,
        user_id:userId
 }, dispatch);
  }
};