import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";

import { MainLayout } from "../../layouts/MainLayout";
import { FormatMessage } from "../../components/atoms/FormatMessage";
import { store } from "../../reducks/store";
import { LC0305 } from "../../utils/api/LC0305";
import { Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { push } from "connected-react-router";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import sanitize from "sanitize-html";

const useStyles = makeStyles((theme) => ({
  headerIcon: {
    fontSize: "23px",
    color: "#3498db",
  },
  b50: {
    float: "left",
    margin: "10px 0 0 0",
  },
  b60: {
    float: "left",
    margin: "0 0 0 20px",
  },
  b70: {
    float: "left",
    margin: "10px 0 0 20px",
    fontWeight: 700,
  },
}));

/**
 * 授業後外部URL表示
 * @param {object} props 引き継いだオブジェクト
 * @return コンポーネントHTMLコード内容
 */
export const Cls017 = (props: any) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  //授業一覧データ
  const [pageAfterUrl, setPageAfterUrl] = React.useState("");

  //ページリロード対応
  let sessionDateValue = localStorage.getItem("Cls017_session");
  const getDateValue =
    typeof props.location.state === "undefined"
      ? sessionDateValue
      : props.location.state;
  if (typeof props.location.state !== "undefined")
    localStorage.setItem("Cls017_session", props.location.state);

  //データ処理
  const getListData = async (callback: any) => {
    const userData = store.getState().user;
    if (userData && Object.keys(getDateValue).indexOf("class_id") >= 0) {
      let getData = await LC0305(dispatch, getDateValue.class_id);
      if (getData && Object.keys(getData).indexOf("body") >= 0) {
        setPageAfterUrl(getData.body.page_after_url);
      }
    }
  };

  //初期処理
  useEffect(() => {
    //データ取得
    getListData(null);
  }, []);

  //トップに戻るボタン
  const backTopClick = () => {
    //localStorage.removeItem("playclass");
    localStorage.removeItem("websdk_ng_global_parameter");
    //localStorage.removeItem("ClassClose");
    localStorage.removeItem("Cls012_session");
    localStorage.removeItem("Cls013_session");
    localStorage.removeItem("Cls017_session");
    //window.open("about:blank", "_self")?.close();
    dispatch(push("/top/002"));
  };

  return (
    <MainLayout>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className="App-paper">
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
            >
              <Grid className="App-mLR10">
                <Button
                  variant="contained"
                  color="primary"
                  className="App-button_bottom"
                  startIcon={<ArrowBackIcon />}
                  onClick={backTopClick}
                >
                  {FormatMessage({ id: "Cls017.Button.back" })}
                </Button>
              </Grid>
            </Grid>
            <Link
              className={classes.headerIcon}
              href={pageAfterUrl}
              target="_blank"
              style={{ whiteSpace: "pre-wrap", overflowWrap: "break-word" }}
            >
              {pageAfterUrl}
            </Link>
            <Grid className={classes.b70}>
              {FormatMessage({ id: "Cls017.Label.text1" })}
            </Grid>
            <Grid className={classes.b60}>
              {FormatMessage({ id: "Cls017.Label.text2" })}
            </Grid>
            <Grid className={classes.b60}>
              {FormatMessage({ id: "Cls017.Label.text3" })}
            </Grid>
            <Grid>
              <div className={classes.b70}>
                {FormatMessage({ id: "Cls017.Label.text4" })}
              </div>
              <div className={classes.b50}>
                {FormatMessage({ id: "Cls017.Label.text5" })}
              </div>
            </Grid>
            <ul>
              <li>
                {
                  //改行コードで分割
                  FormatMessage({ id: "Cls017.Label.text6" })
                    .split("\n")
                    .map((str) => {
                      return <div>{str}</div>;
                    })
                }
              </li>
              <li>
                {
                  //改行コードで分割
                  FormatMessage({ id: "Cls017.Label.text7" })
                    .split("\n")
                    .map((str) => {
                      return <div>{str}</div>;
                    })
                }
              </li>
            </ul>
          </Paper>
        </Grid>
      </Grid>
    </MainLayout>
  );
};
