import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

import { DataTable } from "../../components/table/DataTable";
import { FormatMessage } from "../../components/atoms/FormatMessage";

import { CQ0302 } from "../../utils/api/CQ0302";
import { CQ0301 } from "../../utils/api/CQ0301";
import { OQ0301 } from "../../utils/api/OQ0301";

import { downloadCsv } from "../../components/function/Function";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import GetAppIcon from "@material-ui/icons/GetApp";
import { PaginationBox } from "../../components/atoms/PaginationBox";

//ページング
let PageNo = 1;
let itemCount = 0;
let totalCount = 0;
let pageCount = 0;

/**
 * アンケート結果
 * @param {object} props 引き継いだオブジェクト
 * @return コンポーネントHTMLコード内容
 */
export const Sts004 = (props: any) => {
  // 【 変数・定数宣言】
  const CHARACTER_CLASS_WRITE = "1";
  const CHARACTER_CLASS_CHECKBOX = "3";
  const ENQUETE_CLASS_DEFAULT = "1";
  const ENQUETE_CLASS_CUSTOM = "2";

  const pageMaxCount = 100;

  const dispatch = useDispatch();

  const [total_count, setTotalCount] = React.useState(0);
  // const [classId, setClassId] = React.useState("");
  const [questionNo, setQuestionNo] = React.useState("");
  const [question, setQuestion] = React.useState("");
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const [maxIndex, setMaxIndex] = React.useState(0);
  // const [targetQuestionNo, setTargetQuestionNo] = React.useState("1");
  const [maxQuestionNo, setMaxQuestionNo] = React.useState("");
  const [minQuestionNo, setMinQuestionNo] = React.useState("");

  const [activeStep, setActiveStep] = React.useState(0);
  // const [useDefault, setUseDefault] = React.useState(true);

  //アンケート結果データ
  type enqueteRowsType = {
    enquete_class: string; //アンケートタイプ
    input_date: string; //登録日時
    user_id: string; //視聴ユーザID
    user_name: string; //視聴ユーザ氏名
    handle_name: string; //ハンドルネーム
    answer: string; //回答
  };

  const [enqueteRows, setEnqueteRows] = React.useState<enqueteRowsType[]>([]);

  //アンケート結果CSVデータ
  type csvEnqueteRowsType = {
    class_id: string; // 授業ID
    input_date: string; // 回答日時　登録日時
    user_id: string; //視聴ユーザID
    user_name: string; //視聴ユーザ氏名
    handle_name: string; //ハンドルネーム
    // enquete_class: string; //アンケートタイプ
    questionNo: string; // 質問No
    question: string; // 質問
    optionNo: string; // 回答番号
    answer: string; //回答
  };

  // エスケープ処理
  const CSV_ITEM_SURROUND = '"';
  const escapeForCSV = (s: string) => {
    return CSV_ITEM_SURROUND + s + CSV_ITEM_SURROUND;
  };

  const csvFileName = FormatMessage({ id: "Sts004.FileName1" }); // CSVファイル名
  const csvHeaderEnqueteRows = {
    class_id: escapeForCSV(FormatMessage({ id: "Sts004.Label.text11" })), // 授業ID
    input_date: escapeForCSV(FormatMessage({ id: "Sts004.Label.text1" })), // 回答日時　登録日時
    user_id: escapeForCSV(FormatMessage({ id: "Sts004.Label.text12" })), //視聴ユーザID
    user_name: escapeForCSV(FormatMessage({ id: "Sts004.Label.text3" })), //視聴ユーザ名
    handle_name: escapeForCSV(FormatMessage({ id: "Sts004.Label.text4" })), //ハンドルネーム
    questionNo: escapeForCSV(FormatMessage({ id: "Sts004.Label.text13" })), // 質問No
    question: escapeForCSV(FormatMessage({ id: "Sts004.Label.text14" })), // 質問
    optionNo: escapeForCSV(FormatMessage({ id: "Sts004.Label.text15" })), // 回答番号
    answer: escapeForCSV(FormatMessage({ id: "Sts004.Label.text6" })), //回答
  };

  // 質問データ
  type Rows = {
    questionNo: string; // 質問No
    question: string; // 質問（内容）
    characterClass: string; // 回答方式 1:記述式、2:ラジオボタン、3:チェックボックス、4:プルダウン
    isRequired: string; // 必須フラグ
    updateDate: Date;
  }[];
  const [rows, setRows] = React.useState<Rows>([]);

  // 集計用
  type enqueteSurveyType = {
    answer: string;
    count: string;
    per: string;
  };
  const [enqueteSurvey, setEnqueteSurvey] = React.useState<enqueteSurveyType[]>(
    []
  );

  const enqueteSurveyitems = [
    {
      id: "answer",
      name: FormatMessage({ id: "Sts004.Label.text7" }),
      width: 25,
    },
    {
      id: "count",
      name: FormatMessage({ id: "Sts004.Label.text8" }),
      width: 25,
    },
    {
      id: "per",
      name: FormatMessage({ id: "Sts004.Label.text9" }),
      width: 25,
    },
  ];

  const messages = {
    answerDate: FormatMessage({ id: "Sts004.Label.text1" }),
    userId: FormatMessage({ id: "Sts004.Label.text2" }),
    userName: FormatMessage({ id: "Sts004.Label.text3" }),
    handleName: FormatMessage({ id: "Sts004.Label.text4" }),
    option: FormatMessage({ id: "Sts004.Label.text5" }),
    answer: FormatMessage({ id: "Sts004.Label.text6" }),
  };

  //日付から文字列に変換する関数
  const getStringFromDate = (date: Date) => {
    const year_str: number = date.getFullYear();
    //月だけ+1すること
    const month_str: number = 1 + date.getMonth();
    const day_str: number = date.getDate();
    const hour_str: number = date.getHours();
    const minute_str: number = date.getMinutes();
    const second_str: number = date.getSeconds();

    let format_str = "YYYY/MM/DD hh:mm:ss";
    format_str = format_str.replace(/YYYY/g, String(year_str));
    format_str = format_str.replace(
      /MM/g,
      String(month_str).length === 1
        ? "0" + String(month_str)
        : String(month_str)
    );
    format_str = format_str.replace(
      /DD/g,
      String(day_str).length === 1 ? "0" + String(day_str) : String(day_str)
    );
    format_str = format_str.replace(
      /hh/g,
      String(hour_str).length === 1 ? "0" + String(hour_str) : String(hour_str)
    );
    format_str = format_str.replace(
      /mm/g,
      String(minute_str).length === 1
        ? "0" + String(minute_str)
        : String(minute_str)
    );
    format_str = format_str.replace(
      /ss/g,
      String(second_str).length === 1
        ? "0" + String(second_str)
        : String(second_str)
    );

    return format_str;
  };

  // 【 処理 】
  // 検索処理
  const getListData = async (
    callback: any,
    target_index: number,
    first_flg: boolean,
    target_question_no: string
  ) => {
    props.setParentLoading(true);
    // 0:サービス設定にしたがう 1:デフォルトアンケート、2:外部URL、3:カスタムアンケート、4:表示しない
    //アンケートタイプがデフォルトアンケートの場合
    if (props.page_after_class === "0" || props.page_after_class === "1") {
      // setUseDefault(true);
      await CQ0301(dispatch, null).then((getDefData) => {
        if (
          getDefData &&
          Object.keys(getDefData.body).indexOf("question") >= 0
        ) {
          if (getDefData.body.question.length > 0) {
            let currentQuesitonNo: string;
            const sortList = getDefData.body.question.sort(function (
              a: any,
              b: any
            ) {
              return Number(a.question_no) - Number(b.question_no);
            });
            setMaxIndex(Number(getDefData.body.question.length - 1));

            //質問順並び替え
            getDefData.body.question.sort(function (a: any, b: any) {
              if (a.question_no !== b.question_no) {
                if (Number(a.question_no) === null) return 1;
                if (Number(a.question_no) > Number(b.question_no)) return 1;
                if (Number(a.question_no) < Number(b.question_no)) return -1;
              }
              return 0;
            });
            if (first_flg) {
              // 最大・最小質問No設定
              setCurrentIndex(0);
              setMinQuestionNo(getDefData.body.question[0].question_no);
              setMaxQuestionNo(
                getDefData.body.question[
                  Number(getDefData.body.question.length - 1)
                ].question_no
              ); //getDefData.body.question.length
              currentQuesitonNo = getDefData.body.question[0].question_no;
              setQuestionNo(getDefData.body.question[0].question_no);
            } else {
              setCurrentIndex(target_index);
              currentQuesitonNo =
                getDefData.body.question[target_index].question_no;
              setQuestionNo(getDefData.body.question[target_index].question_no);
            }

            setRows(
              getDefData.body.question.map(
                // eslint-disable-next-line array-callback-return
                (getDataItem: any, index: number) => {
                  // 質問Noが一致する質問データをセット
                  if (getDataItem.question_no === currentQuesitonNo) {
                    // 質問データのアンケート形式取得
                    setQuestion(getDataItem.question);
                    if (getDataItem.question_class === "1") {
                      //記述式
                      setActiveStep(1);
                    } else {
                      //ラジオ、チェック、プルダウン
                      setActiveStep(0);
                    }
                    const options = getDataItem.option[0];
                    let optionsIndex = 1;
                    let optionsArray = [];
                    while (
                      Object.keys(options).indexOf("option_" + optionsIndex) >=
                      0
                    ) {
                      if (options["option_" + optionsIndex]) {
                        optionsArray.push(options["option_" + optionsIndex]);
                      }
                      optionsIndex++;
                    }
                    return {
                      questionNo: getDataItem.question_no, // 質問No
                      question: getDataItem.question, // 質問（内容）
                      characterClass: getDataItem.character_class, // 回答方式 1:記述式、2:ラジオボタン、3:チェックボックス、4:プルダウン
                      isRequired: getDataItem.is_required, // 必須フラグ
                      options: optionsArray,
                      updateDate: getDataItem.update_date,
                    };
                  }
                }
              )
            );
            // アンケート結果を取得
            CQ0302(
              dispatch,
              props.class_id,
              currentQuesitonNo,
              target_question_no,
              ENQUETE_CLASS_DEFAULT,
              PageNo
            )
              .then((getData) => {
                if (
                  getData &&
                  Object.keys(getData).indexOf("body") >= 0 &&
                  Object.keys(getData.body).length > 0 &&
                  Object.keys(getData.body).indexOf("results") >= 0 &&
                  Object.keys(getData.body.results).length > 0
                ) {
                  if (Object.keys(getData.body).indexOf("total_count") >= 0) {
                    setTotalCount(getData.body.total_count);
                  }
                  itemCount = getData.body.results.length;
                  totalCount = getData.body.total_count;
                  pageCount = getData.body.total_page;

                  // アンケート結果セット
                  // チェックボックスの場合は回答選択肢をカンマで区切ってまとめる
                  if (
                    getDefData.body.question[target_index].character_class ===
                    CHARACTER_CLASS_CHECKBOX
                  ) {
                    setEnqueteRows(groupByAnswer(getData.body.results));
                  } else {
                    setEnqueteRows(getData.body.results);
                  }
                  // 記述式以外の時は集計する
                  if (
                    getDefData.body.question[target_index].character_class !==
                    CHARACTER_CLASS_WRITE
                  ) {
                    surveyEnqueate(getData.body.results);
                  }
                } else {
                  setTotalCount(0);
                  surveyEnqueate([]);
                }
              })
              .finally(() => {
                props.setParentLoading(false);
              });
          }
        }
      });
    }
    //アンケートタイプがカスタムアンケートの場合
    else {
      // setUseDefault(false);
      await OQ0301(dispatch, props.class_id, null)
        .then((getCusData) => {
          if (
            getCusData.body &&
            Object.keys(getCusData.body).indexOf("question") >= 0
          ) {
            if (getCusData.body.question.length > 0) {
              var currentQuesitonNo: string;
              setMaxIndex(Number(getCusData.body.question.length - 1));

              //質問順並び替え
              getCusData.body.question.sort(function (a: any, b: any) {
                if (a.question_no !== b.question_no) {
                  if (Number(a.question_no) === null) return 1;
                  if (Number(a.question_no) > Number(b.question_no)) return 1;
                  if (Number(a.question_no) < Number(b.question_no)) return -1;
                }
                return 0;
              });

              if (first_flg) {
                // 最大・最小質問No設定
                setCurrentIndex(0);
                setMinQuestionNo(getCusData.body.question[0].question_no);
                setMaxQuestionNo(
                  getCusData.body.question[
                    Number(getCusData.body.question.length - 1)
                  ].question_no
                );
                currentQuesitonNo = getCusData.body.question[0].question_no;
                setQuestionNo(getCusData.body.question[0].question_no);
              } else {
                setCurrentIndex(target_index);
                currentQuesitonNo =
                  getCusData.body.question[target_index].question_no;
                setQuestionNo(
                  getCusData.body.question[target_index].question_no
                );
              }
              setRows(
                getCusData.body.question.map(
                  // eslint-disable-next-line array-callback-return
                  (getDataItem: any, index: number) => {
                    // 質問Noが一致する質問データをセット
                    if (getDataItem.question_no === currentQuesitonNo) {
                      // 質問データのアンケート形式取得
                      setQuestion(getDataItem.question);
                      if (
                        getDataItem.character_class === CHARACTER_CLASS_WRITE
                      ) {
                        //記述式
                        setActiveStep(1);
                      } else {
                        //ラジオ、チェック、プルダウン
                        setActiveStep(0);
                      }
                      const options = getDataItem.option[0];
                      let optionsIndex = 1;
                      let optionsArray = [];
                      while (
                        Object.keys(options).indexOf(
                          "option_" + optionsIndex
                        ) >= 0
                      ) {
                        if (options["option_" + optionsIndex]) {
                          optionsArray.push(options["option_" + optionsIndex]);
                        }
                        optionsIndex++;
                      }
                      return {
                        questionNo: getDataItem.question_no, // 質問No
                        question: getDataItem.question, // 質問（内容）
                        characterClass: getDataItem.CHARACTER_CLASS, // 回答方式 1:記述式、2:ラジオボタン、3:チェックボックス、4:プルダウン
                        isRequired: getDataItem.is_required, // 必須フラグ
                        options: optionsArray,
                        updateDate: getDataItem.update_date,
                      };
                    }
                  }
                )
              );
              // アンケート結果を取得
              CQ0302(
                dispatch,
                props.class_id,
                currentQuesitonNo,
                target_question_no,
                ENQUETE_CLASS_CUSTOM,
                PageNo
              )
                .then((getData) => {
                  if (
                    getData &&
                    Object.keys(getData).indexOf("body") >= 0 &&
                    Object.keys(getData.body).length > 0 &&
                    Object.keys(getData.body).indexOf("results") >= 0 &&
                    Object.keys(getData.body.results).length > 0
                  ) {
                    if (Object.keys(getData.body).indexOf("total_count") >= 0) {
                      setTotalCount(getData.body.total_count);
                    }
                    itemCount = getData.body.results.length;
                    totalCount = getData.body.total_count;
                    pageCount = getData.body.total_page;

                    // アンケート結果セット
                    // チェックボックスの場合は回答選択肢をカンマで区切ってまとめる
                    if (
                      getCusData.body.question[target_index].character_class ===
                      CHARACTER_CLASS_CHECKBOX
                    ) {
                      setEnqueteRows(groupByAnswer(getData.body.results));
                    } else {
                      setEnqueteRows(getData.body.results);
                    }
                    // 記述式以外の時は集計する
                    if (
                      getCusData.body.question[target_index].character_class !==
                      CHARACTER_CLASS_WRITE
                    ) {
                      surveyEnqueate(
                        getData.body.results,
                        getData.body.group_list
                      );
                    }
                  } else {
                    setTotalCount(0);
                    surveyEnqueate([]);
                  }
                })
                .finally(() => {
                  props.setParentLoading(false);
                });
            }
          }
        })
        .finally(() => {
          props.setParentLoading(false);
        });
    }

    if (callback) callback();
  };
  // チェックボックスのマージ
  const groupByAnswer = (
    arr: any,
    isCsv: boolean = false,
    getEnqueteData: any = {},
    currentQuestionNo: string = "",
    targetIndex: number = 0
  ) => {
    let result: any = [];
    arr.reduce((prev: any, current: any) => {
      let find = result.find((p: any) => {
        return (
          p.user_id === current.user_id &&
          getStringFromDate(new Date(p.input_date.slice(0, 19))) ===
            getStringFromDate(new Date(current.input_date.slice(0, 19)))
        );
      });
      if (find) {
        find.answer = find.answer + "," + current.answer; // sum
        find.optionNo = find.optionNo + "," + current.option_no;
      } else {
        if (isCsv) {
          result.push({
            class_id: props.class_id, // 授業ID
            input_date: getStringFromDate(
              new Date(current.input_date.slice(0, 19))
            ), // 回答日時　登録日時
            user_id: current.user_id, //視聴ユーザID
            user_name: current.user_name, //視聴ユーザ氏名
            handle_name: current.handle_name, //ハンドルネーム
            // enquete_class: "2", //アンケートタイプ
            questionNo: currentQuestionNo, // 質問No
            question: getEnqueteData.body.question[targetIndex].question, // 質問
            optionNo: Boolean(current.option_no) ? current.option_no : "", // 回答番号
            answer: current.answer, //回答
          });
        } else {
          result.push({
            answer: current.answer,
            enquete_class: current.enquete_class,
            handle_name: current.handle_name,
            input_date: current.input_date,
            user_id: current.user_id,
            user_name: current.user_name,
          });
        }
      }
      return prev;
    }, []);

    return result;
  };

  // 集計処理
  const surveyEnqueate = (enquete_rows: any[] = [], group_list?: any) => {
    // var answerList: any[] = [];
    // enquete_rows.forEach((data: any) => {
    //   if (answerList.indexOf(data.answer) === -1) answerList.push(data.answer);
    // });
    // const groupby = answerList.map((answer: any) => {
    //   const list = enquete_rows.filter((r) => r.answer === answer);
    //   return {
    //     answer: answer,
    //     count: list.reduce((sum, r) => sum + 1, 0),
    //     per: `${
    //       Math.round(
    //         (list.reduce((sum, r) => sum + 1, 0) / totalCount) *
    //           100 *
    //           10
    //       ) / 10
    //     } % `,
    //   };
    // });
    if (group_list) {
      var answer: any = {};
      enquete_rows.forEach((data: any) => {
        if (!answer[data.option_no]) answer[data.option_no] = data.answer;
      });
      const groupby = group_list.map((data: any) => {
        return {
          answer: answer[data.option_no],
          count: data.question_count,
          per: `${
            Math.round((data.question_count / totalCount) * 100 * 10) / 10
          } % `,
        };
      });
      setEnqueteSurvey(groupby);
    }
  };

  // 初期処理
  useEffect(() => {
    //データ取得
    getListData(null, 0, true, "1");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //最初の質問ボタン
  const firstQuestionClick = () => {
    PageNo = 1;
    setEnqueteRows([]);
    getListData(null, 0, false, "1");
  };

  //前の質問ボタン
  const previousQuestionClick = () => {
    if (Number(questionNo) - 1 >= Number(minQuestionNo)) {
      PageNo = 1;
      setEnqueteRows([]);
      getListData(null, currentIndex - 1, false, "0");
    }
  };

  //次の質問ボタン
  const nextQuestionClick = () => {
    if (Number(questionNo) + 1 <= Number(maxQuestionNo)) {
      PageNo = 1;
      setEnqueteRows([]);
      getListData(null, currentIndex + 1, false, "0");
    }
  };

  //最後の質問ボタン
  const endQuestionClick = () => {
    PageNo = 1;
    setEnqueteRows([]);
    getListData(null, maxIndex, false, "0");
  };

  //csvダウンロードボタン
  const csvDownloadClick = async () => {
    props.setParentLoading(true);
    // 0:サービス設定にしたがう 1:デフォルトアンケート、2:外部URL、3:カスタムアンケート、4:表示しない
    //アンケートタイプがデフォルトアンケートの場合
    let enqueteArr: any = [];
    if (props.page_after_class === "0" || props.page_after_class === "1") {
      let getDefData = await CQ0301(dispatch, null);
      if (getDefData && Object.keys(getDefData.body).indexOf("question") >= 0) {
        if (getDefData.body.question.length > 0) {
          const sortList = getDefData.body.question.sort(function (
            a: any,
            b: any
          ) {
            return Number(a.question_no) - Number(b.question_no);
          });

          //質問順並び替え
          getDefData.body.question.sort(function (a: any, b: any) {
            if (a.question_no !== b.question_no) {
              if (Number(a.question_no) === null) return 1;
              if (Number(a.question_no) > Number(b.question_no)) return 1;
              if (Number(a.question_no) < Number(b.question_no)) return -1;
            }
            return 0;
          });

          // アンケート結果を取得
          for (let i = 0; i < getDefData.body.question.length; i++) {
            let currentQuesitonNo: string;
            currentQuesitonNo = getDefData.body.question[i].question_no;
            // アンケート結果を取得
            await CQ0302(
              dispatch,
              props.class_id,
              currentQuesitonNo,
              "0",
              ENQUETE_CLASS_DEFAULT,
              null
            ).then((getData) => {
              if (
                getData &&
                Object.keys(getData).indexOf("body") >= 0 &&
                Object.keys(getData.body).length > 0 &&
                Object.keys(getData.body).indexOf("results") >= 0 &&
                Object.keys(getData.body.results).length > 0
              ) {
                // アンケート結果セット
                // チェックボックスの場合は回答選択肢をカンマで区切ってまとめる
                if (
                  getDefData.body.question[i].character_class ===
                  CHARACTER_CLASS_CHECKBOX
                ) {
                  groupByAnswer(
                    getData.body.results,
                    true,
                    getDefData,
                    currentQuesitonNo,
                    i
                  ).forEach((groupedItem: any) => {
                    enqueteArr.push({
                      class_id: escapeForCSV(props.class_id), // 授業ID
                      input_date: escapeForCSV(groupedItem.input_date), // 回答日時　登録日時
                      user_id: escapeForCSV(groupedItem.user_id), //視聴ユーザID
                      user_name: escapeForCSV(groupedItem.user_name), //視聴ユーザ氏名
                      handle_name: escapeForCSV(groupedItem.handle_name), //ハンドルネーム
                      questionNo: escapeForCSV(currentQuesitonNo), // 質問No
                      question: escapeForCSV(groupedItem.question), // 質問
                      optionNo: escapeForCSV(groupedItem.optionNo), // 回答番号
                      answer: escapeForCSV(groupedItem.answer), //回答
                    });
                  });
                } else {
                  for (let r = 0; r < getData.body.results.length; r++) {
                    enqueteArr.push({
                      class_id: escapeForCSV(props.class_id), // 授業ID
                      input_date: escapeForCSV(
                        getStringFromDate(
                          new Date(
                            getData.body.results[r].input_date.slice(0, 19)
                          )
                        )
                      ), // 回答日時　登録日時
                      user_id: escapeForCSV(getData.body.results[r].user_id), //視聴ユーザID
                      user_name: escapeForCSV(
                        getData.body.results[r].user_name
                      ), //視聴ユーザ氏名
                      handle_name: escapeForCSV(
                        getData.body.results[r].handle_name
                      ), //ハンドルネーム
                      questionNo: escapeForCSV(currentQuesitonNo), // 質問No
                      question: escapeForCSV(
                        getDefData.body.question[i].question
                      ), // 質問
                      optionNo: Boolean(getData.body.results[r].option_no)
                        ? escapeForCSV(getData.body.results[r].option_no)
                        : "", // 回答番号
                      answer: escapeForCSV(getData.body.results[r].answer), //回答
                    });
                  }
                }
              }
            });
          }
        }
      }
    } else {
      //アンケートタイプがカスタムアンケートの場合
      let getCusData = await OQ0301(dispatch, props.class_id, null);
      if (
        getCusData.body &&
        Object.keys(getCusData.body).indexOf("question") >= 0
      ) {
        if (getCusData.body.question.length > 0) {
          //質問順並び替え
          getCusData.body.question.sort(function (a: any, b: any) {
            if (a.question_no !== b.question_no) {
              if (Number(a.question_no) === null) return 1;
              if (Number(a.question_no) > Number(b.question_no)) return 1;
              if (Number(a.question_no) < Number(b.question_no)) return -1;
            }
            return 0;
          });
          for (let i = 0; i < getCusData.body.question.length; i++) {
            let currentQuesitonNo: string;
            currentQuesitonNo = getCusData.body.question[i].question_no;
            // アンケート結果を取得
            await CQ0302(
              dispatch,
              props.class_id,
              currentQuesitonNo,
              "0",
              ENQUETE_CLASS_CUSTOM,
              null
            ).then((getData) => {
              if (
                getData &&
                Object.keys(getData).indexOf("body") >= 0 &&
                Object.keys(getData.body).length > 0 &&
                Object.keys(getData.body).indexOf("results") >= 0 &&
                Object.keys(getData.body.results).length > 0
              ) {
                // アンケート結果セット
                // チェックボックスの場合は回答選択肢をカンマで区切ってまとめる
                if (
                  getCusData.body.question[i].character_class ===
                  CHARACTER_CLASS_CHECKBOX
                ) {
                  groupByAnswer(
                    getData.body.results,
                    true,
                    getCusData,
                    currentQuesitonNo,
                    i
                  ).forEach((groupedItem: any) => {
                    enqueteArr.push({
                      class_id: escapeForCSV(props.class_id), // 授業ID
                      input_date: escapeForCSV(groupedItem.input_date), // 回答日時　登録日時
                      user_id: escapeForCSV(groupedItem.user_id), //視聴ユーザID
                      user_name: escapeForCSV(groupedItem.user_name), //視聴ユーザ氏名
                      handle_name: escapeForCSV(groupedItem.handle_name), //ハンドルネーム
                      questionNo: escapeForCSV(currentQuesitonNo), // 質問No
                      question: escapeForCSV(groupedItem.question), // 質問
                      optionNo: escapeForCSV(groupedItem.optionNo), // 回答番号
                      answer: escapeForCSV(groupedItem.answer), //回答
                    });
                  });
                } else {
                  for (let r = 0; r < getData.body.results.length; r++) {
                    enqueteArr.push({
                      class_id: escapeForCSV(props.class_id), // 授業ID
                      input_date: escapeForCSV(
                        getStringFromDate(
                          new Date(
                            getData.body.results[r].input_date.slice(0, 19)
                          )
                        )
                      ), // 回答日時　登録日時
                      user_id: escapeForCSV(getData.body.results[r].user_id), //視聴ユーザID
                      user_name: escapeForCSV(
                        getData.body.results[r].user_name
                      ), //視聴ユーザ氏名
                      handle_name: escapeForCSV(
                        getData.body.results[r].handle_name
                      ), //ハンドルネーム
                      questionNo: escapeForCSV(currentQuesitonNo), // 質問No
                      question: escapeForCSV(
                        getCusData.body.question[i].question
                      ), // 質問
                      optionNo: Boolean(getData.body.results[r].option_no)
                        ? escapeForCSV(getData.body.results[r].option_no)
                        : "", // 回答番号
                      answer: escapeForCSV(getData.body.results[r].answer), //回答
                    });
                  }
                }
              }
            });
          }
        }
      }
    }
    props.setParentLoading(false);
    downloadCsv(
      enqueteArr,
      csvFileName,
      [csvHeaderEnqueteRows],
      [],
      Object.keys(enqueteArr)
    );
  };

  return (
    <React.Fragment>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={4}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<GetAppIcon />}
            className="App-button_bottom"
            onClick={csvDownloadClick}
          >
            {FormatMessage({ id: "Button.Download" })}
          </Button>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
          >
            <Grid className="App-pLR5">
              <Button
                variant="contained"
                color="primary"
                className="App-button_bottom"
                onClick={firstQuestionClick}
              >
                {FormatMessage({ id: "Sts004.Button.text1" })}
              </Button>
            </Grid>
            <Grid className="App-pLR5">
              <Button
                variant="contained"
                color="primary"
                className="App-button_bottom"
                onClick={previousQuestionClick}
              >
                {FormatMessage({ id: "Sts004.Button.text2" })}
              </Button>
            </Grid>
            <Grid className="App-pLR5">
              <Button
                variant="contained"
                color="primary"
                className="App-button_bottom"
                onClick={nextQuestionClick}
              >
                {FormatMessage({ id: "Sts004.Button.text3" })}
              </Button>
            </Grid>
            <Grid className="App-pLR5">
              <Button
                variant="contained"
                color="primary"
                className="App-button_bottom"
                onClick={endQuestionClick}
              >
                {FormatMessage({ id: "Sts004.Button.text4" })}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {activeStep === 1 ? (
        // 記述式
        <div>
          <Grid container spacing={0}>
            <Grid xs={11} sm={1}>
              <Typography
                component="h2"
                variant="h6"
                color="primary"
                gutterBottom
                className="App-mT10 App-text-no-width"
                style={{ whiteSpace: "pre-line" }}
              >
                {FormatMessage({ id: "Sts004.Label.text10" })}
              </Typography>
            </Grid>
            <Grid xs={11} sm={11} className="App-mT10 App-text-no-width">
              <Typography
                component="h2"
                variant="h6"
                color="primary"
                gutterBottom
                style={{ whiteSpace: "pre-line" }}
              >
                {question}
              </Typography>
            </Grid>
          </Grid>
          <span>
            {total_count === 0 && props.parentLoading === false ? (
              <Typography component="h1" color="error" gutterBottom>
                {FormatMessage({ id: "Sts001.Search.noFind" })}
              </Typography>
            ) : null}
            <Grid className="App-mT10">
              <Grid item xs={12}>
                <span>
                  {FormatMessage({
                    id: "Sts001.Label.SearchResult",
                    option: { count: String(total_count) },
                  })}
                </span>
              </Grid>
            </Grid>
            <div className="sticky_table_wrapper">
              <Table size="small" className="sticky_table">
                <TableHead style={{ backgroundColor: "#e4e4ea" }}>
                  <TableRow>
                    <TableCell
                      align="center"
                      style={{ width: "20%", textAlign: "left" }}
                    >
                      {messages.answerDate}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ width: "20%", textAlign: "left" }}
                    >
                      {messages.userId}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ width: "20%", textAlign: "left" }}
                    >
                      {messages.userName}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ width: "20%", textAlign: "left" }}
                    >
                      {messages.handleName}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ width: "20%", textAlign: "left" }}
                    >
                      {messages.answer}
                    </TableCell>
                  </TableRow>
                </TableHead>
                {enqueteRows.length > 0 ? (
                  <TableBody>
                    {enqueteRows.map((row, index: number) => (
                      <TableRow key={index}>
                        <TableCell align="center" style={{ textAlign: "left" }}>
                          {getStringFromDate(
                            new Date(row.input_date.slice(0, 19))
                          )}
                        </TableCell>
                        <TableCell align="center" style={{ textAlign: "left" }}>
                          {row.user_id}
                        </TableCell>
                        <TableCell align="center" style={{ textAlign: "left" }}>
                          {row.user_name}
                        </TableCell>
                        <TableCell align="center" style={{ textAlign: "left" }}>
                          {row.handle_name}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textAlign: "left", whiteSpace: "pre-line" }}
                        >
                          {row.answer}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                ) : null}
              </Table>
              {enqueteRows.length > 0 ? (
                <PaginationBox
                  pageNo={PageNo}
                  itemCount={itemCount}
                  diaplayCount={pageMaxCount}
                  totalCount={totalCount}
                  pageCount={pageCount}
                  onChange={(e: any, pageNo: any) => {
                    let newPageNo = Number(pageNo);
                    if (PageNo !== newPageNo) {
                      PageNo = newPageNo;
                      getListData(null, currentIndex, false, "0");
                    }
                  }}
                />
              ) : null}
            </div>
          </span>
        </div>
      ) : (
        <div>
          <Grid container spacing={0}>
            <Grid xs={11} sm={1}>
              <Typography
                component="h2"
                variant="h6"
                color="primary"
                gutterBottom
                className="App-mT10 App-text-no-width"
                style={{ whiteSpace: "pre-line" }}
              >
                {FormatMessage({ id: "Sts004.Label.text10" })}
              </Typography>
            </Grid>
            <Grid xs={11} sm={11} className="App-mT10 App-text-no-width">
              <Typography
                component="h2"
                variant="h6"
                color="primary"
                gutterBottom
                style={{ whiteSpace: "pre-line" }}
              >
                {question}
              </Typography>
            </Grid>
          </Grid>
          <span>
            {total_count === 0 && props.parentLoading === false ? (
              <Typography component="h1" color="error" gutterBottom>
                {FormatMessage({ id: "Sts001.Search.noFind" })}
              </Typography>
            ) : null}
            <Grid className="App-mT10">
              <Grid item xs={12}>
                <span>
                  {FormatMessage({
                    id: "Sts001.Label.SearchResult",
                    option: { count: String(total_count) },
                  })}
                </span>
              </Grid>
            </Grid>
            <div className="sticky_table_wrapper">
              <Table size="small" className="sticky_table">
                <TableHead className="App-tableHead">
                  <TableRow>
                    <TableCell
                      align="center"
                      style={{ width: "20%", textAlign: "left" }}
                    >
                      {messages.answerDate}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ width: "20%", textAlign: "left" }}
                    >
                      {messages.userId}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ width: "20%", textAlign: "left" }}
                    >
                      {messages.userName}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ width: "20%", textAlign: "left" }}
                    >
                      {messages.handleName}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ width: "20%", textAlign: "left" }}
                    >
                      {messages.option}
                    </TableCell>
                  </TableRow>
                </TableHead>
                {enqueteRows.length > 0 ? (
                  <TableBody>
                    {enqueteRows.map((row, index: number) => (
                      <TableRow key={index}>
                        <TableCell align="center" style={{ textAlign: "left" }}>
                          {getStringFromDate(
                            new Date(row.input_date.slice(0, 19))
                          )}
                        </TableCell>
                        <TableCell align="center" style={{ textAlign: "left" }}>
                          {row.user_id}
                        </TableCell>
                        <TableCell align="center" style={{ textAlign: "left" }}>
                          {row.user_name}
                        </TableCell>
                        <TableCell align="center" style={{ textAlign: "left" }}>
                          {row.handle_name}
                        </TableCell>
                        <TableCell align="center" style={{ textAlign: "left" }}>
                          {row.answer}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                ) : null}
              </Table>
            </div>
            {enqueteRows.length > 0 ? (
              <PaginationBox
                pageNo={PageNo}
                itemCount={itemCount}
                diaplayCount={pageMaxCount}
                totalCount={totalCount}
                pageCount={pageCount}
                onChange={(e: any, pageNo: any) => {
                  let newPageNo = Number(pageNo);
                  if (PageNo !== newPageNo) {
                    PageNo = newPageNo;
                    getListData(null, currentIndex, false, "0");
                  }
                }}
              />
            ) : null}
          </span>

          {Boolean(enqueteSurvey.length) ? (
            <Grid container spacing={1}>
              <Grid item xs={8}>
                <DataTable
                  rows={enqueteSurvey}
                  items={enqueteSurveyitems}
                  width="narrow"
                />
              </Grid>
              <Grid item xs={4}></Grid>
            </Grid>
          ) : null}
        </div>
      )}
    </React.Fragment>
  );
};
