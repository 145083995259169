const sessionHandler = {

  getSessionKey : (itemName:string):string => {
    const sessionKey:any = {
      _prefix:                "keywordSearch.condition.",
      searchText:             "searchText",
      keywordIds:             "keywordIds",
      keywords:               "keywords",
      unwatchedOndemandFlag:  "unwatchedOndemandFlag",
      planDateFrom:           "planDateFrom",
      planDateTo:             "planDateTo",
      runSearchProc:          "runSearchProc", // この項目がfalseの場合はsessionHandlerのgetterはデフォルト値を返却する（＝保存された検索条件を使用しないものとする）
    }
    return sessionKey._prefix + sessionKey[itemName];
  },

  getInitial: () => {
    return {
      searchText:             "",
      keywordIds:             [],
      keywords:               [],
      unwatchedOndemandFlag:  false,
      planDateFrom:           "",
      planDateTo:             "",
      runSearchProc:          false,
    }
  },

  getSearchText : ():string => {
    const _sessionValue:string|null = sessionStorage.getItem(
      sessionHandler.getSessionKey("searchText")
    );
    if (_sessionValue === null || sessionHandler.getRunSearchProc() === false) {
      return sessionHandler.getInitial().searchText;
    } else {
      return _sessionValue;
    }
  },

  setSearchText : (value:string) => {
    sessionStorage.setItem(
      sessionHandler.getSessionKey("searchText"),
      value
    );
  },

  getKeywordIds : ():number[] => {
    const _sessionValue:string|null = sessionStorage.getItem(
      sessionHandler.getSessionKey("keywordIds")
    );
    if (_sessionValue === null || sessionHandler.getRunSearchProc() === false) {
      return sessionHandler.getInitial().keywordIds;
    } else {
      const _sessionObjct = JSON.parse(_sessionValue);
      return Array.isArray(_sessionObjct) === true ? _sessionObjct : [];
    }
  },

  setKeywordIds : (value:number[]) => {
    sessionStorage.setItem(
      sessionHandler.getSessionKey("keywordIds"),
      JSON.stringify(value)
    );
  },

  getKeywords : ():string[] => {
    const _sessionValue:string|null = sessionStorage.getItem(
      sessionHandler.getSessionKey("keywords")
    );
    if (_sessionValue === null || sessionHandler.getRunSearchProc() === false) {
      return sessionHandler.getInitial().keywords;
    } else {
      const _sessionObjct = JSON.parse(_sessionValue);
      return Array.isArray(_sessionObjct) === true ? _sessionObjct : [];
    }
  },

  setKeywords : (value:string[]) => {
    sessionStorage.setItem(
      sessionHandler.getSessionKey("keywords"),
      JSON.stringify(value)
    );
  },

  getUnwatchedOndemandFlag: ():boolean => {
    const _sessionValue:string|null = sessionStorage.getItem(
      sessionHandler.getSessionKey("unwatchedOndemandFlag")
    );
    if (_sessionValue === null || (_sessionValue !== "true" && _sessionValue !== "false") || sessionHandler.getRunSearchProc() === false) {
      return sessionHandler.getInitial().unwatchedOndemandFlag;
    } else {
      return JSON.parse(_sessionValue);
    }
  },

  setUnwatchedOndemandFlag : (value:boolean) => {
    sessionStorage.setItem(
      sessionHandler.getSessionKey("unwatchedOndemandFlag"),
      JSON.stringify(value)
    );
  },

  getPlanDateFrom: ():string => {
    const _sessionValue:string|null = sessionStorage.getItem(
      sessionHandler.getSessionKey("planDateFrom")
    );
    if (_sessionValue === null || sessionHandler.getRunSearchProc() === false) {
      return sessionHandler.getInitial().planDateFrom;
    } else {
      return _sessionValue;
    }
  },

  setPlanDateFrom : (value:string) => {
    if (value === "") {
      sessionStorage.setItem(
        sessionHandler.getSessionKey("planDateFrom"),
        ""
      );
    } else {
      sessionStorage.setItem(
        sessionHandler.getSessionKey("planDateFrom"),
        value
      );
    }
  },

  getPlanDateTo: ():string => {
    const _sessionValue:string|null = sessionStorage.getItem(
      sessionHandler.getSessionKey("planDateTo")
    );
    if (_sessionValue === null || sessionHandler.getRunSearchProc() === false) {
      return sessionHandler.getInitial().planDateTo;
    } else {
      return _sessionValue;
    }
  },

  setPlanDateTo : (value:string) => {
    if (value === null) {
      sessionStorage.setItem(
        sessionHandler.getSessionKey("planDateTo"),
        ""
      );
    } else {
      sessionStorage.setItem(
        sessionHandler.getSessionKey("planDateTo"),
        value
      );
    }
  },

  getRunSearchProc: ():boolean => {
    const _sessionValue:string|null = sessionStorage.getItem(
      sessionHandler.getSessionKey("runSearchProc")
    );
    if (_sessionValue === null || (_sessionValue !== "true" && _sessionValue !== "false")) {
      return sessionHandler.getInitial().runSearchProc;
    } else {
      return JSON.parse(_sessionValue);
    }
  },

  setRunSearchProc : (value:boolean) => {
    sessionStorage.setItem(
      sessionHandler.getSessionKey("runSearchProc"),
      JSON.stringify(value)
    );
  },

  isSetCondition: () => {
    if (
      sessionHandler.getSearchText() !== sessionHandler.getInitial().searchText
      || sessionHandler.getKeywordIds().length > 0
      || sessionHandler.getKeywords().length > 0
      || sessionHandler.getUnwatchedOndemandFlag() === true
      || sessionHandler.getPlanDateFrom() !== sessionHandler.getInitial().planDateFrom
      || sessionHandler.getPlanDateTo() !== sessionHandler.getInitial().planDateTo
    ) {
      return true;
    } else {
      return false;
    }
  },

}

export const sessionHandlerForKeywordSearch = sessionHandler;
