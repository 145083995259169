import {
  ILocalVideoTrack,
  IRemoteVideoTrack,
  ILocalAudioTrack,
  IRemoteAudioTrack,
} from "agora-rtc-sdk-ng";
import React, { useRef, useEffect } from "react";
import { createS3, uploadS3 } from "../components/function/AwsS3Fun";
import { store } from "../reducks/store";

export interface VideoPlayerProps {
  videoTrack: ILocalVideoTrack | IRemoteVideoTrack | undefined;
  audioTrack: ILocalAudioTrack | IRemoteAudioTrack | undefined;
}

const MediaPlayer = (props: any) => {
  const container = useRef<HTMLDivElement>(null);

  const uploadLog = async (Log: any, mesod: string) => {
    if (process.env.REACT_APP_AWS_S3_UPLOG === "true") {
      var jsontext = JSON.stringify(Log);
      var blob = new Blob([jsontext], { type: "text/json" });
      var jsonFile = new File([blob], "message.json");
      const date = new Date().getTime();
      var S3: any = await createS3();
      await uploadS3(
        S3,
        "haishin/" +
          store.getState().user.service_id +
          "/" +
          localStorage.getItem("playclass") +
          "/Log/" +
          store.getState().user.user_id +
          "/" +
          store.getState().user.user_id +
          mesod +
          date +
          ".json",
        jsonFile
      );
    }
  };
  useEffect(() => {
    const Log: any[] = [];
    if (!container.current) return;
    if (!props.videoTrack) return;
    props.videoTrack?.play(container.current);
    if (props.videoTrack && props.videoTrack._userId === 19999) {
      let video = document.getElementById(
        "video_" + props.videoTrack._ID
      ) as HTMLMediaElement;
      video.style.objectFit = "";
    }
    if (props.videoTrack) {
      Log.push("streamer VideoInfo");
      Log.push("Video muted:" + props.videoTrack._muted);
      Log.push("Video Height:" + props.videoTrack._videoHeight);
      Log.push("Video WIdth:" + props.videoTrack._videoWidth);
      uploadLog(Log, "_MediaPlayerVideo_");
    }

    /*return () => {
      props.videoTrack?.stop();
    };*/
  }, [container, props.videoTrack]);
  useEffect(() => {
    if (!props.audioTrack) return;
    props.audioTrack?.play();
    const Log: any[] = [];
    if (props.audioTrack) {
      Log.push("streamer AudioInfo");
      Log.push("Audio muted:" + props.audioTrack._muted);
      uploadLog(Log, "_MediaPlayerAudio_");
    }
    /*return () => {
      props.audioTrack?.stop();
    };*/
  }, [props.audioTrack]);
  return <div ref={container} className="video-player"></div>;
};

export default MediaPlayer;
