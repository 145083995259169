import { useEffect, useState } from "react";
// import { useDispatch } from "react-redux";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import { FormatMessage } from "../components/atoms/FormatMessage";
import Typography from "@material-ui/core/Typography";
import sanitize from "sanitize-html";
// import { CF0902 } from "../utils/api/CF0902";

/**
 * フッター
 * @return コンポーネントHTMLコード内容
 */
export const Copyright = (props: any) => {
  // フッターURL初期化
  const [termsOfUse, setTermsOfUse] = useState<any>("#");
  const [PrivacyPolicy, setPrivacyPolicy] = useState<any>("#");
  const [HelpSupport, setHelpSupport] = useState<any>("#");
  const [OperatingCompany, setOperatingCompany] = useState<any>("#");
  const [systemVersion, setSystemVersion] = useState<any>("");

  // 初回のみ起動
  useEffect(() => {
    // localStorageからフッターURLを取得する
    if (
      !localStorage.getItem("termsOfUse") &&
      Object.keys(props).indexOf("footerURLRequest") >= 0
    ) {
      props.footerURLRequest().then(() => {
        setTermsOfUse(localStorage.getItem("termsOfUse"));
        setPrivacyPolicy(localStorage.getItem("PrivacyPolicy"));
        setHelpSupport(localStorage.getItem("HelpSupport"));
        setOperatingCompany(localStorage.getItem("OperatingCompany"));
        setSystemVersion(localStorage.getItem("systemVersion"));
      });
    } else {
      setTermsOfUse(localStorage.getItem("termsOfUse"));
      setPrivacyPolicy(localStorage.getItem("PrivacyPolicy"));
      setHelpSupport(localStorage.getItem("HelpSupport"));
      setOperatingCompany(localStorage.getItem("OperatingCompany"));
      setSystemVersion(localStorage.getItem("systemVersion"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container className="App-footer">
      <Grid item xs={12} sm={9}>
        {/*　一時的にコメントアウト
        <Box display="inline-block" mr={2}>
          <Link href={sanitize(termsOfUse)} target="_blank">
            {FormatMessage({ id: "Copyright.TermsOfUse" })}
          </Link>
        </Box>
        <Box display="inline-block" mr={2}>
          <Link href={sanitize(PrivacyPolicy)} target="_blank">
            {FormatMessage({ id: "Copyright.PrivacyPolicy" })}
          </Link>
        </Box>
        <Box display="inline-block" mr={2}>
          <Link href={sanitize(HelpSupport)} target="_blank">
            {FormatMessage({ id: "Copyright.HelpSupport" })}
          </Link>
        </Box>
        <Box display="inline-block" mr={2}>
          <Link href={sanitize(OperatingCompany)} target="_blank">
            {FormatMessage({ id: "Copyright.OperatingCompany" })}
          </Link>
        </Box>
      */}
      </Grid>
      <Grid item xs={12} sm={3}>
        <Typography variant="body2" color="textSecondary" align="right">
          {FormatMessage({
            id: "Copyright.SystemVersion",
            option: {
              version: systemVersion,
            },
          })}
        </Typography>
      </Grid>
    </Grid>
  );
};
