import React, { useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AddIcon from "@material-ui/icons/Add";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { store } from "../../reducks/store";
import { FormatMessage } from "../../components/atoms/FormatMessage";
import { LoadingBox } from "../../components/atoms/LoadingBox";
import {
  createS3,
  uploadS3,
  // getFileBase64S3,
} from "../../components/function/AwsS3Fun";
/**
 * タグ一覧
 * @param {object} props 引き継いだオブジェクト
 * @return コンポーネントHTMLコード内容
 */

export const Cls021 = (props: any) => {
  const { confData, Callback } = props;
  const classId: string = props.class_id;
  const userIndo: any = store.getState().user;
  const filePath: string =
  "haishin/" + userIndo.service_id + "/" + classId + "/movie/telop.vtt";
  const [movieTime, setmovieTime] = React.useState<any>("");
  const [loading, setLoading] = React.useState(false);
  const canselClick = () => {
    let data: any = confData;
    data.state = "add_edit";
    Callback(data);
  };

  const endClick = async() => {
    setLoading(true);
    let data: any = confData;
    let telopList = data.telopList
    let item = {
      start_time: data.start_time,
      display_time: data.display_time,
      text: data.text,
      start_Datetime:data.start_Datetime,
      end_Datetime:data.end_Datetime
    };
    if (data.delflg === false ) {
      telopList.push(item);
    }
    telopList.sort((a:any, b:any) => 
      a.start_Datetime -b.start_Datetime
    );
    let sumtext = "WEBVTT FILE\r\n\r\n";
    for (let index = 0; index < telopList.length; index++) {
    sumtext = sumtext + (index + 1) + "\r\n";
    sumtext = sumtext + telopList[index].start_time + ".000 --> ";

    var h = formatTime(telopList[index].end_Datetime.getHours());
    var m = formatTime(telopList[index].end_Datetime.getMinutes());
    var s = formatTime(telopList[index].end_Datetime.getSeconds());
    const endTimeText = h + ":" + m + ":" + s;
    sumtext = sumtext + endTimeText + ".000 align:center\r\n";
    sumtext = sumtext + "<c.white.background-black>" + telopList[index].text + "</c>\r\n\r\n";
    }
    var blob = new Blob([sumtext], { type: "text/plain" });
    var vttFile = new File([blob], "telop.vtt");
    var S3: any = await createS3();
    await uploadS3(S3,filePath,vttFile);
    data.state = "end";
    Callback(data);
    setLoading(false);
    
  };
  const formatTime = (i:any)=> {
    /* 1桁の場合 */
    if (i < 10) {
      /* 先頭を0埋め */
      i = "0" + i;
    }
    return i;
  }
  

  // 初回読み込み
  useEffect(() => {
    setmovieTime(confData.movieTime);
  }, []); 
  

  return (
    <div className="App-paper-root">
      <Grid item xs={12} className="App-pTB10 App-pLR10">
        <Typography variant="h6">{FormatMessage({ id: "Cls001.Label.text10" })}</Typography>
        <Grid container spacing={2}>
          <Grid item xs={6} style={{ display: "flex" }}>
            <div style={{ margin: "10px" }}>{FormatMessage({ id: "Cls020.Label.Class_Time" })}</div>
            <div style={{ margin: "10px" }}>{movieTime}</div>
          </Grid>
          <Grid item xs={6}></Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className="App-alignRight">
        <span className="App-mR10">
          <Button
            variant="contained"
            color="primary"
            onClick={canselClick}
            startIcon={<ArrowBackIcon />}
          >
            {FormatMessage({ id: "Button.EditBack" })}
          </Button>
        </span>
      </Grid>
      <Grid className="App-box">
        <Grid container spacing={2}>
          <Grid item xs={6}>
          {FormatMessage({ id: "Cls020.Text.StartTime" })}{confData.start_time}
          </Grid>
          <Grid item xs={6}>
          {FormatMessage({ id: "Cls020.Text.DisplayTime" })}{confData.display_time}秒
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ display: "flex", padding: "8px" }}>
          <div>テロップ：</div>
          <div
            style={{
              whiteSpace: "pre-wrap",
            }}
            dangerouslySetInnerHTML={{
              __html: confData.text,
            }}
          ></div>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        style={{ marginTop: "10px", display: "flex", justifyContent: "center" }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={endClick}
          startIcon={confData.delflg ? <DeleteForeverIcon /> : <AddIcon />}
        >
          {confData.delflg ? "削除" : "登録"}
        </Button>
      </Grid>
      {loading ? <LoadingBox /> : null}
    </div>
  );
};
