import { apigw } from "../awsApi";
import { Dispatch } from "@reduxjs/toolkit";


//サンプルデータ
const state = 0;

/**
 * カスタムアンケート新規作成
 * @param {Dispatch} dispatch     reducerディスパッチ
 * @param {string} classId        授業ID
 * @param {string} questionNo     質問No.
 * @param {string} question       質問
 * @param {string} isRequired     回答方式
 * @param {string} characterClass 必須フラグ
 * @param {string} option1        回答選択肢1
 * @param {string} option2        回答選択肢2
 * @param {string} option3        回答選択肢3
 * @param {string} option4        回答選択肢4
 * @param {string} option5        回答選択肢5
 * @param {string} option6        回答選択肢6
 * @param {string} option7        回答選択肢7
 * @param {string} option8        回答選択肢8
 * @param {string} option9        回答選択肢9
 * @param {string} option10       回答選択肢10
 * @param {string} option11       回答選択肢11
 * @param {string} option12       回答選択肢12
 * @param {string} option13       回答選択肢13
 * @param {string} option14       回答選択肢14
 * @param {string} option15       回答選択肢15
 * @param {string} option16       回答選択肢16
 * @param {string} option17       回答選択肢17
 * @param {string} option18       回答選択肢18
 * @param {string} option19       回答選択肢19
 * @param {string} option20       回答選択肢20
 * @param {string} option21       回答選択肢21
 * @param {string} option22       回答選択肢22
 * @param {string} option23       回答選択肢23
 * @param {string} option24       回答選択肢24
 * @param {string} option25       回答選択肢25
 * @param {string} option26       回答選択肢26
 * @param {string} option27       回答選択肢27
 * @param {string} option28       回答選択肢28
 * @param {string} option29       回答選択肢29
 * @param {string} option30       回答選択肢30
 * @param {string} option31       回答選択肢31
 * @param {string} option32       回答選択肢32
 * @param {string} option33       回答選択肢33
 * @param {string} option34       回答選択肢34
 * @param {string} option35       回答選択肢35
 * @param {string} option36       回答選択肢36
 * @param {string} option37       回答選択肢37
 * @param {string} option38       回答選択肢38
 * @param {string} option39       回答選択肢39
 * @param {string} option40       回答選択肢40
 * @param {string} option41       回答選択肢41
 * @param {string} option42       回答選択肢42
 * @param {string} option43       回答選択肢43
 * @param {string} option44       回答選択肢44
 * @param {string} option45       回答選択肢45
 * @param {string} option46       回答選択肢46
 * @param {string} option47       回答選択肢47
 * @param {string} option48       回答選択肢48
 * @param {string} option49       回答選択肢49
 * @param {string} option50       回答選択肢50
 * @return コンポーネントHTMLコード内容
 */

export const OQ0302 = async (
  dispatch: Dispatch,
  class_id: string,
  question_no: string,
  question: string,
  is_required: string,
  character_class: string,
  option_1: string,
  option_2: string,
  option_3: string,
  option_4: string,
  option_5: string,
  option_6: string,
  option_7: string,
  option_8: string,
  option_9: string,
  option_10: string,
  option_11: string,
  option_12: string,
  option_13: string,
  option_14: string,
  option_15: string,
  option_16: string,
  option_17: string,
  option_18: string,
  option_19: string,
  option_20: string,
  option_21: string,
  option_22: string,
  option_23: string,
  option_24: string,
  option_25: string,
  option_26: string,
  option_27: string,
  option_28: string,
  option_29: string,
  option_30: string,
  option_31: string,
  option_32: string,
  option_33: string,
  option_34: string,
  option_35: string,
  option_36: string,
  option_37: string,
  option_38: string,
  option_39: string,
  option_40: string,
  option_41: string,
  option_42: string,
  option_43: string,
  option_44: string,
  option_45: string,
  option_46: string,
  option_47: string,
  option_48: string,
  option_49: string,
  option_50: string

  ) => {
    if (process.env.REACT_APP_API_USE_FLG === "true") {
      return apigw(
        "/OQ0302",
        {
        class_id,
        question_no,
        question,
        is_required,
        character_class,
        option_1,
        option_2,
        option_3,
        option_4,
        option_5,
        option_6,
        option_7,
        option_8,
        option_9,
        option_10,
        option_11,
        option_12,
        option_13,
        option_14,
        option_15,
        option_16,
        option_17,
        option_18,
        option_19,
        option_20,
        option_21,
        option_22,
        option_23,
        option_24,
        option_25,
        option_26,
        option_27,
        option_28,
        option_29,
        option_30,
        option_31,
        option_32,
        option_33,
        option_34,
        option_35,
        option_36,
        option_37,
        option_38,
        option_39,
        option_40,
        option_41,
        option_42,
        option_43,
        option_44,
        option_45,
        option_46,
        option_47,
        option_48,
        option_49,
        option_50
        },
        dispatch
      );
    } else {
      //サンプルデータ用
      return {state};
    }
  };