import React, { useEffect } from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import ActionComand from "./comand";
import { FormatMessage } from "../../components/atoms/FormatMessage";

// type chatSetting = {
//   sendSetting: number;
//   displaySetting: number;
// };
export default function RadioButtonsGroup(props: any) {
  const [sendSetting, setSendSetting] = React.useState("1");
  const [displaySetting, setDisplaySetting] = React.useState("1");

  const handleChangeSend = (event: any) => {
    props.setChatSettingItem((state: any) => ({
      ...state,
      sendSetting: event.target.value,
    }));

    setSendSetting(event.target.value);
    props.sendAction(ActionComand.MSG_CHAT_SEND_SETTING, null, {
      chat_send_setting: Number(event.target.value),
    });
  };

  const handleChangeDisplay = (event: any) => {
    props.setChatSettingItem((state: any) => ({
      ...state,
      displaySetting: event.target.value,
    }));

    setDisplaySetting(event.target.value);
    props.sendAction(ActionComand.MSG_CHAT_DISPLAY_SETTINGS, null, {
      chat_display_setting: Number(event.target.value),
    });
    props.setLog(null, null, event.target.value === "1" ? "3" : "2");
  };

  useEffect(() => {
    setSendSetting(props.chatSettingItem.sendSetting);
    setDisplaySetting(props.chatSettingItem.displaySetting);
  }, []);

  return (
    <div style={{ margin: "20px" }}>
      <FormControl component="fieldset">
        <label>
          {" "}
          {FormatMessage({
            id: "Cls008.Button.ChatSendSetting",
          })}
        </label>
        <RadioGroup
          aria-label="gender"
          name="gender1"
          value={sendSetting}
          onChange={handleChangeSend}
        >
          <FormControlLabel
            value="1"
            control={<Radio />}
            label={FormatMessage({
              id: "Cls008.Button.ChatSendSetting_All&Teacher",
            })}
          />
          <FormControlLabel
            value="2"
            control={<Radio />}
            label={FormatMessage({
              id: "Cls008.Button.ChatSendSetting_Teacher",
            })}
          />
          <FormControlLabel
            value="3"
            control={<Radio />}
            label={FormatMessage({
              id: "Cls008.Button.ChatSendSetting_No",
            })}
          />
          <FormControlLabel
            value="4"
            control={<Radio />}
            label={FormatMessage({
              id: "Cls008.Button.ChatSendSetting_All",
            })}
          />
        </RadioGroup>
        <label>
          {" "}
          {FormatMessage({
            id: "Cls008.Button.ChatDisplaySetting",
          })}
        </label>
        <RadioGroup
          aria-label="gender"
          name="gender1"
          value={displaySetting}
          onChange={handleChangeDisplay}
        >
          <FormControlLabel
            value="1"
            control={<Radio />}
            label={FormatMessage({
              id: "Cls008.Button.ChatDisplaySetting_True",
            })}
          />
          <FormControlLabel
            value="2"
            control={<Radio />}
            label={FormatMessage({
              id: "Cls008.Button.ChatDisplaySetting_False",
            })}
          />
        </RadioGroup>
      </FormControl>
    </div>
  );
}
