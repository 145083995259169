import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import RefreshIcon from "@material-ui/icons/Refresh";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import { MainLayout } from "../../layouts/MainLayout";
import { FormatMessage } from "../../components/atoms/FormatMessage";

import { US0302 } from "../../utils/api/US0302";
import { US0303 } from "../../utils/api/US0303";
import { changeuserName } from "../../reducks/userSlice";
import { store } from "../../reducks/store";
import { LoadingBox } from "../../components/atoms/LoadingBox";
import sanitize from "sanitize-html";
import { unescapeStr } from "../../components/function/Function";

let getDateValue: any;
/**
 * アカウント情報（配信ユーザ）
 * @param {object} props 引き継いだオブジェクト
 * @return コンポーネントHTMLコード内容
 */
export const Set003 = (props: any) => {
  let sessionDateValue = localStorage.getItem("Set003_session") as string;
  getDateValue =
    props && props.location && typeof props.location.state === "undefined"
      ? JSON.parse(sessionDateValue)
      : props.location.state;
  if (typeof props.location.state !== "undefined") {
    localStorage.setItem(
      "Set003_session",
      JSON.stringify(props.location.state)
    );
  }
  const dispatch = useDispatch();

  //input データ
  const [userId, setUserId] = React.useState("");
  const [userName, setUserName] = React.useState("");
  const [mailAddress, setMailAddress] = React.useState("");
  const [updateDate, setUpdateDate] = React.useState("");
  const [dbErrFlg, setDbErrFlg] = React.useState(false);
  const [mailErrFlg, setMailErrFlg] = React.useState(false);
  const [duplicationErr, setDuplicationErr] = React.useState(false);
  const [exlusiveCtrlErr, setExlusiveCtrlErr] = React.useState(false);
  const [befMailAddress, setBefMailAddress] = React.useState("");

  // loadingフラグ
  const [loading, setLoading] = React.useState(false);

  const backCilck = () => {
    dispatch(
      push("/set/002", {
        userId: sanitize(userId),
        userName: sanitize(userName),
        mailAddress: sanitize(mailAddress),
        updateDate: sanitize(updateDate),
        back: true,
        befMailAddress: sanitize(befMailAddress),
      })
    );
  };
  const checkCilck = () => {
    setLoading(true);

    checkMailAddress(mailAddress).then((res) => {
      if (res) {
        updateData(userName, mailAddress, updateDate).then((res) => {
          if (res) {
            store.dispatch(
              changeuserName({
                user_name: userName,
              })
            );

            setLoading(false);
            dispatch(push("/set/004"));
          }
        });
      } else {
        setLoading(false);
        setMailErrFlg(true);
      }
    });
  };

  const checkMailAddress = async (mailAddress: any) => {
    if (mailAddress !== "" && befMailAddress !== mailAddress) {
      let checkMailAddress = await US0303(dispatch, mailAddress, updateDate);
      if (
        checkMailAddress &&
        Object.keys(checkMailAddress).indexOf("state") >= 0
      )
        if (checkMailAddress.state === "0") {
          return true;
        } else {
          if (Object.keys(checkMailAddress).indexOf("error_code") >= 0) {
            if (checkMailAddress.error_code === "04070") {
              setDuplicationErr(false);
              setExlusiveCtrlErr(true);
            } else {
              setDuplicationErr(true);
            }
          }
          setLoading(false);
          return false;
        }
    } else {
      return true;
    }
  };

  const updateData = async (
    userName: any,
    mailAddress: any,
    updateDate: any
  ) => {
    let UpdateData = await US0302(dispatch, userName, mailAddress, updateDate);
    if (UpdateData && Object.keys(UpdateData).indexOf("state") >= 0) {
      if (UpdateData.state === "0") {
        return true;
      } else {
        if (Object.keys(UpdateData).indexOf("error_code") >= 0) {
          if (UpdateData.error_code === "04070") {
            setExlusiveCtrlErr(true);
          } else {
            setDbErrFlg(true);
          }
        }
        setLoading(false);
        return false;
      }
    } else {
      setLoading(false);
      return false;
    }
  };

  useEffect(() => {
    if (getDateValue) {
      setUserId(getDateValue.userId);
      setUserName(getDateValue.userName);
      setMailAddress(getDateValue.mailAddress);
      setUpdateDate(getDateValue.updateDate);
      setBefMailAddress(getDateValue.befMailAddress);
    }
  }, [getDateValue]);

  return (
    <MainLayout>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className="App-paper">
            <Typography
              component="h2"
              variant="h6"
              color="inherit"
              gutterBottom
            >
              {FormatMessage({ id: "Set003.Title" })}
            </Typography>
            {duplicationErr ? (
              <div className="App-Color-Red">
                {FormatMessage({ id: "Set.Text.DuplicationErr" })}
              </div>
            ) : exlusiveCtrlErr ? (
              <div className="App-Color-Red">
                {FormatMessage({ id: "Error.ExlusiveControl" })}
              </div>
            ) : dbErrFlg ? (
              <div className="App-Color-Red">
                {FormatMessage({ id: "Set003.ErrMsg1" })}
              </div>
            ) : mailErrFlg ? (
              <div className="App-Color-Red">
                {FormatMessage({ id: "Set003.ErrMsg2" })}
              </div>
            ) : null}
            <Grid item xs={12} className="App-alignRight">
              <Button
                variant="contained"
                color="primary"
                startIcon={<ArrowBackIcon />}
                onClick={backCilck}
              >
                {FormatMessage({ id: "Button.EditBack" })}
              </Button>
            </Grid>
            <Grid className="App-box">
              <Grid container spacing={3} className="App-List">
                <Grid item xs={12} sm={3}>
                  {FormatMessage({ id: "Set003.Text.UserId" })}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="App-text">{unescapeStr(userId)}</div>
                </Grid>
              </Grid>
              <Grid container spacing={3} className="App-List">
                <Grid item xs={12} sm={3}>
                  {FormatMessage({ id: "Set003.Text.UserName" })}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="App-text">{unescapeStr(userName)}</div>
                </Grid>
              </Grid>
              <Grid container spacing={3} className="App-List">
                <Grid item xs={12} sm={3}>
                  {FormatMessage({ id: "Set003.Text.MailAddress" })}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="App-text">{unescapeStr(mailAddress)}</div>
                </Grid>
              </Grid>
              <Grid item xs={12} className="App-alignCenter">
                <Button
                  variant="contained"
                  color="primary"
                  className="App-button_bottom"
                  startIcon={<RefreshIcon />}
                  onClick={checkCilck}
                >
                  {FormatMessage({ id: "Button.Update" })}
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      {loading ? <LoadingBox /> : null}
    </MainLayout>
  );
};
