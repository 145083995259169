import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
//import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import HelpIcon from "@material-ui/icons/Help";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { TextFieldBox } from "../../components/atoms/TextFieldBox";
import { doubleByteCheck } from "../../components/function/Function";
import GetAppIcon from "@material-ui/icons/GetApp";
import AssessmentIcon from "@material-ui/icons/Assessment";
import { getSessionUser } from "../../utils/authFlow";

import { TextBox } from "../../components/atoms/TextBox";
import { DateBox } from "../../components/atoms/DateBox";
import { LoadingBox } from "../../components/atoms/LoadingBox";
import { FormatMessage } from "../../components/atoms/FormatMessage";
import { DataTableAuto } from "../../components/table/DataTableAuto";
import { downloadCsv } from "../../components/function/Function";
import { PaginationBox } from "../../components/atoms/PaginationBox";
import { LC0303 } from "../../utils/api/LC0303";
import { LC0304 } from "../../utils/api/LC0304";
import SearchIcon from "@material-ui/icons/Search";
import Tooltip from "@material-ui/core/Tooltip";
import { styled } from "@material-ui/core/styles";

import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Popover from "@material-ui/core/Popover";
import { createTrue } from "typescript";

//const HtmlTooltip = styled(({ className, ...props }) => (
//<Tooltip {...props} classes={{ popper: className }} />
//))(({ theme }) => ({
//[`&`]: {
//width: "650px",
//fontSize: theme.typography.pxToRem(12),
//borderRadius: "4px",
//},
//}));

export const HelpTableBox = () => {
  return (
    <div className="App-help-box">
      <Typography component="div" variant="body1" color="inherit" gutterBottom>
        {FormatMessage({ id: "Sts001.Help.text1" })}
      </Typography>
      <Typography component="div" variant="body1" color="inherit" gutterBottom>
        {FormatMessage({ id: "Sts001.Help.text2" })}
      </Typography>
      <Table size="small">
        <TableBody>
          <TableRow>
            <TableCell rowSpan={3}>
              {FormatMessage({ id: "Sts001.HelpTable:text1" })}
            </TableCell>
            <TableCell>
              {FormatMessage({ id: "Sts001.HelpTable:text2" })}
            </TableCell>
            <TableCell>
              {FormatMessage({ id: "Sts001.HelpTable:text3" })}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              {FormatMessage({ id: "Sts001.HelpTable:text4" })}
            </TableCell>
            <TableCell>
              {FormatMessage({ id: "Sts001.HelpTable:text5" })}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              {FormatMessage({ id: "Sts001.HelpTable:text6" })}
            </TableCell>
            <TableCell>
              {FormatMessage({ id: "Sts001.HelpTable:text7" })}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell rowSpan={3}>
              {FormatMessage({ id: "Sts001.HelpTable:text8" })}
            </TableCell>
            <TableCell>
              {FormatMessage({ id: "Sts001.HelpTable:text9" })}
            </TableCell>
            <TableCell>
              {FormatMessage({ id: "Sts001.HelpTable:text10" })}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              {FormatMessage({ id: "Sts001.HelpTable:text11" })}
            </TableCell>
            <TableCell>
              {FormatMessage({ id: "Sts001.HelpTable:text12" })}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              {FormatMessage({ id: "Sts001.HelpTable:text13" })}
            </TableCell>
            <TableCell>
              {FormatMessage({ id: "Sts001.HelpTable:text14" })}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};
let searchData: any = {};
let s1: any = {
  pageNo: 1,
  itemCount: 0,
  totalCount: 0,
  pageCount: 0,
};

let s2: any = {
  pageNo: 1,
  itemCount: 0,
  totalCount: 0,
  pageCount: 0,
};

/**
 * 授業視聴状況一覧
 * @param {object} props 引き継いだオブジェクト
 * @return コンポーネントHTMLコード内容
 */
export const Sts002 = (props: any) => {
  const [tabValue, setTabValue] = React.useState(false);

  let sessionDateValue = localStorage.getItem("Sts002_session") as string;
  const getDateValue =
    props.classId === "" ? JSON.parse(sessionDateValue) : props;
  if (props.classId !== "") {
    localStorage.setItem("Sts002_session", JSON.stringify(props));
  }

  //input データ
  const [liveUserNum, setLiveUserNum] = React.useState(0); //ライブ配信参加者数
  const [videoUserNum, setVideoUserNum] = React.useState(0); //オンデマンド視聴者数
  const [userId, setUserId] = React.useState(""); //視聴ユーザID
  const [userName, setUserName] = React.useState(""); //視聴ユーザ名
  const [attendanceTimeThreshold, setAttendanceTimeThreshold] =
    React.useState(100); //視聴時間閾値
  const [periodFrom, setPeriodFrom] = React.useState(""); //期間 from
  const [periodTo, setPeriodTo] = React.useState(""); //期間 to
  const [attendanceTimeThresholdError, setAttendanceTimeThresholdError] =
    React.useState(false); //視聴時間閾値
  const [userIdError, setUserIdError] = React.useState(false); //視聴時間閾値

  // ダウンロード有無
  const [downloadExistence, setDownloadExistence] = React.useState(createTrue);

  const [stateCheck, setStateCheck] = React.useState({
    attendance: true, //ライブ配信授業 出席
    partialAttendance: true, //ライブ配信授業 一部出席
    absence: true, //ライブ配信授業 欠席
    watched: true, //ライブ配信授業 出席
    partiallyWatched: true, //ライブ配信授業 一部出席
    notWatched: true, //ライブ配信授業 未視聴
  });

  //テーブルデータ
  type dataType = {
    user_id: string; //視聴ユーザID
    user_name: string; //視聴ユーザ名
    handle_name: string; //ハンドルネーム
    viewing_time: string; //ライブ配信視聴時間（分）
    viewing_time_state: string; //ライブ配信 出席状況
    viewing_time_ondemand: string; //オンデマンド視聴時間（分）
    viewing_time_ondemand_state: string; //オンデマンド 視聴状況
  };

  const pageMaxCount = 100;
  const [totalCount, setTotalCount] = React.useState(-1);

  const [showData, setShowData] = React.useState<dataType[]>([]);

  type dataDetailsType = {
    viewing_date: string; //視聴日時
    user_id: string; //視聴ユーザID
    user_name: string; //視聴ユーザ氏名
    handle_name: string; //ハンドルネーム
    streaming_class: string; //配信区分
    elapsed_time_fraction: number; //経過時間分数
  };

  const [showDataDetails, setShowDataDetails] = React.useState<
    dataDetailsType[]
  >([]);

  //検索折り畳み
  const [showD, setShowD] = React.useState(true);

  //loadingフラグ
  const [loading, setLoading] = React.useState(false);
  const Title002 = FormatMessage({ id: "Sts002.Title" });
  const handleClick = async () => {
    if (tabValue) {
      saveSearchData("tab2");
      loadSearchData("tab1", null);
    } else {
      saveSearchData("tab1");
      loadSearchData("tab2", null);
    }
    if (checkInput()) {
      if (tabValue) {
        setTabValue(false);
        showDate("tab1");
        if (getDateValue.callback) getDateValue.callback("");
      } else {
        setTabValue(true);
        if (getDateValue.callback) getDateValue.callback(Title002);
        showDateDetails(null);
      }
      // setTabValue(tabValue ? false : true);
    }
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStateCheck({ ...stateCheck, [event.target.name]: event.target.checked });
  };

  const [anchorEl, setAnchorEl] =
    React.useState<HTMLButtonElement | null>(null);
  //const [popOverClassName, setPopOverClassName] = React.useState("App-p0");
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClickHelp = (event: React.MouseEvent<HTMLButtonElement>) => {
    //setPopOverClassName("App-p0 App-popover");
    setAnchorEl(event.currentTarget);
  };
  const handleCloseHelp = () => {
    //setPopOverClassName("App-p0");
    setAnchorEl(null);
  };

  const AttendanceText = FormatMessage({ id: "Sts001.Search.attendance" });
  const PartialAttendanceText = FormatMessage({
    id: "Sts001.Search.partialAttendance",
  });
  const AbsenceText = FormatMessage({ id: "Sts001.Search.absence" });
  const WatchedText = FormatMessage({ id: "Sts001.Search.watched" });
  const PartiallyWatchedText = FormatMessage({
    id: "Sts001.Search.partiallyWatched",
  });
  const NotWatchedText = FormatMessage({ id: "Sts001.Search.notWatched" });

  const sortingData = (data: any, download?: boolean) => {
    var list = [];

    if (data && data.length > 0) {
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        let t1 = "";
        let t2 = "";
        if (
          (!element.viewing_time || element.viewing_time === 0) &&
          (!element.viewing_time_ondemand ||
            element.viewing_time_ondemand === 0)
        ) {
          element.viewing_time = 0;
          element.viewing_time_ondemand = 0;
          t1 = '<font color="red">';
          t2 = "</font>";
        }

        var newData = {
          user_id: t1 + element.user_id + t2,
          user_name: t1 + element.user_name + t2,
          handle_name: t1 + element.handle_name + t2,
          viewing_time: t1 + element.viewing_time + t2,
          viewing_time_state:
            element.viewing_time > 0
              ? (element.viewing_time / getDateValue.classTime) * 100 >=
                attendanceTimeThreshold
                ? t1 + AttendanceText + t2
                : t1 + PartialAttendanceText + t2
              : t1 + AbsenceText + t2,
          viewing_time_ondemand: t1 + element.viewing_time_ondemand + t2,
          viewing_time_ondemand_state:
            element.viewing_time_ondemand > 0
              ? (element.viewing_time_ondemand / getDateValue.classTime) *
                  100 >=
                attendanceTimeThreshold
                ? t1 + WatchedText + t2
                : t1 + PartiallyWatchedText + t2
              : t1 + NotWatchedText + t2,
        };
        list.push(newData);
      }
    }
    return list;
  };

  const showDate = async (flg: null | string, download?: boolean) => {
    if (!download) setLoading(true);
    if (checkInput()) {
      let loadData = flg && searchData[flg] ? searchData[flg] : null;
      let dateList = await LC0303(
        getDateValue.dispatch,
        getDateValue?.classId,
        loadData ? loadData.userId : userId,
        loadData ? loadData.userName : userName,
        loadData ? loadData.periodFrom : periodFrom,
        loadData
          ? loadData.periodTo
            ? loadData.periodTo + " 23:59:59"
            : periodTo
            ? periodTo + " 23:59:59"
            : ""
          : periodTo
          ? periodTo + " 23:59:59"
          : "",
        loadData ? loadData.attendanceTimeThreshold : attendanceTimeThreshold,
        loadData ? loadData.stateCheck.attendance : stateCheck.attendance,
        loadData
          ? loadData.stateCheck.partialAttendance
          : stateCheck.partialAttendance,
        loadData ? loadData.stateCheck.absence : stateCheck.absence,
        loadData ? loadData.stateCheck.watched : stateCheck.watched,
        loadData
          ? loadData.stateCheck.partiallyWatched
          : stateCheck.partiallyWatched,
        loadData ? loadData.stateCheck.notWatched : stateCheck.notWatched,
        download ? 0 : s1.pageNo
      );
      if (
        dateList &&
        dateList.state === "0" &&
        Object.keys(dateList).indexOf("body") >= 0
      ) {
        if (
          !download &&
          Object.keys(dateList.body).indexOf("total_count") >= 0
        ) {
          setTotalCount(dateList.body.total_count);
          if (dateList.body.total_count === 0) {
            setShowData([]);
          }
        }
        if (
          !download &&
          Object.keys(dateList.body).indexOf("live_count") >= 0
        ) {
          setLiveUserNum(dateList.body.live_count);
        }
        if (
          !download &&
          Object.keys(dateList.body).indexOf("ondemand_count") >= 0
        ) {
          setVideoUserNum(dateList.body.ondemand_count);
        }
        if (dateList.body.viewer && dateList.body.viewer.length > 0) {
          let data = sortingData(dateList.body.viewer, download);
          if (download) return data;
          if (!download) setShowData(data);
        }
        s1.itemCount = dateList.body.viewer.length;
        s1.totalCount = dateList.body.total_count;
        s1.pageCount = dateList.body.total_page;
      } else {
        if (!download) setShowData([]);
      }
    }
    if (!download) setLoading(false);
    if (download) return [];
  };

  const LiveClassText = FormatMessage({ id: "Sts002.Table.LiveClass" });
  const VideoClassText = FormatMessage({ id: "Sts002.Table.VideoClass" });
  const ConvertJST = (timeData: string) => {
    let time = new Date(timeData);
    time.setHours(time.getHours() - 9);
    return (
      time.getFullYear() +
      "/" +
      ("00" + (time.getMonth() + 1)).slice(-2) +
      "/" +
      ("00" + time.getDate()).slice(-2) +
      " " +
      ("00" + time.getHours()).slice(-2) +
      ":" +
      ("00" + time.getMinutes()).slice(-2)
    );
  };

  // エスケープ処理
  const CSV_ITEM_SURROUND = '"';
  const escapeForCSV = (s: string) => {
    return CSV_ITEM_SURROUND + s + CSV_ITEM_SURROUND;
  };

  const showDateDetails = async (flg: null | string, download?: boolean) => {
    if (!download) setLoading(true);
    let loadData = flg && searchData[flg] ? searchData[flg] : null;
    if (
      !stateCheck.attendance &&
      !stateCheck.partialAttendance &&
      !stateCheck.watched &&
      !stateCheck.partiallyWatched
    ) {
      setShowDataDetails([]);
      setTotalCount(0);
    } else {
      if (checkInput()) {
        let dateList = await LC0304(
          getDateValue.dispatch,
          getDateValue?.classId,
          loadData ? loadData.userId : userId,
          loadData ? loadData.userName : userName,
          loadData ? loadData.periodFrom : periodFrom,
          loadData
            ? loadData.periodTo
              ? loadData.periodTo + " 23:59:59"
              : periodTo
              ? periodTo + " 23:59:59"
              : ""
            : periodTo
            ? periodTo + " 23:59:59"
            : "",
          loadData ? loadData.attendanceTimeThreshold : attendanceTimeThreshold,
          loadData ? loadData.stateCheck.attendance : stateCheck.attendance,
          loadData
            ? loadData.stateCheck.partialAttendance
            : stateCheck.partialAttendance,
          loadData ? loadData.stateCheck.watched : stateCheck.watched,
          loadData
            ? loadData.stateCheck.partiallyWatched
            : stateCheck.partiallyWatched,
          download ? 0 : s2.pageNo
        );
        if (
          dateList &&
          dateList.state === "0" &&
          Object.keys(dateList).indexOf("body") >= 0
        ) {
          if (
            !download &&
            Object.keys(dateList.body).indexOf("total_count") >= 0
          ) {
            setTotalCount(dateList.body.total_count);
            if (dateList.body.total_count === 0) {
              setShowDataDetails([]);
            }
          }
          let list: any = [];
          if (dateList.body.viewer && dateList.body.viewer.length > 0) {
            for (let index = 0; index < dateList.body.viewer.length; index++) {
              list.push({
                viewing_date: ConvertJST(
                  dateList.body.viewer[index].viewing_date
                ), //視聴日時
                user_id: dateList.body.viewer[index].user_id, //視聴ユーザID
                user_name: dateList.body.viewer[index].user_name, //視聴ユーザ氏名
                handle_name: dateList.body.viewer[index].handle_name, //ハンドルネーム
                streaming_class:
                  dateList.body.viewer[index].streaming_class === "1"
                    ? VideoClassText
                    : LiveClassText, //配信区分
                elapsed_time_fraction:
                  dateList.body.viewer[index].elapsed_time_fraction - 1, //経過時間分数
              });
            }
            if (!download) {
              s2.itemCount = dateList.body.viewer.length;
              s2.totalCount = dateList.body.total_count;
              s2.pageCount = dateList.body.total_page;
            }
          }
          if (download) return list;
          if (!download) setShowDataDetails(list);
        }
      }
    }
    if (!download) setLoading(false);
    if (download) return [];
  };

  const checkInput = () => {
    if (String(attendanceTimeThreshold) === "") {
      setAttendanceTimeThresholdError(true);
      return false;
    } else if (doubleByteCheck(userId)) {
      setUserIdError(true);
      return false;
    } else {
      return true;
    }
  };

  const csvHead = [
    {
      class_id: escapeForCSV(FormatMessage({ id: "Sts001.Label.Class_Id" })),
      class_name: escapeForCSV(
        FormatMessage({ id: "Sts001.Label.Class_Name" })
      ),
      user_id: escapeForCSV(FormatMessage({ id: "Sts002.Table.userId" })),
      user_name: escapeForCSV(FormatMessage({ id: "Sts002.Table.userName" })),
      handle_name: escapeForCSV(
        FormatMessage({ id: "Sts002.Table.handleName" })
      ),
      viewing_time: escapeForCSV(
        FormatMessage({ id: "Sts001.Search.Label1" }) +
          " " +
          FormatMessage({ id: "Sts002.Table.viewingTime" })
      ),
      viewing_time_state: escapeForCSV(
        FormatMessage({ id: "Sts001.Search.Label1" }) +
          " " +
          FormatMessage({ id: "Sts002.Table.viewingTimeState" })
      ),
      viewing_time_ondemand: escapeForCSV(
        FormatMessage({ id: "Sts001.Search.Label2" }) +
          " " +
          FormatMessage({ id: "Sts002.Table.viewingTime" })
      ),
      viewing_time_ondemand_state: escapeForCSV(
        FormatMessage({ id: "Sts001.Search.Label2" }) +
          " " +
          FormatMessage({ id: "Sts002.Table.viewingTimeoState" })
      ),
    },
  ];
  const csvHeadDetails = [
    {
      class_id: escapeForCSV(getDateValue?.classId),
      class_name: escapeForCSV(getDateValue?.className),
      class_time: escapeForCSV(getDateValue?.classTime),
    },
    {
      viewing_date: escapeForCSV(
        FormatMessage({ id: "Sts002.Table.viewingDate" })
      ),
      user_id: escapeForCSV(FormatMessage({ id: "Sts002.Table.userId" })),
      user_name: escapeForCSV(FormatMessage({ id: "Sts002.Table.userName" })),
      handle_name: escapeForCSV(
        FormatMessage({ id: "Sts002.Table.handleName" })
      ),
      streaming_class: escapeForCSV(
        FormatMessage({ id: "Sts002.Table.streamingClass" })
      ),
      elapsed_time_fraction: escapeForCSV(
        FormatMessage({
          id: "Sts002.Table.elapsedTimeFraction",
        })
      ),
    },
  ];

  const fileName = FormatMessage({ id: "Sts001.Title" });
  const fileNameDetails = FormatMessage({ id: "Sts002.Title" });

  const downloadFile = async () => {
    setLoading(true);
    if (tabValue) {
      //ダブルクォート
      let SDD = await showDateDetails(null, true);
      let csvFile: any = [];
      for (let index = 0; index < SDD.length; index++) {
        csvFile.push({
          viewing_date: escapeForCSV(SDD[index].viewing_date), //視聴日時
          user_id: escapeForCSV(SDD[index].user_id), //視聴ユーザID
          user_name: escapeForCSV(SDD[index].user_name), //視聴ユーザ氏名
          handle_name: escapeForCSV(SDD[index].handle_name), //ハンドルネーム
          streaming_class: escapeForCSV(SDD[index].streaming_class), //配信区分
          elapsed_time_fraction: escapeForCSV(
            String(SDD[index].elapsed_time_fraction)
          ), //経過時間分数
        });
      }

      downloadCsv(csvFile, fileNameDetails, csvHeadDetails, [], []);
    } else {
      let SDD = await showDate(null, true);
      let CSVData = SDD!.map((row) => {
        return {
          class_id: escapeForCSV(getDateValue?.classId),
          class_name: escapeForCSV(getDateValue?.className),
          user_id: escapeForCSV(delHTMLTag(row.user_id)),
          user_name: escapeForCSV(delHTMLTag(row.user_name)),
          handle_name: escapeForCSV(delHTMLTag(row.handle_name)),
          viewing_time: escapeForCSV(delHTMLTag(row.viewing_time)),
          viewing_time_state: escapeForCSV(delHTMLTag(row.viewing_time_state)),
          viewing_time_ondemand: escapeForCSV(
            delHTMLTag(row.viewing_time_ondemand)
          ),
          viewing_time_ondemand_state: escapeForCSV(
            delHTMLTag(row.viewing_time_ondemand_state)
          ),
        };
      });
      downloadCsv(CSVData, fileName, csvHead, [], []);
    }
    setLoading(false);
  };

  const delHTMLTag = (text: string) => {
    var div = document.createElement("div");
    div.innerHTML = text;
    return div.textContent || div.innerText || "";
  };

  const saveSearchData = (key: string) => {
    let saveData = {
      userId: userId,
      userName: userName,
      attendanceTimeThreshold: attendanceTimeThreshold,
      periodFrom: periodFrom,
      periodTo: periodTo,
      stateCheck: stateCheck,
    };
    searchData[key] = saveData;
  };
  const loadSearchData = (key: string, callback: any) => {
    let loadData = searchData[key];
    if (loadData) {
      setStateCheck({
        attendance: loadData.stateCheck.attendance ? true : false,
        partialAttendance: loadData.stateCheck.partialAttendance ? true : false,
        absence: loadData.stateCheck.absence ? true : false,
        watched: loadData.stateCheck.watched ? true : false,
        partiallyWatched: loadData.stateCheck.partiallyWatched ? true : false,
        notWatched: loadData.stateCheck.notWatched ? true : false,
      });
      setUserId(loadData.userId);
      setUserName(loadData.userName);
      setAttendanceTimeThreshold(loadData.attendanceTimeThreshold);
      setPeriodFrom(loadData.periodFrom);
      setPeriodTo(loadData.periodTo);
    }
    if (callback) callback();
  };

  const handleCallbackUserID = (data: any) => {
    let userId = data.user_id;
    if (data.user_id.indexOf('<font color="red">') !== -1) {
      const tranceUserId = data.user_id.substring(18);
      userId = tranceUserId.substring(0, tranceUserId.indexOf("</font>"));
    }
    saveSearchData("tab1");
    setUserId(userId);
    setUserName("");
    setPeriodFrom("");
    setPeriodTo("");
    setAttendanceTimeThreshold(100);
    if (checkInput()) {
      searchData["tab2"] = {
        userId: userId,
        userName: "",
        attendanceTimeThreshold: 100,
        periodFrom: "",
        periodTo: "",
        stateCheck: {
          attendance: true,
          partialAttendance: true,
          watched: true,
          partiallyWatched: true,
        },
      };
      setTabValue(true);
      showDateDetails("tab2");
    }
  };

  const handleCallbackCheckLive = (data: any) => {
    saveSearchData("tab1");
    if (checkInput()) {
      let attendance = false;
      let partialAttendance = false;
      let watched = false;
      let partiallyWatched = false;
      if (data.viewing_time_state === AttendanceText) attendance = true;
      if (data.viewing_time_state === PartialAttendanceText)
        partialAttendance = true;
      setUserId(data.user_id);
      setUserName("");
      setPeriodFrom("");
      setPeriodTo("");
      setAttendanceTimeThreshold(100);
      setStateCheck({
        ...stateCheck,
        attendance: attendance,
        partialAttendance: partialAttendance,
        watched: watched,
        partiallyWatched: partiallyWatched,
      });
      searchData["tab2"] = {
        userId: data.user_id,
        userName: "",
        attendanceTimeThreshold: 100,
        periodFrom: "",
        periodTo: "",
        stateCheck: {
          attendance: attendance,
          partialAttendance: partialAttendance,
          watched: watched,
          partiallyWatched: partiallyWatched,
        },
      };
      setTabValue(true);
      showDateDetails("tab2");
    }
  };

  const handleCallbackCheckVideo = (data: any) => {
    saveSearchData("tab1");
    if (checkInput()) {
      let attendance = false;
      let partialAttendance = false;
      // let absence = false;
      let watched = false;
      let partiallyWatched = false;
      let notWatched = false;
      if (data.viewing_time_ondemand_state === WatchedText) watched = true;
      if (data.viewing_time_ondemand_state === PartiallyWatchedText)
        partiallyWatched = true;
      if (data.viewing_time_ondemand_state === NotWatchedText)
        notWatched = true;
      if (!notWatched) {
        setUserId(data.user_id);
        setUserName("");
        setPeriodFrom("");
        setPeriodTo("");
        setAttendanceTimeThreshold(100);
        setStateCheck({
          ...stateCheck,
          attendance: attendance,
          partialAttendance: partialAttendance,
          watched: watched,
          partiallyWatched: partiallyWatched,
        });
        searchData["tab2"] = {
          userId: data.user_id,
          userName: "",
          attendanceTimeThreshold: 100,
          periodFrom: "",
          periodTo: "",
          stateCheck: {
            attendance: attendance,
            partialAttendance: partialAttendance,
            watched: watched,
            partiallyWatched: partiallyWatched,
          },
        };
        setTabValue(true);
        showDateDetails("tab2");
      }
    }
  };

  const itemsA = [
    {
      children: [
        {
          id: "user_id",
          name: FormatMessage({ id: "Sts002.Table.userId" }),
          url: null,
          headAlign: "center",
          bodyAlign: "left",
          callback: handleCallbackUserID,
          rowspan: "2",
          width: 15,
        },
        {
          id: "user_name",
          name: FormatMessage({ id: "Sts002.Table.userName" }),
          url: null,
          headAlign: "center",
          bodyAlign: "left",
          rowspan: "2",
          width: 15,
        },
        {
          id: "handle_name",
          name: FormatMessage({ id: "Sts002.Table.handleName" }),
          url: null,
          headAlign: "center",
          bodyAlign: "left",
          rowspan: "2",
          width: 14,
        },
        {
          id: "tt1",
          name: FormatMessage({ id: "Sts001.Search.Label1" }),
          colspan: "2",
          url: null,
          headAlign: "left",
          bodyAlign: "left",
          width: 28,
        },
        {
          id: "tt2",
          name: FormatMessage({ id: "Sts001.Search.Label2" }),
          colspan: "2",
          url: null,
          headAlign: "left",
          bodyAlign: "left",
          width: 28,
        },
      ],
    },
    {
      children: [
        {
          id: "viewing_time",
          name: FormatMessage({ id: "Sts002.Table.viewingTime" }),
          url: null,
          headAlign: "center",
          bodyAlign: "right",
          width: 14,
        },
        {
          id: "viewing_time_state",
          name: FormatMessage({ id: "Sts002.Table.viewingTimeState" }),
          url: null,
          headAlign: "center",
          bodyAlign: "left",
          callback: handleCallbackCheckLive,
          width: 14,
        },
        {
          id: "viewing_time_ondemand",
          name: FormatMessage({ id: "Sts002.Table.viewingTime" }),
          url: null,
          headAlign: "center",
          bodyAlign: "right",
          width: 14,
        },
        {
          id: "viewing_time_ondemand_state",
          name: FormatMessage({ id: "Sts002.Table.viewingTimeoState" }),
          url: null,
          headAlign: "center",
          bodyAlign: "left",
          callback: handleCallbackCheckVideo,
          width: 14,
        },
      ],
    },
  ];

  const itemsB = [
    {
      children: [
        {
          id: "viewing_date",
          name: FormatMessage({ id: "Sts002.Table.viewingDate" }), //視聴日時
          url: null,
          align: "center",
          width: 17,
        },
        {
          id: "user_id",
          name: FormatMessage({ id: "Sts002.Table.userId" }), //視聴ユーザID
          url: null,
          align: "center",
          width: 17,
        },
        {
          id: "user_name",
          name: FormatMessage({ id: "Sts002.Table.userName" }), //視聴ユーザ名
          url: null,
          align: "center",
          width: 17,
        },
        {
          id: "handle_name",
          name: FormatMessage({ id: "Sts002.Table.handleName" }), //ハンドルネーム
          url: null,
          align: "center",
          width: 17,
        },
        {
          id: "streaming_class",
          name: FormatMessage({ id: "Sts002.Table.streamingClass" }), //ライブ配信/オンデマンド
          url: null,
          align: "center",
          width: 16,
        },
        {
          id: "elapsed_time_fraction",
          name: FormatMessage({ id: "Sts002.Table.elapsedTimeFraction" }), //視聴位置
          url: null,
          align: "center",
          bodyAlign: "right",
          width: 16,
        },
      ],
    },
  ];

  //初期処理
  useEffect(() => {
    searchData = {};
    showDate(null);

    // セッションからダウンロード有無を取得
    getSessionUser().then((res) => {
      if (res) {
        // setDownloadExistence(res.download_existence);
      }
    });
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  return (
    <React.Fragment>
      <Grid item xs={12}>
        {FormatMessage({
          id: "Sts002.Search.text1",
          option: { val: liveUserNum, total: getDateValue?.classUserNum },
        })}
        <br />
        {FormatMessage({
          id: "Sts002.Search.text2",
          option: { val: videoUserNum, total: getDateValue?.classUserNum },
        })}
      </Grid>
      {tabValue ? (
        <Grid item xs={12}>
          <Grid
            item
            xs={12}
            style={{ color: "#3993b2", border: "none", marginTop: "5px" }}
          >
            {FormatMessage({ id: "Sts001.Search.Label" })}
          </Grid>
          <Grid
            className={showD ? "search_div showed" : "search_div hidden"}
            style={{ border: "none" }}
          >
            <Grid container spacing={1}>
              <Grid item xs>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={"auto"}
                    sm={"auto"}
                    className="App-LineHeight40 App-W140 App-alignRight"
                  >
                    {FormatMessage({ id: "Sts001.Search.Label1" })}
                  </Grid>
                  <Grid item xs={"auto"} sm={"auto"}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          name="attendance"
                          color="default"
                          checked={stateCheck.attendance}
                          onChange={handleChange}
                        />
                      }
                      label={FormatMessage({ id: "Sts001.Search.attendance" })}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          name="partialAttendance"
                          color="default"
                          checked={stateCheck.partialAttendance}
                          onChange={handleChange}
                        />
                      }
                      label={FormatMessage({
                        id: "Sts001.Search.partialAttendance",
                      })}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={"auto"}
                    sm={"auto"}
                    className="App-LineHeight40 App-W140 App-alignRight"
                  >
                    {FormatMessage({ id: "Sts001.Search.Label2" })}
                  </Grid>
                  <Grid item xs={"auto"} sm={"auto"}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          name="watched"
                          color="default"
                          checked={stateCheck.watched}
                          onChange={handleChange}
                        />
                      }
                      label={FormatMessage({ id: "Sts001.Search.watched" })}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          name="partiallyWatched"
                          color="default"
                          checked={stateCheck.partiallyWatched}
                          onChange={handleChange}
                        />
                      }
                      label={FormatMessage({
                        id: "Sts001.Search.partiallyWatched",
                      })}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs>
                <TextBox
                  fullWidth
                  label={String(FormatMessage({ id: "Sts001.Search.userId" }))}
                  placeholder={String(
                    FormatMessage({ id: "Sts001.Search.placeholder" })
                  )}
                  value={userId}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setUserId(event.target.value);
                  }}
                  error={userIdError}
                  helperText={
                    userIdError
                      ? FormatMessage({
                          id: "HS009",
                          option: {
                            name: FormatMessage({
                              id: "Sts001.Search.userId",
                            }),
                            type: FormatMessage({
                              id: "TYPE-1",
                            }),
                          },
                        })
                      : ""
                  }
                />
              </Grid>
              <Grid item xs>
                <TextBox
                  fullWidth
                  label={String(
                    FormatMessage({ id: "Sts001.Search.userName" })
                  )}
                  value={userName}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setUserName(event.target.value)
                  }
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs>
                <DateBox
                  fullWidth
                  name={""}
                  label={String(FormatMessage({ id: "Sts001.Search.period" }))}
                  valueFrom={periodFrom}
                  onChangeFrom={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setPeriodFrom(event.target.value)
                  }
                  valueTo={periodTo}
                  onChangeTo={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setPeriodTo(event.target.value)
                  }
                />
              </Grid>
              <Grid item xs>
                <Grid container spacing={1}>
                  <Grid
                    item
                    xs={"auto"}
                    sm={"auto"}
                    className="App-LineHeight40"
                  >
                    {FormatMessage({
                      id: "Sts001.Search.attendanceTimeThreshold",
                    })}
                    <IconButton
                      aria-label="help"
                      onMouseEnter={handleClickHelp}
                      onMouseLeave={handleCloseHelp}
                      onClick={handleClickHelp}
                      //className={popOverClassName}
                    >
                      <HelpIcon />
                    </IconButton>
                  </Grid>

                  <Grid item xs={"auto"} sm={"auto"}>
                    <FormControl variant="outlined">
                      <TextFieldBox
                        // <OutlinedInput
                        type="number"
                        className="App-input-outlined"
                        id="outlined-adornment-amount"
                        value={attendanceTimeThreshold}
                        onChange={(event: any) => {
                          setAttendanceTimeThresholdError(false);
                          if (event.target.value < 0) {
                            setAttendanceTimeThreshold(0);
                          } else if (event.target.value > 100) {
                            setAttendanceTimeThreshold(100);
                          } else {
                            setAttendanceTimeThreshold(
                              Number(event.target.value)
                            );
                          }
                        }}
                        // endAdornment={
                        //   <InputAdornment position="end">%</InputAdornment>
                        // }
                        // labelWidth={0}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                          ),
                        }}
                        error={attendanceTimeThresholdError}
                        helperText={
                          attendanceTimeThresholdError
                            ? FormatMessage({
                                id: "HS012",
                                option: {
                                  name: FormatMessage({
                                    id: "Sts001.Search.attendanceTimeThreshold",
                                  }),
                                },
                              })
                            : null
                        }
                      />
                    </FormControl>
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleCloseHelp}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: 330,
                        horizontal: 500,
                      }}
                      style={{ pointerEvents: "none" }}
                    >
                      <div className="App-help-box">
                        <Typography
                          component="div"
                          variant="body1"
                          color="inherit"
                          gutterBottom
                        >
                          {FormatMessage({ id: "Sts001.Help.text1" })}
                        </Typography>
                        <Typography
                          component="div"
                          variant="body1"
                          color="inherit"
                          gutterBottom
                        >
                          {FormatMessage({ id: "Sts001.Help.text2" })}
                        </Typography>
                        <Table size="small">
                          <TableBody>
                            <TableRow>
                              <TableCell rowSpan={3}>
                                {FormatMessage({
                                  id: "Sts001.HelpTable:text1",
                                })}
                              </TableCell>
                              <TableCell>
                                {FormatMessage({
                                  id: "Sts001.HelpTable:text2",
                                })}
                              </TableCell>
                              <TableCell>
                                {FormatMessage({
                                  id: "Sts001.HelpTable:text3",
                                })}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                {FormatMessage({
                                  id: "Sts001.HelpTable:text4",
                                })}
                              </TableCell>
                              <TableCell>
                                {FormatMessage({
                                  id: "Sts001.HelpTable:text5",
                                })}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                {FormatMessage({
                                  id: "Sts001.HelpTable:text6",
                                })}
                              </TableCell>
                              <TableCell>
                                {FormatMessage({
                                  id: "Sts001.HelpTable:text7",
                                })}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell rowSpan={3}>
                                {FormatMessage({
                                  id: "Sts001.HelpTable:text8",
                                })}
                              </TableCell>
                              <TableCell>
                                {FormatMessage({
                                  id: "Sts001.HelpTable:text9",
                                })}
                              </TableCell>
                              <TableCell>
                                {FormatMessage({
                                  id: "Sts001.HelpTable:text10",
                                })}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                {FormatMessage({
                                  id: "Sts001.HelpTable:text11",
                                })}
                              </TableCell>
                              <TableCell>
                                {FormatMessage({
                                  id: "Sts001.HelpTable:text12",
                                })}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                {FormatMessage({
                                  id: "Sts001.HelpTable:text13",
                                })}
                              </TableCell>
                              <TableCell>
                                {FormatMessage({
                                  id: "Sts001.HelpTable:text14",
                                })}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </div>
                    </Popover>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {showD ? (
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Grid className="App-alignCenter" item xs={4}></Grid>
              <Grid className="App-alignCenter" item xs={4}>
                <IconButton onClick={() => setShowD(false)}>
                  <KeyboardArrowUpIcon />
                </IconButton>
              </Grid>
              <Grid className="App-alignCenter" item xs={4}>
                <Grid className="App-mLR10" style={{ float: "right" }}>
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<SearchIcon />}
                    onClick={() => {
                      s2.pageNo = 1;
                      showDateDetails(null);
                    }}
                  >
                    {FormatMessage({ id: "Button.Search" })}
                  </Button>
                </Grid>
                <Grid className="App-mLR10" style={{ float: "right" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClick}
                  >
                    {FormatMessage({ id: "Sts001.Button.Back" })}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid item xs={12} className="App-alignCenter">
              <IconButton onClick={() => setShowD(true)}>
                <KeyboardArrowDownIcon />
              </IconButton>
            </Grid>
          )}
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
          >
            <Grid item xs={6}>
              {totalCount !== 0 ? (
                totalCount === -1 ? (
                  <span>
                    <Typography component="h1" color="error" gutterBottom>
                      {"　"}
                    </Typography>
                    <span className="App-mR10">
                      {FormatMessage({
                        id: "Sts001.Label.SearchResult",
                        option: { count: "0" },
                      })}
                    </span>
                  </span>
                ) : (
                  <span>
                    <Typography component="h1" color="error" gutterBottom>
                      {"　"}
                    </Typography>
                    <span className="App-mR10">
                      {FormatMessage({
                        id: "Sts001.Label.SearchResult",
                        option: { count: String(totalCount) },
                      })}
                    </span>
                  </span>
                )
              ) : (
                <span>
                  <Typography component="h1" color="error" gutterBottom>
                    {FormatMessage({ id: "Sts001.Search.noFind" })}
                  </Typography>
                  <span className="App-mR10">
                    {FormatMessage({
                      id: "Sts001.Label.SearchResult",
                      option: { count: String(totalCount) },
                    })}
                  </span>
                </span>
              )}
            </Grid>
            {downloadExistence ? (
              <Grid item xs={6} className="App-mT10">
                <Grid className="App-mLR10">
                  <Typography align="right">
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<GetAppIcon />}
                      onClick={downloadFile}
                    >
                      {FormatMessage({ id: "Sts001.Button.Download" })}
                    </Button>
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              <Grid item xs={6} className="App-mT10">
                <span></span>
              </Grid>
            )}
          </Grid>
          <DataTableAuto rows={showDataDetails} items={itemsB} />
          {showDataDetails.length > 0 ? (
            <PaginationBox
              pageNo={s2.pageNo}
              itemCount={s2.itemCount}
              diaplayCount={pageMaxCount}
              totalCount={s2.totalCount}
              pageCount={s2.pageCount}
              onChange={(e: any, pageNo: any) => {
                let newPageNo = Number(pageNo);
                if (s2.pageNo !== newPageNo) {
                  s2.pageNo = newPageNo;
                  showDateDetails(null);
                }
              }}
            />
          ) : null}
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Grid
            item
            xs={12}
            style={{ color: "#3993b2", border: "none", marginTop: "5px" }}
          >
            {FormatMessage({ id: "Sts001.Search.Label" })}
          </Grid>
          <Grid
            className={showD ? "search_div showed" : "search_div hidden"}
            style={{ border: "none" }}
          >
            <Grid container spacing={1}>
              <Grid item xs>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={"auto"}
                    sm={"auto"}
                    className="App-LineHeight40 App-W140 App-alignRight"
                  >
                    {FormatMessage({ id: "Sts001.Search.Label1" })}
                  </Grid>
                  <Grid item xs={"auto"} sm={"auto"}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          name="attendance"
                          color="default"
                          checked={stateCheck.attendance}
                          onChange={handleChange}
                        />
                      }
                      label={FormatMessage({ id: "Sts001.Search.attendance" })}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          name="partialAttendance"
                          color="default"
                          checked={stateCheck.partialAttendance}
                          onChange={handleChange}
                        />
                      }
                      label={FormatMessage({
                        id: "Sts001.Search.partialAttendance",
                      })}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          name="absence"
                          color="default"
                          checked={stateCheck.absence}
                          onChange={handleChange}
                        />
                      }
                      label={FormatMessage({ id: "Sts001.Search.absence" })}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={"auto"}
                    sm={"auto"}
                    className="App-LineHeight40 App-W140 App-alignRight"
                  >
                    {FormatMessage({ id: "Sts001.Search.Label2" })}
                  </Grid>
                  <Grid item xs={"auto"} sm={"auto"}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          name="watched"
                          color="default"
                          checked={stateCheck.watched}
                          onChange={handleChange}
                        />
                      }
                      label={FormatMessage({ id: "Sts001.Search.watched" })}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          name="partiallyWatched"
                          color="default"
                          checked={stateCheck.partiallyWatched}
                          onChange={handleChange}
                        />
                      }
                      label={FormatMessage({
                        id: "Sts001.Search.partiallyWatched",
                      })}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          name="notWatched"
                          color="default"
                          checked={stateCheck.notWatched}
                          onChange={handleChange}
                        />
                      }
                      label={FormatMessage({ id: "Sts001.Search.notWatched" })}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs>
                <TextBox
                  fullWidth
                  label={String(FormatMessage({ id: "Sts001.Search.userId" }))}
                  placeholder={String(
                    FormatMessage({ id: "Sts001.Search.placeholder" })
                  )}
                  value={userId}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setUserId(event.target.value);
                  }}
                  error={userIdError}
                  helperText={
                    userIdError
                      ? FormatMessage({
                          id: "HS009",
                          option: {
                            name: FormatMessage({
                              id: "Sts001.Search.userId",
                            }),
                            type: FormatMessage({
                              id: "TYPE-1",
                            }),
                          },
                        })
                      : ""
                  }
                />
              </Grid>
              <Grid item xs>
                <TextBox
                  fullWidth
                  label={String(
                    FormatMessage({ id: "Sts001.Search.userName" })
                  )}
                  value={userName}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setUserName(event.target.value)
                  }
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs>
                <DateBox
                  fullWidth
                  name={""}
                  label={String(FormatMessage({ id: "Sts001.Search.period" }))}
                  valueFrom={periodFrom}
                  onChangeFrom={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setPeriodFrom(event.target.value)
                  }
                  valueTo={periodTo}
                  onChangeTo={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setPeriodTo(event.target.value)
                  }
                />
              </Grid>
              <Grid item xs>
                <Grid container spacing={1}>
                  <Grid
                    item
                    xs={"auto"}
                    sm={"auto"}
                    className="App-LineHeight40"
                  >
                    {FormatMessage({
                      id: "Sts001.Search.attendanceTimeThreshold",
                    })}

                    <IconButton
                      aria-label="help"
                      onMouseEnter={handleClickHelp}
                      onMouseLeave={handleCloseHelp}
                      onClick={handleClickHelp}
                      //className={popOverClassName}
                    >
                      <HelpIcon />
                    </IconButton>
                  </Grid>

                  <Grid item xs={"auto"} sm={"auto"}>
                    <FormControl variant="outlined">
                      <TextFieldBox
                        // <OutlinedInput
                        type="number"
                        className="App-input-outlined"
                        id="outlined-adornment-amount"
                        value={attendanceTimeThreshold}
                        onChange={(event: any) => {
                          setAttendanceTimeThresholdError(false);
                          if (event.target.value < 0) {
                            setAttendanceTimeThreshold(0);
                          } else if (event.target.value > 100) {
                            setAttendanceTimeThreshold(100);
                          } else {
                            setAttendanceTimeThreshold(
                              Number(event.target.value)
                            );
                          }
                        }}
                        // endAdornment={
                        //   <InputAdornment position="end">%</InputAdornment>
                        // }
                        // labelWidth={0}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                          ),
                        }}
                        error={attendanceTimeThresholdError}
                        helperText={
                          attendanceTimeThresholdError
                            ? FormatMessage({
                                id: "HS012",
                                option: {
                                  name: FormatMessage({
                                    id: "Sts001.Search.attendanceTimeThreshold",
                                  }),
                                },
                              })
                            : null
                        }
                      />
                    </FormControl>
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleCloseHelp}
                      style={{ pointerEvents: "none" }}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: 330,
                        horizontal: 500,
                      }}
                    >
                      <div className="App-help-box">
                        <Typography
                          component="div"
                          variant="body1"
                          color="inherit"
                          gutterBottom
                        >
                          {FormatMessage({ id: "Sts001.Help.text1" })}
                        </Typography>
                        <Typography
                          component="div"
                          variant="body1"
                          color="inherit"
                          gutterBottom
                        >
                          {FormatMessage({ id: "Sts001.Help.text2" })}
                        </Typography>
                        <Table size="small">
                          <TableBody>
                            <TableRow>
                              <TableCell rowSpan={3}>
                                {FormatMessage({
                                  id: "Sts001.HelpTable:text1",
                                })}
                              </TableCell>
                              <TableCell>
                                {FormatMessage({
                                  id: "Sts001.HelpTable:text2",
                                })}
                              </TableCell>
                              <TableCell>
                                {FormatMessage({
                                  id: "Sts001.HelpTable:text3",
                                })}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                {FormatMessage({
                                  id: "Sts001.HelpTable:text4",
                                })}
                              </TableCell>
                              <TableCell>
                                {FormatMessage({
                                  id: "Sts001.HelpTable:text5",
                                })}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                {FormatMessage({
                                  id: "Sts001.HelpTable:text6",
                                })}
                              </TableCell>
                              <TableCell>
                                {FormatMessage({
                                  id: "Sts001.HelpTable:text7",
                                })}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell rowSpan={3}>
                                {FormatMessage({
                                  id: "Sts001.HelpTable:text8",
                                })}
                              </TableCell>
                              <TableCell>
                                {FormatMessage({
                                  id: "Sts001.HelpTable:text9",
                                })}
                              </TableCell>
                              <TableCell>
                                {FormatMessage({
                                  id: "Sts001.HelpTable:text10",
                                })}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                {FormatMessage({
                                  id: "Sts001.HelpTable:text11",
                                })}
                              </TableCell>
                              <TableCell>
                                {FormatMessage({
                                  id: "Sts001.HelpTable:text12",
                                })}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                {FormatMessage({
                                  id: "Sts001.HelpTable:text13",
                                })}
                              </TableCell>
                              <TableCell>
                                {FormatMessage({
                                  id: "Sts001.HelpTable:text14",
                                })}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </div>
                    </Popover>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {showD ? (
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
            >
              <Grid className="App-alignCenter" item xs={4}></Grid>
              <Grid className="App-alignCenter" item xs={4}>
                <IconButton onClick={() => setShowD(false)}>
                  <KeyboardArrowUpIcon />
                </IconButton>
              </Grid>
              <Grid className="App-alignCenter" item xs={4}>
                <Grid className="App-mLR10" style={{ float: "right" }}>
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<SearchIcon />}
                    onClick={() => {
                      s1.pageNo = 1;
                      showDate(null);
                    }}
                  >
                    {FormatMessage({ id: "Button.Search" })}
                  </Button>
                </Grid>
                <Grid className="App-mLR10" style={{ float: "right" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClick}
                  >
                    {FormatMessage({ id: "Sts002.Button.LogDetails" })}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid item xs={12} className="App-alignCenter">
              <IconButton onClick={() => setShowD(true)}>
                <KeyboardArrowDownIcon />
              </IconButton>
            </Grid>
          )}
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
          >
            <Grid item xs={6}>
              {totalCount !== 0 ? (
                totalCount === -1 ? (
                  <span>
                    <Typography component="h1" color="error" gutterBottom>
                      {"　"}
                    </Typography>
                    <span className="App-mR10">
                      {FormatMessage({
                        id: "Sts001.Label.SearchResult",
                        option: { count: "0" },
                      })}
                    </span>
                  </span>
                ) : (
                  <span>
                    <Typography component="h1" color="error" gutterBottom>
                      {"　"}
                    </Typography>
                    <span className="App-mR10">
                      {FormatMessage({
                        id: "Sts001.Label.SearchResult",
                        option: { count: String(totalCount) },
                      })}
                    </span>
                  </span>
                )
              ) : (
                <span>
                  <Typography component="h1" color="error" gutterBottom>
                    {FormatMessage({ id: "Sts001.Search.noFind" })}
                  </Typography>
                  <span className="App-mT10">
                    {FormatMessage({
                      id: "Sts001.Label.SearchResult",
                      option: { count: String(totalCount) },
                    })}
                  </span>
                </span>
              )}
            </Grid>
            {downloadExistence ? (
              <Grid item xs={6} className="App-mT10">
                <Grid className="App-mLR10">
                  <Typography align="right">
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<GetAppIcon />}
                      onClick={downloadFile}
                    >
                      {FormatMessage({ id: "Sts001.Button.Download" })}
                    </Button>
                  </Typography>
                </Grid>
                <Grid className="App-mLR10"></Grid>
              </Grid>
            ) : (
              <Grid item xs={6} className="App-mT10">
                <span></span>
              </Grid>
            )}
          </Grid>
          <DataTableAuto rows={showData} items={itemsA} />
          {showData.length > 0 ? (
            <PaginationBox
              pageNo={s1.pageNo}
              itemCount={s1.itemCount}
              diaplayCount={pageMaxCount}
              totalCount={s1.totalCount}
              pageCount={s1.pageCount}
              onChange={(e: any, pageNo: any) => {
                let newPageNo = Number(pageNo);
                if (s1.pageNo !== newPageNo) {
                  s1.pageNo = newPageNo;
                  showDate(null);
                }
              }}
            />
          ) : null}
        </Grid>
      )}
      {loading ? <LoadingBox /> : null}
    </React.Fragment>
  );
};
