import { apigw } from "../awsApi";
import { Dispatch } from "@reduxjs/toolkit";

//サンプルデータ
const rows = [
    {
        chat_send_log_id: "AAA20210831000",  //チャット送信ログID
        elapsed_time: 60,  //経過時間（※[mst_class].[start_date]との差分／秒）
        sender_user_class: "1",  //送信元ユーザ区分値 (1:配信,2:視聴)
        sender_user_class_value: "配信",  //送信元ユーザ区分名称    
        sender_user_id: "AAA",  //送信元ユーザID
        sender_streaming_user_name: "配信ユーザー送信者",  //配信ユーザ氏名(送信者)
        sender_attendance_user_name: "視聴ユーザー送信者",  //視聴ユーザ氏名(送信者)   
        handle_name: "",  //送信者ハンドルネーム
        destination_user_class: "",  //宛先ユーザ区分値　(1:配信,2:視聴)
        destination_user_class_value: "",  //宛先ユーザ区分名称 
        destination_class: "1",  //宛先範囲区分値　(null,1:全体,2:個人)
        destination_class_value: "全員",  //宛先範囲区分名称
        destination: "",  //宛先(個人)   ID
        destination_streaming_user_name: "",  //配信ユーザ氏名(受信者) 
        destination_attendance_user_name: "",  //視聴ユーザ氏名(受信者)
        log_class: "1",  //ログ区分値　(1:チャット,2:簡易反応,3:択一問題)
        log_class_value: "チャット",  //ログ区分名称
        chat_detail: "１２３４５６７８９０１２３４５６７８９０",  //チャット内容
        problem_answer_detail: "",  //択一問題回答内容
    },
    {
      chat_send_log_id: "BBB20210831010",  //チャット送信ログID
      elapsed_time:600,  //経過時間（※[mst_class].[start_date]との差分／秒）
      sender_user_class: "2",  //送信元ユーザ区分値 (1:配信,2:視聴)
      sender_user_class_value: "視聴",  //送信元ユーザ区分名称    
      sender_user_id:"BBB",  //送信元ユーザID
      sender_streaming_user_name:"配信ユーザー送信者",  //配信ユーザ氏名(送信者)
      sender_attendance_user_name: "視聴ユーザー送信者",  //視聴ユーザ氏名(送信者)   
      handle_name: "視聴送信者ハンドルネーム",  //送信者ハンドルネーム
      destination_user_class: "1",  //宛先ユーザ区分値　(1:配信,2:視聴)
      destination_user_class_value: "配信",  //宛先ユーザ区分名称 
      destination_class:"2",  //宛先範囲区分値　(null,1:全体,2:個人)
      destination_class_value:"個人",  //宛先範囲区分名称
      destination: "AAA",  //宛先(個人)   ID
      destination_streaming_user_name: "配信ユーザー受信者",  //配信ユーザ氏名(受信者) 
      destination_attendance_user_name: "",  //視聴ユーザ氏名(受信者)
      log_class: "1",  //ログ区分値　(1:チャット,2:簡易反応,3:択一問題)
      log_class_value: "チャット",  //ログ区分名称
      chat_detail: "１２３４５６７８９０１２３４５６７８９０",  //チャット内容
      problem_answer_detail: "",  //択一問題回答内容
  },
  {
    chat_send_log_id: "CCC20210831010",  //チャット送信ログID
    elapsed_time:10000,  //経過時間（※[mst_class].[start_date]との差分／秒）
    sender_user_class: "2",  //送信元ユーザ区分値 (1:配信,2:視聴)
    sender_user_class_value: "視聴",  //送信元ユーザ区分名称    
    sender_user_id:"CCC",  //送信元ユーザID
    sender_streaming_user_name:"配信ユーザー送信者",  //配信ユーザ氏名(送信者)
    sender_attendance_user_name: "視聴ユーザー送信者２人目",  //視聴ユーザ氏名(送信者)   
    handle_name: "視聴送信者ハンドルネーム２人目",  //送信者ハンドルネーム
    destination_user_class: "",  //宛先ユーザ区分値　(1:配信,2:視聴)
    destination_user_class_value: "",  //宛先ユーザ区分名称 
    destination_class:"1",  //宛先範囲区分値　(null,1:全員,2:個人)
    destination_class_value:"全員",  //宛先範囲区分名称
    destination: "",  //宛先(個人)   ID
    destination_streaming_user_name: "",  //配信ユーザ氏名(受信者) 
    destination_attendance_user_name: "",  //視聴ユーザ氏名(受信者)
    log_class: "3",  //ログ区分値　(1:チャット,2:簡易反応,3:択一問題)
    log_class_value: "択一問題",  //ログ区分名称
    chat_detail: "",  //チャット内容
    problem_answer_detail: "1",  //択一問題回答内容
},
  ];
  
/**
 * チャット履歴一覧取得
 * @param {Dispatch} dispatch reducerディスパッチ
 * @param {string} classId 授業ID
 * @return コンポーネントHTMLコード内容
 */
export const CH0301 = async (
  dispatch: Dispatch,
  classId: string,
  key?:any 
) => {
  if (process.env.REACT_APP_API_USE_FLG === "true") {
    return apigw(
      "/CH0301",
      {
        class_id: classId,
        exclusive_start_keys:key
      },
      dispatch
    );
  } else {
    //サンプルデータ用
    return { body: rows };
  }
};
