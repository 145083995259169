import { apigw } from "../awsApi";
import { Dispatch } from "@reduxjs/toolkit";

const rows = {
   
}

/**
 * 視聴ユーザ取得
 * @param {Dispatch} dispatch reducerディスパッチ
 * @param {string} classId 授業ID
 * @param {any} choiceId 択一問題ID
 * @param {any} recordingVideoId 録画動画ID
 * @param {number} elapsedTime 経過時間
 * @param {string} operationDate 操作日時
 * @param {string} operationClass 操作区分
 * 
 * @return コンポーネントHTMLコード内容
 */
export const ST0320 = async (
  dispatch: Dispatch,
  classId:string,
  choiceId:any,
  recordingVideoId:any,
  elapsedTime:number,
  operationDate:any,
  operationClass:string
) => {
  if (process.env.REACT_APP_API_USE_FLG === "true") {

    return apigw(
      "/ST0320",
      {
        class_id:classId,
        choice_id:choiceId,
        recording_video_id:recordingVideoId,
        elapsed_time:elapsedTime,
        operation_date:operationDate,
        operation_class:operationClass

      },
      dispatch
    );
  } else {
    //サンプルデータ用
    return { body: rows };
  }
};