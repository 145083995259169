import React, { useState, useEffect } from "react";
//import { getSessionUser } from "../../utils/authFlow";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { FormatMessage } from "../../components/atoms/FormatMessage";
//import { MainLayout } from "../../layouts/MainLayout";
import { CF0304 } from "../../utils/api/CF0304";
import { AU0309 } from "../../utils/api/AU0309";
import { AU0310 } from "../../utils/api/AU0310";
import { AU0315 } from "../../utils/api/AU0315";
import { AU0316 } from "../../utils/api/AU0316";
import { TextFieldBox } from "../../components/atoms/TextFieldBox";
import HelpIcon from "@material-ui/icons/Help";
import { IconButton, makeStyles, Popover } from "@material-ui/core";
import { LoadingBox } from "../../components/atoms/LoadingBox";
import "../../css/pas/pas004.css";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import RefreshIcon from "@material-ui/icons/Refresh";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  arrow: {
    color: "#3993b2",
    fontSize: "20px",
    width: "20px",
    "&::before": {
      backgroundColor: "#3993b2",
      boxSizing: "border-box",
    },
  },
  tooltip: {
    maxWidth: 400,
    top: "10px",
    color: "#fff",
    backgroundColor: "#3993b2",
    display: "brock",
  },
}));
/**
 * パスワード再設定
 * @return メッセージ内容
 */

export const Pas004 = (props: any) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [userClass, setUserClass] = useState("");
  const [serviceId, setServiceId] = useState("");
  const [mailAddress, setMailAddress] = useState("");
  const [passwordPolicy, setPasswordPolicy] = useState("");
  const [resetCode, setResetCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmedPassword, setConfirmedPassword] = useState("");
  const [dialog, setDialog] = useState(false);
  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [showConfirmedPassword, setShowConfirmedPassword] =
    React.useState(false);

  //エラーフラグ
  const [mismatchPassword, setMismatchPassword] = useState(false);
  const [codeRequiredErr, setCodeRequiredErr] = useState(false);
  const [newPasRequiredErr, setNewPasRequiredErr] = useState(false);
  const [confirmedRequiredErr, setConfirmedRequiredErr] = useState(false);
  const [codeTypeErr, setCodeTypeErr] = useState(false);
  const [newPasTypeErr, setNewPasTypeErr] = useState(false);
  const [confirmedTypeErr, setConfirmedTypeErr] = useState(false);
  const [policyError, setPolicyError] = useState(false);
  const [upDateError, setUpDateError] = useState(false);
  const [codeError, setCodeError] = useState(false);

  //loadingフラグ
  const [loading, setLoading] = useState(false);

  // メッセージの定義
  const messages = {
    //パスワードポリシー
    CharacterClass0Str: FormatMessage({
      id: "Policy.Text1",
    }),
    CharacterClassEtcStr: FormatMessage({
      id: "Policy.Text2",
    }),
  };

  //パスワード表示切り替え
  const onClickShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };
  const onClickShowConfirmedPassword = () => {
    setShowConfirmedPassword(!showConfirmedPassword);
  };

  const backClick = () => {
    dispatch(push("/pas/003")); //パスワード初期化
  };

  const cpeClick = () => {
    setCodeRequiredErr(required(resetCode));
    setNewPasRequiredErr(required(newPassword));
    setConfirmedRequiredErr(required(confirmedPassword));
    setPolicyError(false);
    setUpDateError(false);

    //入力チェック
    if (resetCode !== "" && newPassword !== "" && confirmedPassword !== "") {
      setCodeTypeErr(format(resetCode));
      setNewPasTypeErr(format(newPassword));
      setConfirmedTypeErr(format(confirmedPassword));
      if (
        resetCode.match(/^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/) &&
        newPassword.match(/^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/) &&
        confirmedPassword.match(/^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/)
      ) {
        //確認用パスワード一致チェック
        if (newPassword !== confirmedPassword) {
          setMismatchPassword(true);
        } else {
          setMismatchPassword(false);
          setLoading(true);
          checkPasswordPolicy().then((res) => {
            if (res !== "0") {
              //パスワードポリシーチェック
              setPolicyError(true);
              setLoading(false);
            } else {
              upDate().then((res) => {
                if (Object.keys(res).indexOf("state") >= 0) {
                  if (res.state !== "0") {
                    if (Object.keys(res).indexOf("error_code") >= 0) {
                      if (res.error_code === "04072") {
                        setLoading(false);
                        setCodeError(true);
                      } else {
                        setLoading(false);
                      }
                    } else {
                      setLoading(false);
                    }
                    setUpDateError(true);
                  } else {
                    if (userClass === "3") {
                      setLoading(false);
                      dispatch(push("/streamer/"));
                    } else {
                      setLoading(false);
                      dispatch(push("/"));
                    }
                  }
                } else {
                  setLoading(false);
                }
              });
            }
          });
        }
      }
    }
  };
  //必須チェック
  const required = (value: string) => {
    if (value === "") {
      return true;
    } else {
      return false;
    }
  };
  //書式チェック
  const format = (value: string) => {
    if (value.match(/^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/)) {
      return false;
    } else {
      return true;
    }
  };

  //パスワードポリシーチェック
  const checkPasswordPolicy = async () => {
    let getData;
    if (userClass === "3") {
      getData = await AU0309(dispatch, newPassword, serviceId, null);
      if (getData && Object.keys(getData).indexOf("state") >= 0) {
        return getData.state;
      }
    } else if (userClass === "4") {
      getData = await AU0310(dispatch, newPassword, serviceId, null);
      if (getData && Object.keys(getData).indexOf("state") >= 0) {
        return getData.state;
      }
    }
  };
  //パスワード更新
  const upDate = async () => {
    let getData;
    if (userClass === "3") {
      getData = await AU0315(
        dispatch,
        serviceId,
        userClass,
        newPassword,
        resetCode,
        mailAddress
      );
      if (getData) {
        return getData;
      }
    } else if (userClass === "4") {
      getData = await AU0316(
        dispatch,
        serviceId,
        userClass,
        newPassword,
        resetCode,
        mailAddress
      );
      if (getData) {
        return getData;
      }
    }
  };

  //パスワードポリシー取得
  const getPolicyData = async (userClass: any, serviceId: any) => {
    let value;
    let length;
    let code;
    let getData = await CF0304(dispatch, userClass, serviceId, false);
    if (getData && Object.keys(getData).indexOf("body") >= 0) {
      if (Object.keys(getData.body).indexOf("code") >= 0) {
        code = getData.body.code;
      }
      if (Object.keys(getData.body).indexOf("value") >= 0) {
        value = getData.body.value;
      }
      if (Object.keys(getData.body).indexOf("string_length") >= 0) {
        length = getData.body.string_length;
      }
    }
    code === "0"
      ? setPasswordPolicy(
          messages.CharacterClass0Str.replace("【stringLength】", length)
        )
      : setPasswordPolicy(
          messages.CharacterClassEtcStr.replace("【value】", value).replace(
            "【stringLength】",
            length
          )
        );
  };

  useEffect(() => {
    let sessionDataValue = localStorage.getItem("Pas004_session");
    const getDataValue =
      typeof props.location.state === "undefined"
        ? sessionDataValue
          ? JSON.parse(sessionDataValue)
          : sessionDataValue
        : props.location.state;
    if (typeof props.location.state !== "undefined")
      localStorage.setItem(
        "Pas004_session",
        JSON.stringify(props.location.state)
      );
    let sessionData = localStorage.getItem("service_id");
    if (getDataValue) {
      getPolicyData(
        getDataValue.userClass,
        sessionData ? sessionData : getDataValue.serviceId
      );
      setUserClass(getDataValue.userClass);
      setServiceId(sessionData ? sessionData : getDataValue.serviceId);
      setMailAddress(getDataValue.mailAddress);
    } else {
      localStorage.clear();
      sessionStorage.clear();
      dispatch(push("/"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const codeChange = (value: any) => {
    setResetCode(value);
  };
  const newPasswordChange = (value: any) => {
    setNewPassword(value);
  };
  const confirmedPasswordChange = (value: any) => {
    setConfirmedPassword(value);
  };

  //必須項目の空欄解消時
  const codeEmptyCheck = () => {
    setCodeRequiredErr(false);
  };
  const newEmptyCheck = () => {
    setNewPasRequiredErr(false);
  };
  const confirmedEmptyCheck = () => {
    setConfirmedRequiredErr(false);
  };
  //必須項目の半角エラー解消時
  const codeTypeCheck = (value: any) => {
    if (value.match(/^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/)) setCodeTypeErr(false);
  };
  const newTypeCheck = (value: any) => {
    if (value.match(/^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/)) setNewPasTypeErr(false);
  };
  const confirmedTypeCheck = (value: any) => {
    if (value.match(/^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/)) setConfirmedTypeErr(false);
  };
  //新しいパスワードと確認用パスワードの不一致解消時
  const newMatchCheck = (value: any) => {
    if (confirmedPassword === value) setMismatchPassword(false);
  };
  const confirmedMatchCheck = (value: any) => {
    if (newPassword === value) setMismatchPassword(false);
  };
  //ダイアログの表示
  const handleDialog = () => {
    setDialog(true);
  };
  const handleDialogOff = () => {
    setDialog(false);
  };
  const ClickDialog = () => {
    if (navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/i)) {
      setDialog(true);
    }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <div style={{ height: "100%" }}>
      <Grid item xs={12} className="App-login-header">
        {/*<img src="/logo.svg" height="24" />*/}
      </Grid>
      <Grid
        container
        spacing={3}
        style={{
          minHeight: "calc(100% - 50px)",
          width: "100%",
          margin: "0 auto",
          padding: "50px 0 0 0",
        }}
      >
        <Grid item xs={12}>
          <Paper className="App-paper">
            <Typography
              component="h2"
              variant="h6"
              color="inherit"
              gutterBottom
            >
              {FormatMessage({ id: "Pas004.Title" })}
            </Typography>
            <Grid
              item
              xs={12}
              className="App-alignLeft"
              style={{ whiteSpace: "pre-wrap" }}
            >
              <Typography
                component="h2"
                variant="subtitle1"
                color="error"
                gutterBottom
              >
                {codeError
                  ? FormatMessage({ id: "Pas004.Text.CodeErr" })
                  : upDateError
                  ? FormatMessage({ id: "Error.Message.UpdateError" })
                  : ""}
              </Typography>
            </Grid>
            <Grid item xs={12} className="App-alignRight">
              <Button
                variant="contained"
                color="primary"
                className="App-button_bottom"
                startIcon={<ArrowBackIcon />}
                onClick={backClick}
              >
                {FormatMessage({ id: "Pas004.Button.Back" })}
              </Button>
            </Grid>
            <Grid item xs={12} className="App-alignCenter">
              <Typography
                component="h2"
                variant="body1"
                color="inherit"
                gutterBottom
                style={{ whiteSpace: "pre-line" }}
              >
                {FormatMessage({ id: "Pas004.Content" })}
                <Tooltip
                  title={
                    <React.Fragment>
                      <Typography style={{ color: "#fff", margin: "5px" }}>
                        {FormatMessage({ id: "Pas004.Text.Help1" })}
                      </Typography>
                      <Typography style={{ color: "#fff", margin: "5px" }}>
                        {FormatMessage({ id: "Pas004.Text.Help2" })}
                      </Typography>
                      <Typography style={{ color: "#fff", margin: "5px" }}>
                        {FormatMessage({ id: "Pas004.Text.Help3" })}
                      </Typography>
                    </React.Fragment>
                  }
                  arrow
                  classes={{
                    tooltip: classes.tooltip,
                    arrow: classes.arrow,
                  }}
                >
                  {/* (1) */}
                  <HelpIcon color="primary" fontSize="small" />
                </Tooltip>
              </Typography>
            </Grid>
            <Grid className="App-box">
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={12} sm={6} className="item-width-edit">
                  {FormatMessage({ id: "Pas004.label1" })}
                  <span className="required_box">
                    {FormatMessage({ id: "Pas004.Text.Required" })}
                  </span>
                </Grid>
                <Grid item xs={12} sm={6} className="item-padding">
                  <TextFieldBox
                    fullWidth
                    label={FormatMessage({ id: "Pas004.label1" })}
                    //margin="normal"
                    variant="outlined"
                    className="App-input-text"
                    value={resetCode}
                    type="text"
                    onChange={(e) => {
                      codeChange(e.target.value);
                    }}
                    helperText={
                      codeRequiredErr
                        ? FormatMessage({
                            id: "Error.Message.RequiredError",
                            option: {
                              name: FormatMessage({ id: "Pas004.label1" }),
                            },
                          })
                        : codeTypeErr
                        ? FormatMessage({
                            id: "Error.Message.TypeError",
                            option: {
                              name: FormatMessage({ id: "Pas004.label1" }),
                              type: "半角",
                            },
                          })
                        : ""
                    }
                    error={codeRequiredErr || codeTypeErr ? true : false}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1} alignItems="center">
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={4} className="item-width-edit"></Grid>
                  <Grid
                    item
                    xs={12}
                    sm={8}
                    style={{
                      whiteSpace: "pre-line",
                      padding: "20px",
                      paddingBottom: "0px",
                    }}
                  >
                    {passwordPolicy}
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={2} className="item-width-pass">
                  {FormatMessage({ id: "Pas004.label2" })}
                  <span className="required_box">
                    {FormatMessage({ id: "Pas004.Text.Required" })}
                  </span>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={8}
                  style={{ padding: "20px", paddingTop: "0px" }}
                >
                  <TextFieldBox
                    fullWidth
                    label={FormatMessage({ id: "Pas004.label2" })}
                    //margin="normal"
                    variant="outlined"
                    className="App-input-text"
                    value={newPassword}
                    type={showNewPassword ? "text" : "password"}
                    onChange={(e) => {
                      newPasswordChange(e.target.value);
                      newEmptyCheck();
                      newTypeCheck(e.target.value);
                      newMatchCheck(e.target.value);
                    }}
                    helperText={
                      newPasRequiredErr
                        ? FormatMessage({
                            id: "Error.Message.TypeError",
                            option: {
                              name: FormatMessage({ id: "Pas004.label2" }),
                              type: "半角",
                            },
                          })
                        : newPasTypeErr
                        ? FormatMessage({
                            id: "Error.Message.TypeError",
                            option: {
                              name: FormatMessage({ id: "Pas004.label2" }),
                              type: "半角",
                            },
                          })
                        : policyError
                        ? FormatMessage({ id: "Error.Message.PolicyError" })
                        : ""
                    }
                    error={
                      newPasRequiredErr || newPasTypeErr || policyError
                        ? true
                        : false
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={onClickShowNewPassword}
                            edge="end"
                          >
                            {showNewPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1} alignItems="center">
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={4} className="item-width-edit"></Grid>
                  <Grid
                    item
                    xs={12}
                    sm={8}
                    className="App-alignLeft"
                    style={{
                      padding: "20px",
                      paddingBottom: "0px",
                    }}
                  >
                    確認のためもう一度新パスワードを入力してください。
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={4} className="item-width-pass">
                  {FormatMessage({ id: "Pas004.label3" })}
                  <span className="required_box">
                    {FormatMessage({ id: "Pas004.Text.Required" })}
                  </span>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  style={{ padding: "20px", paddingTop: "0px" }}
                >
                  <TextFieldBox
                    fullWidth
                    label={FormatMessage({ id: "Pas004.label2" })}
                    //margin="normal"
                    variant="outlined"
                    className="App-input-text"
                    value={confirmedPassword}
                    type={showConfirmedPassword ? "text" : "password"}
                    onChange={(e) => {
                      confirmedPasswordChange(e.target.value);
                    }}
                    helperText={
                      confirmedRequiredErr
                        ? FormatMessage({
                            id: "Error.Message.RequiredError",
                            option: {
                              name: FormatMessage({ id: "Pas004.label3" }),
                            },
                          })
                        : confirmedTypeErr
                        ? FormatMessage({
                            id: "Error.Message.TypeError",
                            option: {
                              name: FormatMessage({ id: "Pas004.label3" }),
                              type: "半角",
                            },
                          })
                        : mismatchPassword
                        ? FormatMessage({ id: "Error.Message.MismatchError" })
                        : ""
                    }
                    error={
                      confirmedRequiredErr ||
                      confirmedTypeErr ||
                      mismatchPassword
                        ? true
                        : false
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={onClickShowConfirmedPassword}
                            edge="end"
                          >
                            {showConfirmedPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className="App-alignCenter">
              <Button
                variant="contained"
                color="primary"
                className="App-mT20 App-button_bottom"
                startIcon={<RefreshIcon />}
                onClick={cpeClick}
              >
                {FormatMessage({ id: "Button.Update" })}
              </Button>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      {loading ? <LoadingBox /> : null}
    </div>
  );
};
