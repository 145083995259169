import { apigw } from "../awsApi";
import { Dispatch } from "@reduxjs/toolkit";

//サンプルデータ
const rows = 
  {
    body:{
    user_name: "山田太郎",
    mail_address: "abcdef@gmail.com",
    update_date:"2010/02/02-12:30:30"
    }
  };

/**
 * 配信ユーザ詳細取得取得
 * @param {Dispatch} dispatch reducerディスパッチ
 * @param {string} userName ユーザ名
 * @param {string} mailAddress メールアドレス
 * @return コンポーネントHTMLコード内容
 */
export const US0301 = async (
  dispatch: Dispatch,
) => {
  if (process.env.REACT_APP_API_USE_FLG === "true") {
    return apigw(
      "/US0301",
      {},
      dispatch
    );
  } else {
    //サンプルデータ用
    return rows;
  }
};


