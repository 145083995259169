import { date } from "yup";
import { apigw } from "../awsApi";
import { Dispatch } from "@reduxjs/toolkit";

const rows = [
  {
    tag_id: "1",
    tag_name: "1",
  },
  {
    tag_id: "2",
    tag_name: "2",
  },
  {
    tag_id: "3",
    tag_name: "3",
  },
  {
    tag_id: "4",
    tag_name: "4",
  },
];

/**
 * 授業一覧取得
 * @param {Dispatch} dispatch reducerディスパッチ
 * @param {string} userClass ユーザ区分
 * @return コンポーネントHTMLコード内容
 */
export const LC0308 = async (
  dispatch: Dispatch,
  userClass: string,
  customEnqueteFlg: any,
  search_text?: string | undefined,
  keywords?: string[] | undefined,
  unwatched_ondemand_flag?: boolean | undefined,
  plan_date_from?: string | undefined,
  plan_date_to?: string | undefined,
) => {
  if (process.env.REACT_APP_API_USE_FLG === "true") {
    return apigw(
      "/LC0308",
      {
        user_class: userClass,
        custom_enquete_flg: customEnqueteFlg,
        search_text: search_text != undefined ? search_text : "",  // 項目「授業名/説明文からさがす」の指定
        keyword: keywords != undefined ? keywords : [], // 項目「キーワード」の指定
        unwatched_ondemand_flag: unwatched_ondemand_flag != undefined ? unwatched_ondemand_flag: false, // 項目「未視聴のオンデマンドのみ」の指定
        plan_date_from: plan_date_from != undefined ? plan_date_from: "", // 項目「配信日(From)」の指定
        plan_date_to: plan_date_to != undefined ? plan_date_to: "", // 項目「配信日(To)」の指定
      },
      dispatch
    );
  } else {
    //サンプルデータ用
    return { state: "0", body: rows };
  }
};
