import { apigw } from "../awsApi";
import { Dispatch } from "@reduxjs/toolkit";

//サンプルデータ
const rows = 
  {
    user_name: "山田太郎",
    handle_name:"やまだ",
    mail_address: "abcdef@gmail.com",
    update_date:new Date(2021, 8, 20 ),
  };

 // const state = 0;

/**
 * 視聴ユーザ詳細取得
 * @param {Dispatch} dispatch reducerディスパッチ
 * @return コンポーネントHTMLコード内容
 */
export const US0304 = async (
  dispatch: Dispatch,
) => {
  if (process.env.REACT_APP_API_USE_FLG === "true") {
    return apigw(
      "/US0304",
      {},
      dispatch
    );
  } else {
    //サンプルデータ用
    return { body: rows };
  }
};