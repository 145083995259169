import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Menu, { MenuProps } from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { FormatMessage } from "../../components/atoms/FormatMessage";
// import useAgora from "../../utils/useAgora";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const classState = {
  NONE: "     ",
  DIST_START: "配信開始",
  DIST_START_P: "配信開始(Proxy)",
  CLASS_START: "授業開始",
  NO_RECORD_CLASS_START: "録画せずに授業開始",
  CLASS_END: "授業終了",
};

export default function CustomizedMenus(props: any) {
  const [text, setText] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  // const {
  //   localAudioTrack,
  //   localVideoTrack,
  //   joinState,
  //   leave,
  //   join,
  //   publish,
  //   unpublish,
  //   microphoneSetEnabled,
  //   cameraSetEnabled,
  //   remoteUsers,
  // } = useAgora(props.client);

  const handleClick = (event: any) => {
    if (props.classStatus === "2" || props.classStatus === "3") {
      setAnchorEl(event.currentTarget);
    }else{
      setAnchorEl(null);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const setStatus = () => {
    setText(
      props.classStatus === "2"
        ? classState.DIST_START
        : props.classStatus === "3"
        ? classState.CLASS_START
        : classState.CLASS_END
    );
  };
  useEffect(() => {
    setStatus();
  }, [props.classStatus]);

  const statusChange = async () => {
    switch (text) {
      case classState.DIST_START:
        const result = await props.JoinStart();
        if (result) {
          setText(classState.CLASS_START);
        }

        break;

      case classState.DIST_START_P:
        const result2 = await props.JoinStart();
        if (result2) {
          setText(classState.CLASS_START);
        }
        break;

      case classState.CLASS_START:
        props.setRecordingState(true);
        props.startRecording(true);
        setText(classState.CLASS_END);
        break;

      case classState.NO_RECORD_CLASS_START:
        props.setRecordingState(true);
        props.startRecording(false);
        setText(classState.CLASS_END);
        break;

      case classState.CLASS_END:
        props.confirmClassEnd();
        break;
    }
  };

  return (
    <span>
      <ButtonGroup
        aria-controls="customized-menu"
        aria-haspopup="true"
        size="small"
        variant="contained"
        style={{ backgroundColor: "white", border: "1px solid #ccc" }}
        disableRipple
        disabled={props.joinButtonState ? false : true}
      >
        <Button
          aria-controls="customized-menu"
          aria-haspopup="true"
          size="small"
          variant="contained"
          style={{ backgroundColor: "white" }}
          startIcon={<FiberManualRecordIcon />}
          onClick={statusChange}
        >
          {text}
        </Button>
        <Button
          aria-haspopup="true"
          size="small"
          variant="contained"
          style={{ backgroundColor: "white" }}
          onClick={handleClick}
        >
          ▼
        </Button>
      </ButtonGroup>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {props.classStatus === "3" ? (
          <StyledMenuItem>
            <ListItemText
              primary={FormatMessage({ id: "Cls008.Button.ClassStart" })}
              onClick={() => {
                setText(classState.CLASS_START);
                handleClose();
              }}
            />
          </StyledMenuItem>
        ) : null}
        {props.classStatus === "3" ? (
          <StyledMenuItem>
            <ListItemText
              primary={FormatMessage({
                id: "Cls008.Button.NoRecordClassStart",
              })}
              onClick={() => {
                setText(classState.NO_RECORD_CLASS_START);
                handleClose();
              }}
            />
          </StyledMenuItem>
        ) : null}
        {props.classStatus === "2" ? (
          <StyledMenuItem>
            <ListItemText
              primary={classState.DIST_START}
              onClick={() => {
                setText(classState.DIST_START);
                handleClose();
              }}
            />
          </StyledMenuItem>
        ) : null}
        {props.classStatus === "2" ? (
          <StyledMenuItem>
            <ListItemText
              primary={classState.DIST_START_P}
              onClick={() => {
                setText(classState.DIST_START_P);
                handleClose();
              }}
            />
          </StyledMenuItem>
        ) : null}
      </StyledMenu>
    </span>
  );
}
