import { createSlice } from "@reduxjs/toolkit";

export type Notification = {
  id: string;
  message: string;
  variant: string;
};

const initialState: Notification = {
  id: "",
  message: "",
  variant: "",
};

const notificationSlice = createSlice({
  name: "notification",
  initialState: initialState,
  reducers: {
    addNotification(state, action) {
      return {
        id: String(new Date().getTime()),
        message: action.payload.message,
        variant: action.payload.variant,
      };
    },
  },
});

// Reducerをエクスポートする
export const notificationReducer = notificationSlice.reducer;

// Action Creatorsをエクスポートする
export const { addNotification } = notificationSlice.actions;
