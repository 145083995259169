import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { TextFieldBox } from "../../components/atoms/TextFieldBox";
import Button from "@material-ui/core/Button";
import { FormatMessage } from "../../components/atoms/FormatMessage";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import { AU0311 } from "../../utils/api/AU0311";
import { AU0312 } from "../../utils/api/AU0312";
import { isEmail } from "../../components/function/Function";
import sanitize from "sanitize-html";

/**
 * パスワード初期化
 * @return メッセージ内容
 */

export const Pas003 = (props: any) => {
  const dispatch = useDispatch();
  const [mailAddress, setMailAddress] = React.useState("");
  const [userClass, setUserClass] = React.useState("");
  const [serviceId, setServiceId] = React.useState("");
  const [requiredErr, setRequiredErr] = React.useState(false);
  const [formatErr, setFormatErr] = React.useState(false);

  const backClick = () => {
    if (userClass === "3") {
      dispatch(push("/streamer/"));
    } else if (userClass === "4") {
      dispatch(push("/"));
    }
  };

  const sendClick = () => {
    setRequiredErr(false);
    setFormatErr(false);
    if (mailAddress === "") {
      setRequiredErr(true);
    } else if (!isEmail(mailAddress)) {
      setFormatErr(true);
    } else {
      if (userClass === "3") {
        AU0311(dispatch, mailAddress, serviceId, userClass);
      } else if (userClass === "4") {
        AU0312(dispatch, mailAddress, serviceId, userClass);
      }
      dispatch(
        push("/pas/004", {
          serviceId: sanitize(serviceId),
          userClass: sanitize(userClass),
          mailAddress: sanitize(mailAddress),
        })
      );
    }

    //serviceId、userClassがログイン画面から引き渡されたとき
    /*if (props.location.state &&
      Object.keys(props.location.state).indexOf("userClass") >= 0
      && Object.keys(props.location.state).indexOf("serviceId") >= 0
    ) {
      if (props.location.state.userClass === "3") {
        AU0311(dispatch, mailAddress, props.location.state.serviceId, props.location.state.userClass );
      }else{
        AU0312(dispatch, mailAddress, props.location.state.serviceId, props.location.state.userClass );
      }
    }*/
  };
  useEffect(() => {
    let sessionDataValue = localStorage.getItem("Pas003_session");
    const getDataValue =
      typeof props.location.state === "undefined"
        ? sessionDataValue
          ? JSON.parse(sessionDataValue)
          : sessionDataValue
        : props.location.state;
    if (typeof props.location.state !== "undefined")
      localStorage.setItem(
        "Pas003_session",
        JSON.stringify(props.location.state)
      );
    let sessionData = localStorage.getItem("service_id");
    if (getDataValue) {
      setUserClass(getDataValue.userClass);
      setServiceId(sessionData ? sessionData : getDataValue.serviceId);
    } else {
      localStorage.clear();
      sessionStorage.clear();
      dispatch(push("/"));
    }
  }, []);

  const changeMailAddress = (value: string) => {
    setMailAddress(value);
  };

  return (
    <div className="App-changePass-box">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className="App-paper">
            <Typography
              component="h2"
              variant="h6"
              color="inherit"
              gutterBottom
            >
              {FormatMessage({ id: "Pas003.Title" })}
            </Typography>
            <Grid item xs={12} className="App-alignRight">
              <Button
                variant="contained"
                color="primary"
                className="App-button_bottom"
                startIcon={<ArrowBackIcon />}
                onClick={backClick}
              >
                {FormatMessage({ id: "Pas003.Button.Back" })}
              </Button>
            </Grid>
            <Grid item xs={12} className="App-alignCenter">
              <Typography
                component="h2"
                variant="body1"
                color="inherit"
                gutterBottom
                style={{ whiteSpace: "pre-line" }}
              >
                {FormatMessage({ id: "Pas003.Content" })}
              </Typography>
            </Grid>
            <Grid container spacing={1}>
              <br />
            </Grid>
            <Grid item xs={12} className="App-alignCenter">
              <Typography
                component="h2"
                variant="body2"
                color="error"
                gutterBottom
              >
                {FormatMessage({ id: "Pas003.Help" })}
              </Typography>
            </Grid>
            <Grid container spacing={1}>
              <br />
            </Grid>
            <Grid
              container
              spacing={1}
              className="MuiGrid-root"
              alignItems="center"
            >
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={12} sm={6} className="item-width-edit">
                  {FormatMessage({ id: "Pas003.label" })}
                  <span className="required_box">
                    {FormatMessage({ id: "Pas001.Text.Required" })}
                  </span>
                </Grid>
                <Grid item xs={12} sm={6} className="item-padding">
                  <TextFieldBox
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    className="App-mT0 App-pT10 App-input-text"
                    value={mailAddress}
                    inputProps={{
                      maxLength: 254,
                    }}
                    onChange={(e) => {
                      changeMailAddress(e.target.value);
                    }}
                    helperText={
                      formatErr
                        ? FormatMessage({
                            id: "Error.Message.FormatError",
                            option: { name: "メールアドレス" },
                          })
                        : requiredErr
                        ? FormatMessage({
                            id: "Error.Message.RequiredError",
                            option: { name: "メールアドレス" },
                          })
                        : ""
                    }
                    error={requiredErr || formatErr}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} className="App-alignCenter">
              <Button
                variant="contained"
                color="primary"
                className="App-mT20 App-button_bottom"
                onClick={sendClick}
              >
                {FormatMessage({ id: "Button.Send" })}
              </Button>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};
