import React, { useEffect } from "react";
// import Radio from "@material-ui/core/Radio";
// import RadioGroup from "@material-ui/core/RadioGroup";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import FormControl from "@material-ui/core/FormControl";
import ActionComand from "./comand";
import { FormatMessage } from "../../components/atoms/FormatMessage";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
// import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    borderBottom: "1px solid #ccc",
    backgroundColor: "#c4dfef",
  },
  details: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
  },
  content: {
    flex: "1 0 auto",
    padding: "4px 8px",
  },
}));
// type chatSetting = {
//   sendSetting: number;
//   displaySetting: number;
// };
export default function LyoutButtonsGroup(props: any) {
  const classes = useStyles();
  const [videoLyoutSetting, setVideoLyoutSetting] = React.useState(true);
  const [boardLyoutSetting, setBoardLyoutSetting] = React.useState(true);

  const handleChangeVideo = (event: any) => {
    props.setVideoLyoutSetting(!videoLyoutSetting);
    props.setVLSetting(!props.vLSetting);
    props.setBLSetting(false);
    setVideoLyoutSetting(!videoLyoutSetting);
    props.setBoardLyoutSetting(true);
    setBoardLyoutSetting(true);
    props.sendAction(ActionComand.MSG_LAYOUT_CHANGE, null, {
      layout_type: videoLyoutSetting ? 1 : 2,
    });
    props.layoutClose();
    props.setLog(null, null, videoLyoutSetting ? "5" : "6");
  };

  const handleChangeBoard = (event: any) => {
    props.setBoardLyoutSetting(!boardLyoutSetting);
    props.setBLSetting(!props.bLSetting);
    props.setVLSetting(false);
    setBoardLyoutSetting(!boardLyoutSetting);
    props.setVideoLyoutSetting(true);
    setVideoLyoutSetting(true);
    props.sendAction(ActionComand.MSG_LAYOUT_CHANGE, null, {
      layout_type: boardLyoutSetting ? 3 : 4,
    });
    props.layoutClose();
    props.setLog(null, null, boardLyoutSetting ? "7" : "8");
  };

  useEffect(() => {
    setVideoLyoutSetting(props.videoLyoutSetting);
    setBoardLyoutSetting(props.boardLyoutSetting);
  }, []);

  return (
    <div>
      <Card className={classes.root}>
        <ButtonBase className="App-image" onClick={handleChangeVideo}>
          <CardContent className={classes.content}>
            {FormatMessage({
              id: videoLyoutSetting
                ? "Cls008.Dialog.Lyout.VideoMax"
                : "Cls008.Dialog.Lyout.VideoMin",
            })}
          </CardContent>
        </ButtonBase>
      </Card>
      <Card className={classes.root}>
        <ButtonBase className="App-image" onClick={handleChangeBoard}>
          <CardContent className={classes.content}>
            {FormatMessage({
              id: boardLyoutSetting
                ? "Cls008.Dialog.Lyout.BoardMax"
                : "Cls008.Dialog.Lyout.BoardMin",
            })}
          </CardContent>
        </ButtonBase>
      </Card>
    </div>
  );
}
