import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { TextFieldBox } from "../../components/atoms/TextFieldBox";
import { store } from "../../reducks/store";

import { MainLayout } from "../../layouts/MainLayout";
import { FormatMessage } from "../../components/atoms/FormatMessage";
import { US0301 } from "../../utils/api/US0301";
import { US0303 } from "../../utils/api/US0303";
import { isEmail } from "../../components/function/Function";
import { doubleByteCheck } from "../../components/function/Function";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CheckIcon from "@material-ui/icons/Check";
import { LoadingBox } from "../../components/atoms/LoadingBox";
import sanitize from "sanitize-html";
import { unescapeStr } from "../../components/function/Function";
//import { doubleByteCheck } from "../../components/function/Function";

let getDateValue: any;
/**
 * アカウント情報（配信ユーザ）
 * @param {object} props 引き継いだオブジェクト
 * @return コンポーネントHTMLコード内容
 */
export const Set002 = (props: any) => {
  let sessionDateValue = localStorage.getItem("Set002_session") as string;
  getDateValue =
    props && props.location && typeof props.location.state === "undefined"
      ? JSON.parse(sessionDateValue)
      : props.location.state;
  if (typeof props.location.state !== "undefined") {
    localStorage.setItem(
      "Set002_session",
      JSON.stringify(props.location.state)
    );
  }
  const dispatch = useDispatch();

  //input データ

  const [userId, setUserId] = React.useState("");
  const [userName, setUserName] = React.useState("");
  const [mailAddress, setMailAddress] = React.useState("");
  const [updateDate, setUpdateDate] = React.useState("");
  const [userNameErr, setUserNameErr] = React.useState(false);
  const [mailFormatErr, setMailFormatErrFlg] = React.useState(false);
  const [duplicationErr, setDuplicationErrFlg] = React.useState(false);
  const [befMailAddress, setBefMailAddress] = React.useState("");
  const [exlusiveCtrlErr, setExlusiveCtrlErr] = React.useState(false);
  const [isPhone, setIsPhone] = React.useState(false);

  // loadingフラグ
  const [loading, setLoading] = React.useState(false);

  const backCilck = () => {
    dispatch(push("/set/001"));
  };

  // 確認画面へ遷移するハンドラ
  const checkCilck = () => {
    setLoading(true);
    if (userName !== "" && (mailAddress === "" || isEmail(mailAddress))) {
      checkMailAddress(mailAddress).then((res) => {
        if (res) {
          setLoading(false);
          dispatch(
            push("/set/003", {
              userId: sanitize(userId),
              userName: sanitize(userName),
              mailAddress: sanitize(mailAddress),
              updateDate: sanitize(updateDate),
              befMailAddress: sanitize(befMailAddress),
            })
          );
        }
      });
    } else {
      if (userName === "") {
        setUserNameErr(true);
      }
      if (!isEmail(mailAddress)) {
        setMailFormatErrFlg(true);
      }
      setLoading(false);
    }
  };

  //const userNameErrCheck = (value: any) => {
  //if (userName !== "") {
  ///setUserNameErr(false);
  //}
  //};

  const checkMailAddress = async (mailAddress: any) => {
    if (mailAddress !== "") {
      let checkMailAddress = await US0303(dispatch, mailAddress, updateDate);
      if (
        checkMailAddress &&
        Object.keys(checkMailAddress).indexOf("state") >= 0
      )
        if (checkMailAddress.state === "0") {
          return true;
        } else {
          if (Object.keys(checkMailAddress).indexOf("error_code") >= 0) {
            if (checkMailAddress.error_code === "04070") {
              setDuplicationErrFlg(false);
              setExlusiveCtrlErr(true);
            } else {
              setDuplicationErrFlg(true);
            }
          }
          setLoading(false);
          return false;
        }
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (getDateValue) {
      setUserId(getDateValue.userId);
      setUserName(getDateValue.userName);
      setMailAddress(getDateValue.mailAddress);
      setUpdateDate(getDateValue.updateDate);
      setBefMailAddress(getDateValue?.befMailAddress);
    }
    if (
      navigator.userAgent.indexOf("Android") > 0 ||
      navigator.userAgent.indexOf("iPhone") > 0
    ) {
      setIsPhone(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainLayout>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className="App-paper">
            <Typography
              component="h2"
              variant="h6"
              color="inherit"
              gutterBottom
            >
              {FormatMessage({ id: "Set002.Title" })}
            </Typography>
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
            >
              <Grid className="App-pLR5">
                {/* アカウント情報に戻る */}
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<ArrowBackIcon />}
                  className="App-button_bottom"
                  onClick={backCilck}
                >
                  {FormatMessage({ id: "Set002.Button.back" })}
                </Button>
              </Grid>
              <Grid className="App-pLR5">
                {/* 確認画面へ */}
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<CheckIcon />}
                  className="App-button_bottom"
                  onClick={checkCilck}
                >
                  {FormatMessage({ id: "Button.Confirmation" })}
                </Button>
              </Grid>
            </Grid>
            <Grid className="App-box">
              <Grid
                container
                spacing={3}
                className="App-List"
                alignItems="center"
              >
                <Grid item xs={12} sm={4} className="item-width-edit">
                  {FormatMessage({ id: "Set002.Text.UserId" })}
                </Grid>
                <Grid item xs={12} sm={8} className="item-padding">
                  {unescapeStr(userId)}
                </Grid>
              </Grid>

              {isPhone ? (
                <Grid
                  container
                  spacing={3}
                  className="App-List"
                  alignItems="center"
                >
                  <Grid item xs={12} sm={2} className="item-width-edit_Phone">
                    {FormatMessage({ id: "Set002.Text.UserName" })}
                  </Grid>
                  <Grid item xs={12} sm={2} className="required_Phone">
                    {FormatMessage({ id: "Set002.Text.Required" })}
                  </Grid>
                  <Grid item xs={12} sm={8} className="item-padding">
                    <TextFieldBox
                      className="App-input-text"
                      id="outlined-basic"
                      error={userNameErr}
                      value={unescapeStr(userName)}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        setUserName(event.target.value);
                      }}
                      variant="outlined"
                      fullWidth
                      helperText={
                        userNameErr
                          ? FormatMessage({
                              id: "Error.Message.RequiredError",
                              option: { name: "ユーザ名" },
                            })
                          : null
                      }
                      inputProps={{ maxLength: 256 }}
                    />
                  </Grid>
                </Grid>
              ) : (
                <Grid
                  container
                  spacing={3}
                  className="App-List"
                  alignItems="center"
                >
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    className="item-width-edit"
                    style={{ width: "fullWidth" }}
                  >
                    {FormatMessage({ id: "Set002.Text.UserName" })}
                    <span className="required_box">
                      {FormatMessage({ id: "Set002.Text.Required" })}
                    </span>
                  </Grid>
                  <Grid item xs={12} sm={8} className="item-padding">
                    <TextFieldBox
                      className="App-input-text"
                      id="outlined-basic"
                      error={userNameErr}
                      value={unescapeStr(userName)}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        setUserName(event.target.value);
                      }}
                      variant="outlined"
                      fullWidth
                      helperText={
                        userNameErr
                          ? FormatMessage({
                              id: "Error.Message.RequiredError",
                              option: { name: "ユーザ名" },
                            })
                          : null
                      }
                      inputProps={{ maxLength: 256 }}
                    />
                  </Grid>
                </Grid>
              )}

              <Grid
                container
                spacing={3}
                className="App-List"
                alignItems="center"
              >
                <Grid item xs={12} sm={4} className="item-width-edit">
                  {FormatMessage({ id: "Set002.Text.MailAddress" })}
                </Grid>
                <Grid item xs={12} sm={8} className="item-padding">
                  <TextFieldBox
                    className="App-input-text"
                    id="outlined-basic"
                    error={mailFormatErr || duplicationErr || exlusiveCtrlErr}
                    value={unescapeStr(mailAddress)}
                    onChange={(event) => {
                      setMailAddress(event.target.value);
                    }}
                    variant="outlined"
                    fullWidth
                    helperText={
                      mailFormatErr
                        ? FormatMessage({
                            id: "Error.Message.FormatError",
                            option: {
                              name: "メールアドレス",
                            },
                          })
                        : duplicationErr
                        ? FormatMessage({
                            id: "Set.Text.DuplicationErr",
                          })
                        : exlusiveCtrlErr
                        ? FormatMessage({
                            id: "Error.ExlusiveControl",
                          })
                        : null
                    }
                    inputProps={{ maxLength: 254 }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      {loading ? <LoadingBox /> : null}
    </MainLayout>
  );
};
