import { apigw } from "../awsApi";
import { Dispatch } from "@reduxjs/toolkit";

/**
 * OQ0303 更新（カスタムアンケート問題が対象で、DELETE INSERTを行う）
 * @param {Dispatch} dispatch reducerディスパッチ
 * @param {string} classId 授業ID
 * @param {string} questionNo 質問No
 * @param {string} question 質問
 * @param {string} questionClass 回答方式
 * @param {boolean} isRequired 必須
 * @param {any} option 選択肢
 * @param {Date} updateDate 更新日時
 * @return カスタムアンケート問題更新結果
 */
export const OQ0303 = async (
  dispatch: Dispatch,
  classId: string,
  questionNo: string,
  question: string,
  characterClass: string,
  isRequired: string,
  option: any,
  updateDate: Date,
) => {
  if (process.env.REACT_APP_API_USE_FLG === "true") {
    return apigw(
      "/OQ0303",
      Object.assign({
        class_id: classId,
        question_no: questionNo,
        question: question,
        character_class: characterClass,
        is_required: isRequired,
        update_date: updateDate,
      }, option),
      dispatch
    );
  } else {
    //成功時サンプルデータ
    return {
      state: "0",
      error_code: "",
      body: {},
    };
    //排他処理エラー時サンプルデータ
    // return {
    //   state: "3",
    //   error_code: "04070",
    //   body: {},
    // };
    //削除失敗時サンプルデータ
    // return {
    //   state: "2",
    //   error_code: "04014",
    //   body: {},
    // };
  }
};
