import React from "react";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";
import sanitize from "sanitize-html";

const useStyles = makeStyles((theme) => ({
  table: {
    marginTop: "20px",
    marginBottom: "10px",
    border: "1px solid #ccc",
    width: "100%",
    borderCollapse: "separate",
    borderSpacing: 0,
    whiteSpace: "nowrap",
    borderRadius: "10px",
    backgroundColor: "#fff",
    overflow: "hidden",
  },
  tableHead: {
    backgroundColor: "#e4e4ea",
  },
  tableDiv: {
    minHeight: 24,
  },
}));

type itemData = {
  id: string;
  name: string;
  name2: string;
  url: string;
  align: "center" | "inherit" | "justify" | "left" | "right";
  bodyAlign: "center" | "inherit" | "justify" | "left" | "right";
  width: number; //1~100%
  minWidth?:string;
  onChange: any;
  callback: any;
  func:any;
};

/**
 * 共有データテーブル
 * @param {object} items 現在ページ番号
 * @param {object} rows 現在取得件数
 * @return コンポーネントHTMLコード内容
 */
export const DataTable = (props: any) => {
  var { items, rows, width } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const linkClick = (url: string) => {
    dispatch(push(sanitize(url)));
  };

  let defaultWidth = Math.ceil(100 / items.length);

  return (
    <React.Fragment>
      <div className="sticky_table_wrapper">
        <Table size="small" className={width? "sticky_table  sticky_table_narrow":"sticky_table"}>
          <TableHead className={classes.tableHead}>
            <TableRow>
              {items.map((item: itemData, index: number) => (
                <TableCell align="center" key={index} style={{ width: (item.width ? item.width : defaultWidth) + "%"  }}>
                  <div className={classes.tableDiv}>{item.name}</div>
                  {item.name2 ? (
                    <div className={classes.tableDiv}>{item.name2}</div>
                  ) : (
                    ""
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row: any, index: number) => (
              <TableRow key={index}>
                {items.map((rowItem: itemData, index: number) => (
                  <TableCell
                    align={
                      rowItem.align
                        ? rowItem.align
                        : "left"
                    }
                    key={index}
                    style={{
                      textAlign:
                        rowItem.bodyAlign
                          ? rowItem.bodyAlign
                          : "left",
                      width:
                        (rowItem.width
                          ? rowItem.width
                          : defaultWidth) + "%",
                      minWidth:(rowItem.minWidth
                        ? rowItem.minWidth
                        : "50px")
                    }}
                 >
                    {rowItem.id === "CheckBox" ? (
                      <Checkbox
                        size="small"
                        value={row.id}
                        onChange={rowItem.onChange}
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    ) : rowItem.callback &&
                      typeof rowItem.callback === "function" ? (
                      <span
                        className="App-Link"
                        onClick={(event) => rowItem.callback(event)}
                      >
                        {row[rowItem.id]}
                      </span>
                    ) : rowItem.url ? (
                      <span
                        className="App-Link"
                        onClick={() => linkClick(rowItem.url)}
                      >
                        {row[rowItem.id]}
                      </span>
                    ): rowItem.func &&
                    typeof rowItem.func === "function" ? (
                      <span
                        className="App-Link"
                        onClick={() => rowItem.func(row)}
                      >
                        {row[rowItem.id]}
                      </span>
                    ) : (
                      row[rowItem.id]
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </React.Fragment>
  );
};
