import { apigw } from "../awsApi";
import { Dispatch } from "@reduxjs/toolkit";

/**
 * チャット内容登録
 * @param {Dispatch} dispatch reducerディスパッチ
 * @param {string} classId 授業ID
 * @param {number} currentTime チャット送信時間
 * @param {string} sendUserId 送信元ユーザID
 * @param {number} sendToClass 送信先区分
 * @param {string} message チャットメッセージ内容
 * @param {string} reaction 簡易反応内容
 * @return コンポーネントHTMLコード内容
 */
export const ST0323 = async (
  dispatch: Dispatch,
  classId: string,
  currentTime: number,
  sendUserId: string,
  sendToClass: number | null,
  message: string | null,
  reaction: string | null,
  destination: string | null,
) => {
  if (process.env.REACT_APP_API_USE_FLG === "true") {
    return apigw(
      "/ST0323",
      {
        class_id: classId,
        currentTime: currentTime,
        send_user_id: sendUserId,
        send_to_class: sendToClass,
        message: message,
        reaction: reaction,
        destination: destination,
      },
      dispatch
    );
  } else {
    //サンプルデータ用
    return { body: "" };
  }
};
