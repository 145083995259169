import { apigw } from "../awsApi";
import { Dispatch } from "@reduxjs/toolkit";

//サンプルデータ
const state = 0;

/**
 * 配信ユーザパスワードポリシーチェック
 * @param {Dispatch} dispatch reducerディスパッチ
 * @param {string} newPassword 新パスワード
 * @param {string} service_id サービスID
 * @param {string | null} nowPassword 現在パスワード
 * @return コンポーネントHTMLコード内容
 */
export const AU0309 = async (
  dispatch: Dispatch,
  newPassword: string,
  serviceId: string,
  nowPassword: string | null,
) => {
  if (process.env.REACT_APP_API_USE_FLG === "true") {
    return apigw(
      "/AU0309",
      nowPassword?{
        new_password: newPassword,
        service_id:serviceId,
        now_password: nowPassword
      }:{
        new_password: newPassword,
        service_id:serviceId,
      },
      dispatch
    );
  } else {
    //サンプルデータ用
    return {state};
  }
};
