import { apigw } from "../awsApi";
import { Dispatch } from "@reduxjs/toolkit";

const rows = [
  {
    tag_id: "1",
    tag_name: "1",
  },
  {
    tag_id: "2",
    tag_name: "2",
  },
  {
    tag_id: "3",
    tag_name: "3",
  },
  {
    tag_id: "4",
    tag_name: "4",
  },
];

/**
 * 授業一覧取得
 * @param {Dispatch} dispatch reducerディスパッチ
 * @param {string} userClass ユーザ区分
 * @return コンポーネントHTMLコード内容
 */
export const TG0301 = async (dispatch: Dispatch, userClass: string) => {
  if (process.env.REACT_APP_API_USE_FLG === "true") {
    return apigw(
      "/TG0301",
      {
        user_class: userClass,
      },
      dispatch
    );
  } else {
    //サンプルデータ用
    return { state: "0", body: rows };
  }
};
