import { apigw } from "../awsApi";
import { Dispatch } from "@reduxjs/toolkit";


//サンプルデータ
// const state = 0;

/**
 * カスタムアンケート削除
 * @param {Dispatch} dispatch           reducerディスパッチ
 * @param {string} classId              授業ID
 * @param {string} questionNo           質問No.
 * @param {string} updateDate           更新日時
 * @return コンポーネントHTMLコード内容
 */

export const OQ0304 = async (
  dispatch: Dispatch,
  class_id: string,
  question_no: string,
  update_date: Date
  ) => {
    if (process.env.REACT_APP_API_USE_FLG === "true") {
      return apigw(
        "/OQ0304",
        {
        class_id,
        question_no,
        update_date
        },
        dispatch
      );
    } else {
      //サンプルデータ用
      return {
        state: "0",
        error_code: "",
        body: {},
      };
    }
  };