import { apigw } from "../awsApi";
import { Dispatch } from "@reduxjs/toolkit";

const rows = {
    status:0,
    error_code : null,
    body:null
}

/**
 * 録画リソースID一時保存
 * @param {Dispatch} dispatch reducerディスパッチ
 * @param {string} classId 授業ID
 * @param {string} resourceId 録画リソースID
 * @return コンポーネントHTMLコード内容
 */
export const ST0304 = async (
  dispatch: Dispatch,
  classId:string,
  resourceId:string
) => {
  if (process.env.REACT_APP_API_USE_FLG === "true") {
    return apigw(
      "/ST0304",
      {
        class_id:classId,
        resource_id:resourceId
      },
      dispatch
    );
  } else {
    //サンプルデータ用
    return { body: rows };
  }
};