import IconButton from "@material-ui/core/IconButton";
import { GiStraightPipe } from "react-icons/gi";
import { BsTriangle, BsSquare, BsCircle } from "react-icons/bs";

export const ShapeSetting = (props: any) => {
  const handleChange = (value: string) => {
    if (props.callback) props.callback(value);
  };
  return (
    <div
      style={{
        backgroundColor: "#ffffff",
        borderRadius: "3px",
        border: "1px solid #dfe2e5",
      }}
    >
      <ul className="shape-list">
        <li>
          <IconButton
            onClick={() => {
              handleChange("line");
            }}
          >
            <GiStraightPipe className="fontSize40" />
          </IconButton>
        </li>
        <li>
          <IconButton
            onClick={() => {
              handleChange("triangle");
            }}
          >
            <BsTriangle className="fontSize40" />
          </IconButton>
        </li>
      </ul>
      <ul className="shape-list">
        <li>
          <IconButton
            onClick={() => {
              handleChange("square");
            }}
          >
            <BsSquare className="fontSize40" />
          </IconButton>
        </li>
        <li>
          <IconButton
            onClick={() => {
              handleChange("circle");
            }}
          >
            <BsCircle className="fontSize40" />
          </IconButton>
        </li>
      </ul>
    </div>
  );
};
