import axios from "axios";

const customerKey = process.env.REACT_APP_AGORA_REC_CUSTOMERKEY;
const customerSecret =  process.env.REACT_APP_AGORA_REC_CUSTOMERSECRET;
const plainCredential = customerKey + ":" + customerSecret;
const encodedCredential = Buffer.from(plainCredential).toString('base64')
const authorizationField = "Basic " + encodedCredential;

const instance = axios.create({
  baseURL: "https://api.agora.io/dev/v1/channel/user/",
  withCredentials: true,
  timeout: 100000,
  headers: {
    'Authorization': authorizationField,
    'Accept': "application/json",
    "Content-Type": "application/json;charset=utf-8",
  },
});


export const apiAgoraUserCheck = async (url:string) => {
  let responceData;
  await instance
    .get(url)
    //通信成功時
    .then((response) => {
      // 200リクエストが成功
      if (response.status === 200) {
        responceData = response;
      } else {
        console.log("err");
        responceData = null;
      }
    })
    // catchでエラー時
    .catch((err) => {
      console.log("err:", err);
      responceData = null;
    });
  return responceData;
};