import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextFieldBox } from "../atoms/TextFieldBox";
import Grid from "@material-ui/core/Grid";
import { InputDate } from "../atoms/InputDate";

const useStyles = makeStyles((theme) => ({
  textBox: {
    padding: `${theme.spacing(1)}px 0`,
  },
  label: {
    // width: "120px",
    // textAlign: "right",
    // paddingRight: "10px",
  },
  lab: {
    textAlign: "center",
    minWidth: "30px",
  },
  inputGroup: {
    display: "flex",
  },
}));
/**
 * @type 日付選択インターフェイス
 */
type DateBoxProps = {
  label?: string;
  name?: string;
  valueFrom?: string;
  valueTo?: string;
  width?: string;
  maxWidth?: string;
  icon?: string;
  placeholder?: string;
  errorFrom?: boolean;
  errorTo?: boolean;
  helperTextFrom?: string;
  helperTextTo?: string;
  multiline?: boolean;
  fullWidth: boolean;
  row?: number;
  onChangeFrom?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlurFrom?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onChangeTo?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlurTo?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  isSafari?: boolean;
};
/**
 * 共有日付選択
 * @param {string} label タイトル内容
 * @param {Item[]} name ボックス名前
 * @param {string} valueFrom 開始日の値
 * @param {string} valueTo 終了日の値
 * @param {string} width 横幅
 * @param {string} maxWidth 最大横幅
 * @param {boolean} errorFrom 開始日エラーフラグ
 * @param {boolean} errorTo 終了日エラーフラグ
 * @param {string} helperTextFrom 開始日ボックスの下に表示内容
 * @param {string} helperTextTo 終了日ボックスの下に表示内容
 * @param {function} onChangeFrom 開始日変更時のハンドル処理
 * @param {function} onBlurFrom 開始日入力時のハンドル処理
 * @param {function} onChangeTo 終了日変更時のチェック判別
 * @param {function} onBlurTo 終了日入力時のハンドル処理
 * @param {boolean} isSafari ブラウザがSafariかどうかフラグ
 * @return 共有日付選択
 */
export const DateBox: React.FC<DateBoxProps> = ({
  label = "",
  name = "",
  valueFrom = "",
  valueTo = "",
  width = "",
  maxWidth = "",
  errorFrom = false,
  errorTo = false,
  helperTextFrom = "",
  helperTextTo = "",
  onChangeFrom = () => {},
  onBlurFrom = () => {},
  onChangeTo = () => {},
  onBlurTo = () => {},
  isSafari = false,
}: DateBoxProps) => {
  const classes = useStyles();
  //const { title, value, width, icon, placeholder, onChange, error } = props

  return (
    <div
      className={classes.textBox}
      style={{ width: width, maxWidth: maxWidth, minWidth: "70px" }}
    >
      <Grid container direction="row" justify="flex-start" alignItems="center">
        <Grid item xs={4} sm={3} className={classes.label}>
          <label className={classes.label}>{label}</label>
        </Grid>
        <Grid item xs={8} sm={8}>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
          >
            <Grid item xs={"auto"} sm={"auto"}>
              {isSafari ? (
                <InputDate
                  name={name + "_from"}
                  value={valueFrom}
                  error={errorFrom}
                  helperText={helperTextFrom}
                  onChange={onChangeFrom}
                  onBlur={onBlurFrom}
                />
              ) : (
                <TextFieldBox
                  size={"small"}
                  error={errorFrom}
                  fullWidth={false}
                  helperText={helperTextFrom}
                  // placeholder={""}
                  name={name + "_from"}
                  onBlur={onBlurFrom}
                  onChange={onChangeFrom}
                  type="date"
                  value={valueFrom}
                  variant="outlined"
                  inputProps={{
                    style: {
                      height: "26px",
                      padding: "6px 10px",
                    },
                  }}
                />
              )}
            </Grid>
            <Grid item xs={"auto"} sm={"auto"} className={classes.lab}>
              ～
            </Grid>
            <Grid item xs={"auto"} sm={"auto"}>
              {isSafari ? (
                <InputDate
                  name={name + "_to"}
                  value={valueTo}
                  error={errorTo}
                  helperText={helperTextTo}
                  onChange={onChangeTo}
                  onBlur={onBlurTo}
                />
              ) : (
                <TextFieldBox
                  error={errorTo}
                  fullWidth={false}
                  helperText={helperTextTo}
                  // placeholder={""}
                  name={name + "_to"}
                  onBlur={onBlurTo}
                  onChange={onChangeTo}
                  type="date"
                  value={valueTo}
                  variant="outlined"
                  inputProps={{
                    style: {
                      height: "26px",
                      padding: "6px 10px",
                    },
                  }}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
