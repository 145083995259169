import { apigw } from "../awsApi";
import { Dispatch } from "@reduxjs/toolkit";

const rows = {
  status: 0,
  error_code: null,
  body: null,
};

/**
 * 視聴ログ更新
 * @param {Dispatch} dispatch reducerディスパッチ
 * @param {string} classId 授業ID
 * @param {string} elapsedTime 経過時間
 * @param {string} viewingDate 視聴日時
 * @param {string} streamingClass 配信区分
 * @param {number} lecFlg 授業ステータス区分 0：授業開始前 1：授業開始後
 * @param {string} agoraUid AgoraUID
 * @param {string} envFlg 環境フラグ
 * @return コンポーネントHTMLコード内容
 */
export const ST0311 = async (
  dispatch: Dispatch,
  classId: string,
  elapsedTime: number,
  viewingDate: string,
  streamingClass: string,
  lecFlg: string,
  agoraUid: string,
  envFlg:string
) => {
  if (process.env.REACT_APP_API_USE_FLG === "true") {
    return apigw(
      "/ST0311",
      {
        class_id: classId,
        elapsed_time: elapsedTime,
        viewing_date: viewingDate,
        streaming_class: streamingClass,
        lec_flg: lecFlg,
        agora_uid: agoraUid,
        envFlg:envFlg
      },
      dispatch
    );
  } else {
    //サンプルデータ用
    return { body: rows };
  }
};
