import { apigw } from "../awsApi";
import { Dispatch } from "@reduxjs/toolkit";

//サンプルデータ
const state = 0;

/**
 * 視聴ユーザパスワード変更
 * @param {Dispatch} dispatch reducerディスパッチ
 * @param {string} firstLogin 初回フラグ
 * @param {string|null} PasswordExpireDate パスワード有効期限
 * @param {string} nowPassword  現在のパスワード
 * @param {string} newPassword 　新しいパスワード
 * @param {varchar} code リセットコード
 * @return コンポーネントHTMLコード内容
 */
export const AU0308 = async (
  dispatch: Dispatch,
  firstLogin: string,
  nowPassword: string,
  newPassword: string,

) => {
  if (process.env.REACT_APP_API_USE_FLG === "true") {
    return apigw(
      "/AU0308",
      {
        is_first_login: firstLogin,
        now_password: nowPassword,
        new_password: newPassword,
      },
      dispatch
    );
  } else {
    //サンプルデータ用
    return {state};
  }
};
