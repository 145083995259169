import { apigw } from "../awsApi";
import { Dispatch } from "@reduxjs/toolkit";

/**
 * 視聴ユーザログイン実行
 * @param {Dispatch} dispatch reducerディスパッチ
 * @param {string} userId 管理ユーザID
 * @param {string} password パスワード
 * @return レスポンスデータ
 */
export const AU0305 = async (
  dispatch: Dispatch,
  userId: string,
  password: string,
  serviceId: string,
  force_login?: string
) => {
  if (process.env.REACT_APP_API_USE_FLG === "true") {
    let param: any = {
      user_id: userId,
      password: password,
      service_id: serviceId,
      user_class: "4",
    };
    if (force_login) {
      param.force_login = "1";
    }
    return apigw(
      "/AU0305",
      param,
      dispatch
    );
  } else {
    //サンプルデータ用
    return {
      state: "0",
      error_code: "",
      body: {
        user_id: "1", //配信ユーザID
        user_name: "test",
        language: "ja", //表示言語ID
        mailaddress: "test@test.com",
        password: "a", //パスワード
        password_expire_date: null, //パスワード有効期限
        session_id: "n8HWm3bM64nQyDlKJ5yazeRVRon18osR", //セッションID
        is_first_login: "1", //初回フラグ
        user_class: "3", //ユーザ区分
        system_class: "", //システム区分
        service_id: "12345", //サービスID
      },
    };
  }
};
