import React from "react";
import Grid from "@material-ui/core/Grid";
import Pagination from "@material-ui/lab/Pagination";


/**
 * 共有ページング
 * @param {number} pageNo 現在ページ番号
 * @param {number} itemCount 現在取得件数
 * @param {number} diaplayCount 表示件数
 * @param {number} totalCount 合計件数
 * @param {number} pageCount 合計ページ数
 * @param {function} onChange 変更ハンドル処理

 * @return 共有ページング
 */
export const PaginationBox = (props: any) => {
  const {
    pageNo,
    itemCount,
    diaplayCount,
    totalCount,
    pageCount,
    onChange
  } = props;

  var startNo = 1 ;
  var endNo = 1 ;
  if(pageNo > 1){
    startNo = ((pageNo-1)*diaplayCount) + 1;
    endNo = ((pageNo-1)*diaplayCount) + itemCount;
  }else{
    endNo = itemCount;
  }

  return (
    <div>
      <Grid container direction="row" justify="flex-end" alignItems="center">
        <Grid className="App-mR10">{startNo}-{endNo} / {totalCount}</Grid>
        <Grid>
          <Pagination
            page={pageNo}
            count={pageCount}
            onChange={onChange}
            showFirstButton
            showLastButton
            variant="outlined"
            shape="rounded"
          />
        </Grid>
      </Grid>
    </div>
  );
};
