import { apigw } from "../awsApi";
import { Dispatch } from "@reduxjs/toolkit";

//サンプルデータ
const rows = [
  {
    download_document_id: "0",
    download_document_name: "XXXXXX.PDF",
    // s3_id: "https://www.africau.edu/images/default/sample.pdf",
    s3_id: "/test/Book1.xlsx",
  },
  {
    download_document_id: "1",
    download_document_name: "XXXXXX.xlsx",
    // s3_id: "https://filesamples.com/samples/document/xlsx/sample3.xlsx",
    s3_id: "/test/sample.pdf",
  },
  {
    download_document_id: "2",
    download_document_name: "XXXXXX.jpg",
    // s3_id: "https://file-examples-com.github.io/uploads/2017/10/file_example_JPG_100kB.jpg",
    s3_id: "/test/img_03.jpg",
  },
  {
    download_document_id: "3",
    download_document_name: "XXXXXX.png",
    // s3_id: "https://sample-videos.com/img/Sample-png-image-100kb.png",
    s3_id: "/test/img_04.jpg",
  },
];

/**
 * ダウンロード資料一覧取得
 * @param {Dispatch} dispatch reducerディスパッチ
 * @param {string} classId サービスID
 * @return コンポーネントHTMLコード内容
 */
export const CT0302 = async (dispatch: Dispatch, classId: any) => {
  if (process.env.REACT_APP_API_USE_FLG === "true") {
    return apigw(
      "/CT0302",
      {
        class_id: classId,
      },
      dispatch
    );
  } else {
    //サンプルデータ用
    return { body: rows };
  }
};
