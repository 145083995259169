import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { FormatMessage } from "../../components/atoms/FormatMessage";
// import useAgora from "../../utils/useAgora";

const useStyles = makeStyles(() => ({
  margin: {
    width: "200px",
    margin: "0 10px",
  },
  margin10: {
    margin: "20px",
  },
  marginT10: {
    marginTop: "20px",
  },
}));

type deviceItem = {
  devaiceId: string;
  groupId: string;
  kind: string;
  label: string;
};

export default function VideoSetting(props: any) {
  const classes = useStyles();
  const [selectCamera, setSelectCamera] = React.useState("");
  const [selectMic, setSelectMic] = React.useState("");
  const [cameraList, setCameraList] = React.useState<deviceItem[]>();
  const [micList, setMicList] = React.useState<deviceItem[]>();
  // const { microphoneSetDevice, cameraSetDevice } = useAgora(props.client);
  let selectMicMsg = FormatMessage({ id: "Cls008.Dialog.MicSelectText" });

  const cameraChange = (event: any) => {
    props.cameraChange(event.target.value);
    props.setSelectCamera(event.target.value);
    setSelectCamera(event.target.value);
    props.setSelectMic("");
    setSelectMic("");
  };
  const micChange = (event: any) => {
    props.micChange(event.target.value);
    props.setSelectMic(event.target.value);
    setSelectMic(event.target.value);
  };

  useEffect(() => {
    setCameraList(props.cameraList);
    setMicList(props.micList);
    setSelectCamera(props.selectCamera);
    setSelectMic(props.selectMic);
  }, [props.cameraList, props.micList]);

  return (
    <div className={classes.margin10}>
      <div>
        <FormControl className={classes.margin}>
          <InputLabel id="demo-customized-select-label">カメラ</InputLabel>

          <Select
            labelId="demo-customized-select-label"
            id="demo-customized-select"
            value={selectCamera}
            onChange={cameraChange}
          >
            {!cameraList ? (
              <MenuItem key="none" value="" style={{ height: 36 }}>
                {null}
              </MenuItem>
            ) : null}
            {cameraList?.map((camera: any) => (
              <MenuItem key={camera.deviceId} value={camera.label}>
                {camera.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      <div className={classes.marginT10}>
        <FormControl className={classes.margin}>
          <InputLabel id="demo-customized-select-label">マイク</InputLabel>
          <Select
            labelId="demo-customized-select-label"
            id="demo-customized-select"
            value={selectMic}
            onChange={micChange}
          >
            {!micList ? (
              <MenuItem key="none" value="" style={{ height: 36 }}>
                {null}
              </MenuItem>
            ) : null}
            {micList?.map((mic: any) => (
              <MenuItem key={mic.deviceId} value={mic.label}>
                {mic.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <span style={{ color: "red" }}>{selectMic ? null : selectMicMsg}</span>
    </div>
  );
}
