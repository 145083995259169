export const enLocaleData = {
  // サインイン画面
  "SignIn.StreamTitle": "Stream SignIn",
  "SignIn.ViewerTitle": "Viewer SignIn",
  "SignIn.UserId": "UserId",
  "SignIn.UserId.Required": "Please enter your userId",
  "SignIn.Password": "Password",
  "SignIn.Password.Required": "Please enter your password",
  "SignIn.Text.Lock":
    "If you enter the wrong password more than 6 times in a row, your account will be locked and you will not be able to log in for a certain period of time. \n If the lock is applied, it cannot be released until a certain period of time has passed.",
  "SignIn.Button": "Sign In",
  "SignIn.Language": "Language",

  // Top画面
  "Top.Title": "Manage System Top",
  "Top.Label.ServiceId": "serviceID",
  "Top.Label.Service": "service",
  "Top.Label.Customer": "customer",
  "Top.Label.ContractStartDate": "contract start date",
  "Top.Label.Status": "status",
  "Top.Button.UsageList": "Usage list",
  "Top.Button.NewRegister": "New register",
  "Top.Button.Search": "Search",

  // Menu
  "Menu.Top": "Top",
  "Menu.CountInfo": "Account Information",
  "Menu.Logout": "Logout",

  // フッター（Copyright）
  "Copyright.TermsOfUse": "Terms of use",
  "Copyright.PrivacyPolicy": "Privacy policy",
  "Copyright.HelpSupport": "Help support",
  "Copyright.OperatingCompany": "Operating company",
  "Copyright.SystemVersion": "System version",
};
