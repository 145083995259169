/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
// import ActionComand from "./comand";

export default function RadioButtonsGroup(props: any) {
  const [scaleValue, setScaleValue] = React.useState("1");
  const handleChange = (event: any) => {
    setScaleValue(event.target.value);
    props.changeScale(event.target.value);
  };

  useEffect(() => {
    setScaleValue(String(props.scale));
  }, []);

  return (
    <div style={{ margin: "20px" }}>
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="gender"
          name="gender1"
          value={scaleValue}
          onChange={handleChange}
        >
          <FormControlLabel
            value={"1"}
            control={<Radio size="small" />}
            label="X1.0"
          />
          <FormControlLabel
            value={"2"}
            control={<Radio size="small" />}
            label="X2.0"
          />
          <FormControlLabel
            value={"4"}
            control={<Radio size="small" />}
            label="X4.0"
          />
          <FormControlLabel
            value={"8"}
            control={<Radio size="small" />}
            label="X8.0"
          />
        </RadioGroup>
      </FormControl>
    </div>
  );
}
