import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import RefreshIcon from "@material-ui/icons/Refresh";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import { MainLayout } from "../../layouts/MainLayout";
import { FormatMessage } from "../../components/atoms/FormatMessage";
import { useEffect, useState } from "react";

import { US0305 } from "../../utils/api/US0305";
import { US0306 } from "../../utils/api/US0306";
import { store } from "../../reducks/store";
import { changeuserName } from "../../reducks/userSlice";
import React from "react";
import { LoadingBox } from "../../components/atoms/LoadingBox";
import sanitize from "sanitize-html";
import { unescapeStr } from "../../components/function/Function";
/**
 * アカウント編集（視聴ユーザ）
 * @param {object} props 引き継いだオブジェクト
 * @return コンポーネントHTMLコード内容
 */
export const Set007 = (props: any) => {
  const dispatch = useDispatch();
  const [userId, setUserId] = React.useState("");
  const [userName, setUserName] = React.useState("");
  const [handleName, setHandleName] = React.useState("");
  const [mailAddress, setMailAddress] = React.useState("");
  const [updateDate, setUpdateDate] = React.useState("");
  const [duplicationErr, setDuplicationErr] = React.useState(false);
  const [DbErr, setDbErr] = React.useState(false);
  const [exlusiveControlErr, setExlusiveControlErr] = React.useState(false);

  // loadingフラグ
  const [loading, setLoading] = React.useState(false);

  const backCilck = () => {
    dispatch(
      push("/set/006", {
        userId: sanitize(userId),
        userName: sanitize(userName),
        handleName: sanitize(handleName),
        mailAddress: sanitize(mailAddress),
        updateDate: sanitize(updateDate),
        back: true,
      })
    );
  };

  const checkCilck = () => {
    setLoading(true);

    checkMailAddress().then((res) => {
      if (res) {
        updateData().then((res) => {
          if (res) {
            store.dispatch(
              changeuserName({
                user_name: userName,
              })
            );

            setLoading(false);
            dispatch(push("/set/008"));
          }
        });
      } else {
        setLoading(false);
      }
    });
  };

  //データ更新
  const updateData = async () => {
    let updateData = await US0305(
      dispatch,
      userName,
      handleName,
      mailAddress,
      updateDate
    );
    if (updateData && Object.keys(updateData).indexOf("state") >= 0) {
      if (updateData.state === "0") {
        return true;
      } else {
        if (Object.keys(updateData).indexOf("error_code") >= 0) {
          if (updateData.error_code === "04070") {
            setExlusiveControlErr(true);
          } else {
            setDbErr(true);
          }
        }
        setLoading(false);
        return false;
      }
    } else {
      setLoading(false);
      return false;
    }
  };

  //パスワード重複チェック
  const checkMailAddress = async () => {
    if (mailAddress !== "") {
      let checkMailAddress = await US0306(dispatch, mailAddress, updateDate);
      if (
        checkMailAddress &&
        Object.keys(checkMailAddress).indexOf("state") >= 0
      ) {
        if (checkMailAddress.state === "0") {
          return true;
        } else {
          if (Object.keys(checkMailAddress).indexOf("error_code") >= 0) {
            if (checkMailAddress.error_code === "04070") {
              setDuplicationErr(false);
              setExlusiveControlErr(true);
            } else {
              setDuplicationErr(true);
            }
          }
          setLoading(false);
          return false;
        }
      }
    } else {
      return true;
    }
  };

  useEffect(() => {
    let sessionDataValue = localStorage.getItem("Set006_session");
    const getDataValue =
      typeof props.location.state === "undefined"
        ? sessionDataValue
          ? JSON.parse(sessionDataValue)
          : sessionDataValue
        : props.location.state;
    if (typeof props.location.state !== "undefined") {
      localStorage.setItem(
        "Set006_session",
        JSON.stringify(props.location.state)
      );
    }
    setUserId(getDataValue.userId);
    setUserName(getDataValue.userName);
    setHandleName(getDataValue.handleName);
    setMailAddress(getDataValue.mailAddress);
    setUpdateDate(getDataValue.updateDate);
  }, [props.location.state]);

  return (
    <MainLayout>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className="App-paper">
            <Typography
              component="h2"
              variant="h6"
              color="inherit"
              gutterBottom
            >
              {FormatMessage({ id: "Set007.Title" })}
            </Typography>
            {duplicationErr ? (
              <div className="App-Color-Red">
                {FormatMessage({ id: "Set.Text.DuplicationErr" })}
              </div>
            ) : DbErr ? (
              <div className="App-Color-Red">
                {FormatMessage({ id: "Set007.Text.UpdateErr" })}
              </div>
            ) : exlusiveControlErr ? (
              <div className="App-Color-Red">
                {FormatMessage({ id: "Error.ExlusiveControl" })}
              </div>
            ) : null}
            <Grid item xs={12} className="App-alignRight">
              <Button
                variant="contained"
                color="primary"
                startIcon={<ArrowBackIcon />}
                onClick={backCilck}
              >
                {FormatMessage({ id: "Button.EditBack" })}
              </Button>
            </Grid>
            <Grid className="App-box">
              <Grid container spacing={3} className="App-List">
                <Grid item xs={12} sm={3} className="App-alignLeft">
                  {FormatMessage({ id: "Set.Label.ViewerId" })}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="App-text"> {unescapeStr(userId)}</div>
                </Grid>
              </Grid>
              <Grid container spacing={3} className="App-List">
                <Grid item xs={12} sm={3} className="App-alignLeft">
                  {FormatMessage({ id: "Set.Label.ViewerName" })}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="App-text"> {unescapeStr(userName)}</div>
                </Grid>
              </Grid>
              <Grid container spacing={3} className="App-List">
                <Grid item xs={12} sm={3} className="App-alignLeft">
                  {FormatMessage({ id: "Set.Label.HandleName" })}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="App-text"> {unescapeStr(handleName)}</div>
                </Grid>
              </Grid>
              <Grid container spacing={3} className="App-List">
                <Grid item xs={12} sm={3} className="App-alignLeft">
                  {FormatMessage({ id: "Set.Label.MailAddress" })}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="App-text"> {unescapeStr(mailAddress)}</div>
                </Grid>
              </Grid>
              <Grid item xs={12} className="App-alignCenter">
                <Button
                  variant="contained"
                  color="primary"
                  className="App-button_bottom"
                  startIcon={<RefreshIcon />}
                  onClick={checkCilck}
                >
                  {FormatMessage({ id: "Button.Update" })}
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      {loading ? <LoadingBox /> : null}
    </MainLayout>
  );
};
