import { useIntl } from "react-intl";

/**
 * メッセージ表示
 * @param {string} id メッセージID
 * @param {object} option アイテム変換用{key:text}
 * @return メッセージ内容
 */
export const FormatMessage = (props: any) => {
  const { id, option } = props;
  const { formatMessage } = useIntl();

  let formatOption = {
    em: (chunks: string) => <em>{chunks}</em>,
    p: (chunks: string) => <p>{chunks}</p>,
    u: (chunks: string) => <u>{chunks}</u>,
  };
  let result1;
  if (option && Object.keys(option).length > 0) {
    result1 = Object.assign(formatOption, option);
  } else {
    result1 = formatOption;
  }
  return formatMessage({ id: id }, result1);
};
