/* eslint-disable jsx-a11y/alt-text */
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import React, { Fragment, useEffect, useState } from "react";
import { push } from "connected-react-router";
import { getSessionUser } from "../../utils/authFlow";
import { signIn, setUser, signOut } from "../../reducks/userSlice";
// import { setUser } from "../../reducks/userSlice";
// import { changeLanguage } from "../../reducks/userSlice";
// import { useDispatch, useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
// import * as Yup from "yup";
import { Formik } from "formik";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { FormattedMessage, useIntl } from "react-intl";
// import Select from "@material-ui/core/Select";
// import MenuItem from "@material-ui/core/MenuItem";
import { store } from "../../reducks/store";
import Popover from "@material-ui/core/Popover";
import HelpIcon from "@material-ui/icons/Help";
import { Copyright } from "../../layouts/Copyright";
import { AU0305 } from "../../utils/api/AU0305";
import { doubleByteCheck } from "../../components/function/Function";
import { CF0902 } from "../../utils/api/CF0902";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { faSignInAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tooltip from "@material-ui/core/Tooltip";
import { FormatMessage } from "../../components/atoms/FormatMessage";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  textField: {
    marginTop: theme.spacing(3),
  },
  button: {
    marginTop: theme.spacing(3),
  },
  text: {
    marginTop: theme.spacing(1),
    whiteSpace: "pre-line",
  },
  select: {
    margin: theme.spacing(1),
    padding: 0,
    minWidth: 120,
  },
  selectItem: {
    padding: 0,
  },
  text1: {
    marginTop: "20px",
  },
  text2: {
    marginTop: "20px",
  },
  popover: {
    pointerEvents: "none",
  },
  arrow: {
    color: "#3993b2",
    fontSize: "20px",
    width: "20px",
    "&::before": {
      backgroundColor: "#3993b2",
      boxSizing: "border-box",
    },
  },
  tooltip: {
    top: "10px",
    color: "#fff",
    backgroundColor: "#3993b2",
    display: "brock",
  },
}));

let fastAccess = true;
let serviceId: any;
let loginValue: any;
/**
 * ログイン
 * @return コンポーネントHTMLコード内容
 */
export const Log002 = () => {
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();
  const { formatMessage } = useIntl();
  // const [serviceId, setServiceId] = useState("");

  // エラーメッセージフラグ
  const [userIdError, setUserIdError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [loginError1, setLoginError1] = useState("");
  const [loginError2, setLoginError2] = useState("");

  const [mainteFlg, setMainteFlg] = useState(0);
  const [announceMessage, setAnnounceMessage] = useState("");
  const [mainteMessage, setMainteMessage] = useState("");

  // const language = useSelector((state: RootState) => state.user.language);
  localStorage.setItem("userTypeFlg", "2");

  const onClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  //エラーメッセージ初期処理
  const clearErrData = () => {
    setUserIdError("");
    setPasswordError("");
  };

  //エラーメッセージ一覧
  const messageList = {
    userIdError1: formatMessage(
      { id: "HS012" },
      { name: formatMessage({ id: "SignIn.UserId" }) }
    ),
    userIdError2: formatMessage(
      { id: "HS009" },
      {
        name: formatMessage({ id: "SignIn.UserId" }),
        type: formatMessage({ id: "TYPE-1" }),
      }
    ),
    passwordError1: formatMessage(
      { id: "HS012" },
      { name: formatMessage({ id: "SignIn.Password" }) }
    ),
    passwordError2: formatMessage(
      { id: "HS009" },
      {
        name: formatMessage({ id: "SignIn.Password" }),
        type: formatMessage({ id: "TYPE-1" }),
      }
    ),
  };

  const onClickSignIn = async (values: {
    userId: string;
    password: string;
  }) => {
    // 入力内容のチェック処理
    if (errorCheck(values.userId, values.password)) {
      return false;
    }
    loginValue = values;
    let u_id = values.userId.replace(/\s+/g, "");
    let pass = values.password.replace(/\s+/g, "");
    let requst = await AU0305(
      dispatch,
      u_id,
      pass,
      serviceId
    );
    if (
      requst &&
      requst.state === "0" &&
      Object.keys(requst).indexOf("body") >= 0
    ) {
      if (
        requst.body.system_version &&
        localStorage.getItem("systemVersion") &&
        String(requst.body.system_version) !==
          String(localStorage.getItem("systemVersion"))
      ) {
        localStorage.setItem("log002Tmp", JSON.stringify(requst.body));
        window.location.reload();
      } else {
        dispatch(signIn(requst.body));
      }
    }
    if (
      requst &&
      requst.state &&
      requst.error_code &&
      requst.state === "3" &&
      requst.error_code === "01005"
    ) {
      setCheckDialog(true);
    }
    if (
      requst &&
      requst.state &&
      requst.error_code &&
      requst.state === "3" &&
      requst.error_code === "01004"
    ) {
      setLoginError1(formatMessage({ id: "SignIn.Error.Login1" }));
      setLoginError2(formatMessage({ id: "SignIn.Error.Login2" }));
    }
  };

  // const onChangeLanguage = (
  //   event: React.ChangeEvent<{
  //     name?: string | undefined;
  //     value: unknown;
  //   }>
  // ) => {
  //   dispatch(changeLanguage({ language: event.target.value }));
  // };

  const footerURLRequest = async () => {
    // サブドメインの取得　//localhostの場合、「localhost」と返ってくる。
    const subDomain = window.location.hostname.split(".").shift();
    // リクエスト処理
    const returnState0902 = await CF0902(dispatch, subDomain, "4");

    // 応答結果をstateにセット
    if (returnState0902) {
      serviceId = returnState0902.body.service_id;
      localStorage.setItem("service_id", returnState0902.body.service_id);
      localStorage.setItem("termsOfUse", returnState0902.body.terms_of_use_url);
      localStorage.setItem(
        "PrivacyPolicy",
        returnState0902.body.privacy_policy_url
      );
      localStorage.setItem(
        "HelpSupport",
        returnState0902.body.help_support_url
      );
      localStorage.setItem(
        "OperatingCompany",
        returnState0902.body.management_company_url
      );
      localStorage.setItem(
        "systemVersion",
        returnState0902.body.system_version
      );
      localStorage.removeItem("menuOpenFlg");
      localStorage.removeItem("session_id");
      localStorage.removeItem("AU0313");
      localStorage.removeItem("AU0314");
      localStorage.removeItem("time_out");
      localStorage.removeItem("sessionToken");
      localStorage.removeItem("Cls001_session");
      localStorage.removeItem("Cls007_session");
      localStorage.removeItem("Cls005_session");
      localStorage.removeItem("Cls006_session");
      localStorage.removeItem("Cls008_session");
      localStorage.removeItem("classTimeCount");
      localStorage.removeItem("classStartTime");
      localStorage.removeItem("classTimeCount");
      localStorage.removeItem("NotClassID");
      localStorage.removeItem("Cls012_session");
      localStorage.removeItem("playclass");
      localStorage.removeItem("Cls013_session");
      localStorage.removeItem("Cls014_message");
      localStorage.removeItem("Cls014_answer");
      localStorage.removeItem("Cls015_session");
      localStorage.removeItem("Cls017_session");
      localStorage.removeItem("Pas001_session");
      localStorage.removeItem("Pas003_session");
      localStorage.removeItem("Pas004_session");
      localStorage.removeItem("Set002_session");
      localStorage.removeItem("Set003_session");
      localStorage.removeItem("Set006_session");
      localStorage.removeItem("Sts001_session");
      localStorage.removeItem("Sts002_session");
      localStorage.removeItem("Sts003_session");
      localStorage.removeItem("Set006_session");
      localStorage.removeItem("websdk_ng_global_parameter");
    }
  };

  // 初回起動時に実行
  if (fastAccess) {
    fastAccess = false;
    footerURLRequest();
  }

  const authCheck = async () => {
    const user = await getSessionUser();
    if (user) {
      //ログイン済みの場合
      user.type = "viewer";
      dispatch(setUser(user));
    }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  // ログイン時のエラーメッセージ
  const errorCheck = (userId: string, password: string) => {
    let errFlg = false;
    clearErrData();
    // 【ユーザID】を入力してください。
    if (userId.length === 0) {
      setUserIdError(messageList.userIdError1);
      errFlg = true;
    }
    // 【ユーザID】は、（半角）で入力してください。
    if (doubleByteCheck(userId)) {
      setUserIdError(messageList.userIdError2);
      errFlg = true;
    }
    // 【パスワード】を入力してください。
    if (password.length === 0) {
      setPasswordError(messageList.passwordError1);
      errFlg = true;
    }
    // 【パスワード】は、（半角）で入力してください。
    if (doubleByteCheck(password)) {
      setPasswordError(messageList.passwordError2);
      errFlg = true;
    }

    return errFlg;
  };

  const [checkDialog, setCheckDialog] = React.useState(false);

  const handleCloseDialog = () => {
    setCheckDialog(false);
  };
  const handleRetrunLogin = async () => {
    let u_id = loginValue.userId.replace(/\s+/g, "");
    let pass = loginValue.password.replace(/\s+/g, "");
    let requst = await AU0305(
      dispatch,
      u_id,
      pass,
      serviceId,
      "1"
    );
    if (
      requst &&
      requst.state &&
      requst.state === "0" &&
      Object.keys(requst).indexOf("body") >= 0
    ) {
      if (
        requst.body.system_version &&
        localStorage.getItem("systemVersion") &&
        String(requst.body.system_version) !==
          String(localStorage.getItem("systemVersion"))
      ) {
        localStorage.setItem("log002Tmp", JSON.stringify(requst.body));
        window.location.reload();
      } else {
        dispatch(signIn(requst.body));
      }
    }
  };

  const [logo, setLogo] = React.useState("");
  const getLogo = async () => {
    if (serviceId) {
      setLogo("/logo/" + serviceId + "/logo.jpg");
    } else {
      setTimeout(getLogo, 100);
    }
  };

  useEffect(() => {
    //ログインチェック
    //authCheck();
    if (localStorage.getItem("log002Tmp")) {
      let loginDataTmp = JSON.parse(String(localStorage.getItem("log002Tmp")));
      localStorage.removeItem("log002Tmp");
      dispatch(signIn(loginDataTmp));
      return;
    }
    dispatch(signOut());
    getLogo();

    axios.get(window.location.origin + "/maintenance.json").then((res) => {
      let data: any = res.data;
      if (data) {
        setMainteFlg(data.mainteFlg);
        setAnnounceMessage(data.announceMessage);
        setMainteMessage(data.mainteMessage);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <Container maxWidth="sm">
        <Grid item xs={12} className="App-login-header">
          <img src={logo} height="24" alt="" />
        </Grid>
        <Grid container direction="column" justify="center" alignItems="center">
          <Typography
            component="h5"
            variant="h5"
            gutterBottom
            style={{ margin: "55px 0 0px 0", textAlign: "center" }}
          >
            <FormattedMessage id="SignIn.ViewerTitle" />
          </Typography>
          <Formik
            initialValues={{
              userId: "",
              password: "",
            }}
            onSubmit={onClickSignIn}
          >
            {({
              // errors,
              handleBlur,
              handleChange,
              handleSubmit,
              // isSubmitting,
              // touched,
              values,
            }) => (
              <form onSubmit={handleSubmit}>
                {mainteFlg === 1 ? (
                  <div
                    style={{
                      color: "red",
                      fontSize: "18px",
                      textAlign: "center",
                    }}
                  >
                    {announceMessage}
                  </div>
                ) : null}
                {mainteFlg === 2 ? (
                  <div
                    style={{
                      color: "red",
                      fontSize: "18px",
                      textAlign: "center",
                    }}
                  >
                    {mainteMessage}
                  </div>
                ) : null}
                {loginError1 ? (
                  <div
                    className={classes.textField}
                    style={{
                      paddingBottom: "10px",
                      color: "red",
                      fontSize: "16px",
                    }}
                  >
                    {loginError1}
                    <br />
                    {loginError2}
                  </div>
                ) : (
                  <span></span>
                )}
                <TextField
                  className={loginError1 ? "" : classes.textField}
                  // error={Boolean(touched.userId && errors.userId)}
                  fullWidth
                  // helperText={touched.userId && errors.userId}
                  helperText={userIdError.length > 0 ? userIdError : ""}
                  error={userIdError.length > 0 ? true : false}
                  label={<FormattedMessage id="SignIn.UserId" />}
                  name="userId"
                  onBlur={handleBlur}
                  onChange={(e: any) => {
                    e.target.value = String(e.target.value)
                      .replaceAll(" ", "")
                      .replaceAll("　", "");
                    handleChange(e);
                  }}
                  type="text"
                  value={values.userId}
                  variant="outlined"
                  inputProps={{
                    style: {
                      height: "38px",
                      padding: "6px 10px",
                    },
                  }}
                />

                <FormControl
                  className={classes.textField}
                  variant="outlined"
                  fullWidth
                >
                  <InputLabel htmlFor="outlined-adornment-password">
                    <FormattedMessage id="SignIn.Password" />
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    name="password"
                    error={passwordError.length > 0 ? true : false}
                    onBlur={handleBlur}
                    onChange={(e: any) => {
                      e.target.value = String(e.target.value)
                        .replaceAll(" ", "")
                        .replaceAll("　", "");
                      handleChange(e);
                    }}
                    type={showPassword ? "text" : "password"}
                    value={values.password}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={onClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    labelWidth={70}
                    inputProps={{
                      style: {
                        height: "38px",
                        padding: "6px 10px",
                      },
                    }}
                  />
                  {passwordError.length > 0 && (
                    <FormHelperText error id="accountId-error">
                      {passwordError}
                    </FormHelperText>
                  )}
                </FormControl>

                <Box>
                  <Button
                    className={classes.button}
                    color={mainteFlg === 2 ? "default" : "primary"}
                    disabled={mainteFlg === 2 ? true : false}
                    fullWidth
                    size="large"
                    type="submit"
                    startIcon={<FontAwesomeIcon icon={faSignInAlt} />}
                    variant="contained"
                    style={{ padding: "3px 0", fontSize: "16px" }}
                  >
                    <FormattedMessage id="SignIn.Button" />
                  </Button>
                  <Grid className={classes.text1}>
                    <Typography
                      color="textPrimary"
                      gutterBottom
                      variant="body2"
                    >
                      <FormattedMessage id="SignIn.Help.Text1" />
                      <Tooltip
                        title={
                          <React.Fragment>
                            <Typography
                              style={{ color: "#fff", margin: "5px" }}
                            >
                              {FormatMessage({ id: "SignIn.Help.Text6" })}
                            </Typography>
                            <Typography
                              style={{ color: "#fff", margin: "5px" }}
                            >
                              {FormatMessage({ id: "SignIn.Help.Text7" })}
                            </Typography>
                          </React.Fragment>
                        }
                        arrow
                        classes={{
                          tooltip: classes.tooltip,
                          arrow: classes.arrow,
                        }}
                      >
                        {/* (1) */}
                        <HelpIcon color="primary" fontSize="small" />
                      </Tooltip>
                      <FormattedMessage id="SignIn.Help.Text2" />
                    </Typography>
                  </Grid>
                  <Grid>
                    <Typography
                      color="textPrimary"
                      gutterBottom
                      variant="body2"
                    >
                      <FormattedMessage id="SignIn.Help.Text3" />
                      <span
                        className={mainteFlg !== 2 ? "App-Link" : ""}
                        onClick={() => {
                          mainteFlg !== 2 &&
                            dispatch(
                              push("/pas/003", { serviceId, userClass: "4" })
                            );
                        }}
                      >
                        <FormattedMessage id="SignIn.Help.Text4" />
                      </span>
                      <FormattedMessage id="SignIn.Help.Text5" />
                    </Typography>
                  </Grid>
                  <Grid className="NWhceckLink">
                    <Typography
                      color="textPrimary"
                      gutterBottom
                      variant="body2"
                    >
                      <FormattedMessage id="SignIn.Check.Text1" />
                      <span
                        className={mainteFlg !== 2 ? "App-Link" : ""}
                        onClick={() => {
                          mainteFlg !== 2 && dispatch(push("/rtm/001"));
                        }}
                      >
                        <FormattedMessage id="SignIn.Check.Text2" />
                      </span>
                      <FormattedMessage id="SignIn.Check.Text3" />
                    </Typography>
                  </Grid>
                </Box>
              </form>
            )}
          </Formik>

          {/* <FormControl className={classes.select}>
            <InputLabel>
              <FormattedMessage id="SignIn.Language" />
            </InputLabel>
            <Select
              value={language}
              onChange={onChangeLanguage}
              label="language"
            >
              <MenuItem value="ja">日本語</MenuItem>
              <MenuItem value={"en"}>英語</MenuItem>
            </Select>
          </FormControl> */}
        </Grid>
      </Container>
      <Copyright footerURLRequest={footerURLRequest} />
      <Dialog open={checkDialog} onClose={handleCloseDialog}>
        <DialogTitle>
          <FormattedMessage id="SignIn.Dialog.Title" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <FormattedMessage id="SignIn.Dialog.Content" />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRetrunLogin}>
            <FormattedMessage id="SignIn.Dialog.LeftButton" />
          </Button>
          <Button onClick={handleCloseDialog}>
            <FormattedMessage id="SignIn.Dialog.RightButton" />
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

// export const SignIn = injectIntl(SignInComponent);
