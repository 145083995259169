import { apigw } from "../awsApi";
import { Dispatch } from "@reduxjs/toolkit";

const rows = {
  app_id: "6a1f14badbce4b7a8fe879096348ced8",
  token:
    "0066a1f14badbce4b7a8fe879096348ced8IABL3W6yX9eUerxVeEnhGAC31MFESclZLT7Vrjct9k0RBQ4In2IAAAAAEABgsZT+Wyo8YQEAAQBaKjxh",
};

/**
 * Agora接続情報取得
 * @param {Dispatch} dispatch reducerディスパッチ
 * @param {string} cannelId チャンネルID
 * @param {string} userId ユーザID
 * @return コンポーネントHTMLコード内容
 */
export const ST0302 = async (
  dispatch: Dispatch,
  cannelId: string,
  userId: string
) => {
  if (process.env.REACT_APP_API_USE_FLG === "true") {
    return apigw(
      "/ST0302",
      {
        user_id: userId,
        channel_id: cannelId,
      },
      dispatch
    );
  } else {
    //サンプルデータ用
    return { body: rows };
  }
};
