import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { push } from "connected-react-router";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import { MainLayout } from "../../layouts/MainLayout";
import { FormatMessage } from "../../components/atoms/FormatMessage";

/**
 * 送信完了
 * @param {object} props 引き継いだオブジェクト
 * @return コンポーネントHTMLコード内容
 */
export const Cls016 = (props: any) => {
  // [ 変数 ]
  const dispatch = useDispatch();
  const [message, setMessage] = React.useState<any | undefined>("");

  // [ 処理 ]
  //ページリロード対応
  // let sessionDateValue = localStorage.getItem("Cls014_session");
  // const getDateValue =
  //   typeof props.location.state === "undefined"
  //     ? sessionDateValue
  //     : props.location.state;
  if (typeof props.location.state !== "undefined")
    localStorage.setItem("Cls014_session", props.location.state);

  const getListData = async (callback: any) => {};

  const listCilck = () => {
    //localStorage.removeItem("playclass");
    localStorage.removeItem("websdk_ng_global_parameter");
    //localStorage.removeItem("ClassClose");
    localStorage.removeItem("Cls012_session");
    localStorage.removeItem("Cls013_session");
    localStorage.removeItem("Cls015_session");
    localStorage.removeItem("Cls014_session");
    //window.open("about:blank", "_self")?.close();
    dispatch(push("/top/002"));
  };

  //初期処理
  useEffect(() => {
    //データ取得
    getListData(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    setMessage(localStorage.getItem("Cls014_message"));
  }, []);

  return (
    <MainLayout>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className="App-paper">
            <Typography
              component="h2"
              variant="h6"
              color="inherit"
              gutterBottom
            >
              {FormatMessage({ id: "Cls016.Title" })}
            </Typography>
            <Grid item xs={12} className="App-alignRight">
              <Button
                variant="contained"
                color="primary"
                className="App-button_bottom"
                startIcon={<ArrowBackIcon />}
                onClick={listCilck}
              >
                {FormatMessage({ id: "Cls016.Button.back" })}
              </Button>
            </Grid>
            <Grid item xs={12} className="App-alignCenter App-text-no-width">
              <Typography
                component="h1"
                variant="body1"
                color="inherit"
                gutterBottom
              >
                {message}
              </Typography>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </MainLayout>
  );
};
