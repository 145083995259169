import { apiAgoraCloudRec,apiAgoraCloudCheck } from "../AgoraCRecApi";
import { Dispatch } from "@reduxjs/toolkit";
import { apigw } from "../awsApi";

//resourceID
const rowsQuire = {
  code: "00000001",
  data: {
    resourceId: "0000000000000000000000000",
  },
};

//start
const rowsStart = {
  code: 200,
  data: {
    sid: "38f8e3cfdc474cd56fc1ceba380d7e1a",
    resourceId:
      "JyvK8nXHuV1BE64GDkAaBGEscvtHW7v8BrQoRPCHxmeVxwY22-x-kv4GdPcjZeMzoCBUCOr9q-k6wBWMC7SaAkZ_4nO3JLqYwM1bL1n6wKnnD9EC9waxJboci9KUz2WZ4YJrmcJmA7xWkzs_L3AnNwdtcI1kr_u1cWFmi9BWAWAlNd7S7gfoGuH0tGi6CNaOomvr7-ILjPXdCYwgty1hwT6tbAuaW1eqR0kOYTO0Z1SobpBxu1czSFh1GbzGvTZG",
  },
};

//stop
const rowsStop = {
  code: 200,
  data: {
    resourceId:
      "JyvK8nXHuV1BE64GDkAaBGEscvtHW7v8BrQoRPCHxmeVxwY22-x-kv4GdPcjZeMzoCBUCOr9q-k6wBWMC7SaAkZ_4nO3JLqYwM1bL1n6wKnnD9EC9waxJboci9KUz2WZ4YJrmcJmA7xWkzs_L3AnNwdtcI1kr_u1cWFmi9BWAWAlNd7S7gfoGuH0tGi6CNaOomvr7-ILjPXdCYwgty1hwT6tbAuaW1eqR0kOYTO0Z1SobpBxu1czSFh1GbzGvTZG",
    sid: "38f8e3cfdc474cd56fc1ceba380d7e1a",
    serverResponse: {
      fileListMode: "json",
      fileList: [
        {
          fileName: "xxx.m3u8",
          trackType: "audio_and_video",
          uid: "123",
          mixedAllUser: true,
          isPlayable: true,
          sliceStartTime: 1562724971626,
        },
        {
          fileName: "xxx.m3u8",
          trackType: "audio_and_video",
          uid: "456",
          mixedAllUser: true,
          isPlayable: true,
          sliceStartTime: 1562724971626,
        },
      ],
      uploadingStatus: "uploaded",
    },
  },
};

/**
 * リソースID取得
 * @param {Dispatch} dispatch reducerディスパッチ
 * @param {string} cname サービスID
 * @param {string} uid ユーザID
 * @param {string} appID
 * @return コンポーネントHTMLコード内容
 */
export const agoraRecQuire = async (
  dispatch: Dispatch,
  cname: string,
  uid: string,
  appID: string
) => {
  if (process.env.REACT_APP_API_USE_FLG === "true") {
    const recId = await apiAgoraCloudRec(
      appID + "/cloud_recording/acquire",
      {
        cname: cname,
        uid: "527841",
        clientRequest: {
          region: "AP",
          resourceExpiredHour: 24,
        },
      },
      dispatch
    );
    return recId;
  } else {
    //サンプルデータ用
    return rowsQuire;
  }
};

export const agoraRecQuire2 = async (
  dispatch: Dispatch,
  cname: string,
  uid: string,
  appID: string
) => {
  if (process.env.REACT_APP_API_USE_FLG === "true") {
    const recId = await apiAgoraCloudRec(
      appID + "/cloud_recording/acquire",
      {
        cname: cname,
        uid: "123456",
        clientRequest: {
          region: "EU",
          resourceExpiredHour: 24,
        },
      },
      dispatch
    );
    return recId;
  } else {
    //サンプルデータ用
    return rowsQuire;
  }
};


/**
 * 録画開始
 * @param {Dispatch} dispatch reducerディスパッチ
 * @param {string} cname サービスID
 * @param {string} uid ユーザID
 * @param {string} token トークン
 * @param {string} appID AppID
 * @param {string} resourceID リソースID
 * @return コンポーネントHTMLコード内容
 */
export const agoraRecStart = async (
  dispatch: Dispatch,
  cname: string,
  uid: any[],
  token: string,
  appID: string,
  resourceID: string,
  path: any
) => {
  if (process.env.REACT_APP_API_USE_FLG === "true") {
   
    const flg = process.env.REACT_APP_AGORA_REC_FLG
    let layoutConfig: any;
    if (uid.length === 2) {
      layoutConfig = [
        {
          uid: String(uid[0]),
          x_axis: 0.0,
          y_axis: 0.0,
          width: 0.5,
          height: 1.0,
          alpha: 1.0,
          render_mode: 0,
        },
        {
          uid: String(uid[1]),
          x_axis: 0.5,
          y_axis: 0.0,
          width: 0.5,
          height: 1.0,
          alpha: 1.0,
          render_mode: 0,
        },
      ];
    } else if (uid.length === 3) {
      layoutConfig = [
        {
          uid: String(uid[0]),
          x_axis: 0.0,
          y_axis: 0.0,
          width: 0.5,
          height: 1.0,
          alpha: 1.0,
          render_mode: 0,
        },
        {
          uid: String(uid[1]),
          x_axis: 0.5,
          y_axis: 0.0,
          width: 0.5,
          height: 0.5,
          alpha: 1.0,
          render_mode: 0,
        },
        {
          uid: String(uid[2]),
          x_axis: 0.5,
          y_axis: 0.5,
          width: 0.5,
          height: 0.5,
          alpha: 1.0,
          render_mode: 0,
        },
      ];
    } else {
      layoutConfig = [
        {
          uid: String(uid[0]),
          x_axis: 0.0,
          y_axis: 0.0,
          width: 1.0,
          height: 1.0,
          alpha: 1.0,
          render_mode: 1,
        },
      ];
    }
    return apigw(
      "/ST0322",
      {
      url:appID + "/cloud_recording/resourceid/" + resourceID + "/mode/mix/start",
      systemFlag:flg,
      customerKey:process.env.REACT_APP_AGORA_REC_CUSTOMERKEY,
      customerSecret:process.env.REACT_APP_AGORA_REC_CUSTOMERSECRET,
      param:{
        cname: cname,
        uid: "527841",
        clientRequest: {
          token: token,
          recordingConfig: {
            channelType: 1,//0: (Default) Communication profile. 1: Live broadcast profile.
            streamTypes: 2,
            maxIdleTime: 300,//5,
            transcodingConfig: {
              width: 1280,
              height: 720,
              fps: 15,
              bitrate: 2260,
              mixedVideoLayout: 3,
              backgroundColor: "#000000",
              layoutConfig: layoutConfig,
            },
          },
          recordingFileConfig: {
            avFileType: ["hls", "mp4"],
          },
          storageConfig: {
            vendor: 1,
            region: 10,
            bucket: process.env.REACT_APP_AWSS3_BUCKET_NAME,
            accessKey: "",
            secretKey: "",
            fileNamePrefix: path,
          },
        },
      }
    },
      dispatch
    );
  } else {
    //サンプルデータ用
    return rowsStart;
  }
};

export const agoraRecStart2 = async (
  dispatch: Dispatch,
  cname: string,
  uid: any[],
  token: string,
  appID: string,
  resourceID: string,
  path: any
) => {
  if (process.env.REACT_APP_API_USE_FLG === "true") {
   
    const flg = process.env.REACT_APP_AGORA_REC_FLG
    let layoutConfig: any;
    if (uid.length === 2) {
      layoutConfig = [
        {
          uid: String(uid[0]),
          x_axis: 0.0,
          y_axis: 0.0,
          width: 0.5,
          height: 1.0,
          alpha: 1.0,
          render_mode: 0,
        },
        {
          uid: String(uid[1]),
          x_axis: 0.5,
          y_axis: 0.0,
          width: 0.5,
          height: 1.0,
          alpha: 1.0,
          render_mode: 0,
        },
      ];
    } else if (uid.length === 3) {
      layoutConfig = [
        {
          uid: String(uid[0]),
          x_axis: 0.0,
          y_axis: 0.0,
          width: 0.5,
          height: 1.0,
          alpha: 1.0,
          render_mode: 0,
        },
        {
          uid: String(uid[1]),
          x_axis: 0.5,
          y_axis: 0.0,
          width: 0.5,
          height: 0.5,
          alpha: 1.0,
          render_mode: 0,
        },
        {
          uid: String(uid[2]),
          x_axis: 0.5,
          y_axis: 0.5,
          width: 0.5,
          height: 0.5,
          alpha: 1.0,
          render_mode: 0,
        },
      ];
    } else {
      layoutConfig = [
        {
          uid: String(uid[0]),
          x_axis: 0.0,
          y_axis: 0.0,
          width: 1.0,
          height: 1.0,
          alpha: 1.0,
          render_mode: 1,
        },
      ];
    }
    return apigw(
      "/ST0322",
      {
      url:appID + "/cloud_recording/resourceid/" + resourceID + "/mode/mix/start",
      systemFlag:flg,
      customerKey:process.env.REACT_APP_AGORA_REC_CUSTOMERKEY,
      customerSecret:process.env.REACT_APP_AGORA_REC_CUSTOMERSECRET,
      param:{
        cname: cname,
        uid: "123456",
        clientRequest: {
          token: token,
          recordingConfig: {
            channelType: 1,//0: (Default) Communication profile. 1: Live broadcast profile.
            streamTypes: 2,
            maxIdleTime: 30,//5,
            transcodingConfig: {
              width: 1280,
              height: 720,
              fps: 15,
              bitrate: 2260,
              mixedVideoLayout: 3,
              backgroundColor: "#000000",
              layoutConfig: layoutConfig,
            },
          },
          recordingFileConfig: {
            avFileType: ["hls", "mp4"],
          },
          storageConfig: {
            vendor: 1,
            region: 5,
            bucket: process.env.REACT_APP_AWSS3_BACKUPBUCKET_NAME,
            accessKey: "",
            secretKey: "",
            fileNamePrefix: path,
          },
        },
      }
    },
      dispatch
    );
  } else {
    //サンプルデータ用
    return rowsStart;
  }
};

/**
 * 録画レイアウト変更
 * @param {Dispatch} dispatch reducerディスパッチ
 * @param {string} cname サービスID
 * @param {string} uid ユーザID
 * @param {string} userCount 表示ユーザ数
 * @param {string} appID AppID
 * @param {string} resourceID リソースID
 * @param {string} sid SID
 * @return コンポーネントHTMLコード内容
 */
export const agoraRecChangeLayout = async (
  dispatch: Dispatch,
  cname: string,
  uid: any[],
  userCount: number,
  appID: string,
  resourceID: string,
  sid: string
) => {
  if (process.env.REACT_APP_API_USE_FLG === "true") {
    let layoutConfig: any;
    if (userCount === 2) {
      layoutConfig = [
        {
          uid: String(uid[0]),
          x_axis: 0.0,
          y_axis: 0.0,
          width: 0.5,
          height: 1.0,
          alpha: 1.0,
          render_mode: 0,
        },
        {
          uid: String(uid[1]),
          x_axis: 0.5,
          y_axis: 0.0,
          width: 0.5,
          height: 1.0,
          alpha: 1.0,
          render_mode: 0,
        },
      ];
    } else if (userCount === 3) {
      layoutConfig = [
        {
          uid: String(uid[0]),
          x_axis: 0.0,
          y_axis: 0.0,
          width: 0.5,
          height: 1.0,
          alpha: 1.0,
          render_mode: 0,
        },
        {
          uid: String(uid[1]),
          x_axis: 0.5,
          y_axis: 0.0,
          width: 0.5,
          height: 0.5,
          alpha: 1.0,
          render_mode: 0,
        },
        {
          uid: String(uid[2]),
          x_axis: 0.5,
          y_axis: 0.5,
          width: 0.5,
          height: 0.5,
          alpha: 1.0,
          render_mode: 0,
        },
      ];
    } else {
      layoutConfig = [
        {
          uid: String(uid[0]),
          x_axis: 0.0,
          y_axis: 0.0,
          width: 1.0,
          height: 1.0,
          alpha: 1.0,
          render_mode: 1,
        },
      ];
    }
    let pama = {
      uid: "527841",
      cname: cname,
      clientRequest: {
        mixedVideoLayout: 3,
        backgroundColor: "#000000",
        layoutConfig: layoutConfig,
      },
    };
    return apiAgoraCloudRec(
      appID +
        "/cloud_recording/resourceid/" +
        resourceID +
        "/sid/" +
        sid +
        "/mode/mix/updateLayout",
      pama,
      dispatch
    );
  } else {
    //サンプルデータ用
    return rowsStart;
  }
};

export const agoraRecChangeLayout2 = async (
  dispatch: Dispatch,
  cname: string,
  uid: any[],
  userCount: number,
  appID: string,
  resourceID: string,
  sid: string
) => {
  if (process.env.REACT_APP_API_USE_FLG === "true") {
    let layoutConfig: any;
    if (userCount === 2) {
      layoutConfig = [
        {
          uid: String(uid[0]),
          x_axis: 0.0,
          y_axis: 0.0,
          width: 0.5,
          height: 1.0,
          alpha: 1.0,
          render_mode: 0,
        },
        {
          uid: String(uid[1]),
          x_axis: 0.5,
          y_axis: 0.0,
          width: 0.5,
          height: 1.0,
          alpha: 1.0,
          render_mode: 0,
        },
      ];
    } else if (userCount === 3) {
      layoutConfig = [
        {
          uid: String(uid[0]),
          x_axis: 0.0,
          y_axis: 0.0,
          width: 0.5,
          height: 1.0,
          alpha: 1.0,
          render_mode: 0,
        },
        {
          uid: String(uid[1]),
          x_axis: 0.5,
          y_axis: 0.0,
          width: 0.5,
          height: 0.5,
          alpha: 1.0,
          render_mode: 0,
        },
        {
          uid: String(uid[2]),
          x_axis: 0.5,
          y_axis: 0.5,
          width: 0.5,
          height: 0.5,
          alpha: 1.0,
          render_mode: 0,
        },
      ];
    } else {
      layoutConfig = [
        {
          uid: String(uid[0]),
          x_axis: 0.0,
          y_axis: 0.0,
          width: 1.0,
          height: 1.0,
          alpha: 1.0,
          render_mode: 1,
        },
      ];
    }
    let pama = {
      uid: "123456",
      cname: cname,
      clientRequest: {
        mixedVideoLayout: 3,
        backgroundColor: "#000000",
        layoutConfig: layoutConfig,
      },
    };
    return apiAgoraCloudRec(
      appID +
        "/cloud_recording/resourceid/" +
        resourceID +
        "/sid/" +
        sid +
        "/mode/mix/updateLayout",
      pama,
      dispatch
    );
  } else {
    //サンプルデータ用
    return rowsStart;
  }
};

/**
 * 録画終了
 * @param {Dispatch} dispatch reducerディスパッチ
 * @param {string} cname サービスID
 * @param {string} uid ユーザID
 * @param {string} token トークン
 * @param {string} appID AppID
 * @param {string} resourceID リソースID
 * @param {string} sID SID
 * @return コンポーネントHTMLコード内容
 */
export const agoraRecStop = async (
  dispatch: Dispatch,
  cname: string,
  uid: string,
  token: string,
  appID: string,
  resourceID: string,
  sID: string
) => {
  if (process.env.REACT_APP_API_USE_FLG === "true") {
    return apiAgoraCloudRec(
      appID +
        "/cloud_recording/resourceid/" +
        resourceID +
        "/sid/" +
        sID +
        "/mode/mix/stop",
      {
        cname: cname,
        uid: "527841",
        clientRequest: {
          async_stop: false,
        },
      },
      dispatch
    );
  } else {
    //サンプルデータ用
    return { result: rowsStop };
  }
};

export const agoraRecStop2 = async (
  dispatch: Dispatch,
  cname: string,
  uid: string,
  token: string,
  appID: string,
  resourceID: string,
  sID: string
) => {
  if (process.env.REACT_APP_API_USE_FLG === "true") {
    return apiAgoraCloudRec(
      appID +
        "/cloud_recording/resourceid/" +
        resourceID +
        "/sid/" +
        sID +
        "/mode/mix/stop",
      {
        cname: cname,
        uid: "123456",
        clientRequest: {
          async_stop: false,
        },
      },
      dispatch
    );
  } else {
    //サンプルデータ用
    return { result: rowsStop };
  }
};

/**
 * 録画状況確認
 * @param {Dispatch} dispatch reducerディスパッチ
 * @param {string} appID AppID
 * @param {string} resourceID リソースID
 * @param {string} sID SID
 * @return コンポーネントHTMLコード内容
 */
 export const agoraRecCheck = async (
  dispatch: Dispatch,
  appID: string,
  resourceID: string,
  sID: string
) => {
  if (process.env.REACT_APP_API_USE_FLG === "true") {
    return apiAgoraCloudCheck(
      appID +
        "/cloud_recording/resourceid/" +
        resourceID +
        "/sid/" +
        sID +
        "/mode/mix/query");
  } else {
    //サンプルデータ用
    return { result: rowsStop };
  }
};
