/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import ButtonBase from "@material-ui/core/ButtonBase";
import {
  // getFileS3,
  getFileNewS3,
  // getUrlS3,
  // getFileBase64S3,
  getFileNEwBase64S3,
} from "../../components/function/AwsS3Fun";

export const ShowClassImage: React.FC<any> = (props: {
  S3: any;
  serviceId: string;
  classId: string;
}) => {
  const NotImage = "/img/image-not-found.png";
  const [img, setImg] = React.useState<any>();
  const [fristImgName, setFristImgName] = React.useState<any>();
  let path: any;
  let S3Api: any;

  const getOrderList = async () => {
    // let file: any = await getFileS3(S3Api, path + "/doc/order.json");
    let fristImgList = JSON.parse(
      String(sessionStorage.getItem("fristImgList"))
    );
    if(!fristImgList) fristImgList = {};
    if (fristImgList[props.serviceId + "_" + props.classId]) {
      if (
        fristImgList[props.serviceId + "_" + props.classId] === fristImgName &&
        fristImgName &&
        img
      )
        return;
      setFristImgName(fristImgList[props.serviceId + "_" + props.classId]);
      getImgData(fristImgList[props.serviceId + "_" + props.classId]);
      return;
    }
    let file: any = await getFileNewS3(S3Api, path + "/doc/order.json");
    if (file && file.size > 0) {
      const orderFile: any = await new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.readAsText(file);
        reader.onload = function () {
          if (reader.result) {
            resolve(JSON.parse(String(reader.result)));
          }
        };
      });
      if (orderFile && orderFile.fristImgName) {
        fristImgList[props.serviceId + "_" + props.classId] = orderFile.fristImgName;
        sessionStorage.setItem("fristImgList",JSON.stringify(fristImgList));
        if (orderFile.fristImgName === fristImgName && fristImgName && img)
          return;
        setFristImgName(orderFile.fristImgName);
        getImgData(orderFile.fristImgName);
      } else {
        setImg(null);
      }
    } else {
      setImg(null);
    }
  };

  const getImgData = async (fristImgName: any) => {
    setImg(null);
    // let imgURl: any = await getUrlS3(
    //   S3Api,
    //   path + "/doc/thumbnail/" + fristImgName
    // );
    // let imgURl: any = await getFileBase64S3(
    //   S3Api,
    //   path + "/doc/thumbnail/" + fristImgName
    // );
    let imgURl: any = await getFileNEwBase64S3(
      S3Api,
      path + "/doc/thumbnail/" + fristImgName
    );
    if (imgURl) setImg(imgURl);
  };

  useEffect(() => {
    if (props.serviceId && props.classId && props.S3) {
      path = "haishin/" + props.serviceId + "/" + props.classId;
      S3Api = props.S3;
      getOrderList();
    }
  }, [props.classId, props.serviceId, props.S3]);

  return (
    <ButtonBase className="App-image">
      <img
        className="img"
        alt="complex"
        id={props.classId}
        src={img ? img : NotImage}
      />
    </ButtonBase>
  );
};
