import {
  S3Client,
  PutObjectCommand,
  DeleteObjectCommand,
  ListObjectsCommand,
  GetObjectCommand,
  HeadObjectCommand,
} from "@aws-sdk/client-s3";
import axios from "axios";
import {
  MediaConvertClient,
  CreateJobCommand,
  GetJobCommand,
} from "@aws-sdk/client-mediaconvert";

import { getSignedUrl } from "@aws-sdk/s3-request-presigner";

import { ST0313 } from "../../utils/api/ST0313";
import { ST0326 } from "../../utils/api/ST0326";
async function* dataToUint8Chunks(stream: ReadableStream<Uint8Array>) {
  const reader = stream.getReader();
  try {
    while (true) {
      const { done, value } = await reader.read();
      if (done) return;
      yield value;
    }
  } finally {
    reader.releaseLock();
  }
}
async function streamToString(
  stream: ReadableStream<Uint8Array>
): Promise<string> {
  const buf: Buffer[] = [];
  for await (const chunk of dataToUint8Chunks(stream)) {
    buf.push(Buffer.from(chunk as Uint8Array));
  }
  return Buffer.concat(buf).toString("base64");
}
async function streamToFile(
  stream: ReadableStream<Uint8Array>,
  fileName: string
): Promise<File> {
  const buf: Buffer[] = [];
  for await (const chunk of dataToUint8Chunks(stream)) {
    buf.push(Buffer.from(chunk as Uint8Array));
  }
  return new File(buf, fileName);
}
/**
 * S3Client作成
 * @param {string} accessKeyId
 * @param {string} secretAccessKey
 * @returns S3Client || null
 */
export const createS3 = async () => {
  let tokenInfo = await ST0313();
  if (tokenInfo && Object.keys(tokenInfo).indexOf("body") >= 0) {
    return new S3Client({
      region: "ap-northeast-1",
      credentials: {
        accessKeyId: tokenInfo.body.access_key,
        secretAccessKey: tokenInfo.body.secret_Key,
        sessionToken: tokenInfo.body.session_token,
      },
    });
  }
  return null;
};

/**
 * ファイルアップロード
 * @param {S3Client} s3 Aws SDK S3Client
 * @param {string} fileName ファイル名
 * @param {File} body ファイル内容
 * @returns 実行結果（アップロード情報）
 */
export const uploadS3 = async (s3: S3Client, fileName: string, body: File) => {
  const uploadParams = {
    Bucket: process.env.REACT_APP_AWSS3_BUCKET_NAME,
    Key: fileName,
    Body: body,
    ContentType: body.type,
    CacheControl: "no-cache, no-store",
  };
  try {
    const data = await s3.send(new PutObjectCommand(uploadParams));
    return data ? data : null;
  } catch (err) {
    console.error("uploadS3 err", err);
    return null;
  }
};

/**
 * ファイル削除
 * @param {S3Client} s3 Aws SDK S3Client
 * @param {string} fileName ファイル名
 * @returns 実行結果（アップロード情報）
 */
export const deleteS3 = async (s3: S3Client, fileName: string) => {
  const params = {
    Bucket: process.env.REACT_APP_AWSS3_BUCKET_NAME,
    Key: fileName,
  };
  try {
    const data = await s3.send(new DeleteObjectCommand(params));
    return data ? data : null;
  } catch (err) {
    console.error("deleteS3 err", err);
    return null;
  }
};

/**
 * ファイルリスト取得
 * @param {S3Client} s3 Aws SDK S3Client
 * @param {string} pathName プレフィックス
 * @returns 実行結果（アップロード情報）
 */
export const getListS3 = async (s3: S3Client, pathName: string) => {
  const params = {
    Prefix: pathName,
    Bucket: process.env.REACT_APP_AWSS3_BUCKET_NAME,
  };
  try {
    const data = await s3.send(new ListObjectsCommand(params));
    return data ? data.Contents : [];
  } catch (err) {
    console.error("getListS3 err", err);
    return null;
  }
};

/**
 * ファイル取得
 * @param {S3Client} s3 Aws SDK S3Client
 * @param {string} fileName ファイル名
 * @returns 実行結果（ファイル取得）
 */
export const getFileS3 = async (s3: any, fileName: string) => {
  // const params = {
  //   Bucket: process.env.REACT_APP_AWSS3_BUCKET_NAME,
  //   Key: fileName,
  // };
  // try {
  //   const data: any = await s3.send(new GetObjectCommand(params));
  //   const stream = data.Body as ReadableStream<Uint8Array>;
  //   const bodyFile: File = await streamToFile(stream, fileName);
  //   return bodyFile;
  // } catch (err) {
  //   console.error("getFileS3 err", err);
  //   return null;
  // }
  let url: any;
  let urlInfo = await ST0326();
  if (urlInfo && urlInfo.state === "0") {
    var info: any = urlInfo.body;
    url =
      info.domain.slice(0, -3) +
      fileName +
      "?Policy=" +
      info["Policy"] +
      "&Signature=" +
      info["Signature"] +
      "&Key-Pair-Id=" +
      info["Key-Pair-Id"];
    return new Promise((resolve, reject) => {
      axios
        .get(url, {
          responseType: "arraybuffer",
        })
        .then((response) => {
          const file = new File([response.data], "", {
            type: response.headers["content-type"],
          });
          resolve(file);
        })
        .catch((err) => {
          //console.log("getFileS3 err", err);
        });
    });
  }
  return null;
};

export const getFileNewS3 = async (s3: any, fileName: string) => {
  const params = {
    Bucket: process.env.REACT_APP_AWSS3_BUCKET_NAME,
    Key: fileName,
  };
  try {
    const data: any = await s3.send(new GetObjectCommand(params));
    const stream = data.Body as ReadableStream<Uint8Array>;
    const bodyFile: File = await streamToFile(stream, fileName);
    return bodyFile;
  } catch (err) {
    console.error("getFileS3 err", err);
    return null;
  }
};

/**
 * Base64ファイル取得
 * @param {S3Client} s3 Aws SDK S3Client
 * @param {string} fileName ファイル名
 * @returns 実行結果（Base64ファイル取得）
 */
export const getFileBase64S3 = async (s3: S3Client, fileName: string) => {
  let url: any;
  let urlInfo = await ST0326();
  if (urlInfo && urlInfo.state === "0") {
    var info: any = urlInfo.body;
    url =
      info.domain.slice(0, -3) +
      fileName +
      "?Policy=" +
      info["Policy"] +
      "&Signature=" +
      info["Signature"] +
      "&Key-Pair-Id=" +
      info["Key-Pair-Id"];
    return new Promise((resolve, reject) => {
      axios
        .get(url, {
          responseType: "arraybuffer",
        })
        .then((response) => {
          const base64 = new Buffer(response.data, "binary").toString("base64");
          const prefix = `data:${response.headers["content-type"]};base64,`;
          resolve(prefix + base64);
        })
        .catch((err) => {
          //console.log("getFileBase64S3 err", err);
        });
    });
  }
  return null;

  // const params = {
  //   Bucket: process.env.REACT_APP_AWSS3_BUCKET_NAME,
  //   Key: fileName,
  // };
  // try {
  //   const data: any = await s3.send(new GetObjectCommand(params));
  //   const stream = data.Body as ReadableStream<Uint8Array>;
  //   const bodyContents = await streamToString(stream);
  //   return bodyContents
  //     ? "data:" + data.ContentType + ";base64," + bodyContents
  //     : null;
  // } catch (err) {
  //   console.error("getFileS3 err", err);
  //   return null;
  // }
};

export const getFileNEwBase64S3 = async (s3: S3Client, fileName: string) => {
  const params = {
    Bucket: process.env.REACT_APP_AWSS3_BUCKET_NAME,
    Key: fileName,
  };
  try {
    const data: any = await s3.send(new GetObjectCommand(params));
    const stream = data.Body as ReadableStream<Uint8Array>;
    const bodyContents = await streamToString(stream);
    return bodyContents
      ? "data:" + data.ContentType + ";base64," + bodyContents
      : null;
  } catch (err) {
    console.error("getFileS3 err", err);
    return null;
  }
};
/**
 * ファイルURL取得
 * @param {S3Client} s3 Aws SDK S3Client
 * @param {string} fileName ファイル名
 * @returns 実行結果（ファイルURL取得）
 */
export const getUrlS3 = async (s3: any, fileName: string) => {
  let url: any;
  let urlInfo = await ST0326();
  if (urlInfo && urlInfo.state === "0") {
    var info: any = urlInfo.body;
    url =
      info.domain.slice(0, -3) +
      fileName +
      "?Policy=" +
      info["Policy"] +
      "&Signature=" +
      info["Signature"] +
      "&Key-Pair-Id=" +
      info["Key-Pair-Id"];
  }
  return url;
  // const params = {
  //   Bucket: process.env.REACT_APP_AWSS3_BUCKET_NAME,
  //   Key: fileName,
  // };
  // try {
  //   const command: any = new GetObjectCommand(params);
  //   const url = await getSignedUrl(s3, command, { expiresIn: 3600 * 24 });
  //   return url;
  // } catch (err) {
  //   console.error("getUrlS3 err", err);
  // }
};

/**
 * チェックファイル存在
 * @param {S3Client} s3 Aws SDK S3Client
 * @param {string} fileName ファイル名
 * @returns 実行結果
 */
export const checkFileS3 = async (s3: any, fileName: string) => {
  const params = {
    Bucket: process.env.REACT_APP_AWSS3_BUCKET_NAME,
    Key: fileName,
  };
  try {
    const data: any = await s3.send(new HeadObjectCommand(params));
    console.log("checkFileS3", data);
    return data;
  } catch (err) {
    console.error("checkFileS3 err", err);
    return null;
  }
};

/**
 * aws MediaConvert job追加
 * @param {S3Client} s3 Aws SDK S3Client
 * @param {string} pathName ディレクトリ名
 * @param {string} objectKey ファイル名
 * @returns 実行結果（へｎファイル）
 */
export const createJob = async (pathName: string, objectKey: string) => {
  try {
    let tokenInfo = await ST0313();

    if (tokenInfo && Object.keys(tokenInfo).indexOf("body") >= 0) {
      const ENDPOINT = {
        credentials: {
          accessKeyId: tokenInfo.body.access_key,
          secretAccessKey: tokenInfo.body.secret_Key,
          sessionToken: tokenInfo.body.session_token,
        },
        region: "ap-northeast-1",
        endpoint: "https://1muozxeta.mediaconvert.ap-northeast-1.amazonaws.com",
      };

      const emcClient = new MediaConvertClient(ENDPOINT);
      const params = {
        Queue:
          "arn:aws:mediaconvert:ap-northeast-1:040382675760:queues/Default",
        UserMetadata: {},
        Role: "arn:aws:iam::040382675760:role/service-role/MediaConvert_Default_Role",
        Settings: {
          TimecodeConfig: {
            Source: "ZEROBASED",
          },
          OutputGroups: [
            {
              Name: "File Group",
              Outputs: [
                {
                  ContainerSettings: {
                    Container: "MP4",
                    Mp4Settings: {},
                  },
                  VideoDescription: {
                    CodecSettings: {
                      Codec: "H_264",
                      H264Settings: {
                        MaxBitrate: 5000000,
                        RateControlMode: "QVBR",
                        SceneChangeDetect: "TRANSITION_DETECTION",
                      },
                    },
                  },
                  AudioDescriptions: [
                    {
                      CodecSettings: {
                        Codec: "AAC",
                        AacSettings: {
                          Bitrate: 96000,
                          CodingMode: "CODING_MODE_2_0",
                          SampleRate: 48000,
                        },
                      },
                    },
                  ],
                },
              ],
              OutputGroupSettings: {
                Type: "FILE_GROUP_SETTINGS",
                FileGroupSettings: {
                  Destination:
                    "s3://" +
                    process.env.REACT_APP_AWSS3_BUCKET_NAME +
                    "/" +
                    pathName,
                },
              },
            },
          ],
          Inputs: [
            {
              AudioSelectors: {
                "Audio Selector 1": {
                  DefaultSelection: "DEFAULT",
                },
              },
              VideoSelector: {},
              TimecodeSource: "ZEROBASED",
              FileInput:
                "s3://" +
                process.env.REACT_APP_AWSS3_BUCKET_NAME +
                "/" +
                pathName +
                "tmp/" +
                objectKey,
            },
          ],
        },
        AccelerationSettings: {
          Mode: "DISABLED",
        },
        StatusUpdateInterval: "SECONDS_60",
        Priority: 0,
      };
      const data = await emcClient.send(new CreateJobCommand(params));
      // console.log("Job is created:",data);
      return data;
    }
  } catch (err) {
    console.log("createJob Error", err);
    return null;
  }
};

export const getJob = async (id: string) => {
  const params = {
    Id: id,
  };

  try {
    let tokenInfo = await ST0313();
    if (tokenInfo && Object.keys(tokenInfo).indexOf("body") >= 0) {
      const ENDPOINT = {
        credentials: {
          accessKeyId: tokenInfo.body.access_key,
          secretAccessKey: tokenInfo.body.secret_Key,
          sessionToken: tokenInfo.body.session_token,
        },
        region: "ap-northeast-1",
        endpoint: "https://1muozxeta.mediaconvert.ap-northeast-1.amazonaws.com",
      };

      const emcClient = new MediaConvertClient(ENDPOINT);
      const data = await emcClient.send(new GetJobCommand(params));
      console.log("get listy Job:", data);
    }
  } catch (err) {
    console.log("createJob Error", err);
    return null;
  }
};
