import React, { useEffect } from "react";
// import TextField from "@material-ui/core/TextField";
// import ActionComand from "./comand";
// import GridList from "@material-ui/core/GridList";
import { makeStyles } from "@material-ui/core/styles";
// import GridListTile from "@material-ui/core/GridListTile";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
// import Dialog from "@material-ui/core/Dialog";
// import DialogActions from "@material-ui/core/DialogActions";
// import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from "@material-ui/core/DialogContentText";
// import DialogTitle from "@material-ui/core/DialogTitle";
// import { FormatMessage } from "../../components/atoms/FormatMessage";
// import Card from "@material-ui/core/Card";
import "../../css/cls/cls008.css";
// import {
//   red,
//   blue,
//   green,
//   yellow,
//   purple,
//   orange,
//   lightBlue,
//   lightGreen,
//   pink,
// } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    borderBottom: "1px solid #ccc",
    overflow: "hidden",
  },
  icon: {
    color: "rgba(255, 255, 255, 0.54)",
  },
  colorWhite: {
    color: "white",
  },
  alignCenter: {
    textAlign: "center",
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  button: {
    height: 25,
    width: 300,
    textAlign: "center",
    Enabled: false,
  },
  text: {
    height: 25,
    width: 50,
    textAlign: "center",
    float: "right",
    marginTop: "5px",
    Enabled: false,
    fontSize: 20,
  },
  check: {
    verticalalign: "middle",
  },
  region: {
    height: "auto",
    width: 400,
    overflow: "hidden",
  },
}));
type answer = {
  answer: string;
  answer_no: number;
  count: number;
  percent: number;
};
let answerListAnother: answer[] = [];

export default function QuestionGroup(props: any) {
  const [answerList, setAnswerList] = React.useState<answer[]>([]);
  const [answerCatch, setAnswerCatch] = React.useState(0);
  const [percentFlg, setPercentFlg] = React.useState(false);
  const [updeteFlg, setUpdeteFlg] = React.useState(false);
  const classes = useStyles();

  const QuizOptionButton: React.FC<any> = ({ row = {} }) => {
    return (
      <Button
        variant="contained"
        disableRipple
        style={{ textTransform: "none" }}
      >
        {row.answer}
      </Button>
    );
  };

  /*
  const updetecount = async (callback: any) => {
    setAnswerCatch(answerCatch + 1);
    if (props.answerCatch === 0) {
      return;
    }
    let result = await props.getQuestionResultList();
    if (result) {
      const resultList = Object.entries(result).map(([key, value]) => ({
        key,
        value,
      }));
      for (let i = 0; i < answerListAnother.length; i++) {
        answerListAnother[i].count = Number(
          resultList[answerListAnother[i].answer_no - 1].value
        );
        answerListAnother[i].percent =
          answerListAnother[i].count !== null
            ? Math.round(
                (answerListAnother[i].count / answerCatch) * 100 * 10
              ) / 10
            : 0;
      }
      props.sendAction(props.actionComand.MSG_QUIZ_QUESTIONS, null, {
        answer_options: answerListAnother,
        choice_id: props.choiceId,
      });
      setAnswerList(answerListAnother);
      setUpdeteFlg(!updeteFlg);
    }
  };
*/
  const handleClickClose = () => {
    props.setAnswerCatch(0);
    props.setSentAnswerCatch(0);
    props.setResult([]);
    props.questionResultClose();
    props.closeAnswerToLog();
  };

  useEffect(() => {
    setAnswerList(props.result);
    setUpdeteFlg(!updeteFlg);
    //updetecount(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.result]);

  return (
    <div className={classes.region} onMouseDown={props.popupMousedown}>
      <Grid container spacing={3} className="App-mT10">
        <Grid item xs={12} className="App-alignCenter">
          <Button
            variant="contained"
            color="secondary"
            style={{ float: "right", minWidth: 30 }}
            onClick={handleClickClose}
          >
            ✕
          </Button>
        </Grid>
      </Grid>
      <table width="100%">
        <tbody>
          {answerList.map((item, index) => (
            <tr key={index}>
              <td>
                <div
                  key={item.answer_no}
                  className={"quizResultOption_div answer_" + item.answer_no}
                >
                  <QuizOptionButton row={item} />

                  <label className={classes.text}>
                    {updeteFlg
                      ? percentFlg
                        ? item.percent + "%"
                        : item.count
                      : percentFlg
                      ? item.percent + "%"
                      : item.count}
                  </label>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Grid container spacing={3} className="App-mT10">
        <Grid item xs={12} className="App-alignCenter">
          <Button
            variant="contained"
            color="primary"
            style={{ margin: "10px" }}
            className="App-button_bottom"
            onClick={() => {
              props.switchCountDisplay(answerList, !percentFlg);
              setPercentFlg(!percentFlg);
            }}
          >
            表示切替
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
