import React from "react";

import CircularProgress from "@material-ui/core/CircularProgress";

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      opacity: "0.5",
      backgroundColor: "#999",
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    iconImg: {
      position: "fixed",
      top: "45%",
      left: "45%",
      opacity: "1",
    },
  })
);

export const LoadingBox = () => {
  const classes = useStyles();
  return (
    <div className={classes.backdrop}>
      <CircularProgress color="inherit" className={classes.iconImg}/>
    </div>
  );
};
