import { apigw } from "../awsApi";
import { Dispatch } from "@reduxjs/toolkit";

//サンプルデータ
const state = 0;
const error_code = "9999";
  

/**
 * 公開日時編集
 * @param {Dispatch} dispatch reducerディスパッチ
 * @param {string} download_document_id ダウンロード資料ID
 * @param {string | null} release_date 公開日時
 * @param {string} update_date 更新日時
 * @return コンポーネントHTMLコード内容
 */
export const CT0306 = async (
  dispatch: Dispatch,
  download_document_id: string,
  release_date: string | null,
  update_date: string,

) => {
  if (process.env.REACT_APP_API_USE_FLG === "true") {
    return apigw(
      "/CT0306",
      {
        download_document_id: download_document_id,
        release_date:release_date,
        update_date:update_date,
      },
      dispatch
    );
  } else {
    //サンプルデータ用
    return {state, error_code}
  }
};
