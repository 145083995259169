import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(4),
    textAlign: "center",
  },
}));

export const ErrorPage = () => {
  const classes = useStyles();
  return <Container className={classes.container}>500 Internal Server Error.</Container>;
};