import React from "react";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";
import sanitize from "sanitize-html";


type itemData = {
  id: string;
  name: string;
  url: string;
  width: number; //1~100%
  headAlign: "center" | "inherit" | "justify" | "left" | "right";
  bodyAlign: "center" | "inherit" | "justify" | "left" | "right";
  variant: "body" | "footer" | "head";
  rowspan: string; //縦
  colspan: string; //横
  onChange: any;
  callback: any;
};
type item = {
  children: any;
};

/**
 * 共有データテーブル（複数ヘッダー用）
 * @param {object} items ヘッダー情報
 * @param {object} rows テーブルデータ
 * @param {object} maxHeight テーブルデータエリア最大高さ
 * @return コンポーネントHTMLコード内容
 */
export const DataTableAuto = (props: any) => {
  var { items, rows, maxHeight } = props;
  const dispatch = useDispatch();
  type td = { [key: string]: itemData };

  const linkClick = (url: string) => {
    dispatch(push(sanitize(url)));
  };
  let itemObject = {} as td;
  let itemNum = 0;
  if (Object.keys(itemObject).length === 0) {
    for (let index = 0; index < items.length; index++) {
      const element = items[index] as item;
      for (let index = 0; index < element.children.length; index++) {
        const itemElement = element.children[index] as itemData;
        itemObject[itemElement.id] = itemElement;
        if (!itemElement.colspan || Number(itemElement.colspan) < 1) itemNum++;
      }
    }
  }
  let defaultWidth = Math.ceil(100 / itemNum);

  return (
    <React.Fragment>
      <div className="sticky_table_wrapper">
        <Table size="small" className="sticky_table">
          <TableHead>
            {items.map((list: item, h1: number) => (
              <TableRow key={"table_head_" + h1}>
                {list.children.map((item: itemData, h2: number) => (
                  <TableCell
                    variant={item.variant ? item.variant : "body"}
                    align={item.headAlign ? item.headAlign : "center"}
                    rowSpan={item.rowspan ? Number(item.rowspan) : 1}
                    colSpan={item.colspan ? Number(item.colspan) : 1}
                    key={"table_head_" + h1 + "_" + h2}
                    style={{ width: (item.width ? item.width : defaultWidth) + "%" }}
                  >
                    {item.name}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody
            style={{
              maxHeight: maxHeight,
              overflowY: "auto",
            }}
          >
            {rows.map((row: any, a1: number) => (
              <TableRow key={"table_body_" + a1}>
                {Object.keys(row).map((key: string, a2: number) =>
                  Object.keys(itemObject).indexOf(key) >= 0 ? (
                    <TableCell
                      key={"table_body_" + key + "_" + a1 + "_" + a2}
                      variant={
                        itemObject[key].variant
                          ? itemObject[key].variant
                          : "body"
                      }
                      align={
                        itemObject[key].headAlign
                          ? itemObject[key].headAlign
                          : "center"
                      }
                      style={{
                        textAlign:
                          itemObject[key].bodyAlign
                            ? itemObject[key].bodyAlign
                            : "left",
                        width:
                          (itemObject[key].width
                            ? itemObject[key].width
                            : defaultWidth) + "%"
                      }}
                    >
                      {itemObject[key].id === "CheckBox" ? (
                        <Checkbox
                          size="small"
                          onChange={itemObject[key].onChange}
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      ) : itemObject[key].callback &&
                        typeof itemObject[key].callback === "function" ?
                        !row[key].includes("未視聴") && !row[key].includes("欠席") ?(
                          <div
                          style={{
                            overflowWrap: "anywhere",
                          }}
                          className="App-Link"
                          onClick={() => itemObject[key].callback(row)}
                          dangerouslySetInnerHTML={{
                            __html: row[key],
                          }}
                        ></div>
                      )
                      :(
                        <div
                        style={{
                          overflowWrap: "anywhere",
                        }}
                        dangerouslySetInnerHTML={{
                          __html: row[key],
                        }}
                      ></div>
                      
                      )
                      : itemObject[key].url ? (
                      <div
                        style={{
                          overflowWrap: "anywhere",
                        }}
                        className="App-Link"
                        onClick={() => linkClick(itemObject[key].url)}
                        dangerouslySetInnerHTML={{
                          __html: row[key],
                        }}
                      ></div>
                      ) : (
                      <div
                        style={{
                          overflowWrap: "anywhere",
                        }}
                        dangerouslySetInnerHTML={{
                          __html: row[key],
                        }}
                      ></div>
                      )}
                    </TableCell>
                  ) : null
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </React.Fragment>
  );
};
