import { apigw } from "../awsApi";
import { Dispatch } from "@reduxjs/toolkit";

//サンプルデータ
const rows = 
  {
    code:"1",
    value:"英小文字・数字",
    string_length:"8",
    is_expiration_date:1,
    expiration_date:30
  };

/**
 * パスワードポリシー取得
 * @param {Dispatch} dispatch reducerディスパッチ
 * @param {string} user_class ユーザクラス
 * @param {string} service_id サービスID

 * @return コンポーネントHTMLコード内容
 */
export const CF0304 = async (
  dispatch: Dispatch,
  userClass: string,
  serviceId: string,
  signInFlg: boolean
) => {
  if (process.env.REACT_APP_API_USE_FLG === "true") {
    return apigw(
      "/CF0304",
      {
        user_class: userClass,
        service_id: serviceId,
        signInFlg: signInFlg
      },
      dispatch
    );
  } else {
    //サンプルデータ用
    return {body: rows };
  }
};
