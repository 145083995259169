import { useState, useEffect } from "react";
import { createS3, uploadS3 } from "../components/function/AwsS3Fun";
import AgoraRTC, {
  IAgoraRTCClient,
  IAgoraRTCRemoteUser,
  MicrophoneAudioTrackInitConfig,
  CameraVideoTrackInitConfig,
  IMicrophoneAudioTrack,
  ICameraVideoTrack,
} from "agora-rtc-sdk-ng";
let userNum: number = 0;
let micTrack: any;
let camTrack: any;
let Log: any[] = [];
export default function useAgora(
  client: IAgoraRTCClient | undefined,
  checkVideo?: any,
  callback?: any
): {
  localAudioTrack: any;
  localVideoTrack: any;
  joinState: boolean;
  leave: Function;
  join: Function;
  publish: Function;
  unpublish: Function;
  microphoneSetEnabled: Function;
  cameraSetEnabled: Function;
  remoteUsers: any[];
  remoteLiveUsers: any[];
  streamer: any;
  shareWindow: any[];
  setDevice: Function;
  microphoneTrack: IMicrophoneAudioTrack | undefined;
  cameraTrack: ICameraVideoTrack | undefined;
  getCameraMuted: Function;
  microphoneSetDevice: Function;
  cameraSetDevice: Function;
  microphoneSetDisabled: Function;
  microphoneSetChange: Function;
} {
  const [localVideoTrack, setLocalVideoTrack] = useState<any>(undefined);
  const [localAudioTrack] = useState<any>(undefined);
  // const [localAudioTrack, setLocalAudioTrack] = useState<any>(undefined);
  const [microphoneTrack, setmicrophoneTrack] = useState<any>(undefined);
  const [cameraTrack, setcameraTrack] = useState<any>(undefined);

  const [joinState, setJoinState] = useState(false);

  const [remoteUsers, setRemoteUsers] = useState<IAgoraRTCRemoteUser[]>([]);
  const [shareWindow, setShareWindow] = useState<IAgoraRTCRemoteUser[]>([]);
  const [streamer, setStreamer] = useState<IAgoraRTCRemoteUser>();
  const [remoteLiveUsers, setRemoteLiveUsers] = useState<IAgoraRTCRemoteUser[]>(
    []
  );

  async function createLocalTracks(
    videoUse: boolean,
    micUse: boolean,
    audioConfig?: MicrophoneAudioTrackInitConfig,
    videoConfig?: CameraVideoTrackInitConfig
  ): Promise<any> {
    try {
      if (audioConfig) audioConfig.encoderConfig = "music_standard";
      micTrack = await AgoraRTC.createMicrophoneAudioTrack(audioConfig);
      if (micTrack) {
        if (micUse) {
          AgoraRTC.checkAudioTrackIsActive(micTrack).then((result) => {
            if (micTrack) setmicrophoneTrack(micTrack);
          });
        } else {
          AgoraRTC.checkAudioTrackIsActive(micTrack).then((result) => {
            if (micTrack)
              micTrack.setMuted(true).then(() => {
                setmicrophoneTrack(micTrack);
              });
          });
        }
      }
      if (!videoConfig) videoConfig = { encoderConfig: "240p_4" };
      camTrack = await AgoraRTC.createCameraVideoTrack(videoConfig);
      if (camTrack) {
        // if (!videoConfig)
        //   await camTrack.setEncoderConfiguration({
        //     width: 528,
        //     height: 297,
        //     frameRate: 15,
        //   });
        if (videoUse) {
          AgoraRTC.checkVideoTrackIsActive(camTrack).then((result) => {
            setcameraTrack(camTrack);
            setLocalVideoTrack(camTrack);
          });
        } else {
          AgoraRTC.checkVideoTrackIsActive(camTrack).then((result) => {
            camTrack.setMuted(true).then(() => {
              setLocalVideoTrack(camTrack);
              setcameraTrack(camTrack);
            });
          });
        }
      }

      return { micTrack, camTrack };
    } catch (e) {
      console.log("set track error!", e);
      if (micTrack) {
        micTrack.stop();
        micTrack.close();
      }
      if (camTrack) {
        camTrack.stop();
        camTrack.close();
      }
      return e;
    }
  }

  async function join(
    appid: string,
    channel: string,
    token: string,
    uid: any,
    flg?: any,
    fileData?: any
  ) {
    try {
      if (!client) return;
      Log = [];
      var userAgent = window.navigator.userAgent.toLowerCase();
      var supporflg = false;
      if (
        !(
          userAgent.indexOf("iphone") > 0 ||
          userAgent.indexOf("android") > 0 ||
          userAgent.indexOf("mobile") > 0
        )
      ) {
        if (
          userAgent.indexOf("msie") !== -1 ||
          userAgent.indexOf("trident") !== -1
        ) {
          supporflg = false;
        } else if (userAgent.indexOf("edg") > 0) {
          supporflg = false;
        } else if (
          userAgent.indexOf("chrome") !== -1 ||
          userAgent.indexOf("crios") !== -1
        ) {
          supporflg = true;
        } else if (userAgent.indexOf("safari") !== -1) {
          const cutSt = userAgent.indexOf("version");
          const cutEd = userAgent.indexOf(".", cutSt);
          const bwVer = userAgent.substring(cutSt + 8, cutEd);
          if (Number(bwVer) >= 14) {
            supporflg = true;
          }
        }
      }

      const userid = await client.join(appid, channel, token, uid);
      Log.push("Join Clear" + userid);
      await client.setStreamFallbackOption(20000, 1);
      Log.push("Fallback" + 20000);

      if (supporflg) {
        var cl: any = client;
        if (!cl._isDualStreamEnabled)
          await client.enableDualStream().then(() => {
            if (flg)
              client.setLowStreamParameter({
                width: 128,
                height: 72,
                bitrate: 120,
              });
          });
        console.log("DualStream ON");
        // client.enableDualStream().then(() => {
        //   client.setLowStreamParameter({ height: 720, width: 1280 });
        // });
      }

      setJoinState(true);
      Log.push("Join Mesod OK");
      return { state: "OK", agoraUserId: userid };
    } catch (e: any) {
      console.log("Join Mesod Error", e);
      Log.push("Join Mesod Error");
      Log.push(JSON.stringify(e));
      return e.code;
    } finally {
      if (process.env.REACT_APP_AWS_S3_UPLOG === "true") {
        if (fileData) {
          var jsontext = JSON.stringify(Log);
          var blob = new Blob([jsontext], { type: "text/json" });
          var jsonFile = new File([blob], "message.json");
          const date = new Date().getTime();
          var S3: any = await createS3();
          await uploadS3(
            S3,
            "haishin/" +
              fileData.service_id +
              "/" +
              fileData.classId +
              "/Log/" +
              fileData.userId +
              "/" +
              fileData.userId +
              "_join_" +
              date +
              ".json",
            jsonFile
          );
        }
      }
    }
  }

  async function publish(mic?: any, vid?: any, callback?: any) {
    try {
      if (!client) return;
      if ((mic && vid) || mic) {
        //await vid.setEncoderConfiguration({ width: 640, height: 360 });
        const track = mic && vid ? [mic, vid] : mic;
        await client.publish(track);
        (window as any).client = client;
        (window as any).videoTrack = vid;
        setLocalVideoTrack(vid);
        setcameraTrack(vid);
        setmicrophoneTrack(mic);
        return { state: "OK" };
      }
      if (microphoneTrack && cameraTrack) {
        if (callback) {
          cameraTrack.on("track-ended", callback);
          microphoneTrack.on("track-ended", callback);
        }
        let list: any = [];
        let share: any = [];
        let live: any = [];
        for (let index = 0; index < client.remoteUsers.length; index++) {
          const element: any = client.remoteUsers[index];
          if (element.uid === 20000) {
            list.push(element);
            setStreamer(element);
          } else {
            if (element._audio_added_ && element._video_muted_) {
              live.push(element);
            } else if (element._video_added_) {
              if (element.uid === 19999) {
                share.push(element);
              } else {
                list.push(element);
              }
            }
          }
        }
        if (share.length > 0) {
          switch (list.length) {
            case 0://画面共有+配信
              await camTrack.setEncoderConfiguration({
                width: 640,
                height: 360,
                frameRate: 15,
              });
              break;
            case 1://画面共有+配信+視聴1
              await camTrack.setEncoderConfiguration({
                width: 360,
                height: 360,
                frameRate: 15,
              });
              break;
            case 2://画面共有+配信+視聴1+視聴2
              await camTrack.setEncoderConfiguration({
                width: 320,
                height: 180,
                frameRate: 15,
              });
              break;
          }
        } else {
          switch (list.length) {
            case 0://配信
              await camTrack.setEncoderConfiguration({
                width: 1280,
                height: 720,
                frameRate: 15,
              });
              break;
            case 1://配信+視聴1
              await camTrack.setEncoderConfiguration({
                width: 848,
                height: 480,
                frameRate: 15,
              });
              break;
            case 2://配信+視聴1+視聴2
              await camTrack.setEncoderConfiguration({
                width: 480,
                height: 480,
                frameRate: 15,
              });
              break;
          }
        }
        await client.publish([microphoneTrack, cameraTrack]);
        (window as any).client = client;
        (window as any).videoTrack = cameraTrack;
        return { state: "OK" };
      }
    } catch (e: any) {
      return e.code;
    }
  }

  async function setDevice(state: string | undefined, device: any | undefined) {
    if (!client) return;
    let track: any;
    if (state) {
      try {
        micTrack = await AgoraRTC.createMicrophoneAudioTrack({
          encoderConfig: "music_standard",
        });
        camTrack =
          state === "voice"
            ? null
            : await AgoraRTC.createCameraVideoTrack({
                encoderConfig: "240p_4",
              });
        track = { micTrack, camTrack };
      } catch (e) {
        console.log("set track error!", e);
        if (micTrack) {
          micTrack.stop();
          micTrack.close();
        }
        if (camTrack) {
          camTrack.stop();
          camTrack.close();
        }
        return e;
      }
    } else {
      if (device) {
        track = await createLocalTracks(
          true,
          false,
          { microphoneId: device.micconfig },
          { cameraId: device.camconfig }
        );
      } else {
        track = await createLocalTracks(true, false);
      }
    }

    return track;
  }

  async function unpublish(callback?: any) {
    try {
      if (localAudioTrack) {
        localAudioTrack.stop();
        localAudioTrack.close();
      }
      client?.localTracks.forEach((v) => v.stop());
      client?.localTracks.forEach((v) => v.close());
      if (localVideoTrack) {
        localVideoTrack.stop();
        localVideoTrack.close();
      }
      client?.localTracks.forEach((v) => v.stop());
      client?.localTracks.forEach((v) => v.close());
      if (cameraTrack) {
        cameraTrack.stop();
        cameraTrack.close();
      }
      if (microphoneTrack) {
        microphoneTrack.stop();
        microphoneTrack.close();
      }

      if (micTrack) {
        micTrack.stop();
        micTrack.close();
      }
      if (camTrack) {
        camTrack.stop();
        camTrack.close();
      }
      if (client && client.localTracks) {
        for (let index = 0; index < client.localTracks.length; index++) {
          const v = client.localTracks[index];
          v.stop();
          v.close();
        }
      }
      setLocalVideoTrack(null);
      setcameraTrack(null);
      setmicrophoneTrack(null);
      micTrack = null;
      camTrack = null;
      await client?.unpublish();
      if (callback) callback();
    } catch (e: any) {
      return e.code;
    }
  }
  async function leave() {
    try {
      if (localAudioTrack) {
        localAudioTrack.stop();
        localAudioTrack.close();
      }
      client?.localTracks.forEach((v) => v.stop());
      client?.localTracks.forEach((v) => v.close());
      if (localVideoTrack) {
        localVideoTrack.stop();
        localVideoTrack.close();
      }
      client?.localTracks.forEach((v) => v.stop());
      client?.localTracks.forEach((v) => v.close());
      if (cameraTrack) {
        cameraTrack.stop();
        cameraTrack.close();
      }
      if (microphoneTrack) {
        microphoneTrack.stop();
        microphoneTrack.close();
      }

      if (micTrack) {
        micTrack.stop();
        micTrack.close();
      }
      if (camTrack) {
        camTrack.stop();
        camTrack.close();
      }
      if (client && client.localTracks) {
        for (let index = 0; index < client.localTracks.length; index++) {
          const v = client.localTracks[index];
          v.stop();
          v.close();
        }
      }
      setLocalVideoTrack(null);
      setcameraTrack(null);
      setmicrophoneTrack(null);
      micTrack = null;
      camTrack = null;
      setRemoteUsers([]);
      setJoinState(false);
      await client?.leave();
      await client?.stopProxyServer();
      return "OK";
    } catch (e: any) {
      return e.code;
    }
  }

  async function microphoneSetDevice(deviceId: string, status: boolean) {
    //Audioデバイスの設定。getMicrophonesで取得したID
    if (microphoneTrack) {
      microphoneTrack.setDevice(deviceId);
    }
  }
  async function cameraSetDevice(
    deviceId: string,
    deviceName: string,
    status: boolean
  ) {
    //Cameraデバイスの設定。getCamerasで取得したID
    if (cameraTrack) {
      await cameraTrack.setDevice(deviceId);
      if (deviceName === "背面カメラ") {
        cameraTrack._player.videoElement.style.transform = "rotateY(0deg)";
      } else {
        cameraTrack._player.videoElement.style.transform = "rotateY(180deg)";
      }
      await cameraSetEnabled("false");
      // setTimeout(cameraTrack.setMuted, 500, true);
    }
  }
  async function microphoneSetEnabled() {
    if (microphoneTrack) {
      microphoneTrack.setMuted(false);
      return true;
    }
    return false;
  }
  async function microphoneSetDisabled() {
    if (microphoneTrack) {
      microphoneTrack.setMuted(true);
      return true;
    }
    return false;
  }
  async function cameraSetEnabled(state?: any) {
    if (cameraTrack) {
      if (state) {
        if (state === "true") {
          cameraTrack.setMuted(true);
          return true;
        } else {
          cameraTrack.setMuted(false);
          return false;
        }
      }
      cameraTrack.setMuted(!cameraTrack.muted);
      return !cameraTrack.muted;
    }
  }
  async function microphoneSetChange(status: boolean) {
    if (microphoneTrack) {
      microphoneTrack.setMuted(status);
    }
  }
  async function getCameraMuted() {
    if (cameraTrack) {
      return cameraTrack.muted;
    }
  }

  useEffect(() => {
    if (!client) return;
    // setRemoteUsers(client.remoteUsers);
    const setRemoteUserList = async () => {
      let list: any = [];
      let share: any = [];
      let live: any = [];
      for (let index = 0; index < client.remoteUsers.length; index++) {
        const element: any = client.remoteUsers[index];
        if (element.uid === 20000) {
          list.push(element);
          setStreamer(element);
        } else {
          if (element._audio_added_ && element._video_muted_) {
            live.push(element);
          } else if (element._video_added_) {
            if (element.uid === 19999) {
              share.push(element);
            } else {
              list.push(element);
            }
          }
        }
      }
      setRemoteUsers((remoteUsers) => list);
      setShareWindow((shareWindow) => share);
      setRemoteLiveUsers((remoteLiveUsers) => live);
      if (checkVideo) await checkVideo(camTrack, list, share);
      if (list.length + share.length !== userNum) {
        if (callback) callback(list);
      }
      userNum = list.length + share.length;
    };
    setRemoteUserList();
    const handleUserPublished = async (
      user: IAgoraRTCRemoteUser,
      mediaType: "audio" | "video"
    ) => {
      await client.subscribe(user, mediaType);
      await setRemoteUserList();
    };
    const handleUserUnpublished = async (user: IAgoraRTCRemoteUser) => {
      await setRemoteUserList();
    };
    const handleUserJoined = async (user: IAgoraRTCRemoteUser) => {
      await setRemoteUserList();
    };
    const handleUserLeft = async (user: IAgoraRTCRemoteUser) => {
      await setRemoteUserList();
    };
    client.on("user-published", handleUserPublished);
    client.on("user-unpublished", handleUserUnpublished);
    client.on("user-joined", handleUserJoined);
    client.on("user-left", handleUserLeft);

    return () => {
      client.off("user-published", handleUserPublished);
      client.off("user-unpublished", handleUserUnpublished);
      client.off("user-joined", handleUserJoined);
      client.off("user-left", handleUserLeft);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client]);

  return {
    localAudioTrack,
    localVideoTrack,
    joinState,
    leave,
    join,
    publish,
    unpublish,
    microphoneSetEnabled,
    cameraSetEnabled,
    remoteUsers,
    shareWindow,
    streamer,
    remoteLiveUsers,
    setDevice,
    microphoneTrack,
    cameraTrack,
    getCameraMuted,
    microphoneSetDevice,
    cameraSetDevice,
    microphoneSetDisabled,
    microphoneSetChange,
  };
}
