import React, { useEffect } from "react";
import CheckBox from "@material-ui/core/Checkbox";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
// import ActionComand from "./comand";
import GridList from "@material-ui/core/GridList";
import { makeStyles } from "@material-ui/core/styles";
import GridListTile from "@material-ui/core/GridListTile";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { FormatMessage } from "../../components/atoms/FormatMessage";
import BlurLinearIcon from "@material-ui/icons/BlurLinear";
import PlaylistPlayIcon from "@material-ui/icons/PlaylistPlay";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    backgroundColor: theme.palette.background.paper,
    margin: "20px",
  },
  gridList: {
    width: 700,
    height: 500,
  },
  icon: {
    color: "rgba(255, 255, 255, 0.54)",
  },
  colorWhite: {
    color: "white",
  },
  alignCenter: {
    textAlign: "center",
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  text: {
    height: 15,
    width: 300,
    textAlign: "center",
    verticalalign: "middle",
  },
  check: {
    verticalalign: "middle",
  },
}));

type optionType = {
  choice_id: number;
  answer_options: any[];
};
let option: optionType = {
  choice_id: 0,
  answer_options: [],
};

type question = {
  check: boolean;
  answer: string;
  answer_no: number;
};
export default function QuestionGroup(props: any) {
  const [answer1, setAnswer1] = React.useState<question>({
    check: false,
    answer: "",
    answer_no: 1,
  });
  const [answer2, setAnswer2] = React.useState<question>({
    check: false,
    answer: "",
    answer_no: 2,
  });
  const [answer3, setAnswer3] = React.useState<question>({
    check: false,
    answer: "",
    answer_no: 3,
  });
  const [answer4, setAnswer4] = React.useState<question>({
    check: false,
    answer: "",
    answer_no: 4,
  });
  const [answer5, setAnswer5] = React.useState<question>({
    check: false,
    answer: "",
    answer_no: 5,
  });
  const [answer6, setAnswer6] = React.useState<question>({
    check: false,
    answer: "",
    answer_no: 6,
  });
  const [answer7, setAnswer7] = React.useState<question>({
    check: false,
    answer: "",
    answer_no: 7,
  });
  const [answer8, setAnswer8] = React.useState<question>({
    check: false,
    answer: "",
    answer_no: 8,
  });
  const [answer9, setAnswer9] = React.useState<question>({
    check: false,
    answer: "",
    answer_no: 9,
  });
  const [answer10, setAnswer10] = React.useState<question>({
    check: false,
    answer: "",
    answer_no: 10,
  });
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [errdialogOpen, setErrDialogOpen] = React.useState(false);
  const classes = useStyles();

  const handleClickOpen = () => {
    setDialogOpen(true);
  };
  const handleClickClose = () => {
    setDialogOpen(false);
    setErrDialogOpen(false);
  };

  const questions = () => {
    const questionList = [
      {
        answer: answer1.answer,
        check: answer1.check,
        answer_no: 1,
      },
      {
        answer: answer2.answer,
        check: answer2.check,
        answer_no: 2,
      },
      {
        answer: answer3.answer,
        check: answer3.check,
        answer_no: 3,
      },
      {
        answer: answer4.answer,
        check: answer4.check,
        answer_no: 4,
      },
      {
        answer: answer5.answer,
        check: answer5.check,
        answer_no: 5,
      },
      {
        answer: answer6.answer,
        check: answer6.check,
        answer_no: 6,
      },
      {
        answer: answer7.answer,
        check: answer7.check,
        answer_no: 7,
      },
      {
        answer: answer8.answer,
        check: answer8.check,
        answer_no: 8,
      },
      {
        answer: answer9.answer,
        check: answer9.check,
        answer_no: 9,
      },
      {
        answer: answer10.answer,
        check: answer10.check,
        answer_no: 10,
      },
    ];

    const result = questionList.filter(function (value) {
      return value.check === true;
    });
    if (result.length === 0) {
      setErrDialogOpen(true);
      return;
    }
    // let i = 1;

    const answer_options:any[] = [];
    result.forEach((element) => {
      if (element.answer === "") {
        element.answer = String(element.answer_no);
      }
      answer_options.push({
        answer_no: element.answer_no,
        answer: element.answer,
        count: 0,
        percent: 0,
      });
      // i++;
    });
    option.answer_options = answer_options;
    props.setQuestionList(questionList, option);
    props.questionClose();
  };

  const CilckClear = () => {
    setAnswer1({
      check: false,
      answer: "",
      answer_no: 1,
    });
    setAnswer2({
      check: false,
      answer: "",
      answer_no: 2,
    });
    setAnswer3({
      check: false,
      answer: "",
      answer_no: 3,
    });
    setAnswer4({
      check: false,
      answer: "",
      answer_no: 4,
    });
    setAnswer5({
      check: false,
      answer: "",
      answer_no: 5,
    });
    setAnswer6({
      check: false,
      answer: "",
      answer_no: 6,
    });
    setAnswer7({
      check: false,
      answer: "",
      answer_no: 7,
    });
    setAnswer8({
      check: false,
      answer: "",
      answer_no: 8,
    });
    setAnswer9({
      check: false,
      answer: "",
      answer_no: 9,
    });
    setAnswer10({
      check: false,
      answer: "",
      answer_no: 10,
    });

    handleClickClose();
  };

  useEffect(() => {
    setAnswer1(props.questionLists[0]);
    setAnswer2(props.questionLists[1]);
    setAnswer3(props.questionLists[2]);
    setAnswer4(props.questionLists[3]);
    setAnswer5(props.questionLists[4]);
    setAnswer6(props.questionLists[5]);
    setAnswer7(props.questionLists[6]);
    setAnswer8(props.questionLists[7]);
    setAnswer9(props.questionLists[8]);
    setAnswer10(props.questionLists[9]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.root}>
      <GridList cellHeight={90} className={classes.gridList}>
        <GridListTile>
          <div>
            <CheckBox
              value={answer1.check}
              className={classes.check}
              checked={answer1.check}
              color="default"
              onChange={() => {
                setAnswer1((state) => ({ ...state, check: !answer1.check }));
              }}
            />
            <TextField
              id="outlined-search"
              //type="search"
              variant="outlined"
              placeholder="No.1"
              value={answer1.answer}
              className={classes.text}
              inputProps={{ maxLength: 256 }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setAnswer1((state) => ({
                  ...state,
                  answer: event.target.value,
                }));
              }}
            />
          </div>
        </GridListTile>
        <GridListTile>
          <CheckBox
            value={answer2.check}
            className={classes.check}
            checked={answer2.check}
            color="default"
            onChange={() => {
              setAnswer2((state) => ({ ...state, check: !answer2.check }));
            }}
          />
          <TextField
            id="outlined-search"
            //type="search"
            variant="outlined"
            placeholder="No.2"
            value={answer2.answer}
            className={classes.text}
            inputProps={{ maxLength: 256 }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setAnswer2((state) => ({ ...state, answer: event.target.value }));
            }}
          />
        </GridListTile>
        <GridListTile>
          <CheckBox
            value={answer3.check}
            className={classes.check}
            color="default"
            checked={answer3.check}
            onChange={() => {
              setAnswer3((state) => ({ ...state, check: !answer3.check }));
            }}
          />
          <TextField
            id="outlined-search"
            //type="search"
            variant="outlined"
            placeholder="No.3"
            value={answer3.answer}
            className={classes.text}
            inputProps={{ maxLength: 256 }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setAnswer3((state) => ({ ...state, answer: event.target.value }));
            }}
          />
        </GridListTile>
        <GridListTile>
          <CheckBox
            value={answer4.check}
            className={classes.check}
            color="default"
            checked={answer4.check}
            onChange={() => {
              setAnswer4((state) => ({ ...state, check: !answer4.check }));
            }}
          />
          <TextField
            id="outlined-search"
            //type="search"
            variant="outlined"
            placeholder="No.4"
            value={answer4.answer}
            className={classes.text}
            inputProps={{ maxLength: 256 }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setAnswer4((state) => ({ ...state, answer: event.target.value }));
            }}
          />
        </GridListTile>
        <GridListTile>
          <CheckBox
            value={answer5.check}
            className={classes.check}
            color="default"
            checked={answer5.check}
            onChange={() => {
              setAnswer5((state) => ({ ...state, check: !answer5.check }));
            }}
          />
          <TextField
            id="outlined-search"
            //type="search"
            variant="outlined"
            placeholder="No.5"
            value={answer5.answer}
            className={classes.text}
            inputProps={{ maxLength: 256 }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setAnswer5((state) => ({ ...state, answer: event.target.value }));
            }}
          />
        </GridListTile>
        <GridListTile>
          <CheckBox
            value={answer6.check}
            className={classes.check}
            checked={answer6.check}
            color="default"
            onChange={() => {
              setAnswer6((state) => ({ ...state, check: !answer6.check }));
            }}
          />
          <TextField
            id="outlined-search"
            //type="search"
            variant="outlined"
            placeholder="No.6"
            value={answer6.answer}
            className={classes.text}
            inputProps={{ maxLength: 256 }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setAnswer6((state) => ({ ...state, answer: event.target.value }));
            }}
          />
        </GridListTile>
        <GridListTile>
          <CheckBox
            value={answer7.check}
            className={classes.check}
            checked={answer7.check}
            color="default"
            onChange={() => {
              setAnswer7((state) => ({ ...state, check: !answer7.check }));
            }}
          />
          <TextField
            id="outlined-search"
            //type="search"
            variant="outlined"
            placeholder="No.7"
            value={answer7.answer}
            className={classes.text}
            inputProps={{ maxLength: 256 }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setAnswer7((state) => ({ ...state, answer: event.target.value }));
            }}
          />
        </GridListTile>
        <GridListTile>
          <CheckBox
            value={answer8.check}
            className={classes.check}
            checked={answer8.check}
            color="default"
            onChange={() => {
              setAnswer8((state) => ({ ...state, check: !answer8.check }));
            }}
          />
          <TextField
            id="outlined-search"
            //type="search"
            variant="outlined"
            placeholder="No.8"
            value={answer8.answer}
            className={classes.text}
            inputProps={{ maxLength: 256 }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setAnswer8((state) => ({ ...state, answer: event.target.value }));
            }}
          />
        </GridListTile>
        <GridListTile>
          <CheckBox
            value={answer9.check}
            className={classes.check}
            color="default"
            checked={answer9.check}
            onChange={() => {
              setAnswer9((state) => ({ ...state, check: !answer9.check }));
            }}
          />
          <TextField
            id="outlined-search"
            //type="search"
            variant="outlined"
            placeholder="No.9"
            value={answer9.answer}
            className={classes.text}
            inputProps={{ maxLength: 256 }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setAnswer9((state) => ({ ...state, answer: event.target.value }));
            }}
          />
        </GridListTile>
        <GridListTile>
          <CheckBox
            value={answer10.check}
            className={classes.check}
            checked={answer10.check}
            color="default"
            onChange={() => {
              setAnswer10((state) => ({ ...state, check: !answer10.check }));
            }}
          />
          <TextField
            id="outlined-search"
            //type="search"
            variant="outlined"
            placeholder="No.10"
            value={answer10.answer}
            className={classes.text}
            inputProps={{ maxLength: 256 }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setAnswer10((state) => ({
                ...state,
                answer: event.target.value,
              }));
            }}
          />
        </GridListTile>
      </GridList>
      <Grid container spacing={3} className="App-mT10">
        <Grid item xs={12} className="App-alignCenter">
          <Button
            startIcon={<BlurLinearIcon />}
            variant="contained"
            color="primary"
            style={{ margin: "10px", marginRight: "5px" }}
            className="App-button_bottom"
            onClick={handleClickOpen}
          >
            {FormatMessage({
              id: "Cls009.Button.Clear",
            })}
          </Button>
          <Button
            startIcon={<PlaylistPlayIcon />}
            variant="contained"
            color="primary"
            style={{ margin: "10px", marginLeft: "5px" }}
            className="App-button_bottom"
            onClick={questions}
          >
            {FormatMessage({
              id: "Cls009.Button.Push",
            })}
          </Button>
        </Grid>
      </Grid>
      <Dialog
        open={dialogOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={false}
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {FormatMessage({
              id: "Cls009.Dialog.Text",
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickClose} color="primary" autoFocus>
            {FormatMessage({
              id: "Cls009.Dialog.Button1",
            })}
          </Button>
          <Button onClick={CilckClear} color="primary">
            {FormatMessage({
              id: "Cls009.Dialog.Button2",
            })}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={errdialogOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {FormatMessage({
              id: "Cls009.ErrDialog.Text",
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickClose} color="primary" autoFocus>
            {FormatMessage({
              id: "Cls009.Dialog.Button2",
            })}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
