import { apigw } from "../awsApi";

/**
 * S3アクセストークン取得
 * @param {Dispatch} dispatch reducerディスパッチ
 * @return コンポーネントHTMLコード内容
 */
var sessionToken: any;
export const ST0313 = async () => {
  if (process.env.REACT_APP_API_USE_FLG === "true") {
    let nowTime = new Date().getTime();
    if (!sessionToken) {
      sessionToken = localStorage.getItem("sessionToken");
      if (sessionToken) sessionToken = JSON.parse(sessionToken);
    }
    if (sessionToken) {
      // let expiration = new Date(sessionData.expiration).getTime();
      // if (nowTime < expiration - 60000 * 11) {
      let expiration = Number(sessionToken.expiration);
      if (nowTime < expiration) {
        return {
          state: "0",
          error_code: "",
          body: sessionToken,
        };
      }
    }
    if (localStorage.getItem("session_id")) {
      console.log("ST0313 post");
      const session = await apigw("/ST0313", {}, null);
      if (session && session.state === "0") {
        session.body.expiration = new Date().getTime() + 60000 * 50;
        localStorage.setItem("sessionToken", JSON.stringify(session.body));
        sessionToken = session.body;
      }
      return session;
    } else {
      return null;
    }
  } else {
    //サンプルデータ用
    return {
      state: "0",
      error_code: "",
      body: {
        access_key: "ASIAQSZX6XMYON53QH7W",
        secret_Key: "uQOo+BJgxtq7aa1AwZUBDeAA1NpD78up7/ojNCdq",
        session_token:
          "IQoJb3JpZ2luX2VjEPz//////////wEaDmFwLW5vcnRoZWFzdC0xIkcwRQIhAIZwFLyhhjxpVRQgcSjJJ16EQIbc9qn82PCtd40V7M3aAiBe3M5wrfVqXlmDy4MH9KMI5BIw7pi/DiV8P5T4qoGtzyriBAhFEAEaDDA0MDM4MjY3NTc2MCIMBo4Wx9JR/umuZYi9Kr8EuW0agLm5uuDV1ZEk+3bo+ny5No8W4DA2O1Z4hXbk+B2ZG1981fsz4riDDUjTrjowVID4CEIK3tzQTrCieMkjgRQzNSFCGr9AcAn4gEp/kXewxVcRplPMrBR1Qy/VNF2r78/TDlM0BqueOsYoU99HBqAVmMIKk4Rq5MGybOcrjJCmPTEYWEDGNmoimscQaWzyX23NsWeaef2i8V4vMThfCgGdDI3Uh8G5jZrrbOqX7ZefpbZiNET0Y89pJNJYSTxK/kmDgZj7nK43PAXJYObDgNCFh/ghrEOYrxvez1uN4KfqugJhgx88xsOaLPc9HCD0MMfJo3lPxM6sHpumN23cwkWm5kGr/LSN5liJ48Q7a0OZHKI4kf4nW7RwIQfoKpHk/mhdOzr1xDw3AGK/O8nB2FwzKssDZ+sO17tJxNjh+WAmAhCQtmHy3FzcLEL4zaBIsN4C1FIYHixTw3NQ/HnqFR0hhBgomR7RQbJ8N5JA7ZM1tbC4eQ4nFPgk7n2+yWMa66tair7BZzqlf1b5idcAdnn0JctfSEkO21HEaPPGxZGAfnjNsx1P/nncRO5HpqSNy4xdxj3c5GZTJqYa2rne7iNUQi0/DHhbtRy8UaXfZijM1AWIbB6PXRjgoBnfBKDnQySjOHq6EtP0MisjXBQwMYyQ4TDKUHT4I+XOKjLeBPMhsHQuhklUaTjAYiK9BlEMCXs9CaM7cjMkfmeDFI3QEJsEB62feATuw1rh3hMVOHJSk8X/IC1mLRf9HNBI6UwwkLbNiQY6hQJKNWgv9tdsHwxPrDwW0NJDUReAGLYsykBQLLA4N/wnxzQTQxEgHVuYqaZvzLd8GJDb8nrmOtc578pg3paPJZKUuezJcPa4Qo+C8uj2IqEnhyLADK3udAhjX1HuBeGE3SoPrc5q89a/EgeyDCR6isA6PmLEE5SKJpSGglhCjp2YxF28ZaD3desjcBrZ3fKU9hrZI8moG4YDXLlhc9G3zVMS00tmDmzH8dnkAORn43Ar//WFw3HCEoREggBAfWShbFvNHD4Qs+WjqSYdrNdNg2P+3tjsYExoxWykKiWsYYmpEZgfBtEIS89AMzND1nAmzmpu67nQd/d8Tuzkk3iZbpOyP3rM0v4=",
        expiration: "2021-09-04 21:40:00",
      },
    };
  }
};
